// @flow
import React, { Component } from 'react';
import type { Element } from 'react';
import { StyleSheet } from 'react-native';
import FastImage from 'react-native-fast-image';
/* $FlowFixMe */
import DropDownPicker from 'react-native-dropdown-picker';
// import type { DropDownPickerProps } from 'react-native-dropdown-picker';
// https://hossein-zare.github.io/react-native-dropdown-picker-website/docs/changelog    DOC地址

// import { Select } from 'antd';
// import { Select } from 'teaset';

const SelectIcon = () => (<FastImage
    source={require('../../images/selectIcon.webp')}
    style={styles.iconStyle}
/>)

class SelectLanguage extends Component {
  constructor (props: any) {
    super(props);
    this.state = {
      languages: [
        {
          value: 'en',
          label: 'English'
        },
        {
          value: 'zh',
          label: '中文（简体）'
        },
        {
          value: 'zh-HK',
          label: '中文（繁体）'
        }
      ],
      currentLanguage: 'en',
      open: false
    };
  }

  componentDidMount () {
  }

  setOpen: (open: boolean) => void = (open): void => {
    this.setState({
      open
    });
  };

  setValue: (callback: Function) => void = (callback): void => {
    this.setState(state => ({
      currentLanguage: callback(state.currentLanguage)
    }));
  };

  render (): Element<*> {
    const { currentLanguage, languages, open } = this.state;
    return (
            <DropDownPicker
                itemKey="value"
                open={open}
                value={currentLanguage}
                items={languages}
                setOpen={this.setOpen}
                setValue={this.setValue}
                showTickIcon={false}
                disableBorderRadius={false}
                ArrowDownIconComponent={SelectIcon}
                ArrowUpIconComponent={SelectIcon}
                style={styles.inputStyle}
                textStyle={styles.textStyle}
                dropDownContainerStyle={styles.dropDownContainerStyle}
                itemSeparator={true}
                itemSeparatorStyle={styles.itemSeparatorStyle}
            />
            // <Select
            //     style={{
            //         width: 66,
            //         padding: 0,
            //         marginRight: 50,
            //     }}
            //     value={currentLanguage}
            //     options={languages}
            //     // onChange={this.onChange}
            //     onChange={() => console.log('1234567890')}
            //     // onChange={(item, index) => this.setState({currentLanguage: item.value})}
            // />
    )
  }
}

const styles = StyleSheet.create({
  inputStyle: {
    position: 'absolute',
    marginTop: -15,
    height: 32,
    width: 88,
    paddingLeft: 14,
    paddingRight: 18,
    backgroundColor: '#F7F7F8',
    borderRadius: 16,
    overflow: 'hidden'
  },
  textStyle: {
    lineHeight: 32,
    fontSize: 14,
    fontFamily: 'Helvetica',
    color: '#000',
    opacity: 0.4
  },
  dropDownContainerStyle: {
    position: 'absolute',
    borderRadius: 0,
    backgroundColor: '#F7F7F8',
    borderColor: '#F7F7F8',
    top: 20
  },
  iconStyle: {
    position: 'absolute',
    width: 8,
    height: 8,
    top: -20,
    right: -6
  },
  itemSeparatorStyle: {
    backgroundColor: '#fff'
  }
});

export default SelectLanguage;
