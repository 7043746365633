// @flow
// import EncryptedStorage from 'react-native-encrypted-storage';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { persistReducer } from 'redux-persist';
import { type PersistConfig } from 'redux-persist/es/types';
import type { Reducer } from 'redux';
import { API_ENV } from '@env';
import type { PersistActionType, HomeStateType } from '../actions/common';
import type { HomeActionsType } from '../actions/HomeAction';
import { Platform } from 'react-native';

import I18n from '../utils/i18n';
import helper from '../utils/helper';
import { ActionTypes } from '../actions/ActionTypes';
import defaultData from '../offlineData';

const initialState: HomeStateType = {
  languageCode: I18n.locale,
  isLogin: false, // 不进行持久化
  userLoading: false,
  userInfoFinishload: false,
  userLogoutLoading: false,
  loadingError: null,
  userInfo: null,
  socialUserInfo: {}, // 注册时，填写的社交用户昵称
  userAssets: {}, // 需要持久化,但需要每次有资产相关信息变化后刷新
  lockUpdateTime: 0,
  secondVerify: {},
  isLocked: false,
  isSuspensed: false,
  savedUser: null,
  savedUserMobile: null,
  newSavedUser: false,
  member: null,
  deviceId: helper.createUUID(),
  remoteConfig: {
    spotOpen: true,
    swapFixedOpen: true,
    swapCrossOpen: false,
    fiatOpen: true,
    inviteActivityOpen: true,
    earningsOpen: false,
    fiatActivityOpen: false,
    giftCardActivityOpen: false,
    twdFiatOpen: false,
    vipActivityOpen: true,
    newTradeOpen: true,
    marginTradeOpen: false
  },
  bannerConfig: defaultData.bannerConfig[I18n.locale],
  eventCenterConfig: [],
  eventVIPCenterConfig: [],
  regTrialFundConfig: {
    day: 0,
    annualRate: 0,
    name: '',
    amount: 0,
    effectTime: '',
    interestDaily: 0,
    sendFundHour: 10
  },
  regBonusConfig: {
    isOpen: false,
    startTime: '',
    endTime: ''
  },
  discoverConfig: defaultData.discoverConfig,
  referrerId: null,
  vsDataConfig: defaultData.vsDataConfig,

  giftCardConfig: null,
  fiatDepositConfig: {
    title: '',
    date: '',
    brief: [
    ],
    button_txt: '',
    modal_title: '',
    modal_url: '',
    entry_title: '',
    entry_desc: ''
  },
  openAuthInfo: {
    useOpenAuth: false,
    loading: false,
    error: null,
    mustPwdLogin: false,
    loginFinished: false
  },
  appVersionConfig: null,
  firstUpdateShow: true,
  promptedVersionInfo: null,
  noticeSystem: [],
  noticeOtc: [],
  noticeReward: [],
  regBonusOpenTime: '',
  guideBeginnersConfig: []
};

export type PersistHomeActionType = HomeActionsType | PersistActionType;
type VoidHomeStateType = HomeStateType | void;

function locked (state: HomeStateType, time: string): HomeStateType {
  const d1 = new Date(time.replace(/-/g, '/'));
  const d = new Date();
  const localTime = d.getTime();
  const localOffset = d.getTimezoneOffset() * 60000; // getTimezoneOffset()返回是以分钟为单位，需要转化成ms
  const utc = localTime + localOffset;
  const d2 = new Date(utc); // 获取日期与时间
  const bbq = parseInt(120 - parseInt(d2 - d1) / 1000 / 60);
  return Object.assign({}, state, {
    lockUpdateTime: bbq,
    isLocked: true,
    userLoading: false
  });
}

const SAVE_EMAIL_SEPARATOR = '_email_';
const SAVE_MOBILE_SEPARATOR = '_mobile_';

const homereducer: Reducer<HomeStateType, PersistHomeActionType> = (state: VoidHomeStateType, action: PersistHomeActionType) => {
  if (action.type === ActionTypes.REHYDRATE) {
    console.log('get rehydrate return');
    // console.log('authReducer rehydrate state is ' + JSON.stringify(state));
    return state ?? initialState;
  }
  if (!state) {
    return initialState;
  }
  switch (action.type) {
    // 获取全部
    case ActionTypes.ACCOUNT_USER_INFO: // preload
      global.USER_ID = (action.userInfo && action.userInfo.id) || '';

      return Object.assign({}, state, {
        userLoading: false,
        isLogin: true,
        userInfoFinishload: true,
        // userAssets: action.userInfo,
        userInfo: action.userInfo,
        member: {
          memberInfo: action.userInfo,
          // token: action.userInfo.UUID,
          wsKey: ''
        }
      });
    case ActionTypes.ACCOUNT_USER_INFO_REQUEST:
      return Object.assign({}, state, { userLoading: true });
    case ActionTypes.ACCOUNT_USER_INFO_ERROR:
      return Object.assign({}, state, {
        isLogin: !!state.userInfo?.jwtKey, // 有Key 默认为已经登录
        userLoading: false,
        userInfoFinishload: true,
        loadingError: action.error
      });
    case ActionTypes.ACCOUNT_USER_INFO_RESET: // 重置 user info 错误
      return Object.assign({}, state, {
        loadingError: null,
        userLoading: false
      });
    case ActionTypes.ACCOUNT_LOCKED:
      return locked(state, action.time);
    case ActionTypes.ACCOUNT_SUSPENSED:
      return Object.assign({}, state, {
        isSuspensed: true
      });
    case ActionTypes.ACCOUNT_SUSPENSED_RESET:
      return Object.assign({}, state, {
        isSuspensed: false,
        userLoading: false
      });

    case ActionTypes.ACCOUNT_LOCK_RESET:
      return Object.assign({}, state, {
        isLocked: false,
        lockUpdateTime: 0
      });
    case ActionTypes.ACCOUNT_SECOND_VERIFY:
      return Object.assign({}, state, {
        loadingError: null,
        userLoading: false,
        secondVerify: {
          secondOpen: action.secondOpen,
          secondType: action.secondType,
          telephone: action.telephone,
          isPhoneCertified: action.isPhoneCertified,
          isGoogleCertified: action.isGoogleCertified
        }
      });
    case ActionTypes.ACCOUNT_SECOND_VERIFY_QUIT:
      return Object.assign({}, state, { secondVerify: {} });
      // 用户登出
    case ActionTypes.ACCOUNT_LOGOUT:
      global.USER_ID = '';
      return Object.assign({}, state, {
        userLogoutLoading: false,
        isLogin: false,
        userInfo: null,
        userAssets: {},
        userMoneyInfo: {},
        secondVerify: {}
      });
    case ActionTypes.ACCOUNT_LOST_SESSION:
      global.USER_ID = '';
      return Object.assign({}, state, {
        isLogin: false,
        userInfoFinishload: true,
        userLoading: false,
        userInfo: null
      });
    case ActionTypes.ACCOUNT_LOGOUT_REQUEST:
      return Object.assign({}, state, {
        userLogoutLoading: true
      });
    case ActionTypes.ACCOUNT_LOGOUT_ERROR:
      return Object.assign({}, state, {
        userLogoutLoading: false
        // loadingError: action.error,
      });
    case ActionTypes.ACCOUNT_LOGOUT_RESET:
      return Object.assign({}, state, { loadingError: null });
    case ActionTypes.I18N_LANG:
      return Object.assign({}, state, { languageCode: action.languageCode });
    case ActionTypes.ACCOUNT_LOGOUT || ActionTypes.ACCOUNT_USER_INFO_CLEAN: {
      const t = initialState;
      /* $FlowFixMe */
      delete t.languageCode;
      delete t.remoteConfig;
      delete t.bannerConfig;
      delete t.giftCardConfig;
      delete t.fiatDepositConfig;
      delete t.regTrialFundConfig;
      delete t.eventCenterConfig;
      delete t.eventVIPCenterConfig;
      delete t.noticeSystem;
      delete t.noticeOtc;
      delete t.noticeReward;

      return Object.assign({}, state, t);
    }
    case ActionTypes.REMOTE_CONFIG:
      return Object.assign({}, state, { remoteConfig: action.remoteConfig });
    case ActionTypes.BANNER_CONFIG:
      return Object.assign({}, state, { bannerConfig: action.bannerConfig });
    case ActionTypes.EVENTCENTER_CONFIG:
      return Object.assign({}, state, { eventCenterConfig: action.eventCenterConfig });

    case ActionTypes.VIP_EVENTCENTER_CONFIG:
      return Object.assign({}, state, { eventVIPCenterConfig: action.eventVIPCenterConfig });

    case ActionTypes.REG_TRIAL_FUND_CONFIG:
      return Object.assign({}, state, { regTrialFundConfig: action.regTrialFundConfig });
    case ActionTypes.REG_BONUS_CONFIG:
      return Object.assign({}, state, { regBonusConfig: action.regBonusConfig });
    case ActionTypes.DISCOVER_CONFIG:
      return Object.assign({}, state, { discoverConfig: action.discoverConfig });

    case ActionTypes.BANNER_OFFLINE_CONFIG:
      return Object.assign({}, state, { bannerConfig: defaultData.bannerConfig[action.languageCode] });
    case ActionTypes.VSDATA_CONFIG:
      return Object.assign({}, state, { vsDataConfig: action.vsDataConfig });

    case ActionTypes.NOTICE_SYSTEM:
      return Object.assign({}, state, { noticeSystem: action.noticeSystem });

    case ActionTypes.NOTICE_OTC:
      return Object.assign({}, state, { noticeOtc: action.noticeOtc });
    case ActionTypes.NOTICE_REWARD:
      return Object.assign({}, state, { noticeReward: action.noticeReward });
    case ActionTypes.NOTICE_ALL:
      return Object.assign({}, state, { noticeSystem: action.noticeSystem, noticeOtc: action.noticeOtc, noticeReward: action.noticeReward });

    case ActionTypes.REFER_INFO:
      return Object.assign({}, state, { referrerId: action.referrerId });
    case ActionTypes.GIFTCARD_CONFIG:
      return Object.assign({}, state, {
        giftCardConfig: action.giftCardConfig
      });
    case ActionTypes.REGBONUS_OPENTIME:
      return Object.assign({}, state, { regBonusOpenTime: action.regBonusOpenTime });

    case ActionTypes.FIATDEPOSIT_CONFIG:
      return Object.assign({}, state, {
        fiatDepositConfig: action.fiatDepositConfig
      });

    case ActionTypes.VERSION_CONFIG:
      return Object.assign({}, state, {
        appVersionConfig: action.appVersionConfig
      });

    case ActionTypes.GUIDEBEGINNERS_CONFIG:
      return Object.assign({}, state, {
        guideBeginnersConfig: action.guideBeginnersConfig
      });

    case ActionTypes.DEPOSIT_CONFIG:
      return Object.assign({}, state, {
        depositStatus: action.depositStatus
      });

    case ActionTypes.FIRST_UPDATE_SHOW:
      return Object.assign({}, state, {
        firstUpdateShow: action.firstUpdateShow
      });
    case ActionTypes.PROMPTED_VERSION_INFO:
      return Object.assign({}, state, {
        promptedVersionInfo: action.promptedVersionInfo
      });

    case ActionTypes.REFRESH_OPEN_AUTH_INFO: {
      const openAuthInfo = Object.assign({}, state.openAuthInfo ?? initialState.openAuthInfo);
      const info = action.info ?? {};
      // eslint-disable-next-line flowtype/require-parameter-type
      // Object.keys(info).forEach(key => { openAuthInfo[key] = info[key]; });
      Object.keys(info).map((key: string, index: number): number => {
        openAuthInfo[key] = info[key];
        return 0;
      });

      return Object.assign({}, state, {
        openAuthInfo: openAuthInfo
      });
    }
    // eslint-disable-next-line no-duplicate-case
    case ActionTypes.ACCOUNT_LOGOUT:
    case ActionTypes.CLEAN_OPEN_AUTH_INFO: {
      const openAuthInfo = initialState.openAuthInfo;
      return Object.assign({}, state, { openAuthInfo: openAuthInfo, newSavedUser: false });
    }
    case ActionTypes.ACCOUNT_SAVE_USER: {
      const savedUser = state.savedUser;
      // eslint-disable-next-line camelcase
      if (!action.email) {
        return state;
      }
      if (!savedUser) {
        return Object.assign({}, state, { savedUser: action.email, newSavedUser: true });
      }
      const arr = savedUser.split(SAVE_EMAIL_SEPARATOR);
      if (arr.indexOf(action.email) === -1) {
        return Object.assign({}, state, { savedUser: savedUser + SAVE_EMAIL_SEPARATOR + action.email, newSavedUser: true });
      } else {
        // 不发生变化
        return Object.assign({}, state, { newSavedUser: false });
      }
    }
    case ActionTypes.ACCOUNT_SAVE_USER_MOBILE: {
      const savedUserMobile = state.savedUserMobile;
      // eslint-disable-next-line camelcase
      if (!action.mobile) {
        return state;
      }
      if (!savedUserMobile) {
        return Object.assign({}, state, { savedUserMobile: decodeURIComponent(action.mobile) + '_' + action.country, newSavedUser: true });
      }
      // const arr = savedUserMobile.split(SAVE_MOBILE_SEPARATOR);
      if (action.mobile && savedUserMobile.indexOf(decodeURIComponent(action.mobile)) === -1) {
        return Object.assign({}, state, { savedUserMobile: savedUserMobile + SAVE_MOBILE_SEPARATOR + decodeURIComponent(action.mobile) + '_' + action.country, newSavedUser: true });
      } else {
        // 不发生变化
        return Object.assign({}, state, { newSavedUser: false });
      }
    }

    default:
      return state;
  }
};
const _whiteList = [
  'userInfo',
  'socialUserInfo',
  'lockUpdateTime',
  'userAssets',
  'savedUser',
  'savedUserMobile',
  'member',
  'navigationTab',
  'languageCode',
  'bannerConfig',
  'fiatDepositConfig',
  'regTrialFundConfig',
  'remoteConfig',
  'giftCardConfig',
  'eventCenterConfig',
  'eventVIPCenterConfig',
  'deviceId',
  'openAuthInfo',
  'noticeSystem',
  'regBonusOpenTime',
  'noticeOtc',
  'noticeReward',
  'regBonusConfig',
  'promptedVersionInfo',
  'discoverConfig',
  'guideBeginnersConfig'
];
if (Platform.OS === 'web') {
  _whiteList.push('isLogin');
}
const homePersistConfig: PersistConfig = {
  key: 'home',
  debug: API_ENV === 'prod' || API_ENV === 'pro',
  timeout: 10000,
  // storage: Platform.OS === 'ios' ? AsyncStorage : EncryptedStorage,
  storage: AsyncStorage,
  whitelist: _whiteList
};

export type PersistHomeReducerType = Reducer<HomeStateType, PersistHomeActionType>;
const persistAuthReducer: (HomeStateType, PersistHomeActionType) => HomeStateType = persistReducer<HomeStateType, PersistHomeActionType>(homePersistConfig, homereducer);

export default persistAuthReducer;
