// @flow

import React, { Component } from 'react';
import {
  View,
  TouchableWithoutFeedback,
  Animated,
  Text,
  Platform

} from 'react-native';
import FastImage from 'react-native-fast-image';

import p from '../../../utils/Transfrom';
import type { Element } from 'react';
import * as helper from '../../../utils/helper';
import { isSocialLogin } from '../../../utils/helper';
import { connect } from 'react-redux';
import type { CombinedReducerType, DispatchProps } from '../../../reducers';
import { defaultMapDispatchProp, defaultMapStateProp } from '../../../reducers';

import HomeStyle from '../styles/home'

type HeaderStateType = {

};
type HeaderOwnPropType = {};

type HeaderProsType = HeaderOwnPropType & CombinedReducerType & DispatchProps;

class Header extends Component<HeaderProsType, HeaderStateType> {
  constructor (props: HeaderProsType) {
    super(props);
    this.state = {

    };
  }

  goProfile: () => void = (): void => {
    if (!isSocialLogin(this.props.SocialReducer)) {
      return helper.navigate(this.props.navigation.navigate, 'Login');
    }
    try {
      helper.trackEvent('NewHome Click Profile');
    } catch (e) {
    }
    const { myMisc } = this.props.TraderReducer;
    this.props.navigation.navigate('NewProfile', {
      userFullData: myMisc
    });
  };

  render (): Element<*> {
    const { rightView, titleName, animatedScrollYValue, animatedViewChildren, maxAnimateHeight = p(60), topAnimatedValue = 60 } = this.props

    const { myMisc } = this.props.TraderReducer;
    const identificationSource = helper.identificationSwitch(myMisc.role);
    const avatar = !myMisc.avatar
      ? require('../../../images/defaultAvatar.webp')
      : { uri: myMisc.avatar + IMAGE_96 };
    let maxHeight; let topValue; let opacityValue = 1
    if (Platform.OS !== 'web') {
      maxHeight = animatedScrollYValue.interpolate({
        inputRange: [-10000, 0, maxAnimateHeight + (Platform.OS === 'android' && maxAnimateHeight === p(60) ? 40 : 0), 10000],
        /* $FlowFixMe */
        outputRange: [maxAnimateHeight, maxAnimateHeight, 0, 0] // <-- value that larger than your content's height
      });
      opacityValue = animatedScrollYValue.interpolate({
        inputRange: [-10000, 0, 20, 10000],
        /* $FlowFixMe */
        outputRange: [1, 1, 0, 0],
        extrapolate: 'clamp'
      });

      topValue = animatedScrollYValue.interpolate({
        inputRange: [-10000, 0, topAnimatedValue, 10000],
        /* $FlowFixMe */
        outputRange: [0, 0, -topAnimatedValue, -topAnimatedValue] // <-- value that larger than your content's height
      });
    }

    return (
    <View style={HomeStyle.statusBarPadding}>
      <View style={HomeStyle.header}>
        <TouchableWithoutFeedback onPress={this.goProfile} testID={'profile'}>
          <View>
            {
              this.props.HomeReducer.isLogin
                ? <View style={{ position: 'relative' }}>
                  <FastImage
                    source={avatar}
                    resizeMode={'contain'}
                    style={HomeStyle.avatarStyle}
                  />
                  {identificationSource
                    ? <FastImage style={HomeStyle.identificationImageStyle} source={identificationSource} />
                    : null}
              </View>
                : <FastImage
                  source={require('../../../images/logo4.webp')}
                  resizeMode={'contain'}
                  style={HomeStyle.avatarStyle}
                />

            }
          </View>
        </TouchableWithoutFeedback>

        {rightView}
      </View>

        <View style={{ position: 'relative', zIndex: 0, overflow: 'hidden' }}>
          <Animated.View style={[{ zIndex: 2, maxHeight: Platform.OS !== 'web' ? maxHeight : maxAnimateHeight, top: Platform.OS !== 'web' ? topValue : 0, opacity: Platform.OS === 'android' && maxAnimateHeight === p(60) ? opacityValue : 1 }]}>
            <Text style={HomeStyle.screenTitleStyle}>{titleName}</Text>
            {animatedViewChildren || null}
          </Animated.View>
        </View>

    </View>
    )
  }
}
const connector = connect(defaultMapStateProp<HeaderOwnPropType>(), defaultMapDispatchProp<HeaderOwnPropType>());
export default (connector(Header): Class<Header>);
