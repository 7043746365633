// @flow
import type { Element } from 'react';
import React, { Component } from 'react';

import { Text, View } from 'react-native';
// import p from '../utils/Transfrom';
import I18n from '../utils/i18n';
import type { CombinedReducerType, DispatchProps } from '../reducers';
import type { TextLayoutEvent } from 'react-native/Libraries/Types/CoreEventTypes';
import bugsnag from '../utils/bugsnag'
import { colors, fontSize, lineHeight, margin } from '../styles/Common'

type OwnPropType = {
  NUM_OF_LINES: number,
  text: string
};
type StateType = {
  showMore: boolean
};

type ProsType = OwnPropType & CombinedReducerType & DispatchProps;

export default class TextMore extends Component <ProsType, StateType> {
  constructor (props: ProsType) {
    super(props);
    this.state = {
      showMore: false

    };
  }

  onTextLayout (e: TextLayoutEvent) {
    try {
      const { NUM_OF_LINES } = this.props;
      if (e.nativeEvent.lines.length > NUM_OF_LINES - 1) {
        if (!this.props.text.endsWith(e.nativeEvent.lines[NUM_OF_LINES - 1].text) ||
          e.nativeEvent.lines[NUM_OF_LINES - 1].text.length > e.nativeEvent.lines[NUM_OF_LINES - 2].text.length) {
          this.setState({ showMore: true });
        }
      } else {
        this.setState({ showMore: false });
      }
    } catch (e) {
      bugsnag.notify(new Error('[components-TextMore] -- [onTextLayout] -- [error]: ' + e.toString()));
    }
  };

  render (): Element<*> {
    const { NUM_OF_LINES, text } = this.props;
    const { showMore } = this.state;
    return (
      <View
        style={{
          flex: 1
        }}>
        <Text style={{ color: colors.black1, fontSize: fontSize.f28, lineHeight: lineHeight.l46, fontFamily: 'DINPro' }} numberOfLines={NUM_OF_LINES} onTextLayout={this.onTextLayout.bind(this)}>
          {text}
        </Text>
        {showMore
          ? (
                <Text
                  style={{
                    color: colors.purple,
                    fontSize: fontSize.f28,
                    marginTop: margin.m20,
                    lineHeight: lineHeight.l46
                  }}>
                  {I18n.t('viewAll')}
                </Text>
            )
          : null}

      </View>
    );
  }
};
