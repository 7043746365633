// @flow
/* eslint-disable no-case-declarations */
import AsyncStorage from '@react-native-async-storage/async-storage';
import persistReducer from 'redux-persist/es/persistReducer';
import { API_ENV } from '@env';
import { ActionTypes } from '../actions/ActionTypes';
import type { Reducer } from 'redux';
import type { PersistActionType, TraderStateType } from '../actions/common';
import type {
  TraderActionsType
} from '../actions/TraderAction';
import type { KolListInfo } from '../models/social';

const initialState: TraderStateType = {
  myMiscLoading: false,
  myMiscError: null,
  myMisc: {},
  mySettingLoading: false,
  mySettingError: null,
  mySetting: null,
  traderSetting: {},
  isLoading: false,
  loadingError: null,
  isLoadingMore: false,
  loadingMoreError: null,
  recommandList: [],
  historySearchList: [],
  favoriteObject: {},
  masterList: [],
  weekStarList: [],
  allTraderList: [],
  popularityList: [],
  traderUpdateTs: 0,
  rankUpdateTs: {},
  weekKey: '',
  token: null, // get more next token
  rankTokenInfo: {},
  weekList: [],
  newPeopleList: [],
  profitAmountList: [],
  assetsList: [],
  profitRateList: [],
  currencyList: '',
  pnlOrderMap: {},
  dcMaxTime: new Date().getTime(),
  maxTime: new Date().getTime(),
  entrustList: [],
  nextPage: false,
  nextOffset: 0,
  loadingMoreEntrust: false,
  entrustHistoryList: [],
  fiatKlineList: {},
  fiatKlineListChange: false,
  swapKlineList: {},
  swapKlineListChange: false,
  spotKlineList: {},
  spotKlineListChange: false,
  spotKlineListLoading: false,
  swapKlineListLoading: false,
  fiatKlineListLoading: false,
  spotKlineListError: false,
  swapKlineListError: false,
  fiatKlineListError: false,
  firstShowCustomChoices: true
};

export type PersistTraderActionType = TraderActionsType | PersistActionType;
type VoidTraderStateType = TraderStateType | void;

type KolItemsType = {
  masterList: KolListInfo[],
  weekList: KolListInfo[],
  recommandList: KolListInfo[],
  popularityList: KolListInfo[],
  newPeopleList: KolListInfo[],
  profitRateList: KolListInfo[],
  profitAmountList: KolListInfo[],
  assetsList: KolListInfo[]
};

/**
 * 修改本地kollist isFollow状态
 * @param kolCustomerId
 * @param copy
 * @param allKolList
 * @returns {}
 */
function triggerCopy (kolCustomerId: string, copy: boolean, allKolList: KolItemsType): KolItemsType {
  const finalItems: KolItemsType = {};
  const names = ['masterList', 'weekList', 'recommandList', 'popularityList', 'newPeopleList', 'profitRateList', 'profitAmountList', 'assetsList'];
  names.forEach((name: string, index: number, array: string[]) => {
    const l: KolListInfo[] = allKolList[name];
    if (l) {
      l.forEach((item: KolListInfo, idx: number, items: KolListInfo[]) => {
        if (item.customerId === kolCustomerId && item.isFollow !== copy) {
          item.isFollow = copy;
        }
      })
      finalItems[name] = l;
    }
  })
  return finalItems;
}

const traderReducer: Reducer<TraderStateType, PersistTraderActionType> = (state: VoidTraderStateType, action: PersistTraderActionType): TraderStateType => {
  if (action.type === ActionTypes.REHYDRATE) {
    console.log('get rehydrate return');
    return state ?? initialState;
  }
  if (!state) {
    return initialState;
  }
  switch (action.type) {
    // 获取全部数据
    case ActionTypes.TRADER_FETCH_DATA_REQUEST:
      return Object.assign({}, state, { isLoading: true });
    case ActionTypes.TRADER_FETCH_DATA:
      return !action.nodata
        ? Object.assign({}, state, {
          recommandList: action.recommandList,
          masterList: action.masterList,
          weekStarList: action.weekStarList,
          allTraderList: action.allTraderList,
          traderUpdateTs: new Date().getTime(),
          isLoading: false,
          token: action.token
        })
        : Object.assign({}, state, {
          isLoading: false
        });
    case ActionTypes.TRADER_FETCH_DATA_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        loadingError: action.error
      });
    case ActionTypes.TRADER_FETCH_DATA_RESET:
      return Object.assign({}, state, { loadingError: null });
    // 获取trader更多全部
    case ActionTypes.TRADER_FETCH_MORE_DATA_REQUEST:
      return Object.assign({}, state, { isLoadingMore: true });
    case ActionTypes.TRADER_FETCH_MORE_DATA:
      return !action.nodata
        ? Object.assign({}, state, {
          isLoadingMore: false,
          allTraderList: state.allTraderList.concat(action.allTraderList),
          traderUpdateTs: new Date().getTime(),
          token: action.token
        })
        : Object.assign({}, state, { isLoadingMore: false });
    case ActionTypes.TRADER_FETCH_MORE_DATA_ERROR:
      return Object.assign({}, state, {
        loadingMoreError: action.error,
        isLoadingMore: false
      });
    case ActionTypes.TRADER_FETCH_MORE_DATA_RESET:
      return Object.assign({}, state, { loadingMoreError: null });
    case ActionTypes.TRADER_MY_MISC_REQUEST:
      return Object.assign({}, state, { myMiscLoading: true });
    case ActionTypes.TRADER_MY_MISC_ERROR:
      return Object.assign({}, state, {
        myMiscLoading: false,
        myMiscError: action.error
      });
    case ActionTypes.TRADER_MY_MISC_RESET:
      return Object.assign({}, state, {
        myMiscLoading: false,
        myMiscError: null
      });
    case ActionTypes.TRADER_MY_MISC:
      return Object.assign({}, state, {
        myMiscLoading: false,
        myMisc: action.myMisc
      });
    case ActionTypes.TRADER_MY_SETTING_REQUEST:
      return Object.assign({}, state, { mySettingLoading: true });
    case ActionTypes.TRADER_MY_SETTING_ERROR:
      return Object.assign({}, state, {
        mySettingLoading: false,
        mySettingError: action.error
      });
    case ActionTypes.TRADER_MY_SETTING_RESET:
      return Object.assign({}, state, {
        mySettingLoading: false,
        mySettingError: null
      });
    case ActionTypes.TRADER_MY_SETTING:
      return Object.assign({}, state, {
        mySettingLoading: false,
        mySetting: action.mySetting
      });
    case ActionTypes.ACCOUNT_LOGOUT:
    case ActionTypes.TRADER_USER_INFO_CLEAN:
      return Object.assign({}, state, {
        myMisc: {},
        mySetting: null,
        traderSetting: {}
      });

    case ActionTypes.MARKET_FIAT_KLINE_REQUEST:
      return Object.assign({}, state, {
        fiatKlineListLoading: true,
        fiatKlineListChange: false
      });

    case ActionTypes.MARKET_FIAT_KLINE_RESET:
      return Object.assign({}, state, {
        fiatKlineListLoading: false,
        fiatKlineListError: null
      });

    case ActionTypes.MARKET_FIAT_KLINE_ERROR:
      return Object.assign({}, state, {
        fiatKlineListLoading: false,
        fiatKlineListError: action.error && action.error.message && action.error.message.indexOf('Network request failed') === -1 ? action.error : null
      });

    case ActionTypes.MARKET_FIAT_KLINE_DATA: {
      const obj = {};
      obj[action.symbol + '_' + action.resolution] = action.fiatKlineList;
      return Object.assign({}, state, {
        fiatKlineListChange: JSON.stringify(action.fiatKlineList) !== JSON.stringify(state.fiatKlineList[action.symbol + '_' + action.resolution]),
        fiatKlineList: Object.assign({}, state.fiatKlineList, obj),
        fiatKlineListLoading: false
      });
    }
    case ActionTypes.MARKET_SWAP_KLINE_REQUEST:
      return Object.assign({}, state, {
        swapKlineListLoading: true,
        swapKlineListChange: false
      });

    case ActionTypes.MARKET_SWAP_KLINE_RESET:
      return Object.assign({}, state, {
        swapKlineListLoading: false,
        swapKlineListError: null
      });

    case ActionTypes.MARKET_SWAP_KLINE_ERROR:
      return Object.assign({}, state, {
        swapKlineListLoading: false,
        swapKlineListError: action.error && action.error.message && action.error.message.indexOf('Network request failed') === -1 ? action.error : null
      });

    case ActionTypes.MARKET_SWAP_KLINE_DATA: {
      const obj1 = {};
      obj1[action.symbol + '_' + action.resolution] = action.swapKlineList;
      return Object.assign({}, state, {
        swapKlineListChange: JSON.stringify(action.swapKlineList) !== JSON.stringify(state.swapKlineList[action.symbol + '_' + action.resolution]),
        swapKlineList: Object.assign({}, state.swapKlineList, obj1),
        swapKlineListLoading: false
      });
    }
    case ActionTypes.MARKET_SPOT_KLINE_REQUEST:
      return Object.assign({}, state, {
        spotKlineListLoading: true,
        spotKlineListChange: false
      });

    case ActionTypes.MARKET_SPOT_KLINE_RESET:
      return Object.assign({}, state, {
        spotKlineListLoading: false,
        spotKlineListError: null
      });

    case ActionTypes.MARKET_SPOT_KLINE_ERROR:
      return Object.assign({}, state, {
        spotKlineListLoading: false,
        spotKlineListError: action.error && action.error.message && action.error.message.indexOf('Network request failed') === -1 ? action.error : null
      });

    case ActionTypes.MARKET_SPOT_KLINE_DATA: {
      const obj2 = {};
      obj2[action.symbol + '_' + action.resolution] = action.spotKlineList;
      return Object.assign({}, state, {
        spotKlineListChange: JSON.stringify(action.spotKlineList) !== JSON.stringify(state.spotKlineList[action.symbol + '_' + action.resolution]),
        spotKlineList: Object.assign({}, state.spotKlineList, obj2),
        spotKlineListLoading: false
      });
    }
    case ActionTypes.CFD_FETCH_ALL_PNL_ORDERS:
      return Object.assign({}, state, {
        pnlOrderMap: action.pnlOrderMap
      });
    // 获取排行全部数据
    case ActionTypes.TRADER_FETCH_RANK_DATA_REQUEST:
      return Object.assign({}, state, { isLoading: true });

    case ActionTypes.TRADER_FETCH_RANK_DATA: {
      const ts = state.rankUpdateTs;
      ts[action.temKey] = new Date().getTime();

      return !action.nodata
        ? Object.assign({}, state, {
          masterList: action.masterList,
          weekList:
            action.rankType === 'profitRate' &&
            action.options &&
            action.options.week
              ? action.rankList
              : state.weekList,
          recommandList:
            action.rankType === 'recommend'
              ? action.rankList
              : state.recommandList,
          popularityList:
            action.rankType === 'subscribe'
              ? action.rankList
              : state.popularityList,
          newPeopleList:
            action.rankType === 'new' ? action.rankList : state.newPeopleList,
          profitRateList:
            action.rankType === 'profitRate'
              ? action.rankList
              : state.profitRateList,
          profitAmountList:
            action.rankType === 'profitAmount'
              ? action.rankList
              : state.profitAmountList,
          assetsList:
            action.rankType === 'hotMoney'
              ? action.rankList
              : state.assetsList,
          rankUpdateTs: ts,
          weekKey: action.weekKey,
          isLoading: false,
          rankTokenInfo: action.rankTokenInfo
        })
        : Object.assign({}, state, {
          isLoading: false
        });
    }

    case ActionTypes.TRADER_FETCH_RANK_DATA_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        loadingError: action.error
      });
    case ActionTypes.TRADER_FETCH_RANK_DATA_RESET:
      return Object.assign({}, state, { loadingError: null });

    case ActionTypes.TRADER_COPY_TRIGGER:
      return Object.assign({}, state, triggerCopy(action.kolCustomerId, true, state));
    case ActionTypes.TRADER_STOP_COPY_TRIGGER:
      return Object.assign({}, state, triggerCopy(action.kolCustomerId, false, state));
    // 获取币列表数据
    case ActionTypes.TRADER_FETCH_CURRENCY_DATA_REQUEST:
      return Object.assign({}, state, { isLoading: true });
    case ActionTypes.TRADER_FETCH_CURRENCY_DATA:
      return !action.nodata
        ? Object.assign({}, state, {
          currencyList: action.currencyList,
          traderUpdateTs: new Date().getTime(),
          isLoading: false,
          token: action.token
        })
        : Object.assign({}, state, {
          isLoading: false
        });
    case ActionTypes.TRADER_FETCH_CURRENCY_DATA_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        loadingError: action.error
      });
    case ActionTypes.TRADER_FETCH_CURRENCY_DATA_RESET:
      return Object.assign({}, state, { loadingError: null });

    case ActionTypes.REVENUE_EVENT_TIME: {
      const maxTime = Math.max(action.maxTime ?? 0, state.maxTime);
      const dcMaxTime = Math.max(action.maxTime ?? 0, state.dcMaxTime);
      return Object.assign({}, state, {
        maxTime: maxTime,
        dcMaxTime: dcMaxTime
      });
    }

    case ActionTypes.TRADER_ENTRUST_DATA_REQUEST: {
      return Object.assign({}, state, {
        loadingMoreEntrust: true
      });
    }
    case ActionTypes.TRADER_ENTRUST_DATA: {
      let entrustList = state.entrustList;
      if (action.entrustList) {
        entrustList = action.entrustList;
      }
      let nextPage = state.nextPage;
      if (action.nextPage !== undefined) {
        nextPage = action.nextPage
      }
      let nextOffset = state.nextOffset;
      if (action.nextOffset !== undefined) {
        nextOffset = action.nextOffset
      }
      let loadingMoreEntrust = state.loadingMoreEntrust;
      if (action.loadingMoreEntrust !== undefined) {
        loadingMoreEntrust = action.loadingMoreEntrust;
      }
      const entrustHistoryList = action.entrustHistoryList;
      return Object.assign({}, state, {
        entrustList: entrustList,
        nextPage: nextPage,
        nextOffset: nextOffset ?? 0,
        loadingMoreEntrust: loadingMoreEntrust,
        entrustHistoryList: entrustHistoryList
      });
    }
    case ActionTypes.TRADER_ENTRUST_DATA_UPDATE: {
      return Object.assign({}, state, {
        entrustList: action.entrustList
      });
    }

    case ActionTypes.TRADER_HISTORY_SEARCH: {
      return Object.assign({}, state, {
        historySearchList: action.historySearchList
      });
    }
    case ActionTypes.FAVORITE_LIST_FETCH: {
      return Object.assign({}, state, {
        favoriteObject: action.favoriteObject
      });
    }
    case ActionTypes.TRADER_CUSTOM_FIRST_SHOW: {
      return Object.assign({}, state, {
        firstShowCustomChoices: action.firstShowCustomChoices
      });
    }
    default:
      return state;
  }
};

const traderPersistConfig = {
  key: 'trader',
  debug: API_ENV === 'prod' || API_ENV === 'pro',
  timeout: 10000,
  storage: AsyncStorage,
  blacklist: [
    'isLoading',
    'loadingError',
    'isLoadingMore',
    'loadingMoreError',
    'myMiscLoading',
    'myMiscError',
    'mySettingLoading',
    'mySettingError'
  ]
};

const persistTraderReducer: (TraderStateType, PersistTraderActionType) => TraderStateType = persistReducer<TraderStateType, PersistTraderActionType>(traderPersistConfig, traderReducer);

export default persistTraderReducer;
