// @flow
import config from '../utils/config';
import request from '../utils/request';
import helper from '../utils/helper';

import type { SocialResult, CustomerSimpleInfo } from './base';

export interface TopicItemType{
  pollOptionContents?: string,
  latestPostList?: [],
  symbolList?: [],
  pollEntry?: [],
  desc: string,
  hotValue: number,
  categoryId: string,
  pollContent?: string,
  categoryName?: string,
  status: number,
  authorId: string,
  postCount: number,
  viewCount: number,
  backgroundUrl: string,
  pollViewId: string,
  order: number,
  content: string,
  id: string,
  saasId: string
}
export interface FetchTopicCategoryItemType{
  topics?: TopicItemType[],
  status: number,
  authorId: string,
  desc: string,
  order: number,
  content: string,
  id: string,
  saasId: string
}
export interface PollOptionItemType{
  topics?: TopicItemType[],
  status: number,
  authorId: string,
  desc: string,
  order: number,
  content: string,
  id: string,
  saasId: string,

  like: boolean,
  viewId: string,
  text: string,
  likeCount: number
}

export interface PollEntryItemType{
  topics?: TopicItemType[],
  status: number,
  authorId: string,
  desc: string,
  order: number,
  content: string,
  id: string,
  saasId: string,
  pollOptions: PollOptionItemType[],
  likeCount: number
}

export interface FetchTopicDetailItemType{
  pollOptionContents: string,
  latestPostList: number, // 最新话题列表
  symbolList: string, // 相关盘口列表
  pollEntry: PollEntryItemType, // 投票类型
  desc: string,
  hotValue: number,
  categoryId: string,
  pollContent: string,
  categoryName: string, // 分类名称
  status: number,
  authorId: string,
  postCount: number, // 帖子数
  viewCount: number, // 浏览数
  backgroundUrl: string,
  pollViewId: string,
  order: number,
  content: string,
  id: string,
  saasId: string
}

export interface Feed {
  id: string,
  syncId?: string,
  targetId?: string,
  actor: string,
  verb: string,
  time: string,
  images: string[],
  videos?: string[],
  videoImages?: string[],
  hashes?: string[],
  text?: string,
  likeCount?: number,
  shareCount?: number,
  commentCount?: number,
  replyCount?: number,
  favoriteCount?: number,
  isHot?: boolean,
  like?: boolean,
  customerMisc: CustomerSimpleInfo,
  targetCustomerMisc: CustomerSimpleInfo,
  favorite?: boolean,
  timestamp?: number,
  replies?: Feed[],
  comments?: Feed[],
  link?: string,
  viewCount?: number
}
export interface FetchTopicAllItemResponseType{
  pollOptionContents: string,
  latestPostList: number, // 最新话题列表
  symbolList: string, // 相关盘口列表
  pollEntry: PollEntryItemType, // 投票类型
  desc: string,
  hotValue: number,
  categoryId: string,
  pollContent: string,
  categoryName: string, // 分类名称
  status: number,
  authorId: string,
  postCount: number, // 帖子数
  viewCount: number, // 浏览数
  backgroundUrl: string,
  pollViewId: string,
  order: number,
  content: string,
  id: string,
  saasId: string,
  latestPostListItem: Feed[],

  topicId?: string // 构造数据使用
}
export interface TopicFeedItemResponseType{
  pollOptionContents: string,
  latestPostList: number, // 最新话题列表
  symbolList: string, // 相关盘口列表
  pollEntry: PollEntryItemType, // 投票类型
  desc: string,
  hotValue: number,
  categoryId: string,
  pollContent: string,
  categoryName: string, // 分类名称
  status: number,
  authorId: string,
  postCount: number, // 帖子数
  viewCount: number, // 浏览数
  backgroundUrl: string,
  pollViewId: string,
  order: number,
  content: string,
  id: string,
  saasId: string,
  latestPostListItem: Feed[],

  topicId?: string // 构造数据使用
}

export type FetchTopicCategoryResponseType = SocialResult<FetchTopicCategoryItemType[]>;
export type FetchTopicDetailResponseType = SocialResult<FetchTopicDetailItemType>;
export type FetchTopicAllResponseType = SocialResult<FetchTopicAllItemResponseType[]>;
export type TopicFeedResponseType = SocialResult<TopicFeedItemResponseType[]>;
// class definition start

class TopicApi {
  /**
   * fetch favorite recommend
   * @param null
   * @returns {*}
   */
  topicCategory (componentToken: string): Promise<FetchTopicCategoryResponseType> {
    const URL = config.api.host + config.api.social.topicCategory;
    return request.get.call({}, URL, { Authorization: componentToken });
  };

  topicAll (componentToken: string): Promise<FetchTopicAllResponseType> {
    const URL = config.api.host + config.api.social.topicAll + '?' + helper.mapToQueryString({ limit: 6 });
    return request.get.call({}, URL, { Authorization: componentToken });
  };

  topicPostFeed (params: {topicId: string, type: string, id_lte?: string, id_lt?: string, nextToken?: string }, componentToken: string): Promise<TopicFeedResponseType> {
    // type: hot| newest
    const URL = config.api.host + config.api.social.topicPostFeed.replace('{topicId}', params.topicId) + `/${params.type}` + '?' +
    helper.mapToQueryString(params);
    return request.get.call({}, URL, { Authorization: componentToken });
  };

  topicDetail (params: {topicId: string}, componentToken: string): Promise<FetchTopicDetailResponseType> {
    const URL = config.api.host + config.api.social.topicDetail.replace('{topicId}', params.topicId) +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL, { Authorization: componentToken });
  };
}

const TopicApI: TopicApi = new TopicApi();
export { TopicApI };
