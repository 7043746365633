// @flow
import React, { Component } from 'react';
import type { Element } from 'react';
import { Platform } from 'react-native';
import p from '../utils/Transfrom';
import { Circle, Rect, defaultProps } from 'react-content-loader/native';
import type { CombinedReducerType, DispatchProps } from '../reducers';
import FastImage from 'react-native-fast-image';

const ContentLoaderModule = require('react-content-loader/native')

let ContentLoader: React$ComponentType<typeof defaultProps>;

if (Platform.OS === 'web') {
  ContentLoader = ContentLoaderModule.ContentLoader
} else {
  ContentLoader = ContentLoaderModule.default
}

type OwnPropType = {

};
type StateType = {

};

type PropsType = OwnPropType & CombinedReducerType & DispatchProps;

export class DiscoverContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        height={p(350)}
        width={p(1100)}
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">

        <Rect
          x={p(30)}
          y={p(30)}
          rx={p(19)}
          ry={p(19)}
          width={p(220)}
          height={p(38)}
        />
        <Rect
          x={p(30)}
          y={p(100)}
          rx={p(19)}
          ry={p(19)}
          width={p(192)}
          height={p(248)}
        />
        <Rect
          x={p(242)}
          y={p(100)}
          rx={p(19)}
          ry={p(19)}
          width={p(192)}
          height={p(248)}
        />
        <Rect
          x={p(454)}
          y={p(100)}
          rx={p(19)}
          ry={p(19)}
          width={p(192)}
          height={p(248)}
        />
        <Rect
          x={p(666)}
          y={p(100)}
          rx={p(19)}
          ry={p(19)}
          width={p(192)}
          height={p(248)}
        />

      </ContentLoader>
    );
  }
}

export class FollowOrderNowContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        height={p(414)}
        width={p(690)}
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">
        <Circle cx={p(28)} cy={p(28)} r={p(28)} x={p(30)} y={p(30)} />

        <Rect
          x={p(106)}
          y={p(45)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(25)}
        />

        <Rect
          x={p(30)}
          y={p(120)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
        <Rect
          x={p(30)}
          y={p(150)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />

        <Rect
          x={p(270)}
          y={p(120)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
        <Rect
          x={p(270)}
          y={p(150)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />

        <Rect
          x={p(560)}
          y={p(120)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
        <Rect
          x={p(560)}
          y={p(150)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />

        <Rect
          x={p(30)}
          y={p(240)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
        <Rect
          x={p(560)}
          y={p(240)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />

        <Rect
          x={p(30)}
          y={p(306)}
          rx={p(34)}
          ry={p(34)}
          width={p(200)}
          height={p(68)}
        />
        <Rect
          x={p(322)}
          y={p(306)}
          rx={p(34)}
          ry={p(34)}
          width={p(200)}
          height={p(68)}
        />
        <Rect
          x={p(552)}
          y={p(306)}
          rx={p(34)}
          ry={p(34)}
          width={p(108)}
          height={p(68)}
        />
      </ContentLoader>
    );
  }
}

export class RewardRecordContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        height={p(88)}
        width={p(710)}
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">
        <Rect
          x={p(30)}
          y={p(28)}
          rx={p(6)}
          ry={p(6)}
          width={p(200)}
          height={p(25)}
        />

        <Rect
          x={p(590)}
          y={p(28)}
          rx={p(6)}
          ry={p(6)}
          width={p(120)}
          height={p(25)}
        />
      </ContentLoader>
    );
  }
}

export class DepositRecordContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        height={p(112)}
        width={p(710)}
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">
        <Rect
          x={p(30)}
          y={p(38)}
          rx={p(6)}
          ry={p(6)}
          width={p(200)}
          height={p(25)}
        />

        <Rect
          x={p(590)}
          y={p(38)}
          rx={p(6)}
          ry={p(6)}
          width={p(120)}
          height={p(25)}
        />
      </ContentLoader>
    );
  }
}

export class InviteRecordContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        height={p(112)}
        width={p(710)}
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">
        <Rect
          x={p(30)}
          y={p(38)}
          rx={p(6)}
          ry={p(6)}
          width={p(200)}
          height={p(25)}
        />

        <Rect
          x={p(590)}
          y={p(38)}
          rx={p(6)}
          ry={p(6)}
          width={p(120)}
          height={p(25)}
        />
      </ContentLoader>
    );
  }
}

export class FollowOrderHistoryContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        height={p(200)}
        width={p(690)}
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">
        <Circle cx={p(28)} cy={p(28)} r={p(28)} x={p(30)} y={p(30)} />

        <Rect
          x={p(106)}
          y={p(45)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(25)}
        />

        <Rect
          x={p(560)}
          y={p(45)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(25)}
        />

        <Rect
          x={p(30)}
          y={p(140)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
        <Rect
          x={p(560)}
          y={p(140)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
      </ContentLoader>
    );
  }
}
export class KOLFollowersContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        height={p(200)}
        width={p(710)}
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">
        <Circle cx={p(28)} cy={p(28)} r={p(28)} x={p(30)} y={p(30)} />

        <Rect
          x={p(106)}
          y={p(45)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(25)}
        />

        <Rect
          x={p(30)}
          y={p(120)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
        <Rect
          x={p(30)}
          y={p(150)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />

        <Rect
          x={p(270)}
          y={p(120)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
        <Rect
          x={p(270)}
          y={p(150)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />

        <Rect
          x={p(540)}
          y={p(120)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
        <Rect
          x={p(540)}
          y={p(150)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
      </ContentLoader>
    );
  }
}

export class TraderFansContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        height={p(200)}
        width={p(710)}
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">
        <Circle cx={p(28)} cy={p(28)} r={p(28)} x={p(30)} y={p(30)} />

        <Rect
          x={p(106)}
          y={p(45)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(25)}
        />

        <Rect
          x={p(30)}
          y={p(120)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
        <Rect
          x={p(30)}
          y={p(150)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />

        <Rect
          x={p(270)}
          y={p(120)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
        <Rect
          x={p(270)}
          y={p(150)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />

        <Rect
          x={p(540)}
          y={p(120)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
        <Rect
          x={p(540)}
          y={p(150)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
      </ContentLoader>
    );
  }
}

export class TraderPositionCardContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        height={p(362)}
        width={p(710)}
        speed={1}
        primaryColor="#fff"
        secondaryColor="#f1f1f1">
        <Rect
          x={p(30)}
          y={p(40)}
          rx={p(12)}
          ry={p(12)}
          width={p(150)}
          height={p(25)}
        />
        <Rect
          x={p(530)}
          y={p(40)}
          rx={p(12)}
          ry={p(12)}
          width={p(150)}
          height={p(25)}
        />

        <Rect
          x={p(30)}
          y={p(164)}
          rx={p(12)}
          ry={p(12)}
          width={p(150)}
          height={p(25)}
        />
        <Rect
          x={p(330)}
          y={p(164)}
          rx={p(12)}
          ry={p(12)}
          width={p(150)}
          height={p(25)}
        />

        <Rect
          x={p(30)}
          y={p(263)}
          rx={p(12)}
          ry={p(12)}
          width={p(100)}
          height={p(60)}
        />
        <Rect
          x={p(220)}
          y={p(263)}
          rx={p(12)}
          ry={p(12)}
          width={p(100)}
          height={p(60)}
        />
        <Rect
          x={p(412)}
          y={p(263)}
          rx={p(12)}
          ry={p(12)}
          width={p(100)}
          height={p(60)}
        />
        <Rect
          x={p(580)}
          y={p(263)}
          rx={p(12)}
          ry={p(12)}
          width={p(100)}
          height={p(60)}
        />
      </ContentLoader>
    );
  }
}

export class ContractItemContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        height={p(124)}
        width={p(690)}
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">
        <Rect
          x="0"
          y={p(36)}
          rx={p(8)}
          ry={p(8)}
          width={p(130)}
          height={p(52)}
        />
        <Rect
          x={p(330)}
          y={p(36)}
          rx={p(8)}
          ry={p(8)}
          width={p(120)}
          height={p(52)}
        />
        <Rect
          x={p(570)}
          y={p(36)}
          rx={p(8)}
          ry={p(8)}
          width={p(120)}
          height={p(52)}
        />
      </ContentLoader>
    );
  }
}

export class PostItemContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb"
        height={p(300)}
        width={p(630)}>
        <Circle cx={p(40)} cy={p(40)} r={p(40)} x={p(0)} y={p(36)} />
        <Rect
          x={p(100)}
          y={p(56)}
          rx={p(6)}
          ry={p(6)}
          width={p(200)}
          height={p(12)}
        />
        <Rect
          x={p(100)}
          y={p(80)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(12)}
        />

        <Rect
          x={p(0)}
          y={p(160)}
          rx={p(6)}
          ry={p(6)}
          width={p(630)}
          height={p(15)}
        />
        <Rect
          x={p(0)}
          y={p(195)}
          rx={p(6)}
          ry={p(6)}
          width={p(630)}
          height={p(15)}
        />
        <Rect
          x={p(0)}
          y={p(230)}
          rx={p(6)}
          ry={p(6)}
          width={p(300)}
          height={p(15)}
        />
      </ContentLoader>
    );
  }
}

export class NewsItemContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        speed={1}
        primaryColor="#F6F5FA"
        secondaryColor="#F1F1F1"
        height={p(152)}
        style={{ position: 'relative' }}
        width={p(650)}>

        <Rect
          x={p(0)}
          y={p(0)}
          rx={p(15)}
          ry={p(15)}
          width={p(416)}
          height={p(28)}
        />
        <Rect
          x={p(0)}
          y={p(50)}
          rx={p(15)}
          ry={p(15)}
          width={p(416)}
          height={p(28)}
        />
        <Rect
          x={p(0)}
          y={p(125)}
          rx={p(6)}
          ry={p(6)}
          width={p(120)}
          height={p(14)}
        />
        <FastImage source={require('../images/kiki_preload.webp')} style={{ position: 'absolute', right: 0, top: 0, width: p(200), height: p(152) }}/>
      </ContentLoader>
    );
  }
}

export class KlineContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        speed={1}
        primaryColor="#353C4C"
        secondaryColor="#555E76"
        height={p(970)}
        width={p(690)}>
        <Rect
          x={p(0)}
          y={p(0)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(30)}
        />
        <Rect
          x={p(200)}
          y={p(0)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(30)}
        />
        <Rect
          x={p(400)}
          y={p(0)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(30)}
        />
        <Rect
          x={p(600)}
          y={p(0)}
          rx={p(6)}
          ry={p(6)}
          width={p(90)}
          height={p(30)}
        />

        <Rect
          x={p(0)}
          y={p(800)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(70)}
        />
        <Rect
          x={p(30)}
          y={p(760)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(110)}
        />
        <Rect
          x={p(60)}
          y={p(700)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(170)}
        />
        <Rect
          x={p(90)}
          y={p(600)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(270)}
        />
        <Rect
          x={p(120)}
          y={p(760)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(110)}
        />
        <Rect
          x={p(150)}
          y={p(830)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(40)}
        />

        <Rect
          x={p(180)}
          y={p(800)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(70)}
        />
        <Rect
          x={p(210)}
          y={p(810)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(60)}
        />
        <Rect
          x={p(240)}
          y={p(790)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(80)}
        />
        <Rect
          x={p(270)}
          y={p(780)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(90)}
        />
        <Rect
          x={p(300)}
          y={p(800)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(70)}
        />
        <Rect
          x={p(330)}
          y={p(820)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(50)}
        />

        <Rect
          x={p(360)}
          y={p(800)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(70)}
        />
        <Rect
          x={p(390)}
          y={p(760)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(110)}
        />
        <Rect
          x={p(420)}
          y={p(700)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(170)}
        />
        <Rect
          x={p(450)}
          y={p(600)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(270)}
        />
        <Rect
          x={p(480)}
          y={p(760)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(110)}
        />
        <Rect
          x={p(510)}
          y={p(830)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(40)}
        />
        <Rect
          x={p(540)}
          y={p(600)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(270)}
        />
        <Rect
          x={p(570)}
          y={p(760)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(110)}
        />
        <Rect
          x={p(600)}
          y={p(830)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(40)}
        />
        <Rect
          x={p(630)}
          y={p(820)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(50)}
        />
        <Rect
          x={p(660)}
          y={p(800)}
          rx={p(0)}
          ry={p(0)}
          width={p(30)}
          height={p(70)}
        />
      </ContentLoader>
    );
  }
}

export class NoticeItemContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb"
        height={p(250)}
        width={p(690)}>
        <Circle cx={p(40)} cy={p(40)} r={p(40)} x={p(0)} y={p(36)} />
        <Rect
          x={p(100)}
          y={p(56)}
          rx={p(6)}
          ry={p(6)}
          width={p(200)}
          height={p(15)}
        />
        <Rect
          x={p(100)}
          y={p(90)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />

        <Rect
          x={p(0)}
          y={p(150)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <Rect
          x={p(0)}
          y={p(190)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
      </ContentLoader>
    );
  }
}
export class FollowItemContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb"
        height={p(136)}
        width={p(690)}>
        <Circle cx={p(40)} cy={p(40)} r={p(40)} x={p(0)} y={p(28)} />
        <Rect
          x={p(100)}
          y={p(46)}
          rx={p(6)}
          ry={p(6)}
          width={p(200)}
          height={p(15)}
        />
        <Rect
          x={p(100)}
          y={p(82)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
        <Rect
          x={p(578)}
          y={p(44)}
          rx={p(26)}
          ry={p(26)}
          width={p(112)}
          height={p(48)}
        />
      </ContentLoader>
    );
  }
}

export class PostDetailItemContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb"
        height={p(800)}
        width={p(690)}>
        <Circle cx={p(40)} cy={p(40)} r={p(40)} x={p(0)} y={p(36)} />
        <Rect
          x={p(100)}
          y={p(56)}
          rx={p(6)}
          ry={p(6)}
          width={p(200)}
          height={p(15)}
        />
        <Rect
          x={p(100)}
          y={p(90)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />

        <Rect
          x={p(0)}
          y={p(200)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <Rect
          x={p(0)}
          y={p(250)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <Rect
          x={p(0)}
          y={p(300)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <Rect
          x={p(0)}
          y={p(350)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <Rect
          x={p(0)}
          y={p(400)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <Rect
          x={p(0)}
          y={p(450)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <Rect
          x={p(0)}
          y={p(500)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <Rect
          x={p(0)}
          y={p(550)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <Rect
          x={p(0)}
          y={p(600)}
          rx={p(6)}
          ry={p(6)}
          width={p(300)}
          height={p(20)}
        />
      </ContentLoader>
    );
  }
}

export class StarCardContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        speed={1}
        width={p(240)}
        height={p(260)}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">
        <Rect
          x="0"
          y="0"
          rx={p(12)}
          ry={p(12)}
          width={p(240)}
          height={p(260)}
        />
      </ContentLoader>
    );
  }
}

export class AreaChartContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        speed={1}
        width={p(710)}
        height={p(400)}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">
        <Rect
          x={p(300)}
          y={p(300)}
          rx={p(12)}
          ry={p(12)}
          width={p(20)}
          height={p(100)}
        />
        <Rect
          x={p(330)}
          y={p(250)}
          rx={p(12)}
          ry={p(12)}
          width={p(20)}
          height={p(150)}
        />
        <Rect
          x={p(360)}
          y={p(200)}
          rx={p(12)}
          ry={p(12)}
          width={p(20)}
          height={p(200)}
        />
        <Rect
          x={p(390)}
          y={p(220)}
          rx={p(12)}
          ry={p(12)}
          width={p(20)}
          height={p(180)}
        />
        <Rect
          x={p(420)}
          y={p(250)}
          rx={p(12)}
          ry={p(12)}
          width={p(20)}
          height={p(150)}
        />

      </ContentLoader>
    );
  }
}

export class ExploreDataCardContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        speed={1}
        width={p(315)}
        height={p(330)}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">
        <Rect
          x={p(30)}
          y={p(30)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(20)}
        />
        <Rect
          x={p(30)}
          y={p(80)}
          rx={p(6)}
          ry={p(6)}
          width={p(200)}
          height={p(20)}
        />

        <Rect
          x={p(30)}
          y={p(130)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(20)}
        />

        <Rect
          x={p(100)}
          y={p(280)}
          rx={p(6)}
          ry={p(6)}
          width={p(110)}
          height={p(20)}
        />
      </ContentLoader>
    );
  }
}

export class ExploreDataBTCLiquidatationContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        speed={1}
        width={p(690)}
        height={p(48)}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">
        <Rect
          x={p(0)}
          y={p(14)}
          rx={p(6)}
          ry={p(6)}
          width={p(48)}
          height={p(20)}
        />
        <Rect
          x={p(68)}
          y={p(14)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(20)}
        />
        <Rect
          x={p(190)}
          y={p(14)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(20)}
        />
        <Rect
          x={p(320)}
          y={p(14)}
          rx={p(6)}
          ry={p(6)}
          width={p(200)}
          height={p(20)}
        />
        <Rect
          x={p(590)}
          y={p(14)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(20)}
        />
      </ContentLoader>
    );
  }
}

export class MarketItemComponentContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        speed={1}
        width={p(690)}
        height={p(180)}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">
        <Rect
          x={p(0)}
          y={p(58)}
          rx={p(64)}
          ry={p(64)}
          width={p(64)}
          height={p(64)}
        />

        <Rect
          x={p(94)}
          y={p(80)}
          rx={p(6)}
          ry={p(6)}
          width={p(130)}
          height={p(30)}
        />

        <Rect
          x={p(300)}
          y={p(80)}
          rx={p(6)}
          ry={p(6)}
          width={p(130)}
          height={p(30)}
        />

        <Rect
          x={p(510)}
          y={p(80)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(30)}
        />

      </ContentLoader>
    );
  }
}

export class SpotOrdersContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        speed={1}
        width={p(630)}
        height={p(240)}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">
        <Rect
          x={p(0)}
          y={p(20)}
          rx={p(6)}
          ry={p(6)}
          width={p(150)}
          height={p(30)}
        />
        <Rect
          x={p(450)}
          y={p(20)}
          rx={p(6)}
          ry={p(6)}
          width={p(150)}
          height={p(30)}
        />

        <Rect
          x={p(0)}
          y={p(100)}
          rx={p(6)}
          ry={p(6)}
          width={p(250)}
          height={p(20)}
        />
        <Rect
          x={p(350)}
          y={p(100)}
          rx={p(6)}
          ry={p(6)}
          width={p(250)}
          height={p(20)}
        />

        <Rect
          x={p(0)}
          y={p(160)}
          rx={p(6)}
          ry={p(6)}
          width={p(250)}
          height={p(20)}
        />
        <Rect
          x={p(350)}
          y={p(160)}
          rx={p(6)}
          ry={p(6)}
          width={p(250)}
          height={p(20)}
        />

        <Rect
          x={p(0)}
          y={p(220)}
          rx={p(6)}
          ry={p(6)}
          width={p(250)}
          height={p(20)}
        />
        <Rect
          x={p(350)}
          y={p(220)}
          rx={p(6)}
          ry={p(6)}
          width={p(250)}
          height={p(20)}
        />

      </ContentLoader>
    );
  }
}
export class TopicSelectionContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        speed={1}
        width={p(700)}
        height={p(240)}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb"
      >
        <Rect
          x={p(30)}
          y={p(0)}
          rx={p(25)}
          ry={p(25)}
          width={p(350)}
          height={p(52)}

        />
        <Rect
          x={p(30)}
          y={p(25 + 50)}
          rx={p(25)}
          ry={p(25)}
          width={p(350)}
          height={p(52)}
        />
        <Rect
          x={p(400)}
          y={p(25 + 50)}
          rx={p(25)}
          ry={p(25)}
          width={p(300)}
          height={p(52)}
        />

        <Rect
          x={p(30)}
          y={p(25 + 77 + 50)}
          rx={p(25)}
          ry={p(25)}
          width={p(250)}
          height={p(52)}
        />
        <Rect
          x={p(300)}
          y={p(25 + 77 + 50)}
          rx={p(25)}
          ry={p(25)}
          width={p(350)}
          height={p(52)}
        />
      </ContentLoader>
    );
  }
}

export class FlexEarningsContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        speed={1}
        width={p(630)}
        height={p(240)}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">
        <Rect
          x={p(0)}
          y={p(20)}
          rx={p(6)}
          ry={p(6)}
          width={p(150)}
          height={p(20)}
        />
        <Rect
          x={p(450)}
          y={p(20)}
          rx={p(6)}
          ry={p(6)}
          width={p(150)}
          height={p(20)}
        />

        <Rect
          x={p(0)}
          y={p(80)}
          rx={p(6)}
          ry={p(6)}
          width={p(150)}
          height={p(20)}
        />
        <Rect
          x={p(450)}
          y={p(80)}
          rx={p(6)}
          ry={p(6)}
          width={p(150)}
          height={p(20)}
        />

        <Rect
          x={p(0)}
          y={p(200)}
          rx={p(6)}
          ry={p(6)}
          width={p(200)}
          height={p(20)}
        />
        <Rect
          x={p(400)}
          y={p(200)}
          rx={p(6)}
          ry={p(6)}
          width={p(200)}
          height={p(20)}
        />

      </ContentLoader>
    );
  }
}

export class AutoBuyEarningsContentLoading extends Component <PropsType, StateType> {
  render (): Element<*> {
    return (
      <ContentLoader
        speed={1}
        width={p(630)}
        height={p(240)}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb">
        <Rect
          x={p(0)}
          y={p(20)}
          rx={p(6)}
          ry={p(6)}
          width={p(150)}
          height={p(20)}
        />
        <Rect
          x={p(450)}
          y={p(20)}
          rx={p(6)}
          ry={p(6)}
          width={p(150)}
          height={p(20)}
        />

        <Rect
          x={p(0)}
          y={p(80)}
          rx={p(6)}
          ry={p(6)}
          width={p(150)}
          height={p(20)}
        />
        <Rect
          x={p(450)}
          y={p(80)}
          rx={p(6)}
          ry={p(6)}
          width={p(150)}
          height={p(20)}
        />

        <Rect
          x={p(400)}
          y={p(200)}
          rx={p(6)}
          ry={p(6)}
          width={p(200)}
          height={p(20)}
        />

      </ContentLoader>
    );
  }
}
