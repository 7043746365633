// @flow
import apiPre from './api_pre';
import apiBeta from './api_beta';
import apiPro from './api_pro';

import { API_ENV } from '@env';
import {
  Platform
} from 'react-native';

let env = API_ENV
console.log(__DEV__)
if (__DEV__) {
  env = 'beta'
}
// console.log(API_ENV);
interface API {
  host: string,
  host_newapi: string,
  host_api_v1: string,
  kline_url: string,
  supportUrl: string,
  profit_chart_url: string,
  explore_areaChart_url: string,
  explore_compareLineChart_url: string,
  explore_stackBarChart_url: string,
  explore_VSChart_url: string,
  explore_analysisChart_url: string,
  liveness_react_app_url: string,
  market_chart_url: string,
  explore_areaChart_remote_url: string,
  upload_api: string,
  share: string,
  kiki_website_url: string,
  simple_kline_host: string,
  google_web_clientId: string,
  new_simple_kline_host?: string
}

let api: API;
let websocket: {host: string, appCode: string, stage: 'TEST' | 'RELEASE'} = {
  host: 'ws://wstest.kikitrade.com:8080',
  appCode: 'c022929d0e1f4b0d8eace9cb9934bf0d',
  stage: 'TEST'
};

if (env === 'local' && Platform.OS === 'web') {
  env = 'test'
}

switch (env) {
  case 'local':
  case 'dev':
    api = {
      // host:'http://172.30.30.94:8888/',
      // host: 'http://172.30.30.107:8888/',
      // host: 'http://172.30.30.133:8081/',
      // host: 'http://172.30.88.208:3000/',
      profit_chart_url:
        'https://upload.kikitrade.com/kikitrade_profit_chart/index.html',
      // host: 'http://api.dev.dipbit.xyz:8193/',
      host: 'http://192.168.20.67:8082/api/',
      // host_newapi: 'http://172.30.30.17:8070/',
      kiki_website_url: 'http://m.dipbit.xyz/',
      // host_api_v1: 'http://api.dev.dipbit.xyz:8081/',
      // host_newapi: 'http://api.dev.dipbit.xyz:8081/',
      host_newapi: 'http://192.168.20.67:8082/newapi/',
      host_api_v1: 'http://192.168.20.67:8082/newapi/',

      // host: 'http://api.dipbit.xyz:8191/',
      // host_newapi: 'http://api.dipbit.xyz:8080/',
      // host_api_v1: 'http://api.dipbit.xyz:8081/',

      supportUrl: 'https://cschat.antcloud.com.cn/index.htm?tntInstId=1Ab_9Aku&scene=SCE00941738', // https://cschat.antcloud.com.cn/index.htm?tntInstId=1Ab_9Aku&scene=SCE00529069'
      upload_api: 'https://kiki-beta.oss-ap-southeast-1.aliyuncs.com/',
      explore_areaChart_url: 'http://172.30.90.132:8083/areaChart.html',
      explore_compareLineChart_url: 'http://172.30.90.132:8083/compareLineChart.html',
      market_chart_url: 'https://upload.kikitrade.com/kikitrade_chart/spot.html',
      explore_stackBarChart_url: 'http://172.30.90.132:8083/stackBarChart.html',
      explore_VSChart_url: 'http://172.30.90.132:8083/vs.html',
      explore_analysisChart_url: 'http://172.30.90.132:8083/analysis.html',
      explore_areaChart_remote_url: 'https://chart.kikitrade.com/kiki_chart_node',
      liveness_react_app_url: 'https://upload.kikitrade.com/liveness_react_app/index.html',
      kline_url: 'https://upload.kikitrade.com/tradingview_dark_theme/index.html',
      share: 'https://share.kikitrade.com/',
      simple_kline_host: 'https://api.kikitrade.com/cfd/kline/init',
      google_web_clientId: '389380007773-8qrs1its8dptlcme0agi2759bvhoj3pu.apps.googleusercontent.com'
    };
    break;
  case 'beta':
    api = apiBeta;
    break;
  case 'pre':
    api = apiPre;
    break;
  case 'pro':
  case 'prod':
  default:
    api = apiPro;
    websocket = {
      host: 'ws://ws.kikitrade.com:8080',
      appCode: 'c022929d0e1f4b0d8eace9cb9934bf0d',
      stage: 'RELEASE'
    };
    break;
}

module.exports = {
  api: api,
  websocket
};
// }
