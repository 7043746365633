// @flow
import config from '../utils/config';
import request from '../utils/request';
import helper from '../utils/helper';
import type { CommonResponse, ExchangeResult } from './base';

// model start
export interface Kline{
  bars: string[], // timestamp, close, open, high, low, volume
  meta: ?{
    noData: ?boolean,
    nextTime: ?number
  }
}

export type KlineListType = {
  [coinCode: string]: string[]
};

export type KlineResponseType = ExchangeResult<Kline> & CommonResponse;

// KlineApi start

class KlineApi {
  simpleKlineData (params: {symbol: string, resolution: string, from: number, to: number}): Promise<KlineResponseType> {
    const URL = config.api.simple_kline_host +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  newSimpleKlineData (params: {symbol: string, resolution: string, from: number, to: number}): Promise<KlineResponseType> {
    const URL = (config.api.new_simple_kline_host ?? '') +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };
}

const KlineAPI: KlineApi = new KlineApi();
export { KlineAPI };
