// @flow
import AsyncStorage from '@react-native-async-storage/async-storage';
import persistReducer from 'redux-persist/es/persistReducer';
import { ActionTypes } from '../actions/ActionTypes';
import { API_ENV } from '@env';
import type { Reducer } from 'redux';
import type { PersistActionType, SocialStateType } from '../actions/common';
import type { SocialActionsType } from '../actions/SocialAction';
import { readonlyToken } from '../api/base';

const initialState: SocialStateType = {
  retrieveReady: false,
  socialToken: readonlyToken,
  socialAnonymous: true,
  socialUserInfo: null,
  socialUserNotExist: false,
  tokenUpdateTs: 0,
  hotTopic: [],
  hotPoll: [],
  newsData: {},
  recomendPostData: {},
  longShortData: [],
  longShortDataLoading: false,
  userId: null,
  longTextData: ''
};

export type PersistSocialActionType = SocialActionsType | PersistActionType;
type VoidSocialStateType = SocialStateType | void;
const socialReducer: Reducer<SocialStateType, PersistSocialActionType> = (state: VoidSocialStateType, action: PersistSocialActionType): SocialStateType => {
  if (action.type === ActionTypes.REHYDRATE) {
    console.log('get rehydrate return');
    return state ?? initialState;
  }
  if (!state) {
    return initialState;
  }
  switch (action.type) {
    // 获取全部
    case ActionTypes.SOCIAL_TOKEN_REFRESH:
      return Object.assign({}, state, {
        socialToken: action.socialToken || readonlyToken,
        retrieveReady: action.socialUserInfo != null, // 获取数据的时候，如果没有socialUserInfo， 则默认not ready
        socialAnonymous: false,
        socialUserInfo: action.socialUserInfo ? action.socialUserInfo : state.socialUserInfo, // 刷新token的售后，如果userInfo 不存在， 则不进行修改，防止网络问题导致的异常
        socialUserNotExist: (action.socialUserNotExist === undefined) ? false : action.socialUserNotExist,
        tokenUpdateTs: action.tokenUpdateTs,
        userId: action.userId
      });
    case ActionTypes.ACCOUNT_LOST_SESSION:
      global.USER_ID = '';
      return Object.assign({}, state, {
        retrieveReady: false,
        socialToken: readonlyToken,
        socialUserInfo: null,
        socialAnonymous: true,
        tokenUpdateTs: action.tokenUpdateTs,
        userId: null
      });
    case ActionTypes.SOCIAL_TOKEN_ANONYMOUS_REFRESH:
      return Object.assign({}, state, {
        retrieveReady: false,
        socialToken: action.socialToken || readonlyToken,
        socialUserInfo: action.socialUserInfo,
        socialUserNotExist: (action.socialUserNotExist === undefined) ? false : action.socialUserNotExist,
        socialAnonymous: true,
        tokenUpdateTs: action.tokenUpdateTs,
        userId: null
      });
    case ActionTypes.SOCIAL_HOTTOPIC_FETCH:
      return Object.assign({}, state, {
        hotTopic: action.hotTopic
      });
    case ActionTypes.SOCIAL_HOTPOLL_FETCH:
      return Object.assign({}, state, {
        hotPoll: action.hotPoll
      });
    case ActionTypes.SOCIA_NEWS_FETCH:
      return Object.assign({}, state, {
        newsData: action.newsData
      });
    case ActionTypes.RECOMMEND_POST_FETCH:
      return Object.assign({}, state, {
        recomendPostData: action.recomendPostData
      });
    case ActionTypes.EXPLORE_LONGSHORT_FETCH:
      console.log(action)
      return Object.assign({}, state, {
        longShortData: action.longShortData,
        longShortDataLoading: false
      });
    case ActionTypes.EXPLORE_LONGSHORT_REQUEST:
      return Object.assign({}, state, {
        longShortDataLoading: true
      });
    case ActionTypes.EXPLORE_LONGSHORT_ERROR:
      return Object.assign({}, state, {
        longShortDataLoading: false
      });
    case ActionTypes.ACCOUNT_LOGOUT:
    case ActionTypes.SOCIAL_USER_INFO_CLEAN:
      return Object.assign({}, initialState, { longShortData: state.longShortData, hotTopic: state.hotTopic, newsData: state.newsData, hotPoll: state.hotPoll, recomendPostData: state.recomendPostData });
    default:
      return state;
  }
};
const _whiteList = [
  'newsData',
  'longShortData',
  'recomendPostData',
  'userId',
  'retrieveReady',
  'socialToken',
  'socialAnonymous',
  'socialUserInfo',
  'socialUserNotExist',
  'tokenUpdateTs'
];

const socialPersistConfig = {
  key: 'social',
  debug: API_ENV === 'prod' || API_ENV === 'pro',
  timeout: 10000,
  storage: AsyncStorage,
  whitelist: _whiteList
};

export type PersistSocialReducerType = Reducer<SocialStateType, PersistSocialActionType>;
const persistSocialReducer: (SocialStateType, PersistSocialActionType) => SocialStateType = persistReducer<SocialStateType, PersistSocialActionType>(socialPersistConfig, socialReducer);

export default persistSocialReducer;
