// @flow

import React, { Component } from 'react';
import type { Element } from 'react';
import {
  Text,
  TouchableWithoutFeedback,
  ScrollView,
  Animated,
  DeviceEventEmitter,
  View
} from 'react-native';
import { connect } from 'react-redux';
import type { CombinedReducerType, DispatchProps } from '../../reducers';
import { defaultMapDispatchProp, customMapStateProp } from '../../reducers';

import { TabView, SceneMap } from 'react-native-tab-view';
import bugsnag from '../../utils/bugsnag';
import _ from 'lodash'
import * as helper from '../../utils/helper';
import { isSocialLogin } from '../../utils/helper';
import FastImage from 'react-native-fast-image';
import HomeStyle from './styles/home';
import WebHomeStyle from './styles/webHome';
import { margin } from '../../styles/Common'
import Discover from './widget/Discover'
import News from '../social/News';
import Social from '../social/';
import Deposit from './widget/Deposit';
import Search from './widget/Search'
import Header from './widget/Header'
import {
  FetchRemoteConfig, FetchDepositConfig
} from '../../actions/HomeAction';
import I18n from '../../utils/i18n';
import p from '../../utils/Transfrom';

// 定义Home页面的State类型
type HomeStateType = {
  loading: boolean,
  liquidationData: {},
  longShortData: [],
  spotMarketItems: [],
  unread: string
};
// 定义Home页面的自己的 Props类型
type HomeOwnPropType = {};
// 联合注入的类型？  缺少navigator 的注入声明
type HomeProsType = HomeOwnPropType & CombinedReducerType & DispatchProps;

class Home extends Component<HomeProsType, HomeStateType> {
  constructor (props: HomeProsType) {
    super(props);
    this.state = {
      index: this.props.route.params?.index ?? 1, // locale === 'hk' ? 0 : 1,
      tabArr: ['community', 'explore', 'deposit1', 'news'],
      routes: [
        { key: 'first', title: 'community' },
        { key: 'second', title: 'explore' },
        { key: 'third', title: 'deposit1' },
        { key: 'four', title: 'news' }
      ]
    };
  }

  componentDidMount () {
    const that = this
    this.firebaseEventListener1 = DeviceEventEmitter.addListener('firebaseWebReady', function () {
      that.props.dispatch(FetchRemoteConfig());
      that.props.dispatch(FetchDepositConfig());
    })
  }

  FirstRoute: () => Element<*> = (): Element<*> => {
    const IS_LARGE_SCREEN = this.props.screenProps?.screenSize === 'large_screen';
    const screen = IS_LARGE_SCREEN
      ? (<View style={[WebHomeStyle.tabContentContainerStyle, WebHomeStyle.tabWhiteBackground]}>
        <Social hasTopic setAnimatedScrollYValue={this.setAnimatedScrollYValue} {...this.props} />
      </View>)
      : <Social hasTopic setAnimatedScrollYValue={this.setAnimatedScrollYValue} {...this.props} />
    return screen
  };

  SecondRoute: () => Element<*> = (): Element<*> => {
    const IS_LARGE_SCREEN = this.props.screenProps?.screenSize === 'large_screen';
    const screen = IS_LARGE_SCREEN
      ? (<View style={WebHomeStyle.tabContentContainerStyle}>
      <Discover setAnimatedScrollYValue={this.setAnimatedScrollYValue} {...this.props} />
    </View>)
      : <Discover setAnimatedScrollYValue={this.setAnimatedScrollYValue} {...this.props} />
    return screen
  };

  ThirdRoute: () => Element<*> = (): Element<*> => {
    const IS_LARGE_SCREEN = this.props.screenProps?.screenSize === 'large_screen';
    const screen = IS_LARGE_SCREEN
      ? (<View style={[WebHomeStyle.tabContentContainerStyle, WebHomeStyle.tabWhiteBackground]}>
      <Deposit setAnimatedScrollYValue={this.setAnimatedScrollYValue} {...this.props} />
    </View>)
      : <Deposit setAnimatedScrollYValue={this.setAnimatedScrollYValue} {...this.props} />
    return screen
  };

  FourRoute: () => Element<*> = (): Element<*> => {
    const IS_LARGE_SCREEN = this.props.screenProps?.screenSize === 'large_screen';
    const screen = IS_LARGE_SCREEN
      ? (<View style={[WebHomeStyle.tabContentContainerStyle, WebHomeStyle.tabWhiteBackground]}>
      <News setAnimatedScrollYValue={this.setAnimatedScrollYValue} {...this.props} homeNewsStyle={{ marginTop: margin.m10 }} />
    </View>)
      : <News setAnimatedScrollYValue={this.setAnimatedScrollYValue} {...this.props} homeNewsStyle={{ marginTop: margin.m10 }} />
    return screen
  };

  setAnimatedScrollYValue: (animateValue: number) => void = (animateValue: number) => {
    this.setState({
      animatedScrollYValue: animateValue
    })
  };

  _renderTypeScene = SceneMap({
    first: this.FirstRoute,
    second: this.SecondRoute,
    third: this.ThirdRoute,
    four: this.FourRoute
  })

  _renderTypeTabBar = (props: {}): Element<*> => {
    const IS_LARGE_SCREEN = this.props.screenProps?.screenSize === 'large_screen';
    const { index, tabArr } = this.state;
    const { depositStatus } = this.props.HomeReducer;
    const btnActiveStyle = IS_LARGE_SCREEN ? [HomeStyle.tabButtonActive, WebHomeStyle.tabButtonActive] : HomeStyle.tabButtonActive;
    const btnStyle = IS_LARGE_SCREEN ? [HomeStyle.tabButton, WebHomeStyle.tabButton] : HomeStyle.tabButton;

    return (
      <View style={HomeStyle.tabStyle}>
        <ScrollView
         horizontal
          // eslint-disable-next-line flowtype/no-weak-types
          ref={(ref: any) => {
            this.scrollView = ref;
          }}
         showsHorizontalScrollIndicator={false}
         keyboardShouldPersistTaps={'always'}
         contentContainerStyle={ IS_LARGE_SCREEN ? WebHomeStyle.contentContainerStyle : {}}
        >
        {this.state.routes.map((item: { key: string, title: string }, i: number): Element<*> => {
          return (
            <TouchableWithoutFeedback testID={'home_' + tabArr[i]} onPress={() => { this._typeIndexChange(i) }}>
              <View style={index === i ? btnActiveStyle : btnStyle}>
                <Text style={[HomeStyle.tabButtonText, index === i ? HomeStyle.tabButtonTextActive : {}]}>{I18n.t(tabArr[i])}</Text>
                { i === 2 && depositStatus !== 'depositFinish' && <FastImage
                  style={HomeStyle.depositDotStyle}
                  source={require('../../images/purple_dot.webp')}
                />}
              </View>
            </TouchableWithoutFeedback>
          );
        })}
        </ScrollView>
      </View>
    );
  };

  getUnread: () => string = (): string => {
    try {
      let unread = 0
      const { myMisc } = this.props.TraderReducer;
      const { socialUserInfo } = this.props.SocialReducer;
      if (this.props.HomeReducer.isLogin) {
        unread = myMisc?.unreadComment + myMisc?.unreadLike + myMisc?.unreadReply;
        const { noticeReward, noticeOtc, noticeSystem } = this.props.HomeReducer
        const noticeRewardUnread = _.filter(noticeReward, function (item: string): boolean { return JSON.parse(item).cid === socialUserInfo?.customerId && !JSON.parse(item).isRead })
        const noticeOtcUnread = _.filter(noticeOtc, function (item: string): boolean { return JSON.parse(item).cid === socialUserInfo?.customerId && !JSON.parse(item).isRead })
        const noticeSystemUnread = _.filter(noticeSystem, function (item: string): boolean { return !JSON.parse(item).isRead })

        unread += noticeRewardUnread.length + noticeOtcUnread.length + noticeSystemUnread.length
      }

      if (unread > 99) {
        unread = '99+'
      }

      return unread !== 0 ? unread.toString() : ''
    } catch (e) {
      bugsnag.notify(new Error('[home]--[getUnread]--error:' + e.toString()));
      return ''
    }
  };

  goNotice: () => void = (): void => {
    if (!isSocialLogin(this.props.SocialReducer)) {
      return helper.navigate(this.props.navigation.navigate, 'Login');
    }
    try {
      helper.trackEvent('NewHome Click Notice');
    } catch (e) {
    }
    const { myMisc } = this.props.TraderReducer;
    this.props.navigation.navigate('MyNotice', {
      userFullData: myMisc
    });
  };

  getNoticeView: () => Element<*> = (): Element<*> => {
    const { socialUserInfo } = this.props.SocialReducer;
    const userFullData = socialUserInfo;
    const unread = this.getUnread()
    if (userFullData && userFullData.customerId) {
      return (
        <TouchableWithoutFeedback
          onPress={this.goNotice}>
          <View
            style={HomeStyle.systemNoticeStyle}>
            <FastImage
              style={HomeStyle.systemNoticeImageStyle}
              source={require('../../images/system_notice.webp')}
            />
            {unread
              ? (
                <View
                  style={HomeStyle.unreadView}>
                  <Text
                    style={HomeStyle.unreadText}>
                    {unread}
                  </Text>
                </View>
                )
              : null}
          </View>
        </TouchableWithoutFeedback>
      )
    } else {
      return <View />
    }
  };

  _typeIndexChange: (i: number) => void = (i: number) => {
    try {
      const { tabArr } = this.state;
      helper.trackEvent('NewHome Tab ' + tabArr[i]);
      if (i === tabArr.length - 1) {
        this.scrollView.scrollToEnd({ animated: true });
      } else if (i === 0) {
        this.scrollView.scrollTo({ x: 0, y: 0, animated: true })
      }
    } catch (e) {
    }

    this.setState({ index: i });
  };

  render (): Element<*> {
    const IS_LARGE_SCREEN = this.props.screenProps?.screenSize === 'large_screen';
    const { animatedScrollYValue = new Animated.Value(0) } = this.state
    return (
      <View style={[
        HomeStyle.container,
        IS_LARGE_SCREEN ? WebHomeStyle.container : null
      ]}>

          {IS_LARGE_SCREEN
            ? null
            : (<Header
            navigation={this.props.navigation}
            animatedScrollYValue={animatedScrollYValue}
            titleName={I18n.t('home')}
            rightView={this.getNoticeView()}
            topAnimatedValue={90}
            maxAnimateHeight={p(152)}
            animatedViewChildren={(<Search navigation={this.props.navigation} IS_LARGE_SCREEN={IS_LARGE_SCREEN}/>)}
            />)
          }

          {IS_LARGE_SCREEN
            ? <Search navigation={this.props.navigation} IS_LARGE_SCREEN={IS_LARGE_SCREEN}/>
            : null
          }

        <TabView
          lazy
          navigationState={this.state}
          renderScene={this._renderTypeScene}
          renderTabBar={this._renderTypeTabBar}
          onIndexChange={this._typeIndexChange}
          IS_LARGE_SCREEN={IS_LARGE_SCREEN}
        />
      </View>

    )
  }
}

const connector = connect(customMapStateProp < HomeOwnPropType >(
  {
    HomeReducer: 1,
    MarketReducer: 1,
    SocialReducer: 1,
    AccountReducer: 1,
    TraderReducer: 1
  }
), defaultMapDispatchProp < HomeOwnPropType >());
export default (connector(Home): Class<Home>);
