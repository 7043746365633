/* @flow */
import { Component } from 'react';
import { getClient } from '../api/social';
import { emitFollowEvent, emitLikeEvent } from '../events/SocialEvents';
import { Feed } from 'getstream';
import bugsnag from '../utils/bugsnag'

export default class SocialBaseComponent extends Component {
  // eslint-disable-next-line no-useless-constructor
  // constructor (props: P) { super(props); }
  follow (param: {
    toCustomerId: string,
    customerId: string,
    isFollow: boolean,
    onSuccess: (code: number) => void,
    onFail: (err: string) => void,
    needLogin?: () => void
  }) {
    try {
      const feed: Feed = getClient()
        .feed('user', param.customerId);
      // const func = param.isFollow ? feed.follow : feed.unfollow;
      if (param.isFollow) {
        feed.follow('user', param.toCustomerId)
          .then((body: {result: boolean}) => {
            if (body && body.result) {
              param.onSuccess && param.onSuccess(0);
              // 先做业务，在做通知状态更新，保证业务处理按照未更新的状态进行判断和处理。
              emitFollowEvent({
                follow: param.isFollow,
                customerId: param.toCustomerId
              });
            } else {
              param.onFail('unknown fail, bad response');
            }
          })
          .catch((error: Error) => {
            // TODO fix handle error
            bugsnag.notify(new Error('[components-SocialBaseComponent] -- [feed.follow] -- [error]: ' + error.toString()));
            try {
              // $FlowIgnore
              if (error.error.result.statusCode === 400) {
                param.needLogin && param.needLogin()
              } else {
                param.onFail(error.message);
              }
            } catch (e) {
              bugsnag.notify(new Error('[components-SocialBaseComponent] -- [feed.follow.catch] -- [error]: ' + e.toString()));
              param.onFail(e.message);
            }
          });
      } else {
        feed.unfollow('user', param.toCustomerId)
          .then((body: {result: boolean}) => {
            if (body && body.result) {
              param.onSuccess && param.onSuccess(0);
              // 先做业务，在做通知状态更新，保证业务处理按照未更新的状态进行判断和处理。
              emitFollowEvent({
                follow: param.isFollow,
                customerId: param.toCustomerId
              });
            } else {
              param.onFail('unknown fail, bad response');
            }
          })
          .catch((error: Error) => {
            bugsnag.notify(new Error('[components-SocialBaseComponent] -- [feed.unfollow.catch] -- [error]: ' + error.toString()));
            // TODO fix handle error
            try {
              // $FlowIgnore
              if (error.error.result.statusCode === 400) {
                param.needLogin && param.needLogin()
              } else {
                param.onFail(error.message);
              }
            } catch (e) {
              bugsnag.notify(new Error('[components-SocialBaseComponent] -- [feed.unfollow.catch] -- [error]: ' + e.toString()));
              param.onFail(e.message);
            }
          });
      }
    } catch (e) {
      param.onFail(e);
      bugsnag.notify(new Error('[components-SocialBaseComponent] -- [follow] -- [error]: ' + e.toString()));
    }
  }

  /**
   * （不）喜欢
   * @param param
   */
  like (param: {
    toCustomerId: string,
    customerId: string,
    targetId: string,
    targetType: 'post' | 'comment' | 'reply',
    isLike: boolean,
    onSuccess: (code: number) => void,
    onFail: (err: {}) => void,
    needLogin?: () => void
  }) {
    getClient()
      .reactions.add(
        'LIKE',
        param.targetId,
        !param.isLike ? { reverse: true } : {}
      )
      .then((res: {result: string}) => {
        if (res.result) {
          emitLikeEvent({
            customerId: param.toCustomerId,
            targetId: param.targetId,
            targetType: param.targetType,
            like: param.isLike
          });
          param.onSuccess(0);
        }
      })
      .catch((error: {message: string}) => { // TODO
        bugsnag.notify(new Error('[components-SocialBaseComponent] -- [add] -- [error]: ' + error.toString()));
        try {
          // $FlowIgnore
          if (error.error.result.statusCode === 400) {
            param.needLogin && param.needLogin()
          } else {
            param.onFail(error);
          }
        } catch (e) {
          bugsnag.notify(new Error('[components-SocialBaseComponent] -- [like] -- [error]: ' + e.toString()));
          param.onFail(e);
        }
      });
  }

  /**
   * 收藏、取消收藏
   * @param param
   */
  fav (param: {}) {}
}
