// @flow

import React, { Component } from 'react';
import type { Element } from 'react';
import { Text, View } from 'react-native';

type MarketProps = {};

class Market extends Component {
  constructor (props: MarketProps) {
    super(props);
    this.state = {
    };
  }

  componentDidMount () {
  }

  render (): Element<*> {
    return (
        <View>
              <Text>Markets</Text>
      </View>

    )
  }
}
export default Market;
