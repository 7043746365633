
/**
 * 包含了可定位Modal的按钮组件
 * 备注:LocationModal的容器组件,统一封装了定位函数和点击事件
 */
// @flow
import React from 'react';
import type { Element } from 'react';
import {
  View
} from 'react-native';
import LottieView from 'lottie-react-native';
import p from '../utils/Transfrom';

type StateType = {
};

type ProsType = {
};

class LottieLoading extends React.Component<ProsType, StateType> {
  render (): Element<*> {
    return (
      <View
        style={this.props.style}>
              <LottieView
                style={{
                  height: p(100),
                  width: p(100)
                }}
                autoSize={false}
                loop
                // eslint-disable-next-line flowtype/no-weak-types
                ref={(animation: any) => {
                  if (animation) {
                    animation.play();
                  }
                }}
                /* $FlowFixMe */
                  source={require('../animations/loading.json')}
              />
      </View>
    );
  }
}
module.exports = LottieLoading;
