// @flow
import { ActionTypes } from './ActionTypes';
import helper from '../utils/helper';
import I18n from '../utils/i18n';
import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import { ThunkDispatch } from 'redux-thunk';
import { AccountAPI, ApplyWithdrawRequestType, type ApplyWithdrawResponseType } from '../api/account';
import type {
  AccountAssetsResponseType,
  AccountInfo, CoinAccount, CoinAddressAccount,
  CoinListResponseType,
  Currency,
  CurrencyResponseType, DepositAddressResponseType, ExdmTransaction,
  ExProduct,
  InfoResponseType, TreasureProduct, VirtualAccountLog, WithdrawAddress, WithdrawFee, WithdrawFeeResponseType
} from '../api/account';
import type { ErrorActionType, StateType } from './common';
import type { PageResult } from '../api/base';
import bugsnag from '../utils/bugsnag'

interface AssetAccountListData {
  assetAccountList: CoinAccount[],
  accountList: CoinAccount[],
  assetIndex: number
}

interface AssetAccountAllData {
  allAssets: {[assetIndex: number]: AssetAccountListData}
}

interface AccountHistoryData {
  historyHasNextPage: boolean,
  historyPage: number,
  historyData: VirtualAccountLog[],
  historyCoinCode: string
}

interface TransactionItemData {
  transactions: ExdmTransaction[],
  transactionsPage: number,
  transactionsCurrency: string,
  transactionsHasNext: boolean,
  transactionsType: number
}

export type CoinListActionType = Action<typeof ActionTypes.COIN_LIST> & {coinMap: {[key: string]: ExProduct}};
export type CoinListRequestActionType = Action<typeof ActionTypes.COIN_LIST_REQUEST>;
export type CoinListErrorActionType = Action<typeof ActionTypes.COIN_LIST_ERROR> & ErrorActionType;
export type CoinListResetActionType = Action<typeof ActionTypes.COIN_LIST_RESET>;
export type CurrencyActionActionType = Action<typeof ActionTypes.CURRENCY> & {currency: Currency}; // TODO
export type CurrencyActionRequestActionType =Action<typeof ActionTypes.CURRENCY_REQUEST>;
export type CurrencyActionErrorActionType = Action<typeof ActionTypes.CURRENCY_ERROR> & ErrorActionType;
export type CurrencyActionResetActionType = Action<typeof ActionTypes.CURRENCY_RESET>;
export type AccountInfoActionType = Action<typeof ActionTypes.ACCOUNT_INFO> & {accountInfo: AccountInfo};
export type AccountInfoErrorActionType = Action<typeof ActionTypes.ACCOUNT_INFO_ERROR> & ErrorActionType;
export type AccountInfoRequestActionType = Action<typeof ActionTypes.ACCOUNT_INFO_REQUEST>;
export type AccountInfoResetActionType = Action<typeof ActionTypes.ACCOUNT_INFO_RESET>;
export type AccountAssetRequestActionType = Action<typeof ActionTypes.ACCOUNT_ASSET_REQUEST>;
export type AccountAssetActionType = Action<typeof ActionTypes.ACCOUNT_ASSET> & AssetAccountListData;
export type AccountAssetResetActionType = Action<typeof ActionTypes.ACCOUNT_ASSET_RESET> & AssetAccountListData;
export type AccountAssetErrorActionType = Action<typeof ActionTypes.ACCOUNT_ASSET_ERROR> & ErrorActionType;
export type AccountAssetAllRequestActionType = Action<typeof ActionTypes.ACCOUNT_ASSET_ALL_REQUEST>;
export type AccountAssetAllActionType = Action<typeof ActionTypes.ACCOUNT_ASSET_ALL> & AssetAccountAllData;
export type AccountAssetAllResetActionType = Action<typeof ActionTypes.ACCOUNT_ASSET_ALL_RESET>;
export type AccountAssetAllErrorActionType = Action<typeof ActionTypes.ACCOUNT_ASSET_ALL_ERROR> & ErrorActionType;
export type AccountHistoryActionType = Action<typeof ActionTypes.ACCOUNT_HISTORY> & AccountHistoryData;
export type AccountHistoryErrorActionType = Action<typeof ActionTypes.ACCOUNT_HISTORY_ERROR> & ErrorActionType;
export type AccountHistoryRequestActionType = Action<typeof ActionTypes.ACCOUNT_HISTORY_REQUEST>;
export type AccountHistoryResetActionType = Action<typeof ActionTypes.ACCOUNT_HISTORY_RESET>;
export type CreateDepositAddrActionType = Action<typeof ActionTypes.CREATE_DEPOSIT_ADDR> & {depositAddr: CoinAddressAccount};
export type CreateDepositAddrRequestActionType = Action<typeof ActionTypes.CREATE_DEPOSIT_ADDR_REQUEST>;
export type CreateDepositAddrResetActionType = Action<typeof ActionTypes.CREATE_DEPOSIT_ADDR_RESET>;
export type CreateDepositAddrErrorActionType = Action<typeof ActionTypes.CREATE_DEPOSIT_ADDR_ERROR> & ErrorActionType;
export type DepositAddrActionType = Action<typeof ActionTypes.DEPOSIT_ADDR> & {depositAddr: CoinAddressAccount};
export type DepositAddrRequestActionType = Action<typeof ActionTypes.DEPOSIT_ADDR_REQUEST>;
export type DepositAddrResetActionType = Action<typeof ActionTypes.DEPOSIT_ADDR_RESET>;
export type DepositAddrErrorActionType = Action<typeof ActionTypes.DEPOSIT_ADDR_ERROR> & ErrorActionType;
export type AccountTransactionsActionType = Action<typeof ActionTypes.ACCOUNT_TRANSACTIONS> & TransactionItemData;
export type AccountTransactionsResetActionType = Action<typeof ActionTypes.ACCOUNT_TRANSACTIONS_RESET> & TransactionItemData;
export type AccountTransactionsRequestActionType = Action<typeof ActionTypes.ACCOUNT_TRANSACTIONS_REQUEST>;
export type AccountTransactionsErrorActionType = Action<typeof ActionTypes.ACCOUNT_TRANSACTIONS_ERROR> & ErrorActionType;
export type AccountYuebaoProductListActionType = Action<typeof ActionTypes.ACCOUNT_YUEBAO_PRODUCT_LIST> & {yuebaoProduct: TreasureProduct[]};
export type AccountTransferActionType = Action<typeof ActionTypes.ACCOUNT_TRANSFER> & {accountTransferSuccess: number};
export type AccountTransferRequestActionType = Action<typeof ActionTypes.ACCOUNT_TRANSFER_REQUEST>;
export type AccountTransferResetActionType = Action<typeof ActionTypes.ACCOUNT_TRANSFER_RESET> & {accountTransferSuccess: number};
export type AccountTransferErrorActionType = Action<typeof ActionTypes.ACCOUNT_TRANSFER_ERROR> & ErrorActionType;
export type WithdrawFeeActionType = Action<typeof ActionTypes.WITHDRAW_FEE> & {product: WithdrawFee};
export type WithdrawFeeRequestActionType = Action<typeof ActionTypes.WITHDRAW_FEE_REQUEST>;
export type WithdrawFeeErrorActionType = Action<typeof ActionTypes.WITHDRAW_FEE_ERROR> & ErrorActionType;
export type WithdrawFeeResetActionType = Action<typeof ActionTypes.WITHDRAW_FEE_RESET>;
export type WithdrawAddrListActionType = Action<typeof ActionTypes.WITHDRAW_ADDR_LIST> & {withdrawAddrList: WithdrawAddress[]};
export type WithdrawAddrListRequestActionType = Action<typeof ActionTypes.WITHDRAW_ADDR_LIST_REQUEST>;
export type WithdrawAddrListErrorActionType = Action<typeof ActionTypes.WITHDRAW_ADDR_LIST_ERROR> & ErrorActionType;
export type WithdrawAddrListResetActionType = Action<typeof ActionTypes.WITHDRAW_ADDR_LIST_RESET>;
export type WithdrawAddrActionType = Action<typeof ActionTypes.WITHDRAW_ADDR> & {withdrawAddr: boolean};
export type WithdrawAddrRequestActionType = Action<typeof ActionTypes.WITHDRAW_ADDR_REQUEST>;
export type WithdrawAddrErrorActionType = Action<typeof ActionTypes.WITHDRAW_ADDR_ERROR> & ErrorActionType;
export type WithdrawAddrResetActionType = Action<typeof ActionTypes.WITHDRAW_ADDR_RESET>;
export type CurrentRateTypeActionType = Action<typeof ActionTypes.CURRENT_RATE_TYPE> & {rateType: string};
export type AccountAssetInfoCleanActionType = Action<typeof ActionTypes.ACCOUNT_ASSET_INFO_CLEAN>;
export type WithdrawApplyActionType = Action<typeof ActionTypes.WITHDRAW_APPLY> & {withdrawApply: {}}; // TODO
export type WithdrawApplyRequestActionType = Action<typeof ActionTypes.WITHDRAW_APPLY_REQUEST>;
export type WithdrawApplyErrorActionType = Action<typeof ActionTypes.WITHDRAW_APPLY_ERROR> & ErrorActionType;
export type WithdrawApplyResetActionType = Action<typeof ActionTypes.WITHDRAW_APPLY_RESET>;
export type ShowAssetAmountActionType = Action<typeof ActionTypes.SHOW_ASSET_AMOUNT> & {showAssetAmount: boolean};
export type ShowMinCoinCodeActionType = Action<typeof ActionTypes.SHOW_MIN_COINCODE> & {showMinCoinCode: boolean};

export type AccountActionsType = CoinListActionType | CoinListRequestActionType | CoinListErrorActionType | CoinListResetActionType |
  CurrencyActionActionType | CurrencyActionRequestActionType | CurrencyActionErrorActionType | CurrencyActionResetActionType |
  AccountInfoActionType | AccountInfoErrorActionType | AccountInfoRequestActionType | AccountInfoResetActionType |
  AccountAssetRequestActionType | AccountAssetActionType | AccountAssetResetActionType | AccountAssetErrorActionType |
  AccountAssetAllRequestActionType | AccountAssetAllActionType | AccountAssetAllResetActionType | AccountAssetAllErrorActionType |
  AccountHistoryActionType | AccountHistoryErrorActionType | AccountHistoryRequestActionType | AccountHistoryResetActionType |
  CreateDepositAddrActionType | CreateDepositAddrRequestActionType | CreateDepositAddrResetActionType | CreateDepositAddrErrorActionType |
  DepositAddrActionType | DepositAddrRequestActionType | DepositAddrResetActionType | DepositAddrErrorActionType |
  AccountTransactionsActionType | AccountTransactionsResetActionType | AccountTransactionsRequestActionType | AccountTransactionsErrorActionType |
  AccountYuebaoProductListActionType |
  AccountTransferActionType | AccountTransferRequestActionType | AccountTransferResetActionType | AccountTransferErrorActionType |
  WithdrawFeeActionType | WithdrawFeeRequestActionType | WithdrawFeeErrorActionType | WithdrawFeeResetActionType |
  WithdrawAddrListActionType | WithdrawAddrListRequestActionType | WithdrawAddrListErrorActionType | WithdrawAddrListResetActionType |
  WithdrawAddrActionType | WithdrawAddrRequestActionType | WithdrawAddrErrorActionType | WithdrawAddrResetActionType |
  CurrentRateTypeActionType | AccountAssetInfoCleanActionType |
  WithdrawApplyActionType | WithdrawApplyRequestActionType | WithdrawApplyErrorActionType | WithdrawApplyResetActionType | ShowAssetAmountActionType | ShowMinCoinCodeActionType;

export type CoinListActionUnionActionType = CoinListActionType | CoinListRequestActionType | CoinListErrorActionType | CoinListResetActionType;
export type FetchCoinListThunkActionType = ThunkAction<Promise<void>, StateType, void, CoinListActionUnionActionType>;
export type FetchCoinListThunkDispatchType = ThunkDispatch<StateType, void, CoinListActionUnionActionType>;
export function FetchCoinList (props: {callback: ?({[key: string]: ExProduct})=>void, ignoreError: ?boolean} = { ignoreError: false, callback: null }): FetchCoinListThunkActionType {
  return async (dispatch: FetchCoinListThunkDispatchType, getState: ()=> StateType) => {
    const { AccountReducer } = getState();
    if ((new Date().getTime() - AccountReducer.coinListUpdateTs) < 1000 * 60 * 3) {
      return;
    }
    dispatch({ type: ActionTypes.COIN_LIST_REQUEST });
    try {
      const response: CoinListResponseType = await AccountAPI.coinList();
      if (response.success && response.obj) {
        let obj = response.obj; const _obj = {}

        if (Object.prototype.toString.call(response.obj) === '[object Array]') {
          /* $FlowFixMe */
          response.obj.forEach(
            // eslint-disable-next-line flowtype/no-weak-types
            (item: any) => {
              _obj[item.coinCode] = item
            })
          obj = _obj
        }
        dispatch({
          type: ActionTypes.COIN_LIST,
          coinMap: obj
        });
        // 登录跳转
        if (props.callback && response.obj) {
          props.callback(response.obj);
        }
      } else {
        if (props.ignoreError === true) {
          return;
        }
        dispatch({
          type: ActionTypes.COIN_LIST_ERROR,
          error: response.code
        });
      }
    } catch (error) {
      console.log(error)
      bugsnag.notify(new Error('[account] -- [FetchCoinList] -- [error]: ' + error.toString()));
      if (props.ignoreError === true) {
        return;
      }
      dispatch({
        type: ActionTypes.COIN_LIST_ERROR,
        error
      });
    }
  };
}

export type CurrencyActionUnionActionType = CurrencyActionActionType | CurrencyActionRequestActionType | CurrencyActionErrorActionType | CurrencyActionResetActionType;
export type FetchCurrencyThunkActionType = ThunkAction<Promise<void>, void, void, CurrencyActionUnionActionType>;
export type FetchCurrencyThunkDispatchType = ThunkDispatch<void, void, CurrencyActionUnionActionType>;
export function FetchCurrency (props: {coinCode: string, callback: (Currency)=> void, ignoreError: boolean}): FetchCurrencyThunkActionType {
  return async (dispatch: FetchCurrencyThunkDispatchType) => {
    dispatch({ type: ActionTypes.CURRENCY_REQUEST });
    try {
      const response: CurrencyResponseType = await AccountAPI.currency({ coinCode: props.coinCode });
      if (response.success && response.obj) {
        dispatch({
          type: ActionTypes.CURRENCY,
          currency: response.obj
        });
        // 登录跳转
        if (props.callback && response.obj) {
          props.callback(response.obj);
        }
      } else {
        if (props.ignoreError) {
          return;
        }
        dispatch({
          type: ActionTypes.CURRENCY_ERROR,
          error: response.code
        });
      }
    } catch (error) {
      bugsnag.notify(new Error('[account] -- [FetchCurrency] -- [error]: ' + error.toString()));
      if (props.ignoreError) {
        return;
      }
      dispatch({
        type: ActionTypes.CURRENCY_ERROR,
        error: 'system.error'
      });
    }
  };
}

export type AccountInfoUnionActionType = AccountInfoActionType | AccountInfoErrorActionType | AccountInfoRequestActionType | AccountInfoResetActionType;
export type FetchAccountInfoThunkActionType = ThunkAction<Promise<void>, void, void, AccountInfoUnionActionType>;
export type FetchAccountInfoThunkDispatchType = ThunkDispatch<void, void, AccountInfoUnionActionType>;
export function FetchAccountInfo (props: {params: {accountCategory: string}, ignoreError: boolean}): FetchAccountInfoThunkActionType {
  return async (dispatch: FetchAccountInfoThunkDispatchType) => {
    dispatch({ type: ActionTypes.ACCOUNT_INFO_REQUEST });
    // loginStateAPIS.accountAPI.accountAssets(response=>{}, error=>{}, {});
    try {
      const response: InfoResponseType = await AccountAPI.info(props.params);
      if (response.success && response.obj) {
        dispatch({
          type: ActionTypes.ACCOUNT_INFO,
          accountInfo: response.obj
        });
      } else {
        if (props.ignoreError) {
          return;
        }
        dispatch({
          type: ActionTypes.ACCOUNT_INFO_ERROR,
          error: I18n.t(response.msgKey)
        });
      }
    } catch (e) {
      bugsnag.notify(new Error('[account] -- [FetchAccountInfo] -- [error]: ' + e.toString()));
      if (props.ignoreError) {
        return;
      }
      dispatch({
        type: ActionTypes.ACCOUNT_INFO_ERROR,
        error: I18n.t('system.error')
      });
    }
  };
}

export type AccountAssetUnionActionType = AccountAssetRequestActionType | AccountAssetActionType | AccountAssetResetActionType | AccountAssetErrorActionType;
export type FetchAccountAssetsThunkActionType = ThunkAction<Promise<void>, void, void, AccountAssetUnionActionType>;
export type FetchAccountAssetsThunkDispatchType = ThunkDispatch<void, void, AccountAssetUnionActionType>;
/**
 * 初始化加载各类账户资产，然后再每次变更的时候更新单账户即可
 * @param props
 * @returns {function(...[*]=)}
 * @constructor
 */
export function FetchAccountAssets (props: {index: number, ignoreError?: boolean}): FetchAccountAssetsThunkActionType {
  return async (dispatch: FetchAccountAssetsThunkDispatchType) => {
    dispatch({ type: ActionTypes.ACCOUNT_ASSET_REQUEST });
    const accountCategory = helper.getAccountCategory(props.index);
    try {
      const response: AccountAssetsResponseType = await AccountAPI.accountAssets({ accountCategory: accountCategory });
      if (response.success && response.obj) {
        dispatch({
          type: ActionTypes.ACCOUNT_ASSET,
          assetAccountList: response.obj.coinAccountsWithAssets,
          accountList: response.obj.coinAccountList,
          assetIndex: props.index
        });
        // 登录跳转
      } else {
        if (props.ignoreError === true) {
          return;
        }
        dispatch({
          type: ActionTypes.ACCOUNT_ASSET_ERROR,
          error: I18n.t(response.msgKey)
        });
      }
    } catch (e) {
      bugsnag.notify(new Error('[account] -- [FetchAccountAssets] -- [error]: ' + e.toString()));
      if (props.ignoreError === true) {
        return;
      }
      dispatch({
        type: ActionTypes.ACCOUNT_ASSET_ERROR,
        error: I18n.t('system.error')
      });
    }
  };
}
export type AccountAssetAllUnionActionType = AccountAssetAllRequestActionType | AccountAssetAllActionType | AccountAssetAllResetActionType | AccountAssetAllErrorActionType;
export type FetchAccountAssetsAllThunkActionType = ThunkAction<Promise<void>, void, void, AccountAssetAllUnionActionType>;
export type FetchAccountAssetsAllThunkDispatchType = ThunkDispatch<void, void, AccountAssetAllUnionActionType>;
export function FetchAccountAssetsAll (props: {indexes: number[], ignoreError?: boolean}): FetchAccountAssetsAllThunkActionType {
  return async (dispatch: FetchAccountAssetsAllThunkDispatchType) => {
    dispatch({ type: ActionTypes.ACCOUNT_ASSET_ALL_REQUEST });
    const promises = [];
    props.indexes.forEach((i: number) => {
      const accountCategory = helper.getAccountCategory(i);
      promises.push(AccountAPI.accountAssets({ accountCategory: accountCategory }))
    })

    try {
      const responses: AccountAssetsResponseType[] = await Promise.all(promises);
      let i = 0;
      let hasError = false;
      const allAssets = {};
      responses.forEach((response: AccountAssetsResponseType) => {
        if (response.success && response.obj) {
          allAssets[props.indexes[i]] = {
            type: ActionTypes.ACCOUNT_ASSET,
            assetAccountList: response.obj.coinAccountsWithAssets,
            accountList: response.obj.coinAccountList,
            assetIndex: props.indexes[i]
          }
          i++;
        } else {
          if (props.ignoreError === true) {
            return;
          }
          hasError = true
          dispatch({
            type: ActionTypes.ACCOUNT_ASSET_ALL_ERROR,
            error: I18n.t(response.msgKey)
          });
        }
      });
      if (!hasError) {
        dispatch({ allAssets: allAssets, type: ActionTypes.ACCOUNT_ASSET_ALL });
      }
    } catch (e) {
      bugsnag.notify(new Error('[account] -- [FetchAccountAssetsAll] -- [error]: ' + e.toString()));
      if (props.ignoreError === true) {
        return;
      }
      dispatch({
        type: ActionTypes.ACCOUNT_ASSET_ALL_ERROR,
        error: I18n.t('system.error')
      });
    }
  };
}

export type AccountHistoryUnionActionType = AccountHistoryActionType | AccountHistoryErrorActionType | AccountHistoryRequestActionType | AccountHistoryResetActionType;
export type FetchAccountHistoryThunkActionType = ThunkAction<Promise<void>, void, void, AccountHistoryUnionActionType>;
export type FetchAccountHistoryThunkDispatchType = ThunkDispatch<void, void, AccountHistoryUnionActionType>;
export function FetchAccountHistory (props: {page: ?number, coinCode: string, index: number, callback: (PageResult<VirtualAccountLog>)=>void, ignoreError?: boolean}): FetchAccountHistoryThunkActionType {
  return async (dispatch: FetchAccountHistoryThunkDispatchType) => {
    dispatch({ type: ActionTypes.ACCOUNT_HISTORY_REQUEST });
    const accountCategory = helper.getAccountCategory(props.index);
    const limit = 10;
    const offset = ((props.page ?? 1) - 1) * limit;
    try {
      const response = await AccountAPI.history({ offset, limit, coinCode: props.coinCode, accountCategory });
      if (response.success) {
        dispatch({
          type: ActionTypes.ACCOUNT_HISTORY,
          historyHasNextPage: response.obj?.hasNextPage ?? response.obj.rows?.length === limit ?? false,
          historyPage: response.obj?.page || props.page,
          historyData: response.obj?.rows || [],
          historyCoinCode: props.coinCode
        });
        // 登录跳转
        if (props.callback) {
          props.callback(response.obj || {});
        }
      } else {
        if (props.ignoreError === true) {
          return;
        }
        dispatch({
          type: ActionTypes.ACCOUNT_HISTORY_ERROR,
          error: I18n.t(response.msgKey)
        });
      }
    } catch (e) {
      bugsnag.notify(new Error('[account] -- [FetchAccountHistory] -- [error]: ' + e.toString()));
      if (props.ignoreError === true) {
        return;
      }
      dispatch({
        type: ActionTypes.ACCOUNT_HISTORY_ERROR,
        error: I18n.t('system.error')
      });
    }
  };
}

// CREATE_DEPOSIT_ADDR
export type CreateDepositAddrUnionActionType = CreateDepositAddrActionType | CreateDepositAddrRequestActionType | CreateDepositAddrResetActionType | CreateDepositAddrErrorActionType;
export type CreateDepositAddressThunkActionType = ThunkAction<Promise<void>, void, void, CreateDepositAddrUnionActionType>;
export type CreateDepositAddressThunkDispatchType = ThunkDispatch<void, void, CreateDepositAddrUnionActionType>;
export function CreateDepositAddress (props: { params: {currency: string, isV1API?: boolean}, ignoreError?: boolean}): CreateDepositAddressThunkActionType {
  return async (dispatch: CreateDepositAddressThunkDispatchType) => {
    dispatch({ type: ActionTypes.CREATE_DEPOSIT_ADDR_REQUEST });
    try {
      const response: DepositAddressResponseType = await AccountAPI.depositAddress(props.params);
      if (response && response.success && response.obj) {
        // response.success
        const ret = response.obj;
        dispatch({
          type: ActionTypes.CREATE_DEPOSIT_ADDR,
          depositAddr: ret
        });
      } else {
        if (props.ignoreError === true) {
          return;
        }
        dispatch({
          type: ActionTypes.CREATE_DEPOSIT_ADDR_ERROR,
          error: I18n.t(response.msgKey ?? 'system.error')
        });
      }
    } catch (e) {
      console.log(e)
      bugsnag.notify(new Error('[account] -- [CreateDepositAddress] -- [error]: ' + e.toString()));
      if (props.ignoreError === true) {
        return;
      }
      dispatch({
        type: ActionTypes.CREATE_DEPOSIT_ADDR_ERROR,
        error: I18n.t('system.error')
      });
    }
  };
}

export type DepositAddrUnionActionType = DepositAddrActionType | DepositAddrRequestActionType | DepositAddrResetActionType | DepositAddrErrorActionType;
export type FetchDepositAddressThunkActionType = ThunkAction<Promise<void>, StateType, void, DepositAddrUnionActionType>;
export type FetchDepositAddressThunkDispatchType = ThunkDispatch<StateType, void, DepositAddrUnionActionType>;
export function FetchDepositAddress (props: {currency: string, ignoreError?: boolean, isV1API: boolean}): FetchDepositAddressThunkActionType {
  return async (dispatch: FetchDepositAddressThunkDispatchType, getState: ()=> StateType) => {
    dispatch({ type: ActionTypes.DEPOSIT_ADDR_REQUEST });
    try {
      const response = await AccountAPI.showDepositAddress({ currency: props.currency, isV1API: props.isV1API });
      console.log('获取充币地址', JSON.stringify(response));
      if (response && response.success) {
        // response.success
        const ret: ?CoinAddressAccount = response.obj ?? null;
        dispatch({
          type: ActionTypes.DEPOSIT_ADDR,
          depositAddr: ret
        });
      } else {
        if (props.ignoreError === true) {
          return;
        }
        dispatch({
          type: ActionTypes.DEPOSIT_ADDR_ERROR,
          error: I18n.t(response.msgKey)
        });
      }
    } catch (e) {
      bugsnag.notify(new Error('[account] -- [FetchDepositAddress] -- [error]: ' + e.toString()));
      if (props.ignoreError === true) {
        return;
      }
      dispatch({
        type: ActionTypes.DEPOSIT_ADDR_ERROR,
        error: I18n.t('system.error')
      });
    }
  };
}

// ACCOUNT_TRANSACTIONS
export type AccountTransactionsUnionActionType = AccountTransactionsActionType | AccountTransactionsResetActionType | AccountTransactionsRequestActionType | AccountTransactionsErrorActionType;
export type FetchTransactionsThunkActionType = ThunkAction<Promise<void>, StateType, void, AccountTransactionsUnionActionType>;
export type FetchTransactionsThunkDispatchType = ThunkDispatch<StateType, void, AccountTransactionsUnionActionType>;
// type 0: deposit, type 1: withdraw   currency '' | 'BTC' can be empty, when fetch all coin record
export function FetchTransactions (props: {currency: string, type: 1 | 2, page: ?number, ignoreError?: boolean, status?: number, isV1API: boolean}): FetchTransactionsThunkActionType {
  return async (dispatch: FetchTransactionsThunkDispatchType, getState: ()=> StateType) => {
    dispatch({ type: ActionTypes.ACCOUNT_TRANSACTIONS_REQUEST });
    const limit = 10;
    const offset = ((props.page ?? 1) - 1) * limit;
    const { status } = props;
    const { type } = props
    if (props.isV1API) {
      // type = 'DEPOSIT'
    }
    try {
      const response = await AccountAPI.transactionList({ offset, limit, currency: props.currency, coinCode: props.currency, status, type, isV1API: props.isV1API })
      console.log('获取充提币记录', JSON.stringify(response));
      if (response.success) {
        const ret = response.obj;
        dispatch({
          type: ActionTypes.ACCOUNT_TRANSACTIONS,
          transactions: ret.rows ?? [],
          transactionsPage: ret.page,
          transactionsType: props.type,
          transactionsHasNext: ret.rows?.length === limit,
          transactionsCurrency: props.currency
        });
      } else {
        if (props.ignoreError === true) {
          return;
        }
        dispatch({
          type: ActionTypes.ACCOUNT_TRANSACTIONS_ERROR,
          error: I18n.t(response.msgKey)
        });
      }
    } catch (e) {
      bugsnag.notify(new Error('[account] -- [FetchTransactions] -- [error]: ' + e.toString()));
      if (props.ignoreError === true) {
        return;
      }
      dispatch({
        type: ActionTypes.ACCOUNT_TRANSACTIONS_ERROR,
        error: I18n.t('system.error')
      });
    }
  };
}

export type FetchYuebaoProductThunkActionType = ThunkAction<Promise<void>, void, void, AccountYuebaoProductListActionType>;
export type FetchYuebaoProductThunkDispatchType = ThunkDispatch<void, void, AccountYuebaoProductListActionType>;
export function FetchYuebaoProduct (): FetchYuebaoProductThunkActionType {
  return async (dispatch: FetchYuebaoProductThunkDispatchType) => {
    try {
      const response = await AccountAPI.yuebaoProduct();
      console.log('获取余额宝产品列表', JSON.stringify(response));
      if (response.success && response.obj) {
        const ret: TreasureProduct[] = response.obj;
        dispatch({
          type: ActionTypes.ACCOUNT_YUEBAO_PRODUCT_LIST,
          yuebaoProduct: ret.filter((item: TreasureProduct): boolean => {
            return item.status === 1;
          })
        });
      } else {
        console.log('fetch yuebao list error');
      }
    } catch (e) {
      // TODO FIX error Handler
      // console.log(error);
      bugsnag.notify(new Error('[account] -- [FetchYuebaoProduct] -- [error]: ' + e.toString()));
    }
  };
}

export type AccountTransferUnionActionType = AccountTransferActionType | AccountTransferRequestActionType | AccountTransferResetActionType | AccountTransferErrorActionType;
export type YuebaoTransferThunkActionType = ThunkAction<Promise<number>, void, void, AccountTransferUnionActionType>;
export type YuebaoTransferThunkDispatchType = ThunkDispatch<void, void, AccountTransferUnionActionType>;
export function YuebaoTransfer (props: {
  currency: string,
  amount: number,
  direction: 0 | 1
}): YuebaoTransferThunkActionType {
  return async (dispatch: YuebaoTransferThunkDispatchType): Promise<number> => {
    dispatch({ type: ActionTypes.ACCOUNT_TRANSFER_REQUEST });
    try {
      const { currency, amount, direction } = props;
      const response = await AccountAPI.yuebaoTransfer({ currency, amount, direction });
      console.log('划转yuebao', JSON.stringify(response));
      if (response.success) {
        dispatch({
          type: ActionTypes.ACCOUNT_TRANSFER,
          accountTransferSuccess: props.amount
        });
        return props.amount;
      } else {
        dispatch({
          type: ActionTypes.ACCOUNT_TRANSFER_ERROR,
          error: I18n.t(response.msgKey)
        });
        return 0;
      }
    } catch (e) {
      bugsnag.notify(new Error('[account] -- [YuebaoTransfer] -- [error]: ' + e.toString()));
      dispatch({
        type: ActionTypes.ACCOUNT_TRANSFER_ERROR,
        error: I18n.t('system.error')
      });
      return 0;
    }
  }
};

export type AccountTransferThunkActionType = ThunkAction<Promise<void>, void, void, AccountTransferUnionActionType>;
export type AccountTransferThunkDispatchType = ThunkDispatch<void, void, AccountTransferUnionActionType>;
export function AccountTransfer (props: { coinCode: string, accountType: number, toAccountType: number, amount: number}): AccountTransferThunkActionType {
  return async (dispatch: AccountTransferThunkDispatchType) => {
    dispatch({ type: ActionTypes.ACCOUNT_TRANSFER_REQUEST });
    const fromAccountCategory = helper.getAccountCategory(props.accountType);
    const toAccountCategory = helper.getAccountCategory(props.toAccountType);
    const { coinCode, amount } = props;
    try {
      const response = await AccountAPI.accountTransfer({ currency: coinCode, amount, fromAccountCategory, toAccountCategory });
      console.log('划转', JSON.stringify(response));
      if (response.success) {
        dispatch({
          type: ActionTypes.ACCOUNT_TRANSFER,
          accountTransferSuccess: props.amount
        });
      } else {
        dispatch({
          type: ActionTypes.ACCOUNT_TRANSFER_ERROR,
          error: I18n.t(response.msgKey)
        });
      }
    } catch (e) {
      bugsnag.notify(new Error('[account] -- [AccountTransfer] -- [error]: ' + e.toString()));
      dispatch({
        type: ActionTypes.ACCOUNT_TRANSFER_ERROR,
        error: I18n.t('system.error')
      });
    }
  };
};

export type WithdrawFeeUnionActionType = WithdrawFeeActionType | WithdrawFeeRequestActionType | WithdrawFeeErrorActionType | WithdrawFeeResetActionType;
export type FetchProductThunkActionType = ThunkAction<Promise<WithdrawFee | void>, StateType, void, WithdrawFeeUnionActionType>;
export type FetchProductThunkDispatchType = ThunkDispatch<StateType, void, WithdrawFeeUnionActionType>;
/**
 * 可以获取currency接口返回的所有东西，并且还有精度，提示等等所有product的信息
 * @param props
 * @returns {function(...[*]=)}
 * @constructor
 */
export function FetchProduct (props: {coinCode: string}): FetchProductThunkActionType {
  return async (dispatch: FetchProductThunkDispatchType, getState: ()=> StateType): Promise<WithdrawFee | void> => {
    const { AccountReducer } = getState();
    if (AccountReducer.productMap[props.coinCode]) {
      return AccountReducer.productMap[props.coinCode];
    }
    dispatch({ type: ActionTypes.WITHDRAW_FEE_REQUEST });
    try {
      const response: WithdrawFeeResponseType = await AccountAPI.withdrawFee(props.coinCode);
      console.log('提币手续费', JSON.stringify(response));
      if (response.success && response.obj) {
        dispatch({
          type: ActionTypes.WITHDRAW_FEE,
          product: response.obj
        });
        let obj: WithdrawFee;
        if (response.obj) {
          obj = response.obj;
        }
        return obj;
      } else {
        dispatch({
          type: ActionTypes.WITHDRAW_FEE_ERROR,
          error: I18n.t(response.msgKey)
        });
      }
    } catch (e) {
      bugsnag.notify(new Error('[account] -- [FetchProduct] -- [error]: ' + e.toString()));
      dispatch({
        type: ActionTypes.WITHDRAW_FEE_ERROR,
        error: I18n.t('system.error')
      });
    }
  };
};

export type WithdrawAddrListUnionActionType = WithdrawAddrListActionType | WithdrawAddrListRequestActionType | WithdrawAddrListErrorActionType | WithdrawAddrListResetActionType;
export type FetchWithdrawAddressListThunkActionType = ThunkAction<Promise<void>, void, void, WithdrawAddrListUnionActionType>;
export type FetchWithdrawAddressListThunkDispatchType = ThunkDispatch<void, void, WithdrawAddrListUnionActionType>;
export function FetchWithdrawAddressList (props: {coinCode: string, isV1API: boolean}): FetchWithdrawAddressListThunkActionType {
  return async (dispatch: FetchWithdrawAddressListThunkDispatchType) => {
    dispatch({ type: ActionTypes.WITHDRAW_ADDR_LIST_REQUEST });
    try {
      const response = await AccountAPI.withdrawAddressList(props.coinCode, props.isV1API);
      console.log('提币地址列表', JSON.stringify(response));
      if (response.success) {
        dispatch({
          type: ActionTypes.WITHDRAW_ADDR_LIST,
          withdrawAddrList: response.obj && response.obj.rows ? response.obj.rows : response.obj
        });
      } else {
        dispatch({
          type: ActionTypes.WITHDRAW_ADDR_LIST_ERROR,
          error: I18n.t(response.msgKey)
        });
      }
    } catch (e) {
      bugsnag.notify(new Error('[account] -- [FetchWithdrawAddressList] -- [error]: ' + e.toString()));
      dispatch({
        type: ActionTypes.WITHDRAW_ADDR_LIST_ERROR,
        error: I18n.t('system.error')
      });
    }
  };
};

export type WithdrawAddrUnionActionType = WithdrawAddrActionType | WithdrawAddrRequestActionType | WithdrawAddrErrorActionType | WithdrawAddrResetActionType;
export type CreateWithdrawAddressThunkActionType = ThunkAction<Promise<void>, void, void, WithdrawAddrUnionActionType>;
export type CreateWithdrawAddressThunkDispatchType = ThunkDispatch<void, void, WithdrawAddrUnionActionType>;
export function CreateWithdrawAddress (props: {coinCode: string, address: string, memo: ?string, remark: ?string, isV1API?: boolean}): CreateWithdrawAddressThunkActionType {
  return async (dispatch: CreateWithdrawAddressThunkDispatchType) => {
    dispatch({ type: ActionTypes.WITHDRAW_ADDR_REQUEST });
    try {
      const { coinCode, address, memo, remark, isV1API } = props;
      const response = await AccountAPI.createWithdrawAddress({ currency: coinCode, address, memo, remark, isV1API });
      console.log('新增提币地址', JSON.stringify(response));
      if (response.success) {
        dispatch({
          type: ActionTypes.WITHDRAW_ADDR,
          withdrawAddr: true
        });
      } else {
        dispatch({
          type: ActionTypes.WITHDRAW_ADDR_ERROR,
          error: I18n.t(response.msgKey)
        });
      }
    } catch (e) {
      bugsnag.notify(new Error('[account] -- [CreateWithdrawAddress] -- [error]: ' + e.toString()));
      dispatch({
        type: ActionTypes.WITHDRAW_ADDR_ERROR,
        error: I18n.t('system.error')
      });
    }
  };
};

export type WithdrawApplyUnionActionType = WithdrawApplyActionType | WithdrawApplyRequestActionType | WithdrawApplyErrorActionType | WithdrawApplyResetActionType;
export type WithdrawApplyThunkActionType = ThunkAction<Promise<void>, void, void, WithdrawApplyUnionActionType>;
export type WithdrawApplyThunkDispatchType = ThunkDispatch<void, void, WithdrawApplyUnionActionType>;
export function WithdrawApply (params: ApplyWithdrawRequestType & {ignoreError: boolean}): WithdrawApplyThunkActionType {
  return async (dispatch: WithdrawApplyThunkDispatchType) => {
    dispatch({ type: ActionTypes.WITHDRAW_APPLY_REQUEST });
    try {
      const response: ApplyWithdrawResponseType = await AccountAPI.applyWithdraw(params);
      if (response.success && response.obj) {
        dispatch({
          type: ActionTypes.WITHDRAW_APPLY,
          withdrawApply: response.obj
        });
      } else {
        if (params.ignoreError) {
          return;
        }
        dispatch({
          type: ActionTypes.WITHDRAW_APPLY_ERROR,
          error: I18n.t(response.msgKey)
        });
      }
    } catch (error) {
      bugsnag.notify(new Error('[account] -- [WithdrawApply] -- [error]: ' + error.toString()));
      if (params.ignoreError) {
        return;
      }
      dispatch({
        type: ActionTypes.WITHDRAW_APPLY_ERROR,
        error: I18n.t('system.error')
      });
    }
  };
};

export type SetCleanAccountDataThunkActionType = ThunkAction<void, void, void, AccountAssetInfoCleanActionType>;
export type SetCleanAccountDataThunkDispatchType = ThunkDispatch<void, void, AccountAssetInfoCleanActionType>;
export function SetCleanAccountData (): SetCleanAccountDataThunkActionType {
  return (dispatch: SetCleanAccountDataThunkDispatchType) => {
    dispatch({ type: ActionTypes.ACCOUNT_ASSET_INFO_CLEAN });
  };
};

export type ShowAssetAmountThunkActionType = ThunkAction<void, void, void, ShowAssetAmountActionType>;
export type ShowAssetAmountThunkDispatchType = ThunkDispatch<void, void, ShowAssetAmountActionType>;
export function ShowAssetAmount (isShow: boolean): ShowAssetAmountThunkActionType {
  return (dispatch: ShowAssetAmountThunkDispatchType) => {
    dispatch({ type: ActionTypes.SHOW_ASSET_AMOUNT, showAssetAmount: isShow });
  };
};

export type ShowMinCoinCodeThunkActionType = ThunkAction<void, void, void, ShowMinCoinCodeActionType>;
export type ShowMinCoinCodeThunkDispatchType = ThunkDispatch<void, void, ShowMinCoinCodeActionType>;
export function ShowMinCoinCode (isShow: boolean): ShowMinCoinCodeThunkActionType {
  return (dispatch: ShowMinCoinCodeThunkDispatchType) => {
    dispatch({ type: ActionTypes.SHOW_MIN_COINCODE, showMinCoinCode: isShow });
  };
};

export type FetchCurrentRateThunkActionType = ThunkAction<void, void, void, CurrentRateTypeActionType>;
export type FetchCurrentRateThunkDispatchType = ThunkDispatch<void, void, CurrentRateTypeActionType>;
export function FetchCurrentRate (type: string): FetchCurrentRateThunkActionType {
  return (dispatch: FetchCurrentRateThunkDispatchType) => {
    dispatch({
      type: ActionTypes.CURRENT_RATE_TYPE,
      rateType: type
    });
  };
};

export type AccountThunkActionsType = FetchCurrentRateThunkActionType | SetCleanAccountDataThunkActionType |
  WithdrawApplyThunkActionType | CreateWithdrawAddressThunkActionType | FetchWithdrawAddressListThunkActionType |
  FetchProductThunkActionType | AccountTransferThunkActionType | YuebaoTransferThunkActionType | FetchYuebaoProductThunkActionType |
  FetchTransactionsThunkActionType | FetchDepositAddressThunkActionType | CreateDepositAddressThunkActionType |
  FetchAccountHistoryThunkActionType | FetchAccountAssetsThunkActionType | FetchAccountInfoThunkActionType |
  FetchCurrencyThunkActionType | FetchCoinListThunkActionType | ShowAssetAmountThunkActionType | ShowMinCoinCodeThunkActionType;
