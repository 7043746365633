// @flow
import React, { Component } from 'react';
import type { Element } from 'react';
import FastImage from 'react-native-fast-image';
import { Text, View, TouchableWithoutFeedback } from 'react-native';
import _ from 'lodash'
import p from '../../../../utils/Transfrom';
import I18n from '../../../../utils/i18n';
import * as helper from '../../../../utils/helper';
import bugsnag from '../../../../utils/bugsnag';
// import { } from '../../../../styles/Common'
import HomeStyle from '../../../home/styles/home';

class Notice extends Component {
  constructor (props: any) {
    super(props);
    this.state = {
    };
  }

  componentDidMount () {

  }

  goNotice: () => void = (): void => {
    if (!helper.isSocialLogin(this.props.SocialReducer)) {
      return helper.navigate(this.props.navigation.navigate, 'Login');
    }

    const { myMisc } = this.props.TraderReducer;
    this.props.navigation.navigate('MyNotice', {
      userFullData: myMisc
    });
  };

  getUnread: () => string = (): string => {
    try {
      let unread = 0
      const { myMisc } = this.props.TraderReducer;
      const { socialUserInfo } = this.props.SocialReducer;
      if (this.props.HomeReducer.isLogin) {
        unread = myMisc?.unreadComment + myMisc?.unreadLike + myMisc?.unreadReply;
        const { noticeReward, noticeOtc, noticeSystem } = this.props.HomeReducer
        const noticeRewardUnread = _.filter(noticeReward, function (item: string): boolean { return JSON.parse(item).cid === socialUserInfo?.customerId && !JSON.parse(item).isRead })
        const noticeOtcUnread = _.filter(noticeOtc, function (item: string): boolean { return JSON.parse(item).cid === socialUserInfo?.customerId && !JSON.parse(item).isRead })
        const noticeSystemUnread = _.filter(noticeSystem, function (item: string): boolean { return !JSON.parse(item).isRead })

        unread += noticeRewardUnread.length + noticeOtcUnread.length + noticeSystemUnread.length
      }

      if (unread > 99) {
        unread = '99+'
      }

      return unread !== 0 ? unread.toString() : ''
    } catch (e) {
      bugsnag.notify(new Error('[home]--[getUnread]--error:' + e.toString()));
      return ''
    }
  };

  getNoticeView: () => Element<*> = (): Element<*> => {
    const { socialUserInfo } = this.props.SocialReducer || {};
    const userFullData = socialUserInfo;
    const unread = this.getUnread()
    // if (userFullData && userFullData.customerId) {
    return (
            <TouchableWithoutFeedback
            //   onPress={this.goNotice}
            >
              <View
                style={HomeStyle.systemNoticeStyle}>
                <FastImage
                  style={HomeStyle.systemNoticeImageStyle}
                  source={require('../../images/system_notice.webp')}
                />
                <View
                      style={HomeStyle.unreadView}>
                    <Text
                        style={HomeStyle.unreadText}>
                        999
                    </Text>
                </View>
                {/* {unread
                  ? (
                    <View
                      style={HomeStyle.unreadView}>
                      <Text
                        style={HomeStyle.unreadText}>
                        {unread}
                      </Text>
                    </View>
                    )
                  : null} */}
              </View>
            </TouchableWithoutFeedback>
    )
    // } else {
    //   return <View/>
    // }
  };

  render (): Element<*> {
    return (
        <View style={this.props.style}>
            {this.getNoticeView()}
        </View>
    )
  }
}
export default Notice;
