// @flow
import {
  StyleSheet
} from 'react-native';
import { alignItems, fontSize, textAlign, justifyContent, radius, width, colors, paddingHorizontal, height, fontWeight, lineHeight } from './Common'
const btn = {
  flexDirection: 'row',
  alignItems: alignItems.center,
  justifyContent: justifyContent.center,
  borderRadius: radius.r36,
  paddingHorizontal: paddingHorizontal.ph28,
  borderWidth: width.w4,
  borderColor: colors.white4,
  height: height.h72
}
export const Button = {
  tabButton: btn,
  tabButtonActive: {
    ...btn,
    ...{ borderColor: colors.white2, backgroundColor: colors.white2 }
  },
  tabButtonText: {
    color: colors.gray1,
    fontSize: fontSize.f28,
    fontWeight: fontWeight.f500,
    lineHeight: lineHeight.l36
  },
  tabButtonTextActive: {
    color: colors.black,
    fontWeight: fontWeight.f700
  },
  followBtnStyle: {
    width: width.w100,
    height: height.h48,
    backgroundColor: colors.lightPurple,
    borderRadius: radius.r26,
    alignItems: alignItems.center,
    justifyContent: justifyContent.center
  },
  followBtnTextStyle: {
    color: colors.purple,
    fontSize: fontSize.f20,
    fontWeight: fontWeight.f500,
    lineHeight: lineHeight.l26,
    textAlign: textAlign.center
  }

}
// eslint-disable-next-line flowtype/no-weak-types
export default function createBtnStyles (overrides: any = {}): any {
  /* $FlowFixMe */
  return StyleSheet.create({ ...Button, ...overrides })
}
