/* @flow */

import React, { Component } from 'react';
import type { Element } from 'react';
import {
  StyleSheet,
  Dimensions,
  View
} from 'react-native';
import { connect } from 'react-redux';
import p from '../../utils/Transfrom';
import Posts from './Posts';
import { defaultMapDispatchProp, customMapStateProp } from '../../reducers';

const width = Math.round(Dimensions.get('window').width);
const height = Math.round(Dimensions.get('window').height);

type PropsType = {};
type StateType = {};
type ItemType = {
  someoneUserId: string,
  currentUser: {}
};
export class News extends Component <PropsType, StateType> {
  constructor (props: PropsType) {
    super(props);
    this.state = {
      index: 0,
      currentFeed: 'recommend',
      typeIndex: 0,
      followList: [],
      showfollows: false,
      someoneUserId: '',
      currentUser: 'all'
    };
  }

  componentDidMount: () => void = () => {

  };

  onScrollViewLayout: () => void = () => {
    // console.log("onScrollBeginDrag")
    // alert("scroll");
  };

  onScrollChange: () => void = () => {
    // alert("scroll change");
  };

  currentFollowUser: (data: ItemType) => void = (data: ItemType) => {
    console.log('Str: ' + data.someoneUserId)
    this.setState({
      someoneUserId: data.someoneUserId,
      currentUser: data.currentUser
    })
  };

  // eslint-disable-next-line flowtype/require-return-type
  showList: () => Element<*> = (): Element<*> => {
    const { someoneUserId } = this.state;

    return (
        <Posts
          type={someoneUserId ? 'myFeed' : 'recommend'}
          squareType={'square_news'}
          index={someoneUserId ? 2 : 0}
          showNewsSwiper={true}
          activeRouteName={someoneUserId ? 'myFeed' : 'recommend'}
          postCellContainer={styles.postCellContainer}
          postCellOne={styles.postCellOne}
          postCellTwo={styles.postCellTwo}
          {...this.props}
          onScrollViewLayout={this.onScrollViewLayout}
          onScrollChange={this.onScrollChange}
          currentFollowUser={this.currentFollowUser}
        />
    );
  };

  // eslint-disable-next-line flowtype/require-return-type
  render (): Element<*> {
    return (
      <View style={{ height: height, flex: 1 }}>
          {this.showList()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  followItem: {
    paddingLeft: p(30),
    paddingRight: p(20),
    height: p(88),
    marginTop: p(30),
    flexDirection: 'row',
    alignItems: 'center'
  },
  postCellContainer: {
    width: (width - p(60 + 60 + 24)) / 3,
    height: (width - p(60 + 60 + 24)) / 3
  },
  postCellOne: {
    width: width - p(60 + 60 + 12),
    height: width - p(60 + 60 + 12)
  },
  postCellTwo: {
    width: (width - p(60 + 60 + 12)) / 2,
    height: (width - p(60 + 60 + 12)) / 2
  }
});

type PageOwnPropType = {};
const connector = connect(customMapStateProp<PageOwnPropType>(
  {
    SocialReducer: 1,
    HomeReducer: 1,
    AccountReducer: 1
  }
), defaultMapDispatchProp<PageOwnPropType>());
export default (connector(News): Class<News>);
