// @flow
import React from 'react';
import type { Element } from 'react';
import {
  View,
  StyleSheet,
  Text
} from 'react-native';
import FastImage from 'react-native-fast-image';
import { TouchableOpacity } from 'react-native-gesture-handler';

import I18n from '../utils/i18n';
import p from '../utils/Transfrom';

import type { SymbolList } from '../models/market';

import helper from '../utils/helper'

import { width, height, imageRounded40, paddingHorizontal, radius, colors, padding, margin } from '../styles/Common'

function LiquidationView (props: {
  languageCode: string,
  symbol: SymbolList,
  navigation: {
    navigate: (name: string, opt?: {})=> void
  },
  item: { type: string, interval: string, dataList: {}, symbol: string, exchange: string, data?: { totalBlastUsd24h: number } }
}): Element<*> {
  console.log(props.symbol)
  return (
    <TouchableOpacity activeOpacity={0.8} onPress={() => {
      try {
        helper.trackEvent('NewHome Click ExploreDataBTCLiquidatation', { item: JSON.stringify(props.item) });
      } catch (e) {
      }

      props.navigation.navigate('ExploreDataBTCLiquidatation',
        { TRACK_PARAM: helper.toTrackParam(props.item.type, props.item.exchange, props.item.symbol, props.item.interval) });
    }}>
      <View style={styles.card}>
        <View>
          <View style={styles.coinRow}>
            <FastImage
              source={{ uri: props.symbol.picturePath }}
               style={imageRounded40}
            />
            <Text style={styles.coin}>{props.item.symbol}{I18n.t('liquidatation')}</Text>

          </View>
          <Text style={[styles.coin, {
            fontSize: p(46),
            lineHeight: p(59),
            fontWeight: 'bold',
            marginTop: p(24)
          }]}>{isNaN(props.item.data?.totalBlastUsd24h) ? '--' : helper.fixNumString((props.item.data?.totalBlastUsd24h ?? 0) / 10 ** 6, 2) + 'M'}</Text>
        </View>
        <View style={{
          backgroundColor: '#EDEBF7',
          borderRadius: p(19),
          height: p(123),
          justifyContent: 'center',
          paddingHorizontal: p(25),
          marginTop: p(42)
        }}>
          <Text style={styles.coin}>24{I18n.t('hour')}</Text>
          <Text style={styles.coin}>{I18n.t('liquidatation_analysis')}</Text>
        </View>

      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({

  card: {
    width: width.w380,
    height: height.h340,
    paddingTop: padding.p30,
    backgroundColor: colors.white3,
    borderRadius: radius.r36,
    paddingHorizontal: paddingHorizontal.ph24
  },
  coinRow: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  coin: {
    fontSize: p(24),
    color: '#1F2126',
    lineHeight: p(33),
    marginLeft: margin.m10
  }

})

// eslint-disable-next-line flowtype/no-weak-types
const MemoizedLiquidationView: any = React.memo(LiquidationView);
export default MemoizedLiquidationView
