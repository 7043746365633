// @flow

import React, { Component } from 'react';
import type { Element } from 'react';
import {
  Text,
  Animated,
  View,
  DeviceEventEmitter
} from 'react-native';

import I18n from '../../../utils/i18n';
import { TouchableWithoutFeedback, TouchableOpacity, FlatList } from 'react-native-gesture-handler';
import type { CombinedReducerType, DispatchProps } from '../../../reducers';
import { defaultMapDispatchProp, customMapStateProp } from '../../../reducers';
import { Toast } from 'teaset';

import FastImage from 'react-native-fast-image';
import homeStyle from '../styles/home';
import { margin } from '../../../styles/Common';
import { connect } from 'react-redux';
import helper from '../../../utils/helper';
import bugsnag from '../../../utils/bugsnag';
import type { KycResultResponseType } from '../../../api/kyc';
import type { ChannelsResponseType } from '../../../api/credit';
import type { CheckDepositResponseType } from '../../../api/account';
import { KycAPI } from '../../../api/kyc';
import { CreditAPI } from '../../../api/credit';
import { AccountAPI } from '../../../api/account';
import { SpringScrollView } from 'react-native-spring-scrollview';
import LottieHeader from '../../../components/LottieHeader';
import { FetchDepositConfig, FetchGuideBeginnersConfig } from '../../../actions/HomeAction';
import type { GuideBeginnersConfigType } from '../../../actions/common';

// 定义Home页面的State类型
type DepositStateType = {

};
// 联合注入的类型？  缺少navigator 的注入声明
type DepositProsType = DepositStateType & CombinedReducerType & DispatchProps;

class Deposit extends Component<DepositProsType, DepositStateType> {
  _scrollView;

  constructor (props: DepositProsType) {
    super(props);
    this.state = {

    };
  }

  componentDidMount () {
    const that = this;
    this.firebaseEventListener1 = DeviceEventEmitter.addListener('firebaseWebReady', function () {
      that.props.dispatch(FetchGuideBeginnersConfig(that.props.HomeReducer.languageCode));
    })
  }

  checkDepositStatus: () => void = () => {
    const that = this;
    if (!that.props.HomeReducer.isLogin) {
      that.setState({ depositStatus: 'toLogin' });
      return;
    }

    that.IDCertify(function (data: { idCertifiedStatus: number, country: string }) {
      if (data && data.idCertifiedStatus !== 2) {
        that.setState({ depositStatus: 'toKYC' });
      } else {
        that.depositCheck();
      }
    });
  };

  toNavigationCredit: () => void = () => {
    if (!this.props.HomeReducer.isLogin) {
      this.props.navigation.navigate('Login');
      return;
    }

    CreditAPI.channels().then((response: ChannelsResponseType) => {
      //  初始化查询可用的渠道
      if (response.success) {
        const channels = response.obj || [];
        if (channels.length) {
          try {
            helper.trackEvent('NewHome Click CreditCard');
          } catch (e) {
          }

          this.props.navigation.navigate('Credit', { channels });
        } else {
          Toast.show({ text: I18n.t('credit_channels_close'), position: 'center' });
        }
      } else {
        Toast.show({ text: I18n.t(response.msgKey), position: 'center' });
      }
    }).catch((error: Error) => {
      Toast.show({ text: I18n.t('system.error'), position: 'center' });
      bugsnag.notify(new Error('[home->credit]--[toNavigationCredit]--error:' + error.toString()));
    });
  };

  // toNavigationC2C: () => void = () => {
  //   if (!this.props.HomeReducer.isLogin) {
  //     this.props.navigation.navigate('Login');
  //     return;
  //   }
  //   const { marginOption } = this.state;
  //   if (marginOption) {
  //     this.setState({ marginVisible: true });
  //     return;
  //   }
  //   const that = this;
  //   this.IDCertify(function (data: { idCertifiedStatus: number, country: string, isPhoneCertified: boolean }) {
  //     if (data && !data.isPhoneCertified && data.idCertifiedStatus !== 2) {
  //       // 手机号未绑定，并且未kyc
  //       that.setState({
  //         confirmVisible1: true,
  //         isNeedBindPhone: true,
  //         content: I18n.t('c2c_kyc_not_phone_kyc'),
  //         idCertifiedStatus: data.idCertifiedStatus
  //       })
  //     } else if (data && !data.isPhoneCertified) {
  //       // 手机号未绑定
  //       that.setState({
  //         confirmVisible1: true,
  //         isNeedBindPhone: true,
  //         content: I18n.t('c2c_kyc_not_phone'),
  //         idCertifiedStatus: data.idCertifiedStatus
  //       })
  //     } else if (data && data.idCertifiedStatus !== 2) {
  //       // 未kyc
  //       that.setState({
  //         confirmVisible1: true,
  //         isNeedBindPhone: false,
  //         content: I18n.t('c2c_kyc_not_kyc'),
  //         idCertifiedStatus: data.idCertifiedStatus
  //       })
  //     } else if (data && data.idCertifiedStatus === 2 && data.isPhoneCertified) {
  //       const { isMerchant } = that.props.HomeReducer;
  //       // 进行跳转、区分用户和商户  response.msgKey : 'system.data.not.found'/'success
  //       const activeRoute = !isMerchant ? 'BuyerCenter' : 'MerchantCenter';
  //       that.props.navigation.navigate(activeRoute);
  //     }
  //   })
  // };

  IDCertify: (callback: (data: { idCertifiedStatus: number, country: string }) => void) => void = (callback: (data: { idCertifiedStatus: number, country: string }) => void) => {
    // Teaset.ModalIndicator.show(I18n.t('loading'));
    KycAPI.kycResult(this.props.HomeReducer.userInfo?.isMigration)
      .then((res: KycResultResponseType) => {
        if (res.success) {
          if (res.obj) {
            callback && callback(res.obj)
          }
        } else {
          if (res.code === '1040') {
            const data = { idCertifiedStatus: 0, country: '' };
            callback && callback(data)
          }
        }
      })
      .catch((error: Error) => {
        console.log(error)
        bugsnag.notify(new Error('[home]--[KycAPI.kycResult]--error:' + error.toString()));
      });
  };

  depositCheck: () => void = () => {
    AccountAPI.checkDeposit()
      .then((res: CheckDepositResponseType) => {
        if (res.success && res.obj) {
          this.setState({ depositStatus: 'toDeposit' });
        } else {
          this.setState({ depositStatus: 'depositFinish' });
        }
      })
      .catch((error: Error) => {
        console.log(error)
        bugsnag.notify(new Error('[home]--[AccountAPI.checkDeposit]--error:' + error.toString()));
      })
  };

  toDeposit: () => void = () => {
    const { depositStatus } = this.props.HomeReducer;
    if (depositStatus === 'toLogin') {
      this.props.navigation.navigate('Login');
    } else if (depositStatus === 'toKYC') {
      this.props.navigation.navigate('IDCertify');
      try {
        helper.trackEvent('NewHome Click Deposit ToKYC');
      } catch (e) {
      }
    } else {
      this.props.navigation.navigate('BuyCrypto');
      try {
        helper.trackEvent('NewHome Click Deposit BuyCrypto');
      } catch (e) {
      }
    }
  };

  depositGift: () => void = () => {
    let url;
    if (this.props.HomeReducer.languageCode === 'en') {
      url = 'https://kikitrade.zendesk.com/hc/en-hk/articles/4403373660431-First-Deposit-Huge-Rebate-Deposit-over-HK-10-000-awards-you-HK-250-';
    } else {
      url = 'https://kikitrade.zendesk.com/hc/zh-tw/articles/4403373660431--%E9%A6%96%E6%AC%A1%E5%85%85%E5%80%BC%E5%A4%A7%E5%9B%9E%E8%B4%88-%E5%85%85%E5%80%BC%E6%BB%BF-HK-10-000-%E9%80%81%E4%BD%A0-HK-250-'
    }
    try {
      helper.trackEvent('NewHome Click Deposit Gift');
    } catch (e) {
    }

    this.props.navigation.navigate('WebViewPage', {
      url: url,
      title: I18n.t('webview_event'),
      TRACK_PARAM: url
    });
  };

  giftCard: () => void = () => {
    try {
      helper.trackEvent('Page Click GiftCardBanner', { USER_ID: global.USER_ID });
    } catch (e) {
      bugsnag.notify(new Error('[home]--[helper.trackEvent-Page Click GiftCardBanner]--error:' + e.toString()));
    }
    try {
      helper.trackEvent('NewHome Click GiftCard');
    } catch (e) {
    }

    if (!global.serviceHealth) {
      DeviceEventEmitter.emit('showStopServiceModal', true);
      return;
    }
    this.props.navigation.navigate('GiftCard');
  };

  _onRefresh = () => {
    this.props.dispatch(FetchDepositConfig());
    this.props.dispatch(FetchGuideBeginnersConfig(this.props.HomeReducer.languageCode));
    this._scrollView.endRefresh();

    // setTimeout(() => {
    //   this._scrollView.endRefresh();
    // }, 2000);
  };

  renderSectionItem: ({ item: GuideBeginnersConfigType, index: number }) => Element<*> = ({ item, index }: { item: GuideBeginnersConfigType, index: number }): Element<*> => {
    let guideIcon;
    switch (item.type) {
      case 'deposit&withdraw': guideIcon = require('../../../images/deposit_icon.webp'); break;
      case 'about': guideIcon = require('../../../images/about_icon.webp'); break;
      case 'trade': guideIcon = require('../../../images/trade_icon.webp'); break;
      case 'common': guideIcon = require('../../../images/question_icon.webp'); break;
      case 'security': guideIcon = require('../../../images/security_icon.webp'); break;
      default: guideIcon = require('../../../images/deposit_icon.webp'); break;
    };

    return (
      <TouchableWithoutFeedback
        activeOpacity={0.8}
        onPress={() => {
          try {
            helper.trackEvent('NewHome Click GuideBeginners', { item: JSON.stringify(item) });
          } catch (e) {
          }

          this.props.navigation.navigate('WebViewPage', {
            url: item.url,
            title: I18n.t('deposit_guideForBeginners'),
            TRACK_PARAM: item.url
          });
        }}
      >
        <View style={homeStyle.teachingCardViewStyle}>
          <View style={homeStyle.teachingTitleStyle}>
            <FastImage
              style={homeStyle.teachingCardIconStyle}
              source={guideIcon}
            />
            <Text style={homeStyle.teachingTitleTextStyle}>{item.type}</Text>
          </View>
          <View style={homeStyle.teachingContentViewStyle}>
            <Text
              style={homeStyle.teachingContentTextStyle}
              numberOfLines={2}
              ellipsizeMode={'tail'}
            >{item.title}</Text>
          </View>
        </View>

      </TouchableWithoutFeedback>

    )
  };

  render (): Element<*> {
    const { animatedScrollYValue = new Animated.Value(0) } = this.state;
    const { guideBeginnersConfig, depositStatus } = this.props.HomeReducer;

    let statusButtonText;
    let statusTitleText;
    switch (depositStatus) {
      case 'toLogin':
        statusButtonText = I18n.t('deposit_login');
        statusTitleText = I18n.t('deposit_step1');
        break;
      case 'toKYC':
        statusButtonText = I18n.t('deposit_KYC');
        statusTitleText = I18n.t('deposit_step2');
        break;
      case 'toDeposit':
        statusButtonText = I18n.t('deposit_deposit');
        statusTitleText = I18n.t('deposit_step3');
        break;
      default:
        statusButtonText = I18n.t('deposit_login');
        statusTitleText = I18n.t('deposit_step1');
        break;
    }

    return (
      // eslint-disable-next-line flowtype/no-weak-types
      <SpringScrollView ref={(component: any): void => (this._scrollView = component)}
        onScroll={Animated.event(
          [
            {
              nativeEvent: {
                contentOffset: { y: animatedScrollYValue }
              }
            }
          ],
          {
            useNativeDriver: false,
            // eslint-disable-next-line flowtype/no-weak-types
            listener: (event: any) => {
              this.props.setAnimatedScrollYValue && this.props.setAnimatedScrollYValue(animatedScrollYValue)
            }
          }
        )}

        onRefresh={this._onRefresh}
        refreshHeader={LottieHeader}>
        {depositStatus === 'depositFinish'
          ? null
          : <View>
            <Text style={[homeStyle.sectionTitleStyle, { marginTop: margin.m28 }]}>{statusTitleText}</Text>
            <View style={homeStyle.sectionStyle}>
              <View style={homeStyle.registerCardStyle}>
                <View style={homeStyle.statusViewStyle}>

                  <View style={homeStyle.loginIconViewStyle}>
                    <FastImage
                      style={homeStyle.statusIconStyle}
                      source={
                        depositStatus === 'toLogin'
                          ? require('../../../images/unchecked.webp')
                          : require('../../../images/checked.webp')
                      }
                    />
                    <Text style={homeStyle.statusTextStyle}>{I18n.t('deposit_login')}</Text>
                  </View>

                  <View style={homeStyle.kycIconViewStyle}>
                    <View style={homeStyle.kycViewStyle}>
                      <View style={depositStatus === 'toLogin' ? homeStyle.statusLineStyle1 : homeStyle.statusLineStyle} />
                      <FastImage
                        style={homeStyle.statusIconStyle}
                        source={
                          depositStatus === 'toDeposit'
                            ? require('../../../images/checked.webp')
                            : require('../../../images/unchecked.webp')
                        }
                      />
                      <View style={depositStatus === 'toDeposit' ? homeStyle.statusLineStyle : homeStyle.statusLineStyle1} />
                    </View>
                    <Text style={depositStatus === 'toLogin' ? homeStyle.statusTextStyle1 : homeStyle.statusTextStyle}>{I18n.t('deposit_KYC')}</Text>
                  </View>

                  <TouchableOpacity
                    activeOpacity={0.8}
                    onPress={this.depositGift}>
                    <View style={homeStyle.depositIconViewStyle}>
                      <FastImage
                        style={homeStyle.statusIconStyle}
                        source={require('../../../images/gift.webp')}
                      />
                      <Text style={homeStyle.statusTextStyle1}>{I18n.t('deposit_deposit')}</Text>
                    </View>
                  </TouchableOpacity>

                </View>

                <TouchableWithoutFeedback
                  activeOpacity={0.8}
                  onPress={this.toDeposit}
                >
                  <View style={homeStyle.statusButtonStyle}>
                    <Text style={homeStyle.statusButtonTextStyle}>{statusButtonText}</Text>
                    <FastImage
                      style={homeStyle.arrowIconStyle}
                      source={require('../../../images/purple_arrow.webp')}
                    />
                  </View>
                </TouchableWithoutFeedback>

              </View>
            </View>
          </View>

        }

        <Text style={homeStyle.sectionTitleStyle}>{I18n.t('deposit_deposit')}</Text>

        <View style={homeStyle.depositSectionStyle}>

          {
            this.props.HomeReducer.remoteConfig.bankCardOpen
              ? <TouchableOpacity
                activeOpacity={0.8}
                onPress={() => {
                  if (!this.props.HomeReducer.remoteConfig.fiatOpen) {
                    Toast.show({
                      text: I18n.t('common_not_open'),
                      type: 'error'
                    });
                    return;
                  }
                  if (!this.props.HomeReducer.isLogin) {
                    this.props.navigation.navigate('Login');
                    return;
                  }
                  try {
                    helper.trackEvent('NewHome Click FiatDeposit');
                  } catch (e) {
                  }
                  this.props.navigation.navigate('FiatDeposit');
                }}>
                <View style={homeStyle.depositViewStyle}>
                  <FastImage
                    style={homeStyle.depositIconStyle}
                    source={require('../../../images/dollar.webp')}
                  />
                  <View style={homeStyle.depositTextViewStyle}>
                    <Text style={homeStyle.depositTitleStyle}>
                    {I18n.t('deposit_bankDeposit_title')}
                    </Text>
                    <Text style={homeStyle.depositSubtitleStyle}>
                    {I18n.t('deposit_bankDeposit_desc')}
                    </Text>
                  </View>
                  <FastImage
                    style={homeStyle.depositToNext}
                    source={require('../../../images/toNext3.webp')}
                  />
                </View>
              </TouchableOpacity>
              : null
          }

          {
            this.props.HomeReducer.remoteConfig.creditOpen
              ? <TouchableOpacity
                activeOpacity={0.8}
                onPress={this.toNavigationCredit}>
                <View style={homeStyle.depositViewStyle}>
                  <FastImage
                    style={homeStyle.depositIconStyle}
                    source={require('../../../images/credit.webp')}
                  />
                  <View style={homeStyle.depositTextViewStyle}>
                    <Text style={homeStyle.depositTitleStyle}>
                    {I18n.t('deposit_creditDeposit_title')}
                    </Text>
                    <Text style={homeStyle.depositSubtitleStyle}>
                    {I18n.t('deposit_creditDeposit_desc')}
                    </Text>
                  </View>
                  <FastImage
                    style={homeStyle.depositToNext}
                    source={require('../../../images/toNext3.webp')}
                  />
                </View>
              </TouchableOpacity>
              : null
          }

          {
            this.props.HomeReducer.remoteConfig.c2cOpen
              ? <TouchableOpacity
                activeOpacity={0.8}
              // onPress={this.toNavigationC2C}
              >
                <View style={homeStyle.depositViewStyle}>
                  <FastImage
                    style={homeStyle.depositIconStyle}
                    source={require('../../../images/bitcoin.webp')}
                  />
                  <View style={homeStyle.depositTextViewStyle}>
                    <Text style={homeStyle.depositTitleStyle}>
                    {I18n.t('deposit_c2cDeposit_title')}
                    </Text>
                    <Text style={homeStyle.depositSubtitleStyle}>
                    {I18n.t('deposit_c2cDeposit_desc')}
                    </Text>
                  </View>
                  <FastImage
                    style={homeStyle.depositToNext}
                    source={require('../../../images/toNext3.webp')}
                  />
                </View>
              </TouchableOpacity>
              : null
          }
        </View>

        <Text style={homeStyle.sectionTitleStyle}>{I18n.t('deposit_giftCard')}</Text>

        <View style={homeStyle.giftCardViewStyle}>
          <View style={homeStyle.giftCardImageViewStyle}>
            <FastImage
              resizeMode={'contain'}
              style={homeStyle.giftCardImageStyle}
              source={require('../../../images/giftCard.webp')}
            />
          </View>
          <View style={homeStyle.giftCardTextViewStyle}>
            <Text style={homeStyle.giftCardTextStyle}>
            {I18n.t('deposit_giftCard_desc')}
            </Text>
            <TouchableOpacity
              activeOpacity={0.8}
              onPress={this.giftCard}>
              <View style={homeStyle.purchaseBtnStyle}>
                <Text style={homeStyle.purchaseBtnTextStyle}>{I18n.t('deposit_giftCard_button')}</Text>
              </View>
            </TouchableOpacity>
          </View>

        </View>

        <Text style={homeStyle.sectionTitleStyle}>{I18n.t('deposit_guideForBeginners')}</Text>

        <View style={homeStyle.teachingViewStyle}>
          <FlatList
            data={guideBeginnersConfig}
            horizontal
            contentContainerStyle={homeStyle.flatListContentStyle}
            showsHorizontalScrollIndicator={false}
            renderItem={this.renderSectionItem}
            keyExtractor={(item: {}, index: number): string => {
              return index.toString()
            }}
          />
        </View>

      </SpringScrollView>

    )
  }
}

const connector = connect(customMapStateProp<DepositProsType>(
  {
    HomeReducer: 1
  }
), defaultMapDispatchProp<DepositProsType>());
export default (connector(Deposit): Class<Deposit>);
