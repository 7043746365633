export const myState = {
  aboutUs: {
    title: 'About Us',
    data: [{
      name: 'Security',
      link: 'https://www.baidu.com/'
    }, {
      name: 'Careers',
      link: 'https://www.baidu.com/'
    }, {
      name: 'Newsroom',
      link: 'https://www.baidu.com/'
    }, {
      name: 'Conditions',
      link: 'https://www.baidu.com/'
    }]
  },
  support: {
    title: 'Support',
    data: [{
      name: 'Security',
      link: 'https://www.baidu.com/'
    }, {
      name: 'Careers',
      link: 'https://www.baidu.com/'
    }, {
      name: 'Newsroom',
      link: 'https://www.baidu.com/'
    }, {
      name: 'Conditions',
      link: 'https://www.baidu.com/'
    }]
  },
  learn: {
    title: 'Learn',
    data: [{
      name: 'Security',
      link: 'https://www.baidu.com/'
    }, {
      name: 'Careers',
      link: 'https://www.baidu.com/'
    }, {
      name: 'Newsroom',
      link: 'https://www.baidu.com/'
    }, {
      name: 'Conditions',
      link: 'https://www.baidu.com/'
    }]
  },
  contactUs: {
    title: 'Contact Us',
    data: [{
      name: 'facebook',
      link: 'https://www.facebook.com/kikitrade'
    }, {
      name: 'instagram',
      link: 'https://www.instagram.com/kikitrade_official/'
    }, {
      name: 'twitter',
      link: 'https://twitter.com/KikitradeHQ'
    }, {
      name: 'youtube',
      link: 'https://www.baidu.com/'
    }]
  }
}

// Link:

// download now button的 link: https://app.adjust.com/l5wvb52_ddrk1a1
// Kikitrade logo: https://www.kikitrade.com/
// facebook: https://www.facebook.com/kikitrade
// linkedin: https://www.linkedin.com/company/kikitrade/
// Ig: https://www.instagram.com/kikitrade_official/
// twitter: https://twitter.com/KikitradeHQ
