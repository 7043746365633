// @flow
import React, { Component } from 'react';
import type { Element } from 'react';
import {
  StyleSheet,
  Text,
  Animated,
  Easing,
  View
} from 'react-native';
import FastImage from 'react-native-fast-image';
import _ from 'lodash'
import p from '../utils/Transfrom';
import helper from '../utils/helper';
import type { CombinedReducerType, DispatchProps } from '../reducers';
import type { ContentItemType } from './LongTextPublishedComponent'
import { width, colors, margin, fontSize, imageRounded32 } from '../styles/Common'
const striptags = require('striptags');
const html2json = require('html2json').html2json;

declare function onChange(index: number): void;
type OwnPropType = {
  data: [], // TODO
  scrollHeight: number,
  delay: number,
  duration: number,
  kbContainer: {}, // TODO
  onChange: typeof onChange
};

type ItemDataType = {
  link: string,
  id: string,
  text: string,
  customerMisc: {
    nickName: string,
    customerId: string,
    follow: boolean,
    avatar: string
  },
  images: Array<string>,
  videoThumbnail: string,
  timestamp: number,
  isVideo: boolean,
  videoSource: string,
  commentCount: number,
  likeCount: number,
  like: boolean,
  shareCount: number
};

type StateType = {
  translateValue: number,
  // 滚屏高度
  scrollHeight: number,
  // 滚屏内容
  kb_content: [], // TODO
  // Animated.View 滚动到的 y轴坐标
  kb_tempValue: number,
  // 最大偏移量
  kb_contentOffsetY: number,
  // 每一次滚动切换之前延迟的时间
  delay: number,
  // 每一次滚动切换的持续时间
  duration: number,
  enableAnimation: boolean
};

type ProsType = OwnPropType & CombinedReducerType & DispatchProps;

export default class ScrollUpDown extends Component <ProsType, StateType> {
  constructor (props: ProsType) {
    super(props);
    const translateValue = new Animated.ValueXY({ x: 0, y: 0 });
    // translateValue.addListener(({ x, y }) => {
    //   // Log('value',x,y)
    // });
    this.state = {
      translateValue: translateValue,
      // 滚屏高度
      scrollHeight: this.props.scrollHeight || p(44),
      // 滚屏内容
      kb_content: [],
      // Animated.View 滚动到的 y轴坐标
      kb_tempValue: 0,
      // 最大偏移量
      kb_contentOffsetY: 0,
      // 每一次滚动切换之前延迟的时间
      delay: this.props.delay || 3000,
      // 每一次滚动切换的持续时间
      duration: this.props.duration || 500,
      enableAnimation: true
    };
  }

  componentDidMount: () => void = () => {
    const content = this.props.data || [];

    const h = (content.length + 1) * this.state.scrollHeight;
    this.setState(
      {
        kb_content: content.concat(content[0]),
        kb_contentOffsetY: h
      },
      () => {
        // 开始动画
        this.startAnimation();
      }
    );
  };

  createKbItem: (item: ItemDataType, index: number) => Element<*> = (item: ItemDataType, index: number): Element<*> => {
    let text = helper.isHtmlTag(item.text) ? item.text : item.text;
    let striphtmls = striptags(item.text, ['img', 'br', 'source', 'video'])
    striphtmls = striphtmls.replace(/<br[^>]*>/g, '\n')
    striphtmls = striphtmls.replace(/<(?!img)(?!br)(?!source)(?!video)/g, '&lt;')
    const itemDataObj = html2json(striphtmls)
    const textArr = _.filter(itemDataObj?.child || [], function (item: ContentItemType): boolean { return item.node === 'text' && ((item.text || '').replace(/\n/g, '')).trim() !== '' })
    text = textArr[0]?.text || ''
    text = helper.replaceHtmlEntity(text);
    return (
      <View
        key={index}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          height: this.state.scrollHeight
        }}>
        {item.customerMisc && item.customerMisc.avatar
          ? (
          <FastImage
            style={imageRounded32}
            source={{ uri: item.customerMisc.avatar }}
          />
            )
          : (
          <FastImage
            style={imageRounded32}
            source={require('../images/defaultAvatar.webp')}
          />
            )}
        <Text
          numberOfLines={1}
          ellipsizeMode='tail'
          style={styles.commentText}>
          {text}
        </Text>
      </View>
    );
  };

  render (): Element<*> {
    return (
      <View
        style={[
          styles.kbContainer,
          {
            height: this.state.scrollHeight,
            flexDirection: 'row',
            paddingHorizontal: p(10),
            borderRadius: p(22)
          },
          this.props.kbContainer
        ]}>
        {this.state.kb_content.length !== 0
          ? (
          <Animated.View
            style={[
              { flexDirection: 'column' },
              {
                transform: [{ translateY: this.state.translateValue.y }]
              }
            ]}>
            {this.state.kb_content.map(this.createKbItem.bind(this))}
          </Animated.View>
            )
          : null}
      </View>
    );
  }

  startAnimation: () => void = () => {
    if (this.state.enableAnimation) {
      if (!this.animation) {
        this.animation = setTimeout(() => {
          this.animation = null;
          this._startAnimation();
        }, this.state.delay);
      }
    }
  };

  componentWillUnmount () {
    if (this.animation) {
      clearTimeout(this.animation);
    }
    if (this.state.translateValue) {
      this.state.translateValue.removeAllListeners();
    }
  }

  _startAnimation: () => void = () => {
    this.state.kb_tempValue -= this.state.scrollHeight;
    if (this.props.onChange) {
      const index = Math.abs(this.state.kb_tempValue) / this.state.scrollHeight;
      this.props.onChange(index < this.state.kb_content.length - 1 ? index : 0);
    }
    Animated.sequence([
      // Animated.delay(this.state.delay),
      Animated.timing(this.state.translateValue, {
        isInteraction: false,
        toValue: { x: 0, y: this.state.kb_tempValue },
        duration: this.state.duration, // 动画持续的时间（单位是毫秒），默认为500
        easing: Easing.linear,
        useNativeDriver: true
      })
    ]).start(() => {
      if (
        this.state.kb_tempValue - this.state.scrollHeight ===
        -this.state.kb_contentOffsetY
      ) {
        // 快速拉回到初始状态
        this.state.translateValue.setValue({ x: 0, y: 0 });
        this.state.kb_tempValue = 0;
      }
      this.startAnimation();
    });
  };
}

const styles = StyleSheet.create({
  kbContainer: {
    // 必须要有一个背景或者一个border，否则本身高度将不起作用
    backgroundColor: colors.transparent,
    overflow: 'hidden'
  },
  kb_text_c: {
    fontSize: fontSize.f18,
    color: colors.red
  },
  commentText: {
    color: colors.gray1,
    fontSize: fontSize.f18,
    marginLeft: margin.m10,
    width: width.w230
  }
});
