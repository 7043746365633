// @flow
import config from '../utils/config';
import request from '../utils/request';
import helper from '../utils/helper';
import type { CommonResponse, ExchangeResult, PageResult } from './base';
export const AccountCategory = {
  // 0 币币账户
  NORMAL: 0,
  // 1 清算账户
  SETTLE: 1,
  // 2 法币账户
  FIAT: 2,
  // 3 理财账户
  INVEST: 3,
  // 4 余额宝账户
  TREASURE: 4,
  // 5 余额宝确认账户
  BEARING: 5,
  // 6 CFD合约逐仓账户
  CFD_CONTRACT_FIXED: 6,
  // 7 CFD合约清算账户
  CFD_CONTRACT_SETTLE: 7,
  // 8 CFD合约手续费账户
  CFD_CONTRACT_FEE: 8,
  // 9 跟单账户
  COPY: 9,
  // 10 跟单清算账户
  COPY_SETTLE: 10,
  // 11 跟单清算账户
  COPY_FEE: 11,
  // 12 系统会员费用账户
  MEMBER_FEE: 12,
  // 13 CFD合约全仓账户
  CFD_CONTRACT_CROSS: 13
}
/**
 * 用户账户对象
 */
export interface CoinAccount {
  id: string,
  coinCode: string,
  hotMoney: number,
  coldMoney: number,
  usdtMoney: number,
  usdMoney: number,
  btcMoney: number,
  picturePath: ?string,
  name: string,
  languageCode: ?string,
  keepDecimalForCoin: number,
  showKeepDecimalForCoin: number,
  allowWithdraw: boolean,
  allowDeposit: boolean,
  sort: number,
  leastDepositNum: number,
  totalRevenue: number,
  inPositionMoney: number,
  accountNetAssets: number,
  freeMargin: number,
  inPositionMargin: number,
  unrealizedPnl: number,
  marginRate: number
}

// ------------------------- accountAssets
export interface AccountAssets {
  coinAccountsWithAssets: CoinAccount[],
  coinAccountList: CoinAccount[]
};
export type AccountAssetsResponseType = ExchangeResult<AccountAssets> & CommonResponse;

// ------------------------- balance
export interface Balance {
  id: string,
  version: number,
  customerId: string,
  hotMoney: number,
  coldMoney: number,
  userName: string,
  currencyType: string, // ,
  status: number,
  category: ?string, // null,
  lendMoney: number,
  coinName: string, // ETH,
  coinCode: string, // ETH,
  website: string, // ,
  psitioNaveragePrice: ?number, // null,
  psitioProtectPrice: ?number, // null,
  sumCost: ?number, // null ,
  trueName: ?string, // null,
  disableMoney: number,
  surname: string,
  saasId: string,
  created: string, // 2020-07-10 10:55:33,
  modified: string, // 2020-07-10 10:55:33,
  totalRevenue: number,
  inPositionMoney: number,
  accountNetAssets: number,
  freeMargin: number,
  inPositionMargin: number,
  unrealizedPnl: number,
  marginRate: number,
  coinPrecision: number // 8
}

export type BalanceResponseType = ExchangeResult<Balance> & CommonResponse;

// ------------------------- applyWithdraw (ExDmTransaction)
export interface ApplyWithdrawRequestType {
  coinCode: string,
  address: string,
  amount: string,
  tokenId: string,
  memo?: ?string,
  isV1API: boolean
};

export interface ExdmTransaction {
  transactionNum: string,
  orderNo: string,
  txId: string,
  customerId: string,
  customerName: string,
  accountId: string,
  channel: string,
  transactionType: number, // 1 deposit, 2 withdraw
  transactionMoney: number,
  status: number, // withdraw:  1 apply , 2 approve 3 reject 4 complete 5 faild, 6 not in chain   deposit: 10 init 11 unconfirmed 12 finish 13 fail
  userId: string,
  currencyType: string,
  coinCode: string,
  website: string,
  fee: number,
  remark: ?string,
  rejectionReason: ?string,
  address: string,
  memo: string,
  category: number,
  chainStatus: number,
  modified: string,
  created: string
}

export type ApplyWithdrawResponseType = CommonResponse & ExchangeResult<ExdmTransaction>;

// coinMap
export interface ExProduct {
  id: number,
  name: string,
  totalNum: number,
  issueTotalMoney: number,
  issuePrice: number,
  issueTime: string,
  issueState: number,
  coinCode: string,
  issueId: number,
  issueName: string,
  splitMinCoin: number,
  stock: number,
  sort: number,
  pamState: number,
  circulation: number,
  openBell: number,
  language: string,
  open_c2c: number,
  c2cBuyPrice: number,
  c2cSellPrice: number,
  openTibi: string, // 0, 1
  openDeposit: number,
  isRecommend: number,
  openingTime: string,
  closeTime: string,
  openAndclosePlateTime: string,
  transactionType: number,
  picturePath: string,
  prepaidFeeRate: number,
  paceFeeRate: number,
  oneTimePaceNum: number,
  leastPaceNum: number,
  oneDayPaceNum: number,
  keepDecimalForCoin: number,
  showKeepDecimalForCoin: number,
  giveCoin: number,
  paceType: string,
  paceCurrecy: string,
  unCardOneTimePaceNum: number,
  unCardLeastPaceNum: number,
  unCardOneDayPaceNum: number,
  alert: string,
  memoLabel: string,
  tokenSource: string,
  addressType: number,
  tips: string,
  network: number,
  withdrawAlertMin: number,
  withdrawAlertMid: number,
  withdrawBalanceAlertMid: number,
  withdrawAlertMax: number,
  withdrawBalanceAlertMax: number,
  status: number,
  fiat: number,
  leastDepositNum: number
}

export type CoinListResponseType = ExchangeResult<{ [key: string]: ExProduct }> & CommonResponse;

// currency
export interface Currency {
  name: string,
  issueState: number,
  currency: string,
  alert: string,
  memoLabel: string,
  tokenSource: string,
  addressType: number,
  tips: string,
  network: number,
  allowWithdraw: boolean,
  allowDeposit: boolean,
  relatedProducts: string
}

export type CurrencyResponseType = ExchangeResult<Currency> & CommonResponse;

export interface AccountInfo {
  coldMoney: number,
  // 可用
  hotMoney: number,
  // 净资产
  rmbAccountNetAsse: number,
  // 总资产
  sumRmbfund: number,
  // USDT总资产
  sumMoney: number,
  // 美金总资产
  sumUsdMoney: number,
  // 比特币总资产
  sumBtcMoney: number,
  // cfd合约持仓中，跟单中金额
  inPositionMoney: number
}

export type InfoResponseType = ExchangeResult<AccountInfo> & CommonResponse;

export interface VirtualAccountLog {
  accountNum: string,
  created: number,
  id: string,
  operateType: number,
  coldMoney: number,
  operateId: string,
  businessType: number,
  menu: string,
  customerId: string,
  visible: boolean
}

export type HistoryVirtualAccountLogResponseType = ExchangeResult<PageResult<VirtualAccountLog>> & CommonResponse;

export type TransactionListResponseType = ExchangeResult<PageResult<ExdmTransaction>> & CommonResponse;

// depositAddress
export interface CoinAddressAccount {
  address: string,
  coinCode: string,
  memo: string,
  account: string,
  channelId: string,
  accountNumber: string,
  customerId: string,
  config: string,
  category: number,
  visible: boolean,
  extra: string,
  createTime: string,
  updateTime: string
}

export type DepositAddressResponseType = ExchangeResult<CoinAddressAccount> & CommonResponse;
// yuebao
export interface TreasureProduct {
  id: number,
  name: string,
  currency: string,
  type: number,
  subjectId: number,
  rate: string,
  rateInterval: number,
  rateType: number, // （0-单利、1-复利）
  confirmDays: number,
  confirmTime: string,
  start: string,
  end: string,
  status: number, // （ 0-disable，1-enable）
  minAmount: number,
  agreement: string
}

export type YuebaoProductResponseType = ExchangeResult<TreasureProduct[]> & CommonResponse;

// withdraw fee
export interface WithdrawFee {
  relatedProducts: ?ExProduct[],
  product: ExProduct,
  isReal: boolean
}

export interface ConfigProduct {
  unauthorizedDailyMaxAmount: number,
  unauthorizedOnceMaxAmount: number,
  unauthorizedOnceMinAmount: number,
  onceMinAmount: number,
  onceMaxAmount: number,
  dailyMaxAmount: number
}
export interface WithdrawConfig {
  available: number,
  maxAvailable: number,
  configs: ConfigProduct[]
}

export interface AssetsDetail {

}

export interface CheckDeposit {

}

export type WithdrawFeeResponseType = ExchangeResult<WithdrawFee> & CommonResponse;

export type AssetsDetailResponseType = ExchangeResult<AssetsDetail> & CommonResponse;
export type WithdrawConfigResponseType = ExchangeResult<WithdrawConfig> & CommonResponse;

export type CheckDepositResponseType = ExchangeResult<CheckDeposit> & CommonResponse;

export interface WithdrawAddress {
  id: string,
  publicKeyName: string,
  address: string,
  currency: string,
  customerId: string,
  remark: string,
  memo: string,
  extra: string

}

const mappingType = {
  type1: 'DEPOSIT',
  type2: 'WITHDRAW'
}

const mappingStatus = {
  status1: 'WITHDRAW_APPLY',
  status2: 'WITHDRAW_APPROVED',
  status3: 'WITHDRAW_REJECTED',
  status4: 'WITHDRAW_COMPLETED',
  status5: 'WITHDRAW_FAILED',
  status10: 'DEPOSIT_INITIAL',
  status11: 'DEPOSIT_APPLY',
  status12: 'DEPOSIT_FINISHED',
  status13: 'DEPOSIT_FAILED'
}

export type WithdrawAddressListResponseType = ExchangeResult<WithdrawAddress[]> & CommonResponse;

const branch = 'prod'

// class definition
class AccountApi {
  accountAssets (params: ?{ accountCategory: ?number }): Promise<AccountAssetsResponseType> {
    if (!params) {
      params = {};
    }
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.account.assets +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call(params, URL);
  };

  balance (params: { coinCode: string }): Promise<BalanceResponseType> {
    if (!params) {
      params = {};
    }
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.account.balance +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call(params, URL);
  };

  delWithdrawAddress (params: { id: string, currency: string, isV1API: boolean }): Promise<CommonResponse> {
    let URL = ''
    if (params.isV1API && branch === 'prod') {
      URL =
        config.api.host_api_v1 +
        config.api.accountV1.delWithdrawAddress
          .replace(/{currency}/, params.currency)
          .replace(/{id}/, params.id)
      return request.delete.call(params, URL);
    } else {
      URL =
        config.api.host_newapi +
        config.api.account.delWithdrawAddress +
        '?' +
        helper.mapToQueryString(params);
      return request.post.call(params, URL);
    }
  };

  applyWithdraw (params: ApplyWithdrawRequestType): Promise<ApplyWithdrawResponseType> {
    let URL = ''
    if (params.isV1API && branch === 'prod') {
      URL =
        config.api.host_api_v1 +
        config.api.accountV1.withdrawApply +
        '?saas_id=kiki&currency=' +
        params.coinCode +
        '&address=' +
        params.address +
        '&memo=' +
        (params.memo || '') +
        '&amount=' +
        params.amount +
        '&tokenId=' +
        params.tokenId;

      return request.post.call(params, URL);
    } else {
      URL =
        config.api.host_newapi +
        config.api.account.withdrawApply +
        '?saas_id=kiki&currency=' +
        params.coinCode +
        '&address=' +
        params.address +
        '&memo=' +
        (params.memo || '') +
        '&amount=' +
        params.amount +
        '&tokenId=' +
        params.tokenId;

      return request.post.call(params, URL);
    }
  };

  coinList (params ?: {sort?: string}): Promise<CoinListResponseType> {
    const URL =
      config.api.host_api_v1 +
      config.api.account.coinList +
      '?' +
      helper.mapToQueryString(params ?? {});

    return request.get
      .call({}, URL);
  };

  currency (params: { coinCode: string }): Promise<CurrencyResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.account.currency +
      '/' +
      params.coinCode;
    return request.get
      .call({}, URL);
  }

  /**
   * 查询账户总额度汇总
   * @param params
   * @returns {*}
   */
  info (params: { accountCategory: string }): Promise<InfoResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.account.info +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL)
  }

  history (params: { offset: number, limit: number, coinCode: string, accountCategory: number }): Promise<HistoryVirtualAccountLogResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.account.history +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL)
  }

  depositAddress (params: { currency: string, isV1API?: boolean }): Promise<DepositAddressResponseType> {
    let URL = ''
    if (params && params.isV1API === true && branch === 'prod') {
      URL =
        config.api.host_api_v1 +
        config.api.accountV1.depositAddress.replace(/{currency}/, params.currency) +
        '?' +
        helper.mapToQueryString(params);
      return request.post.call({}, URL);
    } else {
      URL =
        config.api.host_newapi +
        config.api.account.depositAddress +
        '?' +
        helper.mapToQueryString(params);
      return request.post.call({}, URL);
    }
  }

  showDepositAddress (params: { currency: string, isV1API?: boolean }): Promise<DepositAddressResponseType> {
    let URL = ''
    if (params && params.isV1API === true && branch === 'prod') {
      URL = config.api.host_api_v1 +
        config.api.accountV1.showDepositAddress.replace(/{currency}/, params.currency) +
        '?currency=' + params.currency;
      return request.get.call({}, URL);
    } else {
      URL = config.api.host_newapi +
        config.api.account.showDepositAddress +
        '?currency=' + params.currency;
      return request.get.call({}, URL);
    }
  }

  transactionList (params: { coinCode?: string, currency?: string, type?: number | string, offset?: number, limit?: number, status?: number | string, isV1API?: boolean }): Promise<TransactionListResponseType> {
    let URL = ''

    if (params && params.isV1API === true && branch === 'prod') {
      params.type = Number(params.type ?? 0) ? mappingType['type' + (params.type ?? '').toString()] : ''
      params.status = Number(params.status ?? 0) ? mappingStatus['status' + (params.status ?? '').toString()] : ''

      URL =
        config.api.host_api_v1 +
        config.api.accountV1.transactionList + '?' + helper.mapToQueryString(params);
      return request.get.call({}, URL);
    } else {
      URL =
        config.api.host_newapi +
        config.api.account.transactionList + '?' + helper.mapToQueryString(params);
      return request.post.call({}, URL);
    }
  }

  yuebaoProduct (): Promise<YuebaoProductResponseType> {
    const limit = 10;
    const offset = 0;
    const URL =
      `${config.api.host}${config.api.account.yuebaoProductList}` +
      `?saas_id=kiki&offset=${offset}&limit=${limit}`;
    return request.get.call({}, URL);
  }

  yuebaoTransfer (params: { currency: string, amount: number, direction: 0 | 1 }): Promise<CommonResponse> {
    // 余额宝本身的接口写的有问题， error msg key是无法返回的。
    const URL =
      `${config.api.host}${config.api.account.yuebaoTransfer}` + '?' + helper.mapToQueryString(params);

    return request.post.call({}, URL);
  }

  accountTransfer (params: { currency: string, fromAccountCategory: number, toAccountCategory: number, amount: number }): Promise<CommonResponse> {
    const URL =
      config.api.host_newapi +
      config.api.account.transfer + '?' + helper.mapToQueryString(params);

    return request.post.call({}, URL);
  }

  withdrawFee (coinCode: string): Promise<WithdrawFeeResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.account.withdrawFee +
      '?saas_id=kiki&coinCode=' + coinCode;

    return request.post.call({}, URL)
  }

  assetsDetail (params: { coinCode: string }): Promise<AssetsDetailResponseType> {
    const URL =
      config.api.host_api_v1 +
      config.api.account.assetsDetail.replace(/{coinCode}/, params.coinCode)

    return request.get.call({}, URL)
  }

  withdrawConfig (params: { coinCode: string }): Promise<WithdrawConfigResponseType> {
    const URL =
      config.api.host_api_v1 +
      config.api.account.withdrawConfig.replace(/{coinCode}/, params.coinCode)

    return request.get.call({}, URL)
  }

  withdrawAddressList (coinCode: string, isV1API: boolean): Promise<WithdrawAddressListResponseType> {
    let URL = ''
    if (isV1API === true && branch === 'prod') {
      URL =
        config.api.host_api_v1 +
        config.api.accountV1.withdrawAddress.replace(/{currency}/, coinCode) +
        '?currency=' + coinCode;
      console.log(URL)
      return request.get.call({}, URL)
    } else {
      URL =
        config.api.host_newapi +
        config.api.account.withdrawAddress +
        '?currency=' + coinCode;
      return request.get.call({}, URL)
    }
  }

  createWithdrawAddress (params: { currency: string, address: string, memo: ?string, remark: ?string, isV1API?: boolean }): Promise<CommonResponse> {
    let URL = ''
    if (params && params.isV1API === true && branch === 'prod') {
      URL =
        config.api.host_api_v1 +
        config.api.accountV1.withdrawAddress.replace(/{currency}/, params.currency) + '?' + helper.mapToQueryString(params);
      return request.post.call({}, URL)
    } else {
      URL =
        config.api.host_newapi +
        config.api.account.withdrawAddress + '?' + helper.mapToQueryString(params);
      return request.post.call({}, URL)
    }
  }

  checkDeposit (): Promise<CheckDepositResponseType> {
    const URL =
      config.api.host_api_v1 +
      config.api.account.depositStatus;
    return request.get.call({}, URL)
  }
}

const AccountAPI: AccountApi = new AccountApi();
export { AccountAPI };
