// @flow

import React, { Component } from 'react';
import { Text, View, TouchableWithoutFeedback, StyleSheet } from 'react-native';
import p from '../../../../utils/Transfrom';
import Logo from './Logo';
import HeaderRight from './HeaderRight';

export default function MyTabBar (props: any): React$Node {
  const state = props.state;
  const descriptors = props.descriptors;
  const navigation = props.navigation;
  return (
        <View style={styles.headerContainer} >
            <Logo style={styles.logo} />
            <View style={styles.tabBarLabelGroup} >
                {/* eslint-disable-next-line flowtype/no-weak-types */}
                {state.routes.map((route: any, index: number): React$Node => {
                  const { options } = descriptors[route.key];
                  let label =
                    options.tabBarLabel !== undefined
                      ? options.tabBarLabel
                      : options.title !== undefined
                        ? options.title
                        : route.name;
                  const isFocused = state.index === index;
                  if (options.tabBarLabel && typeof options.tabBarLabel === 'function') {
                    label = options.tabBarLabel({ focused: isFocused, color: isFocused ? '#6F40EE' : '#696F7F' });
                  }
                  const onPress = () => {
                    const event = navigation.emit({
                      type: 'tabPress',
                      target: route.key,
                      canPreventDefault: true
                    });

                    if (!isFocused && !event.defaultPrevented) {
                      navigation.navigate(route.name);
                    }
                  };

                  return (
                    <TouchableWithoutFeedback
                        testID={options.tabBarTestID}
                        onPress={onPress}
                        key={index}
                    >
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: '#fff', height: 88, marginRight: 32 }}>
                            <Text style={{ color: isFocused ? '#7A34F7' : '#000000', fontSize: 16, lineHeight: 88, fontFamily: 'Helvetica' }}>
                                {label}
                            </Text>
                        </View>
                    </TouchableWithoutFeedback>
                  );
                })}
            </View>
            <HeaderRight style={styles.headerRight} />
        </View>
  );
}
const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    // paddingLeft: p(120),
    // paddingRight: p(120),
    paddingLeft: 80,
    paddingRight: 80,
    backgroundColor: '#fff',
    shadowColor: 'rgba(0,0,0,0.08)',
    shadowOpacity: 1,
    shadowOffset: {
      width: 0,
      height: 2
    },
    marginBottom: 2,
    zIndex: 99

  },
  logo: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // marginRight: p(80),
    marginRight: 35,
    width: 169,
    height: 30
  },
  headerRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  tabBarLabelGroup: {
    flexDirection: 'row'
  }
})
