// @flow
const api = {
  host: 'https://newapi.kikitrade.com/',
  host_newapi: 'https://api.kikitrade.com/',
  host_api_v1: 'https://api.kikitrade.com/',
  kline_url: 'https://upload.kikitrade.com/tradingview_dark_theme/index.html',
  supportUrl: 'https://cschat.antcloud.com.cn/index.htm?tntInstId=1Ab_9Aku&scene=SCE00529069',
  profit_chart_url: 'https://upload.kikitrade.com/kikitrade_profit_chart/index.html',
  explore_areaChart_url: 'https://upload.kikitrade.com/kikitrade_chart/areaChart.html',
  explore_compareLineChart_url: 'https://upload.kikitrade.com/kikitrade_chart/compareLineChart.html',
  explore_stackBarChart_url: 'https://upload.kikitrade.com/kikitrade_chart/stackBarChart.html',
  explore_VSChart_url: 'https://upload.kikitrade.com/kikitrade_chart/vs.html',
  explore_analysisChart_url: 'https://upload.kikitrade.com/kikitrade_chart/analysis.html',
  liveness_react_app_url: 'https://upload.kikitrade.com/liveness_react_app/index.html',
  market_chart_url: 'https://upload.kikitrade.com/kikitrade_chart/spot.html',
  explore_areaChart_remote_url: 'https://chart.kikitrade.com/kiki_chart_node',
  upload_api: 'https://upload.kikitrade.com/',
  share: 'https://share.kikitrade.com/',
  kiki_website_url: 'https://m.kikitrade.com/',
  simple_kline_host: 'https://api.kikitrade.com/cfd/kline/init',
  new_simple_kline_host: 'https://api.kikitrade.com/v1/market/klines/init',
  google_web_clientId: '389380007773-8qrs1its8dptlcme0agi2759bvhoj3pu.apps.googleusercontent.com'
};

export default api
