// @flow

import React, { Component } from 'react';
import type { Element } from 'react';
import {
  Text,
  View
} from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

import HomeStyle from '../styles/home'
import MemoizedKol from '../../../components/MemoizedKol'
import { margin } from '../../../styles/Common'
import I18n from '../../../utils/i18n';

import type { HotKOL } from '../../../models/social';

type KolStateType = {

};
type KolProsType = {};

export default class KOL extends Component<KolProsType, KolStateType> {
  constructor (props: KolProsType) {
    super(props);
    this.state = {

    };
  }

  renderKoltem: ({item: HotKOL, index: number}) => Element<*> = ({ item, index }: {item: HotKOL, index: number}): Element<*> => {
    let itemstr = ''
    try {
      console.log(item)
      itemstr = JSON.stringify(item ?? {})
    } catch (e) {

    }
    return (
      <MemoizedKol itemstr={itemstr} isLogin={ this.props.isLogin} navigation={this.props.navigation} follow={this.props.follow}/>
    )
  };

  FlatListItemSeparator48: () => Element<*> = (): Element<*> => {
    return (
      <View style={{ marginRight: margin.m48 }} />
    );
  };

  render (): Element<*> {
    const { kolData } = this.props
    return (
      <>
            <Text style={HomeStyle.sectionTitleStyle}>{I18n.t('kikiStars')}</Text>
            <View style={HomeStyle.sectionStyle}>
              <FlatList
                data={kolData}
                horizontal
                contentContainerStyle={HomeStyle.flatListContentStyle}
                showsHorizontalScrollIndicator={false}
                ItemSeparatorComponent={this.FlatListItemSeparator48}
                renderItem={this.renderKoltem}
                keyExtractor={(item: {id: string}): string => item.id}
              />
            </View>

      </>
    )
  }
}
