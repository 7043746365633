// @flow

const obj: { [key: string]: string} = 
{
   "no_internet":"No network, please check the network link",
   "click_retry":"Click retry",
   "load_failed":"Load failed",
   "share_fail":"Share Failed",
   "share_success":"Share success",
   "line_not_install":"line not installed",
   "imagePicker_Title":"Please select image",
   "imagePicker_chooseFromLibraryButtonTitle":"Choose from Libray...",
   "imagePicker_takePhotoButtonTitle":"Take photo",
   "regMessage01":"I have read and accept the",
   "regMessage02":"Users Service Agreement",
   "regMessage03":"Please check the email,fill in the confirmation code to complete the confirmation process",
   "regMessage04":"Have not received yet?",
   "regMessage05":"Send again",
   "gaAuthentication":"Google Authenticator",
   "gaVerificationCode":"GA VerificationCode",
   "smsVerification":"SMS Verification",
   "smsVerifyTip01":"Please fill in the SMS verification code to complete the verification first.",
   "gaVerifyTip01":"Please fill in the Google verification code to complete the verification first.",
   "unBindPhoneTip":"Unbinding phone number",
   "unBindGaTip":"Unbinding Google Authenticator",
   "rebate":"Recommended Rebate",
   "logoutTip1":"Do you want to Sign out?",
   "logoutReminder":"Log out reminder",
   "auth":"Identity Authentication",
   "registrationServiceAgreement":"Registration Service Agreement",
   "phone":"Phone",
   "rules":"Rules",
   "country":"Country",
   "firstName":"First Name",
   "lastName":"Last Name",
   "gender":"Gender",
   "login":"Login",
   "goLogin":"Login",
   "about":"About",
   "aboutus":"About Us",
   "kline":"Kline",
   "sure":"Sure",
   "activity":"Activity",
   "gotit":"Got it",
   "back":"Back",
   "supportCenter":"Support Center",
   "resetPass":"Reset password",
   "loginToRemind":"Login reminder",
   "loginToRemindTip1":"Your login token has expired.",
   "loginToRemindTip2":"Please log in again.",
   "loginToRemindTip3":"Your account has been logged in on another device. If that is not you, please change your password immediately",
   "loginToRemindTip4":"Your login token has expired. Please log in again.",
   "passportId":"Passport Id",
   "depositAddress":"Deposit Address",
   "depositPaymentID":"Deposit ",
   "accountBalance":"Balance",
   "firstNamePlaceHolder":"Please enter your first name",
   "lastNamePlaceHolder":"Please enter your last name",
   "passportIdPlaceHolder":"Please enter your Passport ID",
   "codeisnull":"Please enter the code",
   "googleAuth":"Google Authenticator",
   "enterGoogleCode":"Enter Google Code",
   "passwordSetting":"Password Setting",
   "referralReward":"Get Rebate",
   "ReferralHistory":"Referral History",
   "referFriends":"Invite friends to trade on Dipbit and get 20% transaction fee as referral reward",
   "referralCode":"My referral code : ",
   "referralLink":"My referral link : ",
   "reward":"Referral reward history",
   "referralOne":"1.Only the users who sign up with your referral link or code are counted as your referrals.",
   "referralTwo":"2.When your friend finish the trading successfully, your referral reward will be calculated by DBT at 00:00 of the next day against real-time rate and allocated to your account (There might be a delay)",
   "referralThree":"3.DipBit reserves the rights of final explanation for readjusting referral rules.",
   "safetyVerification":"Safety verification",
   "sSMS":"SMS verification code",
   "sEmail":"Email verification code",
   "sGoogle":"Google verification code",
   "signOut":"Sign out",
   "male":"Male",
   "birthDay":"BirthDay",
   "female":"Female",
   "account":"Account",
   "TradingHall":"TRADING",
   "home":"HOME",
   "reApply":"Re Apply",
   "trading":"Trade",
   "market":"Market",
   "oldPassword":"Original Password",
   "newPassword":"New Password",
   "confirmNewPassword":"Confirm New Password",
   "NewsInformation":"News",
   "personCenter":"Personal Center",
   "passportCover":"Passport Cover",
   "copySuccess":"Copy Success!",
   "confirm":"Confirm",
   "yes":"yes",
   "region":"Region",
   "submit":"Submit",
   "send":"send",
   "copy":"Copy",
   "paste":"paste",
   "onOrders":"On Orders",
   "enable":"Enable",
   "search":"Search",
   "loginPassword":"Login Password",
   "resetBtnText":"Reset",
   "tradePassword":"Trade Password",
   "forgotPassword":"Forgot Password",
   "resetPassword":"Reset Password",
   "enter_email":"Enter email",
   "total":"Total",
   "toAll":"All",
   "tips":"Tips",
   "withdraw":"Withdraw",
   "BTCDeposit":"BTC Deposit",
   "BTCWithdraw":"BTC Withdraw",
   "allRecord":"All Records",
   "amount":"Amount",
   "address":"Address",
   "financialRecords":"Financial Records",
   "phoneNumberPlaceholder":"Enter your phone number",
   "smsCodePlaceholder":"Enter SMS code",
   "copyAddress":"Copy Address",
   "showQRCode":"Show QR Code",
   "addAddress":"Add Address",
   "history":"History",
   "min":"Min",
   "fee":"Fee",
   "cancel":"Cancel",
   "verifyNow":"Verify Now",
   "setup":"Set up",
   "ok":"Ok",
   "password":"Password",
   "type":"Type",
   "buy":"Buy",
   "pwConfirm":"Confirm password",
   "price":"Price",
   "phoneVerificationCode":"Phone Verification Code",
   "enterNewAddress":"Enter New Address",
   "enterLabel":"Enter Label",
   "enter_verification_code":"Enter Verification Code",
   "deleteAddress":"Delete Address",
   "transAction":"Transaction",
   "addressManagement":"Address Management",
   "enterOrPasteAddress":"Enter or paste address",
   "passportInformationPage":"Personal passport information page",
   "passportSignature":"Submit my passport and personal signature",
   "withdraw_24h_limit":"24h Withdrawal Limit",
   "withdrawTip1":"The maximum withdrawal amount of the day",
   "withdrawTip11":"The minimum amount of single withdrawal",
   "withdrawTip12":"The maximum amount of single withdrawal",
   "withdrawTip13":"For the security reason, when you use a new withdraw address, we will manually review your withdrawal. Please wait for phone calls or emails from our staff.",
   "withdrawTip2":"For the security reason, when you use a new withdraw address, we will manually review your withdrawal. Please wait for phone calls or emails from our staff.",
   "withdrawTip3":"Can not withdraw now",
   "withdrawTip4":"You have to set up the phone number to continue withdraw",
   "withdrawTip5":"Withdrawal application failed",
   "withdrawTip6":"Please verify the identity to raise the withdrawal efficiency.",
   "withdrawTip7":"Withdraw application successfully",
   "withdrawTip8":"Your withdrawal application has been received and the withdrawal is prepared for process.",
   "withdrawTip9":"Your withdrawal requirement exceeds the limit. ",
   "withdrawTip10":"We are preparing to open vip channel, you can apply for VIP in the future to increase the amount.",
   "withdrawTip14":"Trading reminder",
   "withdrawTip15":"You're going to spend ",
   "withdrawTip16":"of your available balance on trading and you're going to make a deal?",
   "depositTip1":"Do not add any other digital assets except",
   "depositTip2":"After recharging to the above address, you need to wait for the confirmation of the entire network node, after ",
   "depositTip3":" it will be seen in your balance.",
   "depositTip4":"Please confirm the computer and browser security to prevent the information from tamperring with or leaking.",
   "depositTip5":"The address copied successfully",
   "networkConfirmation":"network confirmation",
   "depositTip6":"to the above address or the asset will not be returned.",
   "depositTip7":"Minimum deposit amount",
   "depositTip8":"Any deposits less than the minimum will not be credited or refunded.",
   "depositTip9":"Send only",
   "depositTip10":"to this deposit address.",
   "passportTip1":"Make sure that the photos are complete and clearly visible, only the JPG image format is supported.",
   "passportTip2":"Make sure that the photos are complete and clearly visible, the passport must be valid, and only the JPG image format is supported.",
   "passportTip3":"Please upload a photo with your ID card, front view and personal signature. The personal signature contains the \"Plateform name\" and the current date. Please ensure that the contents of the photos and personal signatures are clearly visible.",
   "passportTip4":"Please select a photo",
   "passvail":"Password format is incorrect",
   "gaVerification":"Google Auth Verification",
   "twopwd_is_diff":"The two times entered passwords are not matched",
   "oldpwd_no_null":"Original password can not be empty",
   "newpwd_no_null":"New password can not be empty",
   "enterNewPassAgain":"Enter new password again",
   "loginpwd_no_null":"Login password can not be empty",
   "pwd_no_null":"Password can not be empty",
   "Save_the_QR_code":"Save the QR code",
   "deposit_address":"Deposit Address",
   "deposit_paymentId":"Deposit Address",
   "depositXRP1":"Both a ",
   "depositXRP2":"to DipBit",
   "depositXRP3":"Do not add any other digital assets except ",
   "depositXRP4":"to the above address or the asset will not be returned.",
   "depositXRP5":"After recharging to the above address, you need to wait for the confirmation of the entire network nodes, after ",
   "depositXRP6":"it can been checked in your asset.",
   "depositXRP7":"and an Address is required to successfully deposit your",
   "depositXRP8":"Deposit Confirmation",
   "Got_it":"Got it",
   "depositIOT1":"deposit address can not be reused. After transferring or depositing on the blockchain, any further transfer or deposit to this same address will not be credited.",
   "depositIOT2":"Do not add any other digital assets except ",
   "depositIOT3":"to the above address or the asset will not be returned.",
   "depositIOT4":"After recharging to the above address, you need to wait for the confirmation of the entire network nodes, after ",
   "serial_number":"Serial Number",
   "transaction_id":"Transaction ID",
   "register":"Register",
   "please_write_email":"Email",
   "please_write_pwd":"Password",
   "close":"Close",
   "network":"Please check the network connection",
   "emailisnull":"Mail address cannot be empty",
   "emailvail":"Email format is incorrect",
   "captcha":"Captcha",
   "invitationCode":"Invatation code is not required",
   "reg_success":"Registration successfully, please log in",
   "login_success":"login successfully",
   "next":"Next step",
   "sell":"Sell",
   "pwformat":"Password(6-20 letters + numbers)",
   "orders":"Open Orders",
   "bid":"Bid",
   "ask":"Ask",
   "spotsell":"Spot sell",
   "spotbuy":"Spot buy",
   "tradlogin":"Trade after login",
   "available":"Available",
   "deposit":"Deposit",
   "withdrawal":"Withdrawal",
   "verification":"Identity Authentication",
   "time":"Time",
   "number":"Number",
   "newandold_no_null":"New login password can not be the same with the original one",
   "exception":"Server exception, please visit later",
   "exceptionLogin":"Please login and visit！",
   "agreement":"Please read the registration service agreement first！",
   "spotAssets":"ASSETS",
   "money":"money",
   "company":"company",
   "hide":"Hide",
   "pulldown":"Refreshing the latest updated data:",
   "refreshing":"Refreshing the latest updated data:",
   "realesePull":"Release to refresh the latest update:",
   "details":"Details",
   "officialNews":"Official News",
   "updateNow":"update",
   "later":"Later",
   "done":"Done",
   "deleteAccount":"Confirm to delete the account",
   "deleteConfirm":"Delete",
   "continue":"Continue",
   "dismiss":"dismiss",
   "emailAlready":"Email Already in Use",
   "depositIOT5":"can been checked in the asset.",
   "AccountLockout":"Account Lockout",
   "attentions":"Attention",
   "AccountLockout2":"Your account has been locked",
   "referredFriends":"Invite friends",
   "referredReward":"Referral reward",
   "referredRatio":"Referral ratio",
   "myReferralCode":"My invitation code",
   "copyCode":"Copy Code",
   "copyLink":"Copy Link",
   "referredTip5":"The code copied successfully",
   "referredTip6":"The link copied successfully",
   "securityPolicy":"Security Policy",
   "balanceHome":"Balance",
   "tradeCoins":"Trade Coins",
   "noTradeMarket":"No Trading Market",
   "financialDesc":"designed by a professional team.",
   "financialDesc1":"Super high annually rate of return, almost 60%.",
   "financialDesc2":"Withdraw free during and after this activity.",
   "financialDesc3":"Platform guarantee the return while waive your possible loss.",
   "rulesTitle":"Trading Rules",
   "rulesDesc1":"• Subscription Rate:  Subscription Rate is 0",
   "rulesDesc2":"• Rules:  Annual rate begins at 8.00% from the first month and grows at 2.00% by month to up to 20.00%. Pay interest on a monthly basis and repay the principal after one year.",
   "rulesDesc3":"• Value Date:  Value date starts from T+1, that is, interest begins accruing on the following day after subscription.",
   "rulesDesc4":"• Redemption Date:  You can choose to transfer out on redemption date after maturity of each month.",
   "rulesDesc5":"• Redemption Rate:  Redemption Rate is 0",
   "rulesDesc6":"• Arrival time:  Real-time arrival.",
   "purchaseTypeTitle":"How much do you plan to purchase?",
   "noApplyTitle":"No application qualification",
   "noApplyDesc1":"Application requirements: account balance is more than ",
   "noApplyDesc2":"Your Account Balance:  ",
   "noApplyDesc3":"The application is free and the account balance is only used for verification.",
   "depositBtn":"Deposit",
   "applySuccess":"Successful application",
   "applySuccessDesc":"If the number of purchases is insufficient, the project will be cancelled",
   "BuyUSDT":"Buy USDT",
   "limitCounts":"Transaction limit:from ",
   "limitCounts2":" to 50,000",
   "selectPaymentMethod":"Select Payment Method",
   "pleasePay":"Please  Pay",
   "pleasePayOnTime":"Please pay on time.",
   "confirmToCancel":"Confirm to cancel",
   "haventPaid":"I confirm that I haven't paid.",
   "payDesc1":"• Your transfer will be credited to seller's account. During the transaction, the assets for sale will be kept on our platform.",
   "payDesc2":"• Do not remark any other words besides Reference  ID for all transfers.",
   "payDesc3":"• Please complete your payment in the allowed time and click on 'I have paid' .After the seller confirms the receipt, the assets will be transferred to your account automatically.",
   "payDesc4":"• The platform does not charge you automatically.Please use your real name to transfer to the above account.",
   "complaint":"Complaint",
   "complaintDesc":"The assets will be frozen after this complaint submit successfully, and the customer service will intervene in the transaction until the end.Malicious complainants will be frozen accounts. The complaint can be made in 5 minutes after the buyer complete the payment.",
   "confirmPayment":"Confirm payment",
   "payDesc":"Please confirm you have paid the order?",
   "waitForRelease":"Wait for release…",
   "waitDesc":"Wait for the seller to release.",
   "ifNotPay":"If you have not paid",
   "ifNotReceived":"If you have not received your coins",
   "notice":"Notice",
   "paymentNoticeDesc":"Merchant payment methods changed, Please refresh and try again.",
   "refresh":"Refresh",
   "cancelNoticeDesc":"You have too many cancellations in the same day, and you will not be able to release advertisement for buying in by 0 o'clock of next day!",
   "completed":"Completed",
   "completedPay":"You have completed this payment.",
   "cancelled":"Cancelled",
   "cancelledPay":"You cancelled this payment.",
   "receiptQRCode":"Receipt QR Code",
   "complainting":"Complainting...",
   "complaintingDesc":"Please wait for customer service.",
   "copied":"copied successfully",
   "orderTimeout":"Payment timed out, the order cancelled.",
   "maximumCNY":"Maximum should be 50,000.00",
   "minimumCNY":"Minimum should be ",
   "duplicateOrder":"Duplicate order",
   "duplicateDetail":"You already have a single transaction in process, please buy again after the completion of the process!",
   "checkOrder":"Check order",
   "language":"Language",
   "english":"English",
   "skipBtnText":"Skip",
   "safetyTitle":"Safety",
   "safetyDesc1":"Advanced system architecture built to protect",
   "safetyDesc2":"against DDoS and other potential threats. ",
   "safetyDesc3":"More than 98% of digital assets are stored in",
   "safetyDesc4":"multi-signature, cold wallets. ",
   "speedTitle":"Speed",
   "speedDesc1":"Our programs are precisely developed,",
   "speedDesc2":"mixed with reliable efficiency and ",
   "speedDesc3":"stability. ",
   "serviceTitle":"Service",
   "serviceDesc1":"7*24 hours online, if any doubts, please",
   "serviceDesc2":"contact us via email and telegram for real- ",
   "serviceDesc3":"time settlement.  ",
   "teamTitle":"Team",
   "teamDesc1":"Core team of Dipbit comes from Tencent, Baidu and Ali.  With professional syatematic traders,",
   "teamDesc2":"dipbit creates the most convenient and safest exchange globally.",
   "teamDesc3":"",
   "getStarted":"Get started",
   "withFiatMoney":"Fiat",
   "dipbitreasure":"FatPocket",
   "optimizeAssets":"Optimize assets",
   "otcDesc1":"OTC Trading has not been opened yet,",
   "otcDesc2":"please look forward to it.",
   "deatils":"Deatils",
   "emptyFavorite":"You don't have a favorite",
   "name":"Name",
   "vol":"Vol",
   "oneVol":"Vol",
   "lastPrice":"Last Price",
   "chg":"Chg%",
   "alreadyLatestVersion":"Already the latest version",
   "version":"Version",
   "telegram":"Telegram",
   "productDescription":"Product Description",
   "publicKeyNumber":"Public key number",
   "addTime":"Add time",
   "label":"Label",
   "areYouSure":"Are you sure?",
   "noAddress":"You haven't created the address yet",
   "accessCamera":"Can we access your Camera?",
   "setProfile":"We need access so you can set your profile pic",
   "noWay":"No way",
   "openSettings":"Open Settings",
   "QRScan":"Align the QR code/bar code with the box to scan",
   "scanQRCode":"Scan QR code",
   "googleAuthStep1":"1.Install “Google Authenticator” and run it, Click “+” on right top corner，Select “Manual input”。",
   "googleAuthStep2":"2.Paste the “Secret”, click “Finish”",
   "googleAuthStep3":"3.Copy “Google Authorization Code” and paste it here at “Google Code” input.DO NOT delete the Google authorization account. If you deleted it by mistake, you can reset it.",
   "linked":"Linked",
   "unbindingGoogleAuthentication":"Want to unbinding Google Authenticator ？",
   "passwordResetComplete":"Password reset complete",
   "rejected":"Rejected",
   "verified":"Verified",
   "unverified":"Unverified",
   "turnedOn":"Turned on",
   "unopened":"Unopened",
   "notLinked":"Not Linked",
   "pleaseInputTelephone":"Please input telephone",
   "SMSSendSuccessfully":"SMS Send Successfully",
   "failedVerificationCode":"Failed to get the verification code. Please check the network connection",
   "pleaseInputSmsCode":"Please input sms code",
   "unbindingPhoneNumber":"Want to unbinding Phone Number?",
   "authenticationVerified":"Your Authentication is being verified…",
   "identityNotApproved":"Your identity was not approved.",
   "failedReason":"Failed reason:",
   "signedUpTime":"Signed up Time",
   "loading":"loading",
   "referFriendsTrade":"Refer friends to trade in Dipbit and get ",
   "transactionFeesReward":"transaction fees as referral reward.",
   "coin":"Coin",
   "allocatedTime":"Allocated Time",
   "resetPasswordLogin":"Reset Password Successfully! You can login now",
   "sentVerificationCode":"We have sent a verification code to this",
   "fillVerificationCode":"Please fill in this verification code.",
   "checkInternetTryAgain":"Internet not available, Cross check your internet connectivity and try again",
   "fiat":"Fiat",
   "totalBalance":"Total Balance",
   "depositFirst":"No assets, please deposit first",
   "coinWithoutAssets":"Coin without assets",
   "noResult":"No result",
   "haveNoFinancialRecords":"You have no financial records",
   "withdrawSuspended":"Withdraw suspended",
   "depositSuspended":"Deposit suspended",
   "bindMobileBeforeWithdraw":"Please bind your mobile phone number before withdraw",
   "haveNoOrder":"You have no order",
   "haveNoDeposit":"You have no record of deposit",
   "inputRightCNY":"Please input right CNY",
   "inputRightUSDT":"Please input right USDT",
   "gets":"Get",
   "needHelp":"Need help?",
   "submittedSuccessfully":"Submitted successfully",
   "complaintReason":"Complaint Reason",
   "enterComplaintReason":"Please enter the details of complaint reason",
   "paymentMethod":"Payment method:",
   "wechat":"Wechat",
   "alipay":"Alipay",
   "cancelOrder":"Cancel Order",
   "payee":"Payee:",
   "referenceID":"Reference ID",
   "seller":"Seller",
   "orderID":"Order ID",
   "haveNoOrders":"You have no orders",
   "allStatus":"All Status",
   "processing":"Processing",
   "complete":"Complete",
   "Complaining":"Complaining",
   "Paid":"Paid",
   "Unpaid":"Unpaid",
   "Cancelled":"Cancelled",
   "lessThan":"less than",
   "moreThan":"more than",
   "annualYield":"Promising max annually",
   "investTable":"History Net Value Curve",
   "fixedAnnualYield":"stable annually",
   "notFixedType":"Progressive",
   "fixedType":"Conservative",
   "lockupPeriod":"Lock-up period",
   "days":"Days",
   "progress":"least amount",
   "purchaseQuantity":"Purchase quantity:",
   "openPurchaseTime":"Open purchase time:",
   "applyForFree":"Buy now",
   "applyLockBtn":"Freezing",
   "applyOverBtn":"project closed",
   "reservation":"Raising period",
   "purchasePeriod":"Lock-up period",
   "incomePeriod":"Settlement date",
   "haveNoProject":"You have no project yet.",
   "fixedRate":"Fixed Rate",
   "monthlyYieldGrowth":"Monthly Yield Growth",
   "weeklyYieldGrowth":"Weekly Yield Growth",
   "favorites":"Favorites",
   "discover":"Social",
   "totalValue":"Total Value",
   "loginToView":"login to view",
   "topVolume":"Top Volume",
   "topGainers":"Top Gainers",
   "topLosers":"Top Losers",
   "setPassword":"Set Password",
   "pwdRules":"6-20 difits, letters + numbers",
   "resendSuccess":"Resend success",
   "emailVerification":"Email Vertification",
   "verificationCode":"Verification Code",
   "resend":"Resend",
   "notReceivedEmail":"If you have not received the email, please look in the trash.",
   "pwdError":"Password error, currently there are ",
   "inputLeft":" input opportunities left",
   "accountLocked":"The account has been locked and cannot be logged in temporarily. There is still  ",
   "minutesToUnlock":" minutes to unlock",
   "emailRegistered":"Email has been registered. If you already have an account, please login",
   "mobileRegistered":"Mobile has been registered. If you already have an account, please login",
   "registrationMeans":"Registration means accepting",
   "userService":"《User Service Agreement》",
   "pleaseClickBack":"Please click BACK again to exit",
   "balance":"Balance",
   "noNotice":"You have no notice",
   "limit":"Limit",
   "BUY":"BUY",
   "SELL":"SELL",
   "status":"Status",
   "noRewardHistory":"You have no reward history",
   "clickReload":"Click to reload",
   "cancelSuccessfully":"cancel successfully",
   "cancelFailed":"cancel failed",
   "successfullyAdd":"Successfully add",
   "successfullyDelete":"Successfully delete",
   "executed":"Executed",
   "today":"Today",
   "noOrder":"You have no order",
   "openOrders":"Open Orders",
   "ordersHistory":"Orders History",
   "marketTrades":"Market Trades",
   "depth":"Depth",
   "joinMyFavorite":"Join my favorite",
   "removeFavorite":"Remove from my favorite",
   "depthChart":"DepthChart",
   "high":"High",
   "low":"Low",
   "inputRightPrice":"Please input right price",
   "inputRightAmount":"Please input right amount",
   "inputRightAccount":"Please input right account",
   "noEnoughAssets":"No enough assets",
   "notEnoughCoin":"Not enough coin",
   "all":"ALL",
   "marketTrading":"Market Trading: Optimal market price",
   "avaliable":"Avaliable",
   "loginTrading":"login",
   "avgPrice":"Avg Price",
   "enterWalletAddress":"Please enter a wallet address",
   "atention":"Atention",
   "addAddressSuccessfully":"Add Address Successfully",
   "minimum_withdrawal":"Minimum withdrawal",
   "enterValidAddress":"Please enter valid withdraw address",
   "withdrawalAmount":"The withdrawal amount is less than the account balance",
   "withdrawalRequest1":"Your withdrawal request is less than the minimum withdrawal limit and cannot be withdrawn",
   "withdrawalRequest2":"Your withdrawal request exceeds the maximum withdrawal limit and cannot be withdrawn",
   "withdrawalRequest3":"Your withdrawal request exceeds today's cumulative maximum withdrawal limit and cannot be withdrawn",
   "chooseTheAddress":"Choose the address",
   "useNewAddress":"Use New Address",
   "noRecordWithdraw":"You have no record of withdraw",
   "create":"Create",
   "totalTrades":"Total trades",
   "completionRate":"Completion rate",
   "averageProcessingTime":"Average processing time",
   "pay":"Pay",
   "verifyGoogle":"Verify by Google",
   "verifySMS":"Verify by SMS",
   "googleVerification":"Google Verification",
   "pleaseInputCode":"Please input code",
   "failedGetVerificationCode":"Failed to get the verification code. Please check the network connection",
   "inputGoogleCode":"Please input Google Auth code",
   "sending":"Sending",
   "sendSuccessfully":"SMS Send Successfully",
   "resendAfter":"Resend after ",
   "securityDesc":"For the assets security,  you must obey security policy",
   "identityAuthentication":"Identity Authentication",
   "verifiedFailed":"Verified failed",
   "phoneAuthentication":"Mobile Verification",
   "google":"Google",
   "assetsSecurity":"• For your assets security, Withdrawal requires Phone verification and google verification.",
   "withdrawalMore":"• Withdrawal more than",
   "requiresIdentityAuthentication":"requires identity authentication.",
   "assetsSecurityFirst":"Assets security always comes first",
   "uploadSuccessful":"Upload successful",
   "set":"Set",
   "verifying":"Verifying",
   "reset":"Reset",
   "identity":"Identity",
   "investMarket":"Market",
   "investAccount":"Financial Account",
   "myProjects":"My Project",
   "update":"update",
   "expired":"Expired",
   "androidVerificationModuleError":"Android VerificationModule Error: ",
   "iosVerificationModuleError":"Ios VerificationModule Error: ",
   "sResend":"s Resend",
   "inputSmsCode":"Please input Sms Code",
   "inputEmailCode":"Please input email code",
   "checkTheEmail":"Please check the email, fill in the confirmation codes,to complete the confirmation process.",
   "notReceiveYet":"Have not receive yet?",
   "sendAgain":"Send again",
   "congratulationsSuccessfulDeposit":"Congratulations on your successful Deposit of",
   "seeFullDetails":". Please see the full details.",
   "congratulationsSuccessfulWithdraw":"Congratulations on your successful Withdraw of",
   "successfulTransaction":"You have a successful transaction on the",
   "order":"order",
   "tradedLongTime":"You have a order that has not been traded for a long time.",
   "youHaveNotification":"You have a notification.",
   "rewardHistory":"Reward history",
   "referralHistory":"Referral history",
   "registerForDipbit":"Register for Dipbit",
   "achieveYourDream":"Achieve your dream！",
   "isComing":"is Coming...",
   "depositProfit":"Deposit to profit, raise fortune with idle money. Build wealth without making trades!",
   "invitationCodeIfApply":"Invitation Code (If applicable)",
   "sureIt":"Sure",
   "success":"Success",
   "failed":"Failed",
   "passwordResetSuccess":"Password reset success",
   "enterRegisteredEmail":"Please enter your registered email",
   "investfixedRate":"Stable Annually Return Ratio",
   "investing":"Raising",
   "upTime":"",
   "beforeupTime":"Till ",
   "myProjectsMoney":"Project Money",
   "yestordayIncome":"Yeaterday Yield",
   "totalIncome":"Accumulated Yield",
   "invesAccounttLast":"Project Balance",
   "investRecord":"Finacial Records",
   "transactionIn":"Transfer In",
   "transactionOut":"Transfer Out",
   "buyTime":"Purchasing Time",
   "getTime":"Settlement Time",
   "buyAllAmount":"Purchasing Amount",
   "getAllAmount":"Settlement Amount",
   "income":"Yield",
   "buyIn":"Buy In",
   "everyAmount":"Per Unit",
   "maxBuyAmount":"Max Purchasing Amount",
   "transmentIn":"Transfer",
   "buyInAmount":"Purchasing Units",
   "agree":"Agree",
   "agreementText":"FatPocket Financial Agreement",
   "agreementTextTitle":"Financial Agreement",
   "sureBuyIn":"Transfer In",
   "sureBuyOut":"Transfer Out",
   "sureBuy":"Buy",
   "AmountUnit":"Unit",
   "daysIncome":"Promising Yield",
   "inputMoney":"Invest Amount",
   "buySuccess":"Success",
   "startIncomeTime":"Starting Time",
   "startComputeTime":"Settelling Time",
   "lookUpMyInvest":"Look Up My Invest",
   "getYourGift":"Get your VIP service",
   "investNote":"For instant reply and better service, please add our WeChat service account or join telegram group.",
   "wechatImage":"WeChat",
   "telegramImage":"Telegram",
   "transferFrom":"From",
   "transferTo":"To",
   "transferAccount":"Trading Account",
   "transferAmount":"Transfer Amount",
   "tradeType0":"Transfer In",
   "tradeType1":"Transfer out",
   "tradeType2":"Purchase",
   "tradeType3":"Settlement",
   "freeFee":"Free",
   "freeWithdraw":"Avaliable Free Withdraw Counts",
   "freeTime":"time",
   "enterRightNum":"Enter right num",
   "balanceRunsOut":"Balance runs out",
   "overTopLimit":"Over top limit",
   "bitRunsOut":"Balance runs out",
   "lockUp":"Locked",
   "ended":"Ended",
   "cardFace":"ID/Passport Front",
   "cardFaceDesc":"Please ensure that the content of the photo is complete and clearly visible",
   "cardOpposite":"ID/Passport Back",
   "cardOppositeDesc":"Please ensure that the content of the photo is complete and clearly visible and in validating time.",
   "idDipbitCard":"ID/Passport Hands-Held",
   "idDipbitCardDesc":"Please upload a hand-held ID/Passport face and personal signature photo with the signature \"dipbit\" and current date.",
   "cardDesc1":"•Please ensure the picture size is under 2M. Only JPG and PNG image formats are supported.",
   "cardDesc2":"•Don't submit photos or other images that don't match your identity, otherwise the platform has the right to suspend your use, seal, etc.",
   "confirmSubmit":"Comfirm Submit",
   "checking":"Identifying",
   "checkingDesc":"Your submission will be reviewed within 3-5 business days, please be patient.",
   "checkSuccess":"Identify completed",
   "checkFailed":"Identify denied",
   "checkFailedDesc":"Photos too blur to identify",
   "submittedFailed":"Identify denied",
   "submittedSuccessfullyDesc":"Your submission will be reviewed within 3-5 business days, please be patient.",
   "submittedFailedDesc":"You haven't submitted all the information yet",
   "recertification":"Submit Again",
   "uploadAgain":"Click to upload again",
   "transferSuccess":"Transfer Success",
   "inviteFriends":"Invite Friends",
   "exclusivePosters":"QR code poster",
   "invitedNumber":"Invite Total",
   "accumulatedRewards":"Reward Value(DBT)",
   "twitter":"Twitter",
   "facebook":"Facebook",
   "wechatWay":"Wechat",
   "weibo":"Weibo",
   "qqWay":"QQ",
   "sharePosters":"Share The Poster",
   "shared":"Share",
   "activityRules":"Program Rules",
   "rewardRecord":"Reward History",
   "referralRecord":"Invite History",
   "referralAccount":"Invited Account",
   "rewardType":"Type",
   "rewardCount":"Amount",
   "shareTitle":"Come and register to get 100DBT by my invitation!",
   "getCoin":"Get Coin",
   "noRecords":" No Records",
   "currentExchangeRate":"Current exchange rate:",
   "priceRealTime":"Price (real time)",
   "faitTotal":"Total",
   "chooseMerchant":"Choose Merchant",
   "cancelOrderPay":"Cancel Order",
   "iHavePay":"I've paid",
   "wallet":"Penny wallet",
   "iKonw":"OK",
   "yestordayAllIncome":" LastDay Income",
   "AllIncome":"Cumulative Income",
   "pennyIncome":"Penny Income",
   "loginDipbit":"Log in Dipbit",
   "contactUs":"Contact Us",
   "fiatTrading":"Fiat",
   "startChat":"开始实时聊天",
   "enterChatEmail":"Please enter your email",
   "exceeding_quota":"Exceeding quota",
   "remaining_amount_to_followed":"Remaining amount to followed",
   "total_amount_to_follow":"Total amount to follow",
   "total_amount_followed":"Total amount followed",
   "customer.login.incorrect":"User info expired",
   "system.parameter.invalid":"Request Parameter Invalid",
   "system.success":"Success",
   "customer.login.new":"Logout",
   "customer.password.old.required":"Original password can not be empty",
   "customer.verify.code.expire":"Verification code error",
   "customer.verify.type.invalid":"Invalid verify type",
   "trade.value.greater.than.order.max":"Cannot exceed %{amount} %{currency} price per order limit",
   "trade.forbidden.invalid.count":"Invalid Entrust Count",
   "customer.verify.inactive":"Login account is not active",
   "trade.forbidden.by.balance.volume":"Pricing currency is not enough",
   "customer.phone.required":"Phone number can not be empty",
   "customer.kyc.id.used":"The ID certificate has been used, please try others",
   "customer.kyc.verify.fail":"KYC certification failed",
   "customer.kyc.live.result.check.fail":"liveness detection failed",
   "customer.kyc.not.submit":"KYC has not been submitted",
   "customer.kyc.info.insufficient":"Insufficient KYC information",
   "customer.kyc.id.expire":"Passport expired/invalid",
   "customer.kyc.reject.unsupported.id.type":"Passport type not supported",
   "customer.google.unbind.fail":"Google verification unbinding failed",
   "customer.phone.on.fail":"Open your phone to verify failed",
   "customer.locked":"Your account is locked, please contact us",
   "customer.google.on.error":"Google verification fail to open",
   "customer.password.same":"New login password can not be the same as the original one",
   "customer.phone.off.success":"Close your phone to verify success",
   "trade.entrust.cancel.success":"Cancel successfully",
   "customer.exist.none":"User does not exist",
   "customer.email.format.incorrect":"Email format is incorrect",
   "customer.kyc.duplicate":"Customer already certified",
   "customer.verify.email.inactive":"Need to activate mailbox",
   "trade.forbidden.by.date":"Holidays or non-opening time",
   "customer.google.off.period":"For security purpose, no withdrawal is permitted for 24 hours after turning off Google Auth",
   "customer.email.required":"Email can not be empty",
   "customer.email.duplicate":"Email address cannot be repeated",
   "trade.forbidden.customer.status":"Customer is not activating",
   "customer.reg.email.duplicate":"Email has been registered",
   "customer.google.verify.code.incorrect":"Google verification code can not be empty",
   "customer.phone.code.often":"SMS verification code sent too often, please try again later",
   "customer.phone.code.limit":"SMS sent beyond the maximum limit of the day",
   "trade.forbidden.by.system":"System maintenance, trading terminated",
   "trade.forbidden.by.precision.volume":"Number of small bits, please keep less than or equal to {0} bits",
   "coin.transaction.nonexist":"Record does not exist",
   "coin.address.outer.required":"Public key id is empty",
   "customer.phone.format.incorrect":"Wrong format of phone number",
   "system.cache.refresh.fail":"System cache refresh fail",
   "system.parameter.type.mismatch":"Parameter type mismatch",
   "customer.google.on":"Google verification opened successfully",
   "customer.login.success":"login successfully",
   "customer.forbidden":"Your account is disabled, please contact us",
   "trade.forbidden.invalid.sum":"Illegal argument,invalid Entrust Sum",
   "customer.login.required":"User not login",
   "customer.verify.code.incorrect":"Email verification code is incorrect",
   "customer.email.code.incorrect":"Email verification code is incorrect",
   "customer.verify.code.sent":"Verification code has been sent",
   "customer.verify.phone.unbind":"Need binding phone",
   "customer.phone.code.required":"Phone verification code can not be empty",
   "coin.address.save.success":"Saved successfully",
   "customer.phone.send.fail":"Failed to send verification code",
   "customer.phone.duplicate":"The phone number has been bound",
   "customer.verify.twice":"Need validating twice",
   "coin.address.invalid":"Invalid address",
   "customer.phone.off.fail":"Failed to verify handset shutdown",
   "trade.forbidden.by.balance.price":"Insufficient balance",
   "customer.query.success":"Email has been registered",
   "system.parameter.required":"Parameter [{0}] is empty",
   "customer.password.reset.success":"Reset password successfully",
   "customer.login.password.error":"Email or Password error, {0} input opportunities left",
   "system.error":"Connection fail, retry later",
   "customer.phone.new.duplicate":"The new phone number can not be duplicated with the old one",
   "customer.google.off":"Google Authenticator closed successfully",
   "trade.forbidden.invalid.price":"Invalid Entrust Price",
   "customer.phone.code.incorrect":"Phone Verification code error",
   "incorrect.invitation.code":"Incorrect invitation code",
   "customer.registration.success":"Registration successfully",
   "customer.google.auth.fail":"Google validation code error",
   "customer.kyc.expire":"The verification expired. Please try again later",
   "customer.password.reset.fail":"Password reset failure",
   "customer.google.success":"Google verification success",
   "customer.password.format.incorrect":"Bad password format",
   "customer.phone.error":"Mobilephone certification failed",
   "trade.forbidden.by.num.max":"Number of orders exceeds the maximum limit:{0}",
   "trade.forbidden.by.total.min":"Number of orders exceeds the minimum limit :{0}",
   "activity.not.start.code":"Please wait for the activity to start",
   "customer.password.different":"The password entered twice must be the same",
   "system.parameter.format.incorrect":"Parameter format [{0}] is incorrect",
   "system.data.not.found":"System data is not found",
   "system.parameter.digital.required":"Parameter [{0}] should be digital",
   "customer.phone.on.success":"Open your phone to verify success",
   "coin.interface.error":"Coin inner system error",
   "trade.forbidden.customer.uncertified":"Real name authentication, please",
   "trade.entrust.exist.none":"Entrust not found",
   "trade.entrust.cancel.fail":"Cancel failed",
   "system.operation.frequent":"Operation too frequent",
   "trade.forbidden.by.precision.price":"The price of the Commission is kept at less than or equal to {0} bits",
   "customer.virtual.account.nonexist":"Virtual account does not exist",
   "customer.google.off.error":"Google verification failed to close",
   "trade.forbidden.by.symbol":"Current trading is not routable",
   "coin.address.outer.mismatch":"Public key does not belong to this customer",
   "trade.forbidden.customer":"You have been prohibited from trading",
   "system.security.param.required":"Security check parameter is empty",
   "trade.forbidden.by.num.min":"The minimum order quantity can not be less than :{0}",
   "customer.reset.password.error":"Password error",
   "coin.address.outer.duplicate":"Wallet public key cannot be duplicated",
   "coin.code.nonexist":"Coin code non-exist",
   "customer.google.bind.fail":"Google verification binding failed",
   "customer.phone.success":"Mobile certification success",
   "customer.password.required":"Password can not be empty",
   "trade.forbidden.by.updown":"Not exceeding {0} of the current price",
   "trade.entrust.success":"Entrust success",
   "customer.reg.fail":"Registration failed",
   "system.security.check.fail":"Security check failed",
   "customer.password.new.required":"New password can not be empty",
   "customer.google.unbind.success":"Google verification unbinding successfully",
   "customer.google.bind.success":"Google verification binding successfully",
   "customer.verify.code.sent.often":"Email verification code sent too often, please try later",
   "coin.withdraw.success":"Applied withdraw successfully",
   "coin.withdraw.amount.onetime.min.less":"The single withdrawal amount is less than the minimum limit",
   "coin.withdraw.amount.onetime.min.less.unnamed":"Please verify identity to increase withdrawal limit",
   "coin.withdraw.amount.onetime.max.exceed.unnamed":"Please verify identity to increase withdrawal limit",
   "coin.withdraw.amount.day.max.exceed.unnamed":"Please verify identity to increase withdrawal limit",
   "coin.withdraw.closed":"Withdraw suspended",
   "coin.withdraw.amount.onetime.max.exceed":"The single withdrawal amount is greater than the maximum limit",
   "coin.withdraw.amount.day.max.exceed":"Today's total withdrawal amount exceeds the maximum limit",
   "coin.withdraw.balance.insufficient":"Insufficient balance",
   "client.coin.withdraw.minimum.limit":"Your withdrawal request is less than the minimum withdrawal limit",
   "client.coin.withdraw.maximum.limit":"Your withdrawal request exceeds the maximum withdrawal limit",
   "client.coin.withdraw.today.maximum.limit":"Your withdrawal request exceeds today's cumulative maximum withdrawal limit",
   "client.copy.success":"The {0} is copied successfully",
   "client.copy.failure":"The {0} is copied failure",
   "client.user.not.exist":"The user does not exist",
   "client.user.registered":"The user has been registered",
   "active.not.start.code":"Please wait patiently for the activity to start",
   "coin.code.or.address.empty":"Coin and address cannot be empty",
   "coin.channel.unavailable  ":"Coin channel unavailable",
   "coin.channel.validation.exception":"Address validation failed",
   "trade.entrust.add.invalid.symbol":"Invalid parameter symbol for adding entrust",
   "trade.entrust.add.invalid.type":"Invalid parameter type for adding entrust",
   "trade.entrust.add.invalid.source":"Invalid parameter source for adding entrust",
   "trade.entrust.add.invalid.way":"Invalid parameter entrustWay for adding entrust",
   "trade.entrust.add.invalid.price":"Invalid parameter entrustPrice for adding entrust",
   "trade.entrust.add.invalid.count":"Invalid parameter entrustCount for adding entrust",
   "trade.entrust.add.invalid.sum":"Invalid parameter entrustSum for adding entrust",
   "otc.place.order.success":"Place an order successfully",
   "otc.place.order.fail":"Place an order fail",
   "otc.cancel.order.success":"Cancel order success",
   "otc.cancel.order.fail":"Cancel order fail",
   "otc.payer.confirm.success":"Payer confirm success",
   "otc.payer.confirm.fail":"Payer confirm fail",
   "otc.payee.confirm.success":"Payee confirm success",
   "otc.payee.confirm.fail":"Payee confirm fail",
   "otc.appeal.order.success":"Appeal success",
   "otc.appeal.order.fail":"Appeal fail",
   "otc.appeal.order.finish.success":"Appeal result update success",
   "otc.appeal.order.finish.fail":"Appeal result update fail",
   "otc.order.query.success":"Query success",
   "otc.order.query.fail":"Query fail",
   "otc.no.data.found":"Order information does not exist",
   "otc.incorrect.operation":"Current order status does not allow this operation",
   "otc.has.timed.out":"Order has timed out",
   "otc.cancel.more.than.three.times":"Cancelled 3 times today, no operation allowed",
   "otc.order.invalid.parameter":"Invalid parameter '{0}'",
   "otc.current.customer.cannot.process.this.order":"Cannot process this order",
   "otc.incomplete.orders.exists":"Incomplete orders exists",
   "otc.trans.amount.check.fail":"Trans amount check fail",
   "coin.withdraw.amount.less.fee":"The withdrawal amount is less than fee",
   "customer.kyc.reject.duplicate.id":"certificate's occupied",
   "customer.kyc.reject.image.not.readable":"The system could not verify your passport photo, please read the instruction and upload again. If you have any questions, please go to [Profile]-[Other Settings]-[Contact us] to contact us.",
   "customer.kyc.reject.unsupported.id.country":"Certificate info and submitted country info does not match",
   "customer.kyc.reject.handheld.fraud":"Hand-held ID photo info does not meet the platform's requirements",
   "go_alipay":"Go Alipay",
   "common_network_error":"Connection fail, retry later",
   "follow_heroes":"Heroes",
   "follow_my_copies":"My Copies",
   "follow_master_hero":"Master Hero",
   "follow_all":"All",
   "follow_copy":"Copy",
   "follow_popularity":"Trending",
   "follow_new_people":"New",
   "follow_total_earning":"Return",
   "follow_total_return":"Return (USDT)",
   "follow_total_return_title":"Overall Return",
   "follow_total_return_ratio":"Rate of Profit",
   "follow_total_assets":"Asset",
   "follow_return":"Return",
   "follow_all_risk":"All risk level",
   "follower_level1":"1-3level",
   "follower_level2":"4-6level",
   "follower_level3":"7-10level",
   "follower_currency":"{name}",
   "follower_all_currency":"Investing",
   "follow_week_return":"Return (USDT)",
   "follow_keep_currency":"Investing",
   "follow_return_ratio":"Return",
   "follow_week_return_ratio":"Rate of 7d",
   "follow_copiers":"Copiers",
   "follow_week_hero":"Hero of the week",
   "follow_all_heroes":"All Heroes",
   "follow_network_error":"Copy failed due to network error, please try again later.",
   "follow_copying":"Copying",
   "follow_amount":"Amount",
   "follow_history":"History",
   "follow_stop_success":"Bye Bye Hero",
   "follow_stop":"Stop Copying",
   "follow_stop_fail":"Stop Copying failed due to network error, please try again later.",
   "follow_form_all":"all",
   "follow_form_tip_balance":"Your balance needs to be more than %{min}USDT,less than %{max}USDT",
   "follow_form_tip_stop_profit":"Stop profit rate must be from %{min}% to %{max}%",
   "follow_form_tip_stop_loss":"Stop loss rate must be from %{min}% to %{max}%",
   "follow_amount_min":"Minimum Amount",
   "follow_balance":"Available Amount",
   "follow_transfer":"Transfer",
   "follow_stop_loss_rate":"Stop Loss Rate",
   "follow_stop_profit_rate":"Stop Profit Rate",
   "follow_stop_loss_at":"Stop copy when loss reaches ",
   "follow_at_cancel":"",
   "follow_stop_profit_at":"Stop copy when gain reaches ",
   "follow_confirm":"Copy",
   "follow_save":"Save",
   "follow_saving":"Saving...",
   "follow_stop_tip":"If you stop copy this Hero, you will no longer follow his/her position. All your copied position will be liquidated by current price.",
   "follow_stop_keep":"Keep copying",
   "follow_success":"Copy Success",
   "follow_complete":"Complete",
   "follow_detail":"Order Detail",
   "follow_copy_return":"Return",
   "follow_copy_return_rate":"Return%",
   "follow_days":"Days up to now",
   "follow_edit":"Edit",
   "follow_share":"Share",
   "follow_status_cancel":"Stop Copying",
   "follow_status_fill":"Stop profit and loss",
   "follow_share_to_friends":"Share to your friends",
   "follow_order_id":"Order ID",
   "follow_status":"Current Status",
   "follow_order_detail":"History Order Detail",
   "follow_copy_fee":"Transaction Fee",
   "follow_profit_share":"Success Fee",
   "follow_profit_fee":"Copy Fee",
   "follow_start_date":"Start Date",
   "follow_end_date":"Stop Date",
   "follow_kol_copying":"Copying",
   "follow_kol_history":"History",
   "follow_kol_trader":"Hero",
   "follow_kol_all_traders":"All Heroes",
   "follow_kol_all_earning":"Historical Earnings",
   "follow_kol_earning_note":"Note: The return will be auto transferred to",
   "follow_kol_copy_account":"Copy Account",
   "follow_kol_feed_setting":"Success Fee Settings",
   "follow_kol_setting_tip":"Success fee will be settled when followers stop follow orders with positive PNL.",
   "follow_kol_api_setting":"API Management",
   "follow_kol_return":"Return",
   "follow_kol_amount":"Amount",
   "follow_kol_now_copy_day":"Days",
   "follow_kol_history_copy_day":"Days",
   "follow_kol_detail":"Detail",
   "follow_kol_history_detail":"History Detail",
   "follow_profit_share_percent":"Success Fee Rate(%)",
   "follow_bad_profit_share_percent":"Success fee rate needs to be more than %{min}%,less than %{max}%",
   "follow_save_success":"Save Success",
   "api_integration":"API integration",
   "api_tip":"Notice",
   "api_new_tip":"Kiki suggest you to renewal your api to make sure it is not out of date. An expire api could cause damage to your reputation and your followers return.",
   "api_new_understand":"I understand",
   "api_save_error":"API error, please check if key and secret is correct.",
   "api_authorize_success":"API has successfully integrated.",
   "api_saving":"Authorizing...",
   "api_delete":"Resign API",
   "api_delete_success":"API Resigned",
   "api_readonly_tip":"Need only \"view only\" access",
   "api_keep_tip":"Please preserve your API key private and secure.",
   "api_access_key_tip":"Please fill in Access key",
   "api_secret_key_tip":"Please fill in Secret Key",
   "api_passphrase_tip":"Please fill in Passphrase",
   "api_document":"View API integration ",
   "api_document_link":"document",
   "api_grant":"Grant Permission",
   "api_renewal":"API renewal",
   "api_delete_tip":"Warning! If you have followers, resigning your API will cause them to be liquidated. Do you sure you want to resign?",
   "api_cancel":"Cancel",
   "api_confirm":"Confirm",
   "safety_setting":"Safety Settings",
   "safety_google":"Google Authenticator",
   "safety_change_pwd":"Change Password",
   "safety_google_deactivate":"Deactivate Google Auth",
   "safety_google_active":"Activate Google Auth",
   "safety_google_store":"Please go to app store to download \"Google Authenticator\" App",
   "safety_google_store_go":"Go to download",
   "safety_google_store_after":"Open \"Google Authenticator\", click \"+\" on the upper right conner (Android device at lower right conner). Choose \"Manual Entry\" , fill in your account name and the key below.",
   "safety_google_copy":"Copy",
   "safety_google_added":"You will receive a 6 digit verification code after you filled in the key successfully. Please fill the code to the input box below.",
   "safety_google_fill_in":"6 digit verification code",
   "safety_google_activate":"Activate",
   "safety_google_check_error":"Please check the verification code",
   "safety_google_copied":"Copied",
   "safety_google_activating":"Activating...",
   "safety_google_success":"Google Authenticator has successfully activated.",
   "safety_google_deactivate_success":"Google Authenticator has successfully deactivated.",
   "position_my":"My Position",
   "apply_trader":"Apply to be a Hero",
   "apply_trader_hero":"Hero",
   "apply_trader_notice":"Please fill out the form below and we will contact you in 48 - 72 hrs. We hold your privacy sacred. Provided information will only be strictly used to evaluate your application.",
   "apply_trader_email":"Email",
   "apply_trader_desc":"Message",
   "apply_trader_required":"required",
   "apply_trader_email_input":"Please fill in Email",
   "apply_trader_size_limit":"Can you tell us why you are qualified to be our Hero? (Please write no more than 300 characters.)",
   "apply_trader_document":"Application Document",
   "apply_trader_apply":"Apply",
   "apply_trader_applied":"Your application has been submitted, our team will contact you shortly.",
   "apply_trader_approved":"Your application has been approved, our team will contact you and walk you through the process shortly",
   "apply_trader_rejected":"Your application has been rejected，please modify and resubmit，reason：%{reason}",
   "apply_trader_rejected_default":"Rejected",
   "apply_trader_saving":"Saving...",
   "apply_trader_modify":"Please modify the message",
   "apply_trader_image_size":"(This can help us learn more about you, image size < 4M)",
   "other_setting":"Other Settings",
   "profile_feed":"Timeline",
   "profile_follow":"Following",
   "profile_followers":"Followers",
   "profile_favorite":"Favorites",
   "profile_balance":"My Wallet",
   "profile_logout":"Log out",
   "profile_my_feed":"My Timeline",
   "profile_my_follow":"My Following",
   "profile_his_follow":"'s Following",
   "profile_no_follow":"No follows",
   "profile_unfollow":"Unfollow",
   "profile_followed":"Followed",
   "profile_unfollowed":"Unfollowed",
   "profile_follow_action":"Follow",
   "profile_follow_action_followed":"Followed",
   "profile_follow_action_friend":"Friend",
   "profile_follow_ing":"Following...",
   "profile_unfollow_ing":"Unfollowing...",
   "profile_my_followers":"My Followers",
   "profile_his_followers":"'s Followers",
   "profile_no_followers":"No followers",
   "profile_my_fav":"My Favorites",
   "profile_my_profile":"My Profile",
   "profile_my_profile_photo":"Profile Photo",
   "profile_my_profile_nick":"Nickname",
   "profile_my_profile_status":"Status",
   "profile_my_profile_click_edit":"Click to edit",
   "profile_my_profile_saving":"Saving...",
   "profile_my_profile_saved":"Saved",
   "profile_my_profile_edit_nick":"Edit Nickname",
   "profile_my_profile_save":"Save",
   "profile_my_profile_nick_input":"Please fill in nickname",
   "profile_my_profile_edit_status":"Edit Status",
   "profile_my_profile_status_input":"Tell us about you",
   "profile_my_profile_album":"Album",
   "profile_my_profile_takephoto":"Take a photo",
   "profile_my_profile_cancel":"Cancel",
   "profile_my_profile_copier":"Copiers",
   "profile_my_profile_copier_ranking_show":"Only show top 10 profitable heroes",
   "trader_timeline":"Timeline",
   "trader_account":"Account",
   "trader_positions":"Positions",
   "trader_success_fee":"Commission",
   "trader_rate_of_profit":"Rate of Profit",
   "trader_total_amount":"Total Amount",
   "trader_total_return":"Total Net Profit",
   "trader_win_rate":"Win Rate",
   "trader_trade_time":"Lifetime",
   "trader_day":"day",
   "trader_days":"days",
   "trader_week_return":"Return 7D",
   "trader_week_return_rate":"Return 7D(%)",
   "trader_frequency":"Frequency",
   "trader_frequency_unit":"/Week",
   "trader_trend":"Trend of Return",
   "trader_rate_trend":"Trend Rate of Return",
   "trader_position":"Position",
   "trader_history_position":"Order History",
   "trader_position_long":"Long",
   "trader_position_short":"Short",
   "trader_open_price":"Entry Price",
   "trader_end_price":"Mark Price",
   "trader_finish_price":"Liquidation Price",
   "trader_position_size":"Size",
   "trader_margin_size":"Margin",
   "profile_pwd_change":"Change Password",
   "profile_pwd_change_failed":"Change Failed",
   "profile_pwd_input_email":"Fill in your email",
   "profile_pwd_reset":"Reset Password",
   "profile_pwd_original":"Original Password",
   "profile_pwd_new":"New Password",
   "profile_pwd_tip":"6-20 characters, including Numbers and letters",
   "kiki_index":"Home",
   "kiki_market":"Markets",
   "market_title":"Market",
   "market_contract":"Margin",
   "market_heroes":"Heroes",
   "market_community":"Community",
   "market_wallet":"Wallet",
   "market_week_heroes":"Heroes of the week",
   "market_more":"More",
   "market_week_profit_rate":"Weekly Return %",
   "market_order":"Order",
   "market_high":"High",
   "market_low":"Low",
   "market_time":"Time",
   "market_15min":"15m",
   "market_1hour":"1H",
   "market_4hour":"4H",
   "market_1day":"1D",
   "market_available":"Available",
   "market_available_balance":"Available Balance",
   "market_transfer":"Transfer",
   "market_buy_long":"Buy/Long",
   "market_sell_short":"Sell/Short",
   "market_order_type":"Type",
   "market_market_order":"Market",
   "market_limit_type":"Limit",
   "market_est_price":"Market Price",
   "market_est_funding_rate":"Est interest",
   "market_amount":"Amount",
   "market_all":"All",
   "market_lever":"Leverage",
   "market_stop_profit_loss_order":"Stop Order",
   "market_stop_profit_loss":"Stop PnL",
   "market_stop_loss_price":"Stop Loss",
   "market_stop_profit_price":"Stop Profit",
   "market_loss_prompt":"Stop loss when %{coinCode} reaches %{lossPrice} %{currency}, estimate PnL %{loss} %{currency}",
   "market_profit_prompt":"Stop gain when %{coinCode} reaches %{profitPrice} %{currency}, estimate PnL %{profit} %{currency}",
   "market_confirm":"Confirm",
   "market_cancel":"Cancel",
   "market_amount_not_greater_than_available":"Amount needs to be not more than available amount",
   "market_margin_not_greater_than_available":"Margin cannot be greater than available",
   "market_amount_must_greater_than":"Amount can not be less than %{amount}%{currency}",
   "market_amount_must_less_than":"Amount needs to be less than %{amount}%{currency}",
   "market_amount_insufficient":"Amount insufficient",
   "market_stop_loss_must_greater_than":"Stop loss price needs to be more than %{amount}%{currency}",
   "market_stop_loss_price_not_less_than":"Stop loss price can not be less than %{amount}%{currency}",
   "market_limit_price_must_greater_than":"Limit price needs to be more than %{amount}%{currency}",
   "market_stop_loss_price_not_greater_than_current":"Stop loss price cannot be more than current price",
   "market_stop_loss_price_not_greater_than_limit":"Stop loss price cannot be more than limit price",
   "market_stop_loss_price_not_greater_than":"Stop loss price cannot be more than %{amount}%{currency}",
   "market_stop_loss_price_not_less_than_current":"Stop loss price cannot be less than current price",
   "market_stop_loss_price_not_less_than_limit":"Stop loss price cannot be less than limit price",
   "market_stop_profit_must_greater_than":"Stop profit price needs to be more than %{amount}%{currency}",
   "market_stop_profit_price_not_less_than_current":"Stop profit price cannot be less than current price",
   "market_stop_profit_price_not_less_than_limit":"Stop profit price cannot be less than limit price",
   "market_stop_profit_price_not_greater_than":"Stop profit price cannot be more than %{amount}%{currency}",
   "market_stop_profit_price_not_greater_than_current":"Stop profit price cannot be more than current price",
   "market_stop_profit_price_not_greater_than_limit":"Stop profit price cannot be more than limit price",
   "market_stop_profit_price_not_less_than":"Stop profit price cannot be less than %{amount}%{currency}",
   "market_order_success":"Order created",
   "market_long":"Long",
   "market_short":"Short",
   "market_open_price":"Entry Price",
   "market_open_time":"Entry Time",
   "market_close_avg_price":"Avg Close Price",
   "market_close_time":"Close Time",
   "market_liquid_price":"Liquidation Price",
   "market_size":"Size",
   "market_margin":"Margin",
   "market_complete":"Complete",
   "market_order_view":"View",
   "market_order_failed":"Order Failed",
   "market_position":"Position",
   "market_active":"Limit Orders",
   "market_positioning":"Position",
   "market_current_positioning":"Position",
   "market_current_open_order":"Limit order",
   "market_order_history":"Order History",
   "market_unrealized_pnl":"Unrealized PnL",
   "market_mark_price":"Mark Price",
   "market_current_price":"Mid Price",
   "market_close_position":"Close",
   "market_order_detail":"Order Details",
   "market_order_history_detail":"History",
   "market_order_id":"Order ID",
   "market_order_status":"Status",
   "market_limit_close":"Limit Close",
   "market_market_close":"Market Close",
   "market_position_closed":"Position Closed",
   "market_operate_success":"Success",
   "market_open_order":"Open Orders",
   "market_open_history":"Order History",
   "market_open_detail":"Details",
   "market_open_history_detail":"Details",
   "market_trigger_price":"Trigger Price",
   "market_cancel_order":"Cancel",
   "market_cancel_success":"Order Cancelled",
   "market_order_date":"Order Date",
   "market_trigger_date":"Trigger Date",
   "market_filled":"Filled",
   "market_canceled":"Canceled",
   "market_order_canceled":"Order failed",
   "market_open_order_success":"Order Created",
   "market_open_order_canceled":"Order Cancelled",
   "market_load_more":"Load more",
   "market_no_data":"No More Content Below...",
   "market_funding_rate":"Interest",
   "market_funding_fee":"Fee",
   "market_share_to":"Share to friends",
   "market_detail":"Details",
   "market_date":"Date",
   "market_price":"Price",
   "market_price_current":"Market Price",
   "market_pay":"Buy ",
   "market_pay_sell":"Sell ",
   "market_fee_detail":"Fee Details",
   "market_fee_tip1":"1.The Interest is used essentially to force convergence of prices between the perpetual futures market and the actual underlying commodity.",
   "market_fee_tip2":"2.If the Interest is positive, long pay short. If negative, short pay long.",
   "market_fee_tip3":"3.Interest = Nominal Value of Positions × Interest.",
   "market_fee_tip4":"4.Interest occurs every 8 hours at 04:00 UTC, 12:00 UTC and 20:00 UTC. You will only pay or receive funding if you hold a position at one of these times.",
   "market_fee_tip5":"5.If you close your position prior to the funding time then you will not pay or receive funding.",
   "market_estimation_tip":"The price might not be your final execution price",
   "market_est_pay":"Est",
   "market_est_get":"Est",
   "market_profit_rate":"Return %",
   "market_position_share_profit":"Making money in KIKITRADE",
   "market_position_share_profit1":"Making money in KIKITRADE",
   "market_position_share_loss":"A small loss",
   "market_spot_order_result":"Result",
   "market_spot_buy_amount":"Size",
   "market_spot_sell_amount":"Size",
   "market_spot_buy_paid":"Amount",
   "market_spot_sell_got":"Amount",
   "market_spot_buy_success":"Buy Success",
   "market_spot_sell_success":"Sell Success",
   "market_spot_buy_fail":"Failed",
   "market_spot_sell_fail":"Failed",
   "market_spot_buy_fail_detail":"Due to network error",
   "market_spot_sell_fail_detail":"Due to network error",
   "market_spot_trade":"Spot Trade",
   "market_spot":"Spot",
   "market_spot_amount":"Amount",
   "market_spot_size":"Size",
   "market_spot_price":"Price",
   "market_fiat_trade":"Fiat",
   "market_fiat_add_card":"Add Account",
   "market_fiat_edit":"Edit",
   "market_fiat_delete":"Delete",
   "market_fiat_name":"Name",
   "market_fiat_save":"Save",
   "market_fiat_manage_card":"Manage Account",
   "market_fiat_deposit":"Fiat Deposit",
   "market_fiat_deposit_history":"History",
   "market_fiat_howtoDeposit":"How to Deposit into Kiki account?",
   "market_fiat_deposit_step1":"• Step 1: Please read the bank info of Kikitrade below and transfer through SWIFT, Bank",
   "market_fiat_deposit_step2":"• Step 2: After the transfer, add your bank account info.",
   "market_fiat_deposit_step3":"• Step 3: Upload your transfer record.",
   "market_fiat_deposit_step4":"• Step 4: Deposit confirmation will be within 1 working day.",
   "market_fiat_deposit_attention_tip1":"1) Bank account name must be the same as Identity Verification name.",
   "market_fiat_deposit_attention_tip2":"2) User has to bear the cost incurred due to bank transfer.",
   "market_fiat_deposit_attention_tip3":"3) The deposit amount will be based on the actual amount Kikitrade received.",
   "market_fiat_deposit_attention_tip4_2":"6-digit Identifier ",
   "market_fiat_deposit_attention_tip4_3":"when you do the FPS, bank transfer, SWIFT. Extra processing time may be needed if the code is missing. The 6-digit Identifier expires in 24 hours.",
   "market_fiat_deposit_attention_tip4_1":"4) Please enter the ",
   "market_fiat_deposit_attention":"Notice:",
   "market_fiat_transaction_agreement":"I have read & agree to",
   "market_fiat_transfer":"Bank details",
   "market_fiat_account":"Account Name",
   "market_fiat_account_number":"Account Number",
   "market_fiat_bank_code":"Bank Code",
   "market_fiat_swift_code":"Swift Code",
   "market_fiat_bank_name":"Bank Name",
   "market_fiat_bank_address":"Bank Address",
   "market_fiat_transferred":"I understand, proceed",
   "market_fiat_currency":"Currency",
   "market_fiat_amount":"Amount",
   "market_fiat_actual_confirmed":"Actual Confirmed Amount",
   "market_fiat_kiki_fee":"Platform fee",
   "market_fiat_bank_charge":"Bank Charge Amount",
   "market_fiat_failed_reason":"Reason:",
   "market_fiat_upload_receipt":"Upload your receipt.",
   "market_fiat_card":"My Bank Account",
   "market_fiat_transfer_currency":"Currency",
   "market_fiat_transfer_amount":"Amount",
   "market_fiat_transfer_ID":"Transfer ID(Optional)",
   "market_fiat_transfer_receipt":"Transaction record",
   "market_fiat_transfer_confirm1":"I confirm，that the deposit has no relevance to money-laundering (AML) nor counter financing of terrorism(CFT) statutory and legal requirements.",
   "market_fiat_transfer_confirm2":"I also understand that KIKITRADE is not responsible for any fee incurred due to bank transfer.",
   "market_fiat_transfer_confirm3":"*Reminder: Bank charges varies between banks.",
   "market_fiat_submit":"Submit",
   "market_fiat_order_apply":"Apply",
   "market_fiat_order_history":"History",
   "market_fiat_order_type":"Type",
   "market_fiat_order_amount":"Amount",
   "market_fiat_order_amount_withdrawl":"Withdrawal Amount",
   "market_fiat_order_amount_received":"Received Amount",
   "market_fiat_apply_submit":"Your withdrawal request has been submitted",
   "market_fiat_order_limit":"%{currency} Withdrawal terms:",
   "market_fiat_order_limit0":"• Minimum: %{limit} %{currency}",
   "market_fiat_order_limit1":"• Daily Maximum: %{limit} %{currency}",
   "market_fiat_order_limit2":"• 30 days Maximum: %{limit} %{currency}",
   "market_fiat_order_limit_hkd_1":"• 0.15%, Minimum 10 HKD",
   "market_fiat_order_fee":"Platform fee:",
   "market_fiat_order_limit_usd_1":"HK USD A/C: 0.15% , Min 40 USD",
   "market_fiat_order_limit_usd_2":"Oversea USD A/C: 0.15%, Min 40 USD",
   "market_fiat_order_tips":"Notice:",
   "market_fiat_order_tips1":"1) User must complete Identity Authentication with identical bank account recipient name.",
   "market_fiat_order_tips2":"2）Withdrawal Amount less than %{limit} %{currency}: Process within 1 business day",
   "market_fiat_order_tips3":"3）Withdrawal Amount more than %{limit} %{currency}: Process within 2-3 business days",
   "market_fiat_order_tips4":"4）Please note that processing time will be longer for weekends and public holidays.",
   "market_fiat_bank_number":"Account",
   "market_fiat_save_success":"Save Success",
   "market_fiat_saving":"Saving...",
   "market_fiat_transfer_submit":"Your deposit has been successfully submitted",
   "market_fiat":"Fiat",
   "market_crypto":"Crypto",
   "market_fiat_delete_card":"Delete cards",
   "market_fiat_delete_confirm":"Are you sure you want to delete the card?",
   "market_fiat_withdraw_confirm":"Before withdrawing fiat currencies, you need to complete identity verification.",
   "market_future":"Margin",
   "market_future_cross":"Cross Margin",
   "market_future_isolated":"Margin",
   "market_switch":"Switch to",
   "market_switch_light":"Lite",
   "market_switch_pro":"Pro",
   "market_highest":"Highest",
   "market_lowest":"Lowest",
   "market_sell":"Sell",
   "market_buy":"Buy",
   "market_cfd_sell":"Sell/Short",
   "market_cfd_buy":"Buy/Long",
   "market_fiat_deposit_channel":"Deposit Channels",
   "market_fiat_deposit_currency":"Deposit Currency",
   "market_fiat_authentication_title":"Authentication",
   "market_fiat_authentication_content":"Before depositing fiat currencies, you need to complete identity verification.",
   "market_fiat_authentication_back":"Not yet",
   "market_fiat_authentication_certification":"Verification",
   "market_fiat_authentication_ok":"OK",
   "market_fiat_authentication_content_review":"Your current identity information is under review. Please try again later.",
   "market_usdt_margin":"USDT Margin",
   "login_login":"Login",
   "login_email":"Enter your email",
   "login_password":"Password",
   "login_to_register1":"Don't have an account yet? ",
   "login_to_register2":"Sign up.",
   "login_successfully":"Login successful",
   "login_forgot_pwd":"Forgot Your Password？",
   "login_account_lock":"Account Lock",
   "login_account_lock_tip":"Your account has been locked, please try again in 2hr",
   "login_i_understand":"I understand",
   "login_send_successfully":"Sent successfully",
   "login_verify_successfully":"Verification success",
   "login_forget_pwd":"Forgot password",
   "login_input_email":"Enter email address",
   "login_input_google_code":"Please enter GA code",
   "login_input_email_code":"Please enter Email verification code",
   "login_input_login_pass":"Please enter Password",
   "login_email_verification":"Email verification",
   "login_twice_verification":"2 step verification",
   "login_verification_code":"Verification code",
   "register_register":"Sign up",
   "register_email":"Enter your email",
   "register_to_login1":"Registered? ",
   "register_to_login2":" Login",
   "register_invite_code":"Invitation code (optional）",
   "register_invite_code_error":"Invitation code error",
   "register_protocol":"Registration represents acceptance \nof ",
   "register_enter_email":"Please enter valid e-mail address",
   "register_email_used":"Email has been registered",
   "register_verify_code":"Email verification code",
   "register_verifying":"Verifying...",
   "register_verify_successfully":"Verified successfully",
   "register_verify_code_prompt":"Please enter the verification code \nreceived by your e-mail %{email}",
   "register_code_send_successfully":"Verification codesSent successfully",
   "register_code_expired":"Verification code expired, please request again",
   "register_code_not_received":"If you haven't received your code yet, \nplease check your spam folder or click resend",
   "register_china":"Mainland China",
   "register_other":"Rest of World",
   "register_hk":"Hong Kong SAR China",
   "register_tw":"Taiwan",
   "register_sg":"Singapore",
   "register_my":"Malaysia",
   "register_au":"Australia",
   "register_euro":"European",
   "register_uploading":"Uploading",
   "register_upload_success":"Upload successfully",
   "register_click_to_upload":"Upload Profile Image",
   "register_male":"Mr.",
   "register_female":"Ms.",
   "register_complete":"Complete",
   "register_set_pwd":"Set password",
   "register_pwd_tip":"6-20 characters, including Numbers and letters",
   "register_nickname_tip":"Nickname(within 15 words)",
   "register_forget_pwd_success":"Password reset successful",
   "register_forget_pwd_reset":"Reset your password",
   "register_email_success":"Registration Success",
   "asset_asset":"My Assets",
   "asset_total_amount":"Total Amount",
   "asset_main_account":"Spot",
   "asset_contract_account":"Contract",
   "asset_yield_account":"Yield",
   "asset_cross_account":"Cross Margin",
   "asset_isolated_account":"Margin",
   "asset_deposit":"Deposit",
   "asset_exchange":"Exchange",
   "asset_withdrawal":"Withdraw",
   "asset_transfer":"Transfer",
   "asset_amount":"Amount",
   "asset_account_equity":"Account Equity",
   "asset_available":"Available",
   "asset_frozen":"Frozen",
   "asset_detail":"Details",
   "asset_transfer_in":"Transfer in",
   "asset_transfer_out":"Transfer out",
   "asset_reward":"Reward",
   "asset_deposit_history":"History",
   "asset_deposit_address":"Deposit Address",
   "asset_address_getting":"Detecting address...",
   "asset_generate_address":"Generate address",
   "asset_generate_address_tip":"Press button below to generate address",
   "asset_deposit_tip1":"This address only supports %{coinCode} deposit. Any other cryptocurrency deposit to this address will be not recovered or refunded.",
   "asset_deposit_tip2_ETH":"Only support the transfer network of ETH, using other networks (such as BEP2, BEP20, HRC20, MATIC, FTM) will not be recovered or refunded.",
   "asset_deposit_tip2_USDT":"Only support ERC20 for the transfer network of USDT, using other networks (such as Omni, BEP2, TRC20, HRC20, MATIC, FTM) will not be recovered or refunded.",
   "asset_deposit_tip2_BTC":"Only support BTC for the transfer network of BTC, using other networks (such as BEP20, HRC20, MATIC, FTM) will not be recovered or refunded.",
   "asset_deposit_tip2_BCH":"Only support BCH for the transfer network of BCH, using other networks (such as BEP20, HRC20, MATIC, FTM) will not be recovered or refunded.",
   "asset_deposit_tip2_LTC":"Only support LTC for the transfer network of LTC, using other networks (such as BEP2, HRC20, MATIC, FTM) will not be recovered or refunded.",
   "asset_deposit_tip2_XRP":"Only support XRP for the transfer network of XRP, using other networks (such as BEP20, HRC20, MATIC, FTM) will not be recovered or refunded. Please note that the above address and tag must be correctly filled in. Otherwise, your deposit will not be processed, and the asset cannot be recovered.",
   "asset_deposit_tip3_ETH":"If you deposit ETH from a smart contract wallet (e.g., BlockFi, Etoro, Paxful, Bitstamp), there will be a delay as extra processing time is required and a blockchain transaction fee of 0.015 ETH. If you have transferred the ETH with a valid transaction hash on Etherscan, but the ETH has not shown up in your Kikitrade deposit history, please send the TXID with other info to support@kikitrade.com or contact Kikitrade Customer Service for inquiry. If you are not sure if it sends from a smart contract, please contact your sender.",
   "asset_deposit_tip3_USDT":"If you deposit USDT from a smart contract wallet (e.g., BlockFi, Etoro, Paxful, Bitstamp), there will be a delay as extra processing time is required and a blockchain transaction fee of 20 USDT. If you have transferred the USDT with a valid transaction hash on Etherscan, but the USDT has not shown up in your Kikitrade deposit history, please send the TXID with other info to support@kikitrade.com or contact Kikitrade Customer Service for inquiry. If you are not sure if it sends from a smart contract, please contact your sender.",

   "asset_deposit_minimum_amount":"Minimum Deposit：%{num}",
   "asset_deposit_minimum_amount_desc":"The minimum deposit is based on the amount after deducting the withdrawal fee from sending platform, which is the amount shown on the TXID; any deposit lower than the minimum deposit will not be recovered or refunded.",
   "asset_show_qr":"Show QR Code",
   "asset_copy":"Copy",
   "asset_copy_success":"Copied",
   "asset_save_photo":"Save",
   "asset_saved_photo":"Image saved",
   "asset_save":"Save",
   "asset_date":"Date",
   "asset_status":"Status",
   "asset_complete":"Complete",
   "asset_pending":"Pending",
   "asset_failed":"Failed",
   "asset_withdrawal_history":"Withdrawal History",
   "withdraw_amount":"Withdrawal amount",
   "deposit_amount":"Deposit amount",
   "withdraw_coin":"Withdrawal Coin",
   "deposit_coin":"Deposit Coin",
   "go_to_trade":"Go To Trade",
   "asset_withdrawal_id":"Withdrawal ID",
   "asset_withdrawal_amount":"Amount",
   "asset_withdrawal_detail":"Withdrawal Details",
   "asset_deposit_detail":"Deposit details",
   "asset_withdrawal_address":"Address",
   "asset_withdrawal_submitting":"Submitting",
   "asset_withdrawal_address_save_ok":"Save success",
   "asset_withdrawal_address_tip":"Label should be less than 30 characters",
   "asset_withdrawal_address_manage":"Address Management",
   "asset_withdrawal_input_address":"Input address",
   "asset_withdrawal_input_label":"Input label",
   "asset_withdrawal_input_tag":"Input tag",
   "tag":"Tag",
   "asset_withdrawal_google_bind_tip":"to bind google",
   "asset_withdrawal_status_apply":"Applied",
   "asset_withdrawal_status_approval":"Passed",
   "asset_withdrawal_status_unapproval":"Failed",
   "asset_withdrawal_amount_must_greater_than":"Withdrawal amount needs to be higher than %{amount}",
   "asset_withdrawal_amount_not_greater_than_available":"Please enter an amount no higher than your available balance",
   "asset_withdrawal_amount_not_less_than_once_min_amount":"Withdrawal amount should be no less than minimum withdrawal amount.",
   "asset_withdrawal_amount_not_greater_than_once_max_amount":"Withdrawal amount should be no higher than maximum withdrawal amount.",
   "asset_withdrawal_tip1":"• Daily Maximum Withdrawal Amount: %{oneDayMax}%{currency}, Minimum Withdrawal Amount: %{min}%{currency}, Maximum Withdrawal Amount: %{max}%{currency}",
   "asset_withdrawal_tip2":"• For safety reasons, we will manually verify your transaction when you use a new withdrawal address.",
   "asset_transaction_id":"Transaction ID",
   "asset_transaction_fee":"Fee",
   "asset_add_new_addr":"New Address",
   "asset_label":"Tag",
   "asset_label_name":"Label Name",
   "asset_coin":"coin",
   "asset_transfer_amount":"Transfer Amount",
   "asset_all":"All",
   "asset_transfer_tip":"Please fill in transfer amount",
   "asset_transfer_tip1":"You need to transfer asset to correlated account to activate it.",
   "asset_transfer_tip2":"Internal transfer has 0 fee.",
   "asset_transfer_tip3":"Minimum Transfer to Yield Account: %{amount} %{currency}",
   "asset_in_order":"in Order",
   "asset_in_order_margin":"Position Margin",
   "asset_unrealized_pnl":"Unrealized",
   "asset_margin_rate":"Margin Rate",
   "asset_open_position":"Open margin",
   "asset_close_position":"Close margin",
   "asset_limit_order":"Limit Order",
   "asset_cancel_order":"Cancel Order",
   "asset_adjust_margin":"Adjust Margin",
   "asset_follow_amount":"Following Amount",
   "asset_performance_share":"Performance Share",
   "asset_start_follow":"Start Following",
   "asset_close_follow":"Close Following",
   "asset_spot_trade":"Spot Trade",
   "asset_interest":"Interest rate",
   "asset_total_interest":"Total interest earned",
   "asset_interest_type":"Interest",
   "asset_trial_bonus_interest":"Interest",
   "asset_operate_success":"Transfer successfully",
   "asset_transfer_not_greater_than_available":"Transfer amount not greater than available",
   "asset_transfer_must_greater_than":"Transfer amount must be greater than %{amount}",
   "asset_transfer_from":"from",
   "asset_transfer_to":"to",
   "asset_deleting":"Deleting...",
   "asset_delete_success":"Delete successfully",
   "asset_delete_fail":"Delete failed",
   "asset_chain_type":"Chain Type",
   "profile":"Profile",
   "languageSetting":"Language Setting",
   "otherSetting":"Other Settings",
   "edit":"Edit",
   "share_not_available":"Share Not Available",
   "profile_version":"Version",
   "profile_about":"About",
   "profile_contact_us":"Contact us",
   "profile_update":"Update",
   "profile_download_version_tip":"Click to download the latest version: %{version}",
   "profile_latest_version_tip":"This is the latest version!",
   "profile_my_order":"My Orders",
   "profile_cross_order":"Cross Orders",
   "profile_isolated_order":"Isolated Orders",
   "profile_spot_order":"Spot Orders",
   "profile_fiat_order":"Fiat Orders",
   "community_community":"Community",
   "community_recommend":"Top",
   "community_myFollow":"My Follow",
   "community_follow":"Follow",
   "community_topTrader":"Top Trader of Astronauty Fund",
   "community_feed":"Feed",
   "community_myFans":"My Fans",
   "community_main":"Main",
   "community_account":"Account",
   "community_position":"Position",
   "community_myFollowers":"My Followers",
   "community_topicDetail":"Feed",
   "community_likedCounts":"{likedCounts} likes",
   "community_share":"Share",
   "community_topComments":"Top comments",
   "community_replyCounts":"replies",
   "community_reply":"Reply",
   "community_viewMoreReplies":"View more replies",
   "community_writeComment":"Write a comment...",
   "community_publishComment":"Write a Post",
   "community_sendPost":"Send",
   "community_sendCommentOrReply":"Send",
   "community_allComments":"All comments",
   "community_myMessage":"Message",
   "community_socialMessage":"Social",
   "community_systemNotification":"Notification",
   "community_commentsMe":"Comments",
   "community_repliesMe":"Replies",
   "community_likesMe":"Likes",
   "community_commentsOnYourPost":"comments on your post",
   "community_likesYourPost":"likes your post",
   "community_repliesYourPost":"replies your post",
   "community_uploading":"Uploading...",
   "community_releasePost":"Create Post",
   "community_hotPost":"Hot Topic",
   "community_hotPost_placeholder":"Enter the topic content you want to post here...",
   "community_releasePost_placeholder":"What's in your mind?",
   "community_vote_placeholder":"Enter the voting content you want to publish here...",
   "community_vote_selection":"Voting selection",
   "community_right":"Right",
   "community_left":"Left",
   "community_manage":"Manage",
   "community_delete_post":"Delete post",
   "community_delete_comment":"Delete comment",
   "community_delete_reply":"Delete reply",
   "community_delete_topic_tip":"Are you sure to delete the current topic? Once deleted, it cannot be restored.",
   "community_delete_post_tip":"Are you sure to delete the current post? Once deleted, it cannot be restored.",
   "community_delete_poll_tip":"Are you sure to delete the current vote? Once deleted, it cannot be restored.",
   "community_delete_reply_tip":"Are you sure to delete the current reply? Once deleted, cannot be restored.",
   "community_delete_comment_tip":"Are you sure to delete the current comment? Once deleted, cannot be restored.",
   "community_post_notfound":"Sorry, this post was taken away by UFO...",
   "community_share_increase":"Number of shares +1",
   "community_releasePollSuccess":"Post vote successfully",
   "community_pollSending":"Voting...",
   "community_releaseTopicSuccess":"Post topic successfully",
   "community_topicSending":"Topic publishing...",
   "community_topic_over_limit":"There are currently 3 topics, unable to create a new topic!",
   "community_vote_over_limit":"There are currently 3 votes. Cannot create new votes!",
   "community_vote":"Voting",
   "community_cancelLike":"Unliked",
   "community_likeSuccess":"Liked",
   "community_cancelLikeFail":"Unlike failed, please try again",
   "community_likeFail":"Like failed, please try again",
   "community_cancelFollow":"Unfollowed",
   "community_followSuccess":"Following",
   "community_cancelFollowFail":"Unfollow failed, please try again",
   "community_followFail":"Follow failed, please try again",
   "community_reportSuccess":"Reported",
   "community_contentReport":"Report a Post",
   "community_reportContent":"Description",
   "community_reportContentLimit":"Less than 200 characters",
   "community_report":"Report",
   "community_releaseDesc":"What's in your mind?",
   "community_commentSending":"Kiki is posting your comment...",
   "community_commentSuccess":"Commented",
   "community_commentFail":"Failed, please comment again",
   "community_publishedComment":"Comment",
   "community_published":"Post",
   "community_releaseSuccess":"Posted",
   "community_postSending":"Kiki is uploading your post...",
   "community_favSuccess":"Added to my favorite",
   "community_cancelFavSuccess":"unfavorite",
   "community_all":"All",
   "community_add":"Add",
   "community_imgSaved":"Picture Saved",
   "community_WebPageDetail":"Link",
   "community_comment":"Comment",
   "community_like":"Like",
   "community_yourPost":"My Post",
   "community_originalText":"[Origin]",
   "community_gif":"GIF",
   "community_loading":"Loading...",
   "community_abuse":"Abuse/Insults",
   "community_sexual":"Sexual content",
   "community_ads":"Ads/Scams",
   "community_political":"Political",
   "community_weapons":"Weapons/Drugs",
   "community_violence":"Violence",
   "community_others":"Others",
   "community_insertPic":"Insert picture",
   "community_insertVideo":"Insert video",
   "community_guessULike":"You might like",
   "community_news":"News",
   "community_explore":"Social",
   "community_news_headlines":"Headlines",
   "community_no_more":"No more",
   "community_no_content":"No content",
   "community_hot_topic":"Hot",
   "community_poll":"Poll",
   "community_viewers":"Viewers",
   "community_participants":"Comments",
   "community_voters":"Voters",
   "community_select":"select",
   "community_block":"Block",
   "community_block_failed":"Blocking failed. Please try again later.",
   "member_shipCenter":"Membership Center",
   "member_plus":"Plus",
   "member_gold":"Gold",
   "member_expiry_date":" Expiry date",
   "member_renew":"Renew",
   "member_activate":"Activate",
   "member_silver_membership":"Plus Membership Privilege",
   "member_gold_membership":"Gold Membership Privilege",
   "member_daily_box":"Daily Treasury Box",
   "member_login_earn_coin":"Login to earn different amounts of USDT everyday.",
   "member_trading_bonus":"Trading Bonus",
   "member_earn_rebate":"Earn 10% rebate on every trade.",
   "member_referrals":"Referrals",
   "member_earn_fee":"Invite new users to earn 20% referral bonus from his/her trading fee!",
   "member_yield":"Yield",
   "member_earn_annual_yield":"2% annual yield on your available amount. No lock-up.",
   "member_special_features":"Special Features",
   "member_renewal_plus":"Automatic renewal Plus",
   "member_renewal_gold":"Automatic renewal Gold",
   "member_month_count":" Month ",
   "member_month_counts":" Months ",
   "member_discount1":"{discount}% off",
   "member_discount2":"{firstBuyDiscount}% off",
   "member_activate_renewal":"You have activated automatic renewal. ",
   "member_automatically_charge":"We will automatically charge you {price}USDT from your account 1 month past your activation date.",
   "member_menber_activate":"Activate",
   "member_next_renewal":"The renewal will count from the account's expiration date.",
   "member_renewal":"Automatic renewal (USDT will be deducted from your account when membership expire)",
   "member_activating":"Activate...",
   "member_first_buy":"First $16",
   "member_upgrade":"Upgrade",
   "member_buy_success":"You have activate {level} membership for {period} months.",
   "member_buy_charge":"The system has deducted {price}USDT from your main account.",
   "member_upgrading":"Upgrading...",
   "member_expiry":"{level} Membership Expiry Date:",
   "member_upgrading_membership":"After upgrading {level}, you are entitled to the privileges of {level} membership.",
   "member_upgrad_level":" ",
   "member_purchase_difference":"For this update, all you need  is to purchase the price difference from now to expiry date.",
   "member_current_renewal":"You are purchasing auto renewal {level} membership",
   "member_level_type":"Please beware that your {level} membership is set as auto renewal. ",
   "member_renewal_difference":"For this update, all you need is to purchase the price difference from now to pay date .",
   "member_buy_failed_desc":"Your USDT balance is insufficient, please make a deposit before {type}.",
   "member_buy_failed":"failed",
   "member_topup":"Deposit ",
   "member_desc":"The renewal will count from the account's expiration date.Automatic renewal (Main Account USDT will be deducted from your account when membership expire)",
   "member_automatic_renewal":"Autonomatic Renewal",
   "member_cancel_renewal":"Cancel Renewal",
   "member_cancel_confirm":"Cancel renewal",
   "member_cancel_tip":"Are you sure you want to cancel renewal? You will lose a lot of equity when you lose your membership. Please consider this carefully and make your decision below.",
   "member_cancel_desc1":"You are currently a member of {level} who has opened [renewal]",
   "member_cancel_desc2":"By cancelling renewal, the current membership benefits are retained until Next month's deduction day.",
   "member_cancel_desc3":"There will be no automatic deduction for the next month's deduction day.",
   "member_cancel_res1":"You have successfully cancelled the renewal.",
   "member_cancel_res2":"As of next month's deduction date, you still have all the benefits of {level}.",
   "member_cancel_success":"Renewal cancellation successful!",
   "member_buy":"Membership",
   "member_res_type_desc":"Your {level} membership has {type}",
   "member_renewal_type":"renewed!",
   "member_upgrade_type":"upgraded!",
   "member_activate_type":"activated!",
   "member_res_fail":"Failed to {type} {level} membership.",
   "member_renewal_type_fail":"renewal",
   "member_upgrade_type_fail":"upgrade",
   "member_activate_type_fail":"activate",
   "member_Kiki":"Kiki ",
   "member_no_parameters_purchase":"No parameters available for purchase",
   "common_submiting":"Kiki is posting now...",
   "common_networkError":"Network error, please try again later",
   "common_serverBusy":"Service busy, please try again later",
   "common_systemBusy":"System busy, please try again later",
   "common_noRecords":"No available data",
   "common_loadMore":"Scroll up to load more…",
   "common_loading":"Loading",
   "common_confirm":"Confirm",
   "common_cancel":"Cancel",
   "common_unknown":"Unknown",
   "common_next_step":"Next Step",
   "common_2_step_verification":"2-step verification",
   "common_google_auth":"Google Authenticator",
   "common_sms":"SMS Message",
   "common_resend":"Resend",
   "common_send":"Send",
   "common_code_error":"Wrong verification code",
   "common_ok":"Ok",
   "common_not_open":"Coming soon, stay tuned!",
   "common_complete":"Complete",
   "common_apply_storage":"Allow read-write memory permission on Kiki",
   "common_apply_later":"Ask me later",
   "common_apply_no":"No",
   "common_apply_yes":"Yes",
   "common_no_load_more":"",
   "common_scan_download":"Scan QR code to download App",
   "common_saving":"Saving...",
   "invite_my_code":"My Invitation code",
   "invite_share_link":"Invitation link",
   "invite_share_poster":"Invitation poster",
   "invite_count":"Successful referrals",
   "invite_return_rate":"Commission Rate",
   "invite_return_profit":"My Referrals",
   "invite_rule":"Rules",
   "invite_tip1":"1. Every time your friends make a trade, you'll share commission in real time.",
   "invite_tip2":"2. The commission will be distributed in USDT to your trading account at default rate of 10%. Membership increases commission rate, silver membership gets 40% and gold membership gets 70%.",
   "invite_tip3":"3.Daily commission will be distributed the next day.",
   "invite_copy_success":"Copied",
   "invite_copy_fail":"Copy failed，try again",
   "invite_reward_amount":"Amount",
   "invite_reward_time":"Time",
   "invite_reward_record":"Referral",
   "invite_referral_record":"Invitation Record",
   "invite_referral_account":"Friends",
   "invite_referral_time":"Register time",
   "invite_step_1":"Send Invitations",
   "invite_step_2":"Sign up and Deposit",
   "invite_step_3":"Friend gets 38 USDT, You get 25 USDT",
   "invite_poster_tip_1":"Welcome to",
   "invite_poster_tip_2":"KIKITRADE",
   "invite_rules":"Terms and conditions",
   "invite_rules1":"1. Share your invitation code with your friends. Both inviters and invitees will be rewarded after invitees have completed the identity verification and funded their account with a minimum deposit of 10,000 HKD / 1,300 USD / 36,000 TWD.",
   "invite_rules2":"2. The invitee must ensure that the invitation code is correctly filled in.",
   "invite_rules3":"3. The invitation code cannot be logged in after registration.",
   "invite_rules4":"4. The reward will be issued on the same day after the first HKD/USD deposit is approved, and the TWD rewards will be distributed to the spot account on the next working day.",
   "invite_rules5":"5. This activity cannot be participated in at the same time as the first recharge activity.",
   "invite_rules6":"6. For any inquiries, please email support@kikitrade.com or contact ",
   "invite_rules7":"7. Kikitrade reserves the final interpretation of the activity, and our company will have the right to take any necessary action against any unusual access. This activity is not related to Apple Inc.",
   "invite_desc":"Refer a friend to get free Bitcoin",
   "invite_desc1_1":"Invite your friends to earn",
   "invite_desc1_center":" 500HKD ",
   "invite_desc1_2":"rewards together!",
   "invite_desc2":"Invite your friend to make the first deposit of 10,000 HKD / 1,300 USD / 36,000 TWD and your friend will get a 38 USDT (worth ~300 HKD) reward.",
   "invite_desc3":"you will earn 25 USDT (worth ~200 HKD)",
   "invite_desc4":"Referral program upgraded!",
   "invite_reward":"Awards (USDT)",
   "box_member_zone":"KIKI CHEST",
   "box_reward_record":"History",
   "box_open_box":"Tap to unlock",
   "box_game_desc":"Terms and conditions",
   "box_tip_1":"• Each member has 2 chances to open chest everyday.Share to social media earns 1 additional chance to open.",
   "box_tip_2":"• The chests contain various amount of USDT",
   "box_tip_3":"• Your earning will be deposited into your account seamlessly.",
   "box_tip_4":"• In cases of disputes, Kikitrade reserves the rights of final decision. This activity is solely organised by Kikitrade and does not relate to Apple.",
   "box_congrat_1":"Congrats you've earned",
   "box_congrat_2":"Your earning has distributed to your account",
   "box_show":"Share to friends",
   "box_no_reward_1":"Sorry",
   "box_no_reward_2":"Try again next time",
   "box_no_chance":"You have no more chance today.",
   "box_earn_chance":"Earn 1 chance to unlock",
   "box_share_to_friend":"Share to friends",
   "box_max_reward":"Maximum 1000 USDT!",
   "box_open_member":"Own membership to play",
   "box_opening":"Are you the lucky winner?",
   "box_loading":"Loading...",
   "box_lucky":"Congratulations",
   "box_get_reward":"Get rewards",
   "box_share_open":"KIKI CHEST",
   "box_highest_reward":"Up to ",
   "box_tip_follow_trader":"Follow top traders",
   "kyc_verify_title":"Start Verification",
   "kyc_verify_desc":"Please make sure your camera on device is workable, and your ID/Passport by your side.",
   "kyc_verify_over":"Verification has ended",
   "kyc_over_desc":"We are verifying your information now, please be patient.",
   "kyc_verifying":"Verifying",
   "kyc_verifying_desc":"We are verifying your information now, please be patient.",
   "kyc_success":"Verified",
   "kyc_failed":"Verification Failed",
   "kyc_firstName":"First Name",
   "kyc_lastName":"Last Name",
   "kyc_birthDay":"Birthday",
   "kyc_idCartType":"ID Type",
   "kyc_idCartNo":"ID",
   "kyc_failed_tip1":"Reason",
   "kyc_failed_tip2":"The system could not verify your passport photo, please read the instruction and upload again. If you have any questions, please go to [Profile]-[Other Settings]-[Contact us] to contact us.",
   "kyc_verify_second":"Verify again",
   "kyc_unverified":"Not verified",
   "kyc_verified":"Verified",
   "kyc_rejected":"Not passed",
   "kyc_result_title":"Identity Authentication",
   "kyc_card_id":"National ID",
   "kyc_card_license":"Driver License",
   "kyc_passport":"Passport",
   "kyc_country":"Country",
   "home_buy":"Buy Crypto",
   "home_sell":"Sell Crypto",
   "home_trade_title":"Trade & Buy Crypto on Kikitrade",
   "home_share_commission":"Share commission",
   "home_liquidated_volume":"Liquidated Volume",
   "home_past_hour":"Past 1 hour",
   "home_long_short":"Long-Short Ratio",
   "home_market":"Market",
   "home_news":"News",
   "home_more_news":"More News",
   "home_feed":"Feed",
   "home_more_feed":"More Feed",
   "home_bank_transfer_credit":"Bank Transfer / Credit Card",
   "home_crypto_currency":"Crypto currency",
   "home_more_growth":"More growth",
   "webview_about":"About",
   "webview_contact_us":"Contact Us",
   "webview_event":"Event",
   "cfd.process.success":"Process success",
   "cfd.process.fail":"Process failed",
   "cfd.system.close":"System has shut down",
   "cfd.plate.not.exist":"Trading pair not exist",
   "cfd.Insufficient.balance":"Insufficient balanace",
   "cfd.customer.statu.not.active":"User status inactivate",
   "cfd.customer.permission.denied":"Not enough permission",
   "cfd.invalid.parameter":"Invalid parameter",
   "spot.order.invalid.parameter":"Invalid parameter",
   "cfd.margin.not.enough":"Insufficient maintenance margin",
   "cfd.customer.need.id.verify":"Need KYC",
   "cfd.parameter.required":"Must fill-in parameter",
   "cfd.incorrect.operation":"Order status has changed, please reload and try again",
   "cfd.not.exist":"Info not exist",
   "cfd.will.be.liquidationed":"Position liquidation alert",
   "cfd.greater.than.max.free.margin":"Exceed available amount",
   "cfd.stoplossprice.greater.than.marketprice":"Stop-loss price higher than market price",
   "cfd.stoplossprice.less.than.marketprice":"Stop-loss price lower than market price",
   "cfd.takeprofitprice.greater.than.marketprice":"Stop-profit price higher than market price",
   "cfd.takeprofitprice.less.than.marketprice":"Stop-profit price lower than market price",
   "cfd.stoplossprice.greater.than.liquidationprice":"Stop-loss price higher than liquidation price",
   "cfd.stoplossprice.less.than.liquidationprice":"Stop-loss price lower than liquidation price",
   "cfd.customer.stop.trade":"This user has been banned from trading",
   "cfd.contract.config.error":"Contract configuration failed",
   "cfd.entrust.locked":"Too many requests, please try again later",
   "cfd.order.locked":"Too many requests, please try again later",
   "cfd.stoplossprice.greater.than.triggertprice":"Stop-loss price higher than limit price",
   "cfd.stoplossprice.less.than.triggertprice":"Stop-loss price lower than limit price",
   "cfd.takeprofitprice.greater.than.triggertprice":"Stop-profit price higher than limit price",
   "cfd.takeprofitprice.less.than.triggertprice":"Stop-profit price lower than limit price",
   "cfd.invalid.marketprice":"Invalid market price",
   "cfd.takeprofitprice.over.limit":"Stop-profit price exceed maximum limit",
   "cfd.position.over.limit":"Position exceed maximum limit",
   "cfd.margin.less.than.order.min":"Amount smaller than minimum order limit",
   "cfd.margin.greater.than.order.max":"Amount greater than maximum order limit",
   "cfd.user.cancel.processing":"Cancel order in process",
   "cfd.user.close.processing":"Close position in process",
   "cfd.user.adjust.processing":"Adjusting margin in process",
   "post.not.found":"Post doesn't exist or has been deleted",
   "comment.is.not.exist":"Comment doesn't exist or has been deleted",
   "customer.login.password.error.email":"Username or Password is not correct,%{time} times remaining",
   "customer.login.password.error.mobile":"Username or Password is not correct,%{time} times remaining",
   "member.process.success":"Process success",
   "member.process.fail":"Process failed",
   "member.invalid.parameter":"Invalid parameter",
   "member.config.error":"Member configuration error",
   "member.pay.processing":"Loading",
   "member.upgrade.not.supported":"Upgrade not supported",
   "member.insufficient.balance":"Amount insufficient",
   "member.customermisc.exist.none":"User does not exist",
   "activity.req.processing":"Loading",
   "invalid.customer.level":"Your membership level is insufficient",
   "activity.daily.times.limit":"You have no more chance today",
   "hour":"Hour",
   "learn":"Learn",
   "read_more":"Read More >",
   "order_result":"Order Result",
   "deposit_confirming":"Confirming",
   "deposit_success":"Success",
   "deposit_rejected":"Rejected",
   "deposit_reject_fail":"Failed",
   "withdraw_applied":"Confirming",
   "withdraw_success":"Success",
   "withdraw_reject_fail":"Rejected",
   "withdraw_rejected":"Rejected",
   "withdraw_fail":"Failed",
   "depositFiat":"Deposit",
   "withdrawFiat":"Withdraw",
   "firstStep":"First Step",
   "uploadPassportOrID":"Upload ID/Passport",
   "passportOrID":"ID Card (HK only) / Passport",
   "hongkongId":"ID Card (HK only)",
   "kycTips":"KYC Tips",
   "tip":"Tips",
   "tip1":"Tip1",
   "tip2":"Tip2",
   "step1":"Step 1",
   "step2":"Step 2",
   "step3":"Step 3",
   "kyc_detect_over":"Test completed",
   "upload_error":"Upload failed, please try again",
   "uploadSelfImage":"Upload current date selfie",
   "kyc_tip_1":"Select and upload your ID document",
   "kyc_tip_2":"Face recognition: Make sure your face is within the detection area",
   "kyc_tip_3":"Verification result will be available soon",
   "kyc_tip_4":"Tip 1: Only the page with information is required for Passport upload",
   "kyc_tip_5":"Tip 2: No glare, clear, especially for the 2 lines at the bottom.",
   "kyc_tip_6":"Why do we need your information? ",
   "kyc_tip_7":"Click to know more.",
   "kyc_tip_8":"Tip 3: If you have failed 3 times or more, please contact Kiki customer service for help.",
   "kyc_tip_9":"Verification process is done, system will be completed in 1-5 business days.Please wait for the time being.",
   "activity_gift_card":"Gift card redemption",
   "activity_gift_history":"History",
   "activity_gift_enter":"Input your redemption code",
   "activity_gift_placeHolder":"Try to enter Kikitrade",
   "activity_gift_exchange":"Redeem",
   "activity_gift_how":"How to get the gift card?",
   "activity_gift_how1":"Join Kikitrade Facebook campaign to get free gift card.",
   "activity_gift_how2":" Gift Card purchase is not yet available in the app. If you want to buy the gift card, ",
   "activity_gift_how3":"<click here>",
   "activity_gift_success":"Successful",
   "activity_gift_success_desc1":"Your gift card No.{cardNo}has been redeemed successfully.",
   "activity_gift_success_desc2":"{currency} has been sent to your wallet.",
   "activity_gift_success_text1":"Gift card redeemed succesfully.",
   "activity_gift_success_text2":" equiavalent of BTC will be transferred to your wallet soon.",
   "activity_gift_wallet":"Wallet",
   "activity_gift_know":"I understood",
   "activity_gift_disabled":"Redemption is locked",
   "activity_gift_disabled1":"You have entered a wrong redemption code three times. The redemption function is temporarily locked. Please try again tomorrow.",
   "activity_gift_failed":"Redemption failure",
   "activity_gift_failed1":"Your redemption code is not valid or was used.",
   "activity_gift_exchange_useUp":"Running out of redemption ",
   "activity_gift_exchange_useUp1":"Your daily redemption quota (Daily limit: 3 times) has been used up\n Please try again.",
   "otc.customer.payment.way.save.fail":"Failed to save bank card",
   "otc.customer.payment.way.save.exception":"Error to save bank card",
   "otc.customer.payment.way.none.exist":"Bank card info doesn't exist",
   "otc.customer.payment.way.disable":"Bank card info has been deleted",
   "otc.customer.payment.way.op.exception":"Failed to get bank card info",
   "otc.order.save.fail":"Failed to save Fiat order",
   "otc.order.duplicate":"Fiat order duplicate",
   "otc.order.none.exist":"Fiat order doesn't exist",
   "otc.order.process.exception":"Failed to process Fiat order",
   "otc.order.state.invalid":"Fiat order status error",
   "otc.order.update.fail":"Failed to update Fiat order",
   "otc.order.insufficient.balance":"Insufficient balance in Fiat account",
   "otc.account.none.exist":"Fiat account does not exist",
   "otc.order.invalid.param":"Wrong parameter for Fiat order",
   "otc.order.withdraw.amount.limit":"Fiat withdrawal exceed limit",
   "spot.order.success":"Spot order success",
   "spot.order.duplicate":"Spot order duplicate",
   "spot.order.create.fail":"Failed to create spot order",
   "spot.order.symbol.invalid":"This coin is invalid to trade in spot market",
   "spot.order.error":"Spot order failed",
   "spot.order.user.account.insufficient":"User insufficient balance",
   "spot.entrust.account.insufficient":"User insufficient balance",
   "spot.entrust.user.account.insufficient":"User insufficient balance",
   "spot.entrust.cancel.fail":"Spot transaction cancellation failed",
   "spot.order.system.account.insufficient":"System insufficient balance",
   "spot.order.side.invalid":"Invalid parameter for spot order",
   "spot.order.type.invalid":"Invalid type for spot order",
   "spot.order.check.success":"Success verifying spot order",
   "spot.order.min.check":"Spot order is smaller than minimum limit amount",
   "spot.order.max.check":"Spot order is greater than maximum limit amount",
   "lang":"English",
   "liquidatation_analysis":"Liquidation analysis",
   "liquidatation":"Liquidation",
   "funding_rate":"Interest",
   "liquidatation_volume":"Liquidation  Volume",
   "long_liquidations":"Long Liquidations",
   "short_liquidations":"Short Liquidations",
   "long_short_ratio":"Long/Short Ratio",
   "data":"Data",
   "data_Indicator":"BTC Market Sentiment Indicator / BTC MSI",
   "ten_thousand":"M",
   "explore_txt_1":"In the last 24 hours, there were",
   "explore_txt_2":" busts.",
   "explore_txt_3":"Largest single bust order occurred at",
   "worth":"worth",
   "8h":"8H",
   "exchange_liquidatation":"Exchange Liquidation",
   "liquidatation_volume_30_days":"Liquidation volume 30 days",
   "feed":"Feed",
   "recent":"Recent",
   "session_expire":"Session expired, please try again",
   "cfd.amount.less.than.order.min":"Order amount less than minimum order size",
   "cfd.amount.greater.than.order.max":"Order amount is greater than maximum order size",
   "mainStudy":"Main",
   "secondStudy":"Sub",
   "index":"Indicator",
   "enable_now":"Enable Now",
   "finger_login":"Fingerprint login",
   "faceid_login":"Face ID Login",
   "not_now":"Not Now",
   "enable_finger_login":"Enable Fingerprint login",
   "enable_faceid_login":"Enable Face ID Login",
   "switch_account":"Switch accounts",
   "verify_finger":"Verify Fingerprint",
   "verify_faceid":"Verify face ID",
   "try_again":"Try again",
   "retry":"Try again",
   "password_login":"Password login",
   "password_updted":"Password has been updated",
   "use_pass_login":"The account password has been updated. Please use password login",
   "finger_verify":"Fingerprint Verify",
   "enable_finger_login_ok":"Fingerprint login opened successfully",
   "enable_faceid_login_ok":"Face ID login opened successfully",
   "enable_finger_login_fail":"Failed to enable fingerprint login",
   "enable_faceid_login_fail":"Failed to enable face ID login",
   "verify_finger_login_fail":"Failed to login with fingerprint verification. Please login with your password",
   "verify_faceid_login_fail":"Failed to login with face ID verification. Please login with your password",
   "enroll_finger_tip":"Fingerprint unlock has not been enabled. Please go to \"Settings\" --> \"Touch ID & Passcode\" to enable it",
   "enroll_touchid_tip":"Face ID unlock has not been enabled. Please go to \"Settings\" --> \"Face ID & Passcode\" to enable it",
   "fcm.title.otc.withdraw.success":"",
   "fcm.body.otc.withdraw.success":"Congratulations on your successful withdraw of %{amount} [%{currency}] . Learn more>>",
   "fcm.title.otc.withdraw.fail":"",
   "fcm.body.otc.withdraw.fail":"Your withdraw of %{amount} [%{currency}] has failed. Learn more>>",
   "fcm.title.otc.deposit":"",
   "fcm.body.otc.deposit":"Congratulations on your successful deposit of [%{currency}] %{amount}. Learn more>>",
   "fcm.title.deposit":"",
   "fcm.body.deposit":"Congratulations on your successful deposit of [%{currency}] %{amount}. Learn more>>",
   "fcm.title.activity.reward":"",
   "fcm.body.activity.reward":"Your Rewards [%{currency}] %{amount} has arrived, go check it out~",
   "fcm.title.reward":"",
   "fcm.body.reward":"Your Rewards [%{currency}] %{amount} has arrived, go check it out~",
   "fcm.title.withdraw":"",
   "fcm.body.withdraw":"Your withdrawal [%{currency}] %{amount} has passed the review and is being transferred on the chain. Learn more>>",
   "fcm.title.blast.hour.exceed":"",
   "fcm.body.blast.hour.exceed":"[High risk] The current %{currency} liquidation volume has exceeded %{value} USD. Click here to learn more>>",
   "fcm.title.blast.day.exceed":"",
   "fcm.body.blast.day.exceed":"[High risk] The current %{currency} liquidation volume has exceeded %{value} USD. Click here to learn more>>",
   "fcm.title.longshort.exceed.alert":"",
   "fcm.body.longshort.exceed.alert":"[Extremely high risk] The current %{currency} long-short ratio is higher than %{value}. Click here to learn more>>",
   "fcm.title.longshort.exceed.tip":"",
   "fcm.body.longshort.exceed.tip":"[Risk] The current %{currency} long-short ratio is higher than %{value}. Click here to learn more>>",
   "fcm.title.longshort.less.alert":"",
   "fcm.body.longshort.less.alert":"[Risk] The current %{currency} long-short ratio is lower than %{value}. Click here to learn more>>",
   "fcm.title.longshort.less.tip":"",
   "fcm.body.longshort.less.tip":"[Potential risk] The current %{currency} long-short ratio is lower than %{value}. Click here to learn more>>",
   "fcm.title.kyc.result.success":"",
   "fcm.body.kyc.result.success":"Congratulations, your identity verification is successful. For more details>>",
   "fcm.title.kyc.result.fail":"",
   "fcm.body.kyc.result.fail":"Sorry, your identity verification has failed. For more details>>",
   "fcm.title.treasure.trail.interest":"",
   "fcm.body.treasure.trail.interest":"Your daily interest is in your wallet now, go check it out~",
   "fcm.title.investment.result.success":"",
   "fcm.body.investment.result.success":"Your commissioned investment has been deducted successfully~",
   "trade.margin.customer.risk.alert.title":"[Kikitrade] liquidation warning",
   "trade.margin.customer.risk.alert.content":"Dear User,Your account ${account} is judged as high risk by the system, please pay special attention to the risk.(Friendly reminder: You can mitigate the risk by reducing the position in your account or depositing HKD, USD, USDT)",
   "trade.margin.customer.settle.title":"[Kikitrade] settlement notice",
   "trade.margin.customer.settle.content":"Dear User,Your account ${account} has been liquidated or your order has been cancelled.For details: click \"wallet\"- \"History\"-\"Liquidation\"",

   "redeem.code.expired":"Redeem code has expired",
   "redeem.code.not.exist":"Redeem code does not exist",
   "redeem.code.reach.limit":"This redeem code has reached its maximum quota",
   "redeem.code.not.start":"This redeem code is not available yet",
   "finger_lockout_use_pass_login":"There are too many fingerprint login errors, and fingerprint login is temporarily unavailable. Please log in with a password.",
   "faceid_lockout_use_pass_login":"There are too many errors in face login. Face login is temporarily unavailable. Please login with password",
   "finger_login_lockout":"Fingerprint login is not available",
   "faceid_login_lockout":"Face login is not available",
   "lockout_finger_tip":"Fingerprint login is turned off, please turn it on again in \"System Settings-Touch D and Password\"",
   "lockout_touchid_tip":"Face login is closed, please turn it back on in \"System Settings-Face ID and Password\"",
   "finger_lockout_permanent_use_pass_login":"There are too many fingerprint login errors. Fingerprint login has been disabled. Please turn it on again in \"System Settings-Touch D and Password\".",
   "finger_lockout_open_touchid":"There are too many fingerprint verification errors, and fingerprint verification is temporarily unavailable.",
   "faceid_lockout_open_touchid":"The current FaceId verification errors are too many, and FaceId verification is temporarily unavailable.",
   "asset_saving_amount":"Saving Amount",
   "asset_interest_accured":"Interest",
   "asset_annual_interest":"Annual Interest",
   "asset_isolated":"Isolated",
   "asset_equity_value":"Equity Value",
   "asset_total_unrealized":"Total Unrealized PNL",
   "asset_wallet_balance":"Wallet Balance",
   "asset_total_balance":"Total Balance",
   "common_error_view":"Sorry, the contents of this page were taken by a UFO...",
   "longPhoto":"Long",
   "invite_friend_share_text":"Kikitrade is a safe, efficient and user-friendly social investing platform for trading Bitcoin, Ethereum and more! \nJoin Kikitrade together to start our crypto investment journey! 🚀 \nEarn HK$500 reward with me simply by clicking the link below or register with my invitation code. \n",
   "activity_gift_card_text1":"Go grab Kikitrade Gift card, ",
   "activity_gift_card_text2":"claim your Bitcoin in 3 simple steps!",
   "activity_gift_course":"Check out the Tutorial!",
   "activity_gift_virtual":"E-Gift Card",
   "activity_gift_physical":"Gift Card （HK only）",
   "activity_gift_HKD":"HKD",
   "activity_gift_purchase_type":"Payment method",
   "activity_gift_currency":"Currency",
   "activity_gift_amount":"Amount",
   "activity_gift_exchange_tip1":"You will receive：",
   "activity_gift_exchange_tip2":"(Based on prevailing market price)",
   "activity_gift_quantity":"Quantity",
   "activity_gift_sum":"Total: ",
   "activity_gift_total":"Maximum purchase amount is HKD $10,000",
   "activity_gift_pay_tip":"Please transfer HKD ${total} to the following account and upload the screenshot.",
   "activity_gift_payway":"Payment method",
   "activity_account_FPS":"FPS",
   "activity_upload":"Upload screenshot",
   "activity_recipient_info":"Recipient Information",
   "activity_recipient_name":"Recipient name",
   "activity_confirm":"Confirm",
   "activity_recipient_name_enter":"Please enter recipient name",
   "activity_gift_address":"Recipient address",
   "activity_gift_address_enter":"Please enter Recipient address",
   "activity_gift_telephone":"Recipient phone number",
   "activity_gift_telephone_enter":"Please enter recipient phone number",
   "activity_gift_sender":"Sender information ",
   "activity_gift_change":"(Optional)",
   "activity_sender_name":"Sender name",
   "activity_sender_name_enter":"Please enter sender name",
   "activity_gift_note":"Message (no more than 100 words)",
   "activity_gift_note_enter":"Write down New Year Greetings",
   "activity_purchase_virtual":"You have purchased %{quantity} HKD$%{amount} E-gift card. The total price is HKD$%{total}. Please confirm payment.",
   "activity_purchase_physical":"You have purchased %{quantity} HKD$%{amount} Gift card. The total price is HKD$%{total}. Please confirm payment.",
   "activity_purchase_confirm":"Confirm payment",
   "activity_gift_purchase":"Purchase",
   "activity_gift_exchanged":"Redeem",
   "activity_gift_single_amount":"Single",
   "activity_gift_card_type":"Type",
   "activity_gift_virtual_type":"E-gift card",
   "activity_gift_look_code":"Check redeem code",
   "activity_copy":"Copy",
   "activity_exchanged":"Redeemed",
   "activity_gift_physical_type":"Gift card",
   "activity_purchase_card":"Buy gift cards",
   "activity_desc":"How to get more discounts?",
   "activity_desc1":"Join our",
   "activity_desc2":" Facebook campaign ",
   "activity_desc3":"to get more FREE awards!",
   "activity_gift_desc":"Conditions: ",
   "activity_gift_desc1":"1. Gift card is anonymous and will not be expired.",
   "activity_gift_desc2":"2. Once the gift card is redeemed, the redeem code cannot be reused or cancelled.",
   "activity_gift_desc3":"3. In case of disputes, Kikitrade reserves the rights of final decision",
   "activity_purchase_help":"Purchase Gift Card Help",
   "activity_exchange_title":"Redeem Gift Card Method",
   "activity_exchange_step1_title":"Step 1: Login",
   "activity_exchange_step1":"Login your account, register if you do not have.",
   "activity_exchange_step2_title":"Step 2: Scratchcard",
   "activity_exchange_step2":"Scratch off to get and enter redeem code",
   "activity_exchange_step3_title":"Step 3: Successfully get Bitcoin",
   "activity_exchange_step3":"After login successfully, you will be rewarded with designated BTC",
   "activity_buy_success_virtual":"After the payment is confirmed, the redeem code will be available. Also, you can check your redeem code in \"History\".",
   "activity_buy_success_physical":"After the payment is confirmed, the delivery will be arranged. Also, you can check in \"History\" for the status.",
   "activity_buy_success_desc2":"",
   "activity_buy_success_desc3":"",
   "activity_check_order":"Check your order",
   "activity_buy_success_desc":"It's done, very easy right?",
   "activity_back_home":"Back to Home",
   "activity_buy_success_title":"BTC Gift Card",
   "activity_buy_limit":"Total purchase amount cannot exceed $10,000HKD",
   "activity_iknow":"I understand",
   "activity_confirm_paid":"I have already paid",
   "activity_buy_desc1":"Go get Gift card and E-Gift Card,",
   "activity_buy_desc2":"speedy invest in Bitcoin or a gift to your friends",
   "activity_buy_desc3":"Terms & conditions:",
   "activity_buy_desc4":"•All ordered customers can get an extra 8HKD red pocket amount;",
   "activity_buy_desc5":"•First 20 customers will award 1 more BTC red pocket.",
   "activity_buy_exchange_history":"Record",
   "activity_exchanged_code":"Redeem code",
   "activity_Bitcoin_card":"Bitcoin Gift Card",
   "activity_history_progress":"Progress",
   "activity_history_transfer":"Successful",
   "activity_history_delivered":"Delivered",
   "activity_history_cancelled":"Order Cancelled",
   "home_more":"More",
   "home_buy_speed":"Purchase",
   "home_buy_desc":"Cash / Crypto Deposit",
   "home_lucky_draw":"Event Centre",
   "home_lucky_desc":"Get endless awards",
   "home_gift_card":"BTC Gift Card",
   "home_gift_desc":"Get your Bitcoin",
   "home_data":"Data",
   "fcm.title.redeemcard.buy.result.confirm":"",
   "fcm.body.redeemcard.buy.result.confirm":"Your E-gift card purchase is successful. Get your Redeem Code >>",
   "fcm.title.redeemcard.buy.physical.result.confirm":"",
   "fcm.body.redeemcard.buy.physical.result.confirm":"Your Gift card purchase order is successful.",
   "fcm.title.redeemcard.buy.result.delivered":"",
   "fcm.body.redeemcard.buy.result.delivered":"Your Gift card purchase is delivered. For more details >>",
   "fcm.title.redeemcard.buy.result.fail":"",
   "fcm.body.redeemcard.buy.result.fail":"Your Gift card purchase order has been cancelled. For more details >>",
   "follow_order_confirm":"Confirm copy trading",
   "follow_order_confirm_tip":"Due to trader's strategy and the difference of starting position data, data of copy trading and traders' data may not be the same. ",
   "iKonw_1":"Understand",
   "binance_api_tip1":"Support only spot trading and perpetual future API integration.",
   "binance_api_tip2":"Do not support margin and other trading due to possibility of inaccuracy of data.",
   "okex_api_tip1":"Support only spot trading and perpetual future API integration.",
   "okex_api_tip2":"Do not support margin and other trading due to possibility of inaccuracy of data.",
   "ftx_api_tip1":"Support only Perpetual future API integration.",
   "ftx_api_tip2":"Do not support spot, margin and other trading due to possibility of inaccuracy of data.",
   "bitmex_api_tip1":"Support only Perpetual future API integration.",
   "bitmex_api_tip2":"Do not support spot, margin and other trading due to possibility of inaccuracy of data.",
   "api_integration_intro":"API integration",
   "lottery_tip1":"Win your first ever Bitcoin!",
   "lottery_tip2":"KIKI gives you ",
   "lottery_tip3":"free!",
   "lottery_tip4":"Get 1 extra pull by inviting friends",
   "lottery_tip5":"%{chance} pulls left",
   "lottery_tip6":"Earned Bitcoin",
   "lottery_tip7":"Take away BTC",
   "lottery_tip8":"It's in your Kikitrade wallet!",
   "lottery_tip9":"Happy Share",
   "lottery_tip10":"Invite 1 friend, pull +1",
   "lottery_rule":"Rules",
   "lottery_rule_1":"Every new user will award 3 pulls before login. Once registered successfully, you will get 1 extra pull.",
   "lottery_rule_2":"For every successful registration of a friend from your invite, you will get an extra pull. For Top %{rank} users with most invites, they will share %{quantity} BTC Grand Prize. (Deposit into account directly after the event has ended) ",
   "lottery_rule_3_1":"If you get %{num}",
   "lottery_rule_3_2":"you will earn %{quantity} BTC",
   "lottery_rule_4":"For the BTC earned, you need to check and use in KIKITRADE app. For more details, please download KIKITRADE.",
   "lottery_invite_rank":"Leaderboard",
   "lottery_my_invite":"My invites",
   "lottery_user":"User",
   "lottery_invite":"Invite",
   "lottery_share_txt":"Link copied, send to your friends!",
   "lottery_warm_tip":"Friend tips",
   "lottery_reg_tip":"After registration, you will have your own invite link. You can gain extra pulls through inviting friends to register.",
   "lottery_reg":"Register Kikitrade",
   "lottery_tip11":"Ran out of pulls, get extra pulls by inviting friends",
   "lottery_win_prize":"Congratulations, you earn %{quantity} BTC",
   "lottery_tip12":"Congrats! You get",
   "lottery_tip13":"Your BTC has been deposited into KIKITRADE wallet, download KIKITRADE and take away your BTC!",
   "lottery_download_app":"Download APP",
   "lottery_return_home":"Back to Home",
   "lottery_rank":"Rank",
   "lottery_share_btc":"Shareable BTC",
   "lottery_tip15":"I sent you a bitcoin red envelope",
   "lottery_person":"person",
   "login_other_way":"Login with",
   "login_open_auth_process":"Login......",
   "need.binding.email":"Please enter your email",
   "email_binding":"Enter your email",
   "login_oauth_cancel":"Login canceled",
   "login_oauth_fail_retry":"Third-party login failed",
   "login_oauth_not_support_apple":"Current version does not support Apple Login",
   "customer.login.3rd.email.exist":"Email has already registered in Kikitrade",
   "customer.login.3rd.token.error":"Third-party login failed(1105)",
   "login_oauth_timeout":"Third-party login timeout",
   "fiat_transfer_confirm_title":"Confirm leaving Fiat Deposit",
   "fiat_transfer_confirm_desc1":"If you have transferred payment, please make sure you have uploaded the receipt for verification.",
   "fiat_transfer_confirm_desc2":"I confirm that I did not transfer any payment",
   "fiat_transfer_confirm":"Confirm leaving",
   "settings_rate":"Currency",
   "common_usd":"USD",
   "common_hkd":"HKD",
   "apply_trader_desc1":"Apply to be a HERO, get up to",
   "apply_trader_desc2":"More Copiers, more Success Fee!",
   "apply_master_welfare":"Benefits for a HERO",
   "apply_master_desc1":"Award up to 100 USDT for your API integration",
   "apply_master_desc2":"Award 100 USDT for positive return in a month performance",
   "apply_master_desc3":"Users copy your trading can enjoy success fee",
   "apply_contact_way":"Contact (Email/Phone/Social Media account)",
   "apply_input_ph":"Enter your contact",
   "apply_submit":"Hero I go!",
   "apply_item_title":"Rules",
   "aplly_item1":"2. 50 USDT will be rewarded for successful API integration. 25 USDT after 10 days of integration and 25 USDT after 20 days of integration will be awarded to HERO",
   "aplly_item2":"3. When application quota is full, the reward may change.\n For more details, please follow closely on Kikitrade announcement",
   "aplly_item3":"4. In cases of disputes, Kikitrade has the final right of decision.",
   "aplly_item4":"1. Once applied, we will reach out to you through email or phone calls. Please check email box(spam and trash). If you haven\\'t received any emails, please email to info@kikitrade.com or contact our customer service",
   "apply_item5":"Click here.",
   "api_contract":"Margin",
   "api_spot":"Spot",
   "api_info_title":"API info",
   "api_range_title":"Data collection range",
   "api_range_desc":"Please select the actual trading type(s). Any non-working trading operations may lead to API integration failure or inaccuracy of data.",
   "api_way_title":"Unable to collect data range",
   "api_way_desc":"As you haven’t opened any contracts service, the relevant data is not able to be obtained by the API. Please select the correct the trading type. ",
   "api_way_rechoose":"Select again",
   "market_entrust_cancel":"Cancel",
   "market_entrust_counts":"Order size",
   "market_entrust_amount":"Order amount",
   "market_entrust_total":"Order Total",
   "market_entrust_price":"Order price ",
   "market_entrust_type":"Order type",
   "market_entrust_fee":"Fee",
   "market_entrust_cancel_success":"Cancelled",
   "market_entrust_limit":"Limit order ",
   "market_entrust_market":"Market order",
   "market_order_limit":"Limit order",
   "market_order_market":"Market order",
   "market_status":"Status",
   "market_status_success":"Success",
   "market_status_cancel":"Cancel",
   "market_status_failed":"Failed",
   "market_entrust_success":"Order placed",
   "market_entrust_failed":"Fail to place order",
   "huobi_api_tip1":"Support only spot trading and perpetual future API integration.",
   "netConnectError":"Fail connection",
   "pullDownToRefresh":"Slide down to refresh",
   "releaseToRefresh":"Release to refresh",
   "refreshingText":"Refreshing",
   "airplaneTip":"Turn off airplane mode or connect WIFI",
   "home_buy_speed_activity_1":"Thanksgiving Rebates",
   "buyCrpotoActivity_detail":"Thanksgiving Meal",
   "home_buy_desc_activity_1":"Deposits more, rebates more",
   "liveness_desc_1":"Face recognition tips",
   "liveness_desc_2":"Please take off out glasses for Face Recognition",
   "timeout.error":"Connection fail, retry later",
   "login.network.error":"Connection fail, retry later",
   "login.timeout.error":"Login fail, retry later",
   "new_activity_gift_card_text1":"We wish you a Happy 2021 and the Year of the Ox! Come and get the new BTC Red Pocket!First 20 customers can get 1 more!",
   "activityCenter":"Gift Center",
   "activity1":"Event",
   "activityDetail":"Event details",
   "activityIntro":"Terms and conditions",
   "activityVIP":"VIP Event",
   "TrialBonusTitle":"500 USDT FREE Trial Fund",
   "TrialBonusTip1":"1. From 2021-01-27 to 2021-02-06, new registered users get 500 USDT FREE Trial Fund.",
   "TrialBonusTip2":"2. Users can enjoy interest rate at 14% p.a. through trial fund for 14 days.",
   "TrialBonusTip3":"3. Daily Interest will be distributed everyday starting from the next day after registration.",
   "TrialBonusTip4":"4. Daily Interest will be distributed directly to users\\' wallet, please click 【Check my assets】below.",
   "TrialBonusTip5":"1. Trial Fund is only used to experience KIKI Yield Service, the trial fund will not be shown in users\\' assets.",
   "TrialBonusTip6":"2. If you have any questions, please contact our KIKI customer service, please click.",
   "TrialBonusTip7":"3. In case of disputes, Kikitrade reserves the rights of final decision.",
   "TrialBonusTip8":"Register to Redeem",
   "TrialBonusTip9":"Try it out",
   "TrialBonusTip10":"Ongoing",
   "TrialBonusTip11":"Trial Fund",
   "TrialBonusTip12":"Interest",
   "TrialBonusTip13":"received",
   "TrialBonusTip14":"Day left",
   "TrialBonusTip15":"Oh~ You are KIKITRADE Best Friend!",
   "TrialBonusTip16":"Invite friends to get Trial Fund",
   "TrialBonusTip17":"Check My Assets",
   "TrialBonusTip18":"Day Calendar",
   "TrialBonusTip19":"Share FREE BTC",
   "TrialBonusTip20":"Refer a friend",
   "TrialBonusTip21":"Expired",
   "TrialBonusTip22":"get FREE $20HKD of BTC",
   "TrialBonusTip23":"Invite",
   "TrialBonusTip24":"Kikitrade customer support",
   "TrialBonusTip25":"Terms and conditions",
   "TrialBonusTip26":"1. Once your friend has completed registration and KYC with your referral code, both of you would get free Bitcoin ($20HKD equivalent)",
   "TrialBonusTip27":"2. Only after your friend\\'s KYC completion, both of you would get free Bitcoin reward",
   "TrialBonusTip28":"3. The actual BTC amount transferred into your account depends on the real-time exchange rate between BTC and HKD",
   "TrialBonusTip29":"4. In case of disputes, Kikitrade reserves the rights of final decision.",
   "eventCenterTitle":"Kikitrade Gift Centre has launched! ",
   "eventCenterDesc":"FREE events and surprises are waiting you to explore!",
   "moreEvent":"More events are coming!",
   "NewCarouselViewTip1":"Smart Investing",
   "NewCarouselViewTip2":"No minimum deposit. Start investing with \n as little as USD $1 easily.",
   "NewCarouselViewTip3":"Social Trading",
   "NewCarouselViewTip4":"Empower your investment decision",
   "NewCarouselViewTip5":"with Kikitrade’s powerful community",
   "NewCarouselViewTip6":"Flexible Savings",
   "NewCarouselViewTip7":"Enjoy up to 8% p.a. USDT interest rate \n with Kikitrade Savings",
   "NewCarouselViewTip8":"Explore the crypto world",
   "NewCarouselViewTip9":"Join us now to get real-time crypto news and data! ",
   "CarouselViewTip10":"Create an account",
   "CarouselViewTip11":"Already have?",
   "CarouselViewTip12":"Sign In",
   "freeTrialFund":"Free Trial Fund",
   "treasure.transfer.amount.min.less":"Amount smaller than minimum amount",
   "originText_deleted":"Sorry, content has been deleted.",
   "originText_deleted1":"Sorry, the post has been deleted.",
   "announcement":"Announcement",
   "eventAward":"Event Award",
   "eventAward1":"Event Award has arrived, go check wallet>",
   "interest1":"Your interest has arrived, check your Wallet!",
   "depositOtc":"Deposit",
   "depositOtcSuccess":"Deposit Successful",
   "withdrawOtc":"Withdraw",
   "withdrawOtcSuccess":"Withdraw Successful",
   "withdrawOtcFail":"Withdraw Failed",
   "noticeCenter":"Notice Center",
   "clearUnreadNotice":"Delete all unread notifications?",
   "clear":"Delete",
   "originText_blocked":"This post is not available.",
   "viewAll":"View All",
   "emailLogin":"Email",
   "mobileLogin":"Mobile",
   "emailReg":"Email",
   "mobileReg":"Mobile",
   "mobileVerification":"Mobile Verification",
   "verifyCodeSendToMobile":"Verification code has sent to your mobile phone %{mobile}",
   "enterRegion":"Enter Country/Region",
   "enterMobileVerificationCode":"Enter Mobile Verification code",
   "mobileNumber":"Mobile Number",
   "mobileNumberFormatError":"Invalid Mobile Number",
   "KycTokenErrorCode418":"Face recognition reach max. limit, please try again tomorrow",
   "KycTokenErrorCode500":"System error, please report to engineer to check.",
   "KycTokenErrorCode414":"Current device does not support face recognition, please try with another device.",
   "KycLivenessErrorCodeZ1001":"System Error",
   "KycLivenessErrorCodeZ1003":"Verification Interrupted",
   "KycLivenessErrorCodeZ1008":"Verification Interrupted",
   "KycLivenessErrorCodeZ2002":"Network Error",
   "KycLivenessErrorCodeZ1012":"Please try again later",
   "KycLivenessErrorCodeZ2003":"Client device time error",
   "KycLivenessErrorCodeZ2006":"Face brushing failed",
   "KycLivenessErrorCodeZ1004":"Android system version is too low",
   "KycLivenessErrorCodeZ1005":"Face brushing timeout (single time)",
   "KycLivenessErrorCodeZ1006":"Multiple face scan timeout",
   "KycLivenessErrorCodeZ1018":"No front camera",
   "KycLivenessErrorCodeZ1019":"Camera permission is not granted",
   "KycLivenessErrorCodeZ1020":"Failed to open the camera",
   "KycLivenessErrorCodeZ1024":"SDK authentication process is in progress, please wait for the completion of the local authentication process before initiating a new call",
   "KycLivenessErrorCodeZ5112":"Failed to upload colorful Meta information",
   "KycLivenessErrorCodeZ5113":"Failed to upload colorful video",
   "KycLivenessErrorCodeZ6001":"OCR recognition times exceeded",
   "KycLivenessErrorCodeZ6002":"OCR image upload network timeout",
   "KycLivenessErrorCodeZ6003":"OSS Token expired",
   "KycLivenessErrorCodeZ6004":"Face photo processing failed",
   "KycLivenessErrorCodeZ5114":"The user clicks the Home button",
   "KycLivenessErrorCodeZ1011":"Client initialization network error.",
   "KycLivenessErrorCodeZ1025":"The client initialization interface returned a network error.",
   "KycLivenessErrorCodeZ1026":"Information upload network error.",
   "KycLivenessErrorCodeZ1027":"Server authentication interface network error.",
   "KycLivenessErrorCodeIOSZ1001":"Refused to activate camera permission",
   "KycLivenessErrorCodeIOSZ1002":"Cannot start camera",
   "KycLivenessErrorCodeIOSZ1005":"Face brushing timeout (single time)",
   "KycLivenessErrorCodeIOSZ1006":"Multiple face scan timeout",
   "KycLivenessErrorCodeIOSZ1008":"Users take the initiative to log out of authentication",
   "KycLivenessErrorCodeIOSZ1000":"Sorry, there was an error in the system, please try again later",
   "KycLivenessErrorCodeIOSZ1007":"Local liveness detection error",
   "KycLivenessErrorCodeIOSZ1147":"Local liveness detection error",
   "KycLivenessErrorCodeIOSZ1146":"Local liveness detection error",
   "retryTakePhoto":"Re-take photo",
   "takeIdTip1":"Please place your ID document within the box provided",
   "uploadPassport":"Please upload Passport",
   "uploadHongKongId":"Please upload ID Card (HK only)",
   "mobileNumber1":"Mobile Number",
   "twdFiat":"TWD Deposit/Withdrawal",
   "twdFiatOny":"Taiwan users ONLY",
   "twdFiatTerms":"TWD Trade Disclamier",
   "twdFiatTermsHasRead":"I have read",
   "twdFiatTip":"You can buy or sell USDT through TWD. This transaction is provided by Zomfast. The transaction is risky, please read the transaction instructions carefully.",
   "twdFiatStart":"Start trading",
   "KikiBonusTitle":"Kiki Triple Privileges",
   "KikiBonusRegText1":"Sign up now for 500 USDT Trial Fund",
   "KikiBonusRegText2":"Interest is automatically credited to the account every day",
   "goParticipate":"Go",
   "hasDone":"Completed",
   "hasExpired":"Invalid",
   "KikiBonusTitle1":"Kiki Triple Privileges, BTC, USDT is waiting you",
   "KikiBonusIniviteTitle":"Friends Invitation Awards",
   "KikiBonusIniviteText1":"Invite a friend",
   "KikiBonusIniviteText2":"can get $20 HKD equivalent BTC",
   "KikiBonusFiatTitle":"Deposit Rewards",
   "KikiBonusFiatText1":"Deposit in Kiki",
   "KikiBonusFiatText2":"high as XX USDT awards",
   "LoginNow":"Login",
   "referredFriends1":"Invite",
   "moreComment":"Check more comments",
   "unBindSuccess":"Removed successfully",
   "changeBindMobileSuccess":"Link Mobile successfully",
   "changeBindEmailSuccess":"Link Email successfully",
   "bindSuccess":"Linked sccuessfully",
   "changeBindMobile":"Change Mobile",
   "bindMobile":"Link Mobile",
   "enterMobile":"Enter Mobile",
   "unBindTip":"This login account cannot be changed or removed as it was used during registration",
   "changeBindMobile1":"Change Mobile",
   "unBindMobile":"Remove Mobile",
   "changeBindEmail1":"Change Email",
   "unBindEmail":"Remove Email",
   "changeBindMobileTip":"Confirm changing %{mobile}？",
   "changeBindEmailTip":"Confirm changing Email to %{email}？",
   "unBindMobileTip":"Confirm remove Mobile %{mobile}？",
   "unBindEmailTip":"Confirm remove Email %{email}？",
   "unBindMobileTip1":"After Mobile is removed, you cannot login or receive notices through Mobile",
   "unBindEmailTip1":"After Email is removed, you cannot login or receive notices through Email",
   "cancelChangeBind":"You have cancelled changing Mobile",
   "cancelChangeBindEmail":"You have cancelled changing Email",
   "cancelUnbind":"Cancel Remove",
   "changeBindEmail":"Change Email",
   "bindEmail":"Link Email",
   "emailCodeTip":"Please check the trash mail or resend if you cannot find the verification email",
   "bindTip1":"To protect your account, please link your Mobile or turn on Google Autneticator.",
   "bindTip2":"To protect your account, please link your Email or turn on Google Autneticator.",
   "unBind":"Required",
   "bindEd":"Linked",
   "twdFiatTip1":"According to the registrated region, you cannot trade in currency TWD.",
   "bankCardToCrypo":"Bank Transfer to buy Crypto",
   "crypoDeposit":"Already have crypto deposit",
   "inviteFriendText1":"Invitation ",
   "inviteFriendText2":"BIG Upgrade",
   "inviteFriendText3":"",
   "assetTxt1":"Yield BIG Upgrades coming soon!",
   "assetTxt2":"Details",
   "inviteFriendLoginTxt":" Inivite Friends",
   "OldReferredRewardHead":"Previous invitation event (reward BTC)",
   "ReferredReward":"Awards",
   "fiatServiceTimeTip1":"Your application will be processed within 48 hours. However, due to the traffic caused by the market, we have a large number of tasks to be audited, your processing may be delayed. ",
   "fiatServiceTimeTip2":"Your application will be processed within next bank working day. However, due to the traffic caused by the market, we have a large number of tasks to be audited, your processing may be delayed.",
   "fiatServiceTimeTip3":"Your application will be processed within 48 hours. However, due to the traffic caused by the market, we have a large number of tasks to be audited, your processing may be delayed. ",
   "fiatServiceTimeTip4":"Your application will be processed before 10 am today. However, due to the traffic caused by the market, we have a large number of tasks to be audited, your processing may be delayed.",
   "fiatServiceTimeTip5":"Your application will be processed within 1 hour. However, due to the traffic caused by the market, we have a large number of tasks to be audited, your processing may be delayed.",
   "fiatServiceTimeTip6":"Your application will be processed within 72 hours. However, due to the traffic caused by the market, we have a large number of tasks to be audited, your processing may be delayed. ",
   "expectedFinishTime":"Expected Completion Time: ",
   "submitApplyTime":"Submission Time:  ",
   "nextBankWorkHour":"Next Bank Working Day",
   "otc.order.deposit.frequency.limit":"Frequent submissions, please try again in 15 minutes",
   "forceUpdate.cancel":"cancel",
   "forceUpdate.update":"update",
   "forceUpdate.newVersionUpdate":"New version",
   "forceUpdate.desc":"You need to update to the latest version before you can use.",
   "forceUpdate.latestVersion":"The latest version",
   "forceUpdate.updateContent":"Update content",
   "savings":"Savings",
   "flexibleSavings":"Flexible",
   "fixedInvestmentPlan":"Auto-Invest",
   "lockUpPeriod":"Lock-up Period: ",
   "anytimeDepositWithdrawal":"No",
   "todayAPYReference":"Today's APY reference",
   "buyNow":"Subscribe NOW",
   "autoPurchase":"Auto-purchase",
   "purchase":"Subscribe",
   "fixedCurrency":"Currency",
   "historicalRateReturn":"Last 3 years return",
   "participateInFixedInvestment":"Participate",
   "automaticBalanceTransferInvitation":"Balance Auto-transfer Invitation",
   "withdrawBenefits":"Redeem anytime to get interest",
   "inviteSavingTip1":"Auto-transfer Earn Balance to Flexible Saving every day, enjoy up to 10.85% APY.",
   "saveTimeWorry":"Save time and worry",
   "inviteSavingTip2":"Earn more interests automatically",
   "security":"Safe and Secure",
   "inviteSavingTip3":"High security and safeguard",
   "turnOnAutoTransfer":"Turn on",
   "notOpen":"Cancel",
   "autoPurchaseAll":"Auto-subscribe ALL Flexible Savings",
   "autoPurchaseTip1":"Once you turn on Auto-subscribe, all your KIKI Earn balance available to auto-subscribe Flexible saving at daily 7:00~9:00 am (HKT).",
   "turnOnAutoPurchase":"Turn on",
   "autoPurchaseTip2":"Turn on Auto-subscribe for %{num} currencies: %{currency}, please confirm!",
   "haveTurnOnAutoPurchase":"You have turned on Auto-subscribe",
   "haveTurnOnAutoPurchaseTip1":"You have turned on the Auto-subscribe for all currencies, which no repeat actions are required.If you wish to turn OFF Auto-subscribe of any currency, please scroll down and turn off the currency manually.",
   "purchaseAmount":"Subscribe Amount",
   "accountBalance1":"Balance",
   "interestTime":"Interest Time",
   "referenceAPY":"Reference APY",
   "confirmPurchase":"Confirm",
   "confirmPurchaseTerms":"Click on 'Confirm' means you have read and agree to ",
   "kikiSavingsTerms":"《User Service Agreement》",
   "purchaseAmount1":"Subscribe Amount",
   "purchaseTime":"Subscription Time",
   "purchaseSuccess":"Subscription Success",
   "purchaseFail":"Purchase Failed",
   "redeemAmount":"Redeem Amount",
   "redeemTime":"Redeem Time",
   "redeemSuccess":"Redeem Successfully",
   "redeemFail":"Redeem Failed",
   "redeem":"Redeem",
   "redeemCurrency":"Redeem Currency",
   "redeemAmount1":"Redeem Amount",
   "canRedeemAmount":"Amount",
   "interestEarned":"Interest earned",
   "savingAPY":"Saving APY",
   "redeemConfirm":"Confirm",
   "redeemConfirmTip1":"Redeemed amount will be trasnferred to your Spot balance today.",
   "AutoBuyDetailTip1":"Auto-Invest is an investment strategy through investing FIXED amount of money on cryptocurrencies on a REGULAR basis. By setting up your combination plan with desired ratio of various cryptocurrencies, it will Auto-Invest according to your set period.",
   "AutoBuyDetailTip2":"Easy to use, Save time and effort",
   "AutoBuyDetailTip3":"In a short run, the crypto market is unpredictable. Yet, in a long run,  prices of  Excellent Cryptocurrencies  are expected to grow. Here is the question: What & When shall I invest? No worry, Kikitrade provides the best solution: Auto-Invest",
   "AutoBuyDetailTip4":"Dollar-cost averaging",
   "AutoBuyDetailTip5":"Normally Auto-Invest is on monthly basis, which is to invest a fixed amount of momey into currency every month. Accumulate your wealth through this regular Auto-Invest plan and interest earns, with avoiding high risk single investment at the same time.",
   "AutoBuyDetailTip6":"Click to close",
   "AutoBuyDetailTip7":"Know more",
   "AutoBuyDetailTip8":"Auto-Invest is suitable for most investors. Kikitrade has picked the best cryptocurrencies that is suitable for long-term investment. You can set your own plan by choosing Currency, Period, Amount; then Auto-Invest system will follow your plan.",
   "change":"Edit",
   "averageAPY3Years":"Average APY in the past 3 years",
   "easy3Steps":"EASY 3 Steps",
   "setUpYourRegularInvesmtentPlan":"Set-up Your Auto-Invest plan",
   "comboRatio":"Currency & Ratio",
   "amountAndCycle":"Amount & Period",
   "confirmAutoPurchase":"Confirm",
   "regularInvestmentPlanSetting":"Auto-Invest setting",
   "currencyAndRatio":"Currency & Ratio",
   "confirmInvestCurrecnyRatio":"Confirm Currecny",
   "ratio":"Ratio",
   "confirmSaving":"Confirm Invest",
   "autoCircle":"Period",
   "confirmRegularInvestment":"Confirm Auto-Invest plan",
   "autoAmount":"Amount",
   "savingDistribution":"Distribution",
   "amountPerCycle":"Amount per Period",
   "purchaseFee":"Subscription Fee",
   "autoTransferTip1":"Auto-transfer your Auto-Invest into Flexible Savings",
   "autoTransferTip2":"Auto-transfer into Flexible Savings",
   "autoTransferTip3":"If the Auto-Invest currency supports fliexible savings, it will auto-transfer to Flexible savings. ",
   "nextInvestDay":"Next Invest Day",
   "readAndAgree":"I have read and agree to",
   "readAndAgreeModal":"I agree",
   "savingsTerms1":"《User Service Agreement》",
   "confirmAndEffect":"Confirm",
   "agreeSavingsTerms1":"《User Service Agreement》",
   "toReadAndAgreeSavingsTerms1":"Please read carefully and confirm agree to",
   "myPosition":"My Position",
   "allPosition":"All Position",
   "allPositionValue":"Estimated Value",
   "allAccountValue":"Total Assets",
   "yestordayProfit":"Yday Earns",
   "yestordayProfitValue":"Yday Earns",
   "allProfit":"Total Earns",
   "flexibleSavings1":"Flexible",
   "regularInvestment":"Auto-Invest",
   "positionAmount":"Amount",
   "currentAPY":"Current APY",
   "autoPurchaseRate":" APY",
   "autoPurchaseTotalAmount":"Cumulative investment",
   "autoPurchaseAmount":"Amount",
   "running":"Continuing",
   "paused":"Stopped",
   "flexibleSavingsDetails":"Flexible savings Details",
   "positionAmount1":"Position",
   "basicInformation":"Basic Information",
   "buyAmount":"Amount",
   "cumulativeEarns":"Cumulative Earns",
   "purchaseHistory":"Subscription History",
   "purchaseMore":"Subscribe more",
   "orderType":"Type",
   "investmentDetails":"Investment Details",
   "confirmTime":"Confirm Time",
   "contractId":"TxID",
   "investFee":"Fee",
   "investAmount":"Amount",
   "fundInfo":"Funds Info",
   "feeType":"Currency(Ratio)",
   "regularInvestDetails":"Auto-Invest details",
   "investHistory":"Invest History",
   "stopAndDelete":"Stop and Delete",
   "pauseInvest":"Stop Auto-invest",
   "resumeInvest":"Resume",
   "earnHistory":"Earn History",
   "startTime1":"Starting time",
   "endTime":"Ending time",
   "singlePrice":"Price",
   "purchaseFee1":"Fee",
   "redeemFee":"Redeem Fee",
   "withdraw1":"Cancel",
   "interest":"Interest Bearing",
   "purchase1":"Subscribe",
   "distribution":"Distribute",
   "money1":"Amount",
   "daily":"Daily",
   "weekly":"Weekly",
   "biWeekly":"Bi-weekly",
   "monthly":"Monthly",
   "dailyAutoInvest":"Auto-invest every day",
   "weeklyAutoInvest":"Auto-invest every Monday",
   "biWeeklyAutoInvest":"Auto-invest on the 1st and 15th of every month",
   "monthlyAutoInvest":"Auto-invest on the 1st of every month",
   "turnOffAutoTransfer":"Turn OFF Auto-Transfer",
   "pauseInvest1":"Stop",
   "resumeInvest1":"Resume",
   "stopAndDeleteTip1":"Once stopped and deleted, this plan will not be shown and be recovered in your Auto-Invest list.",
   "pauseInvestTip1":"Once stopped, Auto-Invest will not be executed and Earn data calculation will be renewed.",
   "stopInvestTip1":"If you turn OFF Auto-Transfer, you cannot enjoy Flexible Saving Interest and Fee reduction.",
   "resumeInvestTip1":"Once resumed, Auto-Invest will be executed and Earn data calculation will be renewed.",
   "stopAndDelete1":"Stop & Delete",
   "stopTransfer":"Stop transfer",
   "tempNotClose":"Do not close",
   "chooseCurrency":"Choose Currency",
   "currency":"Currency",
   "namePlan":"Name Auto-invest plan",
   "remarkPlan":"Remark this plan name as:",
   "autoInvestInterval":"Auto-Invest Period",
   "financing.product.param.invalid":"Create Investment Products Invalid",
   "financing.product.not.exist":"This Investment Product does not exist or has been removed",
   "financing.contract.not.exist":"Did not subscribe to this investment product",
   "financing.contract.close.auto.subscribe.fail":"Turn off Auto-invest Failed",
   "financing.contract.not.open.auto.subscribe":"Turn on Auto-invest Failed",
   "financing.contract.subscribe.fail":"Purchase Failed",
   "financing.contract.insufficient.balance":"Insufficient Balance",
   "financing.subscribe.amount.less.than.min":"Failed due less than minimum subscription amount",
   "financing.subscribe.amount.more.than.max":"Failed due to exceeding maximum subscription amount",
   "financing.subscribe.amount.not.increment":"Subscrtiption amount must be on the multiple of XXX",
   "financing.subscribe.success":"Subscribed successfully",
   "financing.contract.redeem.fail":"Redeem Failed",
   "financing.account.amount.incorrect":"Amount Incorrect, please try again later",
   "financing.contract.revoke.fail":"Cancel Subscription Failed",
   "financing.subscribe.auto.success":"Auto-Subscription turn ON successfully",
   "financing.subscribe.auto.fail":"Auto-Subscription turn ON failed",
   "financing.product.already.exist":"This investment product already exists",
   "financing.revoke.fail":"Cancel failed",
   "financing.bearing.fail":"Interest bearing failed",
   "financing.product.already.exist.auto.subscribe":"Already turned ON Auto-Subscription",
   "investment.product.param.invalid":"Create Auto-Invest Plan Invalid",
   "investment.product.not.exist":"This Auto-Invest Plan does not exist or has been removed",
   "investment.product.proportion.invalid":"Do not support this ratio",
   "investment.product.pay.option.invalid":"Do not support this payment method",
   "investment.subscribe.amount.less.than.min":"Failed due less than minimum amount",
   "investment.subscribe.amount.more.than.max":"Failed due to exceeding maximum amount",
   "investment.subscribe.amount.not.increment":"Auto-invest amount must be on the multiple of XXX",
   "investment.subscribe.period.invalid":"Do not support this period",
   "investment.contract.not.exist":"Auto-Invest plan does not exist",
   "investment.contract.name.format.invalid":"Auto-Invest plan name format invalid",
   "investment.contract.status.not.running":"Stop Auto-Invest plan",
   "investment.contract.status.not.working":"Auto-Invest plan Failed",
   "financing.operate.fail":"Execution failed",
   "fcm.body.investment.result.fail":"Auto-Invest failed",
   "fcm.body.investment.result.fail.user.invalid":"Auto-Invest user invalid",
   "fcm.body.investment.result.fail.balance.insufficient":"Auto-Invest failed, insufficient balance",
   "earn.transfer.fail.need.upgrade":"Earn transfer fail, requires upgrade",
   "treasure.transfer.fail.need.upgrade":"Earn transfer fail, requires upgrade",
   "noFlexRecord":"No subscribed Flexible Savings",
   "noAutoRecord":"No subscribed Auto-Invest",
   "noEarningRecord":"No subscribed Investment",
   "minPurchaseAmount":"Minimum Purchase Amount %{amount} %{currency}",
   "exceedBalance":"Exceed Available Balance %{amount} %{currency}",
   "maxPurchaseAmount":"Exceed Maximum Purchase amount %{amount} %{currency}",
   "onlyMultiple":"Only Multiple of %{amount}",
   "exceedRedeemAmount":"Exceed available redeem amount %{amount} %{currency}",
   "investment.contract.plan.already.effective":"Auto-Invest already effective",
   "investment.contract.enough.balance.invest":"Please make sure you have enough balance to do Auto-Invest",
   "pleaseSelect":"Please Choose",
   "turnOffAutoTransferSuccess":"Turn OFF auto-transfer successfully",
   "turnOnAutoTransferSuccess":"Turn ON auto-transfer successfully",
   "pauseInvestSuccess":"Pause Auto-Invest successfully",
   "resumeInvestSuccess":"Resume Auto-Invest successfully",
   "renameSuccess":"Edit name successfully",
   "stopAndDeleteSuccess":"Stop & Delete successfully",
   "otc.customer.remark.code.op.exception":"Error getting remark code",
   "otc.customer.remark.code.consume.exception":"Consumption remark code error",
   "depositRemark":"Identifier",
   "turnOnAutoBuyFlexSuccess":"Successfully opened automatic subscription",
   "turnOffAutoBuyFlexSuccess":"Turn off automatic subscription successfully",
   "turnOffAutoBuyInvestmentSuccess":"Turn off Auto-Invest successfully",
   "startNotOverEndTime":"Starting time cannot be over Ending time",
   "fillInForm":"Fill in Form",
   "aip.btc.1620892282130":"Auto-Invest BTC plan",
   "aip.eth.1620895592746":"Auto-Invest ETH plan",
   "aip.doge.1620895784252":"Auto-Invest DOGE plan",
   "product.key":"Auto-Invest BTC plan",
   "product.key2":"Auto-Invest ETH plan",
   "eip.eth001.key":"Auto-Invest ETH plan",
   "product.key1":"Auto-Invest DOGE plan",
   "AccountSuspenseTip":"According to the international AML screening system, the peronal information submitted in Identity Verification is considered as High Risk. Therefore, due to 《User Service Agreement》 and Regulatory and Compliance guidleines, our platform cannot provide service to you, and this account will be temporary freezed. Please click on the Appeal button and submit the ticket, our supporting team will contact you for the arrangements of the accounts and the refund if applicable. Sorry for any inconvenience caused.",
   "activity_gift_currency_HKD":"HKD",
   "activity_gift_currency_USD":"USD",
   "activity_gift_currency_USDT":"USDT",
   "activity_gift_pay_tip_1":"• Please transfer %{total} %{currency} to the following account. You must enter the 6-digit identifier in the remark;",
   "activity_gift_pay_tip_2":"• Please upload your receipt in 24 hours",
   "activity_gift_account_select":"Payment method",
   "activity_gift_account_kikitrade":"Wallet Balance",
   "activity_gift_account_bank":"Bank Transfer",
   "activity_gift_pending_payment":"Pay",
   "activity_gift_balance_tips_title":"Balance description",
   "activity_gift_balance_tips_content":"Available assets in your %{currency} wallet account",
   "activity_gift_bank_name_tips":"(Enter your bank account name)",
   "activity_gift_account_pay":"Pay",
   "activity_gift_limit":"The total purchase amount should not be greater than $%{total} %{currency}",
   "activity_gift_purchase_total":"Gift card purchase per order could not exceed %{total} %{currency} ",
   "activity_purchase_kiki_virtual":"You have purchased %{quantity} virtual gift cards of %{amount} %{currency}. A total of %{total} %{currency} is required. Please confirm the payment.",
   "activity_purchase_kiki_physical":"You have purchased %{quantity} physical gift cards of %{amount} %{currency}. A total of %{total} %{currency} is required. Please confirm the payment.",
   "activity_gift_history_cancel_reason":"Reject Reason:%{reason}",
   "activity_gift_wallet_balance":"Wallet Balance",
   "redeem.account.not.exist":"User not exist",
   "redeem.insufficient.balance":"Insufficient user balance",
   "redeem.card.order.duplicate":"Order number duplicated",
   "redeem.card.order.save.fail":"Purchase Failed",
   "redeem.card.order.update.fail":"Purchase Failed",
   "redeem.card.order.not.exist":"Gift Card not exist",
   "redeem.card.discard.fail":"Purchase Failed",
   "redeem.customer.remark.code.op.exception":"Identifier not working",
   "activity_gift_history_paid":"Pending Delivery",
   "activity_gift_history_failed":"Payment failed",
   "image":"Picture",
   "video":"Video",
   "publishPosting":"Publishing...",
   "publishPosting1":"Publishing...",
   "rePublishPost":"Re-publish",
   "save":"Keep",
   "notSave":"Discard",
   "saveLongTextTip":"Keep this post?",
   "publishPostFailTip":"Failed to publish",
   "publishPostSuccess":"Published successfully",
   "onlyInsertOneVideo":"Upload max. 1 video",
   "maxUploadImageCount":"Upload max. %{count} photos",
   "inputTitle":"Title",
   "inputText":"Text",
   "pubslishPost":"Post",
   "pubslishLongLext":"Article",
   "hasPostPublishing":"Publishing post",
   "latestData":"Latest Value",
   "riseAndFallToday":"Today %",
   "riseAndFallToday1":"24h%",
   "topRiseCurrencyAndRiseRate":"Top Currency(24H)/24h%",
   "fundingRate":"funding rate",
   "orderDetail":"Order Details",
   "avgPrice1":"Avg Price",
   "filledQty":"Filled",
   "tradeDetail":"Trade  Details",
   "tradeTime":"Time",
   "entrust":"Open Orders",
   "allOrder":"All Orders",
   "zones":"Sectors",
   "tradingVolume":"Volume",
   "latestPrice":"Price",
   "riseRate24H":"24h%",
   "24HVol":"24h Vol",
   "spotOrder":"Spot Order",
   "fiatOrder":"Fiat Order",
   "fiatDeposit":"With Fiat money",
   "entrustPrice":"Price",
   "tradePrice":"Price",
   "entrustAmount":"Amount",
   "tradeType":"Deal Type",
   "marketCap":"Market Cap",
   "circulatingSupply":"Circulating Supply",
   "maxSupply":"Max supply",
   "totalSupply":"Total Supply",
   "officialWebsite":"Official web",
   "tradesHistory":"Trade History",
   "orderStatus_pending_create":"Order in progress",
   "orderStatus_partial_filled":"Partially filled",
   "orderStatus_filled":"All completed",
   "orderStatus_partial_filled_cancelled":"Partial order has been cancelled",
   "orderStatus_partial_filled_rejected":"Partial transaction has been rejected",
   "orderStatus_rejected":"Rejected",
   "search_care_coin_name":"Search your crypto here",
   "hot_search":"Trending Searches",
   "history_search":"Search History",
   "history_search_clear":"Clear",
   "search_result":"Search Results",
   "search_cancel":"Cancel",
   "choice_add":"Add",
   "edit_finish":"Done",
   "edit_choice":"Edit",
   "all_select":"Select All",
   "favourite":"Favourite",
   "choice_header_name":"Name",
   "choice_header_top":"Pinned",
   "choice_header_drag":"Move",
   "choice_auto_add":"Add",
   "choice_custom_add":"Customize",
   "input_transaction_volume":"Enter Amount",
   "buy_recharge":"Deposit",
   "add_favorite":"Add favorite",
   "add_favorite_success":"Favourite added",
   "add_favorite_failed":"Add Failed",
   "edit_favorite_success":"Edited",
   "edit_favorite_failed":"Edit failed",
   "remove_favorite_success":"Favourite Removed",
   "remove_favorite_failed":"Remove Failed",
   "add_favorite_zone_success":"Sector Added to Favourite",
   "add_favorite_zone_failed":"Sector Added to Favourite Failed",
   "remove_favorite_zone_success":"Sector Removed from Favourite",
   "remove_favorite_zone_failed":"Sector Removed from Favourite Failed",
   "symbol_list":"Trading pairs",
   "tsla":"Tesla",
   "aapl":"Apple",
   "fb":"Facebook",
   "amzn":"Amazon",
   "googl":"Google",
   "maotai":"Moutai",
   "coinBase":"Coinbase",
   "tencent":"Tencent",
   "HSI":"Hang Seng Index",
   "TWSE":"Taiwan Weighted Index",
   "nasdaq":"Nasdaq Index",
   "XAUUSD":"Gold Dollar Price",
   "CL":"Crude Oil Index",
   "DINIW":"Dollar Index",
   "US30Y":"USD 30-Year Bond Index",
   "says":"says",
   "greed":"greed",
   "extremeGreed":"Extreme Greed",
   "neutral":"Neutral",
   "fear":"fear",
   "extremeFear":"Extreme Fear",
   "timeType15M":"15M",
   "timeType1H":"1H",
   "timeType4H":"4H",
   "timeType1D":"1D",
   "timeType1W":"1W",
   "timeType1M":"1M",
   "community_hot_topic_feed":"Hot topic",
   "participationTopic":"Join discussion",
   "relatedTransactions":"You may also be interested in...",
   "comeAndComment":"Leave your comment...",
   "postCountSuffix":"Posts",
   "topic.not.found":"The discussion has been removed.",
   "refreshPostCount1":"",
   "refreshPostCount2":" new posts",
   "refreshPostRecommend":"Hot Feeds has been updated",
   "interest2":"Interest",
   "liquidation1":"Liquidation",
   "borrowed":"Borrowed",
   "liquidationAmt":"Liquidation Amt",
   "frozen":"Frozen",
   "maxAvbl":"Max Avbl",
   "interestFee":"Interest Fee",
   "8HourInterestRate":"8-Hour Interest Rate",
   "balance1":"Balance",
   "maxWithdrawal":"Max Withdrawal",
   "marginTurnedOn":"Margin turned on",
   "marginDisabled":"Margin disabled",
   "marginTurnedOnFail":"Failed to open leverage",
   "marginDisabledFail":"Failed to close the leverage",
   "wallet1":"Wallet",
   "open":"Open",
   "netAssetValue":"Net Asset Value",
   "initialMargin":"Initial Margin",
   "maintenanceMargin":"Maintenance Margin",
   "hideSmallBalances":"Hide Small Balances",
   "marginTip1":"The auto-purchase has been suspended since the margin mode was on. You can subscribe the Savings manually.",
   "marginTip2":"You have to repay your debt before closing the margin trading.",
   "marginTip3":"• The current account still has interest payable, and the system has automatically refunded the interest payable for you.\n•As the account assets are not enough to deduct the interest payable, the loan amount is generated. Please fully return the loan before closing the leverage." ,
   "marginTip4": "There is still a margin order in the current account that is in order, please cancel the margin order and then close the leverage",
   "marginTip5":"Please cancel the pending order first, and then open the leverage",
   "marginTip6":"Currently, your account has opened the leverage mode, and you cannot use the fixed investment function of wealth management. You can manually subscribe to purchase wealth management products.",
   "marginTip7":"Please turn off automatic subscription for wealth management first, and then turn on leverage",
   "marginTip8":"Please close the fixed investment of financial management first, and then open the leverage",

   "yourDebt":"Your debt",
   "withMargin":"• With margin:",
   "maxAvblTip1":"Max. = (NAV-IM)/IMR*（1-Fee/IMR）*0.99 /Ask Price + abs(Sell Unwind Qty)",
   "maxAvblTip3":"Maximum withdrawal amount = (Net Asset Value NAV-Initial Margin IM) *60% /Bid Price",
   "maxAvblTip4":"Maximum withdrawal amount = balance;",
   "maxAvblTip5":"Maximum available = (Net Asset Value NAV-Initial Margin IM) *60% /Bid Price",
   "maxAvblTip6":"Maximum available = account balance;",

   "withoutMargin":"• Without margin:",
   "maxAvblTip2":"Max. = Total Current Assets",
   "interestFeeTip1":"Kikitrade charges interest 3 times a day at ",
   "interestFeeTip2":"00:00. 08:00 and 16:00 (UTC+8)",
   "interestFeeTip3":"You can check interest fee at \"Wallet\" ->\"Interest fee\". I",
   "interestFeeTip4":"Interest rate will be 8-hour interest-rate, and charged every 8 hour. If a borrowed asset repays within 8 hour, will be counted as 8 hour period of time.",
   "riskRateTip1":"If the value of risk rate is lower than 1, means one's portfolio is more likely to get liquidated. On the contrary, the risk of liquidation is lower if the value is higher.\nRisk rate = Net Asset Value / Maintenance Margin",
   "riskRate":"Risk rate",
   "imTip1":"This is the minimum margin required when adding a position. It will increase with an enlarger of the position.\nYou can only increase the position when \"Total Net Asset Value <IM\" after placing an order.",
   "mmTip1":"This is the asset's liquidation point.\nIf Net Asset Value < MM, liquidation starts.\nMM = Initial Margin x 80%",
   "marginModeClosed":"Margin Mode Closed",
   "loanInterest":"Loan Interest",
   "marginTrading":"Margin Trading ",
   "10Bid":"Bid",
   "10Ask":"Ask",
   "orderBook":"Order Book",
   "borrow":"Borrow",
   "maxBuy":"Max Buy",
   "maxSell":"Max Sell",
   "spotBuy":"Spot Buy",
   "spotSell":"Spot Sell",
   "used":"Used",
   "borrowed1":"Borrowed",
   "marginAvbl":"Margin Avbl",
   "spotAvbl":"Spot Avbl",
   "spotAvbl1":"Spot Avbl",
   "enterTotalAmount":"Enter Total Amount",
   "enterBuyAmount":"Enter Buy Amount",
   "enterSellAmount":"Enter Sell Amount",
   "riskRateLiquidating":"Liquidating",
   "riskRateWarning":"Warning",
   "riskRateHigh":"High",
   "riskRateMedium":"Medium",
   "riskRateLow":"Low",
   "riskRateSafe":"Safe",
   "minBuyMessage":"Min buy order amount cannot ≤ %{amount}",
   "minSellMessage":"Min sell order amount cannot ≤ %{amount}",
   "maxBuyMessage":"Max buy order amount %{amount}",
   "maxSellMessage":"Max sell order amount %{amount}",
   "oneMaxBuyMessage":"Max buy order amount cannot ≥ %{amount}",
   "oneMaxSellMessage":"Max sell order amount cannot ≥ %{amount}",
   "minTotalMessage":"Min transaction limits cannot ≤ %{amount}",
   "oneMaxTotalMessage":"Max transaction limits cannot ≥ %{amount}",
   "oneMinTotalMessage":"Min transaction limits cannot ≤ %{amount}",
   "maxAvblMessage":"Max available %{amount}",
   "openMarginTip":"The amount you have used exceeds the maximum available spot, and you can continue trading by opening leverage.",
   "repaymentPending":"Repayment Pending",
   "liquidatedAssets":"Liquidated Assets",
   "ordersCancelled":"Orders Cancelled",
   "spot.order.invalid.status":"Order Cancellation Failed",
   "spot.order.order.book.cancel.fail":"Order Cancellation Failed",
   "spot.order.update.fail":"Update failed",
   "spot.order.trade.duplicate":"Order failed",
   "spot.order.trade.state.illegal":"Order status is wrong",
   "spot.customer.stop.trade":"Trading status error",
   "spot.order.book.invoke.fail":"Order failed",
   "margin.interest.state.illegal":"Close Leverage Failed",
   "margin.interest.duplicate":"Duplicate leveraged interest processing",
   "margin.config.update.fail":"Update failed",
   "margin.config.disable.forbidden":"Leverage closing failed",
   "margin.balance.insufficient":"Insufficient balance",
   "margin.lock.fail":"Order failed",
   "margin.unlock.fail":"Risk check unlock failed",
   "margin.orders.exceed":"The number of orders placed with leverage exceeds the limit",
   "margin.transfer.fail":"Insufficient balance",
   "margin.transfer.lock.fail": "Failed to open leverage, please try again later",
   "margin.open.country.forbidden":"Your current location does not allow margin trading",
   "margin.open.saving.forbidden":"Please turn off automatic subscription for wealth management first, and then turn on leverage",
   "margin.open.invest.forbidden":"Please close financial fixed investment first, and then open leverage",
   "margin.open.order.forbidden":"Please cancel the pending order first, and then open the leverage",
   "margin.open.query.order.fail":"Lever opening failed, please try again later",
   "margin.close.loan.forbidden":"Close leverage failed-user has borrowed money",
   "margin.close.order.forbidden":"Please cancel the pending order first, and then open the leverage",
   "margin.close.interest.cannot.afford":"When the leverage is closed, the interest payable is insufficient",
   "margin.interest.fail": "Failed to close the leverage, please try again later",
   "margin.already.closed":"Leverage is closed",
   "margin.close.fail": "Lever close abnormal",
   "noFav":"No collection yet",
   "noFeed":"No feed yet",
   "noFollow":"No follow yet",
   "noComment":"There are no comments yet",
   "noReply":"There is no reply yet",
   "noSystemNotice":"There is no notice yet",
   "noFans":"No fans yet",
   "noWow":"I haven't received likes yet",
   "noData":"No data temporarily",
   "noSearch":"The content you want was not searched",
   "noPostComment":"There are no comments yet",
   "coinCoin":"Spot",
   "closeAutoBuyTip":"This operation will close your current wealth management automatic subscription function, Are you sure to close?",
   "closeAutoInvestTip":"This operation will close your financial fixed investment function, Are you sure to close?",
   "marginTradeNotOpen":"Kikitrade Margin is coming soon, please be patient!",
   "borrowCoinTip1": "A confirmation prompt is required every time a transaction generates a loan",
   "borrowCoinTip2":"This transaction will generate ≈%{amount} %{currency} borrowing amount. Are you sure to continue the transaction?",
   "autoInvest":"Auto Invest",
   "system.status.upgrading":"The system is being upgraded and maintained. Please try again after the upgrade is complete.\nMaintenance time: 2021-10-21 10:00-18:00",
   "credit_recharge":"Credit Card",
   "credit_desc":"Visa, Mastercard and SEPA etc.",
   "credit_channels_close":"Credit card payment service has been temporarily suspended, please try again later!",
   "credit_buy_coin":"Pay with Credit Card",
   "buy_by_amount":"Total amount",
   "buy_by_quantity":"Buy amount",
   "credit_spend":"Total",
   "credit_estimated":"Est. purchase amt",
   "credit_buy":"Buy",
   "credit_estimated_cost":"Est. cost",
   "credit_input_valid":"Please enter a valid number",
   "credit_not_min":"Cannot be less than the min order size",
   "credit_not_max":"Cannot exceed the max order size",
   "credit_order_next_step":"Continue",
   "credit_channel_type":"Payment gateway",
   "credit_channel_info":"Instruction",
   "credit_estimated_error":"There is a problem with the service of the payment gateway, please try again later.",
   "credit_channel_1_SWITCHERE":"• You are required to complete the registration and KYC process of Switchere in order to purchase cryptocurrency on it.",
   "credit_channel_2_SWITCHERE":"• The minimum order quantity for %{fiatCurrency} trading in switchere is %{fiatCurrencyMin} %{fiatCurrency}, and the maximum order quantity is %{fiatCurrencyMax} %{fiatCurrency}.",
   "credit_channel_3_SWITCHERE":"• The amount of purchased cryptocurrency you see is the estimated amount. Please refer to the final payment transaction amount on Switchere for the actual purchase amount.",
   "credit_channel_4_1_SWITCHERE":"• If you have any questions, please check the Switchere ",
   "credit_channel_4_2_SWITCHERE":" page.",
   "credit_order_history":"Order History",
   "credit_order_confirmation":"Order confirming",
   "credit_order_pending":"Payment Pending",
   "credit_order_failed":"Transaction Failed",
   "credit_order_failure_reason":"Failure details",
   "credit_order_successful":"Successful",
   "credit_order_customer_service":"Contact Us",
   "credit_order_payment_completed":"Continue to pay",
   "credit_order_transaction_confirmation":"Order Confirmed",
   "credit_order_billing_information":"Invoice",
   "credit_order_payment_channel":"Cryptocurrency",
   "credit_order_fiat_currency":"Fiat currency",
   "credit_order_fiat_amount":"Amount",
   "credit_order_purchase_currency":"Name",
   "credit_order_estimated_quantity":"Est. amount",
   "credit_order_purchase_quantity":"Purchase amount",
   "credit_order_estimated_amount":"Est. price",
   "credit_order_place_order":"Order",
   "credit_order_loading":"Please wait...",
   "credit_order_disclaimer":"Disclaimer",
   "credit_disclaimer_tips1_SWITCHERE":"• You'll be automatically redirected to Switchere.com. Switchere is a licensed third-party exchange and all related services are provided by Switchere. Please read and understand the Simplex's Terms of Use carefully before using the service.",
   "credit_disclaimer_tips2_1_SWITCHERE":"• If you have any questions, please contact ",
   "credit_disclaimer_tips2_2_SWITCHERE":" customer support.",
   "credit_disclaimer_tips3_SWITCHERE":"• Kikitrade shall not be responsible for or liable in respect of any loss or damage suffered from Switchere.",
   "credit_order_trading_channel":"Channel",
   "credit_order_currency":"Cryptocurrency",
   "credit_order_amount":"Amount",
   "credit_order_fee":"Transaction Fee",
   "credit_order_on_chain_fee":"On-chain Transaction Fee",
   "credit_card_transaction":"Credit Card Purchase",
   "credit_order_reminder":"Purchase Notifications",
   "credit_order_whether_completed":"Have you paid for the current order?",
   "credit_order_incomplete":"Incomplete",
   "credit_order_completed":"Completed",
   "keyboard_desc":"KIKI keyboard to make your typing safer",
   "credit_fiat_select":"Choose currency",
   "credit_crypto_select":"Choose crypto",
   "quickpay.order.create.fail":"Order creation failed.",
   "quickpay.order.update.fail":"Order update failed.",
   "quickpay.channel.closed":"Payment channel has been shut down.",
   "quickpay.channel.not.support.the.service":"This payment channel does not support this service.",
   "quickpay.order.not.exist":"Order does not exsit.",
   "quickpay.channel.api.call.fail":"Calling Payment channel API failed.",
   "fcm.title.quickpay.order.fail": "",
   "fcm.body.quickpay.order.fail": "You've an invalid transaction. Please click here for details.",
   "fcm.title.quickpay.order.success": "",
   "fcm.body.quickpay.order.success":"You've made a successful deposit of [%{payinCurrency}] %{payinAmount}. Please click here for details.",
   "community":"Community ",
   "news":"News",
   "explore":"Explore",
   "deposit1":"Deposit",
   "hotCoin":"Popular",
   "newCoin":"New",
   "lastEvent":"Latest events",
   "tags":"Tags",
   "hotTheme":"Hot Topics",
   "kikiStars":"Kiki Stars",
   "hotPicks":"Hot Picks",
   "kikiMaster":"Kiki Master",
   "zoneNameETH":"ETH Board",
   "zoneNameMAIN":"Mainstream Coin",
   "zoneNameDEFI":"Defi Board",
   "zoneNameCOMMON":"Public Chain",
   "zoneNamePOW":"POW Ecology",
   "zoneNameDOT":"DOT ecology",
   "zoneNameMEME":"MEME ecology",
   "zoneNameSOL":"SOL Ecology",
   "zoneNameBSC":"BSC ecology",
   "zoneNameNFT":"NFT ecology",
   "zoneNameGAMEFI":"GameFi",

   "deposit_step1": "Step 1: Login",
   "deposit_step2": "Step 2: KYC",
   "deposit_step3": "Step 3: Deposit",
   "deposit_login": "Login",
   "deposit_KYC": "KYC",
   "deposit_deposit": "Deposit",
   "deposit_bankDeposit_title": "Bank transfer",
   "deposit_bankDeposit_desc": "Wire transfer to buy coins",
   "deposit_creditDeposit_title": "Credit Card",
   "deposit_creditDeposit_desc": "Accept Visa, Mastercard, etc...",
   "deposit_c2cDeposit_title": "C2C",
   "deposit_c2cDeposit_desc": "Direct exchange between users/traders",
   "deposit_giftCard": "Gift card",
   "deposit_giftCard_desc": "Go grab Kikitrade gift card, claim your Bitcoin in 3 simple steps!",
   "deposit_giftCard_button": "Buy",
   "deposit_guideForBeginners": "Kiki Learn"

}

export default obj;
