/* @flow */

import React, { Component } from 'react';
import type { Element } from 'react';
import {
  Text,
  TouchableWithoutFeedback,
  // FlatList,
  ScrollView,
  RefreshControl,
  Clipboard,
  Dimensions,
  View
} from 'react-native';
import { connect } from 'react-redux';
// import FastImage from 'react-native-fast-image';
import p from '../../utils/Transfrom';
import I18n from '../../utils/i18n';
import EditTopicOrVoteModal from '../../components/EditTopicOrVoteModal';
import DeleteModal from '../../components/DeleteModal';
import { Toast } from 'teaset';
// import _ from 'lodash'
// import { GetHotTopic } from '../../actions/SocialAction';
import { defaultMapDispatchProp, customMapStateProp } from '../../reducers';
import helper from '../../utils/helper';
import messaging from '@react-native-firebase/messaging';

import { getClient } from '../../api/social';
import type { Feed } from '../../models/social';
const height = Math.round(Dimensions.get('window').height);

type BodyType = {
    result: Array<*>
};

// type TopicItemType = {
//     [key: string]: number,
//     foo: string
// };

type PropsType = {};
type StateType = {};

export class Manage extends Component <PropsType, StateType> {
  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  constructor (props: PropsType) {
    super(props);
    this.state = {
      editTopicOrVoteModalVisble: false,
      topicData: [],
      pollData: [],
      fcmToken: '',
      refreshing: false,
      currentActivity: {}
    };
  }

  componentDidMount: () => void = () => {
    // this.createTopic()
    // this.createPoll()
    // GetHotTopic()
    const that = this
    this._onRefresh()

    messaging().getToken()
      .then((fcmToken: string) => {
        if (fcmToken) {
          that.setState({
            fcmToken: fcmToken
          })
          // user has a device token
        } else {
          // user doesn't have a device token yet
        }
      });

    // this.props.dispatch(GetHotTopic());
    // this.getPoll()
  };

  _onRefresh: () => void = () => {
    // this.props.dispatch(GetHotTopic());
  };

  deleteTopicOrVote: (activityId: string) => void = (activityId: string): void => {
    const feedParamUser = this.state.type;
    const { userId } = this.props.SocialReducer;
    const feedParamType = global.USER_ID || userId
    if (!feedParamType) {
      return helper.navigate(this.props.navigation.navigate, 'Login');
    }
    getClient()
      .feed(feedParamUser, feedParamType)
      .removeActivity(activityId)
      .then(() => {
        Toast.show({
          text: I18n.t('successfullyDelete'),
          position: 'center'
        });
        this._onRefresh()
      })
      .catch(() => {
      })
  };

  increateShareCount: (id: string) => void = (id: string) => {
    getClient()
      .reactions.add('SHARE', id)

      .then((body: BodyType) => {
        if (body.result) {
          Toast.show({
            text: I18n.t('share_success'),
            position: 'center'
          });
          this._onRefresh()
        }
      });
  };

  onChoose: (type: number) => void = (type: number) => {
    // console.log("onScrollBeginDrag")
    // alert("scroll");
    const that = this
    this.setState({
      editTopicOrVoteModalVisble: false
    })
    if (type === 1) {
      setTimeout(function () {
        that.setState({
          deleteTopicOrVoteModalVisble: true,
          deleteTip: that.state.type === 'topic' ? I18n.t('community_delete_topic_tip') : I18n.t('community_delete_poll_tip')
        })
      }, 500)
    }

    if (type === 2) {
      this.increateShareCount(this.state.currentActivity.id)
    }
  };

  onSelectPost: (item: Feed, type: string) => void = (item: Feed, type: string) => {
    // alert("scroll change");
    this.setState({
      editTopicOrVoteModalVisble: true,
      type: type,
      currentActivity: item
    })
  };

    keyExtractor: (item: Feed, index: number) => string = (item: Feed, index: number): string => {
      return index.toString()
    };

    // renderItem: (data: {item: Feed }) => Element<*> = (data: {item: Feed }): Element<*> => {
    //   const item = data.item;
    //   return (
    //         <View style={{ borderRadius: p(24), backgroundColor: '#fff' }}>
    //             <View

    //                 style={{ height: p(48), paddingHorizontal: p(30), marginTop: p(16), justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}
    //             >
    //                 <Text style={{ color: '#8E939F', fontSize: p(28), fontWeight: 'bold' }}>分享数  {item.shareCount}</Text>
    //                 {/* eslint-disable-next-line flowtype/require-return-type */}
    //                 <TouchableWithoutFeedback onPress={(): void => this.onSelectPost(item, 'topic')}>
    //                     <FastImage style={{ width: p(48), height: p(48) }} source={require('../../images/more.webp')}/>
    //                 </TouchableWithoutFeedback>
    //             </View>
    //             <Text style={{ paddingBottom: p(42), paddingHorizontal: p(30), paddingTop: p(35), lineHeight: p(38), color: '#696F7F', fontWeight: 'bold', fontSize: p(28) }}>
    //                 {item.text}
    //             </Text>
    //         </View>

    //   )
    // };

  onPressDelete: () => void = () => {
    // alert("onPressDelete")

    this.setState({
      deleteTopicOrVoteModalVisble: false
    })
    this.deleteTopicOrVote(this.state.currentActivity.id)
  };

    ItemSeparatorComponent: () => Element<*> = (): Element<*> => {
      return <View style={{ height: p(24) }}/>
    };

    toggleModal1: (editTopicOrVoteModalVisble: boolean) => void = (editTopicOrVoteModalVisble: boolean) => {
      this.setState({
        editTopicOrVoteModalVisble: editTopicOrVoteModalVisble
      })
    };

    toggleModal2: (deleteTopicOrVoteModalVisble: boolean) => void = (deleteTopicOrVoteModalVisble: boolean) => {
      this.setState({
        deleteTopicOrVoteModalVisble: !deleteTopicOrVoteModalVisble
      })
    };

    // eslint-disable-next-line flowtype/require-return-type
    render (): Element<*> {
      const { editTopicOrVoteModalVisble, deleteTip, deleteTopicOrVoteModalVisble, refreshing, fcmToken } = this.state;
      // let { hotTopic, hotPoll } = this.props.SocialReducer;
      // hotPoll = _.sortBy(hotPoll, 'shareCount').reverse()
      // hotTopic = _.sortBy(hotTopic, 'shareCount').reverse()

      return (

      <View style={{ height: height, flex: 1, backgroundColor: '#F8F8F8', paddingHorizontal: p(20) }}>
        <ScrollView
          contentContainerStyle={{ paddingBottom: p(150) }}
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={() => {
                  this._onRefresh();
                }}
                // this._onRefresh.bind(this)
              />
            }
          >

          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Text style={{ color: '#666', fontSize: p(24), width: p(500) }}>Firebase Token:{fcmToken}</Text>
            <TouchableWithoutFeedback onPress={() => {
              Clipboard.setString(fcmToken);
              Toast.show({
                text: I18n.t('复制成功'),
                position: 'center'
              });
            }}>
              <Text>{I18n.t('copy')}</Text>
            </TouchableWithoutFeedback>
          </View>
{/*
          <View style={{ height: p(88), justifyContent: 'flex-end', paddingBottom: p(10) }}>
            <Text style={{ color: '#000', fontSize: p(36), fontWeight: 'bold', lineHeight: p(50) }}>
              {I18n.t('community_hotPost')}
            </Text>
          </View>

          <FlatList
            data={hotTopic}
            ItemSeparatorComponent={this.ItemSeparatorComponent}
            scrollEnabled={false}
            style={{ marginTop: p(12) }}
            renderItem={this.renderItem.bind(this)}
            keyExtractor={this.keyExtractor}
          />

          <View style={{ height: p(88), justifyContent: 'flex-end', paddingBottom: p(10), marginTop: p(24) }}>
            <Text style={{ color: '#000', fontSize: p(36), fontWeight: 'bold', lineHeight: p(50) }}>
              {I18n.t('community_vote')}
            </Text>
          </View>

          <FlatList
            data={hotPoll}
            ItemSeparatorComponent={this.ItemSeparatorComponent}
            scrollEnabled={false}
            style={{ marginTop: p(12) }}
            renderItem={this.renderItem}
            keyExtractor={this.keyExtractor}
          /> */}
        </ScrollView>
        <EditTopicOrVoteModal
          onChoose={this.onChoose}
          visible={editTopicOrVoteModalVisble}
          toggleModal={this.toggleModal1}
        />
        <DeleteModal
          onPressDelete={this.onPressDelete.bind(this)}
          visible={deleteTopicOrVoteModalVisble}
          tip={deleteTip}
          title={I18n.t('deleteConfirm')}
          toggleModal={this.toggleModal2.bind(this)}
        />

      </View>
      );
    }
}

type PageOwnPropType = {};
const connector = connect(customMapStateProp<PageOwnPropType>(
  {
    SocialReducer: 1,
    HomeReducer: 1,
    AccountReducer: 1
  }
), defaultMapDispatchProp<PageOwnPropType>());
export default (connector(Manage): Class<Manage>);
