// @flow
// import EncryptedStorage from 'react-native-encrypted-storage';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { persistReducer } from 'redux-persist';
import { type PersistConfig } from 'redux-persist/es/types';
import type { Reducer } from 'redux';
import { API_ENV } from '@env';
import type { PersistActionType, EarningsStateType } from '../actions/common';
import type { EarningsActionsType } from '../actions/EarningsAction';

// import I18n from '../utils/i18n';
// import helper from '../utils/helper';
import { ActionTypes } from '../actions/ActionTypes';
// import defaultData from '../offlineData';

const initialState: EarningsStateType = {
  isFirstShow: true,
  loadingFinancingProducts: false,
  fetchFinancingProductsNextToken: '',
  financingProducts: [],
  loadingInvestmentProducts: false,
  fetchInvestmentProductsNextToken: '',
  investmentProducts: [],
  loadingFinancingContracts: false,
  financingContracts: [],
  fetchFinancingContractsNextToken: '',
  loadingInvestmentContracts: false,
  investmentContracts: [],
  fetchInvestmentContractsNextToken: '',
  financingPositions: {},
  investmentPositions: {},
  totalRevenues: {},
  yesterdayRevenues: {}
};

export type PersistEarningsActionType = EarningsActionsType | PersistActionType;
type VoidEarningsStateType = EarningsStateType | void;

const earningsreducer: Reducer<EarningsStateType, PersistEarningsActionType> = (state: VoidEarningsStateType, action: PersistEarningsActionType) => {
  if (action.type === ActionTypes.REHYDRATE) {
    console.log('get rehydrate return');
    // console.log('authReducer rehydrate state is ' + JSON.stringify(state));
    return state ?? initialState;
  }
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    // 获取全部
    case ActionTypes.FIRST_SHOW:

      return Object.assign({}, state, {
        isFirstShow: false
      });
    case ActionTypes.FINANCING_PRODUCTS:
      // eslint-disable-next-line no-case-declarations
      const isMore = action.isMore;
      // eslint-disable-next-line no-case-declarations
      let financingProducts = state.financingProducts;
      if (isMore === 'init') {
        financingProducts = action.financingProducts
      } else if (isMore === 'addMore') {
        financingProducts = state.financingProducts.concat(action.financingProducts)
      }
      return Object.assign({}, state, {
        loadingFinancingProducts: false,
        financingProducts: financingProducts,
        fetchFinancingProductsNextToken: action.nextToken ?? ''
      });
    case ActionTypes.FINANCING_PRODUCTS_REQUEST:

      return Object.assign({}, state, {
        loadingFinancingProducts: true
      });
    case ActionTypes.FINANCING_PRODUCTS_ERROR:

      return Object.assign({}, state, {
        loadingFinancingProducts: false
      });
    case ActionTypes.FINANCING_PRODUCTS_RESET:

      return Object.assign({}, state, {
        loadingFinancingProducts: false
      });

    case ActionTypes.UPDATE_FINANCING_PRODUCTS:

      return Object.assign({}, state, {
        financingProducts: action.financingProducts
      });

    case ActionTypes.INVESTMENT_PRODUCTS:

      return Object.assign({}, state, {
        loadingInvestmentProducts: false,
        investmentProducts: action.isMore ? action.investmentProducts.concat(action.investmentProducts) : action.investmentProducts,
        fetchInvestmentProductsNextToken: action.nextToken ?? ''
      });
    case ActionTypes.UPDATE_INVESTMENT_PRODUCTS:

      return Object.assign({}, state, {
        investmentProducts: action.investmentProducts
      });

    case ActionTypes.INVESTMENT_PRODUCTS_REQUEST:

      return Object.assign({}, state, {
        loadingInvestmentProducts: true
      });
    case ActionTypes.INVESTMENT_PRODUCTS_ERROR:

      return Object.assign({}, state, {
        loadingInvestmentProducts: false
      });
    case ActionTypes.INVESTMENT_PRODUCTS_RESET:

      return Object.assign({}, state, {
        loadingInvestmentProducts: false
      });

    case ActionTypes.FINANCING_CONTRACTS:

      return Object.assign({}, state, {
        loadingFinancingContracts: false,
        financingContracts: action.isMore ? action.financingContracts.concat(action.financingContracts) : action.financingContracts,
        fetchFinancingContractsNextToken: action.nextToken ?? ''
      });
    case ActionTypes.FINANCING_CONTRACTS_REQUEST:

      return Object.assign({}, state, {
        loadingFinancingContracts: true
      });
    case ActionTypes.FINANCING_CONTRACTS_ERROR:

      return Object.assign({}, state, {
        loadingFinancingContracts: false
      });
    case ActionTypes.FINANCING_CONTRACTS_RESET:

      return Object.assign({}, state, {
        loadingFinancingContracts: false
      });

    case ActionTypes.INVESTMENT_CONTRACTS:

      return Object.assign({}, state, {
        loadingInvestmentContracts: false,
        investmentContracts: action.isMore ? action.investmentContracts.concat(action.investmentContracts) : action.investmentContracts,
        fetchInvestmentContractsNextToken: action.nextToken ?? ''
      });
    case ActionTypes.INVESTMENT_CONTRACTS_REQUEST:

      return Object.assign({}, state, {
        loadingInvestmentContracts: true
      });
    case ActionTypes.INVESTMENT_CONTRACTS_ERROR:

      return Object.assign({}, state, {
        loadingInvestmentContracts: false
      });
    case ActionTypes.INVESTMENT_CONTRACTS_RESET:

      return Object.assign({}, state, {
        loadingInvestmentContracts: false
      });
    case ActionTypes.FINANCING_ACCOUNT:
      return Object.assign({}, state, {
        financingPositions: action.financingPositions ?? {},
        investmentPositions: action.investmentPositions ?? {},
        totalRevenues: action.totalRevenues ?? {},
        yesterdayRevenues: action.yesterdayRevenues ?? {}
      });
    default:
      return state;
  }
};
const _whiteList = [
  'isFirstShow',
  'investmentProducts',
  'financingProducts'
];

const earningsPersistConfig: PersistConfig = {
  key: 'earnings',
  debug: API_ENV === 'prod' || API_ENV === 'pro',
  timeout: 10000,
  // storage: Platform.OS === 'ios' ? AsyncStorage : EncryptedStorage,
  storage: AsyncStorage,
  whitelist: _whiteList
};

export type PersistEarningsReducerType = Reducer<EarningsStateType, PersistEarningsActionType>;
const persistAuthReducer: (EarningsStateType, PersistEarningsActionType) => EarningsStateType = persistReducer<EarningsStateType, PersistEarningsActionType>(earningsPersistConfig, earningsreducer);

export default persistAuthReducer;
