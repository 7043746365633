import { message, Drawer, Select } from 'antd';
import {
  DeviceEventEmitter
} from 'react-native';
import Badge from './teaset/components/Badge/Badge';
import Label from './teaset/components/Label/Label';

require('antd/lib/drawer/style/index.css')
require('antd/lib/message/style/index.css')
require('antd/lib/select/style/index.css')
require('../css/antd_message.less')
const _message = {
  text: '',
  visible: false
}
module.exports = {
  Toast: {
    show: function (opts) {
      // message.config({
      //   top: mapPosition(position)
      // });
      if (opts.text === _message.text && _message.visible) return
      _message.visible = true
      _message.text = opts.text
      let text, duration, type, callback
      if (typeof opts === 'string') {
        text = opts
      } else {
        text = opts.text
        duration = opts.duration
        type = opts.type
        callback = opts.callback
      }
      if (type === 'error') {
        return message.error({
          content: text,
          duration: duration || 3,
          onClose: () => {
            _message.visible = false
            callback && callback()
          }
        });
      }
      message.success({
        content: text,
        duration: duration || 3,
        onClose: () => {
          _message.visible = false
          callback && callback()
        }
      });
    }
  },
  ModalIndicator: {
    show: function (text) {
      // body...
      DeviceEventEmitter.emit('ModalIndicator', { show: true, text: text })
    },
    hide: function () {
      DeviceEventEmitter.emit('ModalIndicator', false)
    }
  },
  Drawer: Drawer,
  Badge: Badge,
  Label: Label,
  Select: Select

}
