// @flow
import config from '../utils/config';
import request from '../utils/request';
import helper from '../utils/helper';
import type { CommonResponse, CustomerKycInfo, ExchangeResult } from './base';

export type KycResultResponseType = ExchangeResult<CustomerKycInfo> & CommonResponse;

export interface ChannelsType {
  name: string,
  id: string,
  status: number,
  iconPath: string,
  currenciesOut?: string[],
  currenciesIn?: string[]
}
export interface OrderItemType {
  customerId: string,
  channelId: string,
  channelName: string,
  id: string,
  side: string,
  payoutCurrency: string,
  payoutAmount: number,
  payoutFee: number,
  payinCurrency: string,
  payinAmount: number,
  payinFee: number,
  status: number,
  statusReason: string,
  outerOrderId: string,
  txId?: string,
  created: string,
  modified: string,
  widget: string,
  saasId?: string
}
export interface OrderItemObjType {
  nextToken: string,
  limit: number,
  rows: OrderItemType[]
}

export interface OrderCreateItemType {
  channel_id: string,
  side: string,
  payin_currency: string,
  payin_group?: string,
  payin_amount?: string,
  payout_currency: string,
  payout_group?: string,
  payout_amount?: number
}

export interface ChannelsContactType {
  name: string,
  id: string,
  status: number,
  iconPath: string,
  apiContactUrl: string
}

export type ChannelsResponseType = ExchangeResult<ChannelsType[]> & CommonResponse;
export type CurrencySideResponseType = ExchangeResult<string[]> & CommonResponse;
export type OrderResponseType = ExchangeResult<OrderItemObjType> & CommonResponse;
export type OrderCreateResponseType = ExchangeResult<OrderItemType> & CommonResponse;
export type OrderConfirmResponseType = ExchangeResult<OrderItemType> & CommonResponse;
export type ChannelsContactResponseType = ExchangeResult<ChannelsContactType> & CommonResponse;
export type ChannelsPayResponseType = ExchangeResult<string> & CommonResponse;

class CreditApi {
  channels (): Promise<ChannelsResponseType> {
    // 查询所有可用的信用卡渠道
    const URL = config.api.host_api_v1 + config.api.credit.channels;
    console.log('url', URL)
    return request.get.call({}, URL);
  };

  currencies (): Promise<CurrencySideResponseType> {
    // 查询所有出/入金信息
    const URL = config.api.host_api_v1 + config.api.credit.currencies;
    return request.get.call({}, URL);
  };

  orders (params: { nextToken?: string, limit: number }): Promise<OrderResponseType> {
    // 查询用户的所有历史订单
    const URL = config.api.host_api_v1 + `${config.api.credit.orders}` +
      '?' + helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  orderCreate (params: OrderCreateItemType): Promise<OrderCreateResponseType> {
    // 创建订单
    const URL = config.api.host_api_v1 + `${config.api.credit.orders}` +
      '?' + helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  orderEstimate (params: OrderCreateItemType): Promise<OrderConfirmResponseType> {
    // 根据购买信息查询预估可得
    const URL = config.api.host_api_v1 + `${config.api.credit.ordersEstimate}` +
      '?' + helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  channelsContact (params: { channelId: string }): Promise<ChannelsContactResponseType> {
    // 根据渠道id查询联系地址
    const URL = config.api.host_api_v1 + config.api.credit.channelsContact.replace('{channelId}', params.channelId) +
      '?' + helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  channelsPay (params: { channelId: string, orderId: string }): Promise<ChannelsPayResponseType> {
    // 根据渠道id查询联系地址
    const URL = config.api.host_api_v1 + config.api.credit.channelsPay
      .replace('{channelId}', params.channelId)
      .replace('{orderId}', params.orderId) + '?' + helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };
}

const CreditAPI: CreditApi = new CreditApi();
export { CreditAPI };
