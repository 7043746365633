// @flow

import React, { Component } from 'react';
import type { Element } from 'react';
import { Text, View, StyleSheet, Linking, FlatList, TouchableWithoutFeedback, Image } from 'react-native';
import p from '../../../utils/Transfrom';
import { justifyContent } from '../../../styles/Common';
/* $FlowFixMe */
import { myState } from './const';
type ItemType = {
    name: string,
    data: Array<ItemDataType>
};
type ItemDataType = {
    name: string,
    link: string
};

type PropsType = {};
type StateType = {};

const Item = ({ item, onPress }) => (
    <TouchableWithoutFeedback onPress={onPress}>
        <View style={styles.item}>
            <Text style={styles.itemText}>{item.name}</Text>
        </View>
    </TouchableWithoutFeedback>
);

const IconItem = ({ item, onPress }) => {
  let imageSource;
  const name = item.name;
  switch (name) {
    case 'facebook': imageSource = require('../images/facebook.webp'); break;
    case 'instagram': imageSource = require('../images/ins.webp'); break;
    case 'twitter': imageSource = require('../images/twitter.webp'); break;
    case 'youtube': imageSource = require('../images/youtube.webp'); break;
    default: imageSource = null; break;
  }
  const image = (
        <Image
            source={imageSource}
            style={{ width: 48, height: 48 }}
            resizeMode='contain'
        />
  )
  return (<TouchableWithoutFeedback onPress={onPress}>
        <View style={styles.iconItem}>
            {image}
        </View>
    </TouchableWithoutFeedback>)
}

class Footer extends Component <PropsType, StateType> {
  constructor (props: any) {
    super(props);
    this.state = myState;
  }

  componentDidMount () {
  }

  render (): Element<*> {
    const { aboutUs, support, learn, contactUs } = this.state;
    const renderItem = ({ item, index }: {item: ItemDataType, index: number}) => {
      const url = item.link;
      return (
        <Item
            item={item}
            onPress={(item) => Linking.openURL(url)}
        />
      );
    };
    const renderIconItem = ({ item, index }: {item: ItemDataType, index: number}) => {
      const url = item.link;
      return (
        <IconItem
            item={item}
            onPress={(item) => Linking.openURL(url)}

        />
      );
    };

    return (
        <View style={styles.footerContainer}>
            <View style={styles.group}>
                <View style={styles.title}>
                    <Text style={styles.titleText}>{aboutUs.title}</Text>
                </View>
                <FlatList
                    data={aboutUs.data}
                    renderItem={renderItem}
                    keyExtractor={(item) => item.name}
                />
            </View>

            <View style={styles.group}>
                <View style={styles.title}>
                    <Text style={styles.titleText}>{support.title}</Text>
                </View>
                <FlatList
                    data={support.data}
                    renderItem={renderItem}
                    keyExtractor={(item) => item.name}
                />
            </View>

            <View style={[styles.group, styles.lastGroup]}>
                <View style={styles.title}>
                    <Text style={styles.titleText}>{learn.title}</Text>
                </View>
                <FlatList
                    data={learn.data}
                    renderItem={renderItem}
                    keyExtractor={(item) => item.name}
                />
            </View>

            <View style={[styles.group, styles.contact]}>
                <View style={styles.title}>
                    <Text style={styles.titleText}>{contactUs.title}</Text>
                </View>
                <FlatList
                    data={contactUs.data}
                    renderItem={renderIconItem}
                    keyExtractor={(item) => item.name}
                    horizontal
                />
            </View>
        </View>

    )
  }
}

const styles = StyleSheet.create({
  footerContainer: {
    height: 401,
    backgroundColor: '#fff',
    // paddingLeft: p(120),
    // paddingRight: p(120),
    // paddingTop: p(42),
    paddingLeft: 80,
    paddingRight: 80,
    paddingTop: 42,
    flexDirection: 'row'
  },
  group: {
    // marginRight: p(100),
    marginRight: 75
  },
  lastGroup: {
    // marginRight: p(100),
    marginRight: 20
  },
  contact: {
    marginRight: 0,
    marginLeft: 'auto'
  },
  title: {
    // marginBottom: p(25)
    marginBottom: 25
  },
  titleText: {
    fontSize: 14,
    color: '#1F2126',
    fontFamily: 'Helvetica'
  },
  item: {
    // marginBottom: p(21),
    marginBottom: 21
  },
  itemText: {
    fontSize: 12,
    color: '#696F7F',
    fontFamily: 'Helvetica'
  },
  iconItem: {
    marginRight: 38,
    width: 48,
    height: 48
  }
})

export default Footer;
