// @flow
import config from '../utils/config';
import request from '../utils/request';
import helper from '../utils/helper';
import type { CommonResponse, CustomerKycInfo, ExchangeResult } from './base';

export type KycResultResponseType = ExchangeResult<CustomerKycInfo> & CommonResponse;

export interface KycToken {
    code: string,
  bizId: string,
  token: string
}
export type KycTokenResponseType = ExchangeResult<KycToken> & CommonResponse;

class KycApi {
  kycResult (isMigration: boolean): Promise<KycResultResponseType> {
    // params.saas_id = 'kiki';
    const URL = (isMigration ? config.api.host_api_v1 : config.api.host_newapi) + config.api.kyc.kycResult;
    return request.get.call({}, URL);
  };

  kycToken (params: {refreshToken?: string}): Promise<KycTokenResponseType> {
    // params.saas_id = 'kiki';
    const URL = config.api.host_newapi + config.api.kyc.kycToken +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  kycLiveness (params: { token: string, bizId: string}): Promise<CommonResponse> {
    const URL = config.api.host_newapi + config.api.kyc.kycLiveness +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  kycOcrUpload (params: {file0: string, file1?: string, docType?: number}): Promise<CommonResponse> {
    const URL = config.api.host_newapi + config.api.kyc.kycOcrUpload +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };
}

const KycAPI: KycApi = new KycApi();
export { KycAPI };
