// @flow
/**
 * Created by amaris on 2018/12/10.
 */
'use strict';

import React, { Component } from 'react';
import type { Element } from 'react';
import {
  View,
  Text,
  TouchableOpacity
} from 'react-native';
import FastImage from 'react-native-fast-image';

import p from '../utils/Transfrom';
import I18n from '../utils/i18n';
import type { CombinedReducerType, DispatchProps } from '../reducers';

type EmptyComponentOwnPropType = {
    style: {} // TODO
};
type EmptyComponentStateType = {

};

type EmptyComponentProsType = EmptyComponentOwnPropType & CombinedReducerType & DispatchProps;

export default class EmptyComponent extends Component <EmptyComponentProsType, EmptyComponentStateType> {
  constructor (props: EmptyComponentProsType) {
    super(props);
    this.state = {};
  }

  render (): Element<*> {
    const { desc, source, onPress, textStyle, emptyImageStyle = {} } = this.props;

    return (

        <View
          style={[{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: p(200)
          }, this.props.style]}>
          <FastImage
            source={source || require('../images/nodata.webp')}
            style={[{ width: p(420), height: p(420) }, emptyImageStyle]}
          />
          {
            onPress
              ? (
                <TouchableOpacity activeOpacity={0.8} onPress={onPress}>
                  <Text
                    style={{
                      color: '#696F7F',
                      fontSize: p(28),
                      fontWeight: 'bold',
                      marginTop: p(10),
                      ...textStyle
                    }}>
                    {desc || I18n.t('noData')}
                  </Text>
                </TouchableOpacity>
                )
              : (
                  <Text
                    style={{
                      color: '#696F7F',
                      fontSize: p(28),
                      fontWeight: 'bold',
                      marginTop: p(10),
                      ...textStyle
                    }}>
                    {desc || I18n.t('noData')}
                  </Text>
                )
          }
        </View>

    );
  }
}
