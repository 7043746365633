/**
 * @Author:      vincent
 * @DateTime:    2018-03-22 10:51:55
 * @flow
 */
import type { TokenResult } from '../api/social';
import { getToken, getUserInfo } from '../api/social';
import { ActionTypes } from './ActionTypes';
import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import { ThunkDispatch } from 'redux-thunk';
import type { CustomerMisc, CustomerSimpleInfo, SocialResult } from '../api/base';
import type { StateType } from './common';
import type { Feed } from '../models/social';
import I18n from '../utils/i18n';
import _ from 'lodash'
import bugsnag from '../utils/bugsnag';

import { ExploreDataAPI } from '../api/exploreData';
import type {
  GetDataListResponseType,
  Item,
  LiquidationResponseType,
  LongShortListResponseType,
  VsItem
} from '../api/exploreData';
import { readonlyToken } from '../api/base';
import { TopicApI } from '../api/topicPoll';
import type { FetchTopicAllResponseType } from '../api/topicPoll';

export interface SocialTokenRefreshData {
  socialToken: string,
  socialUserInfo: ?CustomerMisc,
  tokenUpdateTs: number,
  userId: string,
  socialUserNotExist?: boolean
}

export type SocialTokenRefreshActionType = Action<typeof ActionTypes.SOCIAL_TOKEN_REFRESH> & SocialTokenRefreshData;
export type SocialTokenAnonymousRefreshActionType =
  Action<typeof ActionTypes.SOCIAL_TOKEN_ANONYMOUS_REFRESH>
  & SocialTokenRefreshData;

export type SocialNewsFetchActionType = Action<typeof ActionTypes.SOCIA_NEWS_FETCH> & { newsData: Feed[] };
export type RecomendPostFetchActionType = Action<typeof ActionTypes.RECOMMEND_POST_FETCH> & { recomendPostData: Feed[] };
export type LongShortFetchActionType = Action<typeof ActionTypes.EXPLORE_LONGSHORT_FETCH> & { longShortData: [] };

export type SocialHotTopicFetchActionType = Action<typeof ActionTypes.SOCIAL_HOTTOPIC_FETCH> & { hotTopic: Feed[] };
export type SocialHotPollFetchActionType = Action<typeof ActionTypes.SOCIAL_HOTPOLL_FETCH> & { hotPoll: Feed[] };
export type SocialUserInfoCleanActionType = Action<typeof ActionTypes.SOCIAL_USER_INFO_CLEAN>;

// all social Action
export type SocialActionsType = SocialTokenRefreshActionType | SocialTokenAnonymousRefreshActionType |
  SocialHotTopicFetchActionType | SocialHotPollFetchActionType | SocialUserInfoCleanActionType;

export type FetchSocialLoginThunkActionType = ThunkAction<Promise<void>, StateType, void, SocialActionsType>;
export type FetchSocialLoginThunkDispatchType = ThunkDispatch<StateType, void, SocialActionsType>;

/**
 * 1、HomeReducer未登录状态：
 *  1.1 如果本地非匿名token存在，并且未过期，则不作处理
 *  1.2 如果本地非匿名token不存在，或者为匿名，则重新刷新token
 * 2、已登录状态：
 *  2.1 如果非匿名token已经存在，并且未过期，则不做处理
 *  2.2 如果非匿名token不存在，或者已经过期，则刷新
 * @param HomeReducer
 * @returns {function(...[*]=)}
 * @constructor
 */
export function FetchSocialLogin (props: { force: boolean, userInfo?: CustomerSimpleInfo } = { force: false }): FetchSocialLoginThunkActionType {
  return async (dispatch: FetchSocialLoginThunkDispatchType, getState: ()=> StateType) => {
    const { SocialReducer, HomeReducer } = getState();
    const {
      tokenUpdateTs,
      socialAnonymous,
      userId,
      socialUserInfo
    } = SocialReducer;
    // 过6天
    const elapse = tokenUpdateTs - new Date().getTime();
    // let min_3 = 3600 * 24 * 7 * 1000 - 1000 * 60 * 3;
    // 已social登录，并且未超过有效期  并且非强制
    if (!socialAnonymous && Math.trunc(elapse) > 0 && !props.force) {
      console.log('elapse: ' + Math.trunc(elapse));
      USER_ID = userId;
      return;
    }
    console.log('fetch login at...' + new Date().getTime());
    // 如果本地非匿名token存在，并且未过期，则不作处理
    // 有可能登录，有可能没登陆，
    try {
      let tokenResult: TokenResult;
      // 如果front已经支持jwtKey， 则不需要再次获取key, 一般情况下， 没有任何场景需要在单独调用getToken了。 @since 2.6.0
      if (props.userInfo?.jwtKey) {
        tokenResult = {
          token: props.userInfo.jwtKey,
          login: true,
          time: new Date().getTime(),
          userId: props.userInfo?.id ?? '' // will not happen
        }
      } else {
        try {
          tokenResult = await getToken(props.userInfo?.jwtKey || HomeReducer.userInfo?.jwtKey || SocialReducer.socialToken);
        } catch (error) {
          bugsnag.notify(new Error('[social] -- [getToken] -- [error]: ' + error.toString()));
          // get token fail， social 本地不存在， 则mock出一个 未登录状态
          if (!SocialReducer.socialToken) {
            // login is failed, should login again
            tokenResult = {
              token: readonlyToken,
              login: false,
              time: new Date().getTime(),
              userId: '' // will not happen
            }
          } else {
            // 先用本地token 来代替， 等待下次刷新触发
            tokenResult = {
              token: SocialReducer.socialToken,
              login: false,
              time: new Date().getTime(),
              userId: SocialReducer.userId || '' // will not happen
            }
          }
        }
      }
      if (tokenResult && tokenResult.login) {
        getUserInfo(tokenResult.userId, tokenResult.token)
          .then((result: ?CustomerMisc) => {
            const socialUserInfo = result;
            if (socialUserInfo && socialUserInfo.customerId) {
              dispatch({
                type: ActionTypes.SOCIAL_TOKEN_REFRESH,
                socialToken: tokenResult.token,
                socialUserInfo: socialUserInfo,
                tokenUpdateTs: tokenResult.time,
                userId: tokenResult.userId,
                socialUserNotExist: socialUserInfo.customerId === '999'
              });
            } else {
              dispatch({
                type: ActionTypes.SOCIAL_TOKEN_ANONYMOUS_REFRESH,
                socialToken: tokenResult.token,
                socialUserInfo: null,
                tokenUpdateTs: 0,
                userId: tokenResult.userId
              });
            }
          })
          .catch((err: Error) => {
            // console.log('social error=============>' + JSON.stringify(err));
            bugsnag.notify(new Error('[social] -- [getUserInfo] -- [error]: ' + err.toString()));
            // 如果 userInfo存在， 则默认接口问题， 先不更新为匿名用户
            if (props.userInfo?.jwtKey && socialUserInfo) { // 当本地存在缓存信息时， 即使错误，也不去失败，保留本地socialUserInfo
              dispatch({
                type: ActionTypes.SOCIAL_TOKEN_REFRESH,
                socialToken: tokenResult.token,
                socialUserInfo: socialUserInfo,
                tokenUpdateTs: tokenResult.time,
                userId: tokenResult.userId
              });
            } else {
              dispatch({
                type: ActionTypes.ACCOUNT_USER_INFO_ERROR,
                error: I18n.t('login.network.error')
              });
            }
          });
      } else {
        // 匿名状态, maybe not login or tokenResult is null
        dispatch({
          type: ActionTypes.SOCIAL_TOKEN_ANONYMOUS_REFRESH,
          socialToken: tokenResult.token || readonlyToken,
          socialUserInfo: {},
          tokenUpdateTs: 0,
          userId: tokenResult.userId || ''
        });
      }
    } catch (error) {
      bugsnag.notify(new Error('[social] -- [FetchSocialLogin] -- [error]: ' + error.toString()));
      dispatch({
        type: ActionTypes.ACCOUNT_USER_INFO_ERROR,
        error: I18n.t('login.network.error')
      });
    }
  };
};

export type GetHotTopicThunkActionType = ThunkAction<void, void, void, SocialHotTopicFetchActionType>;
export type GetHotTopicThunkDispatchType = ThunkDispatch<void, void, SocialHotTopicFetchActionType>;

export function GetHotTopic (socialToken: string): GetHotTopicThunkActionType {
  return (dispatch: GetHotTopicThunkDispatchType) => {
    TopicApI.topicAll(socialToken)
      .then((body: FetchTopicAllResponseType) => {
        dispatch({
          type: ActionTypes.SOCIAL_HOTTOPIC_FETCH,
          hotTopic: body.result || []
        });
      })
      .catch((error: Error) => {
        console.log(error);
        bugsnag.notify(new Error('[social] -- [GetHotTopic] -- [error]: ' + error.toString()));
      });
  };
};
export type GetHotPollThunkActionType = ThunkAction<void, void, void, SocialHotPollFetchActionType>;
export type GetHotPollThunkDispatchType = ThunkDispatch<void, void, SocialHotPollFetchActionType>;

export type GetNewsThunkActionType = ThunkAction<void, void, void, SocialNewsFetchActionType>;
export type GetNewsThunkDispatchType = ThunkDispatch<void, void, SocialNewsFetchActionType>;

export function SetNewsData (body: SocialResult<Feed[]>): GetNewsThunkActionType {
  return (dispatch: GetNewsThunkDispatchType) => {
    dispatch({
      type: ActionTypes.SOCIA_NEWS_FETCH,
      newsData: body
    });
  };
};

export type GetRecomendPostThunkActionType = ThunkAction<void, void, void, RecomendPostFetchActionType>;
export type GetRecomendPostThunkDispatchType = ThunkDispatch<void, void, RecomendPostFetchActionType>;

export function SetRecomendPostData (body: SocialResult<Feed[]>): GetRecomendPostThunkActionType {
  return (dispatch: GetRecomendPostThunkDispatchType) => {
    dispatch({
      type: ActionTypes.RECOMMEND_POST_FETCH,
      recomendPostData: body
    });
  };
};

export type FetchLongShortThunkActionType = ThunkAction<void, void, void, LongShortFetchActionType>;
export type FetchLongShortThunkDispatchType = ThunkDispatch<void, void, LongShortFetchActionType>;

function getLongShortList (item: Item, index: number, longShortData: (Item | VsItem)[], dispatch: FetchLongShortThunkDispatchType) {
  // item.interval = '1h'
  const { symbol, exchange } = item;
  ExploreDataAPI.getLongShortList({
    symbol,
    exchange,
    interval: '1h'
  })
    .then((response: LongShortListResponseType) => {
      // console.log(response)
      // console.log(index)
      if (response.result) {
        let dataList = _.sortBy(response.result.items[0].dataList, 'ts')
        dataList = _.reverse(dataList)
        longShortData[index].dataList = dataList.splice(0, 10)
        console.log(longShortData[index].dataList)

        dispatch({
          type: ActionTypes.EXPLORE_LONGSHORT_FETCH,
          longShortData: longShortData
        });
      }
    })
    .catch((error: Error) => {
      console.log(error)
      bugsnag.notify(new Error('[social] -- [getLongShortList] -- [error]: ' + error.toString()));
      dispatch({ type: ActionTypes.EXPLORE_LONGSHORT_ERROR });
    })
};

function getLiquidation (item: Item, index: number, longShortData: (Item | VsItem)[], dispatch: FetchLongShortThunkDispatchType) {
  ExploreDataAPI.getLiquidation()
    .then((response: LiquidationResponseType) => {
      console.log(response)
      console.log(index)
      longShortData[index].data = response.result
      dispatch({
        type: ActionTypes.EXPLORE_LONGSHORT_FETCH,
        longShortData: longShortData
      });
    })
    .catch((error: Error) => {
      console.log(error)
      bugsnag.notify(new Error('[social] -- [getLiquidation] -- [error]: ' + error.toString()));
      dispatch({ type: ActionTypes.EXPLORE_LONGSHORT_ERROR });
    })
};

export function FetchLongShortList (): FetchLongShortThunkActionType {
  return (dispatch: FetchLongShortThunkDispatchType) => {
    dispatch({ type: ActionTypes.EXPLORE_LONGSHORT_REQUEST });
    ExploreDataAPI.getDataList()
      .then((response: GetDataListResponseType) => {
        console.log(response)

        dispatch({
          type: ActionTypes.EXPLORE_LONGSHORT_FETCH,
          longShortData: response.result.items
        });

        response.result.items.map((item: Item | VsItem, index: number): {} => {
          if (item.type === 'long_short' && item.exchange === 'Binance') {
            // eslint-disable-next-line flowtype/no-weak-types
            getLongShortList(((item: any): Item), index, response.result.items, dispatch)
          }
          if (item.type === 'liquidation') {
            // eslint-disable-next-line flowtype/no-weak-types
            getLiquidation(((item: any): Item), index, response.result.items, dispatch)
          }
          return {}
        })
      })
      .catch((error: Error) => {
        console.log(error)
        bugsnag.notify(new Error('[social] -- [getDataList] -- [error]: ' + error.toString()));
        dispatch({ type: ActionTypes.EXPLORE_LONGSHORT_ERROR });
      })
  };
};

export type SetCleanSocialDataThunkActionType = ThunkAction<void, void, void, SocialUserInfoCleanActionType>;
export type SetCleanSocialDataThunkDispatchType = ThunkDispatch<void, void, SocialUserInfoCleanActionType>;

export function SetCleanSocialData (): SetCleanSocialDataThunkActionType {
  return (dispatch: SetCleanSocialDataThunkDispatchType) => {
    dispatch({ type: ActionTypes.SOCIAL_USER_INFO_CLEAN });
  };
};

// ////  Last export the SocialThunkActionsType, MUST be LAST
export type SocialThunkActionsType =
  SetCleanSocialDataThunkActionType
  | GetHotPollThunkActionType
  | GetHotTopicThunkActionType
  | GetNewsThunkActionType
  | GetRecomendPostThunkActionType
  |
  FetchSocialLoginThunkActionType;
