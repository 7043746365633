/* eslint-disable react-native/no-inline-styles */
/* @flow */

import React, { Component } from 'react';
import type { Element } from 'react';
import {
  // Keyboard,
  // Platform,
  DeviceEventEmitter,
  // TouchableWithoutFeedback,
  // FlatList,
  View
} from 'react-native';
import { connect } from 'react-redux';
import { Toast } from 'teaset';
import AliyunOSS from 'aliyun-oss-react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import helper from '../../utils/helper';
import { getClient, initOss, StsToken } from '../../api/social';
import _ from 'lodash'
// import UIStyle from '../../components/UIStyle';
import { defaultMapDispatchProp, customMapStateProp } from '../../reducers';

import I18n from '../../utils/i18n';
import config from '../../utils/config';
import { emitRefreshPollTopicEvent } from '../../events/SocialEvents';
import type { SocialResult } from '../../api/base'
import type { FetchTopicCategoryItemType } from '../../api/topicPoll'
import type { Feed } from '../../models/social'
import type { PhotosType, ContentItemType } from '../../components/LongTextPublishedComponent';
// const screenWidth = Math.round(Dimensions.get('window').width);
import bugsnag from '../../utils/bugsnag';

const EmptyData = {
  title: '',
  content: [{
    type: 'text',
    currentCursorPosition: 0,
    content: ''
  }]
}

type PropsType = {
  postType: string
};
type StateType = {
  postType: string
};
type UploadItemType = {
  fileName: string,
  localSourceUrl: string,
  longTextIndex: number,
  type: string,
  imageIndex?: ?number,
  uploadPath: string
};

export class PublishPost extends Component <PropsType, StateType> {
  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  constructor (props: PropsType) {
    super(props);
    this.state = {
      tagItem: null,
      postType: props.postType
    };
  }

  _isMounted: boolean;

  componentDidMount () {
    const that = this;
    this._isMounted = true
    that.listener1 = DeviceEventEmitter.addListener('publishPosting', function (longTextData: {title: string, content: Array<ContentItemType>}) {
      if (that._isMounted && !that.loading) {
        that.setState({
          canceUpload: false
        }, function () {
          that.publishedPost(longTextData)
        })
      }
    });
    that.tagChangeListener = DeviceEventEmitter.addListener('tagChange', function (tagItem: FetchTopicCategoryItemType) {
      if (that._isMounted) {
        that.setState({
          tagItem: tagItem
        })
      }
    });
  }

  static getDerivedStateFromProps (nextProps: PropsType, state: StateType): StateType | void {
    if (nextProps.postType !== state.postType) {
      return { postType: nextProps.postType }
    }
  }

  componentWillUnmount () {
    this._isMounted = false
    if (this.listener1) {
      this.listener1.remove();
    }
    if (this.tagChangeListener) {
      this.tagChangeListener.remove();
    }
  }

  getImageFileName: (width: number, height: number, uri: string)=> string = (width: number, height: number, uri: string): string => {
    return (
      helper.createUUID() +
      '__w:' +
      width +
      '__h:' +
      height +
      '.' +
      helper.getImageFileExtension(uri)
    );
  };

  publishedPost (longTextData: {title: string, content: Array<ContentItemType>}) {
    const that = this;

    // const { userId } = this.props.SocialReducer;
    if (that.loading) return
    that.loading = true
    let uploadArr = []
    try {
      const _longTextData = longTextData

      _longTextData.content.forEach((_item: ContentItemType, _index: number) => {
        if (_item.type === 'image') {
          _item.images?.forEach((_photoItem: PhotosType, _photoIndex: number) => {
            const fileName = that.getImageFileName(
              _photoItem.width,
              _photoItem.height,
              _photoItem.uri
            );
            uploadArr.push({
              fileName: fileName,
              localSourceUrl: _photoItem.uri,
              longTextIndex: _index,
              type: 'image',
              imageIndex: _photoIndex,
              uploadPath: _photoItem.uploadPath ?? ''
            })
          })
        } else if (_item.type === 'video') {
          const videoData = _item.data
          const videoFileName =
            helper.createUUID() + '.' + helper.getImageFileExtension(videoData?.uri ?? '');

          const thumbnailFileName = that.getImageFileName(
            videoData?.width ?? 0,
            videoData?.height ?? 0,
            videoData?.thumbnail ?? ''
          );
          uploadArr = uploadArr.concat(
            [
              { fileName: videoFileName, localSourceUrl: videoData?.uri ?? '', longTextIndex: _index, type: 'video', uploadPath: videoData?.uploadPathVideo ?? '', imageIndex: 0 },
              { fileName: thumbnailFileName, localSourceUrl: videoData?.thumbnail ?? '', longTextIndex: _index, type: 'videoImage', uploadPath: videoData?.uploadPathThumbnail ?? '', imageIndex: 0 }
            ]
          )
        }
      })

      if (uploadArr.length) {
        let uploadIndex = 0

        const doUploadNextFunc = () => {
          if (uploadIndex < uploadArr.length - 1 && !that.state.canceUpload) {
            uploadIndex++
            uploadFunc()
          } else {
            const ImageArr = _.filter(uploadArr, function (_item: UploadItemType): boolean { return _item.type === 'image' })
            const photosUrlList = _.map(ImageArr, 'uploadPath')

            const VideoImage = _.filter(uploadArr, function (_item: UploadItemType): boolean { return _item.type === 'videoImage' })

            const videoImages = VideoImage.length ? [VideoImage[0].uploadPath] : []
            const Video = _.filter(uploadArr, function (_item: UploadItemType): boolean { return _item.type === 'video' })

            const videos = Video.length ? [Video[0].uploadPath] : []

            that.publishedInfo(photosUrlList, videoImages, videos, _longTextData);
          }
        }
        const uploadFunc = () => {
          if (!uploadArr[uploadIndex].uploadPath) {
            that.uploadToOSS(uploadArr[uploadIndex].fileName, uploadArr[uploadIndex].localSourceUrl, function (uploadPath: string) {
              uploadArr[uploadIndex].uploadPath = uploadPath
              const longTextIndex = uploadArr[uploadIndex].longTextIndex
              const type = uploadArr[uploadIndex].type

              if (type === 'image') {
                const imageIndex = uploadArr[uploadIndex].imageIndex
                if (_longTextData.content[longTextIndex].images && _longTextData.content[longTextIndex].images[imageIndex]) {
                  _longTextData.content[longTextIndex].images[imageIndex].uploadPath = uploadPath
                }
              } else if (type === 'videoImage') {
                if (_longTextData.content[longTextIndex].data) {
                  _longTextData.content[longTextIndex].data.uploadPathThumbnail = uploadPath
                }
              } else if (type === 'video') {
                if (_longTextData.content[longTextIndex].data) {
                  _longTextData.content[longTextIndex].data.uploadPathVideo = uploadPath
                }
              }

              doUploadNextFunc()
            })
          } else {
            doUploadNextFunc()
          }

          // let processWidth = p.currentSize / p.totalSize
          // console.log(processWidth)
        };

        if (!that.state.canceUpload) {
          uploadFunc()
        }
      } else {
        that.publishedInfo([], [], [], _longTextData);
      }
    } catch (e) {
      bugsnag.notify(new Error('[PublishPost]--[publishedPost]--error : ' + e.toString()));
    }
  }

  // eslint-disable-next-line flowtype/no-types-missing-file-annotation,flowtype/require-return-type
  uploadToOSS: (fileName: string, localSourceUrl: string, callback: (uploadPath: string) => void) => void = (fileName: string, localSourceUrl: string, callback: (uploadPath: string) => void) => {
    const that = this
    const { userId } = this.props.SocialReducer;
    initOss()
    // eslint-disable-next-line flowtype/no-types-missing-file-annotation
      .then((ossConfig: StsToken) => {
        console.log(ossConfig.bucket, 'avatar/' + fileName, localSourceUrl);

        AliyunOSS.asyncUpload(
          ossConfig.bucket,
          userId + '/longtext/' + fileName,
          localSourceUrl
        )
        // eslint-disable-next-line flowtype/require-parameter-type
          .then(res => {
            console.log(res);
            // let uploadPath = 'https://' + "gwave-beta" + ".oss-cn-hangzhou.aliyuncs.com" + '/' + res.fileName + '?'+ photosArr[uploadIndex].width + '&' + photosArr[uploadIndex].height;
            const uploadPath =
              // 'https://' +
              // ossConfig.bucket +
              // '.' +
              // ossConfig.endpoint +
              // '/' +
              config.api.upload_api +
              userId +
              '/longtext/' +
              fileName;
            console.log(uploadPath);
            callback(uploadPath)
          })
          .catch((e: Error) => {
            bugsnag.notify(new Error('[PublishPost]--[asyncUpload]--error : ' + e.toString()));

            that.setState({
              canceUpload: true
            })
            that.loading = false
            DeviceEventEmitter.emit('publishPostFail')
            Toast.show({
              text: I18n.t('upload_error'),
              position: 'center'
            });
          });
      })
      .catch((e: Error) => {
        that.setState({
          canceUpload: true
        })
        that.loading = false
        bugsnag.notify(new Error('[PublishPost]--[initOss]--error : ' + e.toString()));
        DeviceEventEmitter.emit('publishPostFail')
        Toast.show({
          text: I18n.t('upload_error'),
          position: 'center'
        });
      });
  };

  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  publishedInfo: (photosUrlList: Array<*>, videoImages: Array<*>, videos: Array<*>, longTextData: {title: string, content: Array<*>}) => void = (photosUrlList: Array<*>, videoImages: Array<*>, videos: Array<*>, longTextData: {title: string, content: Array<*>}): void => {
    const that = this;
    // const { content } = this.state;
    const contentArr = []
    let content = ''

    try {
      longTextData.content.forEach((_item: ContentItemType, _index: number) => {
        if (_item.type === 'image') {
          _item.images?.forEach((_photoItem: PhotosType, _index: number) => {
            contentArr.push('<img src="' + _photoItem.uploadPath + '"  width="320">')
          })
        } else if (_item.type === 'video') {
          contentArr.push('<video width="320" height="320" controls poster="' + (_item.data?.uploadPathThumbnail ?? '') + '"><source src="' + (_item.data?.uploadPathVideo ?? '') + '" type="video/mp4"></video>')
        } else if (_item.type === 'text') {
          if (_item.content?.trim()) {
            contentArr.push('<p>' + (_item.content ?? '').replace('\n', '<br/>') + '</p>')
          }
        }
      })
      // contentArr = contentArr.join('')
      content = contentArr.join('')
    } catch (e) {
      bugsnag.notify(new Error('[PublishPost]--[publishedInfo]--error : ' + e.toString()));
    }

    // const { userId } = this.props.SocialReducer;

    const { tagItem, postType } = this.state;
    const { userId } = this.props.SocialReducer;
    const feedParamType = global.USER_ID || userId
    if (!feedParamType) {
      return helper.navigate(this.props.navigation.navigate, 'Login');
    }
    // return
    const feedParamUser = 'square';
    // eslint-disable-next-line no-undef
    // const feedParamType = global.USER_ID || userId;
    // subVerb: 12, // 用来区分长文和普通帖子，如果是长文，则需要添加该字段
    const postTypeObj = postType === 'long' ? { subVerb: 12 } : {};
    const newActivity = {
      actor: 'user',
      verb: 'POST',
      // eslint-disable-next-line no-undef
      object: feedParamType,
      text: content,
      videoImages: videoImages,
      videos: videos,
      images: photosUrlList || [],
      ...postTypeObj,
      title: longTextData.title ?? '',
      topicId: tagItem?.id
    };
    console.log('newActivity : ' + JSON.stringify(newActivity));
    console.log('newActivity : tagItem ' + JSON.stringify(tagItem));
    getClient()
      .feed(feedParamUser, feedParamType)
      .addActivity(newActivity)
    // eslint-disable-next-line flowtype/require-parameter-type

      .then((body: SocialResult<Feed>) => {
        console.log(body);
        that.loading = false
        if (body && body.result) {
          Toast.show({
            text: I18n.t('publishPostSuccess'),
            position: 'center'
          });
          emitRefreshPollTopicEvent({ type: 'post' });
          DeviceEventEmitter.emit('publishPostDone', true)
          try {
            const storageKey = postType === 'long' ? 'longTextData' : 'normalTextData';
            AsyncStorage.setItem(storageKey, JSON.stringify(EmptyData))
          } catch (e) {
            bugsnag.notify(new Error('[PublishPost]--[storageTextData]--error : ' + e.toString()));
          }
        }
      })
      .catch((e: Error & { error: { result: {code: string }}}) => {
        console.log(e)
        console.log('帖子发布失败', e)
        bugsnag.notify(new Error('[PublishPost]--[publishedPost]--error : ' + e.toString() + ',feedParamUser:' + feedParamUser + ',feedParamType:' + feedParamType + ', newActivity : ' + JSON.stringify(newActivity)));

        DeviceEventEmitter.emit('publishPostFail', true);
        that.loading = false
        Toast.show({
          text: e.error.result.code === 'topic.not.found' ? I18n.t('topic.not.found') : I18n.t('publishPostFailTip'),
          position: 'center'
        });
      });
  };

  render (): Element<*> {
    return (
      <View/>
    );
  }
}

type PageOwnPropType = {};
const connector = connect(customMapStateProp<PageOwnPropType>(
  {
    SocialReducer: 1,
    HomeReducer: 1,
    AccountReducer: 1
  }
), defaultMapDispatchProp<PageOwnPropType>());
export default (connector(PublishPost): Class<PublishPost>);
