/*
 *
 *     整个项目的状态管理
 */
// @flow
import { createStore, applyMiddleware, type Store, type Dispatch, type StoreEnhancer } from 'redux';
import persistStore from 'redux-persist/es/persistStore';
import { type Persistor } from 'redux-persist/es/types'
import thunk, { type ThunkMiddleware } from 'redux-thunk';
import combinedPersistReducer from '../reducers';
import type { PersistTraderActionType } from '../reducers/TraderReducer';
import type { PersistSocialActionType } from '../reducers/SocialReducer';
import type { PersistAccountActionsType } from '../reducers/AccountReducer';
import type { PersistHomeActionType } from '../reducers/HomeReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

import initSubscriber from 'redux-subscriber';
import type { CombinedStateType, RootThunkActionType } from '../reducers';

export type RootActionType = PersistTraderActionType | PersistSocialActionType | PersistAccountActionsType | PersistHomeActionType;
const middlewares = [(thunk: ThunkMiddleware<CombinedStateType, RootThunkActionType, void>)];
// $FlowIssue
export type RootDispatchType = Dispatch<RootThunkActionType>;
export type RootStateType = CombinedStateType;

const storeEnhancer: StoreEnhancer<CombinedStateType, RootThunkActionType, RootDispatchType> = applyMiddleware(...middlewares);
export type StoreType = Store<CombinedStateType, RootThunkActionType, RootDispatchType>;
const store: StoreType = createStore<CombinedStateType, RootThunkActionType, RootDispatchType>(combinedPersistReducer, composeWithDevTools(storeEnhancer));
const persistor: Persistor = persistStore(store);
initSubscriber(store);
export { store, persistor }
