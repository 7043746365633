// @flow

import p from '../../../utils/Transfrom';

import createStyles, { layoutCenter, sectionTitleStyle, imageRounded60, width, height, justifyContent, lineHeight, paddingHorizontal, marginHorizontal, alignItems, colors, zIndex, radius, margin, padding, fontSize, fontWeight, textAlign } from '../../../styles/Common'
import { Button } from '../../../styles/Button'

// eslint-disable-next-line flowtype/no-weak-types
const styles: any = createStyles({
  flatListContentStyle: {
    paddingHorizontal: paddingHorizontal.ph30
  },
  avatarStyle: imageRounded60,
  systemNoticeStyle: {
    position: 'relative',
    zIndex: zIndex.z2,
    // width: p(100),
    flexDirection: 'row',
    justifyContent: justifyContent.flexEnd

  },
  systemNoticeImageStyle: {
    width: width.w60,
    height: height.h60
  },
  unreadView: {
    position: 'absolute',
    zIndex: zIndex.z999,
    top: p(-10),
    left: p(22),
    justifyContent: justifyContent.center,
    alignItems: alignItems.center,
    backgroundColor: colors.red1,
    borderRadius: radius.r12,
    paddingLeft: padding.p6,
    paddingRight: padding.p6
  },
  unreadText: {
    color: colors.pureWhite,
    fontWeight: fontWeight.f700,
    fontSize: fontSize.f20
  },
  screenTitleStyle: {
    fontSize: fontSize.f47,
    lineHeight: lineHeight.l60,
    color: colors.black,
    fontWeight: fontWeight.f700,
    paddingHorizontal: paddingHorizontal.ph30
  },
  searchViewStyle: {
    height: height.p68,
    flexDirection: 'row',
    marginTop: margin.m24,
    justifyContent: justifyContent.spaceBetween,
    alignItems: alignItems.center,
    marginHorizontal: marginHorizontal.mh30,
    backgroundColor: colors.pureWhite,
    paddingBottom: padding.p20

  },
  searchStyle: {
    flexDirection: 'row',
    justifyContent: justifyContent.spaceBetween,
    alignItems: alignItems.center,
    backgroundColor: colors.white2,
    paddingHorizontal: paddingHorizontal.ph20,
    height: height.p68,
    borderRadius: radius.r24
  },
  searchIconStyle: {
    width: width.w36,
    height: height.h36
  },
  searchTextViewStyle: {
    flex: 1,
    height: height.h68,
    justifyContent: justifyContent.center,
    marginLeft: margin.m12
  },
  searchTextStyle: {
    color: colors.gray2,
    fontSize: fontSize.f24,
    lineHeight: lineHeight.l28
  },
  tabStyle: {
    flexDirection: 'row',
    alignItems: alignItems.center,
    marginTop: margin.m28,
    marginBottom: margin.m20,
    paddingHorizontal: paddingHorizontal.ph30
  },
  tabButton: {
    ...Button.tabButton,
    ...{
      marginRight: margin.m24,
      flexDirection: 'row'
    }
  },
  tabButtonActive: {
    ...Button.tabButtonActive,
    ...{
      marginRight: margin.m24,
      flexDirection: 'row'
    }

  },
  tabButtonText: {
    ...Button.tabButtonText
  },
  tabButtonTextActive: {
    ...Button.tabButtonTextActive
  },
  tagButton: {
    ...Button.tabButton,
    ...{
      marginRight: margin.m24,
      height: height.h80,
      borderColor: colors.white1,
      borderRadius: radius.r90
    }
  },
  tagButtonText: {
    ...Button.tabButtonText,
    ...{
      color: colors.black1

    }
  },
  tagStyle: {
    marginTop: margin.m32
  },
  tagRow: {
    flexDirection: 'row'
  },
  sectionTitleStyle: {
    ...sectionTitleStyle
  },

  feedTopStyle: {
    marginTop: 0,
    marginHorizontal: 0,
    bottom: 0
  },
  myFeedTopStyle: {
    paddingBottom: 0
  },
  longBtn: {
    width: width.w500,
    height: height.h80,
    borderRadius: radius.r40,
    backgroundColor: colors.lightPurple,
    alignItems: alignItems.center,
    justifyContent: justifyContent.center
  },
  longBtnText: {
    fontSize: fontSize.f26,
    lineHeight: lineHeight.l40,
    color: colors.purple
  },
  viewMoreFeed: {
    ...layoutCenter,
    ...{
      marginTop: margin.m48,
      marginBottom: margin.m10
    }
  },

  registerCardStyle: {
    width: width.w690,
    height: height.h288,
    marginHorizontal: marginHorizontal.mh30,
    paddingTop: padding.p24,
    paddingBottom: padding.p37,
    paddingHorizontal: padding.p50,
    backgroundColor: colors.white2,
    borderRadius: radius.r36,
    alignItems: alignItems.center
  },
  statusViewStyle: {
    flexDirection: 'row',
    width: width.w590
  },
  statusIconStyle: {
    width: width.w64,
    height: height.h64
  },
  loginIconViewStyle: {
    alignItems: alignItems.center,
    marginLeft: -margin.m4
  },
  kycIconViewStyle: {
    alignItems: alignItems.center,
    marginLeft: -margin.m1,
    marginRight: -margin.m18
  },
  depositIconViewStyle: {
    alignItems: alignItems.center
  },
  kycViewStyle: {
    flexDirection: 'row',
    alignItems: alignItems.center
  },
  statusLineStyle: {
    height: height.h6,
    width: width.w199,
    backgroundColor: colors.green
  },
  statusLineStyle1: {
    height: height.h6,
    width: width.w199,
    backgroundColor: colors.white8
  },
  statusButtonStyle: {
    marginTop: margin.m38,
    width: width.w561,
    height: height.h80,
    backgroundColor: colors.lightPurple,
    borderRadius: radius.r24,
    flexDirection: 'row',
    justifyContent: justifyContent.center,
    alignItems: alignItems.center

  },
  statusButtonTextStyle: {
    textAlign: textAlign.center,
    color: colors.purple,
    fontSize: fontSize.f28,
    lineHeight: lineHeight.l36,
    fontWeight: fontWeight.fbold
  },
  statusTextStyle: {
    textAlign: textAlign.center,
    color: colors.black3,
    fontSize: fontSize.f24,
    lineHeight: lineHeight.l31,
    fontWeight: fontWeight.fbold,
    marginTop: margin.m14
  },
  statusTextStyle1: {
    textAlign: textAlign.center,
    color: colors.gray1,
    fontSize: fontSize.f24,
    lineHeight: lineHeight.l31,
    marginTop: margin.m14
  },

  depositSectionStyle: {
    marginHorizontal: margin.m30,
    marginTop: margin.m8
  },
  depositViewStyle: {
    flexDirection: 'row',
    backgroundColor: colors.white2,
    height: height.h180,
    marginTop: margin.m24,
    borderRadius: radius.r36,
    paddingLeft: padding.p40,
    paddingRight: padding.p30,
    alignItems: alignItems.center
  },
  depositIconStyle: {
    width: width.w92,
    height: height.h92,
    marginRight: margin.m40
  },
  depositTitleStyle: {
    color: colors.black3,
    fontSize: fontSize.f28,
    lineHeight: lineHeight.l36,
    fontWeight: fontWeight.fbold
  },
  depositSubtitleStyle: {
    color: colors.gray3,
    fontSize: fontSize.f24,
    lineHeight: lineHeight.l31,
    marginTop: margin.m10
  },
  depositTextViewStyle: {
    flex: 1
  },
  depositToNext: {
    width: width.w28,
    height: height.h28
  },
  giftCardViewStyle: {
    marginHorizontal: margin.m30,
    marginTop: margin.m32,
    height: height.h759,
    borderRadius: radius.r36
  },
  giftCardImageViewStyle: {
    height: height.h529,
    borderTopLeftRadius: radius.r36,
    borderTopRightRadius: radius.r36
  },
  giftCardImageStyle: {
    height: height.h529,
    width: width.w690
  },
  giftCardTextViewStyle: {
    flex: 1,
    alignItems: alignItems.center,
    backgroundColor: colors.white2,
    borderBottomLeftRadius: radius.r36,
    borderBottomRightRadius: radius.r36
  },
  giftCardTextStyle: {
    marginHorizontal: margin.m22,
    marginTop: margin.m30,
    marginBottom: margin.m22,
    fontSize: fontSize.f28,
    lineHeight: lineHeight.l40,
    color: colors.black,
    fontWeight: fontWeight.fbold,
    textAlign: textAlign.center
  },
  purchaseBtnStyle: {
    width: width.w360,
    height: height.h68,
    backgroundColor: colors.purple,
    justifyContent: justifyContent.center,
    alignItems: alignItems.center,
    borderRadius: radius.r18
  },
  purchaseBtnTextStyle: {
    color: colors.pureWhite,
    fontWeight: fontWeight.fbold,
    fontSize: fontSize.f24,
    lineHeight: lineHeight.l31
  },
  teachingViewStyle: {
    flexDirection: 'row',
    marginTop: margin.m32,
    marginBottom: margin.m48
  },
  teachingCardViewStyle: {
    width: width.w600,
    height: height.h208,
    borderRadius: radius.r36,
    backgroundColor: colors.white2,
    marginRight: margin.m20,
    padding: padding.p30
  },
  teachingTitleStyle: {
    flexDirection: 'row'
  },
  teachingCardIconStyle: {
    width: width.w56,
    height: height.h56
  },
  teachingTitleTextStyle: {
    marginLeft: margin.m12,
    fontSize: fontSize.f28,
    lineHeight: lineHeight.l56,
    color: colors.black,
    fontWeight: fontWeight.fbold,
    textAlign: textAlign.center
  },
  teachingContentViewStyle: {
    marginTop: margin.m17
  },
  teachingContentTextStyle: {
    fontSize: fontSize.f24,
    lineHeight: lineHeight.l42,
    color: colors.black1,
    fontWeight: fontWeight.fbold
  },
  depositDotStyle: {
    height: height.h12,
    width: width.w12,
    marginLeft: margin.m6
  },
  arrowIconStyle: {
    marginLeft: margin.m12,
    width: width.w28,
    height: height.h17
  }

})
export default styles
