// @flow
export interface CommonResponse {
  success: boolean,
  msg: string,
  msgKey: string,
  msgParam: string[],
  code: string
}

export interface SocialErrorResult {
  ok: ?boolean,
  code: ?string,
  statusCode: ?number,
  errors: ?string
}

export interface SocialResult<T> {
  result: SocialErrorResult & T, // statusCode == 200时， 使用 T 类型， 错误发生时， 使用ErrorResult对象
  nextId: ?string,
  refreshCount: ?number
}

export interface ExchangeResult<T> {
  obj: ?T
}

export type ExchangeResultStringType = ExchangeResult<string> & CommonResponse;
export type ExchangeResultBooleanType = ExchangeResult<string> & CommonResponse;

export interface PageResult<T> {
  draw: number,
  page: number,
  pageSize: number,
  totalPage: number,
  recordsTotal: number,
  recordsFiltered: number,
  hasNextPage: boolean,
  rows: T[]
}

// customer misc
export interface CustomerMisc {
  unreadReply?: number,
  kolApplyStatus?: string,
  gender?: number,
  recommandUrl?: string,
  kolApplyAttachments?: string,
  kol?: boolean,
  kolApplyEmail?: string,
  type?: number,
  followingCount?: number,
  recommand?: boolean,
  platform?: string,
  countryCode?: string,
  intro?: string,
  customerId?: string,
  unreadLike?: number,
  postCount?: number,
  followerCount?: number,
  level?: number,
  nickName?: string,
  verified?: boolean,
  avatar?: string,
  unreadPost?: number,
  kolApplyDesc?: string,
  drawRate?: string,
  expireTime?: string,
  subscribeCount?: number,
  unreadComment?: number,
  kolApplyRemark?: string,
  kolApplyOpTime?: number,
  favoriteCount?: number,
  isFollower?: boolean,
  isFollow?: boolean
}

export interface CustomerMiscShort {
  unreadReply?: number,
  kolApplyStatus?: string,
  gender?: number,
  recommandUrl?: string,
  kolApplyAttachments?: string,
  kol?: boolean,
  kolApplyEmail?: string,
  type?: number,
  followingCount?: number,
  recommand?: boolean,
  platform?: string,
  countryCode?: string,
  intro?: string,
  customerId?: string,
  unreadLike?: number,
  postCount?: number,
  followerCount?: number,
  level?: number,
  nickName?: string,
  verified?: boolean,
  avatar?: string,
  unreadPost?: number,
  kolApplyDesc?: string,
  drawRate?: string,
  expireTime?: string,
  subscribeCount?: number,
  unreadComment?: number,
  kolApplyRemark?: string,
  kolApplyOpTime?: number,
  favoriteCount?: number
}

export interface CustomerSimpleInfo {
  id: string,
  email: string,
  userName: string,
  status: number,
  idCertifiedStatus: number,
  isEmailCertified: boolean,
  isBindEmail: boolean,
  isPhoneCertified: boolean,
  isBindPhone: boolean,
  isGoogleCertified: boolean,
  isBindGoogle: boolean,
  updateTime: string,
  locale: string,
  phone: string,
  isMigration: number,
  jwtKey?: string // since 2.6.0
}

export interface CustomerKycInfo extends CustomerSimpleInfo{
  firstName: string,
  lastName: string,
  idCardType: number,
  idCardNo: string,
  birthday: string,
  country: string,
  locale: string,
  idCertifiedMsg: string,
  idCertifiedType: number,
  idCertifiedStatus: number
}

export interface CustomerInfo extends CustomerSimpleInfo{
  wsKey: string
}

export const readonlyToken: string =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiYW5vbnltb3VzIn0.bdFfo5zO73GK4DMqJE8Kxro3KHyEQqZ5aw7puIiqS0U';

// ranking item
export interface RankingItem {
  nickName: ?string,
  avatar: ?string,
  intro: ?string,
  customer_id: string,
  subscribeCount: ?number,
  subscribed: boolean,
  kol: ?boolean,
  level: ?number,
  expireTime: ?string,
  type: string,
  batchId: number,
  profitAmount: number,
  profitRate: number,
  recommandUrl?: string,
  drawdown: number,
  drawdownRate: number,
  successRate: number,
  riskLevel: number,
  duration: number,
  frequency: number,
  /**
   * {
        "2020-02-10": {
          "date": "2020-02-10",
          "yield": "100",
          "yieldRate": "0.1"
        },
        "2020-02-11": {
          "date": "2020-02-10",
          "yield": "50",
          "yieldRate": "0.05"
        }
       }
   */
  profitRateMap?: string,
  hotMoney: number, // 净值
  firstTradeDate: number,
  timestamp: number,
  currencies: string
}
