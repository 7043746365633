// @flow
import React, { Component } from 'react';
import type { Element } from 'react';
import {
  Dimensions,
  StatusBar,
  Text,
  TouchableWithoutFeedback,
  View
} from 'react-native';
import p from '../utils/Transfrom';
import Modal from 'react-native-modal';
import I18n from '../utils/i18n';
import type { CombinedReducerType, DispatchProps } from '../reducers';

declare function toggleModal(): void;
declare function onPressDelete(): void;

type DeleteModalOwnPropType = {
    visible: boolean,
    toggleModal: typeof toggleModal,
    onPressDelete: typeof onPressDelete,
    tip: string,
    title: string
};
type DeleteModalStateType = {

};

type DeleteModalProsType = DeleteModalOwnPropType & CombinedReducerType & DispatchProps;

export default class DeleteModal extends Component <DeleteModalProsType, DeleteModalStateType> {
  constructor (props: DeleteModalProsType) {
    super(props);
    this.state = {};
  }

  componentDidMount: () => void = () => {
  };

  render (): Element<*> {
    const { visible, toggleModal, onPressDelete, tip, title } = this.props;
    return (
      <Modal
        backdropOpacity={0.6}
        transparent
        avoidKeyboard
        coverScreen
        onModalShow={() => {
          StatusBar.setBarStyle('dark-content');
        }}
        onModalWillHide={() => {
          StatusBar.setBarStyle('light-content');
        }}
        deviceHeight={Math.max(
          Dimensions.get('window').height,
          Dimensions.get('screen').height
        )}
        style={[{ margin: p(30) }, this.props.style]}
        isVisible={visible}>
        <View
          style={{
            backgroundColor: 'white',
            borderRadius: p(24)
          }}>
          <View
            style={{
              borderTopLeftRadius: p(24),
              borderTopRightRadius: p(24),
              height: p(88),
              paddingLeft: p(30),
              alignItems: 'center',
              flexDirection: 'row',
              backgroundColor: '#F6F5FA'
            }}>
            <Text
              style={{ color: '#1F2126', fontWeight: 'bold', fontSize: p(36) }}>
              {title}
            </Text>
          </View>
          <Text
            style={{
              marginVertical: p(72),
              marginHorizontal: p(30),
              textAlign: 'center',
              color: '#8E939F',
              fontSize: p(28),
              lineHeight: p(40)
            }}>
            {tip}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              marginHorizontal: p(40),
              marginBottom: p(40),
              justifyContent: 'center'
            }}
            >

            <TouchableWithoutFeedback onPress={toggleModal}>
              <View
                style={{
                  width: p(285),
                  height: p(80),
                  borderRadius: p(40),
                  backgroundColor: '#F5F5F5',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <Text
                  style={{
                    color: '#434B5F',
                    fontSize: p(28),
                    fontWeight: 'bold'
                  }}>
                  {I18n.t('cancel')}
                </Text>
              </View>
            </TouchableWithoutFeedback>

            <TouchableWithoutFeedback onPress={onPressDelete}>
              <View
                style={{
                  width: p(285),
                  height: p(80),
                  borderRadius: p(40),
                  marginLeft: p(40),
                  backgroundColor: '#6F40EE',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <Text
                  style={{
                    color: '#fff',
                    fontSize: p(28),
                    fontWeight: 'bold'
                  }}>
                  {I18n.t('confirm')}
                </Text>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </View>
      </Modal>
    );
  }
}
