// @flow
import React from 'react';
import type { Element } from 'react';
import {
  View,
  StyleSheet,
  Text
} from 'react-native';
import FastImage from 'react-native-fast-image';
import { TouchableOpacity } from 'react-native-gesture-handler';

import I18n from '../utils/i18n';
import p from '../utils/Transfrom';

import type { SymbolList } from '../models/market';

import helper from '../utils/helper'

import { width, height, paddingHorizontal, radius, colors, margin, padding, imageRounded40 } from '../styles/Common'
const mapFearAndGreedIndex = {
  贪婪: I18n.t('greed'),
  极度贪婪: I18n.t('extremeGreed'),
  中立: I18n.t('neutral'),
  恐惧: I18n.t('fear'),
  极度恐惧: I18n.t('extremeFear')
}

function AnalysisView (props: {
  languageCode: string,
  navigation: {
    navigate: (name: string, opt?: {})=> void
  },
  symbol: SymbolList,
  item: { type: string, title: {}, FearAndGreedIndex: string }
}): Element<*> {
  return (
    <TouchableOpacity activeOpacity={0.8} onPress={() => {
      const { item } = props;
      try {
        helper.trackEvent('NewHome Click ExploreAnalysis ' + item.type);
      } catch (e) {
      }

      props.navigation.navigate('ExploreAnalysis', { data: item, TRACK_PARAM: helper.toTrackParam(item.type, item.title[props.languageCode]) });
    }}>
      <View style={styles.card}>
        <View>
          <View style={styles.coinRow}>
            <FastImage
              source={{ uri: props.symbol.picturePath }}
              style={imageRounded40}
            />
            <Text style={styles.coin}>{props.item && props.item.title && props.item.title[props.languageCode]}</Text>
          </View>
          { <Text style={styles.coin}>{mapFearAndGreedIndex[props.item.FearAndGreedIndex]}</Text> }
        </View>
        <View style={{ alignItems: 'center' }}>
          <FastImage
            source={require('../images/analysis.webp')}
            style={{
              width: p(220),
              height: p(120),
              marginTop: p(32)
            }}
          />
        </View>

      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({

  card: {
    width: width.w380,
    height: height.h340,
    paddingTop: padding.p30,
    backgroundColor: colors.white3,
    borderRadius: radius.r36,
    paddingHorizontal: paddingHorizontal.ph24
  },
  coinRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: p(7)
  },
  coin: {
    fontSize: p(24),
    color: colors.black1,
    lineHeight: p(33),
    marginLeft: margin.m10
  }

})

// eslint-disable-next-line flowtype/no-weak-types
const MemoizedAnalysisView: any = React.memo(AnalysisView);
export default MemoizedAnalysisView
