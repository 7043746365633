// @flow
import React, { Component } from 'react';
import type { Element } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { connect } from 'react-redux';
import type { CombinedReducerType, DispatchProps } from '../../../reducers';
import { defaultMapDispatchProp, customMapStateProp } from '../../../reducers';
import { NavigationContainer } from '@react-navigation/native';
/* $FlowFixMe */
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
// import type { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import p from '../../../utils/Transfrom';
import I18n from '../../../utils/i18n';
import Home from '../../home/newIndex';
import Markets from '../MarketsScreen';
import Savings from '../SavingsScreen';
import Wallet from '../WalletScreen';
import MyTabBar from './Component/MyTabBar';
// import {  } from '../../../styles/Common'
// import HomeStyle from '../../home/styles/home';

const Tab = createMaterialTopTabNavigator();

// 定义 Header 组件的 State 类型
type HeaderStateType = {
  index: number,
  tabArr: Array<string>,
  routes: Array<{
    key: string,
    title: string
  }>
};
// 定义 Header 组件的自己的 Props 类型
type HeaderOwnPropType = {};
// 联合注入的类型？  缺少navigator 的注入声明
type HeaderProsType = HeaderOwnPropType & CombinedReducerType & DispatchProps;

class Header extends Component<HeaderProsType, HeaderStateType> {
  constructor (props: HeaderProsType) {
    super(props);
    this.state = {
      index: 0,
      tabArr: ['home', 'markets', 'savings', 'wallet'],
      routes: [
        { key: 'first', title: 'home' },
        { key: 'second', title: 'markets' },
        { key: 'third', title: 'savings' },
        { key: 'four', title: 'wallet' }
      ]
    };
  }

  componentDidMount () {

  }

  render (): Element<*> {
    return (
        <NavigationContainer>
          <Tab.Navigator
            initialRouteName="Home"
            lazy={true}
            detachInactiveScreens={true}
            scrollEnabled={true}
            tabBar={(props: any): Element<*> => <MyTabBar {...props} />}
          >
              <Tab.Screen
                  name="Home"
                  component={props => <Home {...props} screenProps={this.props.screenProps}/>}
                  options={{
                    tabBarTestID: 'home',
                    tabBarLabel: I18n.t('kiki_index'),
                  }}
              />
              <Tab.Screen
                  name="Markets"
                  component={Markets}
                  options={{
                    tabBarTestID: 'market',
                    tabBarLabel: I18n.t('kiki_market'),
                  }}
              />
              <Tab.Screen
                  name="Savings"
                  component={Savings}
                  options={{
                    tabBarTestID: 'savings',
                    tabBarLabel: I18n.t('savings'),
                  }}
              />
              <Tab.Screen
                  name="Wallet"
                  component={Wallet}
                  options={{
                    tabBarTestID: 'wallet',
                    tabBarLabel: I18n.t('balance'),
                  }}
              />
          </Tab.Navigator>
        </NavigationContainer>
    )
  }
}


const connector = connect(customMapStateProp<HeaderOwnPropType>(
  {
    // HomeReducer: 1,
    // MarketsReducer: 1,
    // SocialReducer: 1,
    // AccountReducer: 1
  }
), defaultMapDispatchProp<HeaderOwnPropType>());
export default (connector(Header): Class<Header>);
