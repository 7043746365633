// @flow
import React, { Component } from 'react';
import type { Element } from 'react';
import FastImage from 'react-native-fast-image';
import {
  Text,
  View,
  TouchableWithoutFeedback
} from 'react-native';
/* $FlowFixMe */
import p from '../../../../utils/Transfrom';
import I18n from '../../../../utils/i18n';

import {
} from '../../../../styles/Common'
import HomeStyle from '../../../home/styles/home';

class Profile extends Component {
  constructor (props: any) {
    super(props);
    this.state = {
    };
  }

  componentDidMount () {
  }

  //   goProfile: () => void = (): void => {
  //     if (!isSocialLogin(this.props.SocialReducer)) {
  //       return helper.navigate(this.props.navigation.navigate, 'Login');
  //     }
  //     const { myMisc } = this.props.TraderReducer;
  //     this.props.navigation.navigate('NewProfile', {
  //       userFullData: myMisc
  //     });
  //   };

  render (): Element<*> {
    return (
        <TouchableWithoutFeedback
            // onPress={this.goProfile}
            testID={'profile'}
        >
          <View>
            {/* {
              this.props.HomeReducer.isLogin
                ? <View style={{ position: 'relative' }}>
                  <FastImage
                    source={avatar}
                    resizeMode={'contain'}
                    style={HomeStyle.avatarStyle}
                  />
                  {identificationSource
                    ? <FastImage style={HomeStyle.identificationImageStyle} source={identificationSource} />
                    : null}
              </View>
                :  */}
                <FastImage
                  source={require('../../../../images/logo4.webp')}
                  resizeMode={'contain'}
                  style={HomeStyle.avatarStyle}
                />
            {/* } */}
          </View>
        </TouchableWithoutFeedback>
    )
  }
}
export default Profile;
