// @flow
import React, { Component } from 'react';
import type { Element } from 'react';
import {
  FlatList,
  Platform,
  StyleSheet,
  DeviceEventEmitter,
  Text,
  View,
  Animated,
  Easing
} from 'react-native';
import { TouchableOpacity, TouchableWithoutFeedback } from 'react-native-gesture-handler';
import FastImage from 'react-native-fast-image';
import LottieView from 'lottie-react-native';
import { Toast } from 'teaset';
import Hyperlink from 'react-native-hyperlink';
import _ from 'lodash'
import p from '../utils/Transfrom';
import I18n from '../utils/i18n';
import VideoComponent from './VideoComponent';
import TextMore from './TextMore';
import PlaceHolderFastImage from './PlaceHolderFastImage';
import helper, { isSocialLogin } from '../utils/helper';
import LinkToPreview from './LinkToPreview';
import type { CombinedReducerType, DispatchProps } from '../reducers';
import type { ItemType } from '../layouts/social/Comments';
import type { ContentItemType } from './LongTextPublishedComponent'
import bugsnag from '../utils/bugsnag'
// import type { PostItemType } from '../layouts/social/Posts';
// import type { PostType } from '../utils/helper';
import { imageRounded80, dimensions, paddingVertical, marginHorizontal, imageRounded40, width, height, justifyContent, lineHeight, paddingHorizontal, alignItems, colors, radius, margin, fontSize, fontWeight } from '../styles/Common'

import { Button } from '../styles/Button'
const striptags = require('striptags');
const html2json = require('html2json').html2json;

const screenWidth = dimensions.fullWidth
const screenHeight = dimensions.fullHeight
const imageUriParam = '?x-oss-process=image/auto-orient,1/interlace,1/resize,m_fill,w_690,h_690/quality,q_90/format,jpg';
const imageGifUriParam = '?x-oss-process=image/auto-orient,1/interlace,1/quality,q_10/format,gif';

declare function onImageClick(index: number, item: ItemDataType): void;
export type ItemDataType = {
    link: string,
    id: string,
    text: string,
    customerMisc: {
        nickName: string,
        customerId: string,
        follow: boolean,
        avatar: string
    },
    comments: Array<ItemType>,
    images: Array<string>,
    videoThumbnail: string,
    timestamp: number,
    isVideo: boolean,
    videoSource: string,
    commentCount: number,
    likeCount: number,
    like: boolean,
    shareCount: number
};
type CellListOwnPropType = {
    itemModel: string,
    keyImgIndex: number,
    itemData: ItemDataType,
    postCellContainer: {}, // TODO
    onImageClick: typeof onImageClick
};
type CellListStateType = {

};

type CellListProsType = CellListOwnPropType & CombinedReducerType & DispatchProps;

class CellList extends Component<CellListProsType, CellListStateType> {
  constructor (props: CellListProsType) {
    super(props);
    this.state = {};
  }

  render (): Element<*> {
    const that = this;
    const theModel = this.props.itemModel + imageUriParam;

    return (
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={() => {
          that.props.onImageClick(this.props.keyImgIndex, this.props.itemData);
        }}>
        <PlaceHolderFastImage
          imgStyle={styles.cellImg}
          source={{ uri: theModel }}
          // isLongImg={theModel.longImg}
          isGif={theModel.indexOf('.gif') !== -1}
          style={[styles.cellContainer, this.props.postCellContainer]}
          fallback={Platform.OS === 'android'}
        />
      </TouchableOpacity>
    );
  }
}

type CellListOneOwnPropType = {
    itemModel: string,
    keyImgIndex: number,
    itemData: ItemDataType,
    postCellOne: number, // TODO
    onImageClick: typeof onImageClick,
    squareType: string
};
type CellListOneStateType = {

};

type CellListOneProsType = CellListOneOwnPropType & CombinedReducerType & DispatchProps;

class CellListOne extends Component <CellListOneProsType, CellListOneStateType> {
  constructor (props: CellListOneProsType) {
    super(props);
    this.state = {};
  }

  render (): Element<*> {
    const that = this;
    const longImg = false;
    const cellOneWidth = this.props.postCellOne
      ? screenWidth - p(60 + 60)
      : screenWidth - p(60);
    const cellOneHeight = this.props.postCellOne
      ? screenWidth - p(60 + 60)
      : screenWidth - p(60);

    const theModel = this.props.itemModel + imageUriParam;
    const imageHeight = helper.getImageRelativeWidthHeight(theModel).height * 0.6;
    const imageWidth = helper.getImageRelativeWidthHeight(theModel).width * 0.6;
    const outerHeight = imageHeight || cellOneHeight;
    const outerWidth = cellOneWidth;

    const fetchWidth = helper.getImageWidth(theModel, '');
    const fetchHeight = helper.getImageHeight(theModel, '');
    if (imageHeight / imageWidth > screenHeight / screenWidth) {
      let jpgFormatTheModel = this.props.itemModel + '?x-oss-process=image/auto-orient,1/interlace,1/resize,m_lfit,w_690,h_690/quality,q_90/format,jpg';
      let gifFormatTheModel = this.props.itemModel + '?x-oss-process=image/auto-orient,1/interlace,1/quality,q_10/format,gif'; // 由于oss在m_mfit情况下不支持gif，因此删除
      const isGif = ((this.props.itemModel).indexOf('.gif') !== -1 || (this.props.itemModel).indexOf('.GIF') !== -1)
      let theModel = isGif ? gifFormatTheModel : jpgFormatTheModel;
      if ((fetchWidth / fetchHeight) < 0.4) {
        // 超级长图，只显示图片顶部位置，使用oss参数进行裁剪图片处理
        jpgFormatTheModel = this.props.itemModel + `?x-oss-process=image/auto-orient,1/interlace,1/crop,x_0,y_0,w_${fetchWidth},h_${fetchWidth + 200}/resize,m_mfit,w_690,h_690/quality,q_90/format,jpg`;
        gifFormatTheModel = this.props.itemModel + '?x-oss-process=image/auto-orient,1/interlace,1/quality,q_10/format,gif'; // 由于oss在m_mfit情况下不支持gif，因此删除
        theModel = isGif ? gifFormatTheModel : jpgFormatTheModel;
        return (
          <TouchableOpacity
            activeOpacity={0.8}
            style={{ flexDirection: 'row' }}
            onPress={() => { that.props.onImageClick(this.props.keyImgIndex, this.props.itemData) }}
          >
            {this.props.itemModel
              ? (
              <PlaceHolderFastImage
                source={{ uri: theModel, priority: Platform.OS !== 'web' ? (isGif ? FastImage.priority.low : FastImage.priority.normal) : '' }}
                imgStyle={[styles.cellImgOne, { height: (p(320) * (fetchWidth + 200)) / fetchWidth, width: p(320), resizeMode: 'contain' }]}
                style={{ height: (p(320) * (fetchWidth + 200)) / fetchWidth }}
                isGif={isGif}
                fallback={Platform.OS === 'android'}
              />
                )
              : null}
              <TouchableWithoutFeedback onPress={() => {
                this.props.onTextClick && this.props.onTextClick(this.props.itemData, this.props.type, this.props.keyIndex);
              }}>
                <View style={{ flex: 1, height: p(320) }}/>
              </TouchableWithoutFeedback>
          </TouchableOpacity>
        );
      }
      // 普通长图处理
      return (
        <TouchableOpacity
          activeOpacity={0.8}
          style={{ flexDirection: 'row' }}
          onPress={() => {
            that.props.onImageClick(
              this.props.keyImgIndex,
              this.props.itemData
            );
          }}>
          {this.props.itemModel
            ? (
            <PlaceHolderFastImage
              source={{ uri: theModel, priority: Platform.OS !== 'web' ? (isGif ? FastImage.priority.low : FastImage.priority.normal) : '' }}
              // imgStyle={[styles.cellImgOne, { height: p(460), width: (fetchWidth * p(460)) / fetchHeight, resizeMode: 'contain' }]}
              // style={{ height: p(460) }}
              // 下面方式相当于全页面展示图片
              // imgStyle={[styles.cellImgOne, { height: (p(690) * fetchHeight) / fetchWidth, width: p(690), resizeMode: 'contain' }]}
              // style={{ height: (p(690) * fetchHeight) / fetchWidth }}
              //  下面方式相当于固定宽度，高度自适应
              imgStyle={[styles.cellImgOne, { height: (fetchHeight * p(320)) / fetchWidth, width: p(320), resizeMode: 'contain' }]}
              style={{ height: (fetchHeight * p(320)) / fetchWidth }}
              isGif={isGif}
              fallback={Platform.OS === 'android'}
            />
              )
            : null}
            <TouchableWithoutFeedback onPress={() => { this.props.onTextClick && this.props.onTextClick(this.props.itemData) }}>
              <View style={{ flex: 1, height: p(320) }}/>
            </TouchableWithoutFeedback>
        </TouchableOpacity>
      );
    } else if (imageWidth / imageHeight > 388 / screenHeight) {
      const jpgFormatTheModel = this.props.itemModel + '?x-oss-process=image/auto-orient,1/interlace,1/resize,m_mfit,w_690,h_690/quality,q_90/format,jpg';
      const gifFormatTheModel = this.props.itemModel + '?x-oss-process=image/auto-orient,1/interlace,1/quality,q_10/format,gif'; // 由于oss在m_mfit情况下不支持gif，因此删除
      const isGif = ((this.props.itemModel).indexOf('.gif') !== -1 || (this.props.itemModel).indexOf('.GIF') !== -1)
      const theModel = isGif ? gifFormatTheModel : jpgFormatTheModel;
      // const calWidth =  (fetchWidth * p(400)) / fetchHeight;
      return this.props.itemModel
        ? (
        <TouchableOpacity
          activeOpacity={0.8}
          style={{ flexDirection: 'row' }}
          onPress={() => {
            that.props.onImageClick(
              this.props.keyImgIndex,
              this.props.itemData
            );
          }}>
          <PlaceHolderFastImage
            imgStyle={[styles.cellImgOne, { height: p(320), width: p(690), resizeMode: 'contain' }]}
            style={[styles.cellContainerOne, { height: p(320), width: cellOneWidth }]}
            // imgStyle={[styles.cellImgOne, { height: p(320), width: calWidth > p(690) ? p(690) : calWidth, resizeMode: 'contain' }]}
            // style={[styles.cellContainerOne, { height: p(320), width: cellOneWidth }]}
            source={{ uri: theModel, priority: Platform.OS !== 'web' ? (isGif ? FastImage.priority.low : FastImage.priority.normal) : '' }}
            isGif={isGif}
            fallback={Platform.OS === 'android'}
          />
          <TouchableWithoutFeedback onPress={() => {
            this.props.onTextClick && this.props.onTextClick(this.props.itemData);
          }}>
            <View style={{ flex: 1, height: p(320) }}/>
          </TouchableWithoutFeedback>

        </TouchableOpacity>
          )
        : <View/>;
    }

    return (
      <TouchableOpacity
        activeOpacity={0.8}
        style={{ flexDirection: 'row' }}
        onPress={() => {
          if (this.props.squareType === 'square_news') {
            try {
              const link = this.props.itemData.link;
              helper.trackEvent('Page Click SquareNews', {
                news_id: this.props.itemData.id,
                USER_ID: USER_ID,
                news_text: this.props.itemData.text.substr(0, 30),
                news_link: link.split(':')[1],
                news_author: this.props.itemData?.customerMisc?.nickName
              });
            } catch (e) {
              bugsnag.notify(new Error('[components-CellListOne] -- [trackEvent] -- [error]: ' + e.toString()));
            }

            this.props.navigation.navigate('WebViewPage', {
              url: this.props.itemData.link,
              title: I18n.t('community_news'),
              TRACK_PARAM: this.props.itemData.link
            });
            return;
          }
          that.props.onImageClick(this.props.keyImgIndex, this.props.itemData);
        }}>
        {this.props.itemModel
          ? (
          <PlaceHolderFastImage
            imgStyle={[
              styles.cellImgOne,
              {
                height:
                  this.props.squareType === 'square_news'
                    ? p(338)
                    : imageHeight || '100%',
                width:
                  this.props.squareType === 'square_news'
                    ? cellOneWidth
                    : imageWidth || '100%'
              }
            ]}
            source={{ uri: theModel }}
            isLongImg={longImg}
            isGif={theModel.indexOf('.gif') !== -1}
            style={[
              styles.cellContainerOne,
              {
                height:
                  this.props.squareType === 'square_news' ? p(338) : outerHeight,
                width:
                  this.props.squareType === 'square_news'
                    ? cellOneWidth
                    : outerWidth * 0.6
              }
            ]}
            fallback={Platform.OS === 'android'}
          />
            )
          : (
          <FastImage
            style={[
              styles.cellImgOne,
              {
                height: p(338),
                width: screenWidth - p(120)
              }
            ]}
            source={require('../images/default_news.webp')}
          />
            )}

          <TouchableWithoutFeedback onPress={() => {
            this.props.onTextClick && this.props.onTextClick(this.props.itemData);
          }}>
            <View style={{ flex: 1, height: p(338) }}/>
          </TouchableWithoutFeedback>

      </TouchableOpacity>
    );
  }
}

type CellListTwoOwnPropType = {
    itemModel: string,
    keyImgIndex: number,
    itemData: ItemDataType,
    postCellTwo: {}, // TODO
    onImageClick: typeof onImageClick,
    isFold: boolean,
    images: Array<string>
};
type CellListTwoStateType = {

};

type CellListTwoProsType = CellListTwoOwnPropType & CombinedReducerType & DispatchProps;

class CellListTwo extends Component <CellListTwoProsType, CellListTwoStateType> {
  constructor (props: CellListTwoProsType) {
    super(props);
    this.state = {};
  }

  render (): Element<*> {
    const that = this;
    const { isFold, keyImgIndex, images } = this.props;
    const imgLen = images && images.length;
    const imgLenText = `+${imgLen - 4}`;
    let theModel = this.props.itemModel + imageUriParam;
    const isGif = (theModel.indexOf('.gif') !== -1) || (theModel.indexOf('.GIF') !== -1);
    theModel = this.props.itemModel + (isGif ? imageGifUriParam : imageUriParam);
    let imgLenCom = null;
    if (isFold && keyImgIndex === 3) {
      imgLenCom = (
        <View
          style={{
            width: p(54),
            height: p(32),
            position: 'absolute',
            borderTopLeftRadius: p(12),
            right: 0,
            bottom: 0
          }}>
          <View
            style={{
              backgroundColor: '#000',
              opacity: 0.4,
              flex: 1,
              borderTopLeftRadius: p(12)
            }}
          />
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 999
            }}>
            <Text style={{ fontSize: p(24), color: '#fff' }}>{imgLenText}</Text>
          </View>
        </View>
      );
    }
    if (isFold && keyImgIndex > 3) {
      return <View />;
    }
    return (
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={() => {
          that.props.onImageClick(this.props.keyImgIndex, this.props.itemData);
        }}>
        <PlaceHolderFastImage
          imgStyle={styles.cellImgTwo}
          imgLenCom={imgLenCom}
          source={{ uri: theModel, priority: isGif ? FastImage.priority.low : FastImage.priority.normal }}
          // isLongImg={theModel.longImg}
          isGif={isGif}
          style={[styles.cellContainerTwo, this.props.postCellTwo]}
          fallback={Platform.OS === 'android'}
        />
      </TouchableOpacity>
    );
  }
}

declare function setCurrentVideo(item: ItemDataType): void;
declare function onShare(item: ItemDataType): void;

declare function onLikeSuccess(isLike: boolean): void;
declare function onLikeFail(isLike: boolean): void;
type LikeFunParamsType = {
    toCustomerId: string,
    customerId: string,
    targetId: string,
    targetType: string,
    isLike: boolean,
    onSuccess: typeof onLikeSuccess,
    onFail: typeof onLikeFail
};
declare function likeFun(params: LikeFunParamsType): void;

declare function onSuccess(code: number): void;
declare function onFail(): void;
type FollowParamsType = {
    toCustomerId: string,
    customerId: string,
    isFollow: boolean,
    onSuccess: typeof onSuccess,
    onFail: typeof onFail
};
declare function followFun(params: FollowParamsType): void;

declare function onComment(itemData: ItemDataType, type: string, keyIndex: number): void;
declare function onTextClick(itemData: ItemDataType): void;
declare function onFollow(): void; // TODO
declare function customFooter(itemData: ItemDataType): void;
type PostComponentOwnPropType = {
    itemModel: string,
    keyImgIndex: number,
    itemData: ItemDataType,
    postCellContainer: {}, // TODO
    onImageClick: typeof onImageClick,
    postCellTwo: {}, // TODO
    postCellOne: number, // TODO
    squareType: string,
    activeRouteName: string,
    type: string,
    setCurrentVideo: typeof setCurrentVideo,
    activeVideoId: string,
    onShare: typeof onShare,
    likeFun: typeof likeFun,
    followFun: typeof followFun,
    keyIndex: number,
    onComment: typeof onComment,
    onFollow: typeof onFollow,
    onTextClick: typeof onTextClick,
    postListWraper: {}, // TODO
    customFooter: typeof customFooter
};
type PostComponentStateType = {

};

type PostComponentProsType = PostComponentOwnPropType & CombinedReducerType & DispatchProps;

export default class PostComponent extends Component <PostComponentProsType, PostComponentStateType> {
  constructor (props: PostComponentProsType) {
    super(props);
    const translateValue = new Animated.ValueXY({ x: 0, y: 0 });
    this.state = {
      translateValue: translateValue
    };
    this.lovedId = '';
  }

  componentWillUnmount () {
    if (this.state.translateValue) {
      this.state.translateValue.removeAllListeners();
    }
  }

  _startAnimation: () => void = () => {
    Animated.sequence([
      Animated.timing(this.state.translateValue, {
        isInteraction: false,
        toValue: { x: 0, y: -p(28) },
        duration: this.state.duration || 500, // 动画持续的时间（单位是毫秒），默认为500
        easing: Easing.linear,
        useNativeDriver: true
      })
    ]).start(() => {
      this.state.translateValue.setValue({ x: 0, y: 0 });
      this.lovedId = '';
      this.setState({ lovedIdLikeCount: '' });
    });
  };

  _renderItem: ({ item: {}, index: number}) => Element<*> = ({ item, index }: { item: {}, index: number}): Element<*> => {
    return (
        <CellList
          itemModel={item}
          keyImgIndex={index}
          {...this.props}
          postCellContainer={this.props.postCellContainer}
        />
    );
  };

  _renderItemOne: ({ item: string, index: number}) => Element<*> = ({ item, index }: { item: string, index: number}): Element<*> => {
    if (Platform.OS !== 'web') {
      return (
            <CellListOne
                itemModel={item}
                keyImgIndex={index}
                {...this.props}
                postCellOne={this.props.postCellOne}
                itemData={this.props.itemData}
                squareType={this.props.squareType}
            />
      );
    } else {
      return <FastImage style={{ width: p(500), height: p(500), borderRadius: p(20) }} source={{ uri: item }}/>
    }
  };

  _renderVideo: (item: ItemDataType) => Element<*> = (item: ItemDataType): Element<*> => {
    return (
        <VideoComponent
          {...this.props}
          itemModel={item}
          activeRouteName={this.props.activeRouteName}
          type={this.props.type}
          setCurrentVideo={this.props.setCurrentVideo}
          activeVideoId={this.props.activeVideoId}
        />
    );
  };

    _renderLearnVideo: ({ itemData: ItemDataType}) => Element<*> = ({ itemData }: { itemData: ItemDataType}): Element<*> => {
      return (
        <View>
          <FastImage
            style={{
              width: p(630),
              height: p(360),
              alignItems: 'center',
              justifyContent: 'center'
            }}
            source={{ uri: itemData.videoThumbnail }}>
            <FastImage
              style={{ width: p(80), height: p(80) }}
              source={require('../images/play.webp')}
            />
          </FastImage>
          <Text
            style={{
              marginTop: p(32),
              fontSize: p(32),
              fontWeight: 'bold',
              lineHeight: p(45)
            }}>
            {itemData.text}
          </Text>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text style={{ color: '#949CAD', fontSize: p(24) }}>
              {helper.toLocaleTime(itemData.timestamp, 'MM-DD HH:mm')}
            </Text>
            <Text style={{ color: '#949CAD', fontSize: p(24) }}>
              {I18n.t('read_more')}
            </Text>
          </View>
        </View>
      );
    };

    _renderItemTwo: ({ item: {}, index: number, images: Array<*>, isFold: boolean}) =>
        Element<*> = ({ item, index, images, isFold }: { item: {}, index: number, images: Array<*>, isFold: boolean}): Element<*> => {
          return (

        <CellListTwo
          isFold={isFold}
          images={images}
          itemModel={item}
          {...this.props}
          keyImgIndex={index}
          postCellTwo={this.props.postCellTwo}
        />
          );
        };

  _renderItemThree: ({ itemData: ItemDataType, images: Array<string>, imagesLength: number}) =>
      Element<*> = ({ itemData, images, imagesLength }: { itemData: ItemDataType, images: Array<string>, imagesLength: number}): Element<*> => {
        const that = this;
        // const imgUrlList = images.map((item: string): string => item + imageUriParam);
        return (
          <View style={{ flexDirection: 'row', position: 'relative', justifyContent: 'space-between' }}>
            {
              images.map((imageItem: string, index: number): Element<*> => {
                const isGif = (imageItem.indexOf('.gif') !== -1) || (imageItem.indexOf('.GIF') !== -1)
                return (
                    <TouchableOpacity
                      activeOpacity={0.8}
                      onPress={() => {
                        that.props.onImageClick(index, itemData);
                      }}>
                      <PlaceHolderFastImage
                        imgStyle={{ height: p(224), width: p(224), borderRadius: p(6) }}
                        source={{ uri: imageItem, priority: isGif ? FastImage.priority.low : FastImage.priority.normal }}
                        isGif={isGif}
                        style={[styles.cellContainerThree]}
                        fallback={Platform.OS === 'android'}
                      />
                    </TouchableOpacity>
                )
              })
            }
            {
              imagesLength > 3
                ? <View style={{ position: 'absolute', backgroundColor: 'rgba(0,0,0,0.4)', bottom: 0, right: 0, height: p(32), paddingHorizontal: p(13), borderTopLeftRadius: p(6), borderBottomRightRadius: p(6) }}>
                <Text style={{ color: '#FFF', fontSize: p(24) }}>+{imagesLength - 3}</Text>
              </View>
                : null
            }

          </View>
        );
      };

  share: () => void = (): void => {
    if (!isSocialLogin(this.props.SocialReducer)) {
      return helper.navigate(this.props.navigation.navigate, 'Login');
    }

    const { itemData, onShare } = this.props;
    onShare && onShare(itemData);
  };

  toggleModalPostMore: () => void = (): void => {
    if (!isSocialLogin(this.props.SocialReducer)) {
      return helper.navigate(this.props.navigation.navigate, 'Login');
    }

    const { itemData, toggleModalPostMore, keyIndex } = this.props;
    toggleModalPostMore && toggleModalPostMore(true, itemData, keyIndex);
  };

  onLikeSuccess: (isLike: boolean) => (code: number) => void = (isLike: boolean): (code: number) => void => {
    const that = this;
    return (code: number) => {
      // current status
      if (code === 0) {
        that.sending = false;
        Toast.show({
          text: isLike
            ? I18n.t('community_cancelLike')
            : I18n.t('community_likeSuccess'),
          position: 'center'
        });
      }
    };
  };

  onLikeFail: (isLike: boolean) => () => void = (isLike: boolean): () => void => {
    return () => {
      this.sending = false;
      Toast.show({
        text: isLike
          ? I18n.t('community_cancelLikeFail')
          : I18n.t('community_likeFail'),
        position: 'center'
      });
    };
  };

  like: (isLoved: boolean) => void = (isLoved: boolean): void => {
    if (!isSocialLogin(this.props.SocialReducer)) {
      return helper.navigate(this.props.navigation.navigate, 'Login');
    }

    const that = this;
    const { itemData } = this.props;

    if (that.sending) {
      return Toast.show({
        text: I18n.t('common_submiting'),
        position: 'center'
      });
    }
    this.setState({
      lovedIdLikeCount: itemData.likeCount,
      isLovedStatus: isLoved // 记录从已点赞改变到未点赞还是未点赞改变到已点赞
    });
    this.lovedId = itemData.id;

    that.sending = true;
    const param = {
      toCustomerId: itemData?.customerMisc?.customerId,
      customerId: this.props.HomeReducer?.userInfo?.id,
      targetId: itemData.id,
      targetType: 'post',
      isLike: !isLoved,
      onSuccess: this.onLikeSuccess(isLoved),
      onFail: this.onLikeFail(isLoved),
      needLogin: () => {
        this.sending = false;

        Toast.show({
          text: I18n.t('loginToRemindTip4'),
          position: 'bottom'
        });

        helper.navigate(this.props.navigation.navigate, 'Login');
      }
    };

    this.props.likeFun && this.props.likeFun(param);
    setTimeout(() => {
      this._startAnimation();
    }, 600);
  };

  follow: (data: ItemDataType) => void = (data: ItemDataType): void => {
    const that = this;

    if (!isSocialLogin(this.props.SocialReducer)) {
      return helper.navigate(this.props.navigation.navigate, 'Login');
    }
    if (that.sending) {
      return Toast.show({
        text: I18n.t('common_submiting'),
        position: 'center'
      });
    }
    that.sending = true;

    that.doFollow();
  };

  onFollowSuccess: (code: number) => void = (code: number) => {
    this.sending = false;
    if (code === 0) {
      Toast.show({
        text: this.props.itemData?.customerMisc?.follow
          ? I18n.t('community_cancelFollow')
          : I18n.t('community_followSuccess'),
        position: 'center'
      });
    }
  };

  onFollowFail: () => void = () => {
    this.sending = false;
    Toast.show({
      text: this.props.itemData?.customerMisc?.follow
        ? I18n.t('community_cancelFollowFail')
        : I18n.t('community_followFail'),
      position: 'center'
    });
  };

  doFollow: () => void = () => {
    const { itemData } = this.props;
    this.props.followFun({
      toCustomerId: itemData?.customerMisc?.customerId,
      customerId: this.props.HomeReducer?.userInfo?.id,
      isFollow: !itemData?.customerMisc?.follow,
      onSuccess: this.onFollowSuccess,
      onFail: this.onFollowFail,
      needLogin: () => {
        this.sending = false;

        Toast.show({
          text: I18n.t('loginToRemindTip4'),
          position: 'bottom'
        });

        helper.navigate(this.props.navigation.navigate, 'Login');
      }
    });
  };

  _footerItem: (itemData: ItemDataType, opt?: {no_comment: boolean}) =>
      Element<*> = (itemData: ItemDataType, opt?: {no_comment: boolean}): Element<*> => {
        const { keyIndex, type, squareType } = this.props;

        const curLikeCount = itemData.likeCount;
        return (
          <>
            <View style={{ marginTop: margin.m24, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <View
                style={[
                  styles.shareLove,
                  (opt && opt.no_comment) || squareType === 'square_news'
                    ? { justifyContent: 'flex-end' }
                    : {}
                ]
                }
                >
                <View style={styles.shareItem}>
                  <View
                    style={{
                      flexDirection: 'row',
                      width: p(56),
                      marginRight: p(70)
                    }}>
                    <TouchableWithoutFeedback
                      style={styles.loveItem}
                      onPress={(): void => this.like(itemData.like)}>
                      <View
                        style={{
                          flexDirection: 'row',
                          height: p(25 + 22 * 2),
                          minWidth: p(50),
                          alignItems: 'center'
                        }}>
                        {
                        !itemData.like
                          ? (<FastImage style={[styles.postActionIcon]} source={require('../images/like.webp')} />)
                          : (
                              this.lovedId === itemData.id && this.state.isLovedStatus === false
                                ? (
                                    <LottieView
                                      style={{
                                        width: Platform.OS === 'ios' ? p(46) : p(48),
                                        height: Platform.OS === 'ios' ? p(66) : p(60),
                                        top: Platform.OS === 'ios' ? -p(5) : -p(4),
                                        paddingTop: Platform.OS === 'ios' ? 1.5 : 0,
                                        paddingLeft: Platform.OS === 'ios' ? 0.5 : 0.5,
                                        left: Platform.OS === 'ios' ? -p(4) : -p(4),
                                        margin: 0
                                      }}
                                      autoSize={false}
                                      loop={false}
                                      speed={1}
                                      // eslint-disable-next-line flowtype/no-weak-types
                                      ref={(animation: any) => {
                                        if (animation) {
                                          this.animation = animation;
                                          if (this.lovedId === itemData.id && this.state.isLovedStatus === false) {
                                            animation.play();
                                            this.animation = null;
                                          }
                                        }
                                      }}
                                      /* $FlowFixMe */
                                      source={require('../animations/like.json')}
                                    />
                                  )
                                : (<FastImage style={[styles.postActionIcon]} source={require('../images/like_active.webp')} />)
                            )
                        }
                          <View style={{ height: p(28), backgroundColor: 'transparent', overflow: 'hidden' }}>
                            {
                              this.lovedId === itemData.id
                                ? (
                                  <Animated.View
                                    style={[{ flexDirection: 'column' }, { transform: [{ translateY: this.state.translateValue.y }] }]}>
                                      <Text style={[styles.numText, itemData.like ? { color: '#FA6481' } : {}]}>
                                        {this.state.lovedIdLikeCount || ''}
                                      </Text>
                                      <Text style={[styles.numText, itemData.like ? { color: '#FA6481' } : {}]}>
                                        {curLikeCount || ''}
                                      </Text>
                                  </Animated.View>
                                  )
                                : (
                                  <Text style={[styles.numText, itemData.like ? { color: '#FA6481' } : {}]}>
                                    {curLikeCount || ''}
                                  </Text>
                                  )
                            }
                          </View>
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
                  {(opt && opt.no_comment) || squareType === 'square_news'
                    ? null
                    : (
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginRight: p(70)
                      }}>
                      <TouchableOpacity
                        activeOpacity={0.8}
                        style={{
                          flexDirection: 'row',
                          height: p(25 + 22 * 2),
                          alignItems: 'center'

                        }}
                        onPress={(): void => {
                          if (!isSocialLogin(this.props.SocialReducer)) {
                            return helper.navigate(
                              this.props.navigation.navigate,
                              'Login'
                            );
                          }
                          this.props?.showCommentAndReplyModal('comment', itemData)
                        }}>
                        <FastImage
                          style={styles.postActionIcon}
                          source={require('../images/comment.webp')}
                        />
                        <Text style={styles.numText}>
                          {itemData.commentCount || ' '}
                        </Text>
                      </TouchableOpacity>
                    </View>
                      )}

                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}>
                    <TouchableOpacity
                      activeOpacity={0.8}
                      style={{
                        flexDirection: 'row',
                        height: p(25 + 22 * 2),
                        alignItems: 'center'
                      }}
                      onPress={(): void => this.share()}>
                      <FastImage
                        style={styles.postActionIcon}
                        source={require('../images/share.webp')}
                      />
                      <Text style={styles.numText}>{itemData.shareCount || ' '}</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              <TouchableOpacity
                activeOpacity={0.8}
                style={{
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  height: p(25 + 22 * 2)
                }}
                onPress={(): void => this.toggleModalPostMore()}
              >
                <FastImage
                  style={styles.postActionIcon}
                  source={require('../images/post_more.webp')}
                />
              </TouchableOpacity>
            </View>
            {
              (itemData.comments && itemData.comments.length > 0) || itemData.commentCount > 0
                ? <View style={[itemData.comments.length > 0 ? styles.postCommentsSection : {}]}>
                <FlatList
                  renderItem={({ item, index }: { item: ItemDataType, index: number }): Element<*> => (
                    <View style={styles.commentRow}>
                      <TouchableOpacity
                        activeOpacity={0.8}
                        onPress={() => {
                          this.props.navigation.navigate('NormalUser', {
                            itemData: item

                          });
                        }}>
                        <View style={styles.commentRowHeader}>
                          <FastImage
                            style={[imageRounded40, { marginRight: margin.m16 }]}
                            source={{ uri: item.customerMisc.avatar }}
                          />
                        <Text style={styles.commentRowNickname}>{item.customerMisc.nickName}：</Text>
                        </View>

                      </TouchableOpacity>
                      <TouchableOpacity
                        style={styles.commentRowCommentSection}
                        activeOpacity={0.8}
                        onPress={(): void => {
                          if (!isSocialLogin(this.props.SocialReducer)) {
                            return helper.navigate(this.props.navigation.navigate, 'Login');
                          }
                          this.props?.showCommentAndReplyModal('reply', item)
                        }}>
                        <Text style={styles.commentRowCommentText}>{item.text}</Text>
                      </TouchableOpacity>

                    </View>
                  )}
                  ItemSeparatorComponent={(): Element<*> => {
                    return <View style={{ height: height.h40 }} />;
                  }}
                  ListFooterComponent={(): Element<*> =>
                    itemData.commentCount > 3
                      ? (
                        <TouchableWithoutFeedback onPress={() => {
                          this.props.onComment &&
                            this.props.onComment(itemData, type, keyIndex);
                        }}>
                          <Text style={[styles.moreComment, itemData.comments.length === 0 ? { marginLeft: 0, marginTop: margin.m20 } : {}]}>
                            {I18n.t('moreComment')}
                          </Text>
                        </TouchableWithoutFeedback>
                        )
                      : <View/>
                  }
                  // 在feed流中只显示三条数据，在此过滤掉多余数据
                  // eslint-disable-next-line flowtype/require-return-type
                  data={(itemData.comments || []).filter((item: ItemType, index: number) => index < 3)}
                  scrollEnabled={false}
                  keyExtractor={(item: {}, index: number): string => {
                    return index.toString()
                  }}
                />

              </View>
                : null
            }

          </>
        );
      };

  headerItem: () => Element<*> = (): Element<*> => {
    let NUM_OF_LINES = 6;
    const { itemData, type, keyIndex } = this.props;
    const { customerMisc } = itemData;
    let { images, text } = itemData;
    const REGEX = /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/g;
    const url = text && text.match(REGEX) && text.match(REGEX)[0];
    const imgLen = images && images.length;
    let showLinkPreview = false;
    let title = ''

    if (itemData?.subVerb === 12 || helper.isHtmlTag(text)) {
      try {
        // const itemDataObj = JSON.parse(itemData.text)
        let striphtmls = striptags(itemData.text, ['img', 'br', 'source', 'video'])
        striphtmls = striphtmls.replace(/<br[^>]*>/g, '\n')
        striphtmls = striphtmls.replace(/<(?!img)(?!br)(?!source)(?!video)/g, '&lt;')
        const itemDataObj = html2json(striphtmls)

        const textArr = _.filter(itemDataObj?.child || [], function (item: ContentItemType): boolean { return item.node === 'text' && ((item.text || '').replace(/\n/g, '')).trim() !== '' })
        text = textArr[0]?.text || ''

        text = helper.replaceHtmlEntity(text);

        title = itemData.title ?? ''
        NUM_OF_LINES = 4
      } catch (e) {
        bugsnag.notify(new Error('[components-CellListOne] -- [headerItem] -- [error]: ' + e.toString()));
      }
    }

    // TODO
    if (!imgLen && url && (url.indexOf('youtube.com') > 0 || url.indexOf('youtu.be') > 0)) {
      showLinkPreview = true;
      text = text.replace(REGEX, '');
      text = text.trim();
    }

    let nickName;
    let avatar = null;
    if (customerMisc) {
      nickName = !customerMisc?.nickName ? '' : customerMisc.nickName;
      avatar = !customerMisc.avatar
        ? require('../images/defaultAvatar.webp')
        : { uri: customerMisc.avatar + IMAGE_96 };
    }
    if (customerMisc && nickName !== null) {
      const identificationSource = helper.identificationSwitch(customerMisc.role);
      return (
        <View style={{ position: 'relative', marginBottom: p(0) }}>
          <View>
            <View style={styles.headerWrap}>
              <View style={styles.headerIcon}>
                <TouchableWithoutFeedback
                  onPress={() => {
                    this.props.navigation.navigate('NormalUser', {
                      itemData,
                      onFollow: this.props.onFollow,
                      type: this.props.type,
                      index: this.props.keyIndex,
                      squareType: this.props.squareType === 'square_news' ? 'square_news' : '',
                      TRACK_PARAM: this.props.squareType === 'square_news' ? 'square_news' : ''
                    });
                  }}>
                  <View
                    style={[{ position: 'relative' }]}>
                    <FastImage source={avatar || 0} style={styles.peopleIcon} />
                    {/* 添加用户身份功能，位置和旧版本vip功能相同 */}
                    {identificationSource ? <FastImage style={styles.identificationImageStyle} source={identificationSource} /> : null}
                  </View>
                </TouchableWithoutFeedback>
                <TouchableWithoutFeedback
                  testID={'socialDetail' + keyIndex}
                  accessibilityLabel={'socialDetail' + keyIndex}
                  onPress={() => {
                    this.props.onTextClick && this.props.onTextClick(itemData, type, keyIndex);
                  }}>
                  <View style={styles.headerCenter}>
                    <Text style={styles.name}>
                      {customerMisc?.nickName ?? ''}
                    </Text>
                    <View
                      style={styles.timeAndIntroSection}>
                      <Text style={[styles.title]}>
                        {helper.toLocaleTime(itemData.timestamp, 'MM-DD HH:mm')}
                      </Text>
                      {
                        itemData.customerMisc?.intro
                          ? <Text ellipsizeMode='tail' numberOfLines={1} style={[styles.intro, { marginLeft: p(16) }]}>
                          {itemData.customerMisc?.intro.split('\n')[0]}
                        </Text>
                          : null
                      }

                    </View>
                  </View>
                </TouchableWithoutFeedback>
              </View>

              {!customerMisc?.follow && global.USER_ID !== customerMisc?.customerId
                ? (
                <TouchableWithoutFeedback onPress={(): void => this.follow(customerMisc)}>
                  <View style={Button.followBtnStyle}>
                    <Text style={Button.followBtnTextStyle}>
                      {I18n.t('community_follow')}
                    </Text>
                  </View>
                </TouchableWithoutFeedback>
                  )
                : null}
            </View>

          </View>
          {
            title
              ? <TouchableOpacity
                  style={styles.titleStyle}
                  activeOpacity={0.8}
                  onPress={() => { this.props.onTextClick && this.props.onTextClick(itemData, type, keyIndex); }}
                >
                <Text style={styles.titleTextStyle}>{title}</Text>
              </TouchableOpacity>
              : null
          }
          {
            text
              ? (
            <View style={styles.textWrap}>
              <TouchableOpacity
                activeOpacity={0.8}
                testID={'socialText' + keyIndex}
                accessibilityLabel={'socialText' + keyIndex}
                onPress={() => {
                  if (this.props.squareType === 'square_news') {
                    try {
                      const link = this.props.itemData.link;
                      helper.trackEvent('Page Click SquareNews', {
                        news_id: this.props.itemData.id,
                        USER_ID: USER_ID,
                        news_text: this.props.itemData.text.substr(0, 30),
                        news_link: link.split(':')[1],
                        news_author: this.props.itemData?.customerMisc
                          ?.nickName
                      });
                    } catch (e) {
                      bugsnag.notify(new Error('[components-PostComponent] -- [trackEvent] -- [error]: ' + e.toString()));
                    }

                    this.props.navigation.navigate('WebViewPage', {
                      url: this.props.itemData.link,
                      title: I18n.t('community_news'),
                      TRACK_PARAM: this.props.itemData.link
                    });
                    return;
                  }
                  this.props.onTextClick && this.props.onTextClick(itemData, type, keyIndex);
                }}>
                <Hyperlink
                  onPress={(url: string, text: string): void => {
                    if (url) {
                      return helper.navigate(
                        this.props.navigation.navigate,
                        'WebViewPage',
                        { url: url, TRACK_PARAM: url, title: I18n.t('community_WebPageDetail') }
                      );
                    }
                  }}
                  text={text}
                  linkStyle={{ color: '#2980b9' }}>
                  <TextMore NUM_OF_LINES={NUM_OF_LINES} text={text} />
                </Hyperlink>
              </TouchableOpacity>
            </View>
                )
              : (
            <TouchableOpacity activeOpacity={0.8} onPress={() => { this.props.onTextClick && this.props.onTextClick(itemData, type, keyIndex); }}>
              <View style={{ height: p(20) }}/>
            </TouchableOpacity>
                )
          }
          {
            showLinkPreview ? this.renderLinkToPreview(url) : null
          }
        </View>
      );
    } else {
      return <View/>;
    }
  };

  // eslint-disable-next-line flowtype/require-return-type
  selecteTopic: (rowItem: ItemDataType & {topicContent: string, topicId: string}) => void = (rowItem: ItemDataType & {topicContent: string, topicId: string}): void => {
    if (rowItem) {
      this.props.navigation.navigate(
        'HotTopicFeed',
        {
          item: rowItem,
          TRACK_PARAM: rowItem.topicId
        }
      );
    }
  };

  _renderTopic: (itemData: ItemDataType & {topicContent: string, topicId: string}) => Element<*> = (itemData: ItemDataType & {topicContent: string, topicId: string}): Element<*> => {
    if (!itemData.topicId) {
      return <View />;
    }
    return (
      <View style={styles.topicSection}>
        <TouchableOpacity activeOpacity={0.8} onPress={(): void => this.selecteTopic(itemData)}>
          <View style={styles.topicSectionBtn}>
            <FastImage source={require('../images/topic_tag.webp')} style={styles.topicTagImage}/>
            <Text numberOfLines={2} style={styles.topicTagText}>{itemData.topicContent}</Text>
          </View>
        </TouchableOpacity>
      </View>
    )
  };

  renderLinkToPreview: (url: string) => Element<*> = (url: string): Element<*> => {
    return (
      <View style={{ marginTop: p(18) }}>
        <LinkToPreview
          text={url}
          _onLinkPressed={() => {
            DeviceEventEmitter.emit('youtubeplay', url);
          }}
        />
      </View>
    );
  };

    _renderItemNews: ({ item: string, index: number}) => Element<*> = ({ item, index }: { item: string, index: number}): Element<*> => {
      const { itemData } = this.props;

      const { text, customerMisc } = itemData;
      const theModel = item + imageUriParam;
      return (
      <TouchableOpacity
        activeOpacity={0.8}
        style={{ flexDirection: 'row' }}
        onPress={() => {
          if (!global.serviceHealth && !this.props.showNewsSwiper) {
            DeviceEventEmitter.emit('showStopServiceModal', true)
            return
          }

          this.props.navigation.navigate('WebViewPage', {
            url: itemData.link,
            title: I18n.t('community_news'),
            TRACK_PARAM: itemData.link
          });
        }}>
        <View
          style={{
            flex: 1,
            marginRight: margin.m20,
            justifyContent: justifyContent.spaceBetween
          }}>
          <Text
            numberOfLines={2}
            style={{
              fontSize: fontSize.f28,
              lineHeight: lineHeight.l40,
              color: colors.black3,
              fontWeight: fontWeight.f700
            }}>
            {text}
          </Text>
          <Text
            numberOfLines={1}
            style={{
              color: '#8E939F',
              fontSize: p(20),
              marginTop: p(10)
            }}>
            {customerMisc?.nickName ?? ''} {' '}
            {helper.toLocaleTime(itemData.timestamp, 'MM-DD HH:mm')}
            {/* {'BlockTemple 動區動趨 . 10 minutes ago'} */}
          </Text>
        </View>
        {item && item.indexOf('jeg-empty') === -1
          ? <PlaceHolderFastImage
            imgStyle={styles.newsPoster}
            style={styles.newsPoster}
            text={''}
            textStyle={styles.newsPoster}
            source={{ uri: theModel }}
            fallback={Platform.OS === 'android'}
          />
          : <FastImage
          style={styles.newsPoster}
          source={require('../images/default_news.webp')}
        />}
      </TouchableOpacity>
      );
    };

    render (): Element<*> {
      const { itemData, customFooter, postListWraper = {}, keyIndex } = this.props;
      let images = [];
      if (itemData.images) {
        images = [...itemData.images]
      }

      if (itemData.deleted) {
        return (
          <View style={[styles.postListWraper, { alignItems: 'center' }]}>
            <Text style={{ color: '#1F2126', fontSize: p(28), lineHeight: p(40) }}>{I18n.t('originText_deleted1')}~</Text>
            <View style={{ backgroundColor: '#E8EBF1', width: p(630), height: 1, marginTop: p(30) }}/>
            <TouchableWithoutFeedback onPress={(): void => this.props.onFavoirite(itemData, keyIndex)} >
              <View style={{ width: p(630), alignItems: 'center', marginVertical: p(30) }}>
                <Text style={{ color: '#1F2126', fontSize: p(28), lineHeight: p(40) }}>{I18n.t('deleteConfirm')}</Text>
              </View>
            </TouchableWithoutFeedback>
          </View>
        )
      }

      if (itemData.blocked) {
        return (
          <View style={[styles.postListWraper, { alignItems: 'center' }]}>
            <Text style={{ color: '#1F2126', fontSize: p(28), lineHeight: p(40) }}>{I18n.t('originText_blocked')}~</Text>
            <View style={{ backgroundColor: '#E8EBF1', width: p(630), height: 1, marginTop: p(30) }}/>
            <TouchableWithoutFeedback onPress={(): void => this.props.onFavoirite(itemData, keyIndex)} >
              <View style={{ width: p(630), alignItems: 'center', marginVertical: p(30) }}>
                <Text style={{ color: '#1F2126', fontSize: p(28), lineHeight: p(40) }}>{I18n.t('deleteConfirm')}</Text>
              </View>
            </TouchableWithoutFeedback>
          </View>
        )
      }
      if (this.props.squareType === 'square_news') {
        return (
        <TouchableWithoutFeedback
          testID={'socialNewsDetail' + keyIndex}
          accessibilityLabel={'socialNewsDetail' + keyIndex}
          onPress={() => {
            if (!global.serviceHealth && !this.props.showNewsSwiper) {
              DeviceEventEmitter.emit('showStopServiceModal', true)
              return
            }

            this.props.navigation.navigate('WebViewPage', {
              url: itemData.link,
              title: I18n.t('community_news'),
              TRACK_PARAM: itemData.link
            });
          }}>
          <View style={styles.newsWraper}>
            <View >
              <FlatList
                renderItem={this._renderItemNews}
                data={images && images.length > 0 ? images : ['']}
                numColumns={1}
                scrollEnabled={false}
                key={1}
                keyExtractor={(item: {}, index: number): string => {
                  return index.toString()
                }}
              />
            </View>
          </View>
        </TouchableWithoutFeedback>
        );
      }
      if (itemData?.subVerb === 12 || helper.isHtmlTag(itemData?.text)) {
        try {
          const _images = images.length > 3 ? images.splice(0, 3) : images
          return (
            <View style={[styles.postListWraper]}>
              {this.headerItem()}
              {itemData.images && itemData.images.length > 0
                ? (
                <View style={styles.imagesSection}>
                  {

                    itemData.images.length === 1 || itemData.images.length === 2
                      ? (
                        <FlatList
                          data={_images}
                          renderItem={images.length === 1 ? this._renderItemOne : this._renderItemTwo}
                          numColumns={images.length === 1 ? 1 : 2}
                          scrollEnabled={false}
                          key={itemData.images.length}
                          keyExtractor={(item: string, index: number): string => {
                            return index.toString()
                          }}
                        />
                        )
                      : this._renderItemThree({ itemData, images: _images, imagesLength: itemData.images.length })
                  }

                </View>
                  )
                : null}
              {
                images.length === 0 && itemData.isVideo
                  ? <View style={{ marginTop: p(20) }}>{this._renderVideo(itemData)}</View>
                  : null

              }
               {this._renderTopic(itemData)}
              {!customFooter ? this._footerItem(itemData) : customFooter(itemData)}

            </View>
          )
        } catch (e) {
          bugsnag.notify(new Error('[components-PostComponent] -- [render] -- [error]: ' + e.toString()));
          console.log(e)
          return (<View/>)
        }
      }

      if (itemData.isVideo) {
        if (this.props.postType === 'learn') {
          return (
          <TouchableWithoutFeedback
            onPress={() => {
              this.props.navigation.navigate('WebViewPage', {
                url: itemData.videoSource,
                title: I18n.t('learn'),
                TRACK_PARAM: itemData.videoSource
              });
            }}>
            <View style={[styles.postListWraper, postListWraper]}>
              <View>
                {this._renderLearnVideo(itemData)}
                {this._renderTopic(itemData)}
                {!customFooter ? this._footerItem(itemData, { no_comment: true }) : customFooter(itemData)}
              </View>
            </View>
          </TouchableWithoutFeedback>
          );
        } else {
          return (
          <View style={[styles.postListWraper, postListWraper]}>
            {this.headerItem()}
            <View>{this._renderVideo(itemData)}</View>
            {this._renderTopic(itemData)}
            {!customFooter
              ? this._footerItem(itemData, { no_comment: this.props.squareType === 'square_news' })
              : customFooter(itemData)}
          </View>
          );
        }
      } else if (images && images.length === 1) {
        return (
          <View style={[styles.postListWraper, postListWraper]}>
            {this.headerItem()}
            <View style={{ marginTop: p(12) }}>
              <FlatList
                renderItem={this._renderItemOne}
                data={images}
                numColumns={1}
                scrollEnabled={false}
                key={1}
                keyExtractor={(item: {}, index: number): string => {
                  return index.toString()
                }}
              />
            </View>
            {!customFooter
              ? this._footerItem(itemData)
              : customFooter(itemData)}
          </View>
        );
      } else if (images && images.length === 2) {
        return (
        <View style={[styles.postListWraper, postListWraper]}>
          {this.headerItem()}
          <View style={{ marginTop: p(12) }}>
            <FlatList
              renderItem={this._renderItemTwo}
              data={images}
              numColumns={2}
              scrollEnabled={false}
              key={2}
              keyExtractor={(item: {}, index: number): string => {
                return index.toString()
              }}
            />
          </View>
          {this._renderTopic(itemData)}
          {!customFooter ? this._footerItem(itemData) : customFooter(itemData)}
        </View>
        );
      } else if (images && images.length === 3) {
        return (
          <View style={[styles.postListWraper, postListWraper]}>
            {this.headerItem()}
            <View style={{ marginTop: p(12) }}>{this._renderItemThree({ itemData, images, imagesLength: itemData.images.length })}</View>
            {this._renderTopic(itemData)}
            {!customFooter ? this._footerItem(itemData) : customFooter(itemData)}
          </View>
        );
      } else if (images && images.length > 3) {
        images = images.splice(0, 3);
      }

      return (
      <View style={[styles.postListWraper, postListWraper]}>
        {this.headerItem()}
        {itemData.images && itemData.images.length > 0
          ? (
            <View style={{ marginTop: p(12) }}>{this._renderItemThree({ itemData, images, imagesLength: itemData.images.length })}</View>
            )
          : null}
        {this._renderTopic(itemData)}
        {!customFooter ? this._footerItem(itemData) : customFooter(itemData)}
      </View>
      );
    }
}

const styles = StyleSheet.create({
  newsWraper: {
    paddingHorizontal: paddingHorizontal.ph30,
    backgroundColor: colors.white2,
    paddingVertical: paddingVertical.pv30,
    marginHorizontal: marginHorizontal.mh30,
    borderRadius: radius.r36
  },
  postListWraper: {
    paddingHorizontal: paddingHorizontal.ph30
  },
  headerWrap: {
    height: height.h80,
    justifyContent: justifyContent.spaceBetween,
    alignItems: alignItems.center,
    flexDirection: 'row'
  },
  headerIcon: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  peopleIcon: imageRounded80,
  identificationImageStyle: {
    width: p(28),
    height: p(28),
    position: 'absolute',
    bottom: 0,
    right: -p(2)
  },
  vipFastImageStyle: {
    width: p(30),
    height: p(30),
    position: 'absolute',
    bottom: 0,
    right: p(0)
  },
  headerCenter: {
    marginLeft: margin.m20,
    justifyContent: justifyContent.center
  },
  timeAndIntroSection: {
    flexDirection: 'row',
    alignItems: alignItems.center
  },
  headerRight: {
    flexDirection: 'row',
    height: p(44),
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: p(16),
    borderRadius: p(12),
    backgroundColor: '#F3F3F5'
  },
  follow: {
    fontSize: p(24),
    color: '#000',
    marginLeft: p(3),
    fontWeight: 'bold'
  },
  followActive: {
    fontSize: p(24),
    color: '#ccc',
    marginLeft: p(3),
    fontWeight: 'bold'
  },
  name: {
    fontSize: fontSize.f28,
    lineHeight: lineHeight.l36,
    color: colors.black3,
    fontWeight: fontWeight.f700
  },
  title: {
    fontSize: fontSize.f22,
    color: colors.gray1,
    lineHeight: lineHeight.l28
  },
  intro: {
    fontSize: fontSize.f20,
    color: colors.gray1,
    lineHeight: lineHeight.l26,
    maxWidth: p(350)
  },

  titleStyle: {
    marginTop: margin.m14
  },
  titleTextStyle: {
    color: colors.black,
    fontSize: fontSize.f34,
    lineHeight: lineHeight.l40,
    fontWeight: fontWeight.f700
  },
  textWrap: {
    flex: 1,
    marginTop: margin.m16
  },
  text: {
    color: '#1F2126',
    fontSize: p(28),
    lineHeight: p(40)
  },

  cellContainer: {
    position: 'relative',
    marginRight: p(12),
    marginBottom: p(12),
    width: (screenWidth - p(60 + 24)) / 3,
    height: (screenWidth - p(60 + 24)) / 3,
    alignItems: 'center',
    justifyContent: 'center'
  },
  cellContainerOne: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: screenWidth - p(60 + 12),
    height: screenWidth - p(60 + 12)
  },

  cellContainerTwo: {
    position: 'relative',
    justifyContent: 'center',
    marginRight: p(12),
    marginBottom: p(12),
    alignItems: 'center',
    width: (screenWidth - p(60 + 9)) / 2,
    height: (screenWidth - p(60 + 9)) / 2
  },
  cellContainerThree: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: p(224),
    height: p(224)
  },
  cellTitleViewStyle: {
    justifyContent: 'center'
  },
  cellImgTwo: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
    borderRadius: p(6)
  },
  cellImgOne: {
    borderRadius: p(6)
  },
  cellImg: {
    width: '100%',
    height: '100%',
    borderRadius: p(6)
  },
  shareLove: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row'
  },
  shareItem: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  postActionIcon: {
    height: p(48),
    width: p(48)
  },
  loveItem: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1
  },
  numText: {
    color: colors.gray1,
    fontSize: fontSize.f22,
    lineHeight: lineHeight.l28
  },
  topicSection: {
    marginTop: margin.m28,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: alignItems.center
  },
  topicSectionBtn: {
    flexDirection: 'row',
    alignItems: alignItems.center,
    justifyContent: justifyContent.center,
    backgroundColor: colors.white3,
    height: height.h52,
    borderRadius: radius.r90,
    paddingHorizontal: paddingHorizontal.ph16
  },
  topicTagImage: {
    width: width.w30,
    height: height.h27,
    justifyContent: justifyContent.center,
    alignItems: alignItems.center,
    marginRight: margin.m10
  },
  topicTagText: {
    fontSize: fontSize.f24,
    maxWidth: width.w620,
    color: colors.purple,
    lineHeight: lineHeight.l31,
    fontWeight: fontWeight.f500
  },
  imagesSection: {
    marginTop: margin.m28
  },
  commentRow: {
  },
  commentRowHeader: {
    flexDirection: 'row',
    alignItems: alignItems.center
  },
  commentRowNickname: {
    color: colors.black,
    fontSize: fontSize.f26,
    lineHeight: lineHeight.l33
  },
  commentRowCommentSection: {
    marginLeft: margin.m56
  },
  commentRowCommentText: {
    color: colors.black1,
    lineHeight: lineHeight.l40,
    fontSize: fontSize.f26
  },
  postCommentsSection: {
    marginTop: margin.m32
  },
  moreComment: {
    marginLeft: margin.m56,
    fontSize: fontSize.f24,
    lineHeight: lineHeight.l31,
    color: colors.gray1,
    marginTop: margin.m40
  },
  newsPoster: {
    width: p(200),
    height: p(152),
    borderRadius: p(12)
  }
});
