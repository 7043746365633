import domtoimage from 'dom-to-image';
const CameraRoll = {
  save: function (node, opt) {
    return new Promise((resolve, reject) => {
      try {
        const fileName = (opt && opt.name) || 'kikitrade_' + Date.now()
        domtoimage.toJpeg(node, { quality: 0.95 })
          .then(function (dataUrl) {
            console.log(dataUrl)
            const link = document.createElement('a');
            link.download = fileName + '.jpeg';
            link.href = dataUrl;
            link.click();
          });
        resolve();
      } catch (e) {
        reject(e);
      }
    })
  },
  screenShot: function (node, opt) {
    return new Promise((resolve, reject) => {
      domtoimage.toPng(node)
        .then(function (dataUrl) {
          resolve(dataUrl);
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }
}

export default CameraRoll
