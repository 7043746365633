// @flow
import React from 'react';
import type { Element } from 'react';
import {
  View,
  StyleSheet,
  Platform,
  Text
} from 'react-native';
// import FastImage from 'react-native-fast-image';
import type { TextStyle } from 'react-native/Libraries/StyleSheet/StyleSheet';
import type { ExtractMarketData, SymbolList } from '../models/market';
import PlaceHolderFastImage from './PlaceHolderFastImage'
// import I18n from '../utils/i18n';
import { TouchableOpacity } from 'react-native-gesture-handler';

import helper from '../utils/helper'

import { imageRounded80, width, height, lineHeight, paddingHorizontal, colors, radius, margin, padding, fontSize, fontWeight } from '../styles/Common'

function CoinCard ({ coinTextStyle, index, riseStyle, hidePrice = false, item, symbol, showName = false, navigation }: {
  coinTextStyle: TextStyle,
  riseStyle: TextStyle,
  hidePrice: true,
  item: ExtractMarketData,
  index: number,
  symbol: SymbolList,
  showName: boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  navigation: {navigate: (route: string, params: any)=>{}}
}): Element<*> {
  item = item || {}
  symbol = symbol || {}
  return (
    <TouchableOpacity
      testID={'marketDetail' + index}
      accessibilityLabel={'marketDetail' + index}
      activeOpacity={0.8}
      onPress={() => {
        const type = 'spot'
        const params = {
          coinCode: item.coinCode,
          type: type,
          TRACK_PARAM: helper.toTrackParam(type, item.coinCode)
        }
        try {
          helper.trackEvent('NewHome Click MarketDetail ' + item.coinCode);
        } catch (e) {
        }
        if (item.coinCode) {
          navigation.navigate('FiatAndSpotTrade', params)
        }
      }}>
      <View style={styles.marketRowStyle}>

        <PlaceHolderFastImage
          imgStyle={styles.coinStyle}
          style={styles.coinStyle}
          text={''}
          textStyle={styles.coinStyle}
          source={{ uri: symbol.picturePath }}
          fallback={Platform.OS === 'android'}
        />

        <Text style={[styles.coinTextStyle, coinTextStyle ?? {}]}>{showName ? symbol.name : symbol.coinCode}</Text>
        {
          !hidePrice
            ? <Text style={styles.priceStyle}>${item.priceLast}</Text>
            : null
        }
        <Text style={[styles.riseStyle, { color: Number(item.riseAndFall) > 0 ? colors.green : (Number(item.riseAndFall) < 0 ? colors.red : colors.gray3) }, riseStyle ?? {}]}>
          {helper.formatFixNumString(Number(item.riseAndFall ?? '0'), 2)}%
        </Text>
      </View>
    </TouchableOpacity>

  )
}

const styles = StyleSheet.create({
  marketRowStyle: {
    width: width.w192,
    height: height.h248,
    paddingHorizontal: paddingHorizontal.ph24,
    paddingTop: padding.p24,
    paddingBottom: padding.p32,
    backgroundColor: colors.white2,
    borderRadius: radius.r36
  },
  coinStyle: imageRounded80,
  coinTextStyle: {
    color: colors.black,
    fontSize: fontSize.f24,
    lineHeight: lineHeight.l31,
    marginTop: margin.m10,
    fontWeight: fontWeight.f500
  },
  priceStyle: {
    color: colors.black2,
    fontSize: fontSize.f20,
    marginTop: margin.m2
  },
  riseStyle: {
    fontSize: fontSize.f28,
    fontWeight: fontWeight.f700,
    lineHeight: lineHeight.l36,
    marginTop: margin.m7
  }
})

// eslint-disable-next-line flowtype/no-weak-types
const MemoizedCoinCard: any = React.memo(CoinCard);
export default MemoizedCoinCard
