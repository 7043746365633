// @flow
import React, { Component } from 'react';
import type { Element } from 'react';
import FastImage from 'react-native-fast-image';
import { View } from 'react-native';

class Logo extends Component {
  render (): Element<*> {
    return (
        <View style={this.props.style}>
            <FastImage
                source={require('../../images/logo.webp')}
                resizeMode={'contain'}
                style={{
                  width: 169,
                  height: 30,
                  marginTop: 56
                }}
            />
        </View>
    )
  }
}
export default Logo;
