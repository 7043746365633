// @flow
import React from 'react';
import type { Element } from 'react';
import {
  View,
  StyleSheet,
  Text
} from 'react-native';
import FastImage from 'react-native-fast-image';
import { TouchableOpacity } from 'react-native-gesture-handler';

import I18n from '../utils/i18n';
import p from '../utils/Transfrom';

import type { SymbolList } from '../models/market';

import helper from '../utils/helper'

import { width, height, paddingHorizontal, imageRounded40, radius, colors, padding, margin } from '../styles/Common'

function FundingFeeView (props: {
  languageCode: string,
  navigation: {
    navigate: (name: string, opt?: {})=> void
  },
  symbolETH: SymbolList,
  symbolBTC: SymbolList,
  item?: { type: string, interval: string, dataList?: {}, symbol: string, exchange: string }
}): Element<*> {
  return (
    <TouchableOpacity activeOpacity={0.8} onPress={() => {
      try {
        helper.trackEvent('NewHome Click ExploreDataBTCFunding', { item: props.item ? JSON.stringify(props.item) : '' });
      } catch (e) {
      }

      props.navigation.navigate('ExploreDataBTCFunding', { TRACK_PARAM: helper.toTrackParam(props.item?.type ?? '', props.item?.exchange ?? '', props.item?.symbol ?? '', props.item?.interval ?? '') });
    }}>
      <View style={styles.card}>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: p(50) }}>
            <FastImage
              source={{ uri: props.symbolBTC.picturePath }}
               style={imageRounded40}
            />
            <FastImage
              source={{ uri: props.symbolETH.picturePath }}
               style={[imageRounded40, { marginLeft: margin.m16 }]}
            />

        </View>
        <View style={{
          justifyContent: 'center'
        }}>
          <Text style={[styles.coin, {
            fontSize: p(46),
            lineHeight: p(59),
            marginBottom: p(12),
            marginTop: p(28),
            fontWeight: 'bold'
          }]}>BTC / ETH</Text>

          <Text style={[styles.coin]}>{I18n.t('funding_rate')}</Text>
        </View>

      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({

  card: {
    width: width.w380,
    height: height.h340,
    paddingTop: padding.p30,
    backgroundColor: colors.white3,
    borderRadius: radius.r36,
    paddingHorizontal: paddingHorizontal.ph24
  },
  coinRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: p(7)
  },
  coin: {
    fontSize: p(24),
    color: '#1F2126',
    lineHeight: p(33),
    textAlign: 'center'
  }

})

// eslint-disable-next-line flowtype/no-weak-types
const MemoizedFundingFeeView: any = React.memo(FundingFeeView);
export default MemoizedFundingFeeView
