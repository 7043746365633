/**
 * @Author:      vincent
 * @DateTime:    2018-03-22 10:51:55
 * @flow
 */
import { Platform, StatusBar, DeviceEventEmitter } from 'react-native';
import { ActionTypes } from './ActionTypes';
import I18n from '../utils/i18n';
import helper from '../utils/helper';
import bugsnag from '../utils/bugsnag';
import type {
  CustomerInfoType, LoginError,
  LoginResponseType, OauthLoginError,
  OauthLoginResponseType,
  Referral,
  ReferralResponseType
} from '../api/customer';
import { CustomerAPI } from '../api/customer';
import { ExploreDataAPI } from '../api/exploreData';
import { KycAPI } from '../api/kyc';
import { AccountAPI } from '../api/account';

import { FetchSocialLogin } from './SocialAction';
import type { FetchAccountInfoThunkActionType } from './AccountAction';
import { FetchAccountAssetsAll } from './AccountAction';
import type { FetchMyTraderInfoThunkActionType } from './TraderAction';
import { FetchMyTraderInfo } from './TraderAction';
import analytics from '@react-native-firebase/analytics';
import remoteConfig from '@react-native-firebase/remote-config';
import messaging from '@react-native-firebase/messaging';
import AsyncStorage from '@react-native-async-storage/async-storage';
import auth from '@react-native-firebase/auth';
import type { ThunkAction } from 'redux-thunk';
import { ThunkDispatch } from 'redux-thunk';
import type { Action } from 'redux';
import type {
  BannerConfigType,
  EventCenterConfigType,
  RegTrialFundConfigType,
  RegBonusConfigType,
  DiscoverConfigType,
  ErrorActionType,
  GiftCardConfigType,
  OpenAuthInfoType,
  RemoteConfigType,
  FiatDepositConfigType,
  StateType,
  GuideBeginnersConfigType
} from './common';
import type { CommonResponse, CustomerInfo, CustomerSimpleInfo } from '../api/base';
import type { GetDataListResponseType, Item, VsItem } from '../api/exploreData';
console.log(analytics)
console.log(remoteConfig)
console.log(messaging)

// all action definitions start
export type AccountUserInfoActionType = Action<typeof ActionTypes.ACCOUNT_USER_INFO> & {
  email?: string,
  userInfo: CustomerSimpleInfo,
  userLoading: boolean,
  isLogin: boolean
};

export type AccountSaveUserActionType = Action<typeof ActionTypes.ACCOUNT_SAVE_USER> & {
  email: string
};
export type AccountUserInfoRequestActionType = Action<typeof ActionTypes.ACCOUNT_USER_INFO_REQUEST>;
export type AccountUserInfoErrorActionType = Action<typeof ActionTypes.ACCOUNT_USER_INFO_ERROR> & ErrorActionType;
export type AccountUserInfoResetActionType = Action<typeof ActionTypes.ACCOUNT_USER_INFO_RESET>;
export type CleanOpenAuthInfoActionType = Action<typeof ActionTypes.CLEAN_OPEN_AUTH_INFO>;
export type ReferInfoActionType = Action<typeof ActionTypes.REFER_INFO> & { referrerId: string };
export type AccountLockedActionType = Action<typeof ActionTypes.ACCOUNT_LOCKED> & { time: string };
export type AccountLostSessionActionType = Action<typeof ActionTypes.ACCOUNT_LOST_SESSION>;
export type AccountSecondVerifyActionType = Action<typeof ActionTypes.ACCOUNT_SECOND_VERIFY> & {
  secondOpen: boolean,
  secondType: number,
  telephone: string,
  isGoogleCertified: boolean,
  isPhoneCertified: boolean,
  code: string
};

export type AccountLogoutActionType = Action<typeof ActionTypes.ACCOUNT_LOGOUT>;
export type AccountLogoutRequestActionType = Action<typeof ActionTypes.ACCOUNT_LOGOUT_REQUEST>;
export type AccountLogoutErrorActionType = Action<typeof ActionTypes.ACCOUNT_LOGOUT_ERROR> & ErrorActionType;
export type AccountLogoutResetActionType = Action<typeof ActionTypes.ACCOUNT_LOGOUT_RESET>;
export type RefreshOpenAuthInfoActionType = Action<typeof ActionTypes.REFRESH_OPEN_AUTH_INFO> & { info: OpenAuthInfoType };
export type I18nLangActionType = Action<typeof ActionTypes.I18N_LANG> & { languageCode: string };
export type AccountUserInfoCleanActionType = Action<typeof ActionTypes.ACCOUNT_USER_INFO_CLEAN>;
export type GiftCardConfigActionType = Action<typeof ActionTypes.GIFTCARD_CONFIG> & { giftCardConfig: GiftCardConfigType };
export type FiatDepositConfigActionType = Action<typeof ActionTypes.FIATDEPOSIT_CONFIG> & { giftCardConfig: FiatDepositConfigType };
export type VersionConfigActionType = Action<typeof ActionTypes.VERSION_CONFIG>;
export type SetFirstUpdateShowActionType = Action<typeof ActionTypes.FIRST_UPDATE_SHOW>;
export type PromptedVersionInfoActionType = Action<typeof ActionTypes.PROMPTED_VERSION_INFO>;

export type BannerConfigActionType = Action<typeof ActionTypes.BANNER_CONFIG> & { bannerConfig: BannerConfigType };
export type EventCenterActionType = Action<typeof ActionTypes.EVENTCENTER_CONFIG> & { eventCenterConfig: EventCenterConfigType };

export type RegTrialFundActionType = Action<typeof ActionTypes.REG_TRIAL_FUND_CONFIG> & { regTrialFundConfig: RegTrialFundConfigType };
export type RegBonusConfigActionType = Action<typeof ActionTypes.REG_BONUS_CONFIG> & { regBonusConfig: RegBonusConfigType };
export type DiscoverConfigActionType = Action<typeof ActionTypes.DISCOVER_CONFIG> & { DiscoverConfig: DiscoverConfigType };

export type RemoteConfigActionType = Action<typeof ActionTypes.REMOTE_CONFIG> & { remoteConfig: RemoteConfigType };
export type VsDataConfigActionType = Action<typeof ActionTypes.VSDATA_CONFIG> & { vsDataConfig: VsItem[] };

export type SetRegBonusOpenTimeActionType = Action<typeof ActionTypes.REGBONUS_OPENTIME> & { regBonusOpenTime: string };

export type SetNoticeSystemActionType = Action<typeof ActionTypes.NOTICE_SYSTEM> & { noticeSystem: string[] };
export type SetNoticeOtcActionType = Action<typeof ActionTypes.NOTICE_OTC> & { noticeOtc: string[] };
export type SetNoticeRewardActionType = Action<typeof ActionTypes.NOTICE_REWARD> & { noticeReward: string[] };
export type SetNoticeAllActionType = Action<typeof ActionTypes.NOTICE_ALL> & { noticeSystem: string[], noticeOtc: string[], noticeReward: string[] };

export type GuideBeginnersConfigActionType = Action<typeof ActionTypes.GUIDEBEGINNERS_CONFIG> & { guideBeginnersConfig: GuideBeginnersConfigType };
export type DepositConfigActionType = Action<typeof ActionTypes.DEPOSIT_CONFIG> & { depositConfig: string };
// all action definitions end

export type HomeActionsType =
  AccountUserInfoRequestActionType
  | AccountLockedActionType
  | AccountSecondVerifyActionType
  | AccountUserInfoActionType
  | AccountSaveUserActionType
  | AccountLogoutActionType
  | AccountLogoutRequestActionType
  | AccountLogoutErrorActionType
  | AccountLogoutResetActionType
  | AccountUserInfoErrorActionType
  |
  AccountUserInfoResetActionType
  | CleanOpenAuthInfoActionType
  | ReferInfoActionType
  | AccountLostSessionActionType
  | RefreshOpenAuthInfoActionType
  | I18nLangActionType
  | AccountUserInfoCleanActionType
  | GiftCardConfigActionType
  | BannerConfigActionType
  | RemoteConfigActionType
  | SetNoticeSystemActionType
  | SetNoticeOtcActionType
  | SetNoticeRewardActionType
  | SetNoticeAllActionType;

// eslint-disable-next-line no-use-before-define
type LoginSuccessUnionActionType =
  AccountSecondVerifyActionType
  // eslint-disable-next-line no-use-before-define
  | FetchSocialLoginThunkActionType
  // eslint-disable-next-line no-use-before-define
  | FetchUserInfoThunkActionType
  // eslint-disable-next-line no-use-before-define
  | AccountSaveUserThunkActionType
  // eslint-disable-next-line no-use-before-define
  | TriggerLoginEventThunkActionType
  | FetchMyTraderInfoThunkActionType;
const handleLoginSuccess = (isOpenAuth: boolean, dispatch: ThunkDispatch<void, {}, LoginSuccessUnionActionType>, response: CommonResponse, customerInfo: ?CustomerSimpleInfo, email?: string, mobile?: string, country?: string) => {
  switch (response.code) {
    case '0104':
      // const telephone = response.obj;
      dispatch({
        type: ActionTypes.ACCOUNT_SECOND_VERIFY,
        secondOpen: true,
        secondType: 7,
        telephone: '', // not support currently
        isGoogleCertified: true,
        isPhoneCertified: true,
        code: '0104'
      });
      break;
    case '0103':
      dispatch({
        type: ActionTypes.ACCOUNT_SECOND_VERIFY,
        secondOpen: true,
        secondType: 2,
        telephone: '', // not support currently
        isGoogleCertified: false,
        isPhoneCertified: true,
        code: '0103'
      });
      break;
    case '0102':
      dispatch({
        type: ActionTypes.ACCOUNT_SECOND_VERIFY,
        secondOpen: true,
        secondType: 7,
        telephone: '', // not support currently
        isGoogleCertified: true,
        isPhoneCertified: false,
        code: '0102'
      });
      break;
    default:
      // success, 成功登录，发起获取用户信息
      // save user info
      // login no need to fetch user info anymore

      dispatch(FetchUserInfo({
        customerInfo: customerInfo,
        callback: (userInfo: CustomerSimpleInfo) => {
          if (userInfo != null) {
            if (Platform.OS !== 'web') {
              messaging()
                .subscribeToTopic(userInfo.id).then(() => { }).catch((e: Error) => {
                  bugsnag.notify(new Error('[home] -- [handleLoginSuccess] -- [error]: ' + e.toString()));
                });
            }
            // 在这里刷新social token
            dispatch(FetchSocialLogin({ force: true, userInfo }));
            // 成功获取misc信息
            dispatch(FetchMyTraderInfo(userInfo.id));
            // dispatch(FetchMyTraderSetting(userInfo.id));
            if (email) {
              if (!isOpenAuth) {
                dispatch(AccountSaveEmail(email));
              }
              dispatch(TriggerLoginEvent());
            }

            if (mobile && country) {
              if (!isOpenAuth) {
                dispatch(AccountSaveMobile(mobile, country));
              }
            }
          }

          StatusBar.setBarStyle('dark-content');
        }
      }));
      break;
  }
};

export type AccountUserInfoUnionActionType =
  AccountUserInfoRequestActionType
  | AccountUserInfoActionType
  | AccountUserInfoErrorActionType
  | AccountUserInfoResetActionType;
export type FetchSocialLoginThunkActionType = ThunkAction<Promise<void>, void, void, AccountUserInfoUnionActionType>;
export type FetchSocialLoginThunkDispatchType = ThunkDispatch<void, void, AccountUserInfoUnionActionType>;

export type FetchLoginRequestType = {
  email: string,
  mobile: string,
  source: number,
  password: string,
  pTokenType: 'android' | 'web' | 'ios',
  tokenType?: number,
  token: string,
  tokenId: string,
  country: '',
  deviceId: string
};
export function FetchLogin (params: FetchLoginRequestType): FetchSocialLoginThunkActionType {
  return async (dispatch: FetchSocialLoginThunkDispatchType) => {
    dispatch({ type: ActionTypes.ACCOUNT_USER_INFO_REQUEST });
    try {
      const response: LoginResponseType = await CustomerAPI.login(params);
      if (response) {
        if (response.success) {
          // eslint-disable-next-line flowtype/no-weak-types
          const info: ?CustomerSimpleInfo = ((response.obj: any): CustomerSimpleInfo);
          // eslint-disable-next-line flowtype/no-weak-types
          handleLoginSuccess(false, ((dispatch: any): ThunkDispatch<void, {}, LoginSuccessUnionActionType>), response, info, params.email, params.mobile, params.country);
        } else {
          if (response.code === '1022' && response.obj) {
            dispatch({
              type: ActionTypes.ACCOUNT_LOCKED,
              time: response.obj.updateTime
            });
          } else if (response.code === '1021') {
            dispatch({
              type: ActionTypes.ACCOUNT_SUSPENSED
            });
          } else if ((response.code === '1103' || response.code === '1104') && response.obj) {
            // eslint-disable-next-line flowtype/no-weak-types
            const err: LoginError = ((response.obj: any): LoginError)
            dispatch({
              type: ActionTypes.ACCOUNT_USER_INFO_ERROR,
              error: I18n.t(response.msgKey, {
                time: 5 - err.errorCount
              })
            });
          } else {
            // 其他错误， 直接走ACCOUNT_USER_INFO_ERROR统一进行处理
            bugsnag.notify(new Error('login response.code error  : ' + response.msgKey + ', login params: ' + JSON.stringify(params)));

            dispatch({
              type: ActionTypes.ACCOUNT_USER_INFO_ERROR,
              error: I18n.t(response.msgKey || 'login.network.error')
            });
          }
        }
      } else {
        bugsnag.notify(new Error('login response error  : ' + (response && response.msgKey ? response.msgKey : '') + ', login params: ' + JSON.stringify(params)));
        dispatch({
          type: ActionTypes.ACCOUNT_USER_INFO_ERROR,
          error: I18n.t(response.msgKey || 'login.network.error')
        });
      }
    } catch (e) {
      bugsnag.notify(new Error('[home] -- [login] -- [error]: ' + e.toString() + ', login params: ' + JSON.stringify(params)));

      dispatch({
        type: ActionTypes.ACCOUNT_USER_INFO_ERROR,
        error: e.message && (e.message.indexOf('Network request timeout') !== -1 || e.message.indexOf('Network request error') !== -1) ? '' : I18n.t('login.network.error'),
        userLoading: false
      });
    }
  };
}

export type CleanOpenAuthInfoThunkActionType = ThunkAction<Promise<void>, void, void, CleanOpenAuthInfoActionType>;
export type CleanOpenAuthInfoThunkDispatchType = ThunkDispatch<void, void, CleanOpenAuthInfoActionType>;

export function CleanOpenAuthInfo (): CleanOpenAuthInfoThunkActionType {
  return async (dispatch: CleanOpenAuthInfoThunkDispatchType) => {
    // try {
    //   await auth().signOut();
    // } catch (error) {
    //   console.log('CleanOpenAuthInfo error' + error)
    // }
    dispatch({ type: ActionTypes.CLEAN_OPEN_AUTH_INFO });
  };
}

export type OpenAuthLoginThunkActionType = ThunkAction<Promise<void>, StateType, void, AccountUserInfoUnionActionType>;
export type OpenAuthLoginThunkDispatchType = ThunkDispatch<StateType, void, AccountUserInfoUnionActionType>;

/**
 *
 * @param params {idToken, email, emailToken}
 * @param props
 * @returns {function(...[*]=)}
 * @constructor
 */
export function OpenAuthLogin (): OpenAuthLoginThunkActionType {
  return async (dispatch: OpenAuthLoginThunkDispatchType, getState: () => StateType) => {
    const { HomeReducer } = getState();
    const params = {}
    params.idToken = HomeReducer.openAuthInfo?.idToken || '';
    if (HomeReducer.openAuthInfo?.email && HomeReducer.openAuthInfo.token) {
      params.email = HomeReducer.openAuthInfo.email;
      params.token = HomeReducer.openAuthInfo.token;
    }
    if (HomeReducer.openAuthInfo?.password) {
      params.password = HomeReducer.openAuthInfo.password;
    }
    try {
      const response: OauthLoginResponseType = await CustomerAPI.loginOauth(params);
      const openAuthInfo = {}
      openAuthInfo.loading = false;
      if (response) {
        if (response.success && response.obj) {
          openAuthInfo.loginFinished = true;
          // 成功登录， 返回的是simpleInfo
          // eslint-disable-next-line flowtype/no-weak-types
          const info: ?CustomerSimpleInfo = (typeof response.obj === 'object') ? ((response.obj: any): CustomerSimpleInfo) : null;
          //  特殊情况特殊处理
          // eslint-disable-next-line flowtype/no-weak-types
          handleLoginSuccess(true, ((dispatch: any): ThunkDispatch<void, {}, LoginSuccessUnionActionType>), response, info, params.email);
        } else {
          openAuthInfo.error = I18n.t(response.msgKey);
          // eslint-disable-next-line flowtype/no-weak-types
          const err: OauthLoginError = ((response.obj: any): OauthLoginError)
          if (response.code === '1022' && response.obj) {
            dispatch({
              type: ActionTypes.ACCOUNT_LOCKED,
              time: err.updateTime // 1022返回时间
            });
          } else if (response.code === '1021') {
            dispatch({
              type: ActionTypes.ACCOUNT_SUSPENSED
            });
          } else if (response.code === '1105') {
            // customer.login.3rd.token.error， idToken过期或错误
          } else if (response.code === '1106') {
            // customer.login.3rd.email.not.bind 第三方平台邮箱未绑定或未验证
            openAuthInfo.loading = true;
            openAuthInfo.error = 'need.binding.email';
          } else if (response.code === '1107' || response.code === '1103') {
            // customer.login.3rd.email.exist - 1107, 提示请用邮箱登录 emailExisted
            // customer.login.password.error.email - 1103 ,用户密码错误，设置过密码
            openAuthInfo.mustPwdLogin = true;

            // eslint-disable-next-line flowtype/no-weak-types
            const err: OauthLoginError = ((response.obj: any): OauthLoginError)
            const username = err == null ? '' : err.userName;
            const email = (typeof response.obj === 'string') ? response.obj : params.email;
            openAuthInfo.email = email ?? username;

            openAuthInfo.error = I18n.t(response.msgKey, { time: 5 - err.errorCount })
          } else {
            // 其他错误， 直接走ACCOUNT_USER_INFO_ERROR统一进行处理
            dispatch({
              type: ActionTypes.ACCOUNT_USER_INFO_ERROR,
              error: I18n.t(response.msgKey || 'system.error')
            });
          }
        }
      } else {
        openAuthInfo.error = I18n.t(response.msgKey || 'system.error');
        dispatch({
          type: ActionTypes.ACCOUNT_USER_INFO_ERROR,
          error: I18n.t(response.msgKey || 'system.error')
        });
      }

      dispatch(RefreshOpenAuthInfo(openAuthInfo));
    } catch (e) {
      bugsnag.notify(new Error('[home] -- [loginOauth] -- [error]: ' + e.toString()));
      dispatch(CleanOpenAuthInfo());
      dispatch({
        type: ActionTypes.ACCOUNT_USER_INFO_ERROR,
        error: I18n.t('system.error'),
        userLoading: false
      });
    }
  };
}

// 只会在Social登录时触发，保证用户注册流程完成 REFER_INFO
export type TriggerLoginEventThunkActionType = ThunkAction<void, StateType, void, ReferInfoActionType>;
export type TriggerLoginEventThunkDispatchType = ThunkDispatch<StateType, void, ReferInfoActionType>;

export function TriggerLoginEvent (): TriggerLoginEventThunkActionType {
  return (dispatch: TriggerLoginEventThunkDispatchType, getState: () => StateType) => {
    const { HomeReducer } = getState();
    // TODO fix remove direct all AsyncStorage
    AsyncStorage.getItem('kyc_login').then((data: string) => {
      if (data && data === global.USER_ID && HomeReducer.userInfo?.userName) {
        helper.trackLogin();
      } else {
        CustomerAPI.referInfo()
          .then((response: ReferralResponseType) => {
            if (response && response.success && response.obj) {
              const result: Referral = response.obj;
              const referrerId = result.referrerId;
              if (
                referrerId &&
                HomeReducer.userInfo &&
                HomeReducer.userInfo.idCertifiedStatus === 0
              ) {
                helper.trackEvent('KYC LOGIN', { USER_ID: global.USER_ID });
                AsyncStorage.setItem('kyc_login', global.USER_ID);
                dispatch({
                  type: ActionTypes.REFER_INFO,
                  referrerId: result.referrerId
                });
              } else {
                helper.trackLogin();
              }
            } else {
              helper.trackLogin();
            }
          })
          .catch((error: Error) => {
            // TODO fix error handle
            console.log(error);
            bugsnag.notify(new Error('[home] -- [referInfo] -- [error]: ' + error.toString()));
          });
      }
    });
  };
};

export type SetLostSessionThunkActionType = ThunkAction<void, void, void, AccountLostSessionActionType>;
export type SetLostSessionThunkDispatchType = ThunkDispatch<void, void, AccountLostSessionActionType>;

export function SetLostSession (): SetLostSessionThunkActionType {
  return (dispatch: SetLostSessionThunkDispatchType) => {
    dispatch({
      type: ActionTypes.ACCOUNT_LOST_SESSION
    });
  };
};

export type SetRegBonusOpenTimeThunkActionType = ThunkAction<void, void, void, SetRegBonusOpenTimeActionType>;
export type SetRegBonusOpenTimeThunkDispatchType = ThunkDispatch<void, void, SetRegBonusOpenTimeActionType>;

export function SetRegBonusOpenTime (currentTime: string): SetRegBonusOpenTimeThunkActionType {
  return (dispatch: SetRegBonusOpenTimeThunkDispatchType) => {
    dispatch({
      type: ActionTypes.REGBONUS_OPENTIME,
      regBonusOpenTime: currentTime
    });
  };
};

export type SetNoticeSystemThunkActionType = ThunkAction<void, void, void, SetNoticeSystemActionType>;
export type SetNoticeSystemThunkDispatchType = ThunkDispatch<void, void, SetNoticeSystemActionType>;

export function SetNoticeSystem (noticeSystem: string[]): SetNoticeSystemThunkActionType {
  return (dispatch: SetNoticeSystemThunkDispatchType) => {
    dispatch({
      type: ActionTypes.NOTICE_SYSTEM,
      noticeSystem: noticeSystem
    });
  };
};

export type SetNoticeOtcThunkActionType = ThunkAction<void, void, void, SetNoticeOtcActionType>;
export type SetNoticeOtcThunkDispatchType = ThunkDispatch<void, void, SetNoticeOtcActionType>;

export function SetNoticeOtc (noticeOtc: string[]): SetNoticeOtcThunkActionType {
  return (dispatch: SetNoticeOtcThunkDispatchType) => {
    dispatch({
      type: ActionTypes.NOTICE_OTC,
      noticeOtc: noticeOtc
    });
  };
};

export type SetNoticeRewardThunkActionType = ThunkAction<void, void, void, SetNoticeRewardActionType>;
export type SetNoticeRewardThunkDispatchType = ThunkDispatch<void, void, SetNoticeRewardActionType>;

export function SetNoticeReward (noticeReward: string[]): SetNoticeRewardThunkActionType {
  return (dispatch: SetNoticeRewardThunkDispatchType) => {
    dispatch({
      type: ActionTypes.NOTICE_REWARD,
      noticeReward: noticeReward
    });
  };
};

export type SetNoticeAllThunkActionType = ThunkAction<void, void, void, SetNoticeAllActionType>;
export type SetNoticeAllThunkDispatchType = ThunkDispatch<void, void, SetNoticeAllActionType>;

export function SetAllNotice (noticeSystem: string[], noticeOtc: string[], noticeReward: string[]): SetNoticeAllThunkActionType {
  return (dispatch: SetNoticeAllThunkDispatchType) => {
    dispatch({
      type: ActionTypes.NOTICE_ALL,
      noticeReward: noticeReward,
      noticeSystem: noticeSystem,
      noticeOtc: noticeOtc

    });
  };
};

export type AccountSaveUserThunkActionType = ThunkAction<void, void, void, AccountSaveUserActionType>;
export type AccountSaveUserThunkDispatchType = ThunkDispatch<void, void, AccountSaveUserActionType>;

/**
 * 保存登录名
 * @param email
 * @returns {function(AccountSaveUserThunkDispatchType): void}
 * @constructor
 */
export function AccountSaveEmail (email: string): AccountSaveUserThunkActionType {
  return (dispatch: AccountSaveUserThunkDispatchType) => {
    dispatch({
      type: ActionTypes.ACCOUNT_SAVE_USER,
      email
    });
  };
};

/**
 * 保存登录名
 * @param mobile
 * @returns {function(AccountSaveUserThunkDispatchType): void}
 * @constructor
 */
export function AccountSaveMobile (mobile: string, country: string): AccountSaveUserThunkActionType {
  return (dispatch: AccountSaveUserThunkDispatchType) => {
    dispatch({
      type: ActionTypes.ACCOUNT_SAVE_USER_MOBILE,
      mobile,
      country
    });
  };
};

export type OpenAuthInfoUnionActionType =
  RefreshOpenAuthInfoActionType
  | CleanOpenAuthInfoThunkActionType
  | OpenAuthLoginThunkActionType;
export type RefreshOpenAuthInfoThunkActionType = ThunkAction<void, StateType, void, OpenAuthInfoUnionActionType>;
export type RefreshOpenAuthInfoThunkDispatchType = ThunkDispatch<StateType, void, OpenAuthInfoUnionActionType>;

export function RefreshOpenAuthInfo (info: OpenAuthInfoType): RefreshOpenAuthInfoThunkActionType {
  return (dispatch: RefreshOpenAuthInfoThunkDispatchType, getState: () => StateType) => {
    const { HomeReducer } = getState();
    if (info.useOpenAuth === true || !info.error) {
      info.error = ''; // 重新登录，则error清空
    }
    dispatch({
      type: ActionTypes.REFRESH_OPEN_AUTH_INFO,
      info: info
    });
    const idTokenValue = HomeReducer.openAuthInfo?.idToken ?? info.idToken;
    if (
      !(info.useOpenAuth === true) &&
      idTokenValue &&
      !HomeReducer.isLogin &&
      !info.error &&
      !(info.loginFinished === true)
    ) {
      dispatch(OpenAuthLogin());
    }
    const pwdLogin = info.mustPwdLogin ?? HomeReducer.openAuthInfo?.mustPwdLogin;
    if (
      info.error &&
      info.error !== 'need.binding.email' &&
      !(pwdLogin === true)
    ) {
      // 邮箱绑定和密码登录不需要清除open auth info
      // 出现错误或者加载完成，清空openAuth
      dispatch(CleanOpenAuthInfo());
    }
  };
};

export type UserInfoUnionActionType =
  AccountLostSessionActionType
  | AccountUserInfoActionType
  | RefreshOpenAuthInfoThunkActionType
  | FetchAccountInfoThunkActionType;
export type FetchUserInfoThunkActionType = ThunkAction<Promise<void>, StateType, void, UserInfoUnionActionType>;
export type FetchUserInfoThunkDispatchType = ThunkDispatch<StateType, void, UserInfoUnionActionType>;

/**
 * 获取用户信息
 * @param props {
 *   customerInfo已经获取到的customerInfo， 不需要再次获取,
 * }
 * @returns {function(FetchUserInfoThunkDispatchType, function(): StateType): Promise<undefined>}
 * @constructor
 */
export function FetchUserInfo (props: ?{ callback?: ?(CustomerSimpleInfo) => void, isSilent?: boolean, customerInfo?: ?CustomerSimpleInfo }): FetchUserInfoThunkActionType {
  return async (dispatch: FetchUserInfoThunkDispatchType, getState: () => StateType) => {
    // dispatch({ type: types.ACCOUNT_USER_INFO_REQUEST });
    const { HomeReducer } = getState();
    const { userInfo } = HomeReducer;
    // console.log('user info is ' + JSON.stringify(userInfo ?? ''));
    try {
      let customer: ?CustomerSimpleInfo;
      if (props && props.customerInfo) {
        customer = props.customerInfo;
      }
      console.log(' current jwtKey is ' + (customer?.jwtKey || userInfo?.jwtKey || ''));

      // 未传入customerInfo， 主动获取
      if (!customer) {
        const response: CustomerInfoType = await CustomerAPI.customerInfo({ isSilent: (props && props.isSilent) || false, jwtKey: customer?.jwtKey || userInfo?.jwtKey });
        // console.log('用户信息', JSON.stringify(response));
        if (response.msgKey === 'customer.login.required' || response.msgKey === 'customer.login.new') {
          dispatch({ type: ActionTypes.ACCOUNT_LOST_SESSION });
          return;
        }
        if (response.success) {
          if (response.code === '1000' || !response.obj) {
            // 未登录，则需要重新登录，先本地登出
            dispatch({ type: ActionTypes.ACCOUNT_LOST_SESSION });
            // 有错误发生，直接返回
            return;
          } else {
            // 用户信息OK
            if (response.obj) {
              const customerInfo: CustomerInfo = response.obj;
              global.USER_ID = response.obj.id;
              customer = (customerInfo: CustomerSimpleInfo);
            }
          }
        } else {
          dispatch({
            type: ActionTypes.ACCOUNT_USER_INFO_ERROR,
            error: I18n.t(response.msgKey)
          });
          // 有错误发生，直接返回
          return;
        }
      }

      if (customer) {
        try {
          if (Platform.OS !== 'web') {
            const groupValue = remoteConfig()
              .getValue('user_group')
              .asString();
            if (groupValue) {
              const mailSuffix = '@' + customer.email.split('@')[1];
              const customerId = customer.id;
              const customerEmail = customer.email;
              const customerPhone = customer.phone;

              const groupConfig = JSON.parse(groupValue);
              const groups: string[] = Object.keys(groupConfig);
              groups.forEach((group: string, i: number, array: string[]) => {
                const value = groupConfig[group];
                if (
                  value.includes(mailSuffix) ||
                  value.includes(customerId) ||
                  value.includes(customerPhone) ||
                  value.includes(customerEmail)

                ) {
                  analytics().setUserProperty('user_group', group);
                }
              })
            }

            bugsnag.setUser(customer.id, customer.phone, customer.email)
          }
        } catch (e) {
          bugsnag.notify(new Error('[home] -- [setUser] -- [error]: ' + e.toString()));
        }

        dispatch({
          type: ActionTypes.ACCOUNT_USER_INFO,
          email: customer.email,
          userInfo: customer,
          userLoading: false,
          isLogin: true
        });

        if (customer.isMigration) {
          DeviceEventEmitter.emit('isMigration', customer.jwtKey)
        } else {
          dispatch(FetchAccountAssetsAll({
            indexes: [0, 1, 2, 3, 4],
            ignoreError: true
          }));
        }

        const rawInfo = HomeReducer.openAuthInfo;
        if (rawInfo) {
          dispatch(RefreshOpenAuthInfo(Object.assign(rawInfo, { error: null, loading: false })));
        }

        // 登录跳转
        if (props?.callback) {
          props.callback(customer);
        }
      }
    } catch (e) {
      bugsnag.notify(new Error('[home] -- [FetchUserInfo] -- [error]: ' + e.toString()));
      // 获取用户状态导致的网络失败，需要处理掉
      dispatch({
        type: ActionTypes.ACCOUNT_USER_INFO_ERROR,
        error: I18n.t('system.error')
      });
    }
  };
};

export type AccountLogoutUnionActionType =
  AccountLogoutActionType
  | AccountLogoutRequestActionType
  | AccountLogoutErrorActionType
  | AccountLogoutResetActionType;
export type FetchUserLogOutThunkActionType = ThunkAction<void, StateType, void, AccountLogoutUnionActionType>;
export type FetchUserLogOutThunkDispatchType = ThunkDispatch<StateType, void, AccountLogoutUnionActionType>;

export function FetchUserLogOut (): FetchUserLogOutThunkActionType {
  // 请求登出，删除本地数据
  return (dispatch: FetchUserLogOutThunkDispatchType, getState: () => StateType) => {
    dispatch({ type: ActionTypes.ACCOUNT_LOGOUT_REQUEST });
    // do api request
    console.log('logout');

    const { HomeReducer } = getState();
    const { remoteConfig } = HomeReducer;

    try {
      auth().signOut();
    } catch (error) {
      console.log('CleanOpenAuthInfo error' + error)
      bugsnag.notify(new Error('[home] -- [signOut] -- [error]: ' + error.toString()));
    }
    dispatch(CleanOpenAuthInfo());
    dispatch({ type: ActionTypes.ACCOUNT_LOGOUT });
    if (!remoteConfig?.newTradeOpen) {
      DeviceEventEmitter.emit('isNotMigration', true)
    }

    CustomerAPI.logout()
      .then((response: CommonResponse) => {
        // console.log(JSON.stringify(response));
        if (response.success || response.code === '1000' || response.code === '1043') {
          // dispatch({ type: ActionTypes.ACCOUNT_LOGOUT });

          // dispatch(SetCleanUserData());
          // dispatch(SetCleanAccountData());
          // dispatch(SetCleanSocialData());
          // dispatch(SetCleanTraderData());
          // dispatch(FetchSocialLogin()); // 补充social的匿名token
        } else {
          dispatch({
            type: ActionTypes.ACCOUNT_LOGOUT_ERROR,
            error: 'system.error'
          });
        }
      })
      .catch((err: Error) => {
        bugsnag.notify(new Error('[home] -- [logout] -- [error]: ' + err.toString()));
        dispatch({
          type: ActionTypes.ACCOUNT_LOGOUT_ERROR,
          error: err
        });
      });
  };
};

export type FetchRemoteConfigThunkActionType = ThunkAction<void, void, void, RemoteConfigActionType>;
export type FetchRemoteConfigThunkDispatchType = ThunkDispatch<void, void, RemoteConfigActionType>;

export function FetchRemoteConfig (): FetchRemoteConfigThunkActionType {
  return (dispatch: FetchRemoteConfigThunkDispatchType) => {
    const switchConfig = remoteConfig()
      .getValue('common_switch')
      .asString();
    // console.log('remoteConfig fetched common_switch is ' + switchConfig);
    if (switchConfig) {
      try {
        const switchJson = JSON.parse(switchConfig);
        const keys: string[] = Object.keys(switchJson);
        const config = {};
        keys.forEach((k: string, index: number) => {
          const v = switchJson[k];
          if (v === 'true') {
            config[k] = true;
          } else if (v === 'false') {
            config[k] = false;
          } else {
            config[k] = v;
          }
        })
        dispatch({
          type: ActionTypes.REMOTE_CONFIG,
          remoteConfig: config
        });
      } catch (error) {
        console.log('json parse error' + error.toString())
        bugsnag.notify(new Error('[home] -- [FetchRemoteConfig] -- [error]: ' + error.toString()));
      }
    }
  };
};

export type FetchBannerConfigThunkActionType = ThunkAction<void, void, void, BannerConfigActionType>;
export type FetchBannerConfigThunkDispatchType = ThunkDispatch<void, void, BannerConfigActionType>;

export function FetchBannerConfig (languageCode: string): FetchBannerConfigThunkActionType {
  return (dispatch: FetchBannerConfigThunkDispatchType) => {
    const bannerKey = 'banner_' + languageCode.toLowerCase();
    const bannerConfig = remoteConfig()
      .getValue(bannerKey)
      .asString();
    if (bannerConfig) {
      try {
        dispatch({
          type: ActionTypes.BANNER_CONFIG,
          bannerConfig: JSON.parse(bannerConfig)
        });
      } catch (error) {
        console.log('json parse error' + error.toString())
        bugsnag.notify(new Error('[home] -- [FetchBannerConfig] -- [error]: ' + error.toString()));
      }
    } else {
      dispatch({
        type: ActionTypes.BANNER_OFFLINE_CONFIG,
        languageCode: languageCode
      });
    }
  };
};

export type FetchEventCenterThunkActionType = ThunkAction<void, void, void, EventCenterActionType>;
export type FetchEventCenterThunkDispatchType = ThunkDispatch<void, void, EventCenterActionType>;

export function FetchEventCenterConfig (languageCode: string): FetchEventCenterThunkActionType {
  return (dispatch: FetchEventCenterThunkDispatchType) => {
    const _key = 'event_center_' + languageCode.toLowerCase();
    const _eventCenterConfig = remoteConfig()
      ?.getValue(_key)
      ?.asString();
    if (_eventCenterConfig) {
      try {
        dispatch({
          type: ActionTypes.EVENTCENTER_CONFIG,
          eventCenterConfig: JSON.parse(_eventCenterConfig)
        });
      } catch (error) {
        console.log('json parse error' + error.toString())
        bugsnag.notify(new Error('[home] -- [FetchEventCenterConfig] -- [error]: ' + error.toString()));
      }
    }
  };
};

export type FetchVipEventCenterThunkActionType = ThunkAction<void, void, void, EventCenterActionType>;
export type FetchVipEventCenterThunkDispatchType = ThunkDispatch<void, void, EventCenterActionType>;

export function FetchVIPEventCenterConfig (languageCode: string): FetchVipEventCenterThunkActionType {
  return (dispatch: FetchVipEventCenterThunkDispatchType) => {
    const _key = 'vip_event_center_' + languageCode.toLowerCase();
    const _eventVIPCenterConfig = remoteConfig()
      .getValue(_key)
      .asString();
    if (_eventVIPCenterConfig) {
      try {
        dispatch({
          type: ActionTypes.VIP_EVENTCENTER_CONFIG,
          eventVIPCenterConfig: JSON.parse(_eventVIPCenterConfig)
        });
      } catch (error) {
        console.log('json parse error' + error.toString())
        bugsnag.notify(new Error('[home] -- [FetchVIPEventCenterConfig] -- [error]: ' + error.toString()));
      }
    }
  };
};

export type FetchRegTrialFundThunkActionType = ThunkAction<void, void, void, RegTrialFundActionType>;
export type FetchRegTrialFundThunkDispatchType = ThunkDispatch<void, void, RegTrialFundActionType>;

export function FetchRegTrialFundConfig (languageCode: string): FetchRegTrialFundThunkActionType {
  return (dispatch: FetchRegTrialFundThunkDispatchType) => {
    const _key = 'reg_trial_fund_' + languageCode.toLowerCase();
    const _regTrialFundConfig = remoteConfig()
      .getValue(_key)
      .asString();
    if (_regTrialFundConfig) {
      try {
        dispatch({
          type: ActionTypes.REG_TRIAL_FUND_CONFIG,
          regTrialFundConfig: JSON.parse(_regTrialFundConfig)
        });
      } catch (error) {
        console.log('json parse error' + error.toString())
        bugsnag.notify(new Error('[home] -- [FetchRegTrialFundConfig] -- [error]: ' + error.toString()));
      }
    }
  };
};

export type FetchRegBonusConfigThunkActionType = ThunkAction<void, void, void, RegBonusConfigActionType>;
export type FetchRegBonusConfigThunkDispatchType = ThunkDispatch<void, void, RegBonusConfigActionType>;

export function FetchRegBonusConfig (): FetchRegBonusConfigThunkActionType {
  return (dispatch: FetchRegBonusConfigThunkDispatchType) => {
    const _key = 'reg_bonus_config'
    const _regBonusConfig = remoteConfig()
      .getValue(_key)
      .asString();
    if (_regBonusConfig) {
      try {
        dispatch({
          type: ActionTypes.REG_BONUS_CONFIG,
          regBonusConfig: JSON.parse(_regBonusConfig)
        });
      } catch (e) {
        bugsnag.notify(new Error('[home] -- [FetchRegBonusConfig] -- [error]: ' + e.toString()));
      }
    }
  };
};

export type FetchDiscoverConfigThunkActionType = ThunkAction<void, void, void, DiscoverConfigActionType>;
export type FetchDiscoverConfigThunkDispatchType = ThunkDispatch<void, void, DiscoverConfigActionType>;

export function FetchDiscoverConfig (): FetchDiscoverConfigThunkActionType {
  return (dispatch: FetchDiscoverConfigThunkDispatchType) => {
    const _key = 'discover'
    const _discoverConfig = remoteConfig()
      .getValue(_key)
      .asString();
    if (_discoverConfig) {
      try {
        dispatch({
          type: ActionTypes.DISCOVER_CONFIG,
          discoverConfig: JSON.parse(_discoverConfig)
        });
      } catch (e) {
        bugsnag.notify(new Error('[home] -- [FetchDiscoverConfig] -- [error]: ' + e.toString()));
      }
    }
  };
};

export type FetchOfflineBannerConfigThunkActionType = ThunkAction<void, void, void, BannerConfigActionType>;
export type FetchOfflineBannerConfigThunkDispatchType = ThunkDispatch<void, void, BannerConfigActionType>;

export function FetchOfflineBannerConfig (languageCode: string): FetchOfflineBannerConfigThunkActionType {
  return (dispatch: FetchOfflineBannerConfigThunkDispatchType) => {
    dispatch({
      type: ActionTypes.BANNER_OFFLINE_CONFIG,
      languageCode: languageCode
    });
  };
};

export type FetchGiftCardConfigThunkActionType = ThunkAction<void, void, void, GiftCardConfigActionType>;
export type FetchGiftCardConfigThunkDispatchType = ThunkDispatch<void, void, GiftCardConfigActionType>;

export function FetchGiftCardConfig (languageCode: string): FetchGiftCardConfigThunkActionType {
  return (dispatch: FetchGiftCardConfigThunkDispatchType) => {
    const giftCardKey = 'giftcard_ph_' + languageCode.toLowerCase();
    const giftCardConfig = remoteConfig()
      .getValue(giftCardKey)
      .asString();
    if (giftCardConfig) {
      try {
        dispatch({
          type: ActionTypes.GIFTCARD_CONFIG,
          giftCardConfig: JSON.parse(giftCardConfig)
        });
      } catch (error) {
        console.log('json parse error' + error.toString())
        bugsnag.notify(new Error('[home] -- [FetchGiftCardConfig] -- [error]: ' + error.toString()));
      }
    }
  };
};

export type FetchFiatDepositConfigThunkActionType = ThunkAction<void, void, void, FiatDepositConfigActionType>;
export type FetchFiatDepositConfigThunkDispatchType = ThunkDispatch<void, void, FiatDepositConfigActionType>;

export function FetchFiatDepositConfig (languageCode: string): FetchFiatDepositConfigThunkActionType {
  return (dispatch: FetchFiatDepositConfigThunkDispatchType) => {
    const fiatDepositKey = 'fiat_deposit_' + languageCode.toLowerCase();
    const fiatDepositConfig = remoteConfig()
      .getValue(fiatDepositKey)
      .asString();
    if (fiatDepositConfig) {
      try {
        dispatch({
          type: ActionTypes.FIATDEPOSIT_CONFIG,
          fiatDepositConfig: JSON.parse(fiatDepositConfig)
        });
      } catch (error) {
        console.log('json parse error' + error.toString())
        bugsnag.notify(new Error('[home] -- [FetchFiatDepositConfig] -- [error]: ' + error.toString()));
      }
    }
  };
};

export type FetchVersionConfigThunkActionType = ThunkAction<void, void, void, VersionConfigActionType>;
export type FetchVersionConfigThunkDispatchType = ThunkDispatch<void, void, VersionConfigActionType>;

export function FetchVersionConfig (languageCode: string): FetchVersionConfigThunkActionType {
  return (dispatch: FetchVersionConfigThunkDispatchType) => {
    const appVersionKey = 'app_version_' + languageCode.toLowerCase();
    const appVersionConfig = remoteConfig()
      .getValue(appVersionKey)
      .asString();
    try {
      const platformConfig = JSON.parse(appVersionConfig)[Platform.OS];
      if (platformConfig) {
        dispatch({
          type: ActionTypes.VERSION_CONFIG,
          appVersionConfig: platformConfig
        });
      }
    } catch (e) {
      console.log(e);
      bugsnag.notify(new Error('[home] -- [FetchVersionConfig] -- [error]: ' + e.toString()));
    }
  };
};

export type SetFirstUpdateShowThunkActionType = ThunkAction<void, void, void, SetFirstUpdateShowActionType>;
export type SetFirstUpdateShowThunkDispatchType = ThunkDispatch<void, void, SetFirstUpdateShowActionType>;

export function SetFirstUpdateShow (value: boolean): SetFirstUpdateShowThunkActionType {
  return (dispatch: SetFirstUpdateShowThunkDispatchType) => {
    dispatch({
      type: ActionTypes.FIRST_UPDATE_SHOW,
      firstUpdateShow: value
    });
  };
};

export type PromptedVersionInfoThunkActionType = ThunkAction<void, void, void, PromptedVersionInfoActionType>;
export type PromptedVersionInfoThunkDispatchType = ThunkDispatch<void, void, PromptedVersionInfoActionType>;

export function SetPromptedVersionInfo (version: string, isNextVersionShow: boolean): PromptedVersionInfoThunkActionType {
  return (dispatch: PromptedVersionInfoThunkDispatchType) => {
    dispatch({
      type: ActionTypes.PROMPTED_VERSION_INFO,
      promptedVersionInfo: { version, isNextVersionShow }
    });
  };
};

export type FetchVsDataConfigThunkActionType = ThunkAction<void, void, void, VsDataConfigActionType>;
export type FetchVsDataConfigThunkDispatchType = ThunkDispatch<void, void, VsDataConfigActionType>;
export function FetchVSDataConfig (): FetchVsDataConfigThunkActionType {
  return (dispatch: FetchVsDataConfigThunkDispatchType) => {
    ExploreDataAPI.getDataList()
      .then((response: GetDataListResponseType) => {
        const result = response.result.items;
        const data = result.filter((item: Item | VsItem, index: number): boolean => {
          return item.type === 'vs';
        });
        dispatch({
          type: ActionTypes.VSDATA_CONFIG,
          vsDataConfig: data.slice(0, 3)
        });
      })
      .catch((error: Error) => {
        console.log(error);
        bugsnag.notify(new Error('[home] -- [FetchVSDataConfig] -- [error]: ' + error.toString()));
      });
  };
};

export type SetCleanUserDataThunkActionType = ThunkAction<void, void, void, AccountUserInfoCleanActionType>;
export type SetCleanUserDataThunkDispatchType = ThunkDispatch<void, void, AccountUserInfoCleanActionType>;

export function SetCleanUserData (): SetCleanUserDataThunkActionType {
  return (dispatch: SetCleanUserDataThunkDispatchType) => {
    dispatch({ type: ActionTypes.ACCOUNT_USER_INFO_CLEAN });
  };
};

export type SetLanguageThunkActionType = ThunkAction<void, void, void, I18nLangActionType>;
export type SetLanguageThunkDispatchType = ThunkDispatch<void, void, I18nLangActionType>;

export function SetLanguage (languageCode: string): SetLanguageThunkActionType {
  return (dispatch: SetLanguageThunkDispatchType) => {
    dispatch({
      type: ActionTypes.I18N_LANG,
      languageCode: languageCode
    });
  };
};

export type FetchGuideBeginnersConfigThunkActionType = ThunkAction<void, void, void, GuideBeginnersConfigActionType>;
export type FetchGuideBeginnersConfigThunkDispatchType = ThunkDispatch<void, void, GuideBeginnersConfigActionType>;

export function FetchGuideBeginnersConfig (languageCode: string): FetchGuideBeginnersConfigThunkActionType {
  return (dispatch: FetchGuideBeginnersConfigThunkDispatchType) => {
    const language = languageCode === 'en' ? 'en' : 'hk';
    const guideBeginnersKey = 'guide_for_beginners_' + language;
    const guideBeginnersConfig = remoteConfig()
      .getValue(guideBeginnersKey)
      .asString();
    if (guideBeginnersConfig) {
      try {
        dispatch({
          type: ActionTypes.GUIDEBEGINNERS_CONFIG,
          guideBeginnersConfig: JSON.parse(guideBeginnersConfig)
        });
      } catch (error) {
        console.log('json parse error' + error.toString())
        bugsnag.notify(new Error('[home] -- [FetchGuideBeginnersConfig] -- [error]: ' + error.toString()));
      }
    }
  };
};

export type FetchDepositConfigThunkActionType = ThunkAction<Promise<void>, StateType, void, DepositConfigActionType>;
export type FetchDepositConfigThunkDispatchType = ThunkDispatch<StateType, void, DepositConfigActionType>;

export function FetchDepositConfig (): FetchDepositConfigThunkActionType {
  return async (dispatch: FetchDepositConfigThunkDispatchType, getState: () => StateType) => {
    const { HomeReducer } = getState();
    let depositStatus = 'toLogin';
    if (HomeReducer.isLogin) {
      depositStatus = 'toKYC';
      try {
        const kycResponse = await KycAPI.kycResult(HomeReducer.userInfo?.isMigration === 1);
        if (kycResponse) {
          if (kycResponse.success) {
            if (kycResponse.obj && kycResponse.obj.idCertifiedStatus === 2) {
              depositStatus = 'toDeposit';
              try {
                const depositResponse = await AccountAPI.checkDeposit();
                if (depositResponse.success && depositResponse.obj) {
                  depositStatus = 'depositFinish';
                }
              } catch (error) {
                console.log(error)
                bugsnag.notify(new Error('[home]--[AccountAPI.checkDeposit]--error:' + error.toString()));
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
        bugsnag.notify(new Error('[home]--[KycAPI.kycResult]--error:' + error.toString()));
      }
    }
    dispatch({
      type: ActionTypes.DEPOSIT_CONFIG,
      depositStatus: depositStatus
    });
  };
};

// ////  Last export the HomeThunkActionType, MUST be LAST
export type HomeThunkActionsType =
  SetLanguageThunkActionType
  | SetCleanUserDataThunkActionType
  |
  FetchGiftCardConfigThunkActionType
  | FetchBannerConfigThunkActionType
  | FetchRemoteConfigThunkActionType
  | AccountSaveUserThunkActionType
  | FetchUserLogOutThunkActionType
  | FetchUserInfoThunkActionType
  | RefreshOpenAuthInfoThunkActionType
  |
  SetLostSessionThunkActionType
  | TriggerLoginEventThunkActionType
  | OpenAuthLoginThunkActionType
  |
  CleanOpenAuthInfoThunkActionType
  | FetchSocialLoginThunkActionType
  | FetchVsDataConfigThunkActionType
  | FetchBannerConfigThunkActionType
  | FetchRemoteConfigThunkActionType
  | FetchEventCenterThunkActionType
  | FetchVipEventCenterThunkActionType
  | FetchFiatDepositConfigThunkActionType
  | FetchGiftCardConfigThunkActionType
  | SetNoticeSystemThunkActionType
  | SetNoticeOtcThunkActionType
  | SetNoticeRewardThunkActionType;
