// @flow
import React, { Component } from 'react';
import type { Element } from 'react';

import { AppState, DeviceEventEmitter, View } from 'react-native';

import Orientation from 'react-native-orientation';
import VideoPlayer from 'react-native-video-player';
import type { CombinedReducerType, DispatchProps } from '../reducers';
// import { string } from 'prop-types';

const player = {}
const videoView = {}
const videoInstance = {}
DeviceEventEmitter.addListener('videoNormalScreen', function (videoData: VideoDataType) {
  console.log('videoNormalScreen linsten  Data' + JSON.stringify(videoData))
  const that = videoInstance[getActiveRouteName() + '_' + videoData.videoId]
  if (videoData.event === 'outFullScreen') {
    that && that.setState({
      muted: false,
      isPlaying: videoData.isPlaying
    })

    player[getActiveRouteName() + '_' + videoData.videoId] && player[getActiveRouteName() + '_' + videoData.videoId].seek(videoData.currentTime || 0);
  }

  if (videoData.event === 'closeFullScreen') {
    if (!videoData.isStarted) {
      player[getActiveRouteName() + '_' + videoData.videoId] && player[getActiveRouteName() + '_' + videoData.videoId].seek(0)
      that && that.setState({
        isStarted: false,
        currentTime: 0,
        isPlaying: false,
        muted: false
      })
    }
  }
});

// const getActiveRouteName: () => (activeRouteName: string) => void = (): (activeRouteName: string) => void => {
function getActiveRouteName (): string {
  if (!Object.keys(videoInstance).length) return ''
  const _instanceKey = Object.keys(videoInstance).pop()
  const activeRouteName = videoInstance[_instanceKey].props.activeRouteName
  return activeRouteName
}

type VideoDataType = {
  videoSource: string,
  videoThumbnail: string,
  currentTime: number,
  videoId: string,
  videoWidth: number,
  videoHeight: number,
  id: string,
  width: number,
  height: number,
  event: string,
  isPlaying: boolean,
  isStarted: boolean
};

declare function setCurrentVideo(item: VideoDataType): void;
declare function videoCallback():void;
type OwnPropType = {
  type: string,
  itemModel: {
    id: string
  },
  setCurrentVideo: typeof setCurrentVideo,
  activeVideoId: string
};
type StateType = {
  value: string,
  video: {
    width: number,
    height: number,
    duration: number
  },
  thumbnail: string,
  currentTime: number,
  isPlaying: boolean,
  isStarted: boolean,
  muted: boolean,
  orientation: string
};

type ProsType = OwnPropType & CombinedReducerType & DispatchProps;

export default class VideoComponent extends Component <ProsType, StateType> {
  constructor (props: ProsType) {
    super(props);

    this.state = {
      value: '',
      video: { width: undefined, height: undefined, duration: undefined },
      thumbnail: '',
      currentTime: 0,
      isPlaying: false,
      isStarted: false,
      muted: false,
      orientation: 'portrait'
    }
  }

  _orientationDidChange: (orientation: string) => void = (orientation: string) => {
    this.setState({
      orientation: orientation.toLowerCase()
    })
    if (orientation === 'LANDSCAPE') {
      // do something with landscape layout
    } else {
      // do something with portrait layout
    }
  };

  componentDidMount: () => void = () => {
    const that = this;
    const { itemModel, activeVideoId, type } = this.props;
    that.setState({
      type: type,
      itemModel: itemModel,
      activeVideoId: activeVideoId
    })
    videoInstance[type + '_' + that.props.itemModel?.id] = that

    DeviceEventEmitter.removeListener('visibleAreaChange')

    Orientation.addSpecificOrientationListener(this._orientationDidChange);

    AppState.addEventListener('change', this._handleAppStateChange);

    this.props.navigation.addListener(
      'didBlur',
      () => {
        this.setState({
          isPlaying: false,
          currentTime: 0
        })
      }
    );
  };

  _handleAppStateChange: (nextAppState: string) => void = (nextAppState: string) => {
    if (nextAppState.match(/inactive|background/)) {
      this.props.setCurrentVideo && this.props.setCurrentVideo({ id: '' })
    }
  };

  onToggleFullScreen: (videoData: VideoDataType) => void = (videoData: VideoDataType) => {
    console.log('whz---onToggleFullScreen---')
    const { activeVideoId } = this.props
    // console.log(this.state)
    // console.log(videoData)
    const isPlayingPrev = this.state.isPlaying && activeVideoId === videoData.id;
    this.setState({
      muted: true,
      isPlaying: false
    }, function () {
      const emitData = {
        event: 'fullscreen',
        currentTime: (this.state.currentTime || 0) + 1,
        isPlaying: isPlayingPrev
      }
      if (!isPlayingPrev) {
        /* $FlowFixMe */
        emitData.videoSource = videoData.videoSource
        /* $FlowFixMe */
        emitData.videoThumbnail = videoData.videoThumbnail
        /* $FlowFixMe */
        emitData.videoId = videoData.id
        // videoSource:videoData.videoSource,
        // videoThumbnail:videoData.videoThumbnail,
      }

      DeviceEventEmitter.emit('videoFullScreen', emitData)
    })
  };

  onProgress: (event: {currentTime: string}) => void = (event: {currentTime: string}) => {
    // console.log(" onProgress " + event.currentTime)
    this.setState({
      currentTime: event.currentTime || 0
    })
  };

  start: () => void = () => {
    this.setState({
      isStarted: true,
      isPlaying: true
    })
  };

  pause: () => void = () => {
    this.setState({
      isStarted: false
    })
  };

  onStart: (videoData: VideoDataType) => void = (videoData: VideoDataType) => {
    console.log('whz---onstart---');
    const that = this;

    if (videoData) {
      this.props.setCurrentVideo && this.props.setCurrentVideo(videoData)
      DeviceEventEmitter.emit('videoFullScreen', {
        event: 'start',
        videoSource: videoData.videoSource,
        videoThumbnail: videoData.videoThumbnail,
        currentTime: that.state.currentTime || 0,
        videoId: videoData.id,
        videoWidth: videoData.width,
        videoHeight: videoData.height
      })
    }
  };

  onPlayPress: (isPlaying: boolean, videoData: VideoDataType) => void = (isPlaying: boolean, videoData: VideoDataType) => {
    this.setState({
      isPlaying: isPlaying
    })
    // DeviceEventEmitter.emit("videoFullScreen", {event: isPlaying ? "play" : "pause"})
    this.props.setCurrentVideo && this.props.setCurrentVideo(videoData)
  };

  onToggleMuted: (isMuted: boolean, callback: typeof videoCallback) => void = (isMuted: boolean, callback: typeof videoCallback) => {
    this.setState({
      muted: isMuted
    }, function () {
      callback && callback()
    })
  };

  onTogglePlaying: (isPlaying: boolean, callback: typeof videoCallback) => void = (isPlaying: boolean, callback: typeof videoCallback) => {
    this.setState({
      isPlaying: isPlaying
    }, function () {
      callback && callback()
    })
  };

  onToggleIsStarted: (isStarted: boolean, callback: typeof videoCallback) => void = (isStarted: boolean, callback: typeof videoCallback) => {
    this.setState({
      isStarted: isStarted
    }, function () {
      callback && callback()
    })
  };

  render (): Element<*> {
    const { isPlaying, muted, isStarted, itemModel, type } = this.state;
    const { activeVideoId } = this.props;
    const videoData = itemModel;
    const videoId = videoData?.id;
    const isCurrentActiveVideo = activeVideoId === videoId
    if (!videoData) return <View />;
    console.log('whz----videoData----', videoData);
    return (
      <View
        ref={(view: React$ElementRef<React$ElementType>) => { videoView[type + '_' + videoId] = view }}

        renderToHardwareTextureAndroid
      >
        <VideoPlayer
          endWithThumbnail
          videoId={videoId}
          video={{ uri: videoData?.videoSource }}
          thumbnail={{ isStatic: true, uri: videoData?.videoThumbnail }}
          fullscreenVideo={false}
          isPlaying={isCurrentActiveVideo && isPlaying}
          isStarted={isStarted}
          muted={isCurrentActiveVideo && muted}
          onPlayPress={(isPlaying: boolean) => {
            console.log('whz-----onPlayPress----')
            this.onPlayPress(isPlaying, videoData)
          }}
          customStyles={{

            // thumbnail: {
            //   width: videoData.width,
            //   height: videoData.height
            // }

          }}

          onStart={() => {
            console.log('whz-----onStart----')
            this.onStart(videoData)
          }}
          onToggleFullScreen={() => {
            this.onToggleFullScreen(videoData)
          }}
          onToggleIsStarted={this.onToggleIsStarted}
          pauseOnPress
          onProgress={this.onProgress}
          onToggleMuted={this.onToggleMuted}
          ref={(r: React$ElementRef<React$ElementType>) => { player[type + '_' + videoId] = r }}
          onTogglePlaying={this.onTogglePlaying}
        />
      </View>
    )
  }
}
