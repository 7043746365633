// @flow

const Data = {
  vsDataConfig: [
    {
      type: 'vs',
      symbol: '^HSI',
      title: {
        en: 'BTC VS HSI\nGrow\n${rate}',
        hk: 'BTC VS 恆生指數\n多增長\n${rate}',
        zh: 'BTC VS 恒生指数\n多增长\n${rate}'
      },
      title_args: {
        en: {
          rate: '+870.85%'
        },
        hk: {
          rate: '+870.85%'
        },
        zh: {
          rate: '+870.85%'
        }
      },
      description: {
        en: 'One year ago, if you use ${expense}USD to invest:\nIn ${coin0}, you will have ${value0}USD, ${rate0} growth\nIn ${coin1}, you will have ${value1}USD, ${rate1} growth',
        hk: '如果一年前，你花${expense}投資:\n買${coin0}， 你現在會有${value0}美元，漲幅達${rate0}\n買${coin1}，你現在會有${value1}美元，漲幅達${rate1}',
        zh: '如果一年前，你花${expense}投资:\n买${coin0}, 你现在会有${value0}美元，涨幅达${rate0}\n买${coin1}，你现在会有${value1}美元，涨幅达${rate1}'
      },
      description_args: {
        en: {
          expense: '100',
          coin0: 'BTC',
          value0: '989.78',
          rate0: '+889.78%',
          coin1: 'HSI',
          value1: '118.94',
          rate1: '+18.94%'
        },
        hk: {
          expense: '100 美元',
          coin0: 'BTC',
          value0: '989.78',
          rate0: '+889.78%',
          coin1: '恆生指數',
          value1: '118.94',
          rate1: '+18.94%'
        },
        zh: {
          expense: '100 美元',
          coin0: 'BTC',
          value0: '989.78',
          rate0: '+889.78%',
          coin1: '恒生指数',
          value1: '118.94',
          rate1: '+18.94%'
        }
      },
      code: 'f20QFUtD'

    },
    {
      type: 'vs',
      symbol: '^TWII',
      title: {
        en: 'BTC VS TAIEX\nGrow\n${rate}',
        hk: 'BTC VS 台灣加權指數\n多增長\n${rate}',
        zh: 'BTC VS 台湾加权指数\n多增长\n${rate}'
      },
      title_args: {
        en: {
          rate: '+835.17%'
        },
        hk: {
          rate: '+835.17%'
        },
        zh: {
          rate: '+835.17%'
        }
      },
      description: {
        en: 'One year ago, if you use ${expense}USD to invest:\nIn ${coin0}, you will have ${value0}USD, ${rate0} growth\nIn ${coin1}, you will have ${value1}USD, ${rate1} growth',
        hk: '如果一年前，你花${expense}投資:\n買${coin0}, 你現在會有${value0}美元，漲幅達${rate0}\n買${coin1}，你現在會有${value1}美元，漲幅達${rate1}',
        zh: '如果一年前，你花${expense}投资:\n买${coin0}, 你现在会有${value0}美元，涨幅达${rate0}\n买${coin1}，你现在会有${value1}美元，涨幅达${rate1}'
      },
      description_args: {
        en: {
          expense: '100',
          coin0: 'BTC',
          value0: '989.78',
          rate0: '+889.78%',
          coin1: 'TAIEX',
          value1: '154.62',
          rate1: '+54.62%'
        },
        hk: {
          expense: '100 美元',
          coin0: 'BTC',
          value0: '989.78',
          rate0: '+889.78%',
          coin1: '台灣加權指數',
          value1: '154.62',
          rate1: '+54.62%'
        },
        zh: {
          expense: '100 美元',
          coin0: 'BTC',
          value0: '989.78',
          rate0: '+889.78%',
          coin1: '台湾加权指数',
          value1: '154.62',
          rate1: '+54.62%'
        }
      },
      code: 'KSf35EwU'

    },
    {
      type: 'vs',
      symbol: '^NDX',
      title: {
        en: 'BTC VS NASDAQ\nGrow\n${rate}',
        hk: 'BTC VS 納斯達克指數\n多增長\n${rate}',
        zh: 'BTC VS 纳斯达克指数\n多增长\n${rate}'
      },
      title_args: {
        en: {
          rate: '+835.95%'
        },
        hk: {
          rate: '+835.95%'
        },
        zh: {
          rate: '+835.95%'
        }
      },
      description: {
        en: 'One year ago, if you use ${expense}USD to invest:\nIn ${coin0}, you will have ${value0}USD, ${rate0} growth\nIn ${coin1}, you will have ${value1}USD, ${rate1} growth',
        hk: '如果一年前，你花${expense}投資:\n買${coin0}， 你現在會有${value0}美元，漲幅達${rate0}\n買${coin1}，你現在會有${value1}美元，漲幅達${rate1}',
        zh: '如果一年前，你花${expense}投资:\n买${coin0}, 你现在会有${value0}美元，涨幅达${rate0}\n买${coin1}，你现在会有${value1}美元，涨幅达${rate1}'
      },
      description_args: {
        en: {
          expense: '100',
          coin0: 'BTC',
          value0: '989.78',
          rate0: '+889.78%',
          coin1: 'NASDAQ',
          value1: '153.83',
          rate1: '+53.83%'
        },
        hk: {
          expense: '100 美元',
          coin0: 'BTC',
          value0: '989.78',
          rate0: '+889.78%',
          coin1: '納斯達克指數',
          value1: '153.83',
          rate1: '+53.83%'
        },
        zh: {
          expense: '100 美元',
          coin0: 'BTC',
          value0: '989.78',
          rate0: '+889.78%',
          coin1: '纳斯达克指数',
          value1: '153.83',
          rate1: '+53.83%'
        }
      },
      code: '3VgpleQ9'

    }
  ],
  marketItems1: [
    {
      coinCode: 'BTC_USD'
    },
    {
      coinCode: 'ETH_USD'
    },
    {
      coinCode: 'USDT_USD'
    },
    {
      coinCode: 'XRP_USD'
    },
    {
      coinCode: 'BCH_USD'
    }
  ],
  marketItems: [{
    coinCode: 'BTC_HKD'
  },
  {
    coinCode: 'ETH_HKD'
  },
  {
    coinCode: 'USDT_HKD'
  },
  {
    coinCode: 'XRP_HKD'
  },
  {
    coinCode: 'BCH_HKD'
  },
  {
    coinCode: 'BTC_USD'
  },
  {
    coinCode: 'ETH_USD'
  },
  {
    coinCode: 'USDT_USD'
  },
  {
    coinCode: 'XRP_USD'
  },
  {
    coinCode: 'BCH_USD'
  }
  ],
  bannerConfig: {
    en: [

      {
        image: require('./images/Referrel_Banner_EN.jpeg'),
        title: '',
        url: 'kikitrade://InviteFriend//'
      }

    ],
    hk: [

      {
        image: require('./images/REFERRAL_BANNER_HK.jpeg'),
        title: '',
        url: 'kikitrade://InviteFriend//'
      }

    ],
    zh: [

      {
        image: require('./images/REFERRAL_BANNER_ZH.png'),
        title: '',
        url: 'kikitrade://InviteFriend//'
      }

    ]
  },
  discoverConfig: {
    sort: [
      'hotCoin',
      'newCoin',
      'event',
      'tag',
      'theme',
      'data',
      'peopleToFollow',
      'social',
      'kol'
    ],
    hotCoinNum: 8,
    newCoinNum: 8,
    peopleToFollowNum: 8,
    tagNum: 8,
    socialNum: 3,
    /* $FlowFixMe */
    kol: []
  }
}

export default Data;
