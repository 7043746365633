import { getValue } from "@firebase/remote-config";

const remoteConfigWeb = function(){


	return {
	  getValue: (str)=>{
	  	console.log(window.remoteConfig)
	  	return getValue(window.remoteConfig, str)
	  }
	}

}
export default remoteConfigWeb
