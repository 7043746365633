/**
 * @flow
 */
import { Dimensions, Platform } from 'react-native';
const WIDTH = Dimensions.get('window').width;

/**
 * 将UI给的像素值(切图上的px值)，转化为当前设备需要的数值
 * elemSize * (layoutWidthSize / designWidthSize )
 * @param n
 * @returns {number}
 */

// eslint-disable-next-line flowtype/no-weak-types
export default function p (n: number): any {
  // const WIDTH = Dimensions.get('window').width;
  // return Math.round((n) * (PixelRatio.getPixelSizeForLayoutSize(WIDTH) / PixelRatio.get()) / 750);

  // console.log(Math.round((n) * (WIDTH / 750)))
  // console.log(Math.round((n) * (PixelRatio.getPixelSizeForLayoutSize(WIDTH) / PixelRatio.get()) / 750))
  let displayPX = Math.round(n * (WIDTH / 750));

  if (Platform.OS === 'android' && displayPX < 1) {
    return 1;
  }
  if (Platform.OS === 'web') {
    displayPX = displayPX + 'rem'
  }

  return displayPX;
}

export function ph (n: number): number {
  // const WIDTH = Dimensions.get('window').width;
  // return Math.round((n) * (PixelRatio.getPixelSizeForLayoutSize(WIDTH) / PixelRatio.get()) / 750);
  const HEIGHT = Dimensions.get('window').height;
  // console.log(Math.round((n) * (WIDTH / 750)))
  // console.log(Math.round((n) * (PixelRatio.getPixelSizeForLayoutSize(WIDTH) / PixelRatio.get()) / 750))
  const displayPY = Math.round(n * (HEIGHT / 1624));
  if (Platform.OS === 'android' && displayPY < 1) {
    return 1;
  }
  return displayPY;
}
