// @flow
import moment from 'moment';
import { Dimensions, PermissionsAndroid, Platform, StatusBar } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import bigDecimal from 'js-big-decimal';
import Analytics from 'appcenter-analytics';
import { firebase } from '@react-native-firebase/analytics';
import { Adjust, AdjustEvent } from 'react-native-adjust';
import { isEmpty as lodashIsEmpty, join } from 'lodash';
import { Toast } from 'teaset';
import bugsnag from '../utils/bugsnag';

import I18n from '../utils/i18n';
import p from '../utils/Transfrom';
import type { PermissionStatus } from 'react-native/Libraries/PermissionsAndroid/NativePermissionsAndroid';
import type { ExtractMarketData, MarketPushItemType, SymbolList } from '../models/market';

import md5 from './md5';
import type { MarketEventTypeItem } from '../events/MarketEvents';
import type { RouteType, MarketStateType, HomeStateType, SocialStateType } from '../actions/common';
import type { CoinAccount } from '../api/account';
import type { FastImageSource } from 'react-native-fast-image';
import codePush from 'react-native-code-push';
import DeviceInfo from 'react-native-device-info';

const screenWidth = Math.round(Dimensions.get('window').width);
export type ItemType = {
  sort: number,
  coinCode: string,
  hotMoney: number,
  currency: string,
  name: string,
  freeMargin: number,
  accountType: number,
  totalRevenue: number
};
export type PostType = {
  videoThumbnail: string,
  isVideo?: boolean,
  images: Array<string>,
  videoImages?: Array<string>,
  videos?: Array<string>,
  videoSource?: string,
  videoThumbnail?: string,
  width?: number,
  height?: number,
  image_width?: number,
  image_height?: number
};

function toNonExponential (num: number): string {
  try {
    const m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/) || [];
    const _len = (m[1] || '').length - (Number(m[2]))
    return num.toFixed(
      Math.max(0, _len > 20 ? 20 : _len)
    );
  } catch (e) {
    bugsnag.notify(new Error('[Helper]--[toNonExponential]--error  : ' + e.toString()));
    return num.toString()
  }
}

module.exports = {
  getRiskText: function (riskRate: number): string {
    let text = ''
    if (riskRate > 2 || riskRate === 2 || riskRate === 0) {
      text = I18n.t('riskRateSafe')
    } else if ((riskRate < 2 && riskRate > 1.5) || riskRate === 1.5) {
      text = I18n.t('riskRateLow')
    } else if ((riskRate < 1.5 && riskRate > 1.3) || riskRate === 1.3) {
      text = I18n.t('riskRateMedium')
    } else if ((riskRate < 1.3 && riskRate > 1.1) || riskRate === 1.1) {
      text = I18n.t('riskRateHigh')
    } else if ((riskRate < 1.1 && riskRate > 0) || riskRate === 1) {
      text = I18n.t('riskRateWarning')
    }
    return text
  },
  checkAndroidPermission: async (): Promise<PermissionStatus> => {
    try {
      const permission = PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE;
      return await PermissionsAndroid.request(permission);
    } catch (error) {
      bugsnag.notify(new Error('[Helper]--[checkAndroidPermission]--error  : ' + error.toString()));

      throw new Error(error);
    }
  },
  replaceHtmlEntity: function (str: string): string {
    const s = str;
    const translateRe = /&(nbsp|amp|quot|lt|gt);/g;
    const translate = { nbsp: ' ', amp: '&', quot: '"', lt: '<', gt: '>' };
    return (s.replace(translateRe, function (match: string, entity: string): string {
      return translate[entity];
    }));
  },
  subStrNum: function (str: string, len: number): string {
    str = str ?? ''
    const strLen = str.length;
    let strCut = '';
    let strLength = 0;
    for (let i = 0; i < strLen; i++) {
      let charStr = str.charAt(i); // 使用charAt获取单个字符；
      strLength++
      try {
        if (encodeURI(charStr).length > 4) { // 使用encodeURI获取编码长度
          strLength++;
        }
      } catch (e) {
        bugsnag.notify(new Error('[Helper]--[subStrNum]--error  : ' + e.toString()));

        charStr = ''
      }

      strCut = strCut.concat(charStr);// 单个字符进行合并
      if (strLength > len) {
        strCut = strCut.concat('...') // 大于指定长度后合并'...'并返回此字符串
        return strCut;
      }
    }
    if (strLength < len) {
      return str
    }
    // default return raw string
    return str;
  },

  getQueryParams (qs: string): {[key: string]: string} {
    if (qs.indexOf('?') !== -1) {
      qs = qs.substr(qs.indexOf('?') + 1)
    }
    qs = qs.split('+').join(' ');

    const params = {};
    let tokens: string[] | null;
    const re = /[?&]?([^=]+)=([^&]*)/g;

    while ((tokens = re.exec(qs)) != null) {
      if (tokens != null) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
      }
    }

    return params;
  },

  trackEvent: function (event: string, params?: { [key: string]: string }) {
    try {
      params = params || {}
      params = Object.assign({}, params, {
        USER_ID: global.USER_ID
      })
      Analytics.trackEvent(event, params);
      // alert(event)
      const analytics = firebase.analytics();
      analytics.logEvent(event.replace(/\s/g, '_'), params);
      if (event === 'User Login') {
        analytics.logLogin({ method: 'login' });
      }
      if (event === 'User Register') {
        analytics.logSignUp({ method: 'register' });
      }
      if (event.startsWith('Page Click')) {
        analytics.logScreenView({ screen_name: event.replace(/\s/g, '_') });
      }
      if (event.startsWith('NewHome Click')) {
        analytics.logScreenView({ screen_name: event.replace(/\s/g, '_') });
      }
    } catch (e) {
      bugsnag.notify(new Error('[Helper]--[trackEvent]--error  : ' + e.toString()));
      console.log(e);
    }
  },

  trackNavigation: function (route: RouteType) {
    try {
      const params = route.params ? Object.assign({}, route.params) : {};
      const screen = route.name;
      let screenWithParams: string = '';
      if (params.TRACK_PARAM) {
        screenWithParams = screen + '_' + params.TRACK_PARAM;
      } else {
        screenWithParams = screen;
      }
      const analytics = firebase.analytics();
      console.log('navigate to ' + screenWithParams);
      // {
      //          *    screen_class: 'ProductScreen',
      //          *    screen_name: 'ProductScreen',
      //          * }
      analytics.logScreenView({ screen_class: screen, screen_name: screenWithParams });
    } catch (e) {
      bugsnag.notify(new Error('[Helper]--[trackNavigation]--error  : ' + e.toString()));
      console.log(e);
    }
  },

  toTrackParam: function (...params: string[]): string {
    try {
      return join(params, '|')
    } catch (e) {
      console.log(e);
      bugsnag.notify(new Error('[Helper]--[toTrackParam]--error  : ' + e.toString()));
      return ''
    }
  },

  trackLogin: function () {
    try {
      this.trackEvent('User Login', { USER_ID: global.USER_ID });
      const adjustEvent = new AdjustEvent(Platform.OS === 'android' ? 'oosjgm' : 'u47ykr');
      Adjust.trackEvent(adjustEvent);
    } catch (e) {
      bugsnag.notify(new Error('[Helper]--[trackLogin]--error  : ' + e.toString()));
    }
  },

  adjustTrackRevenueEvent: function (isDC: boolean, amount: number, currency: string, transactionNum?: string) {
    if (!isDC) {
      const adjustEvent = new AdjustEvent(Platform.OS === 'android' ? '6v9jyt' : '5z2cx3');
      adjustEvent.setRevenue(amount, currency);
      adjustEvent.setTransactionId(transactionNum ?? '');
      Adjust.trackEvent(adjustEvent);
    }

    const adjustEvent = new AdjustEvent(Platform.OS === 'android' ? 'k46g6m' : '7ggpcn');
    adjustEvent.addCallbackParameter('amount', '' + amount);
    adjustEvent.addCallbackParameter('currency', currency);
    if (transactionNum) {
      adjustEvent.addCallbackParameter('transactionId', transactionNum);
    }
    Adjust.trackEvent(adjustEvent);
  },

  getStatusBarHeight: function (): number | null | void {
    if (Platform.OS === 'ios') {
      if (this.isIphoneX()) {
        return 44
      } else {
        return 20
      }
    } else {
      return StatusBar.currentHeight
    }
  },

  getMappingSort: function (num: number): number[] {
    let sort = []
    switch (num) {
      case 0:
        sort = [5, 0, 1, 2, 3, 4, 5, 0, 1, 2]
        break;
      case 1:
        sort = [0, 1, 2, 3, 4, 5, 0, 1, 2, 3]

        break;
      case 2:
        sort = [1, 2, 3, 4, 5, 0, 1, 2, 3, 4]

        break;
      case 3:
        sort = [2, 3, 4, 5, 0, 1, 2, 3, 4, 5]

        break;
      case 4:
        sort = [3, 4, 5, 0, 1, 2, 3, 4, 5, 0]

        break;
      case 5:
        sort = [4, 5, 0, 1, 2, 3, 4, 5, 0, 1]
        break;
    }
    return sort
  },

  getMobileOperatingSystem: function (): string {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  },
  getRelativeImageSize: function (_width: number, _height: number): {width: number, height: number} {
    if (_width > p(690)) {
      const radio = _width / p(690);
      _height = _height / radio
      _width = p(690)
      if (_height > p(690)) {
        _width = p(690) / (_height / p(690))
        _height = p(690)
      }
    } else if (_height > p(690)) {
      const radio = _height / p(690);
      _width = _width / radio
      _height = p(690)
      if (_width > p(690)) {
        _height = p(690) / (_width / p(690))
        _width = p(690)
      }
    }
    return {
      width: _width,
      height: _height
    }
  },
  extractMarketData: function (symbols: SymbolList[], i: number): ExtractMarketData {
    return this.extractMarketDataItem(symbols[i]);
  },
  extractMarketDataItem: function (symbols: SymbolList): ExtractMarketData {
    symbols = symbols || {}
    return {
      coinCode: symbols.symbol || symbols.coinCode,
      priceLast: Number(symbols.priceLast).toFixed(Number(symbols.symbolPrecision ?? 2)),
      volume: Number(symbols.volume).toFixed(Number(symbols.convertToCoinPrecision ?? 2)),
      riseAndFall: Number(symbols.riseAndFall).toFixed(2),
      highPrice: Number(symbols.highPrice).toFixed(Number(symbols.symbolPrecision ?? 2)),
      lowPrice: Number(symbols.lowPrice).toFixed(Number(symbols.symbolPrecision ?? 2)),
      favorite: symbols.favorite,
      convertToCoinPrecision: symbols.convertToCoinPrecision,
      longPrice: symbols.longPrice,
      shortPrice: symbols.shortPrice,
      precision: symbols.symbolPrecision,
      volumePrecision: symbols.volumePrecision,
      marketPricePrecision: symbols.marketPricePrecision,
      tradePrecision: symbols.tradePrecision,
      tradeCalPrecision: symbols.tradeCalPrecision,
      tradeInputPrecision: symbols.tradeInputPrecision,
      tradeVolumePrecision: symbols.tradeVolumePrecision,
      orderValueMin: symbols.orderValueMin,
      orderValueMax: symbols.orderValueMax,
      orderAmtMin: symbols.orderAmtMin,
      orderAmtMax: symbols.orderAmtMax,
      orderBaseMax: symbols.orderBaseMax,
      orderBaseMin: symbols.orderBaseMin,
      orderQuoteMax: symbols.orderQuoteMax,
      orderQuoteMin: symbols.orderQuoteMin,
      ask: symbols.ask,
      bid: symbols.bid,
      symbol: symbols.symbol || symbols.coinCode,
      priceList: symbols.priceList,
      fiatHedgeLimit: symbols.fiatHedgeLimit,
      spotHedgeLimit: symbols.spotHedgeLimit,
      swapHedgeLimit: symbols.swapHedgeLimit,
      priceLastRise: symbols.priceLastRise
    };
  },
  extractMarketDataList: function (symbols: SymbolList[]): ExtractMarketData[] {
    const that = this;
    return symbols.map((item: SymbolList, index: number, arrays: SymbolList[]): ExtractMarketData => {
      return that.extractMarketData(arrays, index);
    })
  },
  extractMarketEventData: function (value: MarketPushItemType): MarketEventTypeItem {
    try {
      return {
        coinCode: value.coinCode,
        priceLast: Number(value.priceLast).toFixed(Number(value.convertToCoinPrecision ?? 2)),
        volume: Number(value.volume).toFixed(Number(value.convertToCoinPrecision ?? 2)),
        riseAndFall: Number(value.riseAndFall).toFixed(2),
        highPrice: Number(value.highPrice).toFixed(Number(value.convertToCoinPrecision ?? 2)),
        lowPrice: Number(value.lowPrice).toFixed(Number(value.convertToCoinPrecision ?? 2)),
        longPrice: value.longPrice,
        shortPrice: value.shortPrice,
        convertToCoinPrecision: value.convertToCoinPrecision ?? 2
      };
    } catch (e) {
      bugsnag.notify(new Error('[Helper]--[extractMarketEventData]--error  : ' + e.toString()));
      return {
        coinCode: '',
        priceLast: '0.00',
        volume: '',
        riseAndFall: '',
        highPrice: '0.00',
        lowPrice: '0.00',
        longPrice: 0,
        shortPrice: 0,
        convertToCoinPrecision: 2
      }
    }
  },
  formatMarketPush: function (marketPushItem: MarketPushItemType): $Shape<ExtractMarketData> {
    return {
      coinCode: marketPushItem.coinCode,
      riseAndFall: marketPushItem.riseAndFall,
      priceLast: Number(marketPushItem.priceLast).toFixed(Number(marketPushItem.convertToCoinPrecision ?? 2)),
      highPrice: Number(marketPushItem.highPrice).toFixed(Number(marketPushItem.convertToCoinPrecision ?? 2)),
      lowPrice: Number(marketPushItem.lowPrice).toFixed(Number(marketPushItem.convertToCoinPrecision ?? 2)),
      volume: Number(marketPushItem.volume).toFixed(Number(marketPushItem.convertToCoinPrecision ?? 2)),
      longPrice: marketPushItem.longPrice,
      shortPrice: marketPushItem.shortPrice
    }
  },
  mergeMarketItemFromWsItem: function (extractMarketData: ExtractMarketData, marketPushItem: MarketPushItemType): ExtractMarketData {
    // 格式转化
    return Object.assign({}, extractMarketData, this.formatMarketPush(marketPushItem));
  },
  // 合并 pushList 到 marketItem
  mergeMarketItemList: function (pushList: MarketPushItemType[], marketList: ExtractMarketData[]): ExtractMarketData[] {
    const that = this;
    const pushMap = Object.fromEntries(pushList.map((e: MarketPushItemType): [string, MarketPushItemType] => [e.coinCode, e]));
    return marketList.map((item: ExtractMarketData, number: number, array: ExtractMarketData[]): ExtractMarketData => {
      const pushTmp = pushMap[item.coinCode];
      if (!pushTmp) {
        return item;
      }
      return that.mergeMarketItemFromWsItem(item, pushTmp);
    });
  },
  mergeAccount: function (accountList: Array<CoinAccount>, assetAccountList: Array<CoinAccount>): Array<CoinAccount> {
    const itemList: CoinAccount[] = [];
    if (accountList && accountList.length > 0) {
      // TODO if (account.category === this.props.type){
      accountList.forEach((account: CoinAccount) => {
        itemList.push(account);
      });
    }
    if (assetAccountList && assetAccountList.length > 0) {
      assetAccountList.forEach((account: CoinAccount) => {
        itemList.push(account);
      });
    }
    // sort
    itemList.sort(function (a: CoinAccount, b: CoinAccount): number {
      if (a.sort < b.sort) {
        return -1;
      } else if (a.sort > b.sort) {
        return 1;
      } else {
        return a.coinCode.localeCompare(b.coinCode);
      }
    });
    return itemList;
  },

  isStringBlank: function (value: string): boolean {
    return !value || value === '' || value.trim() === '';
  },

  isEmpty: function (obj: {...}): boolean {
    // for (const key in obj) {
    //   if (obj.hasOwnProperty(key)) {
    //     return false;
    //   }
    // }
    return lodashIsEmpty(obj);
  },
  isIphone12: function (): boolean {
    const dimen = Dimensions.get('window');
    const IPHONE12H = 844
    const IPHONE12Max = 926
    const IPHONE12Mini = 780

    return (
      Platform.OS === 'ios' &&
      !Platform.isPad &&
      !Platform.isTVOS &&
      (
        dimen.height === IPHONE12H ||
        dimen.height === IPHONE12Max ||
        dimen.height === IPHONE12Mini
      )
    );
  },

  isIphoneX: function (): boolean {
    const dimen = Dimensions.get('window');
    const IPHONE12H = 844
    const IPHONE12Max = 926
    const IPHONE12Mini = 780

    return (
      Platform.OS === 'ios' &&
      !Platform.isPad &&
      !Platform.isTVOS &&
      (dimen.height === 812 ||
        dimen.width === 812 ||
        dimen.height === 896 ||
        dimen.height === IPHONE12H ||
        dimen.height === IPHONE12Max ||
        dimen.height === IPHONE12Mini ||
        dimen.width === 896)
    );
  },

  toLocaleTime: function (time: string | number, format: ?string, utcFormat: ?string): string {
    format = format || 'YYYY-MM-DD HH:mm:ss';
    if (utcFormat) {
      return moment
        .utc(time.toString(), utcFormat)
        .local()
        .format(format);
    } else {
      return moment
        .utc(time)
        .local()
        .format(format);
    }
  },
  formatDateValue: function (value: string, format: string): string {
    const date = moment(value).format(format || 'YYYY-MM-DD');
    return date;
  },
  getAccountValue: function (key: number): string {
    let name = '';
    switch (key) {
      case 0:
        name = I18n.t('asset_main_account');
        break;
      case 1:
        name = I18n.t('asset_isolated_account');
        break;
      case 2:
        name = I18n.t('asset_cross_account');
        break;

      case 4:
        name = I18n.t('asset_yield_account');
        break;
    }
    return name;
  },

  getOperateType: function (key: number, type: string): string {
    let name = '';
    if (type === 'flex') {
    // 1=申购,2=申购费,5=赎回,6=赎回费,7=撤回，8=计息,
      switch (key) {
        case 1:
          name = I18n.t('purchase');
          break;
        case 2:
          name = I18n.t('purchaseFee1');
          break;
        case 5:
          name = I18n.t('redeem');
          break;
        case 6:
          name = I18n.t('redeemFee');
          break;
        case 7:
          name = I18n.t('withdraw1');
          break;
        case 8:
          name = I18n.t('interest');
          break;
      }
    } else {
      // 0=认购，即扣钱到代理户,1=分发，即扣钱到代理户
      switch (key) {
        case 0:
          name = I18n.t('regularInvestment');
          break;
        case 1:
          name = I18n.t('distribution');
          break;
      }
    }

    return name;
  },
  mapingEarningsStatus: (key: string): string => {
    const arr = {
      status1: I18n.t('getStarted'),
      status2: I18n.t('processing'),
      status3: I18n.t('success'),
      status11: I18n.t('failed')
    }
    return arr[key]
  },
  mapingExchange1: {
    okex: require('../images/okex1.webp'),
    binance: require('../images/binance1.webp')
  },
  mapingExchange: {
    okex: require('../images/okex_icon.webp'),
    bitmex: require('../images/bitmex_icon.webp'),
    huobi: require('../images/huobi_icon.webp'),
    ftx: require('../images/ftx.webp'),
    binance: require('../images/binance_icon.webp'),
    bybit: require('../images/bybit_icon.webp'),
    all: require('../images/kiki_icon.webp')
  },

  mapingStatusIcon: {
    withdraw_success: require('../images/success.webp'),
    withdraw_applied: require('../images/processing.webp'),
    withdraw_rejected: require('../images/failure1.webp'),
    withdraw_fail: require('../images/failure1.webp'),
    deposit_success: require('../images/success.webp'),
    deposit_rejected: require('../images/failure1.webp'),
    deposit_confirming: require('../images/processing.webp'),
    deposit_fail: require('../images/failure1.webp')
  },

  mapPeriods: (index: number): {title: string, desc: string} => {
    const arr = [
      { title: I18n.t('daily'), desc: I18n.t('dailyAutoInvest') },
      { title: I18n.t('weekly'), desc: I18n.t('weeklyAutoInvest') },
      { title: I18n.t('biWeekly'), desc: I18n.t('biWeeklyAutoInvest') },
      { title: I18n.t('monthly'), desc: I18n.t('monthlyAutoInvest') }
    ]
    return arr[index] || { title: '', desc: '' }
  },
  identificationSwitch: (statusType: number): FastImageSource | number => {
    const type = `role${statusType}`;
    const status = {
      role2: require('../images/identification_kol.webp'),
      role3: require('../images/identification_media.webp'),
      role4: require('../images/identification_organization.webp')
    };
    return type ? status[type] : 0;
  },
  formatCardNumber: function (value: string): string {
    const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
    const onlyNumbers = value.replace(/[^\d]/g, '');

    return onlyNumbers.replace(regex, (regex: string, $1: string, $2: string, $3: string, $4: string, $5: string): string =>
      [$1, $2, $3, $4, $5].filter((group: string): boolean => !!group).join(' '));
  },
  getNextInvestmentTime: function (period: number): string {
    let str = ''
    switch (period) {
      case 0:
        str = moment().add(1, 'day').format('YYYY-MM-DD')
        break;
      case 1:
        str = moment().startOf('isoWeek').add(1, 'week').format('YYYY-MM-DD')
        break;
      case 2:
        if (new Date().getDate() < 15) {
          str = moment().startOf('month').add(14, 'day').format('YYYY-MM-DD')
        } else {
          str = moment().startOf('month').add(1, 'month').format('YYYY-MM-DD')
        }

        break;
      case 3:
        str = moment().startOf('month').add(1, 'month').format('YYYY-MM-DD')
        break;
    }
    return str
  },

  obscureCardNumber: function (txt: string): string {
    return txt.substr(0, 4) + '********' + txt.substr(txt.length - 4, 4);
  },

  getAccountCategory: function (key: number): number {
    let category = 0;
    switch (key) {
      case 0: // spot
        category = 0;
        break;
      case 1: // margin
        category = 6;
        break;
      case 2: // cross margin
        category = 13;
        break;
      case 3: // copy
        category = 9;
        break;
      case 4: // treasure
        category = 4;
        break;
    }
    return category;
  },

  mapingTransactionStatusIcon: function (status: number): number {
    switch (status) {
      case 2: return require('../images/success.webp');
      case 1: return require('../images/processing.webp');
      case 3: return require('../images/failure1.webp');
      case 13: return require('../images/failure1.webp');
      case 4: return require('../images/success.webp');
      case 12: return require('../images/success.webp');
      case 11: return require('../images/processing.webp');
      case 10: return require('../images/processing.webp');
      case 5: return require('../images/failure1.webp');
      default:
        return require('../images/success.webp');
    }
  },
  getTransactionStatusDesc: function (status: number): string {
    let desc = I18n.t('common_unknown');
    switch (status) {
      case 1:
        desc = I18n.t('asset_withdrawal_status_apply');
        break;
      case 2:
        desc = I18n.t('asset_withdrawal_status_approval');
        break;
      case 3:
        desc = I18n.t('asset_withdrawal_status_unapproval');
        break;
      case 4:
        desc = I18n.t('asset_complete');
        break;
      case 5:
        desc = I18n.t('asset_failed');
        break;
      case 10:
      case 11:
        desc = I18n.t('asset_pending');
        break;
      case 12:
        desc = I18n.t('asset_complete');
        break;
      case 13:
        desc = I18n.t('asset_failed');
        break;
      default:
        break;
    }
    return desc;
  },
  getTransactionStatusDesc1: function (status: string): string {
    let desc = I18n.t('common_unknown');
    switch (status) {
      case 'WITHDRAW_APPLY':
        desc = I18n.t('asset_withdrawal_status_apply');
        break;
      case 'WITHDRAW_APPROVED':
        desc = I18n.t('asset_withdrawal_status_approval');
        break;
      case 'WITHDRAW_REJECTED':
        desc = I18n.t('asset_withdrawal_status_unapproval');
        break;
      case 'WITHDRAW_COMPLETED':
        desc = I18n.t('asset_complete');
        break;
      case 'WITHDRAW_FAILED':
        desc = I18n.t('asset_failed');
        break;
      case 'DEPOSIT_INITIAL':
      case 'DEPOSIT_APPLY':
        desc = I18n.t('asset_pending');
        break;
      case 'DEPOSIT_FINISHED':
        desc = I18n.t('asset_complete');
        break;
      case 'DEPOSIT_FAILED':
        desc = I18n.t('asset_failed');
        break;
      default:
        break;
    }
    return desc;
  },

  getBusinessNameByType: function (
    businessType: number,
    isKol: ?boolean = false,
    operateType: ?number = 0
  ): string {
    let name = I18n.t('common_unknown');
    switch (businessType) {
      case 1:
        name = I18n.t('asset_withdrawal');
        break;
      case 2:
        name = I18n.t('asset_deposit');
        break;
      case 3:
        name = I18n.t('asset_exchange');
        break;
      case 5:
        name = I18n.t('asset_reward');
        break;
      case 8:
        if (operateType === 6) {
          name = I18n.t('asset_transfer_in');
        } else if (operateType === 5) {
          name = I18n.t('asset_transfer_out');
        } else {
          name = I18n.t('asset_transfer');
        }
        break;
      case 10:
        name = I18n.t('asset_transfer');
        break;
      case 12:
        name = I18n.t('asset_interest_type');
        break;
      case 14:
        name = I18n.t('asset_trial_bonus_interest');
        break;

      case 16:
        name = I18n.t('asset_open_position');
        break;
      case 17:
        name = I18n.t('asset_limit_order');
        break;
      case 18:
        name = I18n.t('asset_cancel_order');
        break;
      case 19:
        name = I18n.t('asset_adjust_margin');
        break;
      case 20:
        name = I18n.t('asset_close_position');
        break;
      case 21:
        name = I18n.t('asset_start_follow');
        break;
      case 22:
        name = isKol === true
          ? I18n.t('asset_performance_share')
          : I18n.t('asset_close_follow');
        break;
      case 23:
        name = I18n.t('member_buy');
        break;
      case 24:
        name = I18n.t('asset_spot_trade');
        break;
      case 25:
        name = I18n.t('depositFiat');
        break;
      case 26:
        name = I18n.t('withdrawFiat');
        break;
      case 27:
        name = I18n.t('asset_reward');
        break;
      case 30:
        name = I18n.t('activity_purchase_card');
        break;
      case 32:
        name = I18n.t('marginTrading');
        break;
      case 33:
        name = I18n.t('loanInterest');
        break;
    }
    return name;
  },

  isIncomingByOperateType: function (operateType: number): boolean {
    let inComing = true;
    switch (operateType) {
      case 1: // 冻结
        inComing = false;
        break;
      case 2: // 解冻
        inComing = true;
        break;
      case 3: // 冻结并扣减
        inComing = false;
        break;
      case 4: // 加钱
        inComing = true;
        break;
      case 5: // 转出
        inComing = false;
        break;
      case 6: // 转入
        inComing = true;
        break;
    }
    return inComing;
  },

  createUUID: function (): string {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c: string): string {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },

  getDeviceToken: function (callback: (string) => void) {
    AsyncStorage.getItem('notification_token').then((data: string) => {
      let token = data;
      if (!data) {
        token = this.createUUID();
      }
      AsyncStorage.setItem('notification_token', token);
      callback(token);
    });
  },

  getImageRelativeWidthHeight: function (imgurl: string): {width: number, height: number} {
    const that = this;
    const realImageWidth = that.getImageWidth(imgurl, '');
    const realImageHeight = that.getImageHeight(imgurl, '');
    const outerWidth = screenWidth - p(60);
    const outerHeight = screenWidth - p(60);
    let height, width;

    if (realImageWidth > realImageHeight) {
      if (realImageWidth > outerWidth) {
        height = realImageHeight * (outerWidth / realImageWidth);
        width = outerWidth;
      } else {
        height = realImageHeight;
        width = realImageWidth;
      }
    } else {
      if (realImageHeight > outerWidth) {
        height = outerWidth;
        width = outerWidth * (outerWidth / realImageHeight);
      } else {
        height = realImageHeight;
        width = realImageWidth;
      }
    }

    if (height < outerHeight / 2) {
      width = width * (outerHeight / 2 / height);
      height = outerHeight / 2;
    } else if (height > outerHeight) {
      width = width * (outerHeight / height);
      height = outerHeight;
    }

    if (width < outerWidth * 0.5) {
      height = height * ((outerWidth * 0.5) / width);
      width = outerWidth * 0.5;
    }

    return {
      width,
      height
    };
  },

  getImageWidth: function (imgurl: string, videoUrl: string): number {
    try {
      const url = (imgurl ?? '').match(/w:\d+/) || videoUrl.match(/w:\d+/);
      return url ? Number(url[0].split(':')[1]) : 0;
    } catch (e) {
      return 0;
    }
  },
  getImageHeight: function (imgurl: string, videoUrl: string): number {
    try {
      const url = (imgurl ?? '').match(/h:\d+/) || (videoUrl ?? '').match(/h:\d+/);
      return url ? Number(url[0].split(':')[1]) : 0;
    } catch (e) {
      bugsnag.notify(new Error('[Helper]--[getImageHeight]--error  : ' + e.toString()));
      return 0;
    }
  },
  minBaseNum: function (num: number): {baseNum: number, baseLength: ?number} {
    // const numStr = num + '';
    // const index = numStr.indexOf('.');
    // const isFLoat = index !== -1;
    // 默认跟单比例调整为整数，这里baseNum直接返回1
    return { baseNum: 1, baseLength: null };
    // if (isFLoat) {
    //   let str = 0 + '.';
    //   for (let i = 0; i < numStr.substr(index + 1).length - 1; i++) {
    //     str += '0';
    //   }
    //   return {
    //     baseNum: parseFloat(str + '1'),
    //     baseLength: (str + '1').length - 2
    //   };
    // } else {
    //   return { baseNum: 1, baseLength: null };
    // }
  },

  /**
   * 按照精度修复用户输入的金额，用于输入框金额输入
   * @param numOrString
   * @param digital
   * @returns {string}
   */
  fixNumString: function (numOrString: string | number, digital: number): string {
    numOrString = numOrString ?? ''
    const numStr = String(numOrString);
    if (numOrString === '.') {
      return '0.'
    }
    if (numStr.indexOf('.') > 0) {
      // 位数大于0
      if (digital > 0) {
        const re = RegExp('^(\\d+)\\.(\\d{1,' + digital + '}).*$', 'gim');
        // let str = numOrString.replace(/^(-)*(\d+)\.(\d{1,4}).*$/, '$1$2.$3');
        const str = numStr.replace(re, '$1.$2');
        const strArr = str.split('.');
        let ret = Number(strArr[0]) + '';
        if (strArr[0].indexOf('-') > -1 && Number(strArr[0]) === 0) {
          ret = '-' + ret;
        }
        if (strArr.length === 2) {
          ret = ret + '.' + strArr[1] ?? '0'; // 单纯输入10. 的时候， 补0
        }
        return ret;
      } else {
        // 只能输入整数时
        // 单纯输入"10." 的时候去掉小数点
        return numStr.substr(0, numStr.indexOf('.'))
      }
    } else {
      return numStr;
    }
  },
  formatNegativeNum: function (num: number | string): string {
    let numStr = String(num);
    if (Number(num) >= 0) {
      return '$' + num;
    } else if (Number(num) < 0 && numStr) {
      numStr = numStr.replace('-', '');
      return '-$' + numStr;
    }
    return '-';
  },
  formatFixNumString: function (numOrString: typeof bigDecimal | string | number, digital?: number): string {
    const numberValue: number = Number(numOrString || 0);
    let numberStr = toNonExponential(numberValue) ?? 0
    if (Number(digital) < 0) {
      digital = 0
    } else if (Number(digital) > 20) {
      digital = 20
    }
    try {
      if (Number(numberStr) === 0) {
        numberStr = Number(numberStr).toFixed(Math.abs(Number(digital ?? 0)))
      } else if (Number(numberStr) < 0.00001 && Number(numberStr) > 0) {
        const str1 = Number(numberStr).toFixed(Math.abs(Number(digital)) + 4)
        numberStr = str1.substr(0, Number(digital) + 2)
      } else if (Math.abs(Number(numberStr)) < 0.00001 && Number(numberStr) < 0) {
        console.log(numberStr)
        numberStr = numberStr + '0000'
        numberStr = numberStr.substr(0, Number(digital) + 3)
      } else {
        // numberStr = '0.' + (Number(numOrString).toString() + '00000000').substr(2, digital)
        const arr1 = Number(numberStr).toString().split('.')

        const splitPointStr = Number(digital) > 0 ? '.' : ''
        if (arr1.length === 1) {
          arr1[1] = '00000000'
        } else {
          arr1[1] += '00000000'
        }
        numberStr = arr1[0] + splitPointStr + arr1[1].substr(0, digital)
      }
    } catch (e) {
      bugsnag.notify(new Error('[Helper]--[formatFixNumString]--error  : ' + e.toString()));
    }

    return numberStr;
  },

  changeValue: function (type: string, currentValue: number): number | string {
    const text = parseFloat(currentValue);
    const base = isNaN(text) ? { baseNum: 0, baseLength: 0 } : this.minBaseNum(currentValue);
    let length = 0
    try {
      length = (text + '').split('.')[1].length
    } catch (e) {
      bugsnag.notify(new Error('[Helper]--[changeValue]--error  : ' + e.toString()));
    }

    let value = 0;
    if (type === 'reduce') {
      if (!isNaN(text)) {
        value =
          text > 0
            ? base.baseNum < 1
              ? (text - base.baseNum).toFixed(Number(base.baseLength))
              : (text - base.baseNum).toFixed(Number(length))
            : 0;
      }
    } else {
      if (!isNaN(text)) {
        value =
          base.baseNum < 1
            ? (text + base.baseNum).toFixed(Number(base.baseLength))
            : (text + base.baseNum).toFixed(Number(length));
      }
    }
    return value;
  },

  formatPostData: function (item: PostType): PostType {
    const that = this;
    try {
      if (item.videos && item.videos.length) {
        // console.log(item)
        item.isVideo = true;
        item.videoThumbnail =
          (item.videoImages && item.videoImages[0]) ||
          (item.images && item.images[0]);
        item.videoSource = item.videos[0];
        item.width = parseInt(that.getImageWidth(item.videoThumbnail, item.videos ? item.videos[0] : ''));
        item.height = parseInt(that.getImageHeight(item.videoThumbnail, item.videos ? item.videos[0] : ''));

        // console.log(item)
      }

      if (item.images && item.images.length === 1) {
        item.image_width = that.getImageWidth(item.images[0]);
        item.image_height = that.getImageHeight(item.images[0], '');
        // console.log(item)
      }

      return item;
    } catch (e) {
      bugsnag.notify(new Error('[Helper]--[formatPostData]--error  : ' + e.toString()));
      // alert(e);
      console.log(e);
      return item;
    }
  },
  /**
   * 每隔三位加逗号
   * @param num
   * @returns {string}
   */
  addCommaToThousands (num: number | string): number | string {
    if (!num) {
      return num;
    }
    num = (num || 0);
    const arr = num.toString().split('.');
    num = arr[0];
    const end = arr[1] ? '.' + arr[1] : '';
    let result = '';
    const symbol = num.startsWith('-') ? '-' : '';
    if (num.startsWith('-')) {
      num = num.replace('-', '');
    }
    while (num.length > 3) {
      result = ',' + num.slice(-3) + result;
      num = num.slice(0, num.length - 3);
    }
    if (num) {
      result = num + result;
    }
    return symbol + result + end;
  },
  formatVolume (num: number | string): number | string {
    if (!num) {
      return num;
    }
    num = (num || 0);
    const arr = num.toString().split('.');
    num = arr[0];
    let end = arr[1] ? '.' + arr[1] : '';
    let result = '';
    const symbol = num.startsWith('-') ? '-' : '';
    if (num.startsWith('-')) {
      num = num.replace('-', '');
    }
    if (Number(num) < 1000000) {
      while (num.length > 3) {
        result = ',' + num.slice(-3) + result;
        num = num.slice(0, num.length - 3);
      }
      if (num) {
        result = num + result;
      }
    } else if (Number(num) < 1000000000) {
      result = (Number(num) / 1000000).toFixed(2)
      end = 'M'
    } else {
      result = (Number(num) / 1000000000).toFixed(2)
      end = 'B'
    }

    return symbol + result + end;
  },

  navigate: function (navigateFunc: (routeTo: string, params?: {monitor_pageStartTime?: number, monitor_routeFrom?: ?string}, routeFrom?: string) => void, routeTo: string, params?: {monitor_pageStartTime?: number, monitor_routeFrom?: ?string}, routeFrom?: string) {
    params = params || {};
    params.monitor_pageStartTime = new Date().getTime();
    params.monitor_routeFrom = routeFrom;
    navigateFunc && navigateFunc(routeTo, params);
  },
  getImageFileExtension: function (path: string): string {
    const basename = path.split(/[\\/]/).pop(); // extract file name from full path ...
    // (supports `\\` and `/` separators)
    const pos = basename.lastIndexOf('.'); // get last position of `.`

    if (basename === '' || pos < 1) {
      // if file name is empty or ...
      return '';
    } //  `.` not found (-1) or comes first (0)

    return basename.slice(pos + 1); // extract extension ignoring `.`
  },

  levelTxtMap: {
    a: 'A+',
    b: 'B'
  },
  mapToQueryString: function (map: {}): string {
    const v = [];
    Object.keys(map).forEach((value: string, index: number, array: Array<string>) => {
      if (typeof map[value] !== 'undefined') {
        if (Array.isArray(map[value])) {
          // 兼容array类型参数only 使用后端为spring java的类型
          v.push(value + '=' + map[value].join(','));
        } else {
          const vv = map[value];
          v.push(value + '=' + (vv ?? ''));
        }
      }
    });
    return v.join('&');
  },
  combineAlertDatas: function (alertText: string, dataArray: Array<string | number>): string {
    let returText = alertText;
    dataArray.map((value: string | number, index: number): boolean => {
      const p = /\{.*?\}/;
      returText = returText.replace(p, value.toString());
      return true;
    });
    return returText;
  },

  isMember: function (socialUserInfo: {level: string, expireTime: number}): boolean {
    try {
      if (
        Number(socialUserInfo.level) >= 0 &&
          socialUserInfo.expireTime > new Date().getTime()
      ) {
        return true;
      }
      return false;
    } catch (e) {
      bugsnag.notify(new Error('[Helper]--[isMember]--error  : ' + e.toString()));
      return false;
    }
  },

  isValidPass: function (pwd: string): boolean {
    console.log(pwd);
    if (
      pwd.length > 5 &&
      pwd.length < 21 &&
      pwd.match(/^(?=.*[a-zA-Z])(?=.*[0-9])/)
    ) {
      return true;
    } else {
      return false;
    }
  },
  isValidEmail: function (email: string): boolean {
    const myreg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const ret = myreg.test(email);

    return ret;
  },

  hiddenMobileText: function (mobile: string, telephoneCode: string): string {
    const text = mobile;
    if (!mobile) {
      return mobile;
    }

    return telephoneCode + text.substring(0, 3) + '****' + text.substring(7);
  },

  hiddenEmailText: function (email: string): string {
    let text = email;
    if (!email) {
      return text;
    }
    const emailArray = email.split('@');
    const len = emailArray.length;
    const prefix = emailArray[0];
    if (prefix.length > 4) {
      text =
        prefix.substring(0, 2) +
        '****' +
        '@';
      for (let i = 1; i < len; i++) {
        text += emailArray[i];
      }
    }
    return text;
  },

  checkUrl: function (text: string): string {
    // let reg =  /^((ht|f)tps?):\/\/([\w-]+(\.[\w-]+)*\/?)+(\?([\w\-\.,@?^=%&:\/~\+#]*)+)?$/;
    // return reg.test(text);

    let str = text;
    const re = /(http|ftp|https):\/\/[\w-]+(.[\w-]+)+([\w-.,@?^=%&:/~+#]*[\w-@?^=%&/~+#])?/g;
    str = str.replace(re, function (childStr: string): string {
      return '<Text  style={{color: "blue"}}>' + childStr + '</Text>';
    });
    console.log(str);
    return str;
  },

  formatKycStatus: function (status: number): {text: string, bgColor: string} {
    let text = '';
    const kycStatus = status + 1;
    let bgColor = '#FFC241';

    switch (kycStatus) {
      case 1:
        text = I18n.t('kyc_unverified');
        bgColor = '#FFC241';
        break;
      case 2:
        text = I18n.t('kyc_verifying');
        bgColor = '#D9DBDF';
        break;
      case 3:
        text = I18n.t('kyc_verified');
        bgColor = '#60D937';
        break;
      case 4:
        text = I18n.t('kyc_rejected');
        bgColor = '#F0506C';
        break;
    }

    const data = {
      text: text,
      bgColor: bgColor
    };

    return data;
  },

  getFormattedDate: function (ts: number): string {
    const date = new Date(ts);

    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let min = date.getMinutes();
    let sec = date.getSeconds();

    month = (month < 10 ? '0' : '') + month;
    day = (day < 10 ? '0' : '') + day;
    hour = (hour < 10 ? '0' : '') + hour;
    min = (min < 10 ? '0' : '') + min;
    sec = (sec < 10 ? '0' : '') + sec;

    const str =
      date.getFullYear() +
      '-' +
      month +
      '-' +
      day +
      ' ' +
      hour +
      ':' +
      min +
      ':' +
      sec;

    /* alert(str); */

    return str;
  },

  toastError: (fail: boolean, text: string = 'Network error, please try again later.'): boolean => {
    if (fail) {
      Toast.show({
        text: text,
        position: 'center'
      });
      return true;
    }
    return false;
  },
  formatKlineBar: function (interval: string): string {
    // moment().valueOf()
    let dateValue = '';
    if (interval === '60') {
      // 前一小时
      dateValue = moment()
        .add(-1 * 30, 'hour')
        .format('YYYY-MM-DD HH:mm:ss');
    } else if (interval === '4H' || interval === '240') {
      // 前四小时
      dateValue = moment()
        .add(-4 * 30, 'hour')
        .format('YYYY-MM-DD HH:mm:ss');
    } else if (interval === 'D') {
      dateValue = moment(new Date())
        .subtract(1 * 30, 'days')
        .format('YYYY-MM-DD HH:mm:ss');
    } else if (interval === 'W') {
      dateValue = moment(new Date())
        .subtract(7 * 30, 'days')
        .format('YYYY-MM-DD HH:mm:ss');
    } else if (interval === 'M') {
      // 前一个月
      dateValue = moment(new Date())
        .subtract(1 * 30, 'months')
        .format('YYYY-MM-DD HH:mm:ss');
    }

    let fromValue = '';
    if (dateValue) {
      fromValue = moment(dateValue, 'YYYY-MM-DD HH:mm:ss').valueOf()
    }
    return fromValue.toString();
  },

  /*
   * [0-9a-zA-Z]
   */
  isValidInput: function (str: string): string {
    const value = str.replace(/[^a-zA-Z0-9]/g, '');
    return value;
  },

  md5: md5,

  /**
   * url: 跳转的target,但是如果有登录，账户等任何限制，请在调用前自行判断
   * navigation = this.props.navigation.navigate
   */
  // eslint-disable-next-line flowtype/no-weak-types
  url_redirect: function (url: string, title: string, navigation: Function, isLogin: boolean) {
    title = title ?? I18n.t('activity')
    if (url && url.startsWith('http')) {
      this.navigate(navigation, 'WebViewPage', {
        url: url,
        title: title,
        TRACK_PARAM: url
      });
    }
    if (url && url.startsWith('kikitrade')) {
      try {
        const arr = url.split('//');

        if (arr[2] && arr[2].startsWith) {
          let str = arr[2]
          if (str.startsWith('?')) {
            this.navigate(navigation, arr[1]);
          } else {
            str = str.substring(0, str.indexOf('?'))
            str = str.replace(/'/g, '"')
            this.navigate(navigation, arr[1], JSON.parse(str));
          }
        } else {
          if (arr[1] !== 'TopicDetail' && arr[1] !== 'HotTopicDetail' && arr[1] !== 'PollDetail') {
            this.navigate(navigation, arr[1]);
          }
        }
      } catch (e) {
        bugsnag.notify(new Error('[Helper]--[url_redirect]--error  : ' + e.toString()));
        console.log('url_redirect fail-' + e);
      }
    }
    if (url && url.startsWith('needlogin_kikitrade')) {
      try {
        if (isLogin) {
          const arr = url.split('//');
          this.navigate(navigation, arr[1]);
        } else {
          this.navigate(navigation, 'Login');
        }
      } catch (e) {
        bugsnag.notify(new Error('[Helper]--[url_redirect]--error  : ' + e.toString()));
        console.log('url_redirect fail-' + e);
      }
    }
  },

  /*
  * 校验 是否包含汉字
  * */
  checkChinese: function (str: string): boolean {
    const reg = /[\u4e00-\u9fa5]/g;
    return reg.test(str);
  },

  parseUrl: (href: string): {href: string, protocol: ?string, host: ?string, hostname: ?string, port: ?string, pathname: ?string, search: ?string, hash: ?string} => {
    const match = href.match(/^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    return {
      href: href,
      protocol: (match && match.length >= 1) ? match[1] : null,
      host: (match && match.length >= 2) ? match[2] : null,
      hostname: (match && match.length >= 3) ? match[3] : null,
      port: (match && match.length >= 4) ? match[4] : null,
      pathname: (match && match.length >= 5) ? match[5] : null,
      search: (match && match.length >= 6) ? match[6] : null,
      hash: (match && match.length >= 7) ? match[7] : null
    }
  },

  isSocialLogin: function (socialReducer: {tokenUpdateTs: number, socialAnonymous: boolean}): boolean {
    const { socialAnonymous } = socialReducer;
    // 新版会不断刷新， 所以不需要一直来做时间验证
    return !socialAnonymous;
  },
  getCoinDetail: function (coinCode: string, marketItems: ExtractMarketData[]): ?ExtractMarketData {
    const detail = marketItems.find((item: ExtractMarketData, index: number, array: ExtractMarketData[]): boolean => {
      if (item.coinCode === coinCode) {
        return true
      }
      return false;
    });
    return detail;
  },
  getFormatDate: function (created: string): string {
    const dateline = created.slice(0, 4) + '/' + created.slice(4, 6) + '/' + created.slice(6, 8);
    const timeline = created.slice(8, 10) + ':' + created.slice(10, 12) + ':' + created.slice(12, 14);
    created = dateline + ' ' + timeline;

    created = moment.utc(created).local().format('YYYY/MM/DD');
    return created;
  },
  /**
   * 检查下单hedge额度, 为方便，前端统一使用USDT作为转换定价币单位
   *  BTC/HKD ==> BTC/HKD / USDT/HKD * quantity < hedge amount
   * @param amount 购买额度， 价格*数量
   * @param coinDetail 盘口明细
   * @param HomeStateType
   * @param orderType 类型
   * @param side 方向
   * @returns {boolean}
   */
  checkMarketHedgeLimit: function (amount: number, coinDetail: ExtractMarketData, MarketReducer: MarketStateType, orderType: 'fiat' | 'spot' | 'margin' | 'copy', side: 'long' | 'short'): {pass: boolean, limitAmount: number} {
    const coinCodes = coinDetail.coinCode.split('_');
    // const coinCode = coinCodes[0];
    const quoteCoinCode = coinCodes[1];
    let target: ?ExtractMarketData;
    let finalAmount = amount;
    let checkLimit: number;
    switch (orderType) {
      case 'fiat':
        //
        checkLimit = coinDetail.fiatHedgeLimit;
        break;
      case 'spot':
        checkLimit = coinDetail.spotHedgeLimit;
        break;
      case 'margin':
        checkLimit = coinDetail.swapHedgeLimit;
        break;
      default: // copy for swapMarketItems
        checkLimit = coinDetail.swapHedgeLimit;
        break;
    }

    if (!checkLimit) {
      return { pass: true, limitAmount: Number(0) };
    }

    if (quoteCoinCode !== 'USDT') {
      switch (orderType) {
        case 'fiat':
          target = MarketReducer.fiatMarketItems.find((item: ExtractMarketData): boolean => {
            return item.coinCode === 'USDT_' + quoteCoinCode;
          })
          break;
        case 'spot':
          target = MarketReducer.spotMarketItems.find((item: ExtractMarketData): boolean => {
            return item.coinCode === 'USDT_' + quoteCoinCode;
          })
          break;
        case 'margin':
          target = MarketReducer.swapMarketItems.find((item: ExtractMarketData): boolean => {
            return item.coinCode === 'USDT_' + quoteCoinCode;
          })
          break;
        default: // copy for swapMarketItems
          target = MarketReducer.swapMarketItems.find((item: ExtractMarketData): boolean => {
            return item.coinCode === 'USDT_' + quoteCoinCode;
          })
          break;
      }
    }
    let limitAmount = checkLimit;
    if (target) {
      finalAmount = amount / (side === 'long' ? target.longPrice : target.shortPrice);
      limitAmount = this.formatFixNumString(checkLimit * (side === 'long' ? target.longPrice : target.shortPrice), coinDetail.tradePrecision)
    }
    if (finalAmount > limitAmount) {
      // limitAmount 转换为盘口本位的计算
      return { pass: false, limitAmount: Number(limitAmount) };
    }
    // limitAmount 转换为盘口本位的计算
    return { pass: true, limitAmount: Number(limitAmount) };
  },
  appVersion: async function (): Promise<string> {
    try {
      const meta = await codePush.getUpdateMetadata();
      const appVersion: string = (meta && meta.description) || DeviceInfo.getVersion();
      return appVersion;
    } catch (error) {
      return DeviceInfo.getVersion()
    }
  },

  isHtmlTag: function (text: string): boolean {
    const reg = /<[^>]+>/g;
    return reg.test(text || '');
  },
  isValidPrecision: function (text: string, precision: number): boolean {
    const precisionNumber = `precision_${precision}`;
    const reglist = {
      precision_2: /^\d+(\.\d{0,1})?$/,
      precision_3: /^\d+(\.\d{0,2})?$/,
      precision_4: /^\d+(\.\d{0,3})?$/,
      precision_5: /^\d+(\.\d{0,4})?$/,
      precision_6: /^\d+(\.\d{0,5})?$/,
      precision_7: /^\d+(\.\d{0,6})?$/,
      precision_8: /^\d+(\.\d{0,7})?$/
    };
    return reglist[precisionNumber].test(text || '');
  },
  isNormalContactUs: function (countryCode: string): boolean {
    // 常规的联系中文客服，而非英文表单
    //     CHINA("CN", "ZH", "HK"),
    //     HONGKONG("HK", "HK", "HK"),
    //     TAIWAN("TW", "HK", "HK"),
    //     MACAO("MO", "HK", "HK"),
    //     OTHER("US", "EN", "EN"),MALAYSIA("MY", "EN", "EN"),,
    const normalContactUsCode = ['CN', 'ZH', 'HK', 'TW', 'MO', 'zh_CN'].map((item: string): string => item.toUpperCase());
    return normalContactUsCode.includes((countryCode || '').toUpperCase());
  },
  needFillUserInfo: function (socialReducer: SocialStateType, homeReducer: HomeStateType): boolean {
    const {
      retrieveReady,
      socialUserInfo,
      socialAnonymous,
      socialUserNotExist
    } = socialReducer;
    if (socialAnonymous) {
      return false;
    }
    if (homeReducer.isLogin && retrieveReady) {
      const noSocialUser =
        !socialUserInfo ||
        (this.isEmpty(socialUserInfo) ||
          !socialUserInfo.avatar ||
          !socialUserInfo.nickName);

      if (noSocialUser && socialUserNotExist) {
        return true;
      }
      // if (noSocialUser) {
      //   this.props.dispatch({
      //     type: ActionTypes.ACCOUNT_USER_INFO_ERROR
      //   });
      // }
    }
    return false;
  }
};
