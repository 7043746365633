// @flow
// import { Client, Configuration } from 'bugsnag-react-native';
// import { BUGSNAG_KEY, API_ENV } from '@env';

// let Bugsnag, configuration
// if (__DEV__) {
//   console.log('Development');
// } else {
//   configuration = new Configuration()
//   configuration.apiKey = BUGSNAG_KEY
//   configuration.releaseStage = API_ENV;

//   Bugsnag = new Client(configuration);
// }

module.exports = {
  notify: function (data: Error) {
    if (!__DEV__) {
      // Bugsnag.notify(data)
    }
  },
  setUser: function (id?: string, name?: string, email?: string) {
    // Bugsnag.setUser(id, name, email)
  }

}
