// @flow
import config from '../utils/config';
import request from '../utils/request';
import helper from '../utils/helper';
import type { CommonResponse, ExchangeResult } from './base';
export interface FetchRecommendItemType{
  symbol: string,
  name: string,
  logo: string
}

export interface HotSearchItemType{
  symbol: string,
  name: string,
  coinCode: string,
  logo: string
}
export interface SymbolItemType{
  convertCurrency: string,
  convertPrecision: number,
  convertPrice: number,
  customerId: string,
  picturePath: string,
  priceLast: number,
  pricePrecision: number,
  riseAndFall: number,
  sequence: number,
  symbol: string,
  volume: number
}

export interface ZonesItemType{
  categoryKey: string,
  categoryName: string
}
export interface FavoriteListResponseItemType {
  symbols?: SymbolItemType[],
  zones?: ZonesItemType[]
}
export type FetchRecommendItemResponseType = ExchangeResult<FetchRecommendItemType[]> & CommonResponse;
export type HotSearchResponseType = ExchangeResult<HotSearchItemType> & CommonResponse;
export type FavoriteAddResponseType = CommonResponse;
// export type FavoriteZoneAddResponseType = CommonResponse;
export type FavoriteListResponseType = ExchangeResult<FavoriteListResponseItemType> & CommonResponse;
export type FavoriteSortResponseType = CommonResponse;
// export type FavoriteZoneSortResponseType = CommonResponse;
export type FavoriteDeleteResponseType = CommonResponse;
export type FavoriteZoneDeleteResponseType = CommonResponse;
// class definition start

class FavoriteCustomApi {
  /**
   * fetch favorite recommend
   * @param null
   * @returns {*}
   */
  fetchRecommend (): Promise<FetchRecommendItemResponseType> {
    const URL = config.api.host_api_v1 + config.api.favoriteCustom.favoriteRecommend;
    return request.get.call({}, URL);
  };

  hotSearch (): Promise<HotSearchResponseType> {
    const URL = config.api.host_api_v1 + config.api.favoriteCustom.hotSearch;
    return request.get.call({}, URL);
  };

  favoriteAdd (params: {items: string, favorite_type: string}): Promise<FavoriteAddResponseType> {
    const URL = config.api.host_api_v1 + config.api.favoriteCustom.favoriteAdd +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call(params, URL);
  };

  favoriteDelete (params: {items: string, favorite_type: string}): Promise<FavoriteDeleteResponseType> {
    const URL = config.api.host_api_v1 + config.api.favoriteCustom.favoriteDelete +
      '?' +
      helper.mapToQueryString(params);
    return request.delete.call({}, URL);
  };

  favoriteSort (params: {changes: string, favorite_type: string}): Promise<FavoriteSortResponseType> {
    const URL = config.api.host_api_v1 + config.api.favoriteCustom.favoriteSort +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call(params, URL);
  };

  // favoriteZoneAdd (params: {items: string}): Promise<FavoriteZoneAddResponseType> {
  //   const URL = config.api.host_api_v1 + config.api.favoriteCustom.favoriteZoneAdd +
  //     '?' +
  //     helper.mapToQueryString(params);
  //   return request.post.call(params, URL);
  // };

  // favoriteZoneDelete (params: {items: string}): Promise<FavoriteZoneDeleteResponseType> {
  //   const URL = config.api.host_api_v1 + config.api.favoriteCustom.favoriteZoneDelete +
  //     '?' +
  //     helper.mapToQueryString(params);
  //   return request.delete.call({}, URL);
  // };

  // favoriteZoneSort (params: {changes: string}): Promise<FavoriteZoneSortResponseType> {
  //   const URL = config.api.host_api_v1 + config.api.favoriteCustom.favoriteZoneSort +
  //     '?' +
  //     helper.mapToQueryString(params);
  //   return request.post.call(params, URL);
  // };

  favoriteList (): Promise<FavoriteListResponseType> {
    const URL = config.api.host_api_v1 + config.api.favoriteCustom.favoriteList;
    return request.get.call({}, URL);
  };
}

const FavoriteCustomAPI: FavoriteCustomApi = new FavoriteCustomApi();
export { FavoriteCustomAPI };
