/* @flow */

import React, { Component } from 'react';
import {
  Dimensions,
  StatusBar,
  Text,
  TouchableOpacity,
  // TextInput,
  View
} from 'react-native';
import p from '../../utils/Transfrom';
// $FlowIgnore
import Modal from 'react-native-modal';
// import I18n from '../../utils/i18n';
import type { ViewStyle } from 'react-native/Libraries/StyleSheet/StyleSheet';
// import { getClient } from '../../api/social';
// import { ModalIndicator, Toast } from 'teaset';
// import type { SocialResult } from '../../api/base';
// import RNToast from 'react-native-easy-toast';
// import type { Feed } from '../../models/social'
import FastImage from 'react-native-fast-image';
import I18n from '../../utils/i18n';

interface CommentAndReplyModalProps {
    modalVisible: boolean,
    closeModal: () => void,
    style?: ViewStyle
}

export default class ReleaseModal extends Component<CommentAndReplyModalProps, {}> {
  constructor (props: CommentAndReplyModalProps) {
    super(props);
    this.state = {};
  }

  render (): React$Node {
    // const that = this
    // const { sending } = this.state
    const { modalVisible, closeModal, onPressRelasePost, onPressRelaseLongPost } = this.props;
    return (
      <Modal
        backdropOpacity={0.6}
        transparent={true}
        avoidKeyboard
        coverScreen={true}
        onModalShow={() => {
          StatusBar.setBarStyle('dark-content');
        }}
        onModalWillHide={() => {
          StatusBar.setBarStyle('light-content');
        }}
        onBackdropPress={() => {
          closeModal();
        }}
        deviceHeight={Math.max(
          Dimensions.get('window').height,
          Dimensions.get('screen').height
        )}
        style={[{ justifyContent: 'flex-end', margin: 0 }, this.props.style]}
        isVisible={modalVisible}>
        <View
          style={{
            backgroundColor: 'white',
            borderTopLeftRadius: p(24),
            borderTopRightRadius: p(24),
            height: p(360)
          }}>
          <View style={{ paddingHorizontal: p(18), marginTop: p(20), height: p(48), alignItems: 'flex-end' }}>
            <TouchableOpacity activeOpacity={0.8}
              style={{ flex: 1 }}
              onPress={closeModal}>
            <FastImage
              source={require('../../images/close2.webp')}
              style={{
                width: p(48),
                height: p(48)
              }}
            />
            </TouchableOpacity>
          </View>

          <View style={{ alignItems: 'center', paddingHorizontal: p(200), marginTop: p(50), justifyContent: 'center', flexDirection: 'row' }}>
            <View style={{ marginRight: p(152) }}>
              <TouchableOpacity activeOpacity={0.8}
                style={{ alignItems: 'center' }}
                onPress={onPressRelasePost}>

                <View style={{ backgroundColor: '#FE8F40', width: p(100), height: p(100), borderRadius: p(100), justifyContent: 'center', alignItems: 'center' }}>
                  <FastImage
                    source={require('../../images/textIcon.webp')}
                    style={{
                      width: p(58),
                      height: p(58)
                    }}
                  />
                </View>
                <Text style={{ color: '#434B5F', fontSize: p(24), marginTop: p(24) }}>{I18n.t('pubslishPost')}</Text>
              </TouchableOpacity>
            </View>

            <View style={{}}>
              <TouchableOpacity activeOpacity={0.8}
                style={{ alignItems: 'center' }}
                onPress={onPressRelaseLongPost}>
                <View style={{ backgroundColor: '#9168FF', width: p(100), height: p(100), borderRadius: p(100), justifyContent: 'center', alignItems: 'center' }}>
                  <FastImage
                    source={require('../../images/longTextIcon.webp')}
                    style={{
                      width: p(58),
                      height: p(58)
                    }}
                  />
                </View>
                <Text style={{ color: '#434B5F', fontSize: p(24), marginTop: p(24) }}>{I18n.t('pubslishLongLext')}</Text>
              </TouchableOpacity>
            </View>

          </View>

        </View>

      </Modal>
    );
  }
}
