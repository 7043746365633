// @flow
const obj: { [key: string]: string} = 
{
   "no_internet":"无网络，请检查网络链接",
   "click_retry":"点击重试",
   "load_failed":"加载失败",
   "imagePicker_Title":"选择一张图片",
   "share_fail":"分享失败",
   "share_success":"分享成功",
   "line_not_install":"未安装line",
   "imagePicker_chooseFromLibraryButtonTitle":"本地相册",
   "imagePicker_takePhotoButtonTitle":"拍照",
   "regMessage01":"已阅读并接受",
   "regMessage02":"用户服务协议",
   "regMessage03":"请查收电子邮件并填写验证码",
   "regMessage04":"仍未收到？",
   "regMessage05":"重新发送",
   "gaAuthentication":"谷歌验证",
   "gaVerificationCode":"谷歌验证码",
   "smsVerification":"短信验证",
   "smsVerifyTip01":"请先填入短信验证码完成验证。",
   "gaVerifyTip01":"请先填入谷歌验证码完成验证。",
   "unBindPhoneTip":"解绑手机号码",
   "unBindGaTip":"解绑谷歌验证",
   "rebate":"推荐返佣",
   "logoutTip1":"您确定要退出吗？",
   "logoutReminder":"退出提醒",
   "auth":"身份认证",
   "registrationServiceAgreement":"注册服务协议",
   "phone":"手机验证",
   "rules":"规则",
   "country":"国家",
   "firstName":"名",
   "lastName":"姓",
   "gender":"性别",
   "login":"登录",
   "goLogin":"去登录",
   "about":"关于",
   "aboutus":"关于我们",
   "kline":"K线",
   "sure":"确定",
   "activity":"活动",
   "gotit":"确定",
   "back":"返回",
   "supportCenter":"帮助中心",
   "resetPass":"重置密码",
   "loginToRemind":"登录提醒",
   "loginToRemindTip1":"抱歉，您的登录已过期。",
   "loginToRemindTip2":"请重新登录。",
   "loginToRemindTip3":"您的账户在另一台设备上登录，如果不是您本人操作，请立即更改您的密码。",
   "loginToRemindTip4":"登录状态已失效，请重新登录",
   "passportId":"护照ID",
   "depositAddress":"充币地址",
   "depositPaymentID":"充币 ",
   "accountBalance":"账户余额",
   "firstNamePlaceHolder":"请输入您的名字",
   "lastNamePlaceHolder":"请输入您的姓氏",
   "passportIdPlaceHolder":"请输入您的护照ID",
   "codeisnull":"请输入验证码",
   "googleAuth":"谷歌验证",
   "enterGoogleCode":"请输入谷歌验证",
   "passwordSetting":"密码设置",
   "referralReward":"邀请返佣",
   "ReferralHistory":"邀请历史记录",
   "referFriends":"邀请朋友在Dipbit交易即可得20%交易手续费返佣",
   "referralCode":"我的邀请码：",
   "referralLink":"我的邀请链接：",
   "reward":"邀请返佣历史记录",
   "referralOne":"1. 请注意只有用户使用你的邀请链接注册才会被记为您的邀请。",
   "referralTwo":"2. 当您邀请的朋友成功交易时，您的邀请奖励将在第二天零点根据实时汇率计入DBT并分配到您的帐户（有可能会有延迟）。",
   "referralThree":"3. DipBit保留重新调整邀请规则的最终解释权。",
   "safetyVerification":"安全验证",
   "sSMS":"短信验证码",
   "sEmail":"邮箱验证码",
   "sGoogle":"谷歌验证码",
   "signOut":"登出",
   "male":"男性",
   "birthDay":"生日",
   "female":"女性",
   "account":"账户",
   "TradingHall":"交易中",
   "home":"主页",
   "reApply":"重新申请",
   "trading":"交易",
   "market":"市场",
   "oldPassword":"原始密码",
   "newPassword":"新密码",
   "confirmNewPassword":"确认密码",
   "NewsInformation":"新闻",
   "personCenter":"个人中心",
   "passportCover":"护照封面",
   "copySuccess":"复制成功！",
   "confirm":"确认",
   "yes":"是的",
   "region":"地区",
   "submit":"提交",
   "send":"发送",
   "copy":"复制",
   "paste":"粘贴",
   "onOrders":"冻结",
   "enable":"启用",
   "search":"搜索",
   "loginPassword":"登录密码",
   "resetBtnText":"重置",
   "tradePassword":"交易密码",
   "forgotPassword":"忘记密码",
   "resetPassword":"重置密码",
   "enter_email":"邮箱",
   "total":"总计",
   "toAll":"全部",
   "tips":"小贴士",
   "withdraw":"提币",
   "BTCDeposit":"BTC充币",
   "BTCWithdraw":"BTC提币",
   "allRecord":"全部记录",
   "amount":"数量",
   "address":"地址",
   "financialRecords":"财务记录",
   "phoneNumberPlaceholder":"请输入电话号码",
   "smsCodePlaceholder":"请输入短信验证码",
   "copyAddress":"复制地址",
   "showQRCode":"展示二维码",
   "addAddress":"添加地址",
   "history":"历史记录",
   "min":"最小",
   "fee":"费率",
   "cancel":"取消",
   "verifyNow":"即刻验证",
   "setup":"设置",
   "ok":"确定",
   "password":"密码",
   "type":"类型",
   "buy":"买",
   "pwConfirm":"确认密码",
   "phoneVerificationCode":"手机验证码",
   "enterNewAddress":"请输入新的地址",
   "enterLabel":"请输入备注",
   "enter_verification_code":"请输入验证码",
   "deleteAddress":"删除地址",
   "transAction":"交易",
   "addressManagement":"地址管理",
   "enterOrPasteAddress":"请输入地址",
   "passportInformationPage":"个人护照信息页",
   "passportSignature":"递交我的护照和个人签名",
   "withdraw_24h_limit":"24小时提币限额",
   "withdrawTip1":"每日最大提币额",
   "withdrawTip11":"单次最小提币额",
   "withdrawTip12":"单次最大提币额",
   "minimum_withdrawal":"最小提币额",
   "withdrawTip13":"出于安全考虑，当您使用新的提款地址时，我们将手动审核您的提款。请等待我们的工作人员拨打电话或发送电子邮件。",
   "withdrawTip2":"出于安全考虑，当您使用新的提款地址时，我们将手动审核您的提款。请等待我们的工作人员拨打电话或发送电子邮件。",
   "withdrawTip3":"暂不能提币",
   "withdrawTip4":"您必须设置电话号码才能继续提币",
   "withdrawTip5":"提币申请失败",
   "withdrawTip6":"请验证身份以提高提款效率。",
   "withdrawTip7":"提币申请通过",
   "withdrawTip8":"您的提币申请已收到，系统将会尽快安排操作。",
   "withdrawTip9":"您的提币超出限额。",
   "withdrawTip10":"我们正计划开通VIP频道，您可以在将来申请VIP以增加额度。",
   "withdrawTip14":"交易提醒",
   "withdrawTip15":"您即将花费 ",
   "withdrawTip16":"您的可用余额在交易上，您确定达成交易吗？",
   "depositTip1":"除此之外，请不要添加任何其他数字货币资产",
   "depositTip2":"充币到上述地址后，您需要等待网络节点确认，之后 ",
   "depositTip3":" 充币成功。",
   "networkConfirmation":"网络确认",
   "depositTip4":"请确认您的计算机和浏览器的安全性，以防止信息被篡改或泄露。",
   "depositTip5":"地址复制成功",
   "depositTip6":"到上述地址或资产将不予退还。",
   "depositTip7":"最小充值额",
   "depositTip8":"任何低于最小充币额的充值将不予退还。",
   "depositTip9":"仅发送",
   "depositTip10":"到这个充值地址。                            ",
   "passportTip1":"确保图片完整并且清晰可见，仅支持JPG图片格式。",
   "passportTip2":"确保图片完整并且清晰可见，护照必须有效，并且仅支持JPG图片格式。",
   "passportTip3":"请上传带有身份证，正面视图和个人签名的照片。个人签名包含“平台名称”和当前日期。请确保照片和个人签名的内容清晰可见。",
   "passportTip4":"请选择一个图片",
   "passvail":"密码格式错误",
   "gaVerification":"谷歌身份验证",
   "twopwd_is_diff":"两次输入密码不一致",
   "oldpwd_no_null":"原始密码不得为空",
   "newpwd_no_null":"新密码不得为空",
   "enterNewPassAgain":"请再次输入新密码",
   "loginpwd_no_null":"登录密码不得为空",
   "pwd_no_null":"密码不得为空",
   "Save_the_QR_code":"保存二维码",
   "deposit_address":"充币地址",
   "deposit_paymentId":"付款地址",
   "depositXRP1":"两者 ",
   "depositXRP2":"到Dipbit",
   "depositXRP3":"除此之外，请不要添加任何其他数字货币资产 ",
   "depositXRP4":"到上述地址，资产或将无法归还。",
   "depositXRP5":"充币到上述地址后，您需要等待网络节点确认，在一个节点确认后 ",
   "depositXRP6":"充币成功。",
   "depositXRP7":"和地址被需要去成功充值到您的",
   "depositXRP8":"充币确认",
   "Got_it":"了解",
   "depositIOT1":"充币地址不能使用。在转入或存入区块链后，任何进一步的转账或存款到同一地址将不予记入。",
   "depositIOT2":"除此之外，请不要添加任何其他数字货币地址 ",
   "depositIOT3":"到上述地址，资产或将无法归还。",
   "depositIOT4":"充币到上述地址后，您需要等待网络节点确认，在一个节点确认后 ",
   "serial_number":"序列号",
   "transaction_id":"交易ID",
   "register":"注册",
   "please_write_email":"邮箱",
   "please_write_pwd":"密码",
   "close":"关闭",
   "network":"请检查网络连接",
   "emailisnull":"邮箱不得为空",
   "emailvail":"邮箱地址格式错误",
   "captcha":"验证码",
   "invitationCode":"不需要邀请码",
   "reg_success":"注册成功，请登录",
   "login_success":"登录成功",
   "next":"下一步",
   "sell":"卖",
   "pwformat":"密码（6-20位字母数字组合）",
   "orders":"当前委托",
   "bid":"买单价格",
   "ask":"卖单价格",
   "spotsell":"现货卖",
   "spotbuy":"现货买",
   "tradlogin":"请登录后交易",
   "available":"可用",
   "deposit":"充币",
   "withdrawal":"提币",
   "verification":"验证",
   "time":"时间",
   "number":"数量",
   "newandold_no_null":"新密码不能与原密码相同",
   "exception":"服务器异常，请稍后访问",
   "exceptionLogin":"请登录！",
   "agreement":"请先阅读注册服务协议！",
   "spotAssets":"资产",
   "money":"金额",
   "company":"公司",
   "hide":"隐藏",
   "pulldown":"下拉刷新至最新数据：",
   "refreshing":"刷新至最新数据：",
   "realesePull":"发布最新版本更新：",
   "details":"详情",
   "officialNews":"官方新闻",
   "updateNow":"更新",
   "later":"后来",
   "done":"完成",
   "deleteAccount":"确认删除账户",
   "deleteConfirm":"删除",
   "continue":"继续",
   "dismiss":"取消",
   "emailAlready":"邮箱地址已占用",
   "depositIOT5":"充币成功。",
   "AccountLockout":"账户锁定",
   "attentions":"注意",
   "AccountLockout2":"您的账户被锁定",
   "referredFriends":"已邀好友",
   "referredReward":"已返佣金",
   "referredRatio":"返佣比例",
   "myReferralCode":"我的邀请码",
   "copyCode":"复制",
   "copyLink":"复制链接",
   "referredTip5":"邀请码复制成功",
   "referredTip6":"邀请链接复制成功",
   "securityPolicy":"安全政策",
   "balanceHome":"资产",
   "tradeCoins":"交易",
   "noTradeMarket":"暂未开放交易",
   "financialDesc":"专业团队打造的数字资产理财产品。",
   "financialDesc1":"超高年化,年化收益率高达60%",
   "financialDesc2":"活动期间冲币免提币手续费",
   "financialDesc3":"平台保障用户利益，所有理财产品用户享有保本权益",
   "rulesTitle":"交易规则",
   "rulesDesc1":"• 认购费率：认购费率为0",
   "rulesDesc2":"• 收益规则：首月起始8.00%，逐月2.00%递增，最高20.00%，按月付息，一年后到期还本。",
   "rulesDesc3":"• 起息日：T+1日计息，即投入后第二天开始计息。",
   "rulesDesc4":"• 赎回日：投入满1个月后，每月转出日可申请转出。",
   "rulesDesc5":"• 赎回费率：赎回费率为0",
   "rulesDesc6":"• 到账时间：实时到账。",
   "purchaseTypeTitle":"您计划购买多少？",
   "noApplyTitle":"无申请资格",
   "noApplyDesc1":"申请要求：您的账户余额大于 ",
   "noApplyDesc2":"您的账户余额： ",
   "noApplyDesc3":"申请是免费的，账户余额仅用来验证。",
   "depositBtn":"充值",
   "applySuccess":"成功申请",
   "applySuccessDesc":"如果购买数量不足，该项目将被取消。",
   "BuyUSDT":"购买USDT",
   "limitCounts":"交易限额：",
   "limitCounts2":"--50,000",
   "selectPaymentMethod":"选择付款方式",
   "pleasePay":"请付款",
   "pleasePayOnTime":"请按时付款",
   "confirmToCancel":"确认取消",
   "haventPaid":"我确认未付款。",
   "payDesc1":"• 您的转帐金额将记入卖家的帐户。在交易过程中，待售资产将保留在我们的平台上。",
   "payDesc2":"• 除了Reference ID之外，不要为其他转账添加任何其他词语。",
   "payDesc3":"• 请在规定的时间内完成付款，然后点击“我已付款”。卖家确认收据后，资产将自动转入您的帐户。",
   "payDesc4":"• 请使用平台实名的银行卡、支付宝等进行转款，否则卖家可拒绝成交并投诉。",
   "complaint":"申诉",
   "complaintDesc":"在申诉提交成功后您的资产将被冻结，并且客服将介入交易直到最后。恶意投诉人将被冻结账户。投诉可在买家完成付款后的5分钟内完成。",
   "confirmPayment":"确认付款",
   "payDesc":"请您确认是否已经支付该订单？",
   "waitForRelease":"等待放币......",
   "waitDesc":"等待卖家放币。",
   "ifNotPay":"如果您未付款",
   "ifNotReceived":"如果您未收到币",
   "notice":"注意",
   "paymentNoticeDesc":"商户支付方式变动，请您刷新重试。",
   "refresh":"刷新",
   "cancelNoticeDesc":"您在同一天取消订单数过多，并且将无法在第二天的0点之前发布购买广告！",
   "completed":"完成",
   "completedPay":"您已经完成该笔付款。",
   "cancelled":"取消",
   "cancelledPay":"您已经取消该笔付款。",
   "receiptQRCode":"收款码",
   "complainting":"申诉中......",
   "complaintingDesc":"请等待客服介入。",
   "copied":"复制成功",
   "orderTimeout":"付款超时，该订单取消。",
   "maximumCNY":"最大可购买50,000",
   "minimumCNY":"最少可购买",
   "duplicateOrder":"重复订单",
   "duplicateDetail":"您已经有一笔交易正在进行，请在该笔交易完成后再次购买！",
   "checkOrder":"检查订单",
   "language":"语言",
   "english":"简体中文",
   "skipBtnText":"跳过",
   "safetyTitle":"安全",
   "safetyDesc1":"构建高级系统架构以防御DDoS和其他潜在威胁，",
   "safetyDesc2":"",
   "safetyDesc3":"逾98%的数字资产存储在多重签名的冷钱包中。",
   "safetyDesc4":"",
   "speedTitle":"效率",
   "speedDesc1":"毫秒级响应，不断优化打磨的平台，",
   "speedDesc2":"具有可靠的效率和稳定性。",
   "speedDesc3":"",
   "serviceTitle":"服务",
   "serviceDesc1":"7 * 24小时在线，如有疑问，",
   "serviceDesc2":"请通过电子邮件和电报与我们实时沟通。",
   "serviceDesc3":"",
   "teamTitle":"团队",
   "teamDesc1":"Dipbit核心团队来自阿里，腾讯，百度等互联网企业，全球专业的金融量化团队，",
   "teamDesc2":"致力于打造最安全，最优的交易平台。",
   "teamDesc3":"",
   "getStarted":"开始",
   "withFiatMoney":"法币",
   "dipbitreasure":"币多盈",
   "optimizeAssets":"优化资产配置",
   "otcDesc1":"OTC交易尚未开放，敬请期待。",
   "otcDesc2":"",
   "deatils":"详情",
   "emptyFavorite":"您没有自选",
   "name":"币种",
   "vol":"成交量",
   "oneVol":"量",
   "lastPrice":"最新价格",
   "chg":"变动百分比",
   "alreadyLatestVersion":"已更新至最新版本",
   "version":"版本",
   "telegram":"电报",
   "productDescription":"产品简介",
   "publicKeyNumber":"公钥",
   "addTime":"添加时间",
   "label":"备注",
   "areYouSure":"确定?",
   "noAddress":"您尚未创建地址",
   "accessCamera":"允许我们获取您的照相机权限?",
   "setProfile":"我们需要权限来设置您的资料照片",
   "noWay":"失败",
   "openSettings":"打开设置",
   "QRScan":"将二维码/条形码与要扫描的框对齐",
   "scanQRCode":"扫描二维码",
   "googleAuthStep1":"1.安装“Google身份验证器”并运行它，点击右上角的“+”，选择“手动输入”。",
   "googleAuthStep2":"2.粘贴“密钥”，点击“完成”",
   "googleAuthStep3":"3.复制“Google授权码”并将其粘贴在“Google Code”输入中。请勿删除Google授权帐户。如果您误删了它，可以重置。",
   "linked":"已验证",
   "unbindingGoogleAuthentication":"解绑谷歌验证 ？",
   "passwordResetComplete":"密码重置成功",
   "rejected":"已拒绝",
   "verified":"已认证",
   "unverified":"未认证",
   "turnedOn":"打开",
   "unopened":"未开启",
   "notLinked":"未验证",
   "pleaseInputTelephone":"请输入您的电话号码",
   "SMSSendSuccessfully":"短信发送成功",
   "failedVerificationCode":"无法获取验证码,请检查网络连接",
   "pleaseInputSmsCode":"请输入短信验证码",
   "unbindingPhoneNumber":"解绑手机号码?",
   "authenticationVerified":"您的授权正在验证中…",
   "identityNotApproved":"您的身份未支持.",
   "failedReason":"失败原因:",
   "signedUpTime":"注册时间",
   "loading":"加载中",
   "referFriendsTrade":"推荐朋友在Dipbit进行交易，获得 ",
   "transactionFeesReward":"交易费作为推荐奖励。",
   "coin":"币种",
   "allocatedTime":"分配时间",
   "resetPasswordLogin":"重置密码成功,即刻登录!",
   "sentVerificationCode":"我们已发送验证码",
   "fillVerificationCode":"请填入此验证码.",
   "checkInternetTryAgain":"网络不可用,请检查连接并重试.",
   "fiat":"法币",
   "totalBalance":"总资产",
   "depositFirst":"无资产,请先存入",
   "coinWithoutAssets":"资产为零币种",
   "noResult":"没有结果",
   "haveNoFinancialRecords":"你尚未有理财记录",
   "withdrawSuspended":"提币已被延迟",
   "depositSuspended":"存币已被延迟",
   "bindMobileBeforeWithdraw":"请在提笔钱绑定手机号码",
   "haveNoOrder":"暂无委托",
   "haveNoDeposit":"您没有存币记录",
   "inputRightCNY":"请输入正确的人民币金额",
   "inputRightUSDT":"请输入正确的USDT数量",
   "gets":"得到",
   "needHelp":"需要帮助?",
   "submittedSuccessfully":"提交成功",
   "complaintReason":"申诉原因",
   "enterComplaintReason":"请输入申诉详情",
   "paymentMethod":"支付渠道:",
   "wechat":"微信支付",
   "alipay":"支付宝",
   "cancelOrder":"取消委托",
   "payee":"收款方:",
   "referenceID":"参考号",
   "seller":"卖方",
   "orderID":"订单号",
   "haveNoOrders":"暂无委托",
   "allStatus":"所有状态",
   "processing":"处理中",
   "complete":"已完成",
   "Complaining":"申诉中",
   "Paid":"已付款",
   "Unpaid":"待付款",
   "Cancelled":"已取消",
   "lessThan":"少于",
   "moreThan":"多于",
   "annualYield":"预期最高年化",
   "investTable":"历史净值走势",
   "fixedAnnualYield":"稳定年化",
   "notFixedType":"     进取型 ",
   "fixedType":"     稳健型  ",
   "lockupPeriod":"锁定期限",
   "days":"天",
   "progress":"起投金额",
   "purchaseQuantity":"购买数量:",
   "openPurchaseTime":"开放购买时间:",
   "applyForFree":"立即抢购",
   "applyLockBtn":"锁仓中",
   "applyOverBtn":"项目已结束",
   "reservation":"募资期",
   "purchasePeriod":"锁仓期",
   "incomePeriod":"结算日",
   "haveNoProject":"您尚未有理财计划。",
   "fixedRate":"固定收益",
   "monthlyYieldGrowth":"月月升",
   "weeklyYieldGrowth":"周周升",
   "favorites":"自选",
   "discover":"社区",
   "totalValue":"总资产",
   "loginToView":"登录查看",
   "topVolume":"成交量",
   "topGainers":"涨幅榜",
   "topLosers":"跌幅榜",
   "setPassword":"设置密码",
   "pwdRules":"6-20 位, 字母 + 数字",
   "resendSuccess":"重新发送成功",
   "emailVerification":"邮箱验证",
   "verificationCode":"验证码已被发送至",
   "resend":"重新发送",
   "notReceivedEmail":"如果仍未收到邮件,请查看垃圾邮件.",
   "pwdError":"密码错误, ",
   "inputLeft":" 剩余输入机会",
   "accountLocked":"账户已锁定无法登录, 请于",
   "minutesToUnlock":"分钟后重试",
   "emailRegistered":"邮箱已被注册,如果您已有账户,请登录",
   "mobileRegistered":"手机号已被注册,如果您已有账户,请登录",
   "registrationMeans":"注册即代表接受",
   "userService":"用户协议",
   "pleaseClickBack":"请点击返回键退出",
   "balance":"资产",
   "noNotice":"暂无通知",
   "limit":"限价",
   "BUY":"买入",
   "SELL":"卖出",
   "status":"状态",
   "noRewardHistory":"您没有返佣历史",
   "clickReload":"点击重新加载",
   "cancelSuccessfully":"取消成功",
   "cancelFailed":"取消失败",
   "successfullyAdd":"添加成功",
   "successfullyDelete":"删除成功",
   "executed":"执行",
   "today":"今日",
   "noOrder":"暂无委托",
   "openOrders":"待成交",
   "ordersHistory":"委托历史",
   "marketTrades":"成交明细",
   "depth":"深度",
   "joinMyFavorite":"加入自选",
   "removeFavorite":"从自选中移除",
   "depthChart":"深度",
   "high":"最高",
   "low":"最低",
   "inputRightPrice":"请输入正确价格",
   "inputRightAmount":"请输入正确数量",
   "inputRightAccount":"请输入正确账户",
   "noEnoughAssets":"余额不足",
   "notEnoughCoin":"余币不足",
   "all":"全部",
   "marketTrading":"市价交易:最优市场价格",
   "avaliable":"可用",
   "loginTrading":"登录",
   "avgPrice":"均价",
   "enterWalletAddress":"请输入钱包地址",
   "atention":"注意",
   "addAddressSuccessfully":"成功添加地址",
   "enterValidAddress":"请输入有效提币地址",
   "withdrawalAmount":"提币量小于账户余额",
   "withdrawalRequest1":"您的提币请求超出最小提币限额,无法提币",
   "withdrawalRequest2":"您的提币请求超出最大提币限额,无法提币",
   "withdrawalRequest3":"您的提币请求超出单日最大提币限额,无法提币",
   "chooseTheAddress":"选择地址",
   "useNewAddress":"使用新地址",
   "noRecordWithdraw":"您暂无提币记录",
   "create":"创建",
   "totalTrades":"全部交易",
   "completionRate":"完成率",
   "averageProcessingTime":"平均处理时间",
   "pay":"付款",
   "verifyGoogle":"谷歌验证",
   "verifySMS":"短信验证",
   "googleVerification":"谷歌验证",
   "pleaseInputCode":"请输入验证码",
   "failedGetVerificationCode":"验证码获取失败,请检查网络连接",
   "inputGoogleCode":"请输入谷歌验证码",
   "sending":"发送中",
   "sendSuccessfully":"短信发送成功",
   "resendAfter":"后重新发送 ",
   "securityDesc":"为了您的资产安全,请遵循我们的安全政策",
   "identityAuthentication":"身份认证",
   "verifiedFailed":"验证失败",
   "phoneAuthentication":"手机验证",
   "google":"谷歌",
   "assetsSecurity":"• 为了您的资产安全,提币需要手机验证码和谷歌验证码.",
   "withdrawalMore":"• 提币大于",
   "requiresIdentityAuthentication":"需要身份验证.",
   "assetsSecurityFirst":"资产安全第一",
   "uploadSuccessful":"上传成功",
   "set":"设置",
   "verifying":"验证中",
   "reset":"重置",
   "identity":"身份",
   "investMarket":"理财市场",
   "investAccount":"理财账户",
   "myProjects":"我的持有",
   "update":"更新",
   "expired":"失效",
   "androidVerificationModuleError":"安卓验证模式错误: ",
   "iosVerificationModuleError":"IOS验证模式错误: ",
   "sResend":"s 重新发送",
   "inputSmsCode":"请填写短信验证码",
   "inputEmailCode":"请填写邮箱验证码",
   "checkTheEmail":"请检查邮箱,输入邮箱验证码,完成验证.",
   "notReceiveYet":"仍未收到?",
   "sendAgain":"重新发送",
   "congratulationsSuccessfulDeposit":"您已成功存入",
   "seeFullDetails":"，请查看详细信息.",
   "congratulationsSuccessfulWithdraw":"您已成功取出",
   "successfulTransaction":"订单上交易成功",
   "order":"订单",
   "tradedLongTime":"您的订单长期未交易。",
   "youHaveNotification":"您有一个未读消息",
   "rewardHistory":"返佣历史",
   "referralHistory":"邀请历史",
   "registerForDipbit":"注册Dipbit",
   "achieveYourDream":"成就您的梦想！",
   "isComing":"即将上线...",
   "depositProfit":"闲钱理财，存币得息，毋需交易!",
   "invitationCodeIfApply":"邀请码(如有)",
   "sureIt":"确定",
   "success":"成功",
   "failed":"失败",
   "passwordResetSuccess":"重置密码成功",
   "enterRegisteredEmail":"请输入您已注册的邮箱",
   "investfixedRate":"募资期固定年化",
   "investing":"募资中",
   "upTime":"到期",
   "beforeupTime":"",
   "myProjectsMoney":"持仓金额",
   "yestordayIncome":"昨日收益",
   "totalIncome":"累积收益",
   "invesAccounttLast":"理财账户余额",
   "investRecord":"财务记录",
   "transactionIn":"转入",
   "transactionOut":"转出",
   "buyTime":"购买时间",
   "getTime":"赎回时间",
   "buyAllAmount":"买入总量",
   "getAllAmount":"赎回总量",
   "income":"收益",
   "buyIn":"购买",
   "everyAmount":"每份数量",
   "maxBuyAmount":"最大投入份数",
   "transmentIn":"划转",
   "buyInAmount":"购买份数",
   "agree":"同意",
   "agreementText":"币多盈理财协议",
   "agreementTextTitle":"理财协议",
   "sureBuyIn":"确认转入",
   "sureBuyOut":"确认转出",
   "sureBuy":"确认购买",
   "AmountUnit":"份",
   "daysIncome":"天预期收益",
   "inputMoney":"投入金额",
   "buySuccess":"购买成功",
   "startIncomeTime":"开始收益时间",
   "startComputeTime":"结算日起",
   "lookUpMyInvest":"查看我的理财",
   "getYourGift":"领取你的专属客服",
   "investNote":"为了实时解答您的问题，更好的为您提供服务，烦请添加我们的客服微信号或加入专属Telegram群组：",
   "wechatImage":"客服微信二维码",
   "telegramImage":"Telegram群二维码",
   "transferFrom":"从",
   "transferTo":"到",
   "transferAccount":"交易账户",
   "transferAmount":"划转数量",
   "tradeType0":"转入",
   "tradeType1":"转出",
   "tradeType2":"购买",
   "tradeType3":"赎回",
   "freeFee":"免费",
   "freeWithdraw":"剩余免收手续费提币次数",
   "freeTime":"次",
   "enterRightNum":"请输入正确的数值",
   "balanceRunsOut":"理财账户余额不足",
   "overTopLimit":"已经超过最大投入值",
   "bitRunsOut":"交易账户余额不足",
   "lockUp":"锁仓中",
   "ended":"已结束",
   "cardFace":"身份证正面照片",
   "cardFaceDesc":"请确保照片的內容完整并清晰可见。",
   "cardOpposite":"身份证背面照片",
   "cardOppositeDesc":"请确保照片的內容完整并清晰可见,身份证必须在有效期内。",
   "idDipbitCard":"手持身份证和签名信息拍照",
   "idDipbitCardDesc":"请上传手持身份证人像面和个人签字照片,签字内容包含“dipbit”和当前日期。",
   "cardDesc1":"• 仅支持jpg、PNG图片格式,请将照片大小保证在2M以內，",
   "cardDesc2":"• 切勿提交与自己身份不相吻合的照片或其他图片,否则平台有权对您进行暂停使用、封号等一切严肃的处理手段。",
   "confirmSubmit":"确认提交",
   "checking":"认证审核中",
   "checkingDesc":"您提交的资料将在3-5个工作日审核完毕，请耐心等待。",
   "checkSuccess":"认证成功",
   "checkFailed":"认证失败",
   "checkFailedDesc":"您提供的身份信息不清晰,请重新提交",
   "submittedFailed":"提交失败",
   "submittedSuccessfullyDesc":"您提交的资料将在3-5个工作日审核完毕，请耐心等待。",
   "submittedFailedDesc":"您尚未完成全部信息的提交",
   "recertification":"重新认证",
   "uploadAgain":"点击图片重新上传",
   "transferSuccess":"划转成功",
   "inviteFriends":"邀请好友",
   "exclusivePosters":"生成专属海报",
   "invitedNumber":"已邀请人数",
   "accumulatedRewards":"获得奖励(DBT)",
   "twitter":"Twitter",
   "facebook":"Facebook",
   "wechatWay":"微信",
   "weibo":"微博",
   "qqWay":"QQ",
   "sharePosters":"分享海报",
   "shared":"分享",
   "activityRules":"活动细则",
   "rewardRecord":"奖励记录",
   "referralRecord":"邀请记录",
   "referralAccount":"被邀请人账户",
   "rewardType":"奖励类型",
   "rewardCount":"奖励数量",
   "shareTitle":"亲爱的朋友，已送您100个DBT, 请尽快领取!",
   "getCoin":"邀请送币",
   "noRecords":"暂无记录",
   "currentExchangeRate":"实时汇率：",
   "priceRealTime":"价格（实时）",
   "faitTotal":"总量",
   "chooseMerchant":"选择商户",
   "cancelOrderPay":"取消订单",
   "iHavePay":"已支付",
   "wallet":"零钱宝",
   "iKonw":"知道了",
   "yestordayAllIncome":"昨日零钱收益",
   "AllIncome":"累积零钱收益",
   "pennyIncome":"零钱收益",
   "loginDipbit":"请登录",
   "contactUs":"联系客服",
   "fiatTrading":"法币交易",
   "startChat":"开始实时聊天",
   "enterChatEmail":"请输入您的邮箱",
   "exceeding_quota":"超过限额",
   "remaining_amount_to_followed":"该KOL剩余可跟随额度",
   "total_amount_to_follow":"总计可跟随额度",
   "total_amount_followed":"当前已跟随总额",
   "customer.login.incorrect":"用户信息已过期",
   "system.parameter.invalid":"请求参数无效",
   "system.success":"成功",
   "customer.login.new":"退出",
   "customer.password.old.required":"原始密码不能为空",
   "customer.verify.code.expire":"验证码错误",
   "customer.verify.type.invalid":"验证类型无效",
   "trade.forbidden.invalid.count":"委托数量无效",
   "trade.value.greater.than.order.max":"单笔交易上限 %{amount} %{currency}",
   "customer.verify.inactive":"登录所用账号无效",
   "trade.forbidden.by.balance.volume":"定价币不够",
   "customer.phone.required":"手机号码不能为空",
   "customer.kyc.id.used":"该身份证号已被使用过，请更换",
   "customer.kyc.verify.fail":"KYC认证失败",
   "customer.kyc.live.result.check.fail":"活体检测、人脸识别失败",
   "customer.kyc.not.submit":"用户未提交过KYC",
   "customer.kyc.info.insufficient":"kyc信息不足",
   "customer.kyc.id.expire":"证件信息已过期或无效",
   "customer.kyc.reject.unsupported.id.type":"证件类型不支持",
   "customer.google.unbind.fail":"google二次验证解绑失败",
   "customer.phone.on.fail":"手机认证打开失败",
   "customer.locked":"您的帐户已经被锁，请联系客服",
   "customer.google.on.error":"google二次验证无法打开",
   "customer.password.same":"新密码不能与原密码相同",
   "customer.phone.off.success":"关闭手机验证成功",
   "trade.entrust.cancel.success":"取消成功",
   "customer.exist.none":"用户不存在",
   "customer.email.format.incorrect":"邮箱地址格式不正确",
   "customer.kyc.duplicate":"用户已通过验证",
   "customer.verify.email.inactive":"需要激活邮箱",
   "trade.forbidden.by.date":"此时间段暂停服务",
   "customer.google.off.period":"为了您的财产安全，关闭google二次验证后的24小时之内无法提现",
   "customer.email.required":"邮箱地址不能为空",
   "customer.email.duplicate":"邮箱地址不能重复",
   "trade.forbidden.customer.status":"账户未激活",
   "customer.reg.email.duplicate":"邮箱已注册",
   "customer.google.verify.code.incorrect":"google验证码不能为空",
   "customer.phone.code.often":"短信验证码发送频繁，请稍后再试",
   "customer.phone.code.limit":"短信验证码发送次数超单日上限",
   "trade.forbidden.by.system":"系统维护，交易终止",
   "trade.forbidden.by.precision.volume":"位数需要小于或等于{0}",
   "coin.transaction.nonexist":"记录不存在",
   "coin.address.outer.required":"Public key id为空",
   "customer.phone.format.incorrect":"电话号码格式错误",
   "system.cache.refresh.fail":"系统缓存刷新失败",
   "system.parameter.type.mismatch":"参数类型不匹配",
   "customer.google.on":"google二次验证打开成功",
   "customer.login.success":"登录成功",
   "customer.forbidden":"您的帐户已停用，请联系客服",
   "trade.forbidden.invalid.sum":"请输入委托量",
   "customer.login.required":"用户未登录",
   "customer.verify.code.incorrect":"邮箱验证码错误",
   "customer.email.code.incorrect":"邮箱验证码错误",
   "customer.verify.code.sent":"验证码已发送",
   "customer.verify.phone.unbind":"需要绑定手机",
   "customer.phone.code.required":"手机验证码不能为空",
   "coin.address.save.success":"保存成功",
   "customer.phone.send.fail":"验证码发送失败",
   "customer.phone.duplicate":"手机号码已绑定",
   "customer.verify.twice":"需要验证两次",
   "coin.address.invalid":"无效的地址",
   "customer.phone.off.fail":"验证手机崩溃失败",
   "trade.forbidden.by.balance.price":"账户余额不足",
   "customer.query.success":"邮箱地址已注册",
   "system.parameter.required":"参数[{0}]为空",
   "customer.password.reset.success":"密码重置成功",
   "customer.login.password.error":"邮箱或密码错误，剩余{0}次机会尝试",
   "system.error":"网络开小差，请稍后重试",
   "customer.phone.new.duplicate":"新电话号码不能与旧电话号码重复",
   "customer.google.off":"关闭google验证成功",
   "trade.forbidden.invalid.price":"无效的委托价格",
   "customer.phone.code.incorrect":"手机验证码错误",
   "incorrect.invitation.code":"邀请码错误",
   "customer.registration.success":"注册成功",
   "customer.google.auth.fail":"谷歌验证码错误",
   "customer.kyc.expire":"验证过期，请稍后再试",
   "customer.password.reset.fail":"密码重置失败",
   "customer.google.success":"google验证成功",
   "customer.password.format.incorrect":"密码格式错误",
   "customer.phone.error":"手机认证失败",
   "trade.forbidden.by.num.max":"订单数量超过最大限制{0}",
   "trade.forbidden.by.total.min":"订单数量小于最小限制{0}",
   "activity.not.start.code":"请等待活动开始",
   "customer.password.different":"两次输入的密码必须相同",
   "system.parameter.format.incorrect":"参数[{0}]不正确",
   "system.data.not.found":"无法找到系统数据",
   "system.parameter.digital.required":"参数[{0}]必须为数字",
   "customer.phone.on.success":"请使用您的手机通过验证",
   "coin.interface.error":"币的内部系统错误",
   "trade.forbidden.customer.uncertified":"请进行实名认证",
   "trade.entrust.exist.none":"没有找到该委托",
   "trade.entrust.cancel.fail":"取消失败",
   "system.operation.frequent":"操作过于频繁，请稍后再试",
   "trade.forbidden.by.precision.price":"价格位数应小于或等于{0}位",
   "customer.virtual.account.nonexist":"虚拟账户不存在",
   "customer.google.off.error":"关闭google验证失败",
   "trade.forbidden.by.symbol":"该交易对暂时未开启",
   "coin.address.outer.mismatch":"Public key不属于这个用户",
   "trade.forbidden.customer":"您已被禁止交易",
   "system.security.param.required":"安全检查参数为空",
   "trade.forbidden.by.num.min":"最小下单量不能小于{0}",
   "customer.reset.password.error":"密码错误",
   "coin.address.outer.duplicate":"钱包的public key不能重复",
   "coin.code.nonexist":"币代码不存在",
   "customer.google.bind.fail":"google二次验证绑定失败",
   "customer.phone.success":"手机认证成功",
   "customer.password.required":"密码不能为空",
   "trade.forbidden.by.updown":"不能超过现价的{0}",
   "trade.entrust.success":"委托成功",
   "customer.reg.fail":"注册失败",
   "system.security.check.fail":"安全检查失败",
   "customer.password.new.required":"新密码不能为空",
   "customer.google.unbind.success":"google验证解绑成功",
   "customer.google.bind.success":"google验证绑定成功",
   "customer.verify.code.sent.often":"邮件验证码发送过于频繁，请稍后再试",
   "coin.withdraw.success":"提币申请成功",
   "coin.withdraw.amount.onetime.min.less":"单次提币金额小于最小提币数",
   "coin.withdraw.amount.onetime.min.less.unnamed":"请进行身份认证以提高提币额度",
   "coin.withdraw.amount.onetime.max.exceed.unnamed":"请进行身份认证以提高提币额度",
   "coin.withdraw.amount.day.max.exceed.unnamed":"请进行身份认证以提高提币额度",
   "coin.withdraw.closed":"暂不支持提币",
   "coin.withdraw.amount.onetime.max.exceed":"单次提币金额超过最大值",
   "coin.withdraw.amount.day.max.exceed":"今日可提币数超过最大限额",
   "coin.withdraw.balance.insufficient":"账户余额不足",
   "client.coin.withdraw.minimum.limit":"您的提笔额低于最小提币数",
   "client.coin.withdraw.maximum.limit":"您的提币额超过了最大提币数",
   "client.coin.withdraw.today.maximum.limit":"您的提笔总额已超过单日限额",
   "client.copy.success":"{0}复制成功",
   "client.copy.failure":"{0}复制失败",
   "client.user.not.exist":"用户不存在",
   "client.user.registered":"该用户已注册",
   "active.not.start.code":"请耐心等待活动的开启",
   "coin.code.or.address.empty":"币种和地址不能为空",
   "coin.channel.unavailable  ":"币种地址不可用",
   "coin.channel.validation.exception":"地址验证失败",
   "trade.entrust.add.invalid.symbol":"添加委托的参数符号无效",
   "trade.entrust.add.invalid.type":"添加委托的参数类型无效",
   "trade.entrust.add.invalid.source":"添加委托的参数源无效",
   "trade.entrust.add.invalid.way":"添加委托的委托方式无效",
   "trade.entrust.add.invalid.price":"添加委托的委托价格无效",
   "trade.entrust.add.invalid.count":"添加委托的委托数量无效",
   "trade.entrust.add.invalid.sum":"添加委托的委托总量无效",
   "otc.place.order.success":"下单成功",
   "otc.place.order.fail":"下单失败",
   "otc.cancel.order.success":"挂单取消成功",
   "otc.cancel.order.fail":"挂单取消失败",
   "otc.payer.confirm.success":"付款人确认成功",
   "otc.payer.confirm.fail":"付款人确认失败",
   "otc.payee.confirm.success":"收款人确认成功",
   "otc.payee.confirm.fail":"收款人确认失败",
   "otc.appeal.order.success":"申诉成功",
   "otc.appeal.order.fail":"申诉失败",
   "otc.appeal.order.finish.success":"申诉结果更新成功",
   "otc.appeal.order.finish.fail":"申诉结果更新失败",
   "otc.order.query.success":"查询成功",
   "otc.order.query.fail":"查询失败",
   "otc.no.data.found":"订单信息不存在",
   "otc.incorrect.operation":"目前订单状态不允许操作",
   "otc.has.timed.out":"订单超时",
   "otc.cancel.more.than.three.times":"取消超过3次，今日无法进行操作",
   "otc.order.invalid.parameter":"参数'{0}'无效",
   "otc.current.customer.cannot.process.this.order":"无法处理此订单",
   "otc.incomplete.orders.exists":"存在未完成订单",
   "otc.trans.amount.check.fail":"转账金额检查失败",
   "coin.withdraw.amount.less.fee":"提币金额小于手续费",
   "customer.kyc.reject.duplicate.id":"证件已被使用",
   "customer.kyc.reject.image.not.readable":"您的证件不清晰，系统无法识别，请重新拍照上传。如有问题，请到【我的】-【其它设置】-【联系客服】联系我们。",
   "customer.kyc.reject.unsupported.id.country":"证件信息与提交的国家不符合",
   "customer.kyc.reject.handheld.fraud":"手持身份证的照片信息不符合平台要求",
   "go_alipay":"去支付",
   "common_network_error":"网络开小差，请稍后重试",
   "follow_heroes":"交易员",
   "follow_my_copies":"我的跟单",
   "follow_master_hero":"交易大师",
   "follow_all":"全部",
   "follow_copy":"跟随",
   "follow_popularity":"人气榜",
   "follow_new_people":"新人榜",
   "follow_total_earning":"总收益榜",
   "follow_total_return":"总收益额",
   "follow_total_return_title":"总收益额",
   "follow_total_return_ratio":"总收益率",
   "follow_total_assets":"总资产榜",
   "follow_return":"收益额",
   "follow_all_risk":"全部风险等级",
   "follower_level1":"1-3级",
   "follower_level2":"4-6级",
   "follower_level3":"7-10级",
   "follower_currency":"{name}",
   "follower_all_currency":"全部持仓币种",
   "follow_week_return":"周收益额",
   "follow_keep_currency":"持仓币种",
   "follow_return_ratio":"收益率",
   "follow_week_return_ratio":"周收益率",
   "follow_copiers":"跟随人数",
   "follow_week_hero":"本周交易之星",
   "follow_all_heroes":"所有交易员",
   "follow_network_error":"因网络原因，导致跟随失败，请重新尝试。",
   "follow_copying":"正在跟随",
   "follow_amount":"本金",
   "follow_history":"历史跟随",
   "follow_stop_success":"取消成功",
   "follow_stop":"取消跟随",
   "follow_stop_fail":"因网络原因，导致取消跟随失败，请重新尝试。",
   "follow_form_all":"全部",
   "follow_form_tip_balance":"本金必需大于%{min}USDT,小于%{max}USDT",
   "follow_form_tip_stop_profit":"止盈比例必须大于等于%{min}%小于等于%{max}%",
   "follow_form_tip_stop_loss":"止损比例必须大于等于%{min}%小于等于%{max}%",
   "follow_amount_min":"最小跟随本金",
   "follow_balance":"可用",
   "follow_transfer":"划转",
   "follow_stop_loss_rate":"止损比例",
   "follow_stop_profit_rate":"止盈比例",
   "follow_stop_loss_at":"预计亏损掉",
   "follow_at_cancel":"时取消跟随",
   "follow_stop_profit_at":"预计盈利",
   "follow_confirm":"确认",
   "follow_save":"保存",
   "follow_saving":"提交中...",
   "follow_stop_tip":"取消跟随后，不再跟随交易员下单。已跟随开仓的订单，将保持同步平仓。",
   "follow_stop_keep":"暂不取消",
   "follow_success":"跟随成功",
   "follow_complete":"完成",
   "follow_detail":"查看详情",
   "follow_copy_return":"跟随收益",
   "follow_copy_return_rate":"跟随收益率",
   "follow_days":"跟随天数",
   "follow_edit":"编辑",
   "follow_share":"分享",
   "follow_status_cancel":"取消跟随",
   "follow_status_fill":"止盈止损",
   "follow_share_to_friends":"炫耀一下",
   "follow_order_id":"订单号",
   "follow_status":"当前状态",
   "follow_order_detail":"历史跟随详情",
   "follow_copy_fee":"手续费",
   "follow_profit_share":"分润",
   "follow_profit_fee":"平台服务费",
   "follow_start_date":"开始跟随",
   "follow_end_date":"结束跟随",
   "follow_kol_copying":"跟随中",
   "follow_kol_history":"历史跟随",
   "follow_kol_trader":"交易员",
   "follow_kol_all_traders":"全部交易员",
   "follow_kol_all_earning":"累计分润收益",
   "follow_kol_earning_note":"注：收益结算后自动划转至",
   "follow_kol_copy_account":"跟单账户",
   "follow_kol_feed_setting":"分润设置",
   "follow_kol_setting_tip":"用户跟单结束后获得的正向收益，按照分润比例归交易员所有。",
   "follow_kol_api_setting":"API管理",
   "follow_kol_return":"跟随收益",
   "follow_kol_amount":"本金",
   "follow_kol_now_copy_day":"跟随(天)",
   "follow_kol_history_copy_day":"跟随(天)",
   "follow_kol_detail":"跟随中详情",
   "follow_kol_history_detail":"历史跟随详情",
   "follow_profit_share_percent":"分润比例(%)",
   "follow_bad_profit_share_percent":"分润比例必须大于%{min}%,小于%{max}%",
   "follow_save_success":"保存成功",
   "api_integration":"API接入",
   "api_tip":"提示",
   "api_new_tip":"KIKI建议您启用一个全新的API，和授权我们的日期同步。以便我们及时提示您API的到期，以免影响跟随者的收益和您的影响力。",
   "api_new_understand":"知道了",
   "api_save_error":"API错误，请检查输入！",
   "api_authorize_success":"API授权成功！",
   "api_saving":"授权中...",
   "api_delete":"注销API",
   "api_delete_success":"API注销成功",
   "api_readonly_tip":"API仅需只读权限",
   "api_keep_tip":"请妥善保管好API密钥等信息，不要像任何人透露该信息",
   "api_access_key_tip":"请输入 Access Key",
   "api_secret_key_tip":"请输入 Secret Key",
   "api_passphrase_tip":"请输入 Passphrase",
   "api_document":"查看API接入",
   "api_document_link":"教程",
   "api_grant":"授权接入",
   "api_renewal":"API更新",
   "api_delete_tip":"注销当前的API，会引发跟随您的用户的强制平仓，确定要注销API吗？",
   "api_cancel":"取消",
   "api_confirm":"确认注销",
   "safety_setting":"安全相关",
   "safety_google":"谷歌验证",
   "safety_change_pwd":"修改密码",
   "safety_google_deactivate":"解绑谷歌验证",
   "safety_google_active":"开启谷歌验证",
   "safety_google_store":"在应用商店中搜索“Google Authenticator” 应用并下载",
   "safety_google_store_go":"前去下载",
   "safety_google_store_after":"安装完成后，打开Google Authenticator，点击右上角（安卓为右下角）的“+”，选择“手动输入密钥”，页面中填入您的账户名和下方密钥。",
   "safety_google_copy":"复制",
   "safety_google_added":"添加成功后，您将得到一个有一定时效的6位数验证码，请将该验证码填入下方输入框中",
   "safety_google_fill_in":"6位数验证码",
   "safety_google_activate":"立刻开启",
   "safety_google_check_error":"请检查验证码",
   "safety_google_copied":"复制成功",
   "safety_google_activating":"开启中...",
   "safety_google_success":"开启谷歌验证成功",
   "safety_google_deactivate_success":"解绑谷歌验证成功",
   "position_my":"我的持仓",
   "apply_trader":"申请成为大师",
   "apply_trader_hero":"交易员",
   "apply_trader_notice":"请在此填写申请表单并提交。您的信息会被我们严格审核。我们会在48-72小时之内，给您答复。 您的资质审核通过后，会有工作人员和您联系，进行相关的签约流程。",
   "apply_trader_email":"联系邮箱",
   "apply_trader_desc":"申请说明",
   "apply_trader_required":"必需",
   "apply_trader_email_input":"请输入邮箱",
   "apply_trader_size_limit":"请填写申请理由，不超过300个字",
   "apply_trader_document":"相关证明",
   "apply_trader_apply":"提交申请",
   "apply_trader_applied":"你的申请已提交，待通过后会有专人和您联系！",
   "apply_trader_approved":"你的申请已通过，后续相关手续会专人和您联系！",
   "apply_trader_rejected":"您的申请未通过，请修改后重新申请，原因：%{reason}",
   "apply_trader_rejected_default":"审核不通过",
   "apply_trader_saving":"提交中...",
   "apply_trader_modify":"请修改后提交",
   "apply_trader_image_size":"（照片大小不得超过4M，不限格式）",
   "other_setting":"其他设置",
   "profile_feed":"动态",
   "profile_follow":"关注",
   "profile_followers":"粉丝",
   "profile_favorite":"收藏",
   "profile_balance":"我的钱包总额",
   "profile_logout":"退出登录",
   "profile_my_feed":"我的动态",
   "profile_my_follow":"我的关注",
   "profile_his_follow":"的关注",
   "profile_no_follow":"您还没有关注过其他人呢",
   "profile_unfollow":"取消关注",
   "profile_followed":"关注成功",
   "profile_unfollowed":"取消关注成功",
   "profile_follow_action":"关注",
   "profile_follow_action_followed":"已关注",
   "profile_follow_action_friend":"相互关注",
   "profile_follow_ing":"关注...",
   "profile_unfollow_ing":"取消关注...",
   "profile_my_followers":"我的粉丝",
   "profile_his_followers":"的粉丝",
   "profile_no_followers":"您还没有粉丝呢",
   "profile_my_fav":"我的收藏",
   "profile_my_profile":"个人信息",
   "profile_my_profile_photo":"头像",
   "profile_my_profile_nick":"昵称",
   "profile_my_profile_status":"简介",
   "profile_my_profile_click_edit":"点此编辑",
   "profile_my_profile_saving":"保存中...",
   "profile_my_profile_saved":"保存成功",
   "profile_my_profile_edit_nick":"编辑昵称",
   "profile_my_profile_save":"保存",
   "profile_my_profile_nick_input":"请输入昵称",
   "profile_my_profile_edit_status":"编辑简介",
   "profile_my_profile_status_input":"在这里输入简介",
   "profile_my_profile_album":"相册",
   "profile_my_profile_takephoto":"拍照",
   "profile_my_profile_cancel":"取消",
   "profile_my_profile_copier":"跟随者",
   "profile_my_profile_copier_ranking_show":"仅显示当前收益率前十名的跟随者",
   "trader_timeline":"主页",
   "trader_account":"账户",
   "trader_positions":"持仓",
   "trader_success_fee":"分润比例",
   "trader_rate_of_profit":"收益率",
   "trader_total_amount":"账户资产",
   "trader_total_return":"总收益",
   "trader_win_rate":"交易胜率",
   "trader_trade_time":"交易时长",
   "trader_day":"天",
   "trader_days":"天",
   "trader_week_return":"周收益",
   "trader_week_return_rate":"周收益率",
   "trader_frequency":"交易频次",
   "trader_frequency_unit":"次/周",
   "trader_trend":"收益走势",
   "trader_rate_trend":"收益率走势",
   "trader_position":"持仓中",
   "trader_history_position":"持仓历史",
   "trader_position_long":"买涨",
   "trader_position_short":"买跌",
   "trader_open_price":"开仓价",
   "trader_end_price":"标记价格",
   "trader_finish_price":"平仓价",
   "trader_position_size":"持仓量",
   "trader_margin_size":"保证金",
   "profile_pwd_change":"修改密码",
   "profile_pwd_change_failed":"修改密码失败",
   "profile_pwd_input_email":"输入邮箱账号",
   "profile_pwd_reset":"重置密码",
   "profile_pwd_original":"原始密码",
   "profile_pwd_new":"设置密码",
   "profile_pwd_tip":"6-20位，数字+字母",
   "kiki_index":"首页",
   "kiki_market":"行情",
   "market_title":"市场",
   "market_contract":"杠杆",
   "market_heroes":"交易员",
   "market_community":"社区动态",
   "market_wallet":"我的钱包",
   "market_week_heroes":"本周交易之星",
   "market_more":"更多",
   "market_week_profit_rate":"近一周收益率",
   "market_order":"订单",
   "market_high":"最高",
   "market_low":"最低",
   "market_time":"分时",
   "market_15min":"15分",
   "market_1hour":"1小时",
   "market_4hour":"4小时",
   "market_1day":"日线",
   "market_available":"可用",
   "market_available_balance":"可用余额",
   "market_transfer":"划转",
   "market_buy_long":"买入/做多",
   "market_sell_short":"卖出/做空",
   "market_order_type":"委托方式",
   "market_market_order":"市价",
   "market_limit_type":"限价",
   "market_est_price":"预估成交价",
   "market_est_funding_rate":"预估资金费率",
   "market_amount":"数量",
   "market_all":"全部",
   "market_lever":"杠杆",
   "market_stop_profit_loss_order":"止盈止损",
   "market_stop_profit_loss":"止盈止损",
   "market_stop_loss_price":"止损价",
   "market_stop_profit_price":"止盈价",
   "market_loss_prompt":"%{coinCode}价格达到%{lossPrice} %{currency}时平仓，预计盈亏%{loss} %{currency}",
   "market_profit_prompt":"%{coinCode}价格达到%{profitPrice} %{currency}时平仓，预计盈亏%{profit} %{currency}",
   "market_confirm":"确认",
   "market_cancel":"取消",
   "market_amount_not_greater_than_available":"交易量不能大于可用余额",
   "market_margin_not_greater_than_available":"保证金不能大于可用余额",
   "market_amount_must_greater_than":"交易量不能小于%{amount}%{currency}",
   "market_amount_must_less_than":"交易量不能超过%{amount}%{currency}",
   "market_amount_insufficient":"余额不足",
   "market_stop_loss_must_greater_than":"止损价必须大于%{amount}%{currency}",
   "market_stop_loss_price_not_less_than":"止损价不能小于%{amount}%{currency}",
   "market_limit_price_must_greater_than":"限价必须大于%{amount}%{currency}",
   "market_stop_loss_price_not_greater_than_current":"止损价不能大于当前价",
   "market_stop_loss_price_not_greater_than_limit":"止损价不能大于限价",
   "market_stop_loss_price_not_greater_than":"止损价不能大于%{amount}%{currency}",
   "market_stop_loss_price_not_less_than_current":"止损价不能小于当前价",
   "market_stop_loss_price_not_less_than_limit":"止损价不能小于限价",
   "market_stop_profit_must_greater_than":"止盈价必须大于%{amount}%{currency}",
   "market_stop_profit_price_not_less_than_current":"止盈价不能小于当前价",
   "market_stop_profit_price_not_less_than_limit":"止盈价不能小于限价",
   "market_stop_profit_price_not_greater_than":"止盈价不能大于%{amount}%{currency}",
   "market_stop_profit_price_not_greater_than_current":"止盈价不能大于当前价",
   "market_stop_profit_price_not_greater_than_limit":"止盈价不能大于限价",
   "market_stop_profit_price_not_less_than":"止盈价不能小于%{amount}%{currency}",
   "market_order_success":"下单成功",
   "market_long":"买涨",
   "market_short":"买跌",
   "market_open_price":"开仓均价",
   "market_open_time":"开仓时间",
   "market_close_avg_price":"平仓均价",
   "market_close_time":"平仓时间",
   "market_liquid_price":"预估强平价",
   "market_size":"交易总额",
   "market_margin":"保证金",
   "market_complete":"完成",
   "market_order_view":"查看订单",
   "market_order_failed":"下单失败",
   "market_position":"持仓",
   "market_active":"计划委托",
   "market_positioning":"持仓中",
   "market_current_positioning":"当前持仓",
   "market_current_open_order":"当前委托",
   "market_order_history":"历史持仓",
   "market_unrealized_pnl":"未实现盈亏",
   "market_mark_price":"标记价格",
   "market_current_price":"中间价",
   "market_close_position":"平仓",
   "market_order_detail":"持仓详情",
   "market_order_history_detail":"历史持仓详情",
   "market_order_id":"订单号",
   "market_order_status":"当前状态",
   "market_limit_close":"用户平仓",
   "market_market_close":"强制平仓",
   "market_position_closed":"平仓成功",
   "market_operate_success":"操作成功",
   "market_open_order":"委托中",
   "market_open_history":"历史委托",
   "market_open_detail":"委托详情",
   "market_open_history_detail":"历史委托详情",
   "market_trigger_price":"触发价",
   "market_cancel_order":"撤销",
   "market_cancel_success":"撤销成功",
   "market_order_date":"委托时间",
   "market_trigger_date":"触发时间",
   "market_filled":"已成交",
   "market_canceled":"已撤销",
   "market_order_canceled":"委托失败",
   "market_open_order_success":"委托成功",
   "market_open_order_canceled":"委托取消",
   "market_load_more":"加载更多",
   "market_no_data":"没有了",
   "market_funding_rate":"利息费率",
   "market_funding_fee":"利息",
   "market_share_to":"炫耀一下",
   "market_detail":"明细",
   "market_date":"时间",
   "market_price":"成交价格",
   "market_price_current":"当前市价",
   "market_pay":"购买",
   "market_pay_sell":"卖出",
   "market_fee_detail":"利息明细",
   "market_fee_tip1":"1、利息是为了避免交易集聚在单边，导致单边开仓价与当前价的价差剧烈扩大，影响平台的市场交易环境，从而采取的措施。平台每日定时向用户的持仓单收取利息或奖励资金补贴。",
   "market_fee_tip2":"2、利息为正，指该交易方向的持仓需收取费用。反之为负则该方向持仓可以得到费用奖励。",
   "market_fee_tip3":"3、利息=持仓名义价值*利息费率。",
   "market_fee_tip4":"4、每日0点、8点、16点，进行一次费用结算。",
   "market_fee_tip5":"5、如果持仓不到下一个结算点，不收取费用。",
   "market_estimation_tip":"非最终交易单价，仅供参考",
   "market_est_pay":"预估花费",
   "market_est_get":"预估得到",
   "market_profit_rate":"收益率",
   "market_position_share_profit":"别上班了，我用Kiki养你",
   "market_position_share_loss":"胜败乃兵家常事",
   "market_position_share_profit1":"跟紧大牛，财务自由",
   "market_spot_order_result":"交易结果",
   "market_spot_buy_amount":"买入数量",
   "market_spot_sell_amount":"卖出数量",
   "market_spot_buy_paid":"支付数量",
   "market_spot_sell_got":"得到数量",
   "market_spot_buy_success":"买入成功",
   "market_spot_sell_success":"卖出成功",
   "market_spot_buy_fail":"买入失败",
   "market_spot_sell_fail":"卖出失败",
   "market_spot_buy_fail_detail":"由于网络问题导致买入失败，请重试。",
   "market_spot_sell_fail_detail":"由于网络问题导致卖出失败，请重试。",
   "market_spot_trade":"现货交易",
   "market_spot":"现货",
   "market_spot_amount":"交易数量",
   "market_spot_size":"成交数量",
   "market_spot_price":"价格",
   "market_fiat_trade":"法币交易",
   "market_fiat_add_card":"添加银行账号",
   "market_fiat_edit":"编辑",
   "market_fiat_delete":"删除",
   "market_fiat_name":"姓名",
   "market_fiat_save":"保存",
   "market_fiat_manage_card":"账号管理",
   "market_fiat_deposit":"法币充值",
   "market_fiat_deposit_history":"充值历史",
   "market_fiat_howtoDeposit":"法币充值流程",
   "market_fiat_deposit_step1":"• Step 1：在您的银行/网上银行输入以下Kikitrade银行账号资料，并进行转账（可使用银行转帐,SWIFT)",
   "market_fiat_deposit_step2":"• Step 2：完成转账后，添加您的银行账号资料",
   "market_fiat_deposit_step3":"• Step 3：在Kikitrade输入转账金额，并上传转账凭证",
   "market_fiat_deposit_step4":"• Step 4：成功提交申请后，我们会在一个工作天内完成审批",
   "market_fiat_deposit_attention_tip1":"1) 银行账户名字必须与身份认证名字一致",
   "market_fiat_deposit_attention_tip2":"2) 用户需承担银行转账所产生费用",
   "market_fiat_deposit_attention_tip3":"3) Kikitrade将以到账的金额为准，为用户进行充值",
   "market_fiat_deposit_attention_tip4_1":"4) 转帐时请在转帐备注填写",
   "market_fiat_deposit_attention_tip4_2":"下方的6位转帐识别码,",
   "market_fiat_deposit_attention_tip4_3":"如无填写转帐审批时间有可以会需要延长。转帐识别码的有效时间是24小时内。",
   "market_fiat_deposit_attention":"充值注意事项：",
   "market_fiat_transaction_agreement":"我已阅读并同意以上流程和",
   "market_fiat_transfer":"汇款信息",
   "market_fiat_account":"收款人",
   "market_fiat_account_number":"收款账号",
   "market_fiat_bank_code":"银行编号",
   "market_fiat_swift_code":"银行国际代码(SWIFT)",
   "market_fiat_bank_name":"银行名称",
   "market_fiat_bank_address":"银行地址",
   "market_fiat_transferred":"我已明白，转帐并提交凭证",
   "market_fiat_currency":"支付币种",
   "market_fiat_amount":"支付金额",
   "market_fiat_actual_confirmed":"实际确认金额",
   "market_fiat_bank_charge":"银行手续费",
   "market_fiat_kiki_fee":"平台费",
   "market_fiat_failed_reason":"失败原因：",
   "market_fiat_upload_receipt":"提交转账凭证",
   "market_fiat_card":"我的银行账号",
   "market_fiat_transfer_currency":"汇款币种",
   "market_fiat_transfer_amount":"汇款金额",
   "market_fiat_transfer_ID":"汇款单号",
   "market_fiat_transfer_receipt":"汇款转账凭证",
   "market_fiat_transfer_confirm1":"本人郑重声明，本人转入的资金来源，合法合规，与洗钱、恐怖主义等犯罪活动无关。",
   "market_fiat_transfer_confirm2":"本人明白KIKITRADE不会负责任何有关银行转账所产生的费用。",
   "market_fiat_transfer_confirm3":"*请注意不同银行会收取不同转账手续费",
   "market_fiat_submit":"提交",
   "market_fiat_order_apply":"提款申请",
   "market_fiat_order_history":"提款历史",
   "market_fiat_order_type":"提款币种",
   "market_fiat_order_amount":"提款金额",
   "market_fiat_order_amount_withdrawl":"申请提款金额",
   "market_fiat_order_amount_received":"实际到账金额",
   "market_fiat_order_limit":"%{currency}提款说明",
   "market_fiat_order_limit0":"• 最低提款金额：%{limit} %{currency}",
   "market_fiat_order_limit1":"• 单日提款上限：%{limit} %{currency}",
   "market_fiat_order_limit2":"• 30日提款上限：%{limit} %{currency}",
   "market_fiat_order_limit_hkd_1":"• 本地港元转账平台费：0.15% , 最低收费10 HKD",
   "market_fiat_order_fee":"平台费：",
   "market_fiat_order_limit_usd_1":"本地美元转账: 0.15% , 最低收费40 USD",
   "market_fiat_order_limit_usd_2":"海外美元转账: 0.15%, 最低收费40 USD",
   "market_fiat_order_tips":"注意事项：",
   "market_fiat_order_tips1":"1）用户必须通过身份认证，并使用本人名下的银行卡提款。",
   "market_fiat_order_tips2":"2）提款金额低于%{limit} %{currency}: 1个工作天审批和转账处理",
   "market_fiat_order_tips3":"3）提款金额高于%{limit} %{currency}: 2-3个工作天审批和转账处理",
   "market_fiat_order_tips4":"4）周末及公众假期处理时间较长，敬请留意",
   "market_fiat_apply_submit":"您的提款申请已成功提交",
   "market_fiat_bank_number":"银行账号",
   "market_fiat_save_success":"保存成功",
   "market_fiat_saving":"保存中...",
   "market_fiat_transfer_submit":"您的充值申请已成功提交",
   "market_fiat":"法币",
   "market_crypto":"数字货币",
   "market_fiat_delete_card":"删除银行卡",
   "market_fiat_delete_confirm":"确定要删除该张银行卡吗？",
   "market_future":"杠杆",
   "market_future_cross":"全仓模式",
   "market_future_isolated":"逐仓模式",
   "market_switch":"切换至",
   "market_switch_light":"简洁版",
   "market_switch_pro":"专业版",
   "market_highest":"最高",
   "market_lowest":"最低",
   "market_sell":"卖",
   "market_buy":"买",
   "market_cfd_sell":"买跌",
   "market_cfd_buy":"买涨",
   "market_fiat_deposit_channel":"充值渠道",
   "market_fiat_deposit_currency":"充值币种",
   "market_fiat_authentication_title":"身份验证",
   "market_fiat_authentication_content":"在法币充值之前，您需要先完成身份验证。",
   "market_fiat_authentication_back":"暂不",
   "market_fiat_authentication_certification":"验证",
   "market_fiat_authentication_ok":"好的",
   "market_fiat_authentication_content_review":"您当前的身份信息正在审核中。请稍后再试。",
   "market_usdt_margin":"USDT杠杆",
   "market_fiat_withdraw_confirm":"在法币提款之前，您需要先完成身份验证。",
   "login_login":"登录",
   "login_email":"邮箱",
   "login_password":"密码",
   "login_to_register1":"还没有 Kikitrade 账户？",
   "login_to_register2":"立即注册",
   "login_successfully":"登录成功",
   "login_forgot_pwd":"忘记密码？",
   "login_account_lock":"账户被锁定",
   "login_account_lock_tip":"你的账户已被锁定，请2小时后再试",
   "login_i_understand":"知道了",
   "login_send_successfully":"发送成功",
   "login_verify_successfully":"验证成功",
   "login_forget_pwd":"忘记密码",
   "login_input_email":"输入邮箱账号",
   "login_input_google_code":"请输入谷歌验证码",
   "login_input_email_code":"请输入邮箱验证码",
   "login_input_login_pass":"请输入登录密码",
   "login_email_verification":"邮箱验证",
   "login_twice_verification":"二次验证",
   "login_verification_code":"验证码",
   "register_register":"注册",
   "register_email":"邮箱",
   "register_to_login1":"已有 Kikitrade 账号？立即",
   "register_to_login2":"登录",
   "register_invite_code":"邀请码（选填）",
   "register_invite_code_error":"邀请码错误",
   "register_protocol":"注册即代表接受",
   "register_enter_email":"请输入正确的邮箱地址",
   "register_email_used":"邮箱已注册",
   "register_verify_code":"邮箱验证",
   "register_verifying":"验证中",
   "register_verify_successfully":"验证成功",
   "register_verify_code_prompt":"验证码已发送至邮箱 %{email}",
   "register_code_send_successfully":"验证码发送成功",
   "register_code_expired":"验证码已过期，请重新获取",
   "register_code_not_received":"如果没收到验证码，请检查垃圾邮件收件箱或重新发送",
   "register_china":"中国大陆",
   "register_other":"其他",
   "register_hk":"香港",
   "register_tw":"台湾",
   "register_sg":"新加坡",
   "register_my":"马来西亚",
   "register_au":"澳大利亚",
   "register_euro":"欧洲",
   "register_uploading":"保存中",
   "register_upload_success":"保存成功",
   "register_click_to_upload":"点击上传头像",
   "register_male":"男士",
   "register_female":"女士",
   "register_complete":"完成",
   "register_set_pwd":"设置密码",
   "register_pwd_tip":"6-20 位，包含数字和字母",
   "register_nickname_tip":"昵称（15字以内）",
   "register_forget_pwd_success":"密码重置成功",
   "register_forget_pwd_reset":"重置密码",
   "register_email_success":"注册成功",
   "asset_asset":"我的资产",
   "asset_total_amount":"总资产",
   "asset_main_account":"现货",
   "asset_contract_account":"杠杆",
   "asset_yield_account":"理财",
   "asset_cross_account":"全仓",
   "asset_isolated_account":"杠杆",
   "asset_deposit":"充币",
   "asset_exchange":"交易",
   "asset_withdrawal":"提币",
   "asset_transfer":"划转",
   "asset_amount":"总资产",
   "asset_account_equity":"账户净值",
   "asset_available":"可用",
   "asset_frozen":"冻结",
   "asset_detail":"资产明细",
   "asset_transfer_in":"转入",
   "asset_transfer_out":"转出",
   "asset_reward":"奖励",
   "asset_deposit_history":"充币历史",
   "asset_deposit_address":"充币地址",
   "asset_address_getting":"正在获取地址...",
   "asset_generate_address":"生成地址",
   "asset_generate_address_tip":"点击下方按钮生成地址",
   "asset_deposit_tip1":"此地址只接受 %{coinCode} 充币，任何其他加密货币资产充入此地址都不可找回和不予处理。",
   "asset_deposit_tip2_ETH":"ETH 充币只支持以太坊主网，其他主网类型（如 BEP2, BEP20, HRC20, MATIC, FTM 等）的ETH 入金将无法追回和不予处理。",
   "asset_deposit_tip2_USDT":"USDT 充币只支持 ERC20，其他主网类型（如 Omni, BEP2, BEP20, TRC20, HRC20, MATIC, FTM 等）的 USDT 入金将无法追回和不予处理。",
   "asset_deposit_tip2_BTC":"BTC 充币只支持 BTC 主网，其他主网类型（如 BEP2, BEP20, HRC20, MATIC, FTM 等）的 BTC 入金将无法追回和不予处理。",
   "asset_deposit_tip2_BCH":"BCH 充币只支持 BCH 主网，其他主网类型（如 BEP2, BEP20, HRC20, MATIC, FTM 等）的 BCH 入金将无法追回和不予处理。",
   "asset_deposit_tip2_LTC":"LTC 充币只支持 LTC 主网类型，其他主网类型（如 BEP2, BEP20, HRC20, MATIC, FTM 等）的 LTC 入金将无法追回和不予处理。",
   "asset_deposit_tip2_XRP":"XRP 充币只支持 XRP 主网，其他主网类型（如 BEP2, BEP20, HRC20, MATIC, FTM 等）的 XRP 入金将无法追回和不予处理。必须正确填写以上地址 (address) 及备注 (tag) 才能成功入金，若有遗漏或无正确填写则不予处理，资产无法追回。",
   "asset_deposit_tip3_ETH":"若您充值的 ETH 来自于智能合约钱包（如 BlockFi、eToro、Paxful、Bitstamp 等），将会延迟到帐，会需要额外的处理时间及区块链转帐费用 0.015 ETH。如果您已转帐且记录已上链但却没出现在 Kikitrade 的充值页面上，欢迎将 txid 电邮至 support@kikitrade.com 或到 Kikitrade 联系客服查询。要是您不确定发送交易来源是否为智能合约，请先与发送平台或钱包服务商确认。",
   "asset_deposit_tip3_USDT":"若您充值的 USDT 来自于智能合约钱包（如 BlockFi、eToro、Paxful、Bitstamp 等），将会延迟到帐，会需要额外的处理时间及区块链转帐费用 20 USDT。如果您已转帐且记录已上链但却没出现在 Kikitrade 的充值页面上，欢迎将 txid 电邮至 support@kikitrade.com 或到 Kikitrade 联系客服查询。要是您不确定发送交易来源是否为智能合约，请先与发送平台或钱包服务商确认。",

   "asset_deposit_minimum_amount":"最低存币金额：%{num}。",
   "asset_deposit_minimum_amount_desc":"最低充币额是按从其他平台转出扣除手续费后，根据链上 TXID 查询的金额为准，任何低于最低充币额将不予处理退款。",
   "asset_show_qr":"显示二维码",
   "asset_copy":"复制",
   "asset_copy_success":"复制成功",
   "asset_save_photo":"保存到本地相册",
   "asset_saved_photo":"图片已保存",
   "asset_save":"保存",
   "asset_date":"时间",
   "asset_status":"状态",
   "asset_complete":"已完成",
   "asset_pending":"确认中",
   "asset_failed":"失败",
   "asset_withdrawal_history":"提币历史",
   "withdraw_amount":"提币数量",
   "deposit_amount":"充币数量",
   "withdraw_coin":"提币币种",
   "deposit_coin":"充币币种",
   "go_to_trade":"去交易",
   "asset_withdrawal_id":"序列号",
   "asset_withdrawal_amount":"数量",
   "asset_withdrawal_detail":"提币详情",
   "asset_deposit_detail":"充币详情",
   "asset_withdrawal_address":"地址",
   "asset_withdrawal_submitting":"提交中",
   "asset_withdrawal_address_save_ok":"地址保存成功",
   "asset_withdrawal_address_tip":"标签长度不能超过30个字符",
   "asset_withdrawal_address_manage":"地址管理",
   "asset_withdrawal_input_address":"输入新地址",
   "asset_withdrawal_input_label":"输入备注",
   "asset_withdrawal_input_tag":"输入标签",
   "tag":"标签",
   "asset_withdrawal_google_bind_tip":"您还未绑定谷歌验证，请前往绑定",
   "asset_withdrawal_status_apply":"申请提币",
   "asset_withdrawal_status_approval":"审核通过",
   "asset_withdrawal_status_unapproval":"审核未通过",
   "asset_withdrawal_amount_must_greater_than":"提币金额必须大于%{amount}",
   "asset_withdrawal_amount_not_greater_than_available":"提币金额不能大于可用金额",
   "asset_withdrawal_amount_not_less_than_once_min_amount":"提币金额不能小于单次最小提币额",
   "asset_withdrawal_amount_not_greater_than_once_max_amount":"提币金额不能大于单次最大提币额",
   "asset_withdrawal_tip1":"• 当天最高提币额：%{oneDayMax}%{currency}，单笔最低提币额：%{min}%{currency}，单笔最高提币额：%{max}%{currency}。",
   "asset_withdrawal_tip2":"• 出于安全原因，当您使用新的提款地址时，我们将手动审核您的提币。",
   "asset_transaction_id":"交易 ID",
   "asset_transaction_fee":"手续费",
   "asset_add_new_addr":"输入新地址",
   "asset_label":"备注",
   "asset_label_name":"输入备注名",
   "asset_coin":"币种",
   "asset_transfer_amount":"划转数量",
   "asset_all":"全部",
   "asset_transfer_tip":"请输入划转数量",
   "asset_transfer_tip1":"只有将资产划转到对应账户，才可进行相关业务操作",
   "asset_transfer_tip2":"账户间的划转不收取手续费",
   "asset_transfer_tip3":"最小理财金额%{amount} %{currency}",
   "asset_in_order":"持仓与委托",
   "asset_in_order_margin":"持仓保证金",
   "asset_unrealized_pnl":"未实现盈亏",
   "asset_margin_rate":"保证金率",
   "asset_open_position":"开仓",
   "asset_close_position":"平仓",
   "asset_limit_order":"限价委托",
   "asset_cancel_order":"取消委托",
   "asset_adjust_margin":"保证金调整",
   "asset_follow_amount":"跟单中",
   "asset_performance_share":"跟单分润收益",
   "asset_start_follow":"跟单开仓",
   "asset_close_follow":"跟单平仓",
   "asset_spot_trade":"现货交易",
   "asset_interest":"年化收益率",
   "asset_total_interest":"历史累计收益",
   "asset_interest_type":"理财利息",
   "asset_trial_bonus_interest":"体验金利息",
   "asset_operate_success":"操作完成",
   "asset_transfer_not_greater_than_available":"划转金额不能大于可用余额",
   "asset_transfer_must_greater_than":"划转金额必须大于%{amount}",
   "asset_transfer_from":"从",
   "asset_transfer_to":"到",
   "asset_deleting":"正在删除...",
   "asset_delete_success":"删除成功",
   "asset_delete_fail":"删除失败",
   "asset_chain_type":"主链类型",
   "profile":"我的",
   "languageSetting":"语言设置",
   "otherSetting":"其他设置",
   "edit":"编辑",
   "share_not_available":"分享暂不可用",
   "profile_version":"版本更新",
   "profile_about":"关于",
   "profile_contact_us":"联系客服",
   "profile_update":"更新",
   "profile_download_version_tip":"点击下载最新版本：%{version}",
   "profile_latest_version_tip":"您当前已经是最新版本！",
   "profile_my_order":"我的交易",
   "profile_cross_order":"全仓订单",
   "profile_isolated_order":"逐仓订单",
   "profile_spot_order":"现货交易订单",
   "profile_fiat_order":"法币交易订单",
   "community_community":"社区",
   "community_recommend":"推荐",
   "community_myFollow":"我的关注",
   "community_follow":"关注",
   "community_topTrader":"天启资本头号交易员",
   "community_feed":"动态",
   "community_myFans":"粉丝",
   "community_main":"主页",
   "community_account":"账户",
   "community_position":"持仓",
   "community_myFollowers":"跟随者",
   "community_topicDetail":"帖子正文",
   "community_likedCounts":"已有{likedCounts}人赞过",
   "community_share":"分享",
   "community_topComments":"精彩评论",
   "community_replyCounts":"条回复",
   "community_reply":"回复",
   "community_viewMoreReplies":"查看所有回复",
   "community_writeComment":"写下你的评论",
   "community_publishComment":"发表评论",
   "community_sendPost":"发布",
   "community_sendCommentOrReply":"发 送",
   "community_allComments":"全部评论",
   "community_myMessage":"我的消息",
   "community_socialMessage":"社交消息",
   "community_systemNotification":"系统通知",
   "community_commentsMe":"评论我的",
   "community_repliesMe":"回复我的",
   "community_likesMe":"赞我的",
   "community_commentsOnYourPost":"评论了你的帖子",
   "community_likesYourPost":"赞了你的帖子",
   "community_repliesYourPost":"回复了你的帖子",
   "community_uploading":"正在努力上传中",
   "community_releasePost":"发布帖子",
   "community_hotPost":"热门话题",
   "community_vote":"投票",
   "community_hotPost_placeholder":"在这里输入你要发布的话题内容……",
   "community_releasePost_placeholder":"发表你在kikitrade言论",
   "community_vote_placeholder":"在这里输入你要发布的投票内容……",
   "community_vote_selection":"投票选型",
   "community_right":"右",
   "community_left":"左",
   "community_manage":"管理",
   "community_delete_post":"删除帖子",
   "community_delete_comment":"删除评论",
   "community_delete_reply":"删除回复",
   "community_delete_topic_tip":"确定删除当前的话题吗？一经删除，不可恢复。",
   "community_delete_post_tip":"确定删除当前的帖子吗？一经删除，不可恢复。",
   "community_delete_poll_tip":"确定删除当前的投票吗？一经删除，不可恢复。",
   "community_delete_reply_tip":"确定删除当前的回复吗？一经删除，不可恢复。",
   "community_delete_comment_tip":"确定删除当前的评论吗？一经删除，不可恢复。",
   "community_post_notfound":"抱歉，这个帖子被飞碟带走了…",
   "community_share_increase":"分享数+1",
   "community_releasePollSuccess":"发布投票成功",
   "community_pollSending":"投票发布中...",
   "community_releaseTopicSuccess":"发布话题成功",
   "community_topicSending":"话题发布中...",
   "community_topic_over_limit":"当前已有3个话题，无法创建新话题！",
   "community_vote_over_limit":"当前已有3个投票，无法创建新投票！",
   "community_cancelLike":"取消点赞成功",
   "community_likeSuccess":"点赞成功",
   "community_cancelLikeFail":"取消点赞失败",
   "community_likeFail":"点赞失败",
   "community_cancelFollow":"取消关注成功",
   "community_followSuccess":"关注成功",
   "community_cancelFollowFail":"取消关注失败",
   "community_followFail":"关注失败",
   "community_reportSuccess":"帖子举报成功",
   "community_contentReport":"内容举报",
   "community_reportContent":"举报内容",
   "community_reportContentLimit":"举报内容不超过200个汉字",
   "community_report":"举报",
   "community_releaseDesc":"发表你在kikitrade言论",
   "community_commentSending":"评论发布中...",
   "community_commentSuccess":"评论发布成功",
   "community_commentFail":"评论发布失败",
   "community_publishedComment":"发表评论",
   "community_published":"发表",
   "community_releaseSuccess":"发布帖子成功",
   "community_postSending":"帖子发布中...",
   "community_favSuccess":"收藏成功",
   "community_cancelFavSuccess":"取消收藏成功",
   "community_all":"所有",
   "community_add":"添加",
   "community_imgSaved":"图片已保存",
   "community_WebPageDetail":"网页详情",
   "community_comment":"评论",
   "community_like":"赞了",
   "community_yourPost":"你的帖子",
   "community_originalText":"【原文】",
   "community_gif":"GIF",
   "community_loading":"正在加载...",
   "community_abuse":"辱骂攻击",
   "community_sexual":"色情低俗",
   "community_ads":"营销诈骗",
   "community_political":"政治敏感",
   "community_weapons":"违禁品",
   "community_violence":"血腥暴力",
   "community_others":"其他",
   "community_insertPic":"插入图片",
   "community_insertVideo":"插入视频",
   "community_guessULike":"猜你喜欢",
   "community_news":"新闻",
   "community_explore":"社区",
   "community_news_headlines":"头条",
   "community_no_more":"更多",
   "community_no_content":"暂无内容",
   "community_hot_topic":"热门",
   "community_poll":"投票",
   "community_viewers":"浏览人数",
   "community_participants":"评论人数",
   "community_voters":"投票人数",
   "community_select":"选择",
   "community_block":"屏蔽",
   "community_block_failed":"屏蔽失败，请稍后再试",
   "member_shipCenter":"会员专区",
   "member_plus":"银卡会员",
   "member_gold":"金卡会员",
   "member_expiry_date":"到期",
   "member_renew":"续费",
   "member_activate":"开通",
   "member_silver_membership":"银卡会员权益",
   "member_gold_membership":"金卡会员权益",
   "member_daily_box":"登录拆礼物",
   "member_login_earn_coin":"每天拆礼物，可抽取不同金额的USDT",
   "member_trading_bonus":"交易鼓励金",
   "member_earn_rebate":"每笔交易，都可获得10%交易费用的鼓励金",
   "member_referrals":"推荐返佣",
   "member_earn_fee":"可以获得推荐用户20%的交易手续费",
   "member_yield":"余额宝",
   "member_earn_annual_yield":"可享有2%的年化收益，且随存随取",
   "member_special_features":"会员专属活动",
   "member_renewal_plus":"银卡自动续费",
   "member_renewal_gold":"金卡自动续费",
   "member_month_count":"个月",
   "member_month_counts":"个月",
   "member_discount1":"限时活动{discount}折",
   "member_discount2":"首购享{firstBuyDiscount}折",
   "member_activate_renewal":"您开通了【{level}】自动续费服务。",
   "member_automatically_charge":"自本月起，每隔一个月，系统将从您账户中，扣除 {price}USDT。",
   "member_menber_activate":"开通会员",
   "member_next_renewal":"下个月续费，从本月会员到期结束时间算起，",
   "member_renewal":"自动续费(到期后自动从USDT账户中扣除)。",
   "member_activating":"开通会员中...",
   "member_first_buy":"首充仅需$16",
   "member_upgrade":"升级",
   "member_buy_success":"您购买了{period}个月{level}",
   "member_buy_charge":"系统已从您的币币账户中，扣除了{price}USDT。",
   "member_upgrading":"会员升级中...",
   "member_expiry":"您当前{level}到期时间：",
   "member_upgrading_membership":"升级为{level}后，您将享有{level}的所有权益。",
   "member_upgrad_level":"升级{level}后，在会员期限内，都为{level}。",
   "member_purchase_difference":"您本次只需缴纳从当前到会员到期日的差额即可。",
   "member_current_renewal":"您当前购买的是自动续费类型的{level}",
   "member_level_type":"升级{level}后，仍为自动续费类型。",
   "member_renewal_difference":"您本次只需缴纳从当前到下一次扣费日的差额即可。",
   "member_buy_failed_desc":"您的USDT余额不足，请先充值后，再进行{type}操作。",
   "member_buy_failed":"失败",
   "member_topup":"充值",
   "member_desc":"下个月续费，从本月会员到期结束时间算起，自动续费(到期后自动从币币账户USDT账户中扣除)",
   "member_automatic_renewal":"自动续费",
   "member_cancel_renewal":"取消自动续费",
   "member_cancel_confirm":"确认取消自动续费",
   "member_cancel_tip":"确定要取消自动续费吗？失去会员资格，您将会损失很多权益。请认真考虑后在下决定。",
   "member_cancel_desc1":"您当前开通了【自动续费】的{level}。",
   "member_cancel_desc2":"取消自动续费，则当前的会员权益，将保留至下个月的扣费日。",
   "member_cancel_desc3":"下个月的扣费日后，系统将不再自动扣款。",
   "member_cancel_res1":"您已成功取消了自动续费。",
   "member_cancel_res2":"截止到下个月的扣款日之前，您仍享有{level}的所有权益。",
   "member_cancel_success":"自动续费取消成功！",
   "member_buy":"购买会员",
   "member_res_type_desc":"{type}{level}成功！",
   "member_renewal_type":"续费",
   "member_upgrade_type":"升级",
   "member_activate_type":"开通",
   "member_res_fail":"{type}{level}失败",
   "member_renewal_type_fail":"续费",
   "member_upgrade_type_fail":"升级",
   "member_activate_type_fail":"开通",
   "member_Kiki":"Kiki ",
   "member_no_parameters_purchase":"暂无可购买的会员参数",
   "common_submiting":"正在提交中，请稍后重试",
   "common_networkError":"网络异常，请检查后再试",
   "common_serverBusy":"服务繁忙，请检查后再试",
   "common_systemBusy":"系统繁忙，请稍后重试",
   "common_noRecords":"暂无记录",
   "common_loadMore":"上拉加载更多…",
   "common_loading":"加载中",
   "common_confirm":"确认",
   "common_cancel":"取消",
   "common_unknown":"其他",
   "common_next_step":"下一步",
   "common_2_step_verification":"二次验证",
   "common_google_auth":"谷歌验证",
   "common_sms":"短信",
   "common_resend":"重新发送",
   "common_send":"发送验证码",
   "common_code_error":"验证码错误",
   "common_ok":"好的",
   "common_not_open":"暂未开放，敬请期待！",
   "common_complete":"完成",
   "common_apply_storage":"申请读写手机存储权限",
   "common_apply_later":"稍后询问",
   "common_apply_no":"拒绝",
   "common_apply_yes":"允许",
   "common_no_load_more":"",
   "common_scan_download":"扫码下载App",
   "common_saving":"提交中...",
   "invite_my_code":"我的邀请码",
   "invite_share_link":"邀请链接",
   "invite_share_poster":"邀请海报",
   "invite_count":"成功邀请人数",
   "invite_return_rate":"返佣比例",
   "invite_return_profit":"返佣收益",
   "invite_rule":"规则",
   "invite_tip1":"1、好友通过邀请码注册后，每产生一笔真实交易的手续费，会产生相应比例的返佣；",
   "invite_tip2":"2、返佣将以USDT形式返佣到您的交易账户，普通用户返佣比例为10%，银卡会员返佣比例为40%，金卡会员返佣比例为70%；",
   "invite_tip3":"3、返佣当日统计，次日到账。",
   "invite_copy_success":"复制成功",
   "invite_copy_fail":"复制失败，请重试",
   "invite_reward_amount":"奖励数量",
   "invite_reward_time":"奖励时间",
   "invite_reward_record":"返佣记录",
   "invite_referral_record":"邀请记录",
   "invite_referral_account":"好友账户",
   "invite_referral_time":"注册时间",
   "invite_step_1":"发送邀请给好友",
   "invite_step_2":"邀请码注册并首次入金成功",
   "invite_step_3":"好友获得 38 USDT 您获得 25 USDT",
   "invite_poster_tip_1":"来KIKITRADE",
   "invite_poster_tip_2":"跟单顶级交易员",
   "invite_rules":"邀请规则",
   "invite_rules1":"1. 分享自己的邀请码给好友，好友注册完成身份认证后，需首次充值且单笔满额 10,000 HKD / 1,300 USD / 36,000 TWD，双方才能获得奖励。",
   "invite_rules2":"2. 被邀请者必须确保注册时有正确填写邀请码，否则邀请人无法领取奖励。",
   "invite_rules3":"3. 邀请码无法于注册后补登录。",
   "invite_rules4":"4. 若充值 HKD 或 USD 奖励会于当天直接发放到理财帐户；充值 TWD 奖励则会于下个工作日发至现货帐户。",
   "invite_rules5":"5. 此活动不能与首次充值活动同时参与。",
   "invite_rules6":"6. 任何活动相关问题请询问电邮至 support@kikitrade.com 或点击 ",
   "invite_rules7":"7. Kikitrade 官方享有活动内容的最终解释权，针对违规行为用户我们将有权进行处理。本活动与Apple Inc. 无关。",
   "invite_desc":"邀请一位好友，可赢取价值$",
   "invite_desc1_1":"好友首次入金齐领",
   "invite_desc1_center":" HK$500 ",
   "invite_desc1_2":"奖励",
   "invite_desc2":"邀请一位好友首次于 Kikitrade 充值，满额 10,000 HKD / 1,300 USD / 36,000 TWD，好友获得 38 USDT（约 HK$300），",
   "invite_desc3":"您会获得 25 USDT（约 HK$200）",
   "invite_desc4":"邀请好友大升级！",
   "invite_reward":"已获得奖励 (USDT)",
   "box_member_zone":"天天拆礼物",
   "box_reward_record":"获奖记录",
   "box_open_box":"开始拆礼物",
   "box_game_desc":"规则说明",
   "box_tip_1":"• 会员每天有两次拆礼物机会。分享到社交平台后可以获得一次拆礼物机会",
   "box_tip_2":"• 礼物中可获得不同金额的USDT。",
   "box_tip_3":"• 奖励会立即发放至钱包中。",
   "box_tip_4":"• 如有任何争议，Kikitrade保留最终决定权。本次活动是由Kikitrade举办，与Apple无关。",
   "box_congrat_1":"恭喜你获得",
   "box_congrat_2":"奖励已发放至你的钱包中",
   "box_show":"炫耀一下",
   "box_no_reward_1":"很遗憾",
   "box_no_reward_2":"没有中奖",
   "box_no_chance":"今天没有拆礼物机会了T_T",
   "box_earn_chance":"可以获得一次拆礼物机会",
   "box_share_to_friend":"邀请好友一起玩",
   "box_max_reward":"最高可得 1000USDT",
   "box_open_member":"开通会员，天天拆礼物",
   "kyc_verify_title":"开始认证",
   "kyc_verify_desc":"请保证您设备的前置摄像头可用，并准备好相关的证件。",
   "kyc_verify_over":"认证结束",
   "kyc_over_desc":"我们正尽全力进行审核，请您稍后来这里查看审核结果。",
   "kyc_verifying":"认证中",
   "kyc_verifying_desc":"我们正尽全力进行审核，请您稍后来这里查看审核结果。",
   "kyc_success":"认证成功",
   "kyc_failed":"认证失败",
   "kyc_firstName":"名字",
   "kyc_lastName":"姓氏",
   "kyc_birthDay":"生日",
   "kyc_idCartType":"证件类型",
   "kyc_idCartNo":"证件ID",
   "kyc_failed_tip1":"未通过原因",
   "kyc_failed_tip2":"您的证件不清晰，系统无法识别，请重新拍照上传。如有问题，请到【我的】-【其它设置】-【联系客服】联系我们。",
   "kyc_verify_second":"重新认证",
   "kyc_unverified":"未认证",
   "kyc_verified":"已认证",
   "kyc_rejected":"未通过",
   "kyc_result_title":"身份认证",
   "kyc_card_id":"身份证",
   "kyc_country":"国家",
   "kyc_card_license":"驾照",
   "kyc_passport":"护照",
   "box_opening":"开奖中...",
   "box_loading":"每天登陆kiki的你，运气都不会太差",
   "box_lucky":"恭喜你中奖了",
   "box_get_reward":"获得奖励",
   "box_share_open":"跟我一起来Kiki拆礼物吧",
   "box_highest_reward":"最高可得",
   "box_tip_follow_trader":"还可以跟单顶级交易员",
   "home_buy":"买入数字货币",
   "home_sell":"卖出数字货币",
   "home_trade_title":"在KIKI一站式购买和交易数字货币",
   "home_share_commission":"Share commission",
   "home_liquidated_volume":"爆仓",
   "home_past_hour":"过去一小时",
   "home_long_short":"多空比",
   "home_market":"市场",
   "home_news":"新闻",
   "home_more_news":"更多新闻",
   "home_feed":"社交",
   "home_more_feed":"更多社交",
   "home_bank_transfer_credit":"银行卡转账",
   "home_crypto_currency":"数字货币充值",
   "home_more_growth":"多增长",
   "webview_about":"关于",
   "webview_contact_us":"联系我们",
   "webview_event":"活动",
   "cfd.process.success":"处理成功",
   "cfd.process.fail":"处理失败",
   "cfd.system.close":"系统已关闭",
   "cfd.plate.not.exist":"盘口不存在",
   "cfd.Insufficient.balance":"余额不足",
   "cfd.customer.statu.not.active":"用户状态未激活",
   "cfd.customer.permission.denied":"权限不足",
   "cfd.invalid.parameter":"无效的参数",
   "spot.order.invalid.parameter":"无效的参数",
   "cfd.margin.not.enough":"保证金不足",
   "cfd.customer.need.id.verify":"需要实名认证",
   "cfd.parameter.required":"参数必输",
   "cfd.incorrect.operation":"订单状态已变更，请刷新后重试",
   "cfd.not.exist":"信息不存在",
   "cfd.will.be.liquidationed":"仓位可能被强制平仓",
   "cfd.greater.than.max.free.margin":"超过可用额度",
   "cfd.stoplossprice.greater.than.marketprice":"止损价格超过当前价",
   "cfd.stoplossprice.less.than.marketprice":"止损价格低于当前价",
   "cfd.takeprofitprice.greater.than.marketprice":"止盈价格超过当前价",
   "cfd.takeprofitprice.less.than.marketprice":"止盈价格低于当前价",
   "cfd.stoplossprice.greater.than.liquidationprice":"止损价格超过强平价",
   "cfd.stoplossprice.less.than.liquidationprice":"止损价格低于强平价",
   "cfd.customer.stop.trade":"当前用户被禁止交易",
   "cfd.contract.config.error":"合约配置错误",
   "cfd.entrust.locked":"操作过于频繁，请您稍后重试",
   "cfd.order.locked":"操作过于频繁，请您稍后重试",
   "cfd.stoplossprice.greater.than.triggertprice":"止损价格超过委托价格",
   "cfd.stoplossprice.less.than.triggertprice":"止损价格低于委托价格",
   "cfd.takeprofitprice.greater.than.triggertprice":"止盈价格超过委托价格",
   "cfd.takeprofitprice.less.than.triggertprice":"止盈价格低于委托价格",
   "cfd.invalid.marketprice":"无效的当前价",
   "cfd.takeprofitprice.over.limit":"止盈价格超过最大限额",
   "cfd.position.over.limit":"仓位超过最大限额",
   "cfd.margin.less.than.order.min":"小于最小下单量",
   "cfd.margin.greater.than.order.max":"大于最大下单量",
   "cfd.user.cancel.processing":"用户取消处理中",
   "cfd.user.close.processing":"用户平仓处理中",
   "cfd.user.adjust.processing":"保证金调整处理中",
   "post.not.found":"帖子不存在或已被删除",
   "comment.is.not.exist":"评论不存在或已被删除",
   "customer.login.password.error.email":"用户名或密码错误,还可以输入%{time}次",
   "customer.login.password.error.mobile":"用户名或密码错误,还可以输入%{time}次",
   "member.process.success":"处理成功",
   "member.process.fail":"处理失败",
   "member.invalid.parameter":"无效的参数",
   "member.config.error":"会员配置错误",
   "member.pay.processing":"正在处理中",
   "member.upgrade.not.supported":"暂不支持升级",
   "member.insufficient.balance":"用户余额不足",
   "member.customermisc.exist.none":"用户信息不存在",
   "activity.req.processing":"正在处理中",
   "invalid.customer.level":"你的用户等级不足",
   "activity.daily.times.limit":"今天已无抽奖机会",
   "hour":"小时",
   "learn":"新手",
   "read_more":"了解更多 >",
   "order_result":"交易结果",
   "deposit_confirming":"充币确认中",
   "deposit_success":"充币成功",
   "deposit_reject_fail":"充币失败",
   "deposit_rejected":"充币失败",
   "withdraw_applied":"提币确认中",
   "withdraw_success":"提币成功",
   "withdraw_reject_fail":"提币失败",
   "withdraw_rejected":"提币失败",
   "withdraw_fail":"提币失败",
   "depositFiat":"充值",
   "withdrawFiat":"提款",
   "firstStep":"第一步",
   "uploadPassportOrID":"上传有效证件",
   "passportOrID":"身份证(只限香港地区）/ 护照",
   "hongkongId":"身份证（仅限香港地区）",
   "kycTips":"KYC 提示",
   "tip":"提示",
   "tip1":"提示1",
   "tip2":"提示2",
   "step1":"第一步",
   "step2":"第二步",
   "step3":"第三步",
   "uploadSelfImage":"上传日期自拍照",
   "kyc_detect_over":"检测完成",
   "upload_error":"上传失败，请重试",
   "kyc_tip_1":"证件检测：请上传身份证（只限香港地区）或护照",
   "kyc_tip_2":"人脸检测：请把脸部对准指定区域进行拍摄",
   "kyc_tip_3":"系统会自动发出检测结果，请耐心等待",
   "kyc_tip_4":"提示1：上传护照只需要有资料的一页",
   "kyc_tip_5":"提示2：完成检测后，稍后会给出检测结果。如果上传的证件照片不够清晰，可能影响检测结果。",
   "kyc_tip_6":"我们为什么需要您的资料？",
   "kyc_tip_7":"点击查看说明。",
   "kyc_tip_8":"提示3：如果检测失败多于3次，请联系Kiki客服处理。",
   "kyc_tip_9":"检测完成，系统会在1-5个工作日内完成，请耐心等候。",
   "activity_gift_card":"礼品卡兑换",
   "activity_gift_history":"兑换历史",
   "activity_gift_enter":"输入兑换码",
   "activity_gift_placeHolder":"第一次输入Kikitrade会有惊喜！",
   "activity_gift_exchange":"兑换",
   "activity_gift_how":"如何购买礼品卡？",
   "activity_gift_how1":"参加Kikitrade的Facebook活动可以免费获得礼品卡",
   "activity_gift_how2":"App内暂不支持礼品卡的购买，如需购买本礼品卡，",
   "activity_gift_how3":"《请点击这里》",
   "activity_gift_success":"兑换成功",
   "activity_gift_success_desc1":"您的礼品卡No.{cardNo}兑换成功",
   "activity_gift_success_desc2":"{currency}已发放至你的钱包中",
   "activity_gift_success_text1":"您的礼品卡兑换成功",
   "activity_gift_success_text2":"等额BTC即将发放至您的钱包中",
   "activity_gift_wallet":"查看钱包",
   "activity_gift_know":"我知道了",
   "activity_gift_disabled":"兑换功能禁用",
   "activity_gift_disabled1":"由于您输入了3次错误的兑换码，兑换功能被临时禁用，今日无法使用。",
   "activity_gift_failed":"兑换失败",
   "activity_gift_failed1":"您输入的兑换码无效，或者已经被使用。",
   "activity_gift_exchange_useUp":"兑换次数用完",
   "activity_gift_exchange_useUp1":"由于您已兑换了3次，当日兑换次数已用完。\n请明天再来。",
   "otc.customer.payment.way.save.fail":"银行卡信息保存失败",
   "otc.customer.payment.way.save.exception":"银行卡信息保存异常",
   "otc.customer.payment.way.none.exist":"银行卡信息不存在",
   "otc.customer.payment.way.disable":"银行卡信息已删除",
   "otc.customer.payment.way.op.exception":"银行卡信息获取失败",
   "otc.order.save.fail":"法币订单保存失败",
   "otc.order.duplicate":"法币订单重复",
   "otc.order.none.exist":"法币订单不存在",
   "otc.order.process.exception":"法币订单处理失败",
   "otc.order.state.invalid":"法币订单状态异常",
   "otc.order.update.fail":"法币订单更新失败",
   "otc.order.insufficient.balance":"法币账户余额不足",
   "otc.account.none.exist":"法币账户不存在",
   "otc.order.invalid.param":"法币订单参数不正确",
   "otc.order.withdraw.amount.limit":"法币提币额度超限",
   "spot.order.success":"现货订单成功",
   "spot.order.duplicate":"现货订单重复",
   "spot.order.create.fail":"现货订单创建失败",
   "spot.order.symbol.invalid":"现货订单盘口无效",
   "spot.order.error":"现货订单失败",
   "spot.order.user.account.insufficient":"用户余额不足",
   "spot.entrust.account.insufficient":"用户余额不足",
   "spot.entrust.user.account.insufficient":"用户余额不足",
   "spot.entrust.cancel.fail":"现货交易取消失败",
   "spot.order.system.account.insufficient":"系统余额不足",
   "spot.order.side.invalid":"现货订单参数无效",
   "spot.order.type.invalid":"现货订单类型无效",
   "spot.order.check.success":"现货订单校验成功",
   "spot.order.min.check":"现货订单最小下单额不足",
   "spot.order.max.check":"现货订单超过最大下单额",
   "lang":"简体中文",
   "liquidatation_analysis":"全网爆仓统计",
   "liquidatation":"爆仓",
   "funding_rate":"当前资金费率",
   "liquidatation_volume":"爆仓总额",
   "long_liquidations":"多头爆仓",
   "short_liquidations":"空头爆仓",
   "long_short_ratio":"多空比",
   "data":"数据",
   "data_Indicator":"BTC市场情绪指数",
   "ten_thousand":"万",
   "explore_txt_1":"最近24小时，共有",
   "explore_txt_2":"人爆仓",
   "explore_txt_3":"最大单笔爆仓单发生在",
   "worth":"价值",
   "8h":"最近8小时",
   "exchange_liquidatation":"交易所爆仓统计",
   "liquidatation_volume_30_days":"30日爆仓统计",
   "feed":"动态",
   "recent":"最新",
   "session_expire":"会话过期，请重试",
   "cfd.amount.less.than.order.min":"下单数量小于最小下单量",
   "cfd.amount.greater.than.order.max":"下单数量大于最大下单量",
   "mainStudy":"主图",
   "secondStudy":"副图",
   "index":"指标",
   "enable_now":"立即开启",
   "finger_login":"指纹登录",
   "faceid_login":"面容登录",
   "not_now":"稍后再说",
   "enable_finger_login":"开启指纹登录",
   "enable_faceid_login":"开启面容登录",
   "switch_account":"切换账号",
   "verify_finger":"请验证指纹",
   "verify_faceid":"请验证面容ID",
   "try_again":"再试一次",
   "retry":"重试",
   "password_login":"密码登录",
   "password_updted":"密码已修改",
   "use_pass_login":"密码已修改，请使用账号密码登录",
   "finger_verify":"指纹验证",
   "enable_finger_login_ok":"指纹登录开启成功",
   "enable_faceid_login_ok":"面容登录开启成功",
   "enable_finger_login_fail":"指纹登录开启失败",
   "enable_faceid_login_fail":"面容登录开启失败",
   "verify_finger_login_fail":"指纹登录验证失败，请用密码登录",
   "verify_faceid_login_fail":"面容登录验证失败，请用密码登录",
   "enroll_finger_tip":"指纹解锁暂未开启，请在“系统设置”中的“指纹和密码”裡设置后再开启。",
   "enroll_touchid_tip":"面容解锁暂未开启，请在“系统设置”中的“面容ID和密码”裡设置后再开启。",
   "fcm.title.otc.withdraw.success":"",
   "fcm.body.otc.withdraw.success":"恭喜您成功出金 %{amount} [%{currency}] ，查看詳情>",
   "fcm.title.otc.withdraw.fail":"",
   "fcm.body.otc.withdraw.fail":"您的%{amount} [%{currency}] 提取失败，查看詳情>",
   "fcm.title.otc.deposit":"",
   "fcm.body.otc.deposit":"恭喜您成功入金 [%{currency}] %{amount}，详情查看这里>>",
   "fcm.title.deposit":"",
   "fcm.body.deposit":"恭喜您成功入金 [%{currency}] %{amount}，详情查看这里>>",
   "fcm.title.reward":"",
   "fcm.body.reward":"您的活动奖励 [%{currency}] %{amount}，已到账，请查收～",
   "fcm.title.activity.reward":"",
   "fcm.body.activity.reward":"您的活动奖励 [%{currency}] %{amount}，已到账，请查收～",
   "fcm.title.withdraw":"",
   "fcm.body.withdraw":"您的提币 [%{currency}] %{amount} 已通过审核，开始链上转账，详情查看这里>>",
   "fcm.title.blast.hour.exceed":"",
   "fcm.body.blast.hour.exceed":"[较高风险] 当前%{currency}的爆仓金额高于%{value} USD，点击查看详情>>",
   "fcm.title.blast.day.exceed":"",
   "fcm.body.blast.day.exceed":"[较高风险] 当前%{currency}的爆仓金额高于%{value} USD，点击查看详情>>",
   "fcm.title.longshort.exceed.alert":"",
   "fcm.body.longshort.exceed.alert":"[风险极高] 当前%{currency} 多空比高于%{value}，点击查看详情>>",
   "fcm.title.longshort.exceed.tip":"",
   "fcm.body.longshort.exceed.tip":"[存在风险] 当前%{currency} 多空比高于%{value}，点击查看详情>>",
   "fcm.title.longshort.less.alert":"",
   "fcm.body.longshort.less.alert":"[存在风险] 当前%{currency} 多空比低于%{value}，点击查看详情>>",
   "fcm.title.longshort.less.tip":"",
   "fcm.body.longshort.less.tip":"[潜在风险] 当前%{currency} 多空比低于%{value}，点击查看详情>>",
   "fcm.title.kyc.result.success":"",
   "fcm.body.kyc.result.success":"恭喜，您的身份信息已成功通过平台审核。查看详情>>",
   "fcm.title.kyc.result.fail":"",
   "fcm.body.kyc.result.fail":"抱歉，您的身份信息没有通过平台审核。查看详情>>",
   "fcm.title.treasure.trail.interest":"",
   "fcm.body.treasure.trail.interest":"今日的理财体验金利息已到账，请查收~",
   "fcm.title.investment.result.success":"",
   "fcm.body.investment.result.success":"您的委托定投已扣款成功~",


   "trade.margin.customer.risk.alert.title":"[Kikitrade]清算预警",
   "trade.margin.customer.risk.alert.content":"尊敬的用户，您的账户处于极高风险状态，请注意风险控制。（提示：降低账户中虚拟货币的持仓或者入金可降低风险）",
   "trade.margin.customer.settle.title":"[Kikitrade]清算内容通知",
   "trade.margin.customer.settle.content":"尊敬的用户，您的账户${account}已经发生撤单或强平，详情查看“钱包”-右上“历史记录”-“清算记录”",

   "redeem.code.expired":"兑换码已过期",
   "redeem.code.not.exist":"兑换码不存在",
   "redeem.code.reach.limit":"本兑换码已达兑换上限",
   "redeem.code.not.start":"本兑换码尚未开始",
   "finger_lockout_use_pass_login":"当前指纹登录错误次数过多，指纹登录暂不可用。请使用密码登录。",
   "faceid_lockout_use_pass_login":"当前面容登录错误次数过多，面容登录暂不可用。请使用密码登录。",
   "finger_login_lockout":"指纹登录不可用",
   "faceid_login_lockout":"面容登录不可用",
   "lockout_finger_tip":"指纹登录关闭，请在”系统设置-触摸ID和密码”中，重新开启。",
   "lockout_touchid_tip":"面容登录关闭，请在”系统设置-面容ID和密码”中，重新开启。",
   "finger_lockout_permanent_use_pass_login":"当前指纹登录错误次数过多，指纹登录已停用，请在“系统设置-触摸ID和密码”中，重新开启。",
   "finger_lockout_open_touchid":"当前指纹验证错误次数过多，指纹验证暂不可用。",
   "faceid_lockout_open_touchid":"当前FaceId验证错误次数过多，FaceId验证暂不可用。",
   "asset_saving_amount":"本金",
   "asset_interest_accured":"利息",
   "asset_annual_interest":"年化",
   "asset_isolated":"杠杆",
   "asset_equity_value":"资产估值",
   "asset_total_unrealized":"未实现盈亏",
   "asset_wallet_balance":"钱包余额",
   "asset_total_balance":"资产估值",
   "common_error_view":"Sorry，本页内容被飞碟带走了…",
   "longPhoto":"长图",
   "invite_friend_share_text":"Kikitrade是一个低入场门槛、安全可靠的加密货币社交投资理财平台。\n与我一起加入Kikitrade，开展简单方便的投资旅程吧！🚀 \n经此链接下载，或注册时记得输入我的邀请码，共享 HK$500 奖赏！\n",
   "activity_gift_card_text1":"Kikitrade推出实体 Bitcoin 礼品卡，",
   "activity_gift_card_text2":"让你以简单三步真正手持比特币！",
   "activity_gift_course":"立即查看教程",
   "activity_gift_virtual":"虚拟礼品卡",
   "activity_gift_physical":"实体礼品卡 （仅限香港地区）",
   "activity_gift_HKD":"港元",
   "activity_gift_purchase_type":"购买方式",
   "activity_gift_currency":"货币",
   "activity_gift_amount":"金额",
   "activity_gift_exchange_tip1":"你将会获得约：",
   "activity_gift_exchange_tip2":"(实际按照汇款确认时间进行市价计算)",
   "activity_gift_quantity":"数量",
   "activity_gift_sum":"合计: ",
   "activity_gift_total":"礼品卡单笔购买额度，不超过10000港元",
   "activity_gift_pay_tip":"请把款项 {total} HKD 汇到以下账户，并上传转账截图",
   "activity_gift_payway":"选择支付方式",
   "activity_account_FPS":"FPS",
   "activity_upload":"上传转账截图",
   "activity_recipient_info":"收件人资料",
   "activity_recipient_name":"收件人姓名",
   "activity_confirm":"确认",
   "activity_recipient_name_enter":"请输入收件人姓名",
   "activity_gift_address":"收件人地址",
   "activity_gift_address_enter":"请输入收件人地址",
   "activity_gift_telephone":"收件人电话",
   "activity_gift_telephone_enter":"请输入收件人电话",
   "activity_gift_sender":"寄件人资料",
   "activity_gift_change":"（选填）",
   "activity_sender_name":"寄件人姓名",
   "activity_sender_name_enter":"请输入寄件人姓名",
   "activity_gift_note":"留言（不超过50个字）",
   "activity_gift_note_enter":"请输入对收件人的新年贺词",
   "activity_purchase_virtual":"您购买了%{quantity}张 %{amount}港元的虚拟礼品卡。一共需支付%{total}港元，请确认支付。",
   "activity_purchase_physical":"您购买了%{quantity}张 %{amount}港元的实体礼品卡。一共需支付%{total}港元，请确认支付。",
   "activity_purchase_confirm":"确认付款",
   "activity_gift_purchase":"礼品卡购买",
   "activity_gift_exchanged":"礼品卡兑换",
   "activity_gift_single_amount":"单张金额",
   "activity_gift_card_type":"卡类型",
   "activity_gift_virtual_type":"虚拟卡",
   "activity_gift_look_code":"查看兑换码",
   "activity_copy":"复制",
   "activity_exchanged":"已兑换",
   "activity_gift_physical_type":"实体卡",
   "activity_purchase_card":"购买礼品卡",
   "activity_desc":"如何获得更多优惠？",
   "activity_desc1":"参加我们",
   "activity_desc2":" Facebook 活动",
   "activity_desc3":"可获得更多免费奖励！",
   "activity_gift_desc":"活动规则：",
   "activity_gift_desc1":"1. 礼品卡不记名及到期日",
   "activity_gift_desc2":"2. 一经兑换，您将不能重用或撤回兑换码",
   "activity_gift_desc3":"3. 活动最终解释权归Kikitrade所有",
   "activity_purchase_help":"购买礼品卡帮助",
   "activity_exchange_title":"兑换礼品卡方法",
   "activity_exchange_step1_title":"Step 1: 登入",
   "activity_exchange_step1":"登入你的账户，如未有账户请先申请。",
   "activity_exchange_step2_title":"Step 2: 查看并输入兑换码",
   "activity_exchange_step2":"查看卡背的兑换码并正确输入兑换码",
   "activity_exchange_step3_title":"Step 3: 成功获取比特币",
   "activity_exchange_step3":"成功登入后，便能获得指定金额的比特币",
   "activity_buy_success_virtual":"我们确认转账成功后，会立即发送兑换码。您可以在“记录”中查看兑换码。",
   "activity_buy_success_physical":"我们确认转账成功后，会马上寄出礼品卡，并发送email和通知。您也可以随时在“记录”中查看快递进度。",
   "activity_buy_success_desc2":"",
   "activity_buy_success_desc3":"",
   "activity_check_order":"查看订单",
   "activity_buy_success_desc":"成功了，很简单吧！",
   "activity_back_home":"回到首页",
   "activity_buy_success_title":"订购比特币礼品卡",
   "activity_buy_limit":"购买总金额不可大于$10,000HKD",
   "activity_iknow":"明白了",
   "activity_confirm_paid":"我已付款",
   "activity_buy_desc1":"Kikitrade现提供『实体礼品卡』和『虚拟礼品卡』",
   "activity_buy_desc2":"助你极速以港币充值投资比特币或送给朋友",
   "activity_buy_desc3":"新年红包奖励规则：",
   "activity_buy_desc4":"•所有用户，买就加赠价值$8港元红包额；",
   "activity_buy_desc5":"•前20位订购者，买就送同价值礼品卡一张；",
   "activity_buy_exchange_history":"购买 & 兑换记录",
   "activity_exchanged_code":"兑换码",
   "activity_Bitcoin_card":"Bitcoin 礼品卡",
   "activity_history_progress":"处理中",
   "activity_history_transfer":"转账成功",
   "activity_history_delivered":"已寄出",
   "activity_history_cancelled":"交易取消",
   "home_more":"更多",
   "home_buy_speed":"极速买币",
   "home_buy_desc":"现金 / 加密货币充值",
   "home_lucky_draw":"活动中心",
   "home_lucky_desc":"日日赚不停",
   "home_gift_card":"礼品卡中心",
   "home_gift_desc":"购买/兑换比特币",
   "home_data":"数据",
   "fcm.title.redeemcard.buy.result.confirm":"",
   "fcm.body.redeemcard.buy.result.confirm":"您购买的虚拟礼品卡交易成功。查看兑换码 >>",
   "fcm.title.redeemcard.buy.physical.result.confirm":"",
   "fcm.body.redeemcard.buy.physical.result.confirm":"您购买的实体礼品卡交易成功。",
   "fcm.title.redeemcard.buy.result.delivered":"",
   "fcm.body.redeemcard.buy.result.delivered":"您购买的实体礼品卡已成功寄出。查看详情 >>",
   "fcm.title.redeemcard.buy.result.fail":"",
   "fcm.body.redeemcard.buy.result.fail":"您的礼品卡购买交易被取消了。查看详情>>",
   "follow_order_confirm":"跟单确认",
   "follow_order_confirm_tip":"由于交易员的跟单策略和初始持仓数据的统计差异，导致可能跟单后和交易员的数据有些许偏差。望知悉。",
   "iKonw_1":"明白",
   "binance_api_tip1":"Binance仅支持现货交易，以及永续合约的API接入。",
   "binance_api_tip2":"暂不支持杠杆交易及其它类型的交易，否则可能会出现数据统计不准确的问题。",
   "okex_api_tip1":"Okex仅支持现货交易，以及永续合约的API接入。",
   "okex_api_tip2":"暂不支持杠杆交易及其它类型的交易，否则可能会出现数据统计不准确的问题。",
   "ftx_api_tip1":"FTX仅支持永续合约的API接入。",
   "ftx_api_tip2":"暂不支持现货交易、杠杆交易及其它类型的交易，否则可能会出现数据统计不准确的问题。",
   "bitmex_api_tip1":"Bitmex仅支持永续合约的API接入。",
   "bitmex_api_tip2":"暂不支持现货交易、杠杆交易及其它类型的交易，否则可能会出现数据统计不准确的问题。",
   "api_integration_intro":"API接入说明",
   "lottery_tip1":"赢得人生第一枚比特币",
   "lottery_tip2":"快来赢得人生第一枚比特币吧！",
   "lottery_tip3":"免费送你",
   "lottery_tip4":"给好友送币，可获得免费抽取机会",
   "lottery_tip5":"当前有%{chance}次抽奖机会",
   "lottery_tip6":"当前赢取的比特币",
   "lottery_tip7":"拿走比特币",
   "lottery_tip8":"下载Kiki打开比特币钱包",
   "lottery_tip9":"送币给好友",
   "lottery_tip10":"邀请一位好友，抽奖+1",
   "lottery_rule":"活动规则",
   "lottery_rule_1":"每个新用户在登录前有三次抽取机会。注册成功后，增加一次抽奖机会。",
   "lottery_rule_2":"每邀请一位好友注册，可增加一次抽奖机会。邀请人数最多的前%{rank}位用户，可一同瓜分%{quantity}个BTC（活动结束后发放至账户中）。",
   "lottery_rule_3_1":"抽中%{num}个",
   "lottery_rule_3_2":"可获得%{quantity}个BTC",
   "lottery_rule_4":"获得的比特币，需要在Kikitrade App内查看并使用，详细的使用方法，请下载kiki trade。",
   "lottery_invite_rank":"邀请排行榜",
   "lottery_my_invite":"我的邀请",
   "lottery_user":"用户",
   "lottery_invite":"邀请",
   "lottery_share_txt":"分享链接成功，快去发给好友吧！",
   "lottery_warm_tip":"友情提示",
   "lottery_reg_tip":"注册kikitrade后，可拥有自己专属的邀请链接。成功邀请好友可增加抽獎次數",
   "lottery_reg":"注册kikitrade",
   "lottery_tip11":"抽奖次数已用完，邀请好友可增加抽奖次数",
   "lottery_win_prize":"恭喜您获得%{quantity}个比特币",
   "lottery_tip12":"恭喜获得",
   "lottery_tip13":"您的比特币已放入kikitrade钱包里，下载APP拿走属于你的比特币吧！",
   "lottery_download_app":"下载APP",
   "lottery_return_home":"返回首页",
   "lottery_rank":"排名",
   "lottery_share_btc":"可瓜分BTC",
   "lottery_tip15":"我发了一个比特币红包给你",
   "lottery_person":"人",
   "login_other_way":"其他登录方式",
   "login_open_auth_process":"第三方登录中...",
   "need.binding.email":"请绑定邮箱",
   "email_binding":"绑定邮箱",
   "login_oauth_cancel":"第三方认证取消",
   "login_oauth_fail_retry":"第三方平台登录失败",
   "login_oauth_not_support_apple":"当前系统版本或设备不支持Apple账号登录",
   "customer.login.3rd.email.exist":"该邮箱已注册过kikitrade账号",
   "customer.login.3rd.token.error":"第三方平台登录失败(1105)",
   "login_oauth_timeout":"登录超时",
   "fiat_transfer_confirm_title":"确认离开汇款",
   "fiat_transfer_confirm_desc1":"如您已完成汇款，请务必完成汇款凭证的提交",
   "fiat_transfer_confirm_desc2":"我确定还没有汇款Kikitrade",
   "fiat_transfer_confirm":"确认离开",
   "settings_rate":"汇率设置",
   "common_usd":"美元",
   "common_hkd":"港元",
   "apply_trader_desc1":"申请成为大狮，即可获取高达",
   "apply_trader_desc2":"跟单人数越多，分红越多！",
   "apply_master_welfare":"成为大狮福利",
   "apply_master_desc1":"申请并接入API可获得高达100USDT",
   "apply_master_desc2":"第一个月正回报率在可获得100USDT",
   "apply_master_desc3":"其他用户跟单可分红利",
   "apply_contact_way":"联系方式（电话）",
   "apply_input_ph":"请输入你的联络方式",
   "apply_submit":"大狮来了",
   "apply_item_title":"申请条款",
   "aplly_item1":"2. 成功接入API后即可获得50USDT，接入10天后获得25 USDT，接入20天后获得25 USDT",
   "aplly_item2":"3. 当申请人数满额后，奖励金额或有改变\n（详情请看Kikitrade公告）",
   "aplly_item3":"4. 如有任何争议，Kikitrade保留最终决定权",
   "aplly_item4":"1. 提交申请后会有专人透过电邮或电话联系你。请留意邮箱（垃圾邮箱等）。如果没有收到邮件，可以联系info@kikitrade.com或客服",
   "apply_item5":"点击这里。",
   "api_contract":"合约",
   "api_spot":"现货",
   "api_info_title":"API信息",
   "api_range_title":"统计范围",
   "api_range_desc":"请选择实际操作的交易方式，选择尚未进行的操作，有可能造成API接入失败或统计不准确",
   "api_way_title":"统计范围出错",
   "api_way_desc":"您当前尚未开通合约交易，API无法统计相关的数据。请选择不带有合约的统计方式。",
   "api_way_rechoose":"重新选择",
   "market_entrust_cancel":"撤单",
   "market_entrust_counts":"委托数量",
   "market_entrust_total":"委托总额",
   "market_entrust_amount":"委托金额",
   "market_entrust_price":"委托价格",
   "market_entrust_type":"委托类型",
   "market_entrust_fee":"手续费",
   "market_entrust_cancel_success":"撤单成功",
   "market_entrust_limit":"限价委托",
   "market_entrust_market":"市价委托",
   "market_order_limit":"限价订单",
   "market_order_market":"市价订单",
   "market_status":"交易状态",
   "market_status_success":"交易成功",
   "market_status_cancel":"交易取消",
   "market_status_failed":"交易失败",
   "market_entrust_success":"委托成功",
   "market_entrust_failed":"委托失败",
   "huobi_api_tip1":"HUOBI仅支持现货交易，以及永续合约的API接入。",
   "netConnectError":"网络连接异常",
   "pullDownToRefresh":"下拉刷新",
   "releaseToRefresh":"松开刷新",
   "refreshingText":"刷新中",
   "airplaneTip":"请关闭手机飞行模式或使用WiFi进行连接",
   "home_buy_speed_activity_1":"感恩入金回赠",
   "home_buy_desc_activity_1":"感恩用户支持，入金多送更多",
   "liveness_desc_1":"人脸检测提示",
   "liveness_desc_2":"如您佩戴眼镜，为保证人脸检测的识别成功，请务必摘掉眼镜。",
   "timeout.error":"网速有点慢，请稍后重试",
   "login.network.error":"网络异常，请稍后重试",
   "login.timeout.error":"登录请求超时，请稍后重试",
   "new_activity_gift_card_text1":"Kikitrade祝大家新年快乐，牛市行大运！现已推出新年贺岁比特币红包，前20名订购可多送一封，送礼自用两相宜",
   "activityCenter":"活动中心",
   "activity1":"活动",
   "activityDetail":"活动详情",
   "activityIntro":"活动声明",
   "activityVIP":"VIP 活动",
   "TrialBonusTitle":"注册送体验金",
   "TrialBonusTip1":"1、活动期间（2021.01.27-2021.02.06），新用户注册即送500USDT体验金",
   "TrialBonusTip2":"2、体验金用于体验理财收益，体验金理财年化利率为14%，用户可享受14天体验金理财利息，每日利息定额发放。",
   "TrialBonusTip3":"3、体验金理财利息自注册次日自动发放。",
   "TrialBonusTip4":"4、利息发放至用户现货USDT资产账户，通过“我的-我的资产-现货”查看，也可直接点击下方页面中【查看我的账户】查看",
   "TrialBonusTip5":"1、体验金仅用做体验理财，不会发放至用户资产账户",
   "TrialBonusTip6":"2、任何有关活动的问题请询问KIKI客服，点击 ",
   "TrialBonusTip7":"3、KIKITRADE官方享有活动内容的最终解释权，针对违规行为用户我们将有权进行相应处理",
   "TrialBonusTip8":"注册领取体验金",
   "TrialBonusTip9":"待领取",
   "TrialBonusTip10":"体验中",
   "TrialBonusTip11":"理财体验金",
   "TrialBonusTip12":"我的利息",
   "TrialBonusTip13":"已到账",
   "TrialBonusTip14":"剩余天数",
   "TrialBonusTip15":"啊哦~你已经是我们的老朋友了",
   "TrialBonusTip16":"邀请好友来领取体验金，还可赚取高额奖励金",
   "TrialBonusTip17":"查看我的账户",
   "TrialBonusTip18":"收益日历",
   "TrialBonusTip19":"邀请好友赚奖励金",
   "TrialBonusTip20":"成功邀请一位好友，",
   "TrialBonusTip21":"已失效",
   "TrialBonusTip22":"可赢取价值$20HKD的BTC",
   "TrialBonusTip23":"去邀请",
   "TrialBonusTip24":"Kikitrade 客服",
   "TrialBonusTip25":"邀请说明",
   "TrialBonusTip26":"1、邀请好友，当好友注册并成功通过KYC后，邀请者和被邀请者，都可以获得价值$20HKD的BTC；",
   "TrialBonusTip27":"2、只有被邀请者成功通过KYC后，才算邀请成功，邀请双方可以获得奖励；",
   "TrialBonusTip28":"3、实际到账的BTC金额，按当时的BTC/HKD市价计算；",
   "TrialBonusTip29":"4、KIKITRADE保留最终活动修改及解释权，针对违规行为用户我们将有权进行处理。",
   "eventCenterTitle":"Kikitrade 活动中心开张了！",
   "eventCenterDesc":"汇集各种福利活动，每日期待新惊喜",
   "moreEvent":"更多活动，敬请期待",
   "NewCarouselViewTip1":"弹性投资",
   "NewCarouselViewTip2":"入金轻松无限制，买币只需 1 美元",
   "NewCarouselViewTip3":"社交结合投资",
   "NewCarouselViewTip4":"加入二十万人社区，",
   "NewCarouselViewTip5":"投资路上有伴不孤单",
   "NewCarouselViewTip6":"灵活理财",
   "NewCarouselViewTip7":"高达 8% 稳定币年利率，\n Kikitrade 助你实现财务自由",
   "NewCarouselViewTip8":"与 KIKI 一起探索加密货币",
   "NewCarouselViewTip9":"立即注册，\n体验即时加密货最新快讯及数据追踪！",


   "CarouselViewTip10":"注册新账号",
   "CarouselViewTip11":"已有KIKI账号？",
   "CarouselViewTip12":"登入",
   "freeTrialFund":"送体验金",
   "treasure.transfer.amount.min.less":"小于最小理财金额",
   "originText_deleted":"抱歉，原文已删除~",
   "originText_deleted1":"抱歉，该帖子已经删除~",
   "announcement":"官方公告",
   "eventAward":"活动奖励",
   "eventAward1":"活动奖励已发放，查看账户详情>",
   "interest1":"理财利息已到账，请查看账户详情",
   "depositOtc":"入金",
   "depositOtcSuccess":"入金成功",
   "withdrawOtc":"出金",
   "withdrawOtcSuccess":"出金成功",
   "withdrawOtcFail":"出金失败",
   "noticeCenter":"消息中心",
   "clearUnreadNotice":"清除所有未读消息提示？",
   "clear":"清除",
   "originText_blocked":"您已屏蔽该帖子",
   "viewAll":"查看更多",
   "emailLogin":"邮箱登录",
   "mobileLogin":"手机登录",
   "emailReg":"邮箱注册",
   "mobileReg":"手机注册",
   "mobileVerification":"手机验证",
   "verifyCodeSendToMobile":"验证码已发送至手机 %{mobile}",
   "enterRegion":"请输入国家或地区",
   "enterMobileVerificationCode":"请输入手机验证码",
   "mobileNumber":"手机号码",
   "mobileNumberFormatError":"手机格式有误",
   "KycTokenErrorCode418":"当天刷脸认证次数过多，请明天再试。",
   "KycTokenErrorCode500":"系统内部错误，请反馈工程师排查。",
   "KycTokenErrorCode414":"当前移动设备不支持刷脸认证，请更换设备后操作。",
   "KycLivenessErrorCodeZ1001":"系统错误",
   "KycLivenessErrorCodeZ1003":"验证中断",
   "KycLivenessErrorCodeZ2002":"网络错误",
   "KycLivenessErrorCodeZ2003":"客户端设备时间错误",
   "KycLivenessErrorCodeZ2006":"刷脸失败",
   "KycLivenessErrorCodeZ1004":"Android系统版本过低",
   "KycLivenessErrorCodeZ1005":"刷脸超时(单次)",
   "KycLivenessErrorCodeZ1006":"多次刷脸超时",
   "KycLivenessErrorCodeZ1018":"无前置摄像头",
   "KycLivenessErrorCodeZ1019":"摄像头权限未赋予",
   "KycLivenessErrorCodeZ1020":"打开摄像头失败",
   "KycLivenessErrorCodeZ1024":"SDK认证流程正在进行中，请等待本地认证流程完成后再发起新调用",
   "KycLivenessErrorCodeZ1012":"请稍后重试",
   "KycLivenessErrorCodeZ5112":"上传炫彩Meta信息失败",
   "KycLivenessErrorCodeZ5113":"上传炫彩视频失败",
   "KycLivenessErrorCodeZ6001":"OCR识别次数超限",
   "KycLivenessErrorCodeZ6002":"OCR图片上传网络超时",
   "KycLivenessErrorCodeZ6003":"OSS Token过期",
   "KycLivenessErrorCodeZ6004":"人脸照片处理失败",
   "KycLivenessErrorCodeZ5114":"用户点击Home键",
   "KycLivenessErrorCodeZ1008":"用户主动退出认证。",
   "KycLivenessErrorCodeZ1011":"客户端初始化网络错误。",
   "KycLivenessErrorCodeZ1025":"客户端初始化接口返回网络错误。",
   "KycLivenessErrorCodeZ1026":"信息上传网络错误。",
   "KycLivenessErrorCodeZ1027":"服务端认证接口网络错误。",
   "KycLivenessErrorCodeIOSZ1001":"拒绝开通相机权限",
   "KycLivenessErrorCodeIOSZ1002":"无法启动相机",
   "KycLivenessErrorCodeIOSZ1005":"刷脸超时(单次)",
   "KycLivenessErrorCodeIOSZ1006":"多次刷脸超时",
   "KycLivenessErrorCodeIOSZ1008":"用户主动退出认证",
   "KycLivenessErrorCodeIOSZ1000":"抱歉，系统出错了，请您稍后再试",
   "KycLivenessErrorCodeIOSZ1007":"本地活体检测出错",
   "KycLivenessErrorCodeIOSZ1147":"本地活体检测出错",
   "KycLivenessErrorCodeIOSZ1146":"本地活体检测出错",
   "retryTakePhoto":"重新拍照",
   "takeIdTip1":"请将证件四角贴合框内拍摄",
   "uploadPassport":"请上传护照照片",
   "uploadHongKongId":"请上传身份证（香港地区）照片",
   "mobileNumber1":"手机号",
   "twdFiat":"TWD入金/出金",
   "twdFiatOny":"仅限台湾用户出金/入金",
   "twdFiatTerms":"TWD交易须知",
   "twdFiatTermsHasRead":"我已阅读",
   "twdFiatTip":"您可通过TWD来买入或卖出USDT。本次交易，由Zomfast提供交易服务。交易有风险，请仔细阅读交易须知。",
   "twdFiatStart":"开始交易",
   "KikiBonusRegText1":"注册即送500USDT理财体验金",
   "KikiBonusRegText2":"利息每天自动到账",
   "goParticipate":"去参与",
   "hasDone":"已完成",
   "hasExpired":"已失效",
   "KikiBonusTitle":"Kikitrade三重礼",
   "KikiBonusTitle1":"Kikitrade礼遇三重，USDT奖励等你拿",
   "KikiBonusIniviteTitle":"邀请好友赚奖励金",
   "KikiBonusIniviteText1":"邀请一位好友",
   "KikiBonusIniviteText2":"即可获得价值$20HKD的BTC",
   "KikiBonusFiatTitle":"入金得奖励",
   "KikiBonusFiatText1":"在kiki入金",
   "KikiBonusFiatText2":"最高可得1515USDT奖励",
   "LoginNow":"立即登录",
   "referredFriends1":"已邀",
   "moreComment":"查看更多评论",
   "unBindSuccess":"解除绑定成功",
   "changeBindMobileSuccess":"更换绑定手机号成功",
   "changeBindEmailSuccess":"更换绑定邮箱成功",
   "bindSuccess":"绑定成功",
   "changeBindMobile":"换绑手机号",
   "bindMobile":"手机号绑定",
   "enterMobile":"请输入手机号",
   "unBindTip":"该账号是注册项，不能进行解绑或换绑操作",
   "changeBindMobile1":"更换绑定手机号",
   "unBindMobile":"解除绑定手机号",
   "changeBindEmail1":"更换绑定邮箱",
   "unBindEmail":"解除绑定邮箱",
   "changeBindMobileTip":"确定将绑定手机号更换为%{mobile}？",
   "changeBindEmailTip":"确定将绑定邮箱更换为%{email}？",
   "unBindMobileTip":"确定要解绑手机号%{mobile}？",
   "unBindEmailTip":"确定要解绑邮箱%{email}？",
   "unBindMobileTip1":"解绑后，您将不能使用该手机号进行登录、接收重要通知",
   "unBindEmailTip1":"解绑后，您将不能使用该邮箱进行登录、接收重要通知",
   "cancelChangeBind":"您已取消更换绑定手机号操作",
   "cancelChangeBindEmail":"您已取消更换绑定邮箱操作",
   "cancelUnbind":"解绑取消",
   "changeBindEmail":"换绑邮箱",
   "bindEmail":"邮箱绑定",
   "emailCodeTip":"如果未收到验证码，请检查垃圾邮件或者重新发送",
   "bindTip1":"为了保证账号安全性，您的账号需要绑定手机号或者开启谷歌验证",
   "bindTip2":"为了保证账号安全性，您的账号需要绑定邮箱或者开启谷歌验证",
   "unBind":"未绑定",
   "bindEd":"已绑定",
   "twdFiatTip1":"根据注册地的相关规定，您无法进行此操作",
   "bankCardToCrypo":"银行卡转账买入数字货币",
   "crypoDeposit":"已有数字货币充值",
   "inviteFriendText1":"邀请好友",
   "inviteFriendText2":"立即邀請好友",
   "inviteFriendText3":"新升级",
   "inviteFriendLoginTxt":"后邀请好友",
   "assetTxt1":"理财升级，收益暴涨",
   "assetTxt2":"点击了解详情 ",
   "OldReferredRewardHead":"旧版邀请活动（奖励 BTC）",
   "ReferredReward":"奖励金",
   "fiatServiceTimeTip1":"您的申请预计会在48小时内处理完成。但由于行情带来的流量，我们有大量的待审核任务，您的处理可能会延迟。",
   "fiatServiceTimeTip2":"您的申请将于下一个银行工作天处理完成。但由于行情带来的流量，我们有大量的待审核任务，您的处理可能会延迟。",
   "fiatServiceTimeTip3":"您的申请预计会在48小时内完成处理。但由于行情带来的流量，我们有大量的待审核任务，您的处理可能会延迟。",
   "fiatServiceTimeTip4":"您的申请预计会在今日10am前完成处理。但由于行情带来的流量，我们有大量的待审核任务，您的处理可能会延迟。",
   "fiatServiceTimeTip5":"您的申请预计会在1小时内完成处理。但由于行情带来的流量，我们有大量的待审核任务，您的处理可能会延迟。",
   "fiatServiceTimeTip6":"您的申请预计会在72小时内处理完成。但由于行情带来的流量，我们有大量的待审核任务，您的处理可能会延迟。",
   "expectedFinishTime":"预计完成时间：",
   "submitApplyTime":"提交申请时间",
   "nextBankWorkHour":"下一个银行工作日",
   "otc.order.deposit.frequency.limit":"提交频繁，请15分钟后再试",
   "forceUpdate.cancel":"取消",
   "forceUpdate.update":"升级",
   "forceUpdate.newVersionUpdate":"版本更新",
   "forceUpdate.desc":"您需要升级到最新版本，才可以正常使用。",
   "forceUpdate.latestVersion":"最新版本",
   "forceUpdate.updateContent":"更新内容",
   "savings":"理财",
   "flexibleSavings1":"活期理财",
   "fixedInvestmentPlan":"定投计划",
   "lockUpPeriod":"锁仓期限",
   "anytimeDepositWithdrawal":"随存随取",
   "todayAPYReference":"今日参考年化收益",
   "buyNow":"立即抢购",
   "autoPurchase":"自动申购",
   "purchase":"申购",
   "fixedCurrency":"定投币种",
   "historicalRateReturn":"历史收益率",
   "participateInFixedInvestment":"参与定投",
   "automaticBalanceTransferInvitation":"余额自动转入邀请",
   "withdrawBenefits":"随时提取 轻享收益",
   "inviteSavingTip1":"开启后，每日会自动将账户余额转入活期理财，享受最高10.85%年化收益。",
   "saveTimeWorry":"省时省心",
   "inviteSavingTip2":"余额自动转入赚取收益",
   "security":"安全保障",
   "inviteSavingTip3":"资金安全放心，明细可查",
   "turnOnAutoTransfer":"开启余额自动转入",
   "notOpen":"暂不开启",
   "autoPurchaseAll":"自动申购所有活期产品",
   "autoPurchaseTip1":"开启自动申购后，会在每日 7:00~9:00 (HKT)，系统将使用您账户中所有可申购的余额，自动申购此活期理财产品。",
   "turnOnAutoPurchase":"开启自动申购",
   "autoPurchaseTip2":"您正在开启 %{currency} %{num}个市场的自动申购功能，请确认！",
   "haveTurnOnAutoPurchase":"您已打开自动申购",
   "haveTurnOnAutoPurchaseTip1":"您已将全部市场的自动申购功能开启，无需重复操作。如需关闭某个市场的自动申购，请下滑找到该市场并手动关闭。",
   "purchaseAmount":"申购金额",
   "accountBalance1":"账户可用",
   "interestTime":"计息时间",
   "referenceAPY":"参考APY",
   "confirmPurchase":"确认申购",
   "confirmPurchaseTerms":"点击“确认申购”按钮则代表您已阅读并同意",
   "kikiSavingsTerms":"KIKI财富服务协议",
   "purchaseAmount1":"申购数量",
   "purchaseTime":"申购时间",
   "purchaseSuccess":"申购成功",
   "purchaseFail":"申购失败",
   "redeemAmount":"赎回数量",
   "redeemTime":"赎回时间",
   "redeemSuccess":"赎回成功",
   "redeemFail":"赎回失败",
   "redeem":"赎回",
   "redeemCurrency":"赎回币种",
   "redeemAmount1":"赎回额度",
   "canRedeemAmount":"可赎回",
   "interestEarned":"产生利息",
   "savingAPY":"存款APY",
   "redeemConfirm":"确定赎回",
   "redeemConfirmTip1":"赎回额度于今日达到现货资产，请注意查收",
   "AutoBuyDetailTip1":"定投计划，是通过定期的投资计划，定期对数字资产投资的计划型策略。将多种数字资产通过一定的比例组合在一起，定期自动进行买入的一种投资方式。",
   "AutoBuyDetailTip2":"普遍使用，省时省力",
   "AutoBuyDetailTip3":"从短期来看，数字币市场是不可预测的，但从长远来看，优质的数字货币是看涨的。但我们很难抉择在何时入场及购买哪种数字货币。因此，定投ETF为您提供了良好的解决方案：",
   "AutoBuyDetailTip4":"平摊风险，聚沙成塔",
   "AutoBuyDetailTip5":"常见的定投方式是按月投。也就是每月将一部分资金通过积攒的形式投入。通过常年累月的资金积累加上复利效应。最终使财富增值。也避免了一次性大额投资所带来的的高风险。",
   "AutoBuyDetailTip6":"点击收起",
   "AutoBuyDetailTip7":"了解更多",
   "AutoBuyDetailTip8":"定投的方法是适合绝大多数的普通投资者的。我们为您筛选出的货币均适合长期定投，您可以选择几种打包，并设置定投周期和金额，就会按要求定期投资。",
   "change":"更改",
   "averageAPY3Years":"均衡投资前3年平均年化",
   "easy3Steps":"接下来将通过简短的3步",
   "setUpYourRegularInvesmtentPlan":"设置您的定期购买计划",
   "comboRatio":"组合比例",
   "amountAndCycle":"金额及周期",
   "confirmAutoPurchase":"确认定投计划",
   "regularInvestmentPlanSetting":"设置定投计划",
   "currencyAndRatio":"货币及比例",
   "confirmInvestCurrecnyRatio":"确定投资币种",
   "ratio":"比例",
   "confirmSaving":"确定投资",
   "autoCircle":"定投周期",
   "confirmRegularInvestment":"确认定投计划",
   "autoAmount":"定投金额",
   "savingDistribution":"投资分布",
   "amountPerCycle":"每期投入金额",
   "purchaseFee":"认购费率",
   "autoTransferTip1":"定投自动转入活期理财",
   "autoTransferTip2":"定投转入活期理财",
   "autoTransferTip3":"选中后，如果定投所得币种，为活期理财支持的币种，则自动转入活期理财",
   "nextInvestDay":"最近扣款日",
   "readAndAgree":"我已阅读并同意",
   "readAndAgreeModal":"我已阅读并同意",
   "savingsTerms1":"《KIKI定投使用协议》",
   "confirmAndEffect":"确认并生效",
   "agreeSavingsTerms1":"请同意KIKI定投使用协议",
   "toReadAndAgreeSavingsTerms1":"请阅读并同意",
   "myPosition":"我的持仓",
   "allPosition":"全部持仓",
   "allPositionValue":"全部持仓估值",
   "allAccountValue":"总账户资产估值",
   "yestordayProfit":"昨日收益",
   "yestordayProfitValue":"昨日持仓收益",
   "allProfit":"总收益",
   "flexibleSavings":"活期",
   "regularInvestment":"定投",
   "positionAmount":"持仓数量",
   "autoPurchaseRate":"定投收益率",
   "autoPurchaseTotalAmount":"累计定投",
   "autoPurchaseAmount":"定投金额",
   "running":"进行中",
   "paused":"已暂停",
   "flexibleSavingsDetails":"活期理财明细",
   "positionAmount1":"持仓额度",
   "basicInformation":"基本信息",
   "currentAPY":"当前年化收益",
   "buyAmount":"投入金额",
   "cumulativeEarns":"累计利息",
   "purchaseHistory":"购买历史",
   "purchaseMore":"追加申购",
   "orderType":"交易类型",
   "investmentDetails":"投资明细",
   "confirmTime":"确认时间",
   "contractId":"合同编号",
   "investFee":"手续费",
   "investAmount":"投资数量",
   "fundInfo":"基金信息",
   "feeType":"费用项",
   "regularInvestDetails":"定投明细",
   "investHistory":"定投历史",
   "stopAndDelete":"终止并删除此计划",
   "pauseInvest":"暂停定投",
   "resumeInvest":"恢复定投",
   "earnHistory":"理财历史记录",
   "startTime1":"开始时间",
   "endTime":"结束时间",
   "singlePrice":"单价",
   "purchaseFee1":"申购费",
   "redeemFee":"赎回费",
   "withdraw1":"撤回",
   "interest":"计息",
   "purchase1":"认购",
   "distribution":"分发",
   "money1":"金额",
   "daily":"每天",
   "weekly":"每周",
   "biWeekly":"每月2次",
   "monthly":"每月",
   "dailyAutoInvest":"每天自动投资",
   "weeklyAutoInvest":"每周一自动投资",
   "biWeeklyAutoInvest":"每月的1日和15日进行自动投资",
   "monthlyAutoInvest":"每月的1日进行自动投资",
   "turnOffAutoTransfer":"关闭自动转入活期",
   "pauseInvest1":"暂停此定投计划",
   "resumeInvest1":"恢复此定投计划",
   "stopAndDeleteTip1":"终止并删除后，此定投计划会彻底删除，不再出现于您的定投计划列表中。",
   "pauseInvestTip1":"暂停后将不会继续执行定投并且会重新计算此定投计划的收益数据",
   "stopInvestTip1":"停止后将不享受活期利息和费率减免优待",
   "resumeInvestTip1":"恢复后将会继续执行定投并且会重新计算此定投计划的收益数据",
   "stopAndDelete1":"终止并删除",
   "stopTransfer":"停止转入",
   "tempNotClose":"暂不关闭",
   "chooseCurrency":"选择货币",
   "currency":"币种",
   "namePlan":"自定义定投名称",
   "remarkPlan":"备注此定投的名称为：",
   "autoInvestInterval":"定投投资间隔",
   "financing.product.param.invalid":"创建理财产品参数错误",
   "financing.product.not.exist":"该理财产品不存在或已下线",
   "financing.contract.not.exist":"尚未申购该活期理财产品",
   "financing.contract.close.auto.subscribe.fail":"关闭自动申购失败",
   "financing.contract.not.open.auto.subscribe":"自动申购开启失败",
   "financing.contract.subscribe.fail":"申购失败",
   "financing.contract.insufficient.balance":"账户余额不足",
   "financing.subscribe.amount.less.than.min":"申购金额小于最小限额",
   "financing.subscribe.amount.more.than.max":"申购金额大于最大限额",
   "financing.subscribe.amount.not.increment":"申购金额必须是xxx的倍数",
   "financing.subscribe.success":"申购成功",
   "financing.contract.redeem.fail":"赎回失败",
   "financing.account.amount.incorrect":"账务异常,请稍后重试",
   "financing.contract.revoke.fail":"撤销申购失败",
   "financing.subscribe.auto.success":"自动申购开启成功",
   "financing.subscribe.auto.fail":"自动申购开启失败",
   "financing.product.already.exist":"理财产品已存在",
   "financing.revoke.fail":"撤销失败",
   "financing.bearing.fail":"计息失败",
   "financing.product.already.exist.auto.subscribe":"已经开启了自动申购",
   "investment.product.param.invalid":"创建定投产品参数错误",
   "investment.product.not.exist":"定投产品不存在或已下线",
   "investment.product.proportion.invalid":"定投比例不支持",
   "investment.product.pay.option.invalid":"定投支付选项不支持",
   "investment.subscribe.amount.less.than.min":"定投金额小于最小限额",
   "investment.subscribe.amount.more.than.max":"定投金额大于最大限额",
   "investment.subscribe.amount.not.increment":"定投金额必须是xxx的倍数",
   "investment.subscribe.period.invalid":"定投周期不支持",
   "investment.contract.not.exist":"还未有定投计划",
   "investment.contract.name.format.invalid":"定投计划名称格式错误",
   "investment.contract.status.not.running":"定投计划已停止",
   "investment.contract.status.not.working":"定投计划已失效",
   "financing.operate.fail":"操作失败",
   "fcm.title.investment.result.fail":"",
   "fcm.title.investment.result.fail.user.invalid":"",
   "fcm.title.investment.result.fail.balance.insufficient":"",
   "fcm.body.investment.result.fail":"定投计划失败",
   "fcm.body.investment.result.fail.user.invalid":"定投计划用户错误",
   "fcm.body.investment.result.fail.balance.insufficient":"定投失败，余额不足",
   "earn.transfer.fail.need.upgrade":"划转失败，需要升级版本",
   "treasure.transfer.fail.need.upgrade":"划转失败，需要升级版本",
   "noFlexRecord":"尚未申购任何活期产品",
   "noAutoRecord":"尚未申购任何定投产品",
   "noEarningRecord":"尚未申购任何理财产品",
   "minPurchaseAmount":"最小申购额度为%{amount} %{currency}",
   "exceedBalance":"超过可用余额%{amount} %{currency}",
   "maxPurchaseAmount":"超过最大申购额度%{amount} %{currency}",
   "onlyMultiple":"只能是%{amount}的倍数",
   "exceedRedeemAmount":"超过可赎回额度%{amount} %{currency}",
   "investment.contract.plan.already.effective":"定投计划已生效",
   "investment.contract.enough.balance.invest":"请保持资金充足以确定定投可以顺利执行",
   "pleaseSelect":"请选择",
   "turnOffAutoTransferSuccess":"关闭自动转入活期成功",
   "turnOnAutoTransferSuccess":"开启自动转入活期成功",
   "pauseInvestSuccess":"暂停定投成功",
   "resumeInvestSuccess":"恢复定投成功",
   "renameSuccess":"修改名称成功",
   "stopAndDeleteSuccess":"终止并删除成功",
   "otc.customer.remark.code.op.exception":"获取备注码出错",
   "otc.customer.remark.code.consume.exception":"消费备注码出错",
   "depositRemark":"转账识别码",
   "turnOnAutoBuyFlexSuccess":"开启自动申购成功",
   "turnOffAutoBuyFlexSuccess":"关闭自动申购成功",
   "turnOffAutoBuyInvestmentSuccess":"关闭自动定投成功",
   "startNotOverEndTime":"起始时间不能大于结束时间",
   "fillInForm":"填写表单",
   "aip.btc.1620892282130":"定投比特计划",
   "aip.eth.1620895592746":"定投以太计划",
   "aip.doge.1620895784252":"定投狗狗计划",
   "product.key":"定投比特计划",
   "product.key2":"定投以太计划",
   "eip.eth001.key":"定投以太计划",
   "product.key1":"定投狗狗计划",
   "AccountSuspenseTip":"由于第三方国际风险系统检测到您的个人资料属高风险类别，基于本平台的《用户服务协议》和有关的监管制度和条例下，本平台未能为您提供服务，您的帐户将会被暂时冻结。请您点击以下申诉并填写表格，我们会尽快与您跟进有关后续处理及退款事宜。不便之处，敬请原谅。",
   "activity_gift_currency_HKD":"HKD",
   "activity_gift_currency_USD":"USD",
   "activity_gift_currency_USDT":"USDT",
   "activity_gift_pay_tip_1":"• 请把款项 %{total} %{currency} 汇到以下账户，汇款时务必备注写上本人姓名和kiki给定的转账备注6位数字；",
   "activity_gift_pay_tip_2":"• 完成汇款后，请您务必于24小时内上传转账截图；",
   "activity_gift_account_select":"选择支付方式",
   "activity_gift_account_kikitrade":"Kikitrade 账户余额",
   "activity_gift_account_bank":"银行卡转账",
   "activity_gift_pending_payment":"待支付",
   "activity_gift_balance_tips_title":"余额说明",
   "activity_gift_balance_tips_content":"您%{currency}钱包账户可用资产",
   "activity_gift_bank_name_tips":"（请输入转账银行账户姓名）",
   "activity_gift_account_pay":"支付",
   "activity_gift_limit":"购买总金额不可大于$%{total} %{currency}",
   "activity_gift_purchase_total":"礼品卡单笔购买额度，不超过%{total} %{currency}",
   "activity_purchase_kiki_virtual":"您购买了%{quantity}张 %{amount}%{currency}的虚拟礼品卡。一共需支付%{total}%{currency}，请确认支付。",
   "activity_purchase_kiki_physical":"您购买了%{quantity}张 %{amount}%{currency}的实体礼品卡。一共需支付%{total}%{currency}，请确认支付。",
   "activity_gift_history_cancel_reason":"取消原因：%{reason}",
   "activity_gift_wallet_balance":"账户余额",
   "redeem.account.not.exist":"用户账户不存在",
   "redeem.insufficient.balance":"用户账户可用余额不足",
   "redeem.card.order.duplicate":"礼品卡订单号重复",
   "redeem.card.order.save.fail":"礼品卡交易失败",
   "redeem.card.order.update.fail":"礼品卡交易失败",
   "redeem.card.order.not.exist":"礼品卡不存在",
   "redeem.card.discard.fail":"礼品卡购买异常",
   "redeem.customer.remark.code.op.exception":"礼品卡识别码显示异常",
   "activity_gift_history_paid":"待发货",
   "activity_gift_history_failed":"支付失败",
   "image":"图片",
   "video":"视频",
   "publishPosting":"正在发布中...",
   "publishPosting1":"发布中...",
   "rePublishPost":"重新发布",
   "save":"保留",
   "notSave":"不保留",
   "saveLongTextTip":"保留此次长文内容?",
   "publishPostFailTip":"帖子发布失败，请稍后重试！",
   "publishPostSuccess":"帖子发布成功",
   "onlyInsertOneVideo":"仅允许插入一个视频",
   "maxUploadImageCount":"图片最多上传%{count}张",
   "inputTitle":"输入标题",
   "inputText":"输入正文",
   "pubslishPost":"发帖",
   "pubslishLongLext":"发长文",
   "hasPostPublishing":"有帖子正在发布中",
   "latestData":"最新数值",
   "riseAndFallToday":"今日涨幅",
   "riseAndFallToday1":"涨幅(24H)",
   "topRiseCurrencyAndRiseRate":"领涨币种/涨幅(24H)",
   "fundingRate":"资金费率",
   "orderDetail":"订单详情",
   "avgPrice1":"成交均价",
   "filledQty":"成交总额",
   "tradeDetail":"成交详情",
   "tradeTime":"成交时间",
   "entrust":"委托",
   "allOrder":"全部订单",
   "zones":"板块",
   "tradingVolume":"交易量",
   "latestPrice":"最新价",
   "riseRate24H":"涨幅(24H)",
   "24HVol":"24H 量",
   "spotOrder":"现货订单",
   "fiatOrder":"法币订单",
   "fiatDeposit":"法币入金",
   "entrustPrice":"委托价",
   "tradePrice":"成交价",
   "entrustAmount":"委托量",
   "tradeType":"成交类型",
   "marketCap":"市值",
   "circulatingSupply":"流通供应量",
   "maxSupply":"最大供给量",
   "totalSupply":"总供应量",
   "officialWebsite":"官网",
   "tradesHistory":"历史成交",
   "orderStatus_pending_create":"下单中",
   "orderStatus_partial_filled":"部分成交",
   "orderStatus_filled":"全部成交",
   "orderStatus_partial_filled_cancelled":"部分成交已撤销",
   "orderStatus_partial_filled_rejected":"部分成交已驳回",
   "orderStatus_rejected":"已驳回",
   "search_care_coin_name":"搜索您关心的币种名称",
   "hot_search":"热门搜索",
   "history_search":"历史搜索",
   "history_search_clear":"清除",
   "search_result":"搜索结果",
   "search_cancel":"取消",
   "choice_add":"添加",
   "edit_finish":"完成",
   "edit_choice":"编辑自选",
   "all_select":"全选",
   "favourite":"自选",
   "choice_header_name":"名称",
   "choice_header_top":"置顶",
   "choice_header_drag":"拖动",
   "choice_auto_add":"一键添加",
   "choice_custom_add":"自定义添加",
   "input_transaction_volume":"输入交易额",
   "buy_recharge":"充值",
   "add_favorite":"添加自选",
   "add_favorite_success":"添加自选成功",
   "add_favorite_failed":"添加自选失败",
   "edit_favorite_success":"编辑自选成功",
   "edit_favorite_failed":"编辑自选失败",
   "remove_favorite_success":"移除自选成功",
   "remove_favorite_failed":"移除自选失败",
   "add_favorite_zone_success":"板块添加自选成功",
   "add_favorite_zone_failed":"板块添加自选失败",
   "remove_favorite_zone_success":"板块移除自选成功",
   "remove_favorite_zone_failed":"板块移除自选失败",
   "symbol_list":"交易对",
   "tsla":"特斯拉",
   "aapl":"苹果",
   "fb":"Facebook",
   "amzn":"亚马逊",
   "maotai":"茅台",
   "googl":"谷歌",
   "coinBase":"Coinbase",
   "tencent":"腾讯",
   "HSI":"恒生指数",
   "TWSE":"台湾加权指数",
   "nasdaq":"纳斯达克指数",
   "XAUUSD":"黄金美元价格",
   "CL":"原油指数",
   "DINIW":"美元指数",
   "US30Y":"美元30年债券指数",
   "says":"说",
   "greed":"贪婪",
   "extremeGreed":"极度贪婪",
   "neutral":"中立",
   "fear":"恐惧",
   "extremeFear":"极度恐惧",
   "timeType15M":"15分",
   "timeType1H":"1小时",
   "timeType4H":"4小时",
   "timeType1D":"1天",
   "timeType1W":"1周",
   "timeType1M":"1月",
   "community_hot_topic_feed":"热门与话题",
   "participationTopic":"参与话题",
   "relatedTransactions":"相关交易",
   "comeAndComment":"快来评论吧～",
   "postCountSuffix":"篇内容",
   "topic.not.found":"该话题已过期",
   "refreshPostCount1":"更新了",
   "refreshPostCount2":"篇内容",
   "refreshPostRecommend":"推荐内容已更新",
   "interest2":"利息收取",
   "liquidation1":"清算记录",
   "borrowed":"借款",
   "liquidationAmt":"清算额",
   "frozen":"冻结",
   "maxAvbl":"最大可用",
   "interestFee":"应付利息",
   "8HourInterestRate":"8小时利率",
   "balance1":"余额",
   "maxWithdrawal":"最大可提",
   "marginTurnedOn":"开启杠杆成功",
   "marginDisabled":"关闭杠杆成功",
   "marginTurnedOnFail":"开启杠杆失败",
   "marginDisabledFail":"关闭杠杆失败",
   "wallet1":"钱包",
   "open":"开启",
   "netAssetValue":"总净资产估值",
   "initialMargin":"初始保证金IM",
   "maintenanceMargin":"维持保证金MM",
   "hideSmallBalances":"隐藏小额资产",
   "marginTip1":"当前您的账户已经开启杠杆模式，不能使用理财自动申购功能。您可以手动申购理财产品。",
   "marginTip2":"您当前账户还存在借贷金额，您需要完全归还当前借贷以后，才能关闭杠杆",
   "marginTip3":"• 当前账户还存在应付利息，系统已为您自动归还应付利息。\n• 由于账户资产不足以抵扣应付利息，产生借贷金额。请完全归还借贷以后，再关闭杠杆。",
   "marginTip4":"当前账户还存在杠杆订单处于委托中，请撤销杠杆委托单后，再关闭杠杆",
   "marginTip5":"请先撤销挂单，再开启杠杆",
   "marginTip6":"当前您的账户已经开启杠杆模式，不能使用理财定投功能。您可以手动申购理财产品。",
   "marginTip7":"请先关闭理财自动申购，再开启杠杆",
   "marginTip8":"请先关闭理财定投，再开启杠杆",
   "yourDebt":"借款类别",
   "withMargin":"• 开启杠杆时：",
   "maxAvblTip1":"最大可用 = (NAV-IM)/IMR *(1-手續費率/IMR）*0.99 /Bid Price + abs(Sell Unwind Qty)",
   "maxAvblTip3":"最大可提数额 =  (資產凈值NAV - 初始保證金IM) *60% /Bid Price",
   "maxAvblTip4":"最大可提数额=余额；",
   "maxAvblTip5":"最大可用 =  (資產凈值NAV - 初始保證金IM) *60% /Bid Price",
   "maxAvblTip6":"最大可用 = 账户余额；",
   "withoutMargin":"• 未开启杠杆时：",
   "maxAvblTip2":"最大可用 = 当前资产余额",
   "interestFeeTip1":"Kikitrade收取利息时间每日固定为：",
   "interestFeeTip2":"00:00、08:00、16:00",
   "interestFeeTip3":"利息只有当借贷产生时才会收取，每个时间段将收取的利息可以在“应付利息”中查看。",
   "interestFeeTip4":"利息按照8小时利率计算，不足8小时以8小时计算。",
   "riskRateTip1":"此值越高，资产越安全，清算风险越小。\n若小于 1 则系统会进行清算作业。\n风险系数 = 总净资产估值 / 维持保证金",
   "riskRate":"风险系数",
   "imTip1":"此为加仓时所需之最低保证金，此金额会随着仓位的增大而增加。\n下单后需满足“总净资产估值 > IM”才能加仓。",
   "mmTip1":"此为帐户资产之清算线。\n若“总净资产估值 < MM”则会进行清算作业。\n维持保证金 = 初始保证金 × 80%",
   "marginModeClosed":"杠杆模式未开启",
   "marginTrading":"杠杆交易",
   "loanInterest":"借贷利息",
   "10Bid":"买盘十档",
   "10Ask":"卖盘十档",
   "orderBook":"买卖十档",
   "borrow":"借",
   "maxBuy":"最大可买",
   "maxSell":"最大可得",
   "spotBuy":"现金可买",
   "spotSell":"现金可得",
   "used":"本次已用",
   "borrowed1":"本次借",
   "marginAvbl":"杠杆可用",
   "spotAvbl":"现金可用",
   "spotAvbl1":"现货可用",
   "enterTotalAmount":"输入成交总量",
   "enterBuyAmount":"输入购买量",
   "enterSellAmount":"输入卖出量",
   "riskRateLiquidating":"清算中",
   "riskRateWarning":"极高风险",
   "riskRateHigh":"高风险",
   "riskRateMedium":"中风险",
   "riskRateLow":"低风险",
   "riskRateSafe":"安全",
   "minBuyMessage":"最小购买量不得小于%{amount}",
   "minSellMessage":"最小卖出量不得小于%{amount}",
   "maxBuyMessage":"最大可买量%{amount}",
   "maxSellMessage":"最大可卖量%{amount}",
   "oneMaxBuyMessage":"单笔最大购买量不得大于%{amount}",
   "oneMaxSellMessage":"单笔最大卖出量不得大于%{amount}",
   "minTotalMessage":"最小交易额不得小于%{amount}",
   "oneMaxTotalMessage":"单笔最大交易额不得大于%{amount}",
   "oneMinTotalMessage":"单笔最小交易额不得小于%{amount}",
   "maxAvblMessage":"最大可用额%{amount}",
   "openMarginTip":"您已用金额超过现货最大可用量，可以通过打开杠杆来继续交易。",
   "repaymentPending":"待还",
   "liquidatedAssets":"清算资产",
   "ordersCancelled":"委托取消",
   "spot.order.invalid.status":"撤单失败",
   "spot.order.order.book.cancel.fail":"撤单失败",
   "spot.order.update.fail":"更新失败",
   "spot.order.trade.duplicate":"下单失败",
   "spot.order.trade.state.illegal":"订单状态错误",
   "spot.customer.stop.trade":"交易状态错误",
   "spot.order.book.invoke.fail":"下单失败",
   "margin.interest.state.illegal":"关闭杠杆失败",
   "margin.interest.duplicate":"杠杆利息处理重复",
   "margin.config.update.fail":"更新失败",
   "margin.config.disable.forbidden":"杠杆关闭失败",
   "margin.balance.insufficient":"余额不足",
   "margin.lock.fail":"下单失败",
   "margin.unlock.fail":"风险检查解锁失败",
   "margin.orders.exceed":"杠杆下单数量超限",
   "margin.transfer.fail":"余额不足",
   "margin.transfer.lock.fail":"开启杠杆失败，请稍后重试",
   "margin.open.country.forbidden":"您当前所在地不允许使用杠杆交易",
   "margin.open.saving.forbidden":"请先关闭理财自动申购，再开启杠杆",
   "margin.open.invest.forbidden":"请先关闭理财定投，再开启杠杆",
   "margin.open.order.forbidden":"请先撤销挂单，再开启杠杆",
   "margin.open.query.order.fail":"开启杠杆失败，请稍后重试",
   "margin.close.loan.forbidden":"关闭杠杆失败-用户有借款",
   "margin.close.order.forbidden":"请先撤销挂单，再开启杠杆",
   "margin.close.interest.cannot.afford":"关闭杠杆时，应付利息不足",
   "margin.interest.fail":"关闭杠杆失败，请稍后重试",
   "margin.already.closed":"杠杆已关闭",
   "margin.close.fail":"杠杆关闭异常",
   "noFav":"暂时还没有收藏",
   "noFeed":"暂时还没有动态",
   "noFollow":"暂时还没有关注",
   "noComment":"暂时还没有任何评论",
   "noReply":"暂时还没有任何回复",
   "noSystemNotice":"暂时还没有通知",
   "noFans":"暂时还没有粉丝",
   "noWow":"暂时还没有收到赞",
   "noData":"暂时没有任何数据",
   "noSearch":"没有搜索到你想要的内容",
   "noPostComment":"暂时还没有评论",
   "coinCoin":"币币",
  "closeAutoBuyTip":"此操作将关闭您的活期理财自动申购功能\n确定关闭？",
  "closeAutoInvestTip":"此操作将关闭您的理财定投功能\n确定关闭？",
  "marginTradeNotOpen":"杠杆功能上线准备中，敬请期待~",
  "borrowCoinTip1":"每次交易产生借贷时都需要确认提示",
  "borrowCoinTip2":"本次交易将产生≈%{amount} %{currency}借款金额，确定继续交易？",
  "autoInvest":"自动定投",
  "system.status.upgrading":"系统升级维护中，请升级完成后重试。\n维护时段：2021-10-21 10:30-18:30",
   "credit_recharge":"信用卡买币",
   "credit_desc":"支持Visa、MasterCard以及SEPA等",
   "credit_channels_close":"信用卡买币服务临时中止，请稍后重试！",
   "credit_buy_coin":"信用卡买币",
   "buy_by_amount":"按金额购买",
   "buy_by_quantity":"按数量购买",
   "credit_spend":"花费",
   "credit_estimated":"预估购得",
   "credit_buy":"购买",
   "credit_estimated_cost":"预估花费",
   "credit_input_valid":"请输入有效数字",
   "credit_not_min":"不得低于最小下单量",
   "credit_not_max":"不得超过最大下单量",
   "credit_order_next_step":"继续",
   "credit_channel_type":"信用卡渠道",
   "credit_channel_info":"说明",
   "credit_estimated_error":"当前支付渠道的服务出现问题，请稍后重试！",
   "credit_channel_1_SWITCHERE":"• 通过switchere购买加密货币，需要完成该平台的注册以及KYC。",
   "credit_channel_2_SWITCHERE":"• %{fiatCurrency}在switchere交易的最小下单量为%{fiatCurrencyMin}%{fiatCurrency}，最大下单量为%{fiatCurrencyMax}%{fiatCurrency}。",
   "credit_channel_3_SWITCHERE":"• 您在本页面看到的，所购入的加密货币数量，仅为预估得到的数量，非实际最终成交的数额。实际确认的购买量，以最终在 switchere 上支付成交时的数额为准。",
   "credit_channel_4_1_SWITCHERE":"• 如果您有任何和 switchere 相关的问题，可通过 switchere 的",
   "credit_channel_4_2_SWITCHERE":"来查找您关心的问题。",
   "credit_order_history":"订单历史",
   "credit_order_confirmation":"订单确认中",
   "credit_order_pending":"待支付",
   "credit_order_failed":"交易失败",
   "credit_order_failure_reason":"失败原因",
   "credit_order_successful":"交易成功",
   "credit_order_customer_service":"联系客服",
   "credit_order_payment_completed":"继续支付",
   "credit_order_transaction_confirmation":"交易确认",
   "credit_order_billing_information":"账单信息",
   "credit_order_payment_channel":"支付渠道",
   "credit_order_fiat_currency":"法币币种",
   "credit_order_fiat_amount":"法币金额",
   "credit_order_purchase_currency":"购买币种",
   "credit_order_estimated_quantity":"预估数量",
   "credit_order_purchase_quantity":"购买数量",
   "credit_order_estimated_amount":"预估金额",
   "credit_order_place_order":"下单",
   "credit_order_loading":"请稍等...",
   "credit_order_disclaimer":"免责声明",
   "credit_disclaimer_tips1_SWITCHERE":"• 您即将离开Kikitrade前往Switchere.com，Switchere是由第三方独立运营的法定货币与加密货币交易平台，所有相关服务均由Switchere方提供。请在使用本服务前，仔细阅读并同意Simplex的使用条款。",
   "credit_disclaimer_tips2_1_SWITCHERE":"• 有关交易的任何问题，请联系 ",
   "credit_disclaimer_tips2_2_SWITCHERE":" 。",
   "credit_disclaimer_tips3_SWITCHERE":"• Kikitrade对因使用该服务遭受的任何损失或损害不承担任何责任。",
   "credit_order_trading_channel":"交易渠道",
   "credit_order_currency":"支付币种",
   "credit_order_amount":"支付金额",
   "credit_order_fee":"手续费",
   "credit_order_on_chain_fee":"链上手续费",
   "credit_card_transaction":"信用卡交易",
   "credit_order_reminder":"订单提醒",
   "credit_order_whether_completed":"您当前这笔订单是否已完成支付？",
   "credit_order_incomplete":"未完成",
   "credit_order_completed":"已完成",
   "keyboard_desc":"KIKI专属键盘  输入更安全",
   "credit_fiat_select":"选择货币",
   "credit_crypto_select":"选择币种",
   "quickpay.order.create.fail":"订单创建失败",
   "quickpay.order.update.fail":"订单更新失败",
   "quickpay.channel.closed":"支付渠道已关闭",
   "quickpay.channel.not.support.the.service":"支付渠道不支持该服务",
   "quickpay.order.not.exist":"订单不存在",
   "quickpay.channel.api.call.fail":"调用支付渠道api失败",
   "fcm.title.quickpay.order.fail": "",
   "fcm.body.quickpay.order.fail": "您有一笔信用卡入金交易失败，详情查看这里>>",
   "fcm.title.quickpay.order.success": "",
   "fcm.body.quickpay.order.success": "恭喜您成功入金 [%{payinCurrency}] %{payinAmount}，详情查看这里>>",
   "community":"社区",
   "news":"资讯",
   "explore":"发现",
   "deposit1":"入金",
   "hotCoin":"热门币种",
   "newCoin":"Kiki新币",
   "lastEvent":"最新活动",
   "tags":"话题",
   "hotTheme":"主题板块",
   "kikiStars":"热门人物",
   "hotPicks":"精选",
   "kikiMaster":"kiki大师",
   "zoneNameETH":"ETH板块",
   "zoneNameMAIN":"主流币",
   "zoneNameDEFI":"Defi板块",
   "zoneNameCOMMON":"公链",
   "zoneNamePOW":"POW生态",
   "zoneNameDOT":"DOT生态",
   "zoneNameMEME":"MEME生态",
   "zoneNameSOL":"SOL生态",
   "zoneNameBSC":"BSC生态",
   "zoneNameNFT":"NFT生态",
   "zoneNameGAMEFI":"GameFi",

   "deposit_step1": "第一步：登录",
   "deposit_step2": "第二步：KYC",
   "deposit_step3": "第三步：入金",
   "deposit_login": "登录",
   "deposit_KYC": "KYC",
   "deposit_deposit": "入金",
   "deposit_bankDeposit_title": "银行卡入金",
   "deposit_bankDeposit_desc": "银行卡转账买入数字货币",
   "deposit_creditDeposit_title": "信用卡入金",
   "deposit_creditDeposit_desc": "支持Visa、Mastercard等多种信用卡",
   "deposit_c2cDeposit_title": "C2C入金",
   "deposit_c2cDeposit_desc": "用户和用户之间直接交易",
   "deposit_giftCard": "礼品卡",
   "deposit_giftCard_desc": "kiki推出实体Bitcoin礼品卡  购买礼品卡   简单三步，真正持有比特币",
   "deposit_giftCard_button": "购买礼品卡",
   "deposit_guideForBeginners": "Kiki教室"
}
export default obj;
