// @flow
import stream, { connect } from 'getstream';
import { subscribe } from 'redux-subscriber';
import type { StateType } from '../actions/common';
import { readonlyToken } from './base';
import type { CustomerMisc, RankingItem, SocialResult } from './base';

let socialToken: ?string = readonlyToken;
subscribe('SocialReducer.socialToken', (state: StateType) => {
  const { SocialReducer } = state;
  socialToken = SocialReducer.socialToken;
});

// model start

export interface RankingListResult {
  items: RankingItem[],
  next_token?: string,
  page_size: number
}
export interface RankingTypeListResult {
  day?: RankingListResult,
  week?: RankingListResult,
  follower?: RankingListResult
}

export type RankingResultResponseType = SocialResult<RankingTypeListResult>;
export type RankingResponseType = SocialResult<RankingListResult>;
export interface RankingAll{
  recommand: RankingListResult,
  day: RankingListResult,
  week: RankingListResult,
  follower: RankingListResult
}

export type RankingAllResponseType = SocialResult<RankingAll>;
// getInfo
export interface Info{
  day: ?RankingItem,
  week: ?RankingItem,
  customer: {res: CustomerMisc},
  isFollow: ?boolean,
  isSubscribe: ?boolean
}
// apply
export type ApplyResponseType = SocialResult<string>;

export type InfoResponseType = SocialResult<Info>;

// batch
export interface TraderRankingBatch {
  batch_id: number,
  type: string,
  timestamp?: number,
  top_currencies: string
}
export type RankingBatchResponseType = SocialResult<TraderRankingBatch>;
export type BlockUserResponseType = SocialResult<boolean>;

// class definition start

export default class TraderAPI {
  client: stream.Client;

  constructor (cl: stream.Client, token: ?string, options: ?{ [p: string]: string }) {
    if (cl != null) {
      this.client = cl;
    } else {
      this.client = connect(
        'key',
        token ?? socialToken ?? readonlyToken,
        'app_id',
        options ?? {}
      );
    }
  }

  /**
   * day ranking, week ranking, follower ranking
   * @param type: day, week, follower
   * @param options:  {limit , token}
   * @param callback
   */
  getRanking (type: 'day' | 'week' | 'follower', options: ?{
    limit: ?number,
    token?: ?string
  }): Promise<RankingResultResponseType> {
    return this.client.get({
      url: 'trader/' + type + '/',
      qs: options ?? {},
      signature: this.client.getOrCreateToken()
    });
  }

  getAllRanking (options: ?{}): Promise<RankingAllResponseType> {
    return this.client.get({
      url: 'trader/ranking/all/',
      qs: options ?? {},
      signature: this.client.getOrCreateToken()
    });
  }

  getInfo (id: string, options: {loginUserId: string}): Promise<InfoResponseType> {
    return this.client.get({
      url: 'trader/' + id + '/info',
      qs: options,
      signature: this.client.getOrCreateToken()
    });
  }

  /**
   * apply
   * @param email
   * @param desc
   * @param url
   * @returns Promise<ApplyResponseType>  kolApplyStatus
   */
  apply (email: string, desc: ?string, url: ?string): Promise<ApplyResponseType> {
    return this.client.post({
      url: 'trader/apply',
      body: { email },
      signature: this.client.getOrCreateToken()
    });
  }

  /**
   * subscribe=人气排行（推荐人数);recommend=推荐排行;profitRate=收益率排行;hotMoney=总资产排行;new=新人排行
   * @param type
   * @param options
   * @returns {*}
   */
  getTraderRank (type: 'profitRate' | 'recommend' | 'subscribe' | 'new' | 'profitAmount' | 'hotMoney',
    options?: {limit?: number, token?: string, week?: boolean, currency?: string, riskLevels?: string}): Promise<RankingResponseType> {
    return this.client.get({
      url: 'trader/rankings/' + type,
      qs: options,
      signature: this.client.getOrCreateToken()
    });
  }

  getCurrencyTypeList (options?: {week?: boolean}): Promise<RankingBatchResponseType> {
    return this.client.get({
      url: 'trader/ranking/batch?week=true',
      qs: options,
      signature: this.client.getOrCreateToken()
    });
  }

  blockPost (userId: string): Promise<BlockUserResponseType> {
    return this.client.post({
      url: 'user/' + userId + '/block',
      body: {},
      signature: this.client.getOrCreateToken()
    });
  }
}

/**
 * demo response
 * {"result":{"day":{"next_token":null,"page_size":10,"items":[{"batch_id":1,"customer_id":"001","profit_amount":100.25,"profit_rate":97,"type":"day","nickName":"vincent","avatar":"http://b-ssl.duitang.com/uploads/item/201809/26/20180926162125_vccccccc.jpg","intro":"剧场版","customerId":"001","subscribeCount":2,"subscribed":false},{"batch_id":1,"customer_id":"002","profit_amount":9120,"profit_rate":75,"type":"day","nickName":"今天是","avatar":"https://kiki-beta.oss-ap-southeast-1.aliyuncs.com/001/avatar/3a9f1e6079ff449e8eebbed3d95b005c.PNG","intro":"这里也很美1122","customerId":"002","subscribeCount":1,"subscribed":true}]},"week":{"next_token":null,"page_size":
 */
