// @flow

// import { StyleSheet, Dimensions, Platform, StatusBar } from 'react-native'
import { StyleSheet, Dimensions } from 'react-native'
import p from '../utils/Transfrom';

// eslint-disable-next-line flowtype/no-weak-types
export const dimensions: any = {
  fullHeight: Math.round(Dimensions.get('window').height),
  fullWidth: Math.round(Dimensions.get('window').width)
}

// added below our colors, fonts, padding etc
export const colors = {
  pureWhite: '#fff',
  white1: '#F6F6F6',
  white2: '#F7F7F8',
  white3: '#F9F9FA',
  white4: '#FBFBFB',
  white5: '#D8D8D8',
  white6: 'rgba(255,255,255,0.8)',
  white7: 'rgba(255,255,255,0.2)',
  white8: '#EDEDED',
  white9: '#F2F1F5',
  gray1: '#696F7F',
  gray2: 'rgba(142,147,159,0.4)',
  gray3: '#8E939F',
  grey4: '#D9DBDF',
  grey5: '#979797',
  grey6: '#EBE9F1',
  black: '#000',
  black1: '#434B5F',
  black2: 'rgba(0,0,0,0.6)',
  black3: '#1F2126',
  black4: 'rgba(0,0,0,0.4)',
  red: '#ED220D',
  red1: '#F0506C',
  green: '#1EAE45',
  lightPurple: 'rgba(111,64,238,0.1)',
  purple: '#6F40EE',
  purple2: '#7600F6',
  transparent: 'transparent'

}
// eslint-disable-next-line flowtype/no-weak-types
export const width: any = {
  w4: p(4),
  w12: p(12),
  w28: p(28),
  w30: p(30),
  w32: p(32),
  w36: p(36),
  w39: p(39),
  w40: p(40),
  w48: p(48),
  w56: p(56),
  w64: p(64),
  w60: p(60),
  w80: p(80),
  w92: p(92),
  w100: p(100),
  w120: p(120),
  w192: p(192),
  w199: p(199),
  w230: p(230),
  w345: p(345),
  w360: p(360),
  w380: p(380),
  w400: p(400),
  w500: p(500),
  w560: p(560),
  w561: p(561),
  w590: p(590),
  w600: p(600),
  w620: p(620),
  w690: p(690)
}

// eslint-disable-next-line flowtype/no-weak-types
export const height: any = {
  h6: p(6),
  h12: p(12),
  h17: p(17),
  h27: p(27),
  h28: p(28),
  h32: p(32),
  h36: p(36),
  h39: p(39),
  h40: p(40),
  h48: p(48),
  h52: p(52),
  h56: p(56),
  h64: p(64),
  h60: p(60),
  h68: p(68),
  h72: p(72),
  h80: p(80),
  h88: p(88),
  h92: p(92),
  h120: p(120),
  h136: p(136),
  h140: p(140),
  h180: p(180),
  h192: p(192),
  h208: p(208),
  h212: p(212),
  h248: p(248),
  h288: p(288),
  h340: p(340),
  h529: p(529),
  h680: p(680),
  h759: p(759)
}
// eslint-disable-next-line flowtype/no-weak-types
export const padding: any = {
  p4: p(4),
  p6: p(6),
  p20: p(20),
  p24: p(24),
  p28: p(28),
  p32: p(32),
  p30: p(30),
  p37: p(37),
  p40: p(40),
  p48: p(48),
  p50: p(50),
  p68: p(68)
}
// eslint-disable-next-line flowtype/no-weak-types
export const margin: any = {
  m1: p(1),
  m2: p(2),
  m4: p(4),
  m6: p(6),
  m7: p(7),
  m8: p(8),
  m10: p(10),
  m12: p(12),
  m14: p(14),
  m16: p(16),
  m17: p(17),
  m18: p(18),
  m20: p(20),
  m22: p(22),
  m24: p(24),
  m25: p(25),
  m28: p(28),
  m30: p(30),
  m32: p(32),
  m36: p(36),
  m38: p(38),
  m35: p(35),
  m40: p(40),
  m48: p(48),
  m56: p(56)
}

// eslint-disable-next-line flowtype/no-weak-types
export const fontWeight: any = {
  f100: '100',
  f200: '200',
  f300: '300',
  f400: '400',
  f500: '500',
  f600: '600',
  f700: '700',
  f800: '800',
  f900: '900',
  fnormal: 'normal',
  fbold: 'bold'
}

// eslint-disable-next-line flowtype/no-weak-types
export const marginHorizontal: any = {
  mh30: p(30)
}

// eslint-disable-next-line flowtype/no-weak-types
export const marginVertical: any = {
  mv48: p(48)
}

// eslint-disable-next-line flowtype/no-weak-types
export const paddingHorizontal: any = {
  ph6: p(6),
  ph16: p(16),
  ph20: p(20),
  ph22: p(22),
  ph24: p(24),
  ph28: p(28),
  ph30: p(30)
}

// eslint-disable-next-line flowtype/no-weak-types
export const paddingVertical: any = {
  pv18: p(18),
  pv22: p(22),
  pv24: p(24),
  pv30: p(30)

}

// eslint-disable-next-line flowtype/no-weak-types
export const fontSize: any = {
  f18: p(18),
  f20: p(20),
  f22: p(22),
  f24: p(24),
  f26: p(26),
  f28: p(28),
  f30: p(30),
  f34: p(34),
  f36: p(36),
  f40: p(40),
  f46: p(46),
  f47: p(47)
}
// eslint-disable-next-line flowtype/no-weak-types
export const radius: any = {
  r8: p(8),
  r12: p(12),
  r18: p(18),
  r24: p(24),
  r26: p(26),
  r32: p(32),
  r36: p(36),
  r40: p(40),
  r48: p(48),
  r56: p(56),
  r60: p(60),
  r80: p(80),
  r90: p(90),
  r120: p(120)
}
// eslint-disable-next-line flowtype/no-weak-types
export const lineHeight: any = {
  l23: p(23),
  l26: p(26),
  l28: p(28),
  l31: p(31),
  l33: p(33),
  l36: p(36),
  l38: p(38),
  l40: p(40),
  l42: p(42),
  l46: p(46),
  l56: p(56),
  l59: p(59),
  l60: p(60)
}
// eslint-disable-next-line flowtype/no-weak-types
export const alignItems: any = {
  center: 'center'
}

// eslint-disable-next-line flowtype/no-weak-types
export const justifyContent: any = {
  center: 'center',
  spaceBetween: 'space-between',
  flexEnd: 'flex-end'
}
// eslint-disable-next-line flowtype/no-weak-types
export const textAlign: any = {
  center: 'center'
}

// eslint-disable-next-line flowtype/no-weak-types
export const layoutCenter: any = {
  alignItems: alignItems.center,
  justifyContent: justifyContent.center,
  flex: 1,
  flexDirection: 'row'
}

// eslint-disable-next-line flowtype/no-weak-types
export const zIndex: any = {
  z2: 2,
  z999: 999
}

// eslint-disable-next-line flowtype/no-weak-types
export const imageRounded32: any = {
  width: width.w32,
  height: height.h32,
  borderRadius: radius.r32
}

// eslint-disable-next-line flowtype/no-weak-types
export const imageRounded40: any = {
  width: width.w40,
  height: height.h40,
  borderRadius: radius.r40
}

// eslint-disable-next-line flowtype/no-weak-types
export const imageRounded56: any = {
  width: width.w56,
  height: height.h56,
  borderRadius: radius.r56
}

// eslint-disable-next-line flowtype/no-weak-types
export const imageRounded60: any = {
  width: width.w60,
  height: height.h60,
  borderRadius: radius.r60
}

// eslint-disable-next-line flowtype/no-weak-types
export const imageRounded80: any = {
  width: width.w80,
  height: height.h80,
  borderRadius: radius.r80
}

// eslint-disable-next-line flowtype/no-weak-types
export const imageRounded120: any = {
  width: width.w120,
  height: height.h120,
  borderRadius: radius.r120
}

// eslint-disable-next-line flowtype/no-weak-types
export const sectionTitleStyle: any = {
  fontSize: fontSize.f30,
  lineHeight: lineHeight.l38,
  color: colors.black,
  fontWeight: fontWeight.f700,
  marginTop: margin.m48,
  paddingHorizontal: paddingHorizontal.ph30
}

// eslint-disable-next-line flowtype/no-weak-types
export const theme: any = {
  colors,
  padding,
  fontSize,
  radius
};

export const darkTheme = {
  ...theme,
  colors: {
    ...theme.colors
  }
}

// base styles
// eslint-disable-next-line flowtype/no-weak-types
export const baseStyles: any = {
  container: {
    flex: 1,
    width: dimensions.fullWidth,
    backgroundColor: colors.pureWhite
  },
  // statusBarPadding: {
  //   paddingTop: p(
  //     Platform.OS === 'android' ? (StatusBar.currentHeight ?? 0) * 2 : (helper.isIphoneX() ? 44 * 2 : 44)
  //   )
  // },
  header: {
    height: height.h88,
    paddingHorizontal: paddingHorizontal.ph30,
    alignItems: alignItems.center,
    justifyContent: justifyContent.spaceBetween,
    flexDirection: 'row'
  },
  sectionStyle: {
    marginTop: margin.m32,
    flexDirection: 'row'
  }
}
// eslint-disable-next-line flowtype/no-weak-types
export default function createStyles (overrides: any = {}): any {
  /* $FlowFixMe */
  return StyleSheet.create({ ...baseStyles, ...overrides })
}
