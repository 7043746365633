module.exports = {
  stat: function () {
    return new Promise((resolve, reject) => {
      resolve();
    })
  },
  copyFile: function () {
    return new Promise((resolve, reject) => {
      resolve();
    })
  }
}
