/* @flow */

import React, { Component } from 'react';
import {
  Dimensions,
  StatusBar,
  Text,
  TouchableOpacity,
  TextInput,
  View
} from 'react-native';
import p from '../../utils/Transfrom';
// $FlowIgnore
import Modal from 'react-native-modal';
import I18n from '../../utils/i18n';
import { getClient } from '../../api/social';
import { ModalIndicator, Toast } from 'teaset';
import { emitCommentEvent } from '../../events/SocialEvents';
import RNToast from 'react-native-easy-toast';
import type { SocialResult } from '../../api/base';
import type { Feed } from '../../models/social';
import type { ViewStyle } from 'react-native/Libraries/StyleSheet/StyleSheet';
import helper from '../../utils/helper';
import bugsnag from '../../utils/bugsnag';

interface CommentAndReplyModalProps {
    modalVisible: boolean,
    closeModal: () => void,
    style?: ViewStyle
}

export default class CommentAndReplyModal extends Component<CommentAndReplyModalProps, {}> {
  constructor (props: CommentAndReplyModalProps) {
    super(props);
    this.state = {
      sending: false
    };
  }

  // eslint-disable-next-line flowtype/no-weak-types
  _textInput: any;
  successCallbak: (body: SocialResult<Feed>) => void = (body: SocialResult<Feed>) => {
    const that = this
    const { closeModal, commentType, commentItemData } = this.props;
    that.setState({
      sending: false
    })
    if (body && body.result) {
      const commentEvent = {
        postId: commentItemData?.id,
        customerId: commentItemData?.customerMisc?.customerId,
        commentItem: Object.assign({}, body.result, { targetCustomerMisc: commentItemData?.customerMisc })
      };
      commentType === 'comment' && emitCommentEvent(commentEvent);
      closeModal()
      Toast.show({
        text: I18n.t('community_commentSuccess'),
        position: 'center'
      });
    } else {
      that.refs.toast.show(I18n.t('community_commentFail'));
    }
  };

  failCalback: (error: Error) =>void = (error: Error) => {
    const that = this
    console.log(error)
    that.setState({
      sending: false
    })

    try {
      // $FlowIgnore
      if (error.error.result.statusCode === 400) {
        that.props.closeModal()

        Toast.show({
          text: I18n.t('loginToRemindTip4'),
          position: 'center'
        });

        helper.navigate(that.props.navigation.navigate, 'Login');
      } else {
        that.refs.toast.show(I18n.t('community_commentFail'));
      }
    } catch (e) {
      bugsnag.notify(new Error('[CommentAndReplyModal]--[failCalback]--error:' + e.toString()));
      that.refs.toast.show(I18n.t('community_commentFail'));
    }
  };

  doSend: () => void = () => {
    const that = this
    const { commentText, commentItemData, commentType } = this.props;
    if (that.state.sending) {
      return;
    }

    that.setState({
      sending: true
    })
    if (commentType === 'reply') {
      getClient()
        .reactions.addChild('REPLY', commentItemData?.id, { text: commentText })
        .then(this.successCallbak)
        .catch(this.failCalback);
    } else {
      getClient()
        .reactions.add('COMMENT', commentItemData?.id, { text: commentText })
        .then(this.successCallbak)
        .catch(this.failCalback);
    }
  };

  render (): React$Node {
    const that = this
    const { sending } = this.state
    const { modalVisible, closeModal, commentText = '', commentType, wordLimit, onChangeCommentText, commentItemData } = this.props;
    return (
      <Modal
        backdropOpacity={0.6}
        transparent={true}
        avoidKeyboard
        coverScreen={true}
        onModalShow={() => {
          that._textInput.focus();
          StatusBar.setBarStyle('dark-content');
          that.setState({
            sending: false
          })
        }}
        onModalWillHide={() => {
          StatusBar.setBarStyle('light-content');
        }}
        onBackdropPress={() => {
          closeModal();
        }}
        deviceHeight={Math.max(
          Dimensions.get('window').height,
          Dimensions.get('screen').height
        )}
        style={[{ justifyContent: 'flex-end', margin: 0 }, this.props.style]}
        isVisible={modalVisible}>
        <View
          style={{
            backgroundColor: 'white',
            borderTopLeftRadius: p(24),
            borderTopRightRadius: p(24),
            minHeight: p(310)
          }}>
          <View style={{ height: p(63), paddingHorizontal: p(30), marginTop: p(20), marginBottom: p(12), flexDirection: 'row', justifyContent: 'space-between' }}>
            <TouchableOpacity activeOpacity={0.8}
              style={{ flex: 1 }}
              onPress={() => {
                closeModal();
              }}>
              <Text
                style={{
                  color: '#1F2126',
                  fontSize: p(28)
                }}>
                {I18n.t('cancel')}
              </Text>
            </TouchableOpacity>
            <View style={{ alignItems: 'center', flex: 1 }}>
              <Text style={{ fontSize: p(28), color: '#696F7F' }}>{commentType === 'reply' ? I18n.t('community_reply') : I18n.t('community_comment')}</Text>
              <Text style={{ color: '#8E939F', fontSize: p(18) }}>{commentText?.length ?? 0}/{wordLimit}</Text>
            </View>
            <View style={{ flex: 1, alignItems: 'flex-end' }}>
              <TouchableOpacity
                activeOpacity={0.8}
                disabled={sending || !commentText.length}
                style={{ opacity: sending || !commentText.length ? 0.4 : 1, backgroundColor: '#6F40EE', width: p(100), height: p(48), borderRadius: p(25), alignItems: 'center', justifyContent: 'center' }}
                onPress={this.doSend}
              >
                <Text style={{ fontSize: p(28), color: '#fff' }}>{I18n.t('send')}</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ backgroundColor: '#F2F1F5', height: 0.5 }}/>
          <Text style={{ color: '#8E939F', fontSize: p(24), paddingHorizontal: p(30), paddingTop: p(20) }}>{commentType === 'reply' ? I18n.t('community_reply') : I18n.t('community_comment')} {commentItemData?.customerMisc.nickName}：</Text>
          <TextInput
            style={{
              color: '#434B5F',
              fontSize: p(28),
              lineHeight: p(44),
              paddingHorizontal: p(30),
              marginBottom: p(24)
            }}
            ref={(ref: typeof TextInput) => {
              that._textInput = ref;
            }}
            onChangeText={(text: string): void => onChangeCommentText(text)}
            multiline={true}
            value={commentText}
            maxLength={wordLimit}
            placeholderTextColor={'#BCC1CE'}
          />

        </View>
        {
          sending &&
          <ModalIndicator.IndicatorView text={I18n.t('community_commentSending')}/>
        }

        <RNToast
          ref="toast"
          style={{ backgroundColor: 'black' }}
          position="center"
          fadeInDuration={750}
          fadeOutDuration={1000}
          opacity={0.8}
          textStyle={{ color: 'white' }}
        />

      </Modal>
    );
  }
}
