// @flow
import config from '../utils/config';
import request from '../utils/request';
import type { SocialResult } from './base';
// // model start
// get Data list start
export interface ListResult<T>{
  items: T[]
}

export interface ItemData {
  ts: number,
  value: number
}

export interface VsMultiLanguage{
  en: string,
  hk: string,
  zh: string
};
export interface VsRateMultiLanguage{
  en: {
    rate: string
  },
  hk: {
    rate: string
  },
  zh: {
    rate: string
  }
};
export interface VsDesc{
  expense: string,
  coin0: string,
  value0: string,
  rate0: string,
  coin1: string,
  value1: string,
  rate1: string
};

export interface VsDescMultiLanguage{
  en: VsDesc,
  hk: VsDesc,
  zh: VsDesc
}

// liquidation
export interface LiquidationItemData {
  exchange: string,
  imageUrl: string,
  totalBlast: number,
  totalBlastRate: number
}

export interface LiquidationItem30Data {
  buyAmount: number,
  sellAmount: number,
  dateStr: string
}

export interface LiquidationResult {
  dataList: LiquidationItemData[],
  data30List: LiquidationItem30Data[],
  maxCount: number,
  maxExchange: string,
  totalBlastNum24h: number,
  totalBlastUsd1h: number,
  totalBlastUsd24h: number
}
export interface VsItem{
  id: string,
  name: string,
  change: string,
  change_pct: string,
  type: 'vs',
  code: string,
  data?: LiquidationResult | string,
  dataList?: ItemData[],
  title: VsMultiLanguage,
  title_args: VsRateMultiLanguage,
  description: VsMultiLanguage,
  description_args: VsDescMultiLanguage
}
export type LiquidationResponseType = SocialResult<LiquidationResult>;
export interface Item {
  id: string,
  name: string,
  change: string,
  change_pct: string,
  symbol: string,
  exchange: string,
  symbols?: string[],
  data?: LiquidationResult,
  dataList: ItemData[],
  code: string,
  interval: '5m' | '1h' | '1d',
  title: string,
  type: 'long_short' | 'liquidation' | 'funding_fee' | 'analysis'
}

export interface LongShortItem extends Item {
  dataList: ItemData[],
  interval: '5m' | '1h' | '1d'
}
export type GetDataListResponseType = SocialResult<ListResult<Item | VsItem>>;
export type LongShortListResponseType = SocialResult<ListResult<LongShortItem>>;

// longshort

// funding fee
export interface FundingFee extends Item {
  dataList: ItemData[]
}

export interface FearAndGreedIndex {
  dataList: ItemData[]
}

export type FundingFeeResponseType = SocialResult<ListResult<FundingFee>>;

export type FearAndGreedIndexResponseType = {
  result: {
    id: string,
    datetime: string,
    value: string,
    result: string
  }
};

export interface StockDataItem{
  id: string,
  name: string,
  data: string,
  change: string,
  change_pct: string
}

export type StockDataIndexResponseType = {
  result: StockDataItem[]
};

// definition class start
class ExploreDataApi {
  /**
   * 包含longshort， liquidation， fundingfee，vs 数据格式，需要根据结果里的type区分。
   * @returns {*}
   */
  getDataList (): Promise<GetDataListResponseType> {
    const URL =
      config.api.host +
      config.api.explore.getList

    return request.get.call({}, URL);
  };

  getLongShortList (params: {exchange: string, symbol: string, interval: string}): Promise<LongShortListResponseType> {
    const URL =
      config.api.host +
      config.api.explore.getLongShort + '?symbol=' + params.symbol + '&interval=' + params.interval + '&exchange=' + params.exchange
    console.log(params)
    console.log(URL)
    return request.get.call(params, URL);
  };

  getLiquidation (): Promise<LiquidationResponseType> {
    const URL =
      config.api.host +
      config.api.explore.getLiquidation
    return request.get.call({}, URL);
  };

  getFearAndGreedIndex (): Promise<FearAndGreedIndexResponseType> {
    const URL =
      config.api.host +
      config.api.explore.getFearAndGreedIndex
    return request.get.call({}, URL);
  };

  getStockDataIndex (): Promise<StockDataIndexResponseType> {
    const URL =
      config.api.host +
      config.api.explore.getStockDataIndex
    return request.get.call({}, URL);
  };

  getFundingFee (params: {symbol: string}): Promise<FundingFeeResponseType> {
    const URL =
      config.api.host +
      config.api.explore.getFundingfee + '?symbol=' + params.symbol
    return request.get.call({}, URL);
  };
}

const ExploreDataAPI: ExploreDataApi = new ExploreDataApi();
export { ExploreDataAPI };
