// @flow

const obj: { [key: string]: string} = 
{
   "no_internet":"無網絡，請檢查網絡鏈接",
   "click_retry":"點擊重試",
   "load_failed":"加載失敗",
   "imagePicker_Title":"選擇一張圖片",
   "share_fail":"分享失敗",
   "share_success":"分享成功",
   "line_not_install":"未安裝line",
   "imagePicker_chooseFromLibraryButtonTitle":"本地相冊",
   "imagePicker_takePhotoButtonTitle":"拍照",
   "regMessage01":"已閱讀並接受",
   "regMessage02":"用戶服務協議",
   "regMessage03":"請查收電子郵件並填寫驗證碼",
   "regMessage04":"仍未收到？ ",
   "regMessage05":"重新發送",
   "gaAuthentication":"Google 驗證",
   "gaVerificationCode":"谷歌驗證碼",
   "smsVerification":"短信驗證",
   "smsVerifyTip01":"請先填入短信驗證碼完成驗證。 ",
   "gaVerifyTip01":"請先填入谷歌驗證碼完成驗證。 ",
   "unBindPhoneTip":"解綁手機號碼",
   "unBindGaTip":"解綁谷歌驗證",
   "rebate":"推薦返佣",
   "logoutTip1":"您確定要退出嗎？ ",
   "logoutReminder":"退出提醒",
   "auth":"身份認證",
   "registrationServiceAgreement":"註冊服務協議",
   "phone":"手機驗證",
   "rules":"規則",
   "country":"國家",
   "firstName":"名",
   "lastName":"姓",
   "gender":"性別",
   "login":"登入",
   "goLogin":"去登錄",
   "about":"關於",
   "aboutus":"關於我們",
   "kline":"K線",
   "sure":"確定",
   "activity":"活動",
   "gotit":"確定",
   "back":"返回",
   "supportCenter":"幫助中心",
   "resetPass":"重置密碼",
   "loginToRemind":"登錄提醒",
   "loginToRemindTip1":"抱歉，您的登錄已過期。 ",
   "loginToRemindTip2":"請重新登錄。 ",
   "loginToRemindTip3":"您的帳戶在另一台設備上登錄，如果不是您本人操作，請立即更改您的密碼。 ",
   "loginToRemindTip4":"登錄狀態已失效，請重新登錄",
   "passportId":"護照ID",
   "depositAddress":"充幣地址",
   "depositPaymentID":"充幣 ",
   "accountBalance":"帳戶餘額",
   "firstNamePlaceHolder":"請輸入您的名字",
   "lastNamePlaceHolder":"請輸入您的姓氏",
   "passportIdPlaceHolder":"請輸入您的護照ID",
   "codeisnull":"請輸入驗證碼",
   "googleAuth":"谷歌驗證",
   "enterGoogleCode":"請輸入谷歌驗證",
   "passwordSetting":"密碼設置",
   "referralReward":"邀請返佣",
   "ReferralHistory":"邀請歷史記錄",
   "referFriends":"邀請朋友在Dipbit交易即可得20%交易手續費返佣",
   "referralCode":"我的邀請碼：",
   "referralLink":"我的邀請鏈接：",
   "reward":"邀請返佣歷史記錄",
   "referralOne":"1. 請注意只有用戶使用你的邀請鏈接註冊才會被記為您的邀請。 ",
   "referralTwo":"2. 當您邀請的朋友成功交易時，您的邀請獎勵將在第二天零點根據實時匯率計入DBT並分配到您的帳戶（有可能會有延遲）。 ",
   "referralThree":"3. DipBit保留重新調整邀請規則的最終解釋權。 ",
   "safetyVerification":"安全驗證",
   "sSMS":"短信驗證碼",
   "sEmail":"電郵驗證碼",
   "sGoogle":"谷歌驗證碼",
   "signOut":"登出",
   "male":"男性",
   "birthDay":"生日",
   "female":"女性",
   "account":"帳戶",
   "TradingHall":"交易中",
   "home":"主頁",
   "reApply":"重新申請",
   "trading":"交易",
   "market":"市場",
   "oldPassword":"原始密碼",
   "newPassword":"新密碼",
   "confirmNewPassword":"確認密碼",
   "NewsInformation":"新聞",
   "personCenter":"個人中心",
   "passportCover":"護照封面",
   "copySuccess":"複製成功！ ",
   "confirm":"確認",
   "yes":"是的",
   "region":"地區",
   "submit":"提交",
   "send":"發送",
   "copy":"複製",
   "paste":"貼上",
   "onOrders":"凍結",
   "enable":"啟用",
   "search":"搜索",
   "loginPassword":"登錄密碼",
   "resetBtnText":"重置",
   "tradePassword":"交易密碼",
   "forgotPassword":"忘記密碼",
   "resetPassword":"重置密碼",
   "enter_email":"電郵",
   "total":"總計",
   "toAll":"全部",
   "tips":"小貼士",
   "withdraw":"提幣",
   "BTCDeposit":"BTC充幣",
   "BTCWithdraw":"BTC提幣",
   "allRecord":"全部記錄",
   "amount":"數量",
   "address":"地址",
   "financialRecords":"財務記錄",
   "phoneNumberPlaceholder":"請輸入電話號碼",
   "smsCodePlaceholder":"請輸入短信驗證碼",
   "copyAddress":"複製地址",
   "showQRCode":"展示二維碼",
   "addAddress":"添加地址",
   "history":"歷史記錄",
   "min":"最小",
   "fee":"費率",
   "cancel":"取消",
   "verifyNow":"即刻驗證",
   "setup":"設置",
   "ok":"確定",
   "password":"密碼",
   "type":"類型",
   "buy":"買",
   "pwConfirm":"確認密碼",
   "phoneVerificationCode":"手機驗證碼",
   "enterNewAddress":"請輸入新的地址",
   "enterLabel":"請輸入備註",
   "enter_verification_code":"請輸入驗證碼",
   "deleteAddress":"刪除地址",
   "transAction":"交易",
   "addressManagement":"地址管理",
   "enterOrPasteAddress":"請輸入地址",
   "passportInformationPage":"個人護照信息頁",
   "passportSignature":"遞交我的護照和個人簽名",
   "withdrawTip1":"每日最大提幣額",
   "withdrawTip11":"單次最小提幣額",
   "withdrawTip12":"單次最大提幣額",
   "withdrawTip13":"出於安全考慮，當您使用新的提款地址時，我們將手動審核您的提款。請等待我們的工作人員撥打電話或發送電子郵件。 ",
   "withdrawTip2":"出於安全考慮，當您使用新的提款地址時，我們將手動審核您的提款。請等待我們的工作人員撥打電話或發送電子郵件。 ",
   "withdrawTip3":"暫不能提幣",
   "withdrawTip4":"您必須設置電話號碼才能繼續提幣",
   "withdrawTip5":"提幣申請失敗",
   "withdrawTip6":"請驗證身份以提高提款效率。 ",
   "withdrawTip7":"提幣申請通過",
   "withdrawTip8":"您的提幣申請已收到，系統將會盡快安排操作。 ",
   "withdrawTip9":"您的提幣超出限額。 ",
   "withdrawTip10":"我們正計劃開通VIP頻道，您可以在將來申請VIP以增加額度。 ",
   "withdrawTip14":"交易提醒",
   "withdrawTip15":"您即將花費 ",
   "withdrawTip16":"您的可用餘額在交易上，您確定達成交易嗎？ ",
   "depositTip1":"除此之外，請不要添加任何其他數字貨幣資產",
   "depositTip2":"充幣到上述地址後，您需要等待網絡節點確認，之後 ",
   "depositTip3":" 充幣成功。 ",
   "networkConfirmation":"網絡確認",
   "depositTip4":"請確認您的計算機和瀏覽器的安全性，以防止信息被篡改或洩露。 ",
   "depositTip5":"地址複製成功",
   "depositTip6":"到上述地址或資產將不予退還。 ",
   "depositTip7":"最小充值額",
   "depositTip8":"任何低於最小充幣額的充值將不予退還。 ",
   "depositTip9":"僅發送",
   "depositTip10":"到這個充值地址。 ",
   "passportTip1":"確保圖片完整併且清晰可見，僅支持JPG圖片格式。 ",
   "passportTip2":"確保圖片完整併且清晰可見，護照必須有效，並且僅支持JPG圖片格式。 ",
   "passportTip3":"請上傳帶有身份證，正面視圖和個人簽名的照片。個人簽名包含“平台名稱”和當前日期。請確保照片和個人簽名的內容清晰可見。 ",
   "passportTip4":"請選擇一個圖片",
   "passvail":"密碼格式錯誤",
   "gaVerification":"谷歌身份驗證",
   "twopwd_is_diff":"兩次輸入密碼不一致",
   "oldpwd_no_null":"原始密碼不得為空",
   "newpwd_no_null":"新密碼不得為空",
   "enterNewPassAgain":"請再次輸入新密碼",
   "loginpwd_no_null":"登錄密碼不得為空",
   "pwd_no_null":"密碼不得為空",
   "Save_the_QR_code":"保存二維碼",
   "deposit_address":"充幣地址",
   "deposit_paymentId":"付款地址",
   "depositXRP1":"兩者 ",
   "depositXRP2":"到Dipbit",
   "depositXRP3":"除此之外，請不要添加任何其他數字貨幣資產 ",
   "depositXRP4":"到上述地址，資產或將無法歸還。 ",
   "depositXRP5":"充幣到上述地址後，您需要等待網絡節點確認，在一個節點確認後 ",
   "depositXRP6":"充幣成功。 ",
   "depositXRP7":"和地址被需要去成功充值到您的",
   "depositXRP8":"充幣確認",
   "Got_it":"了解",
   "depositIOT1":"充幣地址不能使用。在轉入或存入區塊鏈後，任何進一步的轉帳或存款到同一地址將不予記入。 ",
   "depositIOT2":"除此之外，請不要添加任何其他數字貨幣地址 ",
   "depositIOT3":"到上述地址，資產或將無法歸還。 ",
   "depositIOT4":"充幣到上述地址後，您需要等待網絡節點確認，在一個節點確認後 ",
   "serial_number":"序列號",
   "transaction_id":"交易ID",
   "register":"註冊",
   "please_write_email":"電子信箱",
   "please_write_pwd":"密碼",
   "close":"關閉",
   "network":"請檢查網絡連接",
   "emailisnull":"電郵不得為空",
   "emailvail":"電郵地址格式錯誤",
   "captcha":"驗證碼",
   "invitationCode":"不需要邀請碼",
   "reg_success":"註冊成功，請登錄",
   "login_success":"登錄成功",
   "next":"下一步",
   "sell":"賣",
   "pwformat":"密碼（6-20位字母數字組合）",
   "orders":"當前委託",
   "bid":"買單價格",
   "ask":"賣單價格",
   "spotsell":"現貨賣",
   "spotbuy":"現貨買",
   "tradlogin":"請登錄後交易",
   "available":"可用",
   "deposit":"充幣",
   "withdrawal":"提幣",
   "verification":"驗證",
   "time":"時間",
   "number":"數量",
   "newandold_no_null":"新密碼不能與原密碼相同",
   "exception":"服務器異常，請稍後訪問",
   "exceptionLogin":"請登錄！ ",
   "agreement":"請先閱讀註冊服務協議！ ",
   "spotAssets":"資產",
   "money":"金額",
   "company":"公司",
   "hide":"隱藏",
   "pulldown":"下拉刷新至最新數據：",
   "refreshing":"刷新至最新數據：",
   "realesePull":"發布最新版本更新：",
   "details":"詳情",
   "officialNews":"官方新聞",
   "updateNow":"更新",
   "later":"後來",
   "done":"完成",
   "deleteAccount":"確認刪除帳戶",
   "deleteConfirm":"刪除",
   "continue":"繼續",
   "dismiss":"取消",
   "emailAlready":"電郵地址已佔用",
   "depositIOT5":"充幣成功。 ",
   "AccountLockout":"帳戶鎖定",
   "attentions":"注意",
   "AccountLockout2":"您的帳戶被鎖定",
   "referredFriends":"已邀好友",
   "referredReward":"已返佣金",
   "referredRatio":"返佣比例",
   "myReferralCode":"我的邀請碼",
   "copyCode":"複製",
   "copyLink":"複製鏈接",
   "referredTip5":"邀請碼複製成功",
   "referredTip6":"邀請鏈接複製成功",
   "securityPolicy":"安全政策",
   "balanceHome":"資產",
   "tradeCoins":"交易",
   "noTradeMarket":"暫未開放交易",
   "financialDesc":"專業團隊打造的數字資產理財產品。 ",
   "financialDesc1":"超高年化,年化收益率高達60%",
   "financialDesc2":"活動期間衝幣免提幣手續費",
   "financialDesc3":"平台保障用戶利益，所有理財產品用戶享有保本權益",
   "rulesTitle":"交易規則",
   "rulesDesc1":"• 認購費率：認購費率為0",
   "rulesDesc2":"• 收益規則：首月起始8.00%，逐月2.00%遞增，最高20.00%，按月付息，一年後到期還本。 ",
   "rulesDesc3":"• 起息日：T+1日計息，即投入後第二天開始計息。 ",
   "rulesDesc4":"• 贖回日：投入滿1個月後，每月轉出日可申請轉出。 ",
   "rulesDesc5":"• 贖回費率：贖回費率為0",
   "rulesDesc6":"• 到帳時間：實時到帳。 ",
   "purchaseTypeTitle":"您計劃購買多少？ ",
   "noApplyTitle":"無申請資格",
   "noApplyDesc1":"申請要求：您的帳戶餘額大於 ",
   "noApplyDesc2":"您的帳戶餘額： ",
   "noApplyDesc3":"申請是免費的，帳戶餘額僅用來驗證。 ",
   "depositBtn":"充值",
   "applySuccess":"成功申請",
   "applySuccessDesc":"如果購買數量不足，該項目將被取消。 ",
   "BuyUSDT":"購買USDT",
   "limitCounts":"交易限額：",
   "limitCounts2":"--50,000",
   "selectPaymentMethod":"選擇付款方式",
   "pleasePay":"請付款",
   "pleasePayOnTime":"請按時付款",
   "confirmToCancel":"確認取消",
   "haventPaid":"我確認未付款。 ",
   "payDesc1":"• 您的轉帳金額將記入賣家的帳戶。在交易過程中，待售資產將保留在我們的平台上。 ",
   "payDesc2":"• 除了Reference ID之外，不要為其他轉帳添加任何其他詞語。 ",
   "payDesc3":"• 請在規定的時間內完成付款，然後點擊“我已付款”。賣家確認收據後，資產將自動轉入您的帳戶。 ",
   "payDesc4":"• 請使用平台實名的銀行卡、支付寶等進行轉款，否則賣家可拒絕成交並投訴。 ",
   "complaint":"申訴",
   "complaintDesc":"在申訴提交成功後您的資產將被凍結，並且客服將介入交易直到最後。惡意投訴人將被凍結帳戶。投訴可在買家完成付款後的5分鐘內完成。 ",
   "confirmPayment":"確認付款",
   "payDesc":"請您確認是否已經支付該訂單？ ",
   "waitForRelease":"等待放幣......",
   "waitDesc":"等待賣家放幣。 ",
   "ifNotPay":"如果您未付款",
   "ifNotReceived":"如果您未收到幣",
   "notice":"注意",
   "paymentNoticeDesc":"商戶支付方式變動，請您刷新重試。 ",
   "refresh":"刷新",
   "cancelNoticeDesc":"您在同一天取消訂單數過多，並且將無法在第二天的0點之前發布購買廣告！ ",
   "completed":"完成",
   "completedPay":"您已經完成該筆付款。 ",
   "cancelled":"取消",
   "cancelledPay":"您已經取消該筆付款。 ",
   "receiptQRCode":"收款碼",
   "complainting":"申訴中......",
   "complaintingDesc":"請等待客服介入。 ",
   "copied":"複製成功",
   "orderTimeout":"付款超時，該訂單取消。 ",
   "maximumCNY":"最大可購買50,000",
   "minimumCNY":"最少可購買",
   "duplicateOrder":"重複訂單",
   "duplicateDetail":"您已經有一筆交易正在進行，請在該筆交易完成後再次購買！ ",
   "checkOrder":"檢查訂單",
   "language":"語言",
   "english":"簡體中文",
   "skipBtnText":"跳過",
   "safetyTitle":"安全",
   "safetyDesc1":"構建高級系統架構以防禦DDoS和其他潛在威脅，",
   "safetyDesc2":"",
   "safetyDesc3":"逾98%的數字資產存儲在多重簽名的冷錢包中。 ",
   "safetyDesc4":"",
   "speedTitle":"效率",
   "speedDesc1":"毫秒級響應，不斷優化打磨的平台，",
   "speedDesc2":"具有可靠的效率和穩定性。 ",
   "speedDesc3":"",
   "serviceTitle":"服務",
   "serviceDesc1":"7 * 24小時在線，如有疑問，",
   "serviceDesc2":"請通過電子郵件和電報與我們實時溝通。 ",
   "serviceDesc3":"",
   "teamTitle":"團隊",
   "teamDesc1":"Dipbit核心團隊來自阿里，騰訊，百度等互聯網企業，全球專業的金融量化團隊，",
   "teamDesc2":"致力於打造最安全，最優的交易平台。 ",
   "teamDesc3":"",
   "getStarted":"開始",
   "withFiatMoney":"法幣",
   "dipbitreasure":"幣多盈",
   "optimizeAssets":"優化資產配置",
   "otcDesc1":"OTC交易尚未開放，敬請期待。 ",
   "otcDesc2":"",
   "deatils":"詳情",
   "emptyFavorite":"您沒有自選",
   "name":"幣種",
   "vol":"成交量",
   "oneVol":"量",
   "lastPrice":"最新價格",
   "chg":"變動百分比",
   "alreadyLatestVersion":"已更新至最新版本",
   "version":"版本",
   "telegram":"電報",
   "productDescription":"產品簡介",
   "publicKeyNumber":"公鑰",
   "addTime":"添加時間",
   "label":"備註",
   "areYouSure":"確定?",
   "noAddress":"您尚未創建地址",
   "accessCamera":"允許我們獲取您的照相機權限?",
   "setProfile":"我們需要權限來設置您的資料照片",
   "noWay":"失敗",
   "openSettings":"打開設置",
   "QRScan":"將二維碼/條形碼與要掃描的框對齊",
   "scanQRCode":"掃描二維碼",
   "googleAuthStep1":"1.安裝“Google身份驗證器”並運行它，點擊右上角的“+”，選擇“手動輸入”。 ",
   "googleAuthStep2":"2.粘貼“密鑰”，點擊“完成”",
   "googleAuthStep3":"3.複製“Google授權碼”並將其粘貼在“Google Code”輸入中。請勿刪除Google授權帳戶。如果您誤刪了它，可以重置。 ",
   "linked":"已驗證",
   "unbindingGoogleAuthentication":"解綁谷歌驗證 ？ ",
   "passwordResetComplete":"密碼重置成功",
   "rejected":"已拒絕",
   "verified":"已認證",
   "unverified":"未認證",
   "turnedOn":"打開",
   "unopened":"未開啟",
   "notLinked":"未驗證",
   "pleaseInputTelephone":"請輸入您的電話號碼",
   "SMSSendSuccessfully":"短信發送成功",
   "failedVerificationCode":"無法獲取驗證碼,請檢查網絡連接",
   "pleaseInputSmsCode":"請輸入短信驗證碼",
   "unbindingPhoneNumber":"解綁手機號碼?",
   "authenticationVerified":"您的授權正在驗證中…",
   "identityNotApproved":"您的身份未支持.",
   "failedReason":"失敗原因:",
   "signedUpTime":"註冊時間",
   "loading":"加載中",
   "referFriendsTrade":"推薦朋友在Dipbit進行交易，獲得 ",
   "transactionFeesReward":"交易費作為推薦獎勵。 ",
   "coin":"幣種",
   "allocatedTime":"分配時間",
   "resetPasswordLogin":"重置密碼成功,即刻登錄!",
   "sentVerificationCode":"我們已發送驗證碼",
   "fillVerificationCode":"請填入此驗證碼.",
   "checkInternetTryAgain":"網絡不可用,請檢查連接並重試.",
   "fiat":"法幣",
   "totalBalance":"總資產",
   "depositFirst":"無資產,請先存入",
   "coinWithoutAssets":"資產為零幣種",
   "noResult":"沒有結果",
   "haveNoFinancialRecords":"你尚未有理財記錄",
   "withdrawSuspended":"提幣已被延遲",
   "depositSuspended":"存幣已被延遲",
   "bindMobileBeforeWithdraw":"請在提筆錢綁定手機號碼",
   "haveNoOrder":"暫無委託",
   "haveNoDeposit":"您沒有存幣記錄",
   "inputRightCNY":"請輸入正確的人民幣金額",
   "inputRightUSDT":"請輸入正確的USDT數量",
   "gets":"得到",
   "needHelp":"需要幫助?",
   "submittedSuccessfully":"提交成功",
   "complaintReason":"申訴原因",
   "enterComplaintReason":"請輸入申訴詳情",
   "paymentMethod":"支付渠道:",
   "wechat":"微信支付",
   "alipay":"支付寶",
   "cancelOrder":"取消委託",
   "payee":"收款方:",
   "referenceID":"參考號",
   "seller":"賣方",
   "orderID":"訂單號",
   "haveNoOrders":"暫無委託",
   "allStatus":"所有狀態",
   "processing":"處理中",
   "complete":"已完成",
   "Complaining":"申訴中",
   "Paid":"已付款",
   "Unpaid":"待付款",
   "Cancelled":"已取消",
   "lessThan":"少於",
   "moreThan":"多於",
   "annualYield":"預期最高年化",
   "investTable":"歷史淨值走勢",
   "fixedAnnualYield":"穩定年化",
   "notFixedType":" 進取型 ",
   "fixedType":" 穩健型 ",
   "lockupPeriod":"鎖定期限",
   "days":"天",
   "progress":"起投金額",
   "purchaseQuantity":"購買數量:",
   "openPurchaseTime":"開放購買時間:",
   "applyForFree":"立即搶購",
   "applyLockBtn":"鎖倉中",
   "applyOverBtn":"項目已結束",
   "reservation":"募資期",
   "purchasePeriod":"鎖倉期",
   "incomePeriod":"結算日",
   "haveNoProject":"您尚未有理財計劃。 ",
   "fixedRate":"固定收益",
   "monthlyYieldGrowth":"月月升",
   "weeklyYieldGrowth":"週周升",
   "favorites":"自選",
   "discover":"社區",
   "totalValue":"總資產",
   "loginToView":"登錄查看",
   "topVolume":"成交量",
   "topGainers":"漲幅榜",
   "topLosers":"跌幅榜",
   "setPassword":"設置密碼",
   "pwdRules":"6-20 位, 字母 + 數字",
   "resendSuccess":"重新發送成功",
   "emailVerification":"電郵驗證",
   "verificationCode":"驗證碼已被發送至",
   "resend":"重新發送",
   "notReceivedEmail":"如果仍未收到郵件,請查看垃圾郵件.",
   "pwdError":"密碼錯誤",
   "inputLeft":" 剩餘輸入機會",
   "accountLocked":"帳戶已鎖定無法登錄, 請於",
   "minutesToUnlock":"分鐘後重試",
   "emailRegistered":"電郵已被註冊,如果您已有帳戶,請登錄",
   "mobileRegistered":"手機已被註冊,如果您已有帳戶,請登錄",
   "registrationMeans":"註冊即代表接受",
   "userService":"用戶協議",
   "pleaseClickBack":"請點擊返回鍵退出",
   "balance":"資產",
   "noNotice":"暫無通知",
   "limit":"限價",
   "BUY":"買入",
   "SELL":"賣出",
   "status":"狀態",
   "noRewardHistory":"您沒有返佣歷史",
   "clickReload":"點擊重新加載",
   "cancelSuccessfully":"取消成功",
   "cancelFailed":"取消失敗",
   "successfullyAdd":"添加成功",
   "successfullyDelete":"刪除成功",
   "executed":"執行",
   "today":"今日",
   "noOrder":"暫無委託",
   "openOrders":"待成交",
   "ordersHistory":"委託歷史",
   "marketTrades":"成交明細",
   "depth":"深度",
   "joinMyFavorite":"加入自選",
   "removeFavorite":"從自選中移除",
   "depthChart":"深度",
   "high":"最高",
   "low":"最低",
   "inputRightPrice":"請輸入正確價格",
   "inputRightAmount":"請輸入正確數量",
   "inputRightAccount":"請輸入正確帳戶",
   "noEnoughAssets":"餘額不足",
   "notEnoughCoin":"餘幣不足",
   "all":"全部",
   "marketTrading":"市價交易:最優市場價格",
   "avaliable":"可用",
   "loginTrading":"登錄",
   "avgPrice":"均價",
   "enterWalletAddress":"請輸入錢包地址",
   "atention":"注意",
   "addAddressSuccessfully":"成功添加地址",
   "enterValidAddress":"請輸入有效提幣地址",
   "withdrawalAmount":"提幣量小於帳戶餘額",
   "withdrawalRequest1":"您的提幣請求超出最小提幣限額,無法提幣",
   "withdrawalRequest2":"您的提幣請求超出最大提幣限額,無法提幣",
   "withdrawalRequest3":"您的提幣請求超出單日最大提幣限額,無法提幣",
   "withdraw_24h_limit":"24小時提幣限額",
   "minimum_withdrawal":"最小提幣額",
   "chooseTheAddress":"選擇地址",
   "useNewAddress":"使用新地址",
   "noRecordWithdraw":"您暫無提幣記錄",
   "create":"創建",
   "totalTrades":"全部交易",
   "completionRate":"完成率",
   "averageProcessingTime":"平均處理時間",
   "pay":"付款",
   "verifyGoogle":"谷歌驗證",
   "verifySMS":"短信驗證",
   "googleVerification":"谷歌驗證",
   "pleaseInputCode":"請輸入驗證碼",
   "failedGetVerificationCode":"驗證碼獲取失敗,請檢查網絡連接",
   "inputGoogleCode":"請輸入 Google 驗證碼",
   "sending":"發送中",
   "sendSuccessfully":"短信發送成功",
   "resendAfter":"後重新發送 ",
   "securityDesc":"為了您的資產安全,請遵循我們的安全政策",
   "identityAuthentication":"身份認證",
   "verifiedFailed":"驗證失敗",
   "phoneAuthentication":"手機驗證",
   "google":"谷歌",
   "assetsSecurity":"• 為了您的資產安全,提幣需要手機驗證碼和谷歌驗證碼.",
   "withdrawalMore":"• 提幣大於",
   "requiresIdentityAuthentication":"需要身份驗證.",
   "assetsSecurityFirst":"資產安全第一",
   "uploadSuccessful":"上傳成功",
   "set":"設置",
   "verifying":"驗證中",
   "reset":"重置",
   "identity":"身份",
   "investMarket":"理財市場",
   "investAccount":"理財帳戶",
   "myProjects":"我的持有",
   "update":"更新",
   "expired":"失效",
   "androidVerificationModuleError":"安卓驗證模式錯誤: ",
   "iosVerificationModuleError":"IOS驗證模式錯誤: ",
   "sResend":"s 重新發送",
   "inputSmsCode":"請填寫短信驗證碼",
   "inputEmailCode":"請填寫電郵驗證碼",
   "checkTheEmail":"請檢查電郵,輸入電郵驗證碼,完成驗證.",
   "notReceiveYet":"仍未收到?",
   "sendAgain":"重新發送",
   "congratulationsSuccessfulDeposit":"您已成功存入",
   "seeFullDetails":"，請查看詳細信息.",
   "congratulationsSuccessfulWithdraw":"您已成功取出",
   "successfulTransaction":"訂單上交易成功",
   "order":"訂單",
   "tradedLongTime":"您的訂單長期未交易。 ",
   "youHaveNotification":"您有一個未讀消息",
   "rewardHistory":"返佣歷史",
   "referralHistory":"邀請歷史",
   "registerForDipbit":"註冊Dipbit",
   "achieveYourDream":"成就您的夢想！ ",
   "isComing":"即將上線...",
   "depositProfit":"閒錢理財，存幣得息，毋需交易!",
   "invitationCodeIfApply":"邀請碼(如有)",
   "sureIt":"確定",
   "success":"成功",
   "failed":"失敗",
   "passwordResetSuccess":"重置密碼成功",
   "enterRegisteredEmail":"請輸入您已註冊的電郵",
   "investfixedRate":"募資期固定年化",
   "investing":"募資中",
   "upTime":"到期",
   "beforeupTime":"",
   "myProjectsMoney":"持倉金額",
   "yestordayIncome":"昨日收益",
   "totalIncome":"累積收益",
   "invesAccounttLast":"理財帳戶餘額",
   "investRecord":"財務記錄",
   "transactionIn":"轉入",
   "transactionOut":"轉出",
   "buyTime":"購買時間",
   "getTime":"贖回時間",
   "buyAllAmount":"買入總量",
   "getAllAmount":"贖回總量",
   "income":"收益",
   "buyIn":"購買",
   "everyAmount":"每份數量",
   "maxBuyAmount":"最大投入份數",
   "transmentIn":"劃轉",
   "buyInAmount":"購買份數",
   "agree":"同意",
   "agreementText":"幣多盈理財協議",
   "agreementTextTitle":"理財協議",
   "sureBuyIn":"確認轉入",
   "sureBuyOut":"確認轉出",
   "sureBuy":"確認購買",
   "AmountUnit":"份",
   "daysIncome":"天預期收益",
   "inputMoney":"投入金額",
   "buySuccess":"購買成功",
   "startIncomeTime":"開始收益時間",
   "startComputeTime":"結算日起",
   "lookUpMyInvest":"查看我的理財",
   "getYourGift":"領取你的專屬客服",
   "investNote":"為了實時解答您的問題，更好的為您提供服務，煩請添加我們的客服微信號或加入專屬Telegram群組：",
   "wechatImage":"客服微信二維碼",
   "telegramImage":"Telegram群二維碼",
   "transferFrom":"從",
   "transferTo":"到",
   "transferAccount":"交易帳戶",
   "transferAmount":"劃轉數量",
   "tradeType0":"轉入",
   "tradeType1":"轉出",
   "tradeType2":"購買",
   "tradeType3":"贖回",
   "freeFee":"免費",
   "freeWithdraw":"剩餘免收手續費提幣次數",
   "freeTime":"次",
   "enterRightNum":"請輸入正確的數值",
   "balanceRunsOut":"理財帳戶餘額不足",
   "overTopLimit":"已經超過最大投入值",
   "bitRunsOut":"交易帳戶餘額不足",
   "lockUp":"鎖倉中",
   "ended":"已結束",
   "cardFace":"身份證正面照片",
   "cardFaceDesc":"請確保照片的內容完整併清晰可見。 ",
   "cardOpposite":"身份證背面照片",
   "cardOppositeDesc":"請確保照片的內容完整併清晰可見,身份證必須在有效期內。 ",
   "idDipbitCard":"手持身份證和簽名信息拍照",
   "idDipbitCardDesc":"請上傳手持身份證人像面和個人簽字照片,簽字內容包含“dipbit”和當前日期。 ",
   "cardDesc1":"• 僅支持jpg、PNG圖片格式,請將照片大小保證在2M以內，",
   "cardDesc2":"• 切勿提交與自己身份不相吻合的照片或其他圖片,否則平台有權對您進行暫停使用、封號等一切嚴肅的處理手段。 ",
   "confirmSubmit":"確認提交",
   "checking":"認證審核中",
   "checkingDesc":"您提交的資料將在3-5個工作日審核完畢，請耐心等待。 ",
   "checkSuccess":"認證成功",
   "checkFailed":"認證失敗",
   "checkFailedDesc":"您提供的身份信息不清晰,請重新提交",
   "submittedFailed":"提交失敗",
   "submittedSuccessfullyDesc":"您提交的資料將在3-5個工作日審核完畢，請耐心等待。 ",
   "submittedFailedDesc":"您尚未完成全部信息的提交",
   "recertification":"重新認證",
   "uploadAgain":"點擊圖片重新上傳",
   "transferSuccess":"劃轉成功",
   "inviteFriends":"邀請好友",
   "exclusivePosters":"生成專屬海報",
   "invitedNumber":"已邀請人數",
   "accumulatedRewards":"獲得獎勵(DBT)",
   "twitter":"Twitter",
   "facebook":"Facebook",
   "wechatWay":"微信",
   "weibo":"微博",
   "qqWay":"QQ",
   "sharePosters":"分享海報",
   "shared":"分享",
   "activityRules":"活動細則",
   "rewardRecord":"獎勵記錄",
   "referralRecord":"邀請記錄",
   "referralAccount":"被邀請人帳戶",
   "rewardType":"獎勵類型",
   "rewardCount":"獎勵數量",
   "shareTitle":"親愛的朋友，已送您100個DBT, 請盡快領取!",
   "getCoin":"邀請送幣",
   "noRecords":"暫無記錄",
   "currentExchangeRate":"實時匯率：",
   "priceRealTime":"價格（實時）",
   "faitTotal":"總量",
   "chooseMerchant":"選擇商戶",
   "cancelOrderPay":"取消訂單",
   "iHavePay":"已支付",
   "wallet":"零錢寶",
   "iKonw":"知道了",
   "yestordayAllIncome":"昨日零錢收益",
   "AllIncome":"累積零錢收益",
   "pennyIncome":"零錢收益",
   "loginDipbit":"請登錄",
   "contactUs":"聯繫客服",
   "fiatTrading":"法幣交易",
   "startChat":"開始實時聊天",
   "enterChatEmail":"請輸入您的電郵",
   "exceeding_quota":"超過限額",
   "remaining_amount_to_followed":"該KOL剩餘可跟隨額度",
   "total_amount_to_follow":"總計可跟隨額度",
   "total_amount_followed":"當前已跟隨總額",
   "customer.login.incorrect":"用戶信息已過期",
   "system.parameter.invalid":"請求參數無效",
   "system.success":"成功",
   "customer.login.new":"退出",
   "customer.password.old.required":"原始密碼不能為空",
   "customer.verify.code.expire":"驗證碼錯誤",
   "customer.verify.type.invalid":"驗證類型無效",
   "trade.forbidden.invalid.count":"委託數量無效",
   "trade.value.greater.than.order.max":"單筆交易上限 %{amount} %{currency}",
   "customer.verify.inactive":"登錄所用帳號無效",
   "trade.forbidden.by.balance.volume":"定價幣不夠",
   "customer.phone.required":"手機號碼不能為空",
   "customer.kyc.id.used":"該身份證號已被使用過，請更換",
   "customer.kyc.verify.fail":"KYC認證失敗",
   "customer.kyc.live.result.check.fail":"活體檢測、人臉識別失敗",
   "customer.kyc.not.submit":"用戶未曾提交KYC",
   "customer.kyc.info.insufficient":"KYC資料不足",
   "customer.kyc.id.expire":"證件已過期或無效",
   "customer.kyc.reject.unsupported.id.type":"不支持該證件類型",
   "customer.google.unbind.fail":"google二次驗證解綁失敗",
   "customer.phone.on.fail":"手機認證打開失敗",
   "customer.locked":"您的帳戶已經被鎖，請聯繫客服",
   "customer.google.on.error":"google二次驗證無法打開",
   "customer.password.same":"新密碼不能與原密碼相同",
   "customer.phone.off.success":"關閉手機驗證成功",
   "trade.entrust.cancel.success":"取消成功",
   "customer.exist.none":"用戶不存在",
   "customer.email.format.incorrect":"電郵地址格式不正確",
   "customer.kyc.duplicate":"用戶已通過驗證",
   "customer.verify.email.inactive":"需要激活電郵",
   "trade.forbidden.by.date":"此時間段暫停服務",
   "customer.google.off.period":"為了您的財產安全，關閉google二次驗證後的24小時之內無法提現",
   "customer.email.required":"電郵地址不能為空",
   "customer.email.duplicate":"電郵地址不能重複",
   "trade.forbidden.customer.status":"帳戶未激活",
   "customer.reg.email.duplicate":"電郵已註冊",
   "customer.google.verify.code.incorrect":"google驗證碼不能為空",
   "customer.phone.code.often":"短信驗證碼發送頻繁，請稍後再試",
   "customer.phone.code.limit":"短信驗證碼發送次數超單日上限",
   "trade.forbidden.by.system":"系統維護，交易終止",
   "trade.forbidden.by.precision.volume":"位數需要小於或等於{0}",
   "coin.transaction.nonexist":"記錄不存在",
   "coin.address.outer.required":"Public key id為空",
   "customer.phone.format.incorrect":"電話號碼格式錯誤",
   "system.cache.refresh.fail":"系統緩存刷新失敗",
   "system.parameter.type.mismatch":"參數類型不匹配",
   "customer.google.on":"google二次驗證打開成功",
   "customer.login.success":"登錄成功",
   "customer.forbidden":"您的帳戶已停用，請聯繫客服",
   "trade.forbidden.invalid.sum":"請輸入委託量",
   "customer.login.required":"用戶未登錄",
   "customer.verify.code.incorrect":"電郵驗證碼錯誤",
   "customer.email.code.incorrect":"電郵驗證碼錯誤",
   "customer.verify.code.sent":"驗證碼已發送",
   "customer.verify.phone.unbind":"需要綁定手機",
   "customer.phone.code.required":"手機驗證碼不能為空",
   "coin.address.save.success":"保存成功",
   "customer.phone.send.fail":"驗證碼發送失敗",
   "customer.phone.duplicate":"手機號碼已綁定",
   "customer.verify.twice":"需要驗證兩次",
   "coin.address.invalid":"無效的地址",
   "customer.phone.off.fail":"驗證手機崩潰失敗",
   "trade.forbidden.by.balance.price":"帳戶餘額不足",
   "customer.query.success":"電郵地址已註冊",
   "system.parameter.required":"參數[{0}]為空",
   "customer.password.reset.success":"密碼重置成功",
   "customer.login.password.error":"電郵或密碼錯誤，剩餘{0}次機會嘗試",
   "system.error":"無法連接網絡，請稍後重試",
   "customer.phone.new.duplicate":"新電話號碼不能與舊電話號碼重複",
   "customer.google.off":"關閉google驗證成功",
   "trade.forbidden.invalid.price":"無效的委託價格",
   "customer.phone.code.incorrect":"手機驗證碼錯誤",
   "incorrect.invitation.code":"手機邀請碼錯誤",
   "customer.registration.success":"註冊成功",
   "customer.google.auth.fail":"google驗證碼錯誤",
   "customer.kyc.expire":"驗證過期，請稍後再試",
   "customer.password.reset.fail":"密碼重置失敗",
   "customer.google.success":"google驗證成功",
   "customer.password.format.incorrect":"密碼格式錯誤",
   "customer.phone.error":"手機認證失敗",
   "trade.forbidden.by.num.max":"訂單數量超過最大限制{0}",
   "trade.forbidden.by.total.min":"訂單數量小於最小限制{0}",
   "activity.not.start.code":"請等待活動開始",
   "customer.password.different":"兩次輸入的密碼必須相同",
   "system.parameter.format.incorrect":"參數[{0}]不正確",
   "system.data.not.found":"無法找到系統數據",
   "system.parameter.digital.required":"參數[{0}]必須為數字",
   "customer.phone.on.success":"請使用您的手機通過驗證",
   "coin.interface.error":"幣的內部系統錯誤",
   "trade.forbidden.customer.uncertified":"請進行實名認證",
   "trade.entrust.exist.none":"沒有找到該委託",
   "trade.entrust.cancel.fail":"取消失敗",
   "system.operation.frequent":"操作過於頻繁，請稍後再試",
   "trade.forbidden.by.precision.price":"價格位數應小於或等於{0}位",
   "customer.virtual.account.nonexist":"虛擬帳戶不存在",
   "customer.google.off.error":"關閉google驗證失敗",
   "trade.forbidden.by.symbol":"該交易對暫時未開啟",
   "coin.address.outer.mismatch":"Public key不屬於這個用戶",
   "trade.forbidden.customer":"您已被禁止交易",
   "system.security.param.required":"安全檢查參數為空",
   "trade.forbidden.by.num.min":"最小下單量不能小於{0}",
   "customer.reset.password.error":"密碼錯誤",
   "coin.address.outer.duplicate":"錢包的public key不能重複",
   "coin.code.nonexist":"幣代碼不存在",
   "customer.google.bind.fail":"google二次驗證綁定失敗",
   "customer.phone.success":"手機認證成功",
   "customer.password.required":"密碼不能為空",
   "trade.forbidden.by.updown":"不能超過現價的{0}",
   "trade.entrust.success":"委託成功",
   "customer.reg.fail":"註冊失敗",
   "system.security.check.fail":"安全檢查失敗",
   "customer.password.new.required":"新密碼不能為空",
   "customer.google.unbind.success":"google驗證解綁成功",
   "customer.google.bind.success":"google驗證綁定成功",
   "customer.verify.code.sent.often":"郵件驗證碼發送過於頻繁，請稍後再試",
   "coin.withdraw.success":"提幣申請成功",
   "coin.withdraw.amount.onetime.min.less":"單次提幣金額小於最小提幣數",
   "coin.withdraw.amount.onetime.min.less.unnamed":"請進行身份認證以提高提幣額度",
   "coin.withdraw.amount.onetime.max.exceed.unnamed":"請進行身份認證以提高提幣額度",
   "coin.withdraw.amount.day.max.exceed.unnamed":"請進行身份認證以提高提幣額度",
   "coin.withdraw.closed":"暫不支持提幣",
   "coin.withdraw.amount.onetime.max.exceed":"單次提幣金額超過最大值",
   "coin.withdraw.amount.day.max.exceed":"今日可提幣數超過最大限額",
   "coin.withdraw.balance.insufficient":"帳戶餘額不足",
   "client.coin.withdraw.minimum.limit":"您的提筆額低於最小提幣數",
   "client.coin.withdraw.maximum.limit":"您的提幣額超過了最大提幣數",
   "client.coin.withdraw.today.maximum.limit":"您的提筆總額已超過單日限額",
   "client.copy.success":"{0}複製成功",
   "client.copy.failure":"{0}複製失敗",
   "client.user.not.exist":"用戶不存在",
   "client.user.registered":"該用戶已註冊",
   "active.not.start.code":"請耐心等待活動的開啟",
   "coin.code.or.address.empty":"幣種和地址不能為空",
   "coin.channel.unavailable ":"幣種地址不可用",
   "coin.channel.validation.exception":"地址驗證失敗",
   "trade.entrust.add.invalid.symbol":"添加委託的參數符號無效",
   "trade.entrust.add.invalid.type":"添加委託的參數類型無效",
   "trade.entrust.add.invalid.source":"添加委託的參數源無效",
   "trade.entrust.add.invalid.way":"添加委託的委託方式無效",
   "trade.entrust.add.invalid.price":"添加委託的委託價格無效",
   "trade.entrust.add.invalid.count":"添加委託的委託數量無效",
   "trade.entrust.add.invalid.sum":"添加委託的委託總量無效",
   "otc.place.order.success":"下單成功",
   "otc.place.order.fail":"下單失敗",
   "otc.cancel.order.success":"掛單取消成功",
   "otc.cancel.order.fail":"掛單取消失敗",
   "otc.payer.confirm.success":"付款人確認成功",
   "otc.payer.confirm.fail":"付款人確認失敗",
   "otc.payee.confirm.success":"收款人確認成功",
   "otc.payee.confirm.fail":"收款人確認失敗",
   "otc.appeal.order.success":"申訴成功",
   "otc.appeal.order.fail":"申訴失敗",
   "otc.appeal.order.finish.success":"申訴結果更新成功",
   "otc.appeal.order.finish.fail":"申訴結果更新失敗",
   "otc.order.query.success":"查詢成功",
   "otc.order.query.fail":"查詢失敗",
   "otc.no.data.found":"訂單信息不存在",
   "otc.incorrect.operation":"目前訂單狀態不允許操作",
   "otc.has.timed.out":"訂單超時",
   "otc.cancel.more.than.three.times":"取消超過3次，今日無法進行操作",
   "otc.order.invalid.parameter":"參數'{0}'無效",
   "otc.current.customer.cannot.process.this.order":"無法處理此訂單",
   "otc.incomplete.orders.exists":"存在未完成訂單",
   "otc.trans.amount.check.fail":"轉帳金額檢查失敗",
   "coin.withdraw.amount.less.fee":"提幣金額小於手續費",
   "customer.kyc.reject.duplicate.id":"證件已被使用",
   "customer.kyc.reject.image.not.readable":"系統無法識別你上傳的護照，請細閱提示後重新拍照上傳。如有任何問題，請到【我的】-【其他設定】-【聯繫客服】聯繫我們。 ",
   "customer.kyc.reject.unsupported.id.country":"證件信息與提交的國家不符合",
   "customer.kyc.reject.handheld.fraud":"手持身份證的照片信息不符合平台要求",
   "go_alipay":"去支付",
   "common_network_error":"無法連接網絡，請稍後重試",
   "follow_heroes":"大獅",
   "follow_my_copies":"我的跟單",
   "follow_master_hero":"真大獅",
   "follow_all":"全部",
   "follow_copy":"跟單",
   "follow_popularity":"人氣排行榜",
   "follow_new_people":"新人排行榜",
   "follow_total_earning":"總回報排行榜",
   "follow_total_return":"回報額",
   "follow_total_return_title":"總回報額",
   "follow_total_return_ratio":"總回報率",
   "follow_total_assets":"總資產榜",
   "follow_return":"回報額",
   "follow_all_risk":"全部風險等級",
   "follower_level1":"1-3級",
   "follower_level2":"4-6級",
   "follower_level3":"7-10級",
   "follower_currency":"{name}",
   "follower_all_currency":"全部持倉幣種",
   "follow_week_return":"週回報額",
   "follow_keep_currency":"持倉幣種",
   "follow_return_ratio":"回報率",
   "follow_week_return_ratio":"週回報率",
   "follow_copiers":"跟單人數",
   "follow_week_hero":"本週大獅之星",
   "follow_all_heroes":"所有大獅",
   "follow_network_error":"因網絡原因，導致跟單失敗，請重新嘗試。 ",
   "follow_copying":"正在跟單",
   "follow_amount":"本金",
   "follow_history":"歷史跟單",
   "follow_stop_success":"取消成功",
   "follow_stop":"取消跟單",
   "follow_stop_fail":"因網絡原因，導致取消跟單失敗，請重試。 ",
   "follow_form_all":"全部",
   "follow_form_tip_balance":"本金必須大於%{min}USDT,小於%{max}USDT",
   "follow_form_tip_stop_profit":"止盈比例必須大於等於%{min}%小於等於%{max}%",
   "follow_form_tip_stop_loss":"止損比例必須大於等於%{min}%小於等於%{max}%",
   "follow_amount_min":"最小跟單本金",
   "follow_balance":"餘額",
   "follow_transfer":"轉帳",
   "follow_stop_loss_rate":"止損比例",
   "follow_stop_profit_rate":"止盈比例",
   "follow_stop_loss_at":"預計虧損掉",
   "follow_at_cancel":"時取消跟單",
   "follow_stop_profit_at":"預計盈利",
   "follow_confirm":"確認",
   "follow_save":"保存",
   "follow_saving":"提交中...",
   "follow_stop_tip":"取消跟單後，不再跟單大獅下單。已跟單開倉的訂單，將保持同步平倉。 ",
   "follow_stop_keep":"暫不取消",
   "follow_success":"跟單成功",
   "follow_complete":"完成",
   "follow_detail":"查看詳情",
   "follow_copy_return":"跟單回報",
   "follow_copy_return_rate":"跟單回報率",
   "follow_days":"跟單天數",
   "follow_edit":"編輯",
   "follow_share":"分享",
   "follow_status_cancel":"取消跟單",
   "follow_status_fill":"止盈止損",
   "follow_share_to_friends":"分享給朋友",
   "follow_order_id":"訂單號",
   "follow_status":"目前狀態",
   "follow_order_detail":"歷史跟單詳情",
   "follow_copy_fee":"手續費",
   "follow_profit_share":"分紅",
   "follow_profit_fee":"平台服務費",
   "follow_start_date":"開始跟單",
   "follow_end_date":"結束跟單",
   "follow_kol_copying":"跟單中",
   "follow_kol_history":"歷史跟單",
   "follow_kol_trader":"大獅",
   "follow_kol_all_traders":"全部大獅",
   "follow_kol_all_earning":"累計分紅回報",
   "follow_kol_earning_note":"註：回報結算後自動轉帳至",
   "follow_kol_copy_account":"跟單帳戶",
   "follow_kol_feed_setting":"分紅設定",
   "follow_kol_setting_tip":"用戶跟單結束後獲得的正向回報，按照分紅比例歸大獅所有。",
   "follow_kol_api_setting":"API管理",
   "follow_kol_return":"跟單回報",
   "follow_kol_amount":"本金",
   "follow_kol_now_copy_day":"跟單(天)",
   "follow_kol_history_copy_day":"跟單(天)",
   "follow_kol_detail":"跟單中詳情",
   "follow_kol_history_detail":"歷史跟單詳情",
   "follow_profit_share_percent":"分紅比例(%)",
   "follow_bad_profit_share_percent":"分紅比例必須大於%{min}%,小於%{max}%",
   "follow_save_success":"保存成功",
   "api_integration":"API連接",
   "api_tip":"提示",
   "api_new_tip":"KIKI建議你啟用一個全新的API，和授權我們的日期同步。以便我們及時提示你API的到期，以免影響跟單者的回報和你的影響力。",
   "api_new_understand":"知道了",
   "api_save_error":"API錯誤，請檢查輸入！",
   "api_authorize_success":"API授權成功！",
   "api_saving":"授權中...",
   "api_delete":"取消API",
   "api_delete_success":"API取消成功",
   "api_readonly_tip":"API僅需閱讀權限",
   "api_keep_tip":"請妥善保管好API密鑰等信息，不要向任何人透露該信息",
   "api_access_key_tip":"請輸入 Access Key",
   "api_secret_key_tip":"請輸入 Secret Key",
   "api_passphrase_tip":"請輸入 Passphrase",
   "api_document":"查看API接入",
   "api_document_link":"教程",
   "api_grant":"授權接入",
   "api_renewal":"API更新",
   "api_delete_tip":"取消當前的API，會引發跟單你的用戶強制平倉，確定要取消API嗎？",
   "api_cancel":"取消",
   "api_confirm":"確認取消",
   "safety_setting":"安全相關",
   "safety_google":"Google",
   "safety_change_pwd":"修改密碼",
   "safety_google_deactivate":"解除驗證",
   "safety_google_active":"開啟谷歌驗證",
   "safety_google_activate":"啟動谷歌驗證",
   "safety_google_store":"在應用商店中搜索“Google Authenticator” 應用程式並下載",
   "safety_google_store_go":"前去下載",
   "safety_google_store_after":"安裝完成後，打開Google Authenticator，點擊右上角（安卓為右下角）的“+”，選擇“手動輸入密鑰”，頁面中填入你的帳戶名和下方密鑰。",
   "safety_google_copy":" 複製",
   "safety_google_added":"添加成功後，你將得到一個有一定時效的6位數驗證碼，請將該驗證碼填入下方輸入框中",
   "safety_google_fill_in":"6位數驗證碼",
   "safety_google_check_error":"請檢查驗證碼",
   "safety_google_copied":"複製成功",
   "safety_google_activating":"啟動中...",
   "safety_google_success":"啟動谷歌驗證成功",
   "safety_google_deactivate_success":"解取谷歌驗證成功",
   "position_my":"我的持倉",
   "apply_trader":"申請成為大獅",
   "apply_trader_hero":"大獅",
   "apply_trader_notice":"請在此填寫申請表並提交。你的信息會被我們嚴格審核。我們會在48-72小時之內，給你回覆。 你的個人資料審核通過後，會有工作人員和你聯繫，進行相關的簽約流程。",
   "apply_trader_email":"聯繫電郵",
   "apply_trader_desc":"申請說明",
   "apply_trader_required":"必需",
   "apply_trader_email_input":"請輸入電郵",
   "apply_trader_size_limit":"請填寫申請理由，不超過300個字",
   "apply_trader_document":"相關證明",
   "apply_trader_apply":"提交申請",
   "apply_trader_applied":"你的申請已提交，待通過後會有專人和你聯繫！",
   "apply_trader_approved":"你的申請已通過，其後相關手續會專人和你聯繫！",
   "apply_trader_rejected":"你的申請不通過，請修改後重新申請，原因：%{reason}",
   "apply_trader_rejected_default":"審核不通過",
   "apply_trader_saving":"提交中...",
   "apply_trader_modify":"請修改後提交",
   "apply_trader_image_size":"（照片大小不得超過4M，不限格式）",
   "other_setting":"其他設定",
   "profile_feed":"動態",
   "profile_follow":"追蹤",
   "profile_followers":"粉絲",
   "profile_favorite":"收藏",
   "profile_balance":"我的錢包總額",
   "profile_logout":"登出",
   "profile_my_feed":"我的動態",
   "profile_my_follow":"我的追蹤",
   "profile_his_follow":"的追蹤",
   "profile_no_follow":"你還沒有追蹤過其他人呢",
   "profile_unfollow":"取消追蹤",
   "profile_followed":"追蹤成功",
   "profile_unfollowed":"取消追蹤",
   "profile_follow_action":"追蹤",
   "profile_follow_action_followed":"已追蹤",
   "profile_follow_action_friend":"互相追蹤",
   "profile_follow_ing":"追蹤...",
   "profile_unfollow_ing":"取消追蹤...",
   "profile_my_followers":"我的粉絲",
   "profile_his_followers":"的粉絲",
   "profile_no_followers":"你還沒有粉絲呢",
   "profile_my_fav":"我的收藏",
   "profile_my_profile":"個人檔案",
   "profile_my_profile_photo":"頭像",
   "profile_my_profile_nick":"暱稱",
   "profile_my_profile_status":"簡介",
   "profile_my_profile_click_edit":"點此編輯",
   "profile_my_profile_saving":"保存中...",
   "profile_my_profile_saved":"保存成功",
   "profile_my_profile_edit_nick":"編輯暱稱",
   "profile_my_profile_save":"保存",
   "profile_my_profile_nick_input":"請輸入暱稱",
   "profile_my_profile_edit_status":"編輯簡介",
   "profile_my_profile_status_input":"在這裏輸入簡介",
   "profile_my_profile_album":"相簿",
   "profile_my_profile_takephoto":"拍照",
   "profile_my_profile_cancel":"取消",
   "profile_my_profile_copier":"跟單者",
   "profile_my_profile_copier_ranking_show":"僅顯示當前回報率前十名的跟單者",
   "trader_timeline":"主頁",
   "trader_account":"帳戶",
   "trader_positions":"持倉",
   "trader_success_fee":"分紅比例",
   "trader_rate_of_profit":"回報率",
   "trader_total_amount":"帳戶資產",
   "trader_total_return":"總回報",
   "trader_win_rate":"交易勝率",
   "trader_trade_time":"交易歷時",
   "trader_day":"天",
   "trader_days":"天",
   "trader_week_return":"週回報",
   "trader_week_return_rate":"週回報率",
   "trader_frequency":"交易頻率",
   "trader_frequency_unit":"次/週",
   "trader_trend":"回報走勢",
   "trader_rate_trend":"回報率走勢",
   "trader_position":"持倉中",
   "trader_history_position":"持倉歷史",
   "trader_position_long":"買升",
   "trader_position_short":"買跌",
   "trader_open_price":"開倉價",
   "trader_end_price":"標記價格",
   "trader_finish_price":"平倉價",
   "trader_position_size":"持倉量",
   "trader_margin_size":"保證金",
   "profile_pwd_change":"修改密碼",
   "profile_pwd_change_failed":"修改密碼失敗",
   "profile_pwd_input_email":"輸入電郵帳號",
   "profile_pwd_reset":"重設密碼",
   "profile_pwd_original":"原本密碼",
   "profile_pwd_new":"設定密碼",
   "profile_pwd_tip":"6-20位，數字+字母",
   "kiki_index":"首頁",
   "kiki_market":"行情",
   "market_title":"行情",
   "market_contract":"槓桿",
   "market_heroes":"大獅",
   "market_community":"社區動態",
   "market_wallet":"我的錢包",
   "market_week_heroes":"本周大獅之星",
   "market_more":"更多",
   "market_week_profit_rate":"近一周回報率",
   "market_order":"訂單",
   "market_high":"最高",
   "market_low":"最低",
   "market_time":"分時",
   "market_15min":"15分",
   "market_1hour":"1小時",
   "market_4hour":"4小時",
   "market_1day":"日線",
   "market_available":"可用",
   "market_available_balance":"可用餘額",
   "market_transfer":"轉帳",
   "market_buy_long":"買入/做多",
   "market_sell_short":"賣出/做空",
   "market_order_type":"委托方式",
   "market_market_order":"市價",
   "market_limit_type":"限價",
   "market_est_price":"預計成交價",
   "market_est_funding_rate":"預計利息",
   "market_amount":"數量",
   "market_all":"全部",
   "market_lever":"槓桿",
   "market_stop_profit_loss_order":"止盈止損",
   "market_stop_profit_loss":"止盈止損",
   "market_stop_loss_price":"止損價",
   "market_stop_profit_price":"止盈價",
   "market_loss_prompt":"%{coinCode}價格達到%{lossPrice} %{currency}時平倉，預計盈虧%{loss} %{currency}",
   "market_profit_prompt":"%{coinCode}價格達到%{profitPrice} %{currency}時平倉，預計盈虧%{profit} %{currency}",
   "market_confirm":"確認",
   "market_cancel":"取消",
   "market_amount_not_greater_than_available":"交易量不能大於可用餘額",
   "market_margin_not_greater_than_available":"保證金不能大於可用餘額",
   "market_amount_must_greater_than":"交易量不能小於%{amount}%{currency}",
   "market_amount_must_less_than":"交易量不能超過%{amount}%{currency}",
   "market_amount_insufficient":"餘額不足",
   "market_stop_loss_must_greater_than":"止損價必須大於%{amount}%{currency}",
   "market_stop_loss_price_not_less_than":"止損價不能小於%{amount}%{currency}",
   "market_limit_price_must_greater_than":"限價必須大於%{amount}%{currency}",
   "market_stop_loss_price_not_greater_than_current":"止損價不能大於當前價",
   "market_stop_loss_price_not_greater_than_limit":"止損價不能大於限價",
   "market_stop_loss_price_not_greater_than":"止損價不能大於%{amount}%{currency}",
   "market_stop_loss_price_not_less_than_current":"止損價不能小於當前價",
   "market_stop_loss_price_not_less_than_limit":"止損價不能小於限價",
   "market_stop_profit_must_greater_than":"止盈價必須大於%{amount}%{currency}",
   "market_stop_profit_price_not_less_than_current":"止盈價不能小於當前價",
   "market_stop_profit_price_not_less_than_limit":"止盈價不能小於限價",
   "market_stop_profit_price_not_greater_than":"止盈價不能大於%{amount}%{currency}",
   "market_stop_profit_price_not_greater_than_current":"止盈價不能大於當前價",
   "market_stop_profit_price_not_greater_than_limit":"止盈價不能大於限價",
   "market_stop_profit_price_not_less_than":"止盈價不能小於%{amount}%{currency}",
   "market_order_success":"下單成功",
   "market_long":"買升",
   "market_short":"買跌",
   "market_open_price":"開倉均價",
   "market_open_time":"開倉時間",
   "market_close_avg_price":"平倉均價",
   "market_close_time":"平倉時間",
   "market_liquid_price":"預計強平價",
   "market_size":"交易總額",
   "market_margin":"保證金",
   "market_complete":"完成",
   "market_order_view":"查看訂單",
   "market_order_failed":"下單失敗",
   "market_position":"持倉",
   "market_active":"計劃委托",
   "market_positioning":"持倉中",
   "market_current_positioning":"當前持倉",
   "market_current_open_order":"當前委托",
   "market_order_history":"歷史持倉",
   "market_unrealized_pnl":"未實現盈虧",
   "market_mark_price":"標記價格",
   "market_current_price":"中間價",
   "market_close_position":"平倉",
   "market_order_detail":"持倉詳情",
   "market_order_history_detail":"歷史持倉詳情",
   "market_order_id":"訂單號",
   "market_order_status":"當前狀態",
   "market_limit_close":"用戶平倉",
   "market_market_close":"強制平倉",
   "market_position_closed":"平倉成功",
   "market_operate_success":"操作成功",
   "market_open_order":"委托中",
   "market_open_history":"歷史委托",
   "market_open_detail":"委托詳情",
   "market_open_history_detail":"歷史委托詳情",
   "market_trigger_price":"觸發價",
   "market_cancel_order":"撤銷",
   "market_cancel_success":"撤銷成功",
   "market_order_date":"委託時間",
   "market_trigger_date":"觸發時間",
   "market_filled":"已成交",
   "market_canceled":"已撤銷",
   "market_order_canceled":"委托失敗",
   "market_open_order_success":"委托成功",
   "market_open_order_canceled":"委托取消",
   "market_load_more":"加載更多",
   "market_no_data":"沒有了",
   "market_funding_rate":"利息費率",
   "market_funding_fee":"利息",
   "market_share_to":"分享",
   "market_detail":"詳情",
   "market_date":"時間",
   "market_price":"成交價格",
   "market_price_current":"當前市價",
   "market_pay":"購買",
   "market_pay_sell":"賣出",
   "market_fee_detail":"利息詳情",
   "market_fee_tip1":"1、利息是為了避免交易集聚在單邊，導致單邊開倉價與當前價的差價劇烈擴大，影響平台的市場交易環境，從而採取的措施。平台每日定時向用戶的持倉單收取利息或獎勵資金補貼。",
   "market_fee_tip2":"2、利息為正，指該交易方向的持倉需收取費用。反之為負則該方向持倉可以得到費用獎勵。",
   "market_fee_tip3":"3、利息=持倉名義價值*利息費率。",
   "market_fee_tip4":"4、每日0點、8點、16點，進行一次費用結算。",
   "market_fee_tip5":"5、如果持倉不到下一個結算點，不收取費用。",
   "market_estimation_tip":"非最終交易單價，僅供參考",
   "market_est_pay":"預估花費",
   "market_est_get":"預計得到",
   "market_profit_rate":"回報率",
   "market_position_share_profit":"別上班了，我用Kiki養你",
   "market_position_share_loss":"勝敗乃兵家常事",
   "market_position_share_profit1":"跟緊大牛，財務自由",
   "market_spot_order_result":"交易結果",
   "market_spot_buy_amount":"買入數量",
   "market_spot_sell_amount":"買出數量",
   "market_spot_buy_paid":"支付數量",
   "market_spot_sell_got":"賣出數量",
   "market_spot_buy_success":"買入成功",
   "market_spot_sell_success":"賣出成功",
   "market_spot_buy_fail":"買入失敗",
   "market_spot_sell_fail":"賣出失敗",
   "market_spot_buy_fail_detail":"由於網絡問題導致買入失敗，請重試。",
   "market_spot_sell_fail_detail":"由於網絡問題導致賣出失敗，請重試。",
   "market_spot_trade":"現貨交易",
   "market_spot":"現貨",
   "market_spot_amount":"交易數量",
   "market_spot_size":"成交數量",
   "market_spot_price":"價格",
   "market_fiat_trade":"法幣交易",
   "market_fiat_add_card":"添加銀行帳號",
   "market_fiat_edit":"編輯",
   "market_fiat_delete":"刪除",
   "market_fiat_name":"姓名",
   "market_fiat_save":"保存",
   "market_fiat_manage_card":"帳號管理",
   "market_fiat_deposit":"法幣充值",
   "market_fiat_deposit_history":"充值紀錄",
   "market_fiat_howtoDeposit":"銀行轉帳充值流程",
   "market_fiat_deposit_step1":"• Step 1：在您的銀行/網上銀行輸入以下Kikitrade 銀行帳號資料，並進行轉帳(可使用銀行轉帳,SWIFT)",
   "market_fiat_deposit_step2":"• Step 2：完成轉帳後，添加您的銀行帳號資料",
   "market_fiat_deposit_step3":"• Step 3：在Kikitrade輸入轉帳金額，並上傳銀行轉帳憑證",
   "market_fiat_deposit_step4":"• Step 4：成功提交申請後，我們會在一個工作天內完成審批",
   "market_fiat_deposit_attention_tip1":"1) 銀行帳戶名字必須與身分認證名字一致",
   "market_fiat_deposit_attention_tip2":"2) 用戶需承擔銀行轉帳所產生的費用",
   "market_fiat_deposit_attention_tip3":"3) Kikitrade將以到帳的金額為準，為用戶進行充值",
   "market_fiat_deposit_attention_tip4_1":"4) 轉帳時請在轉帳備註填寫",
   "market_fiat_deposit_attention_tip4_2":"下方的6位轉帳識別碼,",
   "market_fiat_deposit_attention_tip4_3":"如無填寫轉帳審批時間有可以會需要延長。轉帳識別碼的有效時間是24小時內。",
   "market_fiat_deposit_attention":"充值注意事項：",
   "market_fiat_transaction_agreement":"我已閱讀并同意以上流程和",
   "market_fiat_transfer":"匯款信息",
   "market_fiat_account":"收款人",
   "market_fiat_account_number":"收款帳號",
   "market_fiat_bank_code":"銀行編號",
   "market_fiat_swift_code":"銀行國際代碼(SWIFT)",
   "market_fiat_bank_name":"銀行名稱",
   "market_fiat_bank_address":"銀行地址",
   "market_fiat_transferred":"我已明白，轉帳並提交憑證",
   "market_fiat_currency":"支付幣種",
   "market_fiat_amount":"支付金額",
   "market_fiat_actual_confirmed":"實際確認金額",
   "market_fiat_kiki_fee":"平台費",
   "market_fiat_bank_charge":"銀行手續費",
   "market_fiat_failed_reason":"失敗原因：",
   "market_fiat_upload_receipt":"提交轉帳憑證",
   "market_fiat_card":"我的銀行帳號",
   "market_fiat_transfer_currency":"匯款幣種",
   "market_fiat_transfer_amount":"匯款金額",
   "market_fiat_transfer_ID":"匯款單號",
   "market_fiat_transfer_receipt":"匯款轉帳憑證",
   "market_fiat_transfer_confirm1":"本人鄭重聲明，本人轉入的資金來源，與洗錢、恐怖主義等犯罪活動無關，合法合規。",
   "market_fiat_transfer_confirm2":"本人明白KIKITRADE不會負責任何有關銀行轉帳所產生的費用。",
   "market_fiat_transfer_confirm3":"*請注意不同銀行會收取不同轉帳手續費。",
   "market_fiat_submit":"提交",
   "market_fiat_order_apply":"提款申請",
   "market_fiat_order_history":"提款歷史",
   "market_fiat_order_type":"提款幣種",
   "market_fiat_order_amount":"提款金額",
   "market_fiat_order_amount_withdrawl":"申請提款金額",
   "market_fiat_order_amount_received":"實際提款金額",
   "market_fiat_order_limit":"%{currency}提款說明：",
   "market_fiat_order_limit0":"• 最低提款金額：%{limit} %{currency}",
   "market_fiat_order_limit1":"• 單日提款上限：%{limit} %{currency}",
   "market_fiat_order_limit2":"• 30日內提款上限：%{limit} %{currency}",
   "market_fiat_order_limit_hkd_1":"• 本地港元轉帳平台費：0.15% , 最低收費10 HKD",
   "market_fiat_order_fee":"平台費：",
   "market_fiat_order_limit_usd_1":"本地美元轉帳: 0.15% , 最低收費40 USD",
   "market_fiat_order_limit_usd_2":"海外美元轉帳: 0.15%, 最低收費40 USD",
   "market_fiat_order_tips":"注意事項：",
   "market_fiat_order_tips1":"1）用戶必須通過身分認證，並使用本人名下的銀行卡提款。",
   "market_fiat_order_tips2":"2）提款金額低於%{limit} %{currency}: 1個工作天審批和轉帳處理",
   "market_fiat_order_tips3":"3）提款金額高於%{limit} %{currency}: 2-3個工作天審批和轉帳處理",
   "market_fiat_order_tips4":"4）週末及公眾假期處理時間較長，敬請留意",
   "market_fiat_apply_submit":"您的提款申請已成功提交",
   "market_fiat_bank_number":"銀行帳號",
   "market_fiat_save_success":"保存成功",
   "market_fiat_saving":"保存中...",
   "market_fiat_transfer_submit":"您的充值申請已成功提交",
   "market_fiat":"法幣",
   "market_crypto":"數字貨幣",
   "market_fiat_delete_card":"刪除銀行卡",
   "market_fiat_delete_confirm":"確定要刪除該張銀行卡嗎？",
   "market_fiat_withdraw_confirm":"在法幣提款之前，您需要先完成身份驗證。",
   "market_future":"槓桿",
   "market_future_cross":"全倉模式",
   "market_future_isolated":"槓桿交易",
   "market_switch":"切換至",
   "market_switch_light":"新手版",
   "market_switch_pro":"專業版",
   "market_highest":"最高",
   "market_lowest":"最低",
   "market_sell":"賣",
   "market_buy":"買",
   "market_cfd_sell":"買跌",
   "market_cfd_buy":"買升",
   "market_fiat_deposit_channel":"充值渠道",
   "market_fiat_deposit_currency":"充值幣種",
   "market_fiat_authentication_title":"身份驗證",
   "market_fiat_authentication_content":"在法幣充值之前，您需要先完成身份驗證",
   "market_fiat_authentication_back":"暫不",
   "market_fiat_authentication_certification":"驗證",
   "market_fiat_authentication_ok":"好的",
   "market_fiat_authentication_content_review":"您當前的身份信息正在審核中，請稍後再試。",
   "market_usdt_margin":"USDT槓桿",
   "login_login":"登入",
   "login_email":"電郵",
   "login_password":"密碼",
   "login_to_register1":"還沒有 Kikitrade 帳號？",
   "login_to_register2":"立即註冊",
   "login_successfully":"登入成功",
   "login_forgot_pwd":"忘記密碼？",
   "login_account_lock":"帳戶被鎖上",
   "login_account_lock_tip":"你的帳戶已被鎖上，請2小時後再試",
   "login_i_understand":"知道了",
   "login_send_successfully":"發送成功",
   "login_verify_successfully":"驗證成功",
   "login_forget_pwd":"忘記密碼",
   "login_input_email":"輸入電郵帳號",
   "login_input_google_code":"請輸入 Google 驗證碼",
   "login_input_email_code":"請輸入電郵驗證碼",
   "login_input_login_pass":"請輸入登錄密碼",
   "login_email_verification":"電郵驗證",
   "login_twice_verification":"二次驗證",
   "login_verification_code":"驗證碼",
   "register_register":"註冊",
   "register_email":"電子信箱",
   "register_to_login1":"已有 Kikitrade 帳號？立即",
   "register_to_login2":"登入",
   "register_invite_code":"邀請碼（選填）",
   "register_invite_code_error":"邀請碼錯誤",
   "register_protocol":"註冊即代表接受",
   "register_enter_email":"請輸入正確的電郵地址",
   "register_email_used":"電郵已註冊",
   "register_verify_code":"電郵驗證",
   "register_verifying":"驗證中",
   "register_verify_successfully":"驗證成功",
   "register_verify_code_prompt":"驗證碼已發送至電郵 %{email}",
   "register_code_send_successfully":"驗證碼發送成功",
   "register_code_expired":"驗證碼已過期，請重新獲取",
   "register_code_not_received":"如果沒收到驗證碼，請檢查收件箱中的垃圾郵件或重新發送",
   "register_china":"中國大陸",
   "register_other":"其他",
   "register_hk":"香港",
   "register_tw":"台灣",
   "register_sg":"新加坡",
   "register_my":"馬來西亞",
   "register_au":"澳大利亞",
   "register_euro":"歐洲",
   "register_uploading":"保存中",
   "register_upload_success":"保存成功",
   "register_click_to_upload":"點擊上傳頭像",
   "register_male":"男士",
   "register_female":"女士",
   "register_complete":"完成",
   "register_set_pwd":"設定密碼",
   "register_pwd_tip":"6-20 位，包含數字和字母",
   "register_nickname_tip":"暱稱（15字以內）",
   "register_forget_pwd_success":"密碼重置成功",
   "register_forget_pwd_reset":"重置密碼",
   "register_email_success":"註冊成功",
   "asset_asset":"我的資產",
   "asset_total_amount":"總資產",
   "asset_main_account":"現貨",
   "asset_contract_account":"槓桿",
   "asset_yield_account":"理財",
   "asset_cross_account":"全倉",
   "asset_isolated_account":"槓桿",
   "asset_deposit":"充幣",
   "asset_exchange":"交易",
   "asset_withdrawal":"提幣",
   "asset_transfer":"轉帳",
   "asset_amount":"總資產",
   "asset_account_equity":"帳戶淨值",
   "asset_available":"可用",
   "asset_frozen":"凍結",
   "asset_detail":"資產詳情",
   "asset_transfer_in":"轉入",
   "asset_transfer_out":"轉出",
   "asset_reward":"獎勵",
   "asset_deposit_history":"充幣歷史",
   "asset_deposit_address":"充幣地址",
   "asset_address_getting":"正在獲取地址...",
   "asset_generate_address":"生成地址",
   "asset_generate_address_tip":"點擊下方按鈕生成地址",
   "asset_deposit_tip1":"此地址只接受 %{coinCode} 充幣，任何其他加密貨幣資產充入此地址都不可找回和不予處理。",
   "asset_deposit_tip2_ETH":"ETH 充幣只支援以太坊主網，其他主網類型（如 BEP2, BEP20, HRC20, MATIC, FTM 等）的 ETH 入金將無法追回和不予處理。",
   "asset_deposit_tip2_USDT":"USDT 充幣只支援 ERC20，其他主網類型（如 Omni, BEP2, BEP20, TRC20, HRC20, MATIC, FTM 等）的 USDT 入金將無法追回和不予處理。",
   "asset_deposit_tip2_BTC":"BTC 充幣只支援 BTC 主網，其他主網類型（如 BEP2, BEP20, HRC20, MATIC, FTM 等）的 BTC 入金將無法追回和不予處理。",
   "asset_deposit_tip2_BCH":"BCH 充幣只支援 BCH 主網類型，其他主網類型（如 BEP2, BEP20, HRC20, MATIC, FTM 等）的 BCH 入金將無法追回和不予處理。",
   "asset_deposit_tip2_LTC":"LTC 充幣只支援 LTC 主網類型，其他主網類型（如 BEP2, BEP20, HRC20, MATIC, FTM 等）的 LTC 入金將無法追回和不予處理。",
   "asset_deposit_tip2_XRP":"XRP 充幣只支援 XRP 主網，其他主網類型（如 BEP2, BEP20, HRC20, MATIC, FTM 等）的 XRP 入金將無法追回和不予處理。必須正確填寫以上地址 (address) 及備註 (tag) 才能成功入金，若有遺漏或無正確填寫則不予處理，資產無法追回。",
   "asset_deposit_tip3_ETH":"若您充值的 ETH 來自於智能合約錢包（如 BlockFi、eToro、Paxful、Bitstamp 等），將會延遲到帳，會需要額外的處理時間及區塊鏈轉帳費用 0.015 ETH。如果您已轉帳且記錄已上鏈但卻沒出現在 Kikitrade 的充值頁面上，歡迎將 txid 電郵至 support@kikitrade.com 或到 Kikitrade 聯繫客服查詢。要是您不確定發送交易來源是否為智能合約，請先與發送平台或錢包服務商確認。",
   "asset_deposit_tip3_USDT":"若您充值的 USDT 來自於智能合約錢包（如 BlockFi、eToro、Paxful、Bitstamp 等），將會延遲到帳，會需要額外的處理時間及區塊鏈轉帳費用 20 USDT。如果您已轉帳且記錄已上鏈但卻沒出現在 Kikitrade 的充值頁面上，歡迎將 txid 電郵至 support@kikitrade.com 或到 Kikitrade 聯繫客服查詢。要是您不確定發送交易來源是否為智能合約，請先與發送平台或錢包服務商確認。",
   "asset_deposit_minimum_amount":"最低存幣金額：%{num}。",
   "asset_deposit_minimum_amount_desc":"最低充幣額是按從其他平台轉出扣除手續費後，根據鏈上 TXID 查詢的金額為準，任何低於最低充幣額將不予處理退款。",
   "asset_show_qr":"顯示二維碼",
   "asset_copy":"複製",
   "asset_copy_success":"複製成功",
   "asset_save_photo":"保存到本地相簿",
   "asset_saved_photo":"圖片已保存",
   "asset_save":"保存",
   "asset_date":"時間",
   "asset_status":"狀態",
   "asset_complete":"已完成",
   "asset_pending":"確認中",
   "asset_failed":"失敗",
   "asset_withdrawal_history":"提幣歷史",
   "withdraw_amount":"提幣數量",
   "deposit_amount":"充幣數量",
   "withdraw_coin":"提幣幣種",
   "deposit_coin":"充幣幣種",
   "go_to_trade":"去交易",
   "asset_withdrawal_id":"號碼",
   "asset_withdrawal_amount":"數量",
   "asset_withdrawal_detail":"提幣詳情",
   "asset_deposit_detail":"充幣詳情",
   "asset_withdrawal_address":"地址",
   "asset_withdrawal_submitting":"提交中",
   "asset_withdrawal_address_save_ok":"地址保存成功",
   "asset_withdrawal_address_tip":"標簽長度不能超過30個字符",
   "asset_withdrawal_address_manage":"地址管理",
   "asset_withdrawal_input_address":"輸入新地址",
   "asset_withdrawal_input_label":"輸入備註",
   "asset_withdrawal_input_tag":"輸入標簽",
   "tag":"標簽",
   "asset_withdrawal_google_bind_tip":"你還未綁定谷歌驗證，請前往綁定",
   "asset_withdrawal_status_apply":"申請提幣",
   "asset_withdrawal_status_approval":"審核通過",
   "asset_withdrawal_status_unapproval":"審核未通過",
   "asset_withdrawal_amount_must_greater_than":"提幣金額必須大於%{amount}",
   "asset_withdrawal_amount_not_greater_than_available":"提幣金額不能大於可用金額",
   "asset_withdrawal_amount_not_less_than_once_min_amount":"提幣金額不能小於單次最小提幣額",
   "asset_withdrawal_amount_not_greater_than_once_max_amount":"提幣金額不能大於單次最大提幣額",
   "asset_withdrawal_tip1":"•  24小時最高提幣額：%{oneDayMax}%{currency}，單筆最小提幣額：%{min}%{currency}，單筆最高提幣額：%{max}%{currency}。",
   "asset_withdrawal_tip2":"• 出於安全原因，當你使用新的提款地址時，我們將手動審核你的提幣。",
   "asset_transaction_id":"交易 ID",
   "asset_transaction_fee":"手續費",
   "asset_add_new_addr":"輸入新地址",
   "asset_label":"備註",
   "asset_label_name":"輸入備註名",
   "asset_coin":"幣種",
   "asset_transfer_amount":"轉帳數量",
   "asset_all":"全部",
   "asset_transfer_tip":"請輸入轉帳數量",
   "asset_transfer_tip1":"只有將資產轉帳到對應帳戶，才可進行相關業務操作",
   "asset_transfer_tip2":"帳戶間的轉帳不收取手續費",
   "asset_transfer_tip3":"最小理財金額%{amount} %{currency}",
   "asset_in_order":"持倉與委托",
   "asset_in_order_margin":"持倉保證金",
   "asset_unrealized_pnl":"未實現盈虧",
   "asset_margin_rate":"保證金率",
   "asset_open_position":"開倉",
   "asset_close_position":"平倉",
   "asset_limit_order":"限價委托",
   "asset_cancel_order":"取消委托",
   "asset_adjust_margin":"保證金調整",
   "asset_follow_amount":"跟單中",
   "asset_performance_share":"跟單分紅回報",
   "asset_start_follow":"跟單開倉",
   "asset_close_follow":"跟單平倉",
   "asset_spot_trade":"現貨交易",
   "asset_interest":"年回報率",
   "asset_total_interest":"歷史累積回報",
   "asset_interest_type":"理財利息",
   "asset_trial_bonus_interest":"體驗金利息",
   "asset_operate_success":"操作完成",
   "asset_transfer_not_greater_than_available":"轉帳金額不能大於可用餘額",
   "asset_transfer_must_greater_than":"轉帳金額必須大於%{amount}",
   "asset_transfer_from":"從",
   "asset_transfer_to":"到",
   "asset_deleting":"正在刪除...",
   "asset_delete_success":"刪除成功",
   "asset_delete_fail":"刪除失敗",
   "asset_chain_type":"主鏈類型",
   "profile":"我的",
   "languageSetting":"語言設定",
   "otherSetting":"其他設定",
   "edit":"編輯",
   "share_not_available":"分享暫無法使用",
   "profile_version":"版本更新",
   "profile_about":"關於",
   "profile_contact_us":"聯繫客服",
   "profile_update":"更新",
   "profile_download_version_tip":"點擊下載最新版本：%{version}",
   "profile_latest_version_tip":"你當前已經是最新版本！",
   "profile_my_order":"我的下單",
   "profile_cross_order":"全倉訂單",
   "profile_isolated_order":"槓桿訂單",
   "profile_spot_order":"現貨交易訂單",
   "profile_fiat_order":"法幣交易訂單",
   "community_community":"社區",
   "community_recommend":"推薦",
   "community_myFollow":"我的追蹤",
   "community_follow":"追蹤",
   "community_topTrader":"頭號大獅",
   "community_feed":"動態",
   "community_myFans":"粉絲",
   "community_main":"主頁",
   "community_account":"帳戶",
   "community_position":"持倉",
   "community_myFollowers":"跟單者",
   "community_topicDetail":"貼文詳情",
   "community_likedCounts":"已有{likedCounts}人讚好過",
   "community_share":"分享",
   "community_topComments":"精彩評論",
   "community_replyCounts":"條回覆",
   "community_reply":"回覆",
   "community_viewMoreReplies":"查看所有回覆",
   "community_writeComment":"撰寫你的評論",
   "community_publishComment":"發布評論",
   "community_sendPost":"建立",
   "community_sendCommentOrReply":"發送",
   "community_allComments":"全部評論",
   "community_myMessage":"我的消息",
   "community_socialMessage":"社交消息",
   "community_systemNotification":"系統通知",
   "community_commentsMe":"評論我的",
   "community_repliesMe":"回覆我的",
   "community_likesMe":"讚好我的",
   "community_commentsOnYourPost":"評論了你的貼文",
   "community_likesYourPost":"讚好了你的貼文",
   "community_repliesYourPost":"回覆了你的貼文",
   "community_uploading":"正在努力上傳中",
   "community_releasePost":"建立貼文",
   "community_hotPost":"熱門話題",
   "community_hotPost_placeholder":"在這裡輸入你要發布的話題內容……",
   "community_releasePost_placeholder":"你在想什麼?",
   "community_vote_placeholder":"在這裡輸入你要發布的投票內容……",
   "community_vote_selection":"投票選型",
   "community_right":"右",
   "community_left":"左",
   "community_manage":"管理",
   "community_delete_post":"删除貼文",
   "community_delete_comment":"刪除評論",
   "community_delete_reply":"刪除回复",
   "community_delete_topic_tip":"確定刪除當前的話題嗎？一經刪除，不可恢復。",
   "community_delete_post_tip":"確定刪除當前的貼文嗎？一經刪除，不可恢復。",
   "community_delete_poll_tip":"確定刪除當前的投票嗎？一經刪除，不可恢復。",
   "community_delete_reply_tip":"確定刪除當前的評論嗎？一經刪除，不可恢復",
   "community_delete_comment_tip":"確定刪除當前的評論嗎？一經刪除，不可恢復",
   "community_post_notfound":"抱歉，這個貼文被飛碟帶走了…",
   "community_share_increase":"分享數+1",
   "community_releasePollSuccess":"發布投票成功",
   "community_pollSending":"投票發布中...",
   "community_releaseTopicSuccess":"發布話題成功",
   "community_topicSending":"話題發布中...",
   "community_topic_over_limit":"當前已有3個話題，無法創建新話題！",
   "community_vote_over_limit":"當前已有3個投票，無法創建新投票！",
   "community_vote":"表決",
   "community_cancelLike":"取消讚好成功",
   "community_likeSuccess":"讚好成功",
   "community_cancelLikeFail":"取消讚好失敗",
   "community_likeFail":"讚好失敗",
   "community_cancelFollow":"取消追蹤成功",
   "community_followSuccess":"追蹤成功",
   "community_cancelFollowFail":"取消追蹤失敗",
   "community_followFail":"追蹤失敗",
   "community_reportSuccess":"貼文檢舉成功",
   "community_contentReport":"內容舉報",
   "community_reportContent":"舉報內容",
   "community_reportContentLimit":"舉報內容不超過200個字",
   "community_report":"檢舉",
   "community_releaseDesc":"你在想什麼?",
   "community_commentSending":"評論建立中...",
   "community_commentSuccess":"評論建立成功",
   "community_commentFail":"評論建立失敗",
   "community_publishedComment":"建立評論",
   "community_published":"建立",
   "community_releaseSuccess":"成功建立貼文",
   "community_postSending":"貼文建立中...",
   "community_favSuccess":"收藏成功",
   "community_cancelFavSuccess":"取消收藏成功",
   "community_all":"所有",
   "community_add":"添加",
   "community_imgSaved":"圖片已保存",
   "community_WebPageDetail":"網頁詳情",
   "community_comment":"評論",
   "community_like":"讚好了",
   "community_yourPost":"你的貼文",
   "community_originalText":"【原文】",
   "community_gif":"GIF",
   "community_loading":"正在加載...",
   "community_abuse":"辱罵攻擊",
   "community_sexual":"色情低俗",
   "community_ads":"營銷詐騙",
   "community_political":"政治敏感",
   "community_weapons":"違禁品",
   "community_violence":"血腥暴力",
   "community_others":"其他",
   "community_insertPic":"插入圖片",
   "community_insertVideo":"插入影片",
   "community_guessULike":"猜你喜歡",
   "community_news":"新聞",
   "community_explore":"社群",
   "community_news_headlines":"頭條",
   "community_no_more":"更多",
   "community_no_content":"暫無內容",
   "community_hot_topic":"熱門",
   "community_poll":"投票",
   "community_viewers":"瀏覽人數",
   "community_participants":"評論人數",
   "community_voters":"投票人數",
   "community_select":"选择",
   "community_block":"隱藏",
   "community_block_failed":"屏蔽失敗，請稍後再試",
   "member_shipCenter":"會員專區",
   "member_plus":"銀卡會員",
   "member_gold":"金卡會員",
   "member_expiry_date":"到期",
   "member_renew":"續期",
   "member_activate":"啟動",
   "member_silver_membership":"銀卡會員權利",
   "member_gold_membership":"金卡會員權利",
   "member_daily_box":"登入拆禮物",
   "member_login_earn_coin":"每天拆禮物，可抽取不同金額的USDT",
   "member_trading_bonus":"交易鼓勵金",
   "member_earn_rebate":"每筆交易，都可獲得10%交易費用的鼓勵金",
   "member_referrals":"推薦返佣",
   "member_earn_fee":"可以獲得推薦用戶20%的交易手續費",
   "member_yield":"餘額寶",
   "member_earn_annual_yield":"享有2%的每年回報，而且隨時隨地存取",
   "member_special_features":"會員專屬活動",
   "member_renewal_plus":"銀卡自動續費",
   "member_renewal_gold":"金卡自動續費",
   "member_month_count":"個月",
   "member_month_counts":"個月",
   "member_discount1":"限時活動{discount}折",
   "member_discount2":"首次享有{firstBuyDiscount}折",
   "member_activate_renewal":"你開啟了【{level}】自動續費服務。",
   "member_automatically_charge":"自本月起，每隔一個月，系統將從你帳戶中，扣除 {price}USDT。",
   "member_menber_activate":"開啟會員",
   "member_next_renewal":"下個月續費，從本月會員到期結束時間計起，",
   "member_renewal":"自動續費(到期後自動從USDT帳戶中扣除)。",
   "member_activating":"開啟會員中...",
   "member_first_buy":"首次充值只需$16",
   "member_upgrade":"升級",
   "member_buy_success":"你購買了{period}個月{level}",
   "member_buy_charge":"系統已從你的幣幣帳戶中，扣除了{price}USDT。",
   "member_upgrading":"會員升級中...",
   "member_expiry":"你當前{level}到期時間：",
   "member_upgrading_membership":"升級為{level}後，你將享有{level}的所有權利。",
   "member_upgrad_level":"升級{level}後，在會員期限內，都是{level}。",
   "member_purchase_difference":"你本次只需繳付從當前到會員到期日的差額即可。",
   "member_current_renewal":"你當前購買的是自動續費類型的{level}",
   "member_level_type":"升級{level}後，仍為自動續費類型。",
   "member_renewal_difference":"你本次只需繳付從當前到下一次扣費日的差額即可。",
   "member_buy_failed_desc":"你的USDT餘額不足，請先充值後，再進行{type}操作。",
   "member_buy_failed":"失敗",
   "member_topup":"充值",
   "member_desc":"下個月續費，從本月會員到期結束時間算起，自動續費(到期後自動從幣幣帳戶USDT帳戶中扣除)",
   "member_automatic_renewal":"自動續費",
   "member_cancel_renewal":"取消自動續費",
   "member_cancel_confirm":"確認取消自動續費",
   "member_cancel_tip":"確定要取消自動續費嗎？失去會員資格，你將會損失很多權利。請認真考慮後在下決定。",
   "member_cancel_desc1":"你當前開啟了【自動續費】的{level}。",
   "member_cancel_desc2":"取消自動續費，則當前的會員權利，將保留至下個月的扣費日。",
   "member_cancel_desc3":"下個月的扣費日後，系統將不再自動扣款。",
   "member_cancel_res1":"你已成功取消了自動續費。",
   "member_cancel_res2":"截止到下個月的扣款日之前，你仍享有{level}的所有權利。",
   "member_cancel_success":"自動續費取消成功！",
   "member_buy":"成為會員",
   "member_res_type_desc":"{type}{level}成功！",
   "member_renewal_type":"續費",
   "member_upgrade_type":"升級",
   "member_activate_type":"開啟",
   "member_res_fail":"{type}{level}失敗",
   "member_renewal_type_fail":"續費",
   "member_upgrade_type_fail":"升級",
   "member_activate_type_fail":"開啟",
   "member_Kiki":"Kiki ",
   "member_no_parameters_purchase":"暫無可購買的會員",
   "common_submiting":"正在提交中，請稍後重試",
   "common_networkError":"網絡異常，請檢查後再試",
   "common_serverBusy":"服務繁忙，請檢查後再試",
   "common_systemBusy":"系統繁忙，請稍後重試",
   "common_noRecords":"暫無記錄",
   "common_loadMore":"加載更多…",
   "common_loading":"加載中",
   "common_confirm":"確認",
   "common_cancel":"取消",
   "common_unknown":"其他",
   "common_next_step":"下一步",
   "common_2_step_verification":"二次驗證",
   "common_google_auth":"谷歌驗證",
   "common_sms":"短信",
   "common_resend":"重新發送",
   "common_send":"發送驗證碼",
   "common_code_error":"驗證碼錯誤",
   "common_ok":"好的",
   "common_not_open":"暫未開放，敬請期待！",
   "common_complete":"完成",
   "common_apply_storage":"申請讀取手機存儲權限",
   "common_apply_later":"稍後詢問",
   "common_apply_no":"拒絕",
   "common_apply_yes":"允許",
   "common_no_load_more":"",
   "common_scan_download":"掃描下載App",
   "common_saving":"提交中...",
   "invite_my_code":"我的邀請碼",
   "invite_share_link":"邀請連結",
   "invite_share_poster":"邀請海報",
   "invite_count":"成功邀請人數",
   "invite_return_rate":"返佣比例",
   "invite_return_profit":"返佣回報",
   "invite_rule":"規則",
   "invite_tip1":"1、好友通過邀請碼註冊後，每產生一筆真實交易的手續費，會產生相應比例的返佣；",
   "invite_tip2":"2、返佣將以USDT形式返佣到你的交易帳戶，普通用戶返佣比例為10%，銀卡會員返佣比例為40%，金卡會員返佣比例為70%；",
   "invite_tip3":"3、返佣當日統計，第二日到帳。",
   "invite_copy_success":"複製成功",
   "invite_copy_fail":"複製失敗，請重試",
   "invite_reward_amount":"獎勵數量",
   "invite_reward_time":"獎勵時間",
   "invite_reward_record":"返佣記錄",
   "invite_referral_record":"邀請記錄",
   "invite_referral_account":"好友帳戶",
   "invite_referral_time":"註冊時間",
   "invite_step_1":"發送邀請給好友",
   "invite_step_2":"邀請碼註冊並首次充值成功",
   "invite_step_3":"好友獲得 38 USDT 您獲得 25 USDT",
   "invite_poster_tip_1":"來KIKITRADE",
   "invite_poster_tip_2":"跟單頂級大獅",
   "invite_rules":"邀請規則",
   "invite_rules1":"1. 分享自己的邀請碼給好友，好友註冊完成身份認證後，需首次充值且單筆滿額 10,000 HKD / 1,300 USD / 36,000 TWD，雙方才能獲得獎勵。",
   "invite_rules2":"2. 被邀請者必須確保註冊時有正確填寫邀請碼，否則邀請人無法領取獎勵。",
   "invite_rules3":"3. 邀請碼無法於註冊後補登錄。",
   "invite_rules4":"4. 若充值 HKD 或 USD 獎勵會於當天直接發放到理財帳戶；充值 TWD 獎勵則會於下個工作日發至現貨帳戶。",
   "invite_rules5":"5. 此活動不能與首次充值活動同時參與。",
   "invite_rules6":"6. 任何活動相關問題請詢問電郵至 support@kikitrade.com 或點擊 ",
   "invite_rules7":"7. Kikitrade 官方享有活動內容的最終解釋權，針對違規行為用戶我們將有權進行處理。本活動與 Apple Inc. 無關。",
   "invite_desc":"邀請一位好友，即免費獲得價值$ ",
   "invite_desc1_1":"好友首次入金齊領 ",
   "invite_desc1_center":" HK$500 ",
   "invite_desc1_2":"獎勵 ",
   "invite_desc2":"邀請一位好友首次於 Kikitrade 充值，滿額 10,000 HKD / 1,300 USD / 36,000 TWD，好友獲得 38 USDT（約 HK$300），",
   "invite_desc3":"您會獲得 25 USDT（約 HK$200）",
   "invite_desc4":"邀請好友大升級",
   "invite_reward":"已獲得獎勵 (USDT)",
   "box_member_zone":"天天拆禮物",
   "box_reward_record":"獲獎記錄",
   "box_open_box":"開始拆禮物",
   "box_game_desc":"規則說明",
   "box_tip_1":"• 會員每天有兩次拆禮物機會，分享到社交平台後可以獲得一次拆禮物機會",
   "box_tip_2":"• 禮物中可獲得不同數量的USDT ",
   "box_tip_3":"• 獎勵會立即發放到錢包中",
   "box_tip_4":"• 如有任何爭議，Kikitrade保留最終決定權。本次活動是由Kikitrade舉辦，與Apple無關。",
   "box_congrat_1":"恭喜你獲得",
   "box_congrat_2":"獎勵已發放至你的錢包中",
   "box_show":"炫耀一下",
   "box_no_reward_1":"很遺憾",
   "box_no_reward_2":"沒有中獎",
   "box_no_chance":"今天沒有抽獎機會了T_T",
   "box_earn_chance":"可以獲得一次抽獎機會",
   "box_share_to_friend":"邀請好友一起玩",
   "box_max_reward":"最高可得 1000USDT",
   "box_open_member":"開啟會員，天天拆禮物",
   "box_opening":"開獎中...",
   "box_loading":"每天登陸kiki的你，運氣都不會太差",
   "box_lucky":"恭喜你中獎了",
   "box_get_reward":"獲得獎勵",
   "box_share_open":"跟我一起來Kiki拆禮物吧",
   "box_highest_reward":"最高可得",
   "box_tip_follow_trader":"還可以跟單頂級大獅",
   "kyc_verify_title":"開始認證",
   "kyc_verify_desc":"請保證你設備的前置鏡頭可用，並準備好相關的證件。",
   "kyc_verify_over":"認證結束",
   "kyc_over_desc":"我們正盡全力進行審核，請你稍後來這裏查看審核結果。",
   "kyc_verifying":"認證中",
   "kyc_verifying_desc":"我們正盡全力進行審核，請你稍後來這裏查看審核結果。",
   "kyc_success":"認證成功",
   "kyc_failed":"認證失敗",
   "kyc_firstName":"名字",
   "kyc_lastName":"姓氏",
   "kyc_birthDay":"生日",
   "kyc_idCartType":"證件類型",
   "kyc_idCartNo":"證件ID",
   "kyc_failed_tip1":"未通過原因",
   "kyc_failed_tip2":"系統無法識別你上傳的護照，請細閱提示後重新拍照上傳。如有任何問題，請到【我的】-【其他設定】-【聯繫客服】聯繫我們。",
   "kyc_verify_second":"重新認證",
   "kyc_unverified":"未認證",
   "kyc_verified":"已認證",
   "kyc_rejected":"未通過",
   "kyc_result_title":"身份認證",
   "kyc_card_id":"身份證",
   "kyc_country":"國家",
   "kyc_card_license":"駕照",
   "kyc_passport":"護照",
   "home_buy":"買入加密貨幣",
   "home_sell":"賣出加密貨幣",
   "home_trade_title":"在KIKI一站式購買和交易數字貨幣",
   "home_share_commission":"Share commission",
   "home_liquidated_volume":"爆倉",
   "home_past_hour":"過去一小時",
   "home_long_short":"多空比",
   "home_market":"市場",
   "home_news":"新聞",
   "home_more_news":"更多新聞",
   "home_feed":"社交",
   "home_more_feed":"更多社交",
   "home_bank_transfer_credit":"銀行卡轉帳",
   "home_crypto_currency":"加密貨幣充值",
   "home_more_growth":"多增長",
   "webview_about":"關於",
   "webview_contact_us":"聯繫我們",
   "webview_event":"活動",
   "cfd.process.success":"處理成功",
   "cfd.process.fail":"處理失敗",
   "cfd.system.close":"系統已關閉",
   "cfd.plate.not.exist":"盤口不存在",
   "cfd.Insufficient.balance":"餘額不足",
   "cfd.customer.statu.not.active":"用戶狀態未啟動",
   "cfd.customer.permission.denied":"權限不足",
   "cfd.invalid.parameter":"無效的參數",
   "spot.order.invalid.parameter":"無效的參數",
   "cfd.margin.not.enough":"保證金不足",
   "cfd.customer.need.id.verify":"需要實名認證",
   "cfd.parameter.required":"必須的參數",
   "cfd.incorrect.operation":"訂單狀態已變更，請刷新後重試",
   "cfd.not.exist":"信息不存在",
   "cfd.will.be.liquidationed":"倉位可能被強制平倉",
   "cfd.greater.than.max.free.margin":"超過可用額度",
   "cfd.stoplossprice.greater.than.marketprice":"止損價格超過市價",
   "cfd.stoplossprice.less.than.marketprice":"止損價格低於市價",
   "cfd.takeprofitprice.greater.than.marketprice":"止盈價格超過市價",
   "cfd.takeprofitprice.less.than.marketprice":"止盈價格低於市價",
   "cfd.stoplossprice.greater.than.liquidationprice":"止損價格超過強平價",
   "cfd.stoplossprice.less.than.liquidationprice":"止損價格低於強平價",
   "cfd.customer.stop.trade":"當前用戶被禁止交易",
   "cfd.contract.config.error":"合約配置錯誤",
   "cfd.entrust.locked":"操作過於頻繁，請你稍後重試",
   "cfd.order.locked":"操作過於頻繁，請你稍後重試",
   "cfd.stoplossprice.greater.than.triggertprice":"止損價格超過委托價格",
   "cfd.stoplossprice.less.than.triggertprice":"止損價格低於委托價格",
   "cfd.takeprofitprice.greater.than.triggertprice":"止盈價格超過委托價格",
   "cfd.takeprofitprice.less.than.triggertprice":"止盈價格低於委托價格",
   "cfd.invalid.marketprice":"無效的市價",
   "cfd.takeprofitprice.over.limit":"止盈價格超過最大限額",
   "cfd.position.over.limit":"倉位超過最大限額",
   "cfd.margin.less.than.order.min":"小於最小下單量",
   "cfd.margin.greater.than.order.max":"大於最大下單量",
   "cfd.user.cancel.processing":"用戶取消處理中",
   "cfd.user.close.processing":"用戶平倉處理中",
   "cfd.user.adjust.processing":"保證金調整處理中",
   "post.not.found":"貼文不存在或已被刪除",
   "comment.is.not.exist":"評論不存在或已被刪除",
   "customer.login.password.error.email":"用戶名或密碼錯誤,還可以輸入%{time}次",
   "customer.login.password.error.mobile":"用戶名或密碼錯誤,還可以輸入%{time}次",
   "member.process.success":"處理成功",
   "member.process.fail":"處理失敗",
   "member.invalid.parameter":"無效的參數",
   "member.config.error":"會員配置錯誤",
   "member.pay.processing":"正在處理中",
   "member.upgrade.not.supported":"暫不支持升級",
   "member.insufficient.balance":"用戶餘額不足",
   "member.customermisc.exist.none":"用戶信息不存在",
   "activity.req.processing":"正在處理中",
   "invalid.customer.level":"你的用戶等級不足",
   "activity.daily.times.limit":"今天已無抽獎機會",
   "hour":"小時",
   "learn":"新手",
   "read_more":"了解更多 >",
   "order_result":"交易結果",
   "deposit_confirming":"入金確認中",
   "deposit_success":"入金成功",
   "deposit_rejected":"入金失敗",
   "deposit_reject_fail":"入金失敗",
   "withdraw_applied":"提款確認中",
   "withdraw_success":"提款成功",
   "withdraw_reject_fail":"提款失敗",
   "withdraw_rejected":"提款失敗",
   "withdraw_fail":"提款失敗",
   "depositFiat":"入金",
   "withdrawFiat":"提款",
   "firstStep":"第一步",
   "uploadPassportOrID":"上傳有效證件",
   "passportOrID":"身分證(只限香港）/ 護照",
   "hongkongId":"身分證(只限香港）",
   "kycTips":"KYC 提示",
   "tip":"提示",
   "tip1":"提示1",
   "tip2":"提示2",
   "step1":"第一步",
   "step2":"第二步",
   "step3":"第三步",
   "kyc_detect_over":"檢測完成",
   "upload_error":"上傳失敗，請重試",
   "uploadSelfImage":"上傳日期自拍照",
   "kyc_tip_1":"選擇證件類型並上傳證件",
   "kyc_tip_2":"臉部辨識檢測：請按指示在指定區域拍攝。",
   "kyc_tip_3":"系統會自動發出驗證結果，請耐心等候。",
   "kyc_tip_4":"提示1: 上傳護照只需要有資料的一頁。",
   "kyc_tip_5":"提示2: 上傳證件不要反光，不要模糊，尤其在底部兩行邊嗎要清晰。",
   "kyc_tip_6":"我們為什麼需要您的資料？",
   "kyc_tip_7":"點擊查看說明。",
   "kyc_tip_8":"提示3: 如果檢測3次都失敗，請聯絡Kiki客服處理。",
   "kyc_tip_9":"檢測完成，系統會在1-5個工作天内完成，請耐心等待。",
   "activity_gift_card":"兌換禮品卡",
   "activity_gift_history":"兌換記錄",
   "activity_gift_enter":"輸入兌換碼",
   "activity_gift_placeHolder":"第一次輸入Kikitrade會有驚喜！",
   "activity_gift_exchange":"兌換",
   "activity_gift_how":"如何獲得禮品卡？",
   "activity_gift_how1":"參加Kikitrade的Facebook活動便可免費獲得禮品卡",
   "activity_gift_how2":"App內暫時未能購買禮品卡，如需購買禮品卡，",
   "activity_gift_how3":"《請點擊這裹》",
   "activity_gift_success":"成功兌換",
   "activity_gift_success_desc1":"你的禮品卡No.{cardNo}已成功兌換",
   "activity_gift_success_desc2":"{currency}已發放到你的錢包中。",
   "activity_gift_success_text1":"你的禮品卡兌換成功",
   "activity_gift_success_text2":"等值BTC即將發放到你的錢包中",
   "activity_gift_wallet":"查看錢包",
   "activity_gift_know":"我知道了",
   "activity_gift_disabled":"兌換功能被鎖上",
   "activity_gift_disabled1":"由於你輸入了3次錯誤的兌換碼，兌換功能暫時被鎖上，今天內不能再使用.",
   "activity_gift_failed":"兌換失敗",
   "activity_gift_failed1":"你輸入的兌換碼無效，或已經被使用。",
   "activity_gift_exchange_useUp":"兌換次數用完",
   "activity_gift_exchange_useUp1":"你已超出單日最高兌換次數(3次).\n請明天再試。",
   "otc.customer.payment.way.save.fail":"銀行信息保存失敗",
   "otc.customer.payment.way.save.exception":"銀行卡信息保存異常",
   "otc.customer.payment.way.none.exist":"銀行卡信息不存在",
   "otc.customer.payment.way.disable":"銀行卡信息已刪除",
   "otc.customer.payment.way.op.exception":"銀行卡信息獲取失敗",
   "otc.order.save.fail":"訂單保存失敗",
   "otc.order.duplicate":"訂單重複",
   "otc.order.none.exist":"訂單不存在",
   "otc.order.process.exception":"訂單處理失敗",
   "otc.order.state.invalid":"訂單狀態異常",
   "otc.order.update.fail":"訂單更新失敗",
   "otc.order.insufficient.balance":"帳戶餘額不足",
   "otc.account.none.exist":"帳戶不存在",
   "otc.order.invalid.param":"訂單參數錯誤",
   "otc.order.withdraw.amount.limit":"提現超額",
   "spot.order.success":"訂單成功",
   "spot.order.duplicate":"訂單重複",
   "spot.order.create.fail":"訂單創建失敗",
   "spot.order.symbol.invalid":"盤口無效",
   "spot.order.error":"下單失敗",
   "spot.order.user.account.insufficient":"餘額不足",
   "spot.entrust.account.insufficient":"餘額不足",
   "spot.entrust.user.account.insufficient":"餘額不足",
   "spot.entrust.cancel.fail":"現貨交易取消失敗",
   "spot.order.system.account.insufficient":"系統餘額不足",
   "spot.order.side.invalid":"參數無效",
   "spot.order.type.invalid":"訂單狀態無效",
   "spot.order.check.success":"现货订单校验成功",
   "spot.order.min.check":"訂單最小下單額不足=",
   "spot.order.max.check":"訂單超過最大下單額度",
   "lang":"繁體中文",
   "liquidatation_analysis":"全網爆倉統計",
   "liquidatation":"爆倉",
   "funding_rate":"當前資金費率",
   "liquidatation_volume":"爆倉總額",
   "long_liquidations":"多頭爆倉",
   "short_liquidations":"空投包倉",
   "long_short_ratio":"多空比",
   "data":"數據",
   "data_Indicator":"BTC市場情緒指數",
   "ten_thousand":"萬",
   "explore_txt_1":"最近24小時，共有",
   "explore_txt_2":"人爆倉",
   "explore_txt_3":"最大單筆爆倉單發生在",
   "worth":"價值",
   "8h":"最近8小時",
   "exchange_liquidatation":"交易所爆倉統計",
   "liquidatation_volume_30_days":"30日爆倉統計",
   "feed":"動態",
   "recent":"最新",
   "session_expire":"會話過期，請重試",
   "cfd.amount.less.than.order.min":"下單數量小於最小下單量",
   "cfd.amount.greater.than.order.max":"下單數量大於最大下單量",
   "mainStudy":"主圖",
   "secondStudy":"副圖",
   "index":"指標",
   "enable_now":"立即開啟",
   "finger_login":"指紋登錄",
   "faceid_login":"面容登錄",
   "not_now":"以後再說",
   "enable_finger_login":"開啟指紋登錄",
   "enable_faceid_login":"開啟面容登錄",
   "switch_account":"切換帳號",
   "verify_finger":"請驗證指紋",
   "verify_faceid":"請驗證面容ID",
   "try_again":"再試一次",
   "retry":"重試",
   "password_login":"密碼登錄",
   "password_updted":"密碼已修改",
   "use_pass_login":"密碼已修改，請使用帳號密碼登錄",
   "finger_verify":"指紋驗證",
   "enable_finger_login_ok":"指紋登入啟動成功",
   "enable_faceid_login_ok":"Face ID登入啟動成功",
   "enable_finger_login_fail":"指紋登入啟動失敗",
   "enable_faceid_login_fail":"Face ID登入啟動失敗",
   "verify_finger_login_fail":"指紋登入驗證失敗，請用密碼登入",
   "verify_faceid_login_fail":"Face ID登入驗證失敗，請用密碼登入",
   "enroll_finger_tip":"指紋解鎖尚未啟動，請在“系統設置”中的“Touch ID 與密碼”裏設置後再啟動。",
   "enroll_touchid_tip":"Face ID解鎖尚未啟動，請在“系統設置”中的“Face ID 與密碼”裏設置後再啟動。",
   "fcm.title.otc.withdraw.success":"",
   "fcm.body.otc.withdraw.success":"恭喜您成功出金 %{amount} [%{currency}] ，查看詳情>",
   "fcm.title.otc.withdraw.fail":"",
   "fcm.body.otc.withdraw.fail":"您的%{amount} [%{currency}] 提取失败，查看詳情>",
   "fcm.title.otc.deposit":"",
   "fcm.body.otc.deposit":"恭喜你成功充值 [%{currency}] %{amount}，了解詳情>>",
   "fcm.title.deposit":"",
   "fcm.body.deposit":"恭喜你成功充值 [%{currency}] %{amount}，了解詳情>>",
   "fcm.title.reward":"",
   "fcm.body.reward":"你的活動獎勵 [%{currency}] %{amount}，已經到帳，請查看～",
   "fcm.title.activity.reward":"",
   "fcm.body.activity.reward":"你的活動獎勵 [%{currency}] %{amount}，已經到帳，請查看～",
   "fcm.title.withdraw":"",
   "fcm.body.withdraw":"你的提幣 [%{currency}] %{amount} 已通過審核，開始鏈上轉帳，了解詳情>>",
   "fcm.title.blast.hour.exceed":"",
   "fcm.body.blast.hour.exceed":"[較高風險] 現時%{currency}的爆倉金額超過%{value} USD，點擊了解詳情>>",
   "fcm.title.blast.day.exceed":"",
   "fcm.body.blast.day.exceed":"[較高風險] 現時%{currency}的爆倉金額超過%{value} USD，點擊了解詳情>>",
   "fcm.title.longshort.exceed.alert":"",
   "fcm.body.longshort.exceed.alert":"[風險極高] 現時%{currency}多空比高於%{value}，點擊了解詳情>>",
   "fcm.title.longshort.exceed.tip":"",
   "fcm.body.longshort.exceed.tip":"[存在風險] 現時%{currency}多空比高於%{value}，點擊了解詳情>>",
   "fcm.title.longshort.less.alert":"",
   "fcm.body.longshort.less.alert":"[存在風險] 現時%{currency}多空比低於%{value}，點擊了解詳情>>",
   "fcm.title.longshort.less.tip":"",
   "fcm.body.longshort.less.tip":"[潛在風險] 現時%{currency}多空比低於%{value}，點擊了解詳情>>",
   "fcm.title.kyc.result.success":"",
   "fcm.body.kyc.result.success":"恭喜，你的身份信息已成功通過審核。查看詳情>>",
   "fcm.title.kyc.result.fail":"",
   "fcm.body.kyc.result.fail":"抱歉，你的身份信息沒有通過審核。查看詳情>>",
   "fcm.title.treasure.trail.interest":"",
   "fcm.body.treasure.trail.interest":"今日的理財體驗金利息已經到帳，馬上查看～",
   "fcm.title.investment.result.success":"",
   "fcm.body.investment.result.success":"您的委托定投已扣款成功~",
   "trade.margin.customer.risk.alert.title":"[Kikitrade]清算預警",
   "trade.margin.customer.risk.alert.content":"尊敬的用戶，您的帳戶${account}處於極高風險狀態，請注意風險控制。（提示：降低帳戶中虛擬貨幣的持倉或者入金可降低風險)",
   "trade.margin.customer.settle.title":"[Kikitrade]清算內容通知",
   "trade.margin.customer.settle.content":"尊敬的用戶，您的帳戶${account}已經發生撤單或強平，詳情查看“錢包”-右上”歷史紀錄”-“清算紀錄”",
   "redeem.code.expired":"兌換碼已經過期",
   "redeem.code.not.exist":"兌換碼不存在",
   "redeem.code.reach.limit":"此兌換碼已達兌換上限",
   "redeem.code.not.start":"此兌換碼尚未開始",
   "finger_lockout_use_pass_login":"當前指紋登錄錯誤次數過多，指紋登錄暫不可用。請使用密碼登錄。",
   "faceid_lockout_use_pass_login":"當前面容登錄錯誤次數過多，面容登錄暫不可用。請使用密碼登錄。",
   "finger_login_lockout":"指紋登錄不可用",
   "faceid_login_lockout":"面容登錄不可用",
   "lockout_finger_tip":"指紋登錄關閉，請在”系統設置-觸控ID和密碼”中，重新開啟。",
   "lockout_touchid_tip":"面容登錄關閉，請在”系統設置-面容ID和密碼”中，重新開啟。",
   "finger_lockout_permanent_use_pass_login":"當前指紋登錄錯誤次數過多，指紋登錄已終止，請在“系統設置-觸摸ID和密碼”中，重新開啟。",
   "finger_lockout_open_touchid":"當前指紋驗證錯誤次數過多，指紋驗證暫不可用。",
   "faceid_lockout_open_touchid":"當前FaceId驗證錯誤次數過多，Faceid驗證暫不可用。",
   "asset_saving_amount":"理財本金",
   "asset_interest_accured":"利息",
   "asset_annual_interest":"年化",
   "asset_isolated":"槓桿",
   "asset_equity_value":"資產估值",
   "asset_total_unrealized":"未實現盈虧",
   "asset_wallet_balance":"錢包餘額",
   "asset_total_balance":"資產估值",
   "common_error_view":"Sorry，本頁內容被飛碟帶走了…",
   "longPhoto":"長圖",
   "invite_friend_share_text":"Kikitrade是一個低入場門檻、安全可靠的加密貨幣社交投資理財平台。\n與我一起加入Kikitrade，開展簡單方便的投資旅程吧！🚀 \n經此連結下載，或註冊時記得輸入我的邀請碼，共享 HK$500 獎賞！\n",
   "activity_gift_card_text1":"Kikitrade 推出實體 Bitcoin 禮品卡,",
   "activity_gift_card_text2":"讓你以簡單三步真正手持比特幣!",
   "activity_gift_course":"立即查看使用教學",
   "activity_gift_virtual":"虛擬禮品卡",
   "activity_gift_physical":"實體禮品卡 （僅限香港地區）",
   "activity_gift_HKD":"港元",
   "activity_gift_purchase_type":"購買方式",
   "activity_gift_currency":"貨幣",
   "activity_gift_amount":"金額",
   "activity_gift_exchange_tip1":"你將會獲得約：",
   "activity_gift_exchange_tip2":"(實際按照兌換完成時間點計算)",
   "activity_gift_quantity":"數量",
   "activity_gift_sum":"合計: ",
   "activity_gift_total":"禮品卡每次購買總額不可超過$10000港元",
   "activity_gift_pay_tip":"請把{total} HKD 匯到以下帳戶，並上傳轉帳截圖",
   "activity_gift_payway":"選擇支付方式",
   "activity_account_FPS":"FPS",
   "activity_upload":"上傳轉帳截圖",
   "activity_recipient_info":"收件人資料",
   "activity_recipient_name":"收件人姓名",
   "activity_confirm":"確認",
   "activity_recipient_name_enter":"請輸入收件人姓名",
   "activity_gift_address":"收件人地址",
   "activity_gift_address_enter":"請輸入收件人地址",
   "activity_gift_telephone":"收件人聯絡電話",
   "activity_gift_telephone_enter":"請輸入收件人聯絡電話",
   "activity_gift_sender":"寄件人資料",
   "activity_gift_change":"（選填）",
   "activity_sender_name":"寄件人姓名",
   "activity_sender_name_enter":"請輸入寄件人姓名",
   "activity_gift_note":"留言 （不多於50個字元）",
   "activity_gift_note_enter":"請輸入對收件人的新年賀詞",
   "activity_purchase_virtual":"你購買了%{quantity}張 %{amount}港元的虛擬禮品卡，一共需要支付%{total}港元，請確認你已支付。",
   "activity_purchase_physical":"你購買了%{quantity}張 %{amount}港元的實體禮品卡，一共需要支付%{total}港元，請確認你已支付。",
   "activity_purchase_confirm":"確認付款",
   "activity_gift_purchase":"禮品卡購買",
   "activity_gift_exchanged":"禮品卡兌換",
   "activity_gift_single_amount":"單張金額",
   "activity_gift_card_type":"卡類型",
   "activity_gift_virtual_type":"虛擬卡",
   "activity_gift_look_code":"查看兌換碼",
   "activity_copy":"複製",
   "activity_exchanged":"已兌換",
   "activity_gift_physical_type":"實體卡",
   "activity_purchase_card":"購買禮品卡",
   "activity_desc":"如何獲得更多優惠？",
   "activity_desc1":"參加我們",
   "activity_desc2":" Facebook 活動",
   "activity_desc3":"可獲得更多免費獎賞！",
   "activity_gift_desc":"活動規則：",
   "activity_gift_desc1":"1. 禮品卡不記名和沒有到期日",
   "activity_gift_desc2":"2. 一經兌換，將不能重用或撤回兌換碼",
   "activity_gift_desc3":"3. 活動最終解釋權歸Kikitrade所有",
   "activity_purchase_help":"購買禮品卡幫助",
   "activity_exchange_title":"兌換禮品卡方法",
   "activity_exchange_step1_title":"Step 1: 登入",
   "activity_exchange_step1":"登入你的帳戶，如未有帳戶請先申請。",
   "activity_exchange_step2_title":"Step 2: 查看並輸入兌換碼",
   "activity_exchange_step2":"查看卡背的兌換碼並正確輸入兌換碼",
   "activity_exchange_step3_title":"Step 3: 成功獲取比特幣",
   "activity_exchange_step3":"成功登入後，便能獲得指定金額的比特幣",
   "activity_buy_success_virtual":"我們確認轉款成功後，會立即發送兌換碼。 你可以在”紀錄”中查看兌換碼。",
   "activity_buy_success_physical":"我們確認轉帳成功後，會馬上寄出禮品卡，並發送電郵和通知。你也可以隨時在\"紀錄“中查看快遞進度。",
   "activity_buy_success_desc2":"",
   "activity_buy_success_desc3":"",
   "activity_check_order":"查看訂單",
   "activity_buy_success_desc":"成功了，很簡單吧！",
   "activity_back_home":"回到首頁",
   "activity_buy_success_title":"訂購比特幣禮品卡",
   "activity_buy_limit":"購買總金額不能大於$10,000HKD",
   "activity_iknow":"明白了",
   "activity_confirm_paid":"我已付款",
   "activity_buy_desc1":"Kikitrade現提供「實體禮品卡」和「虛擬禮品卡」",
   "activity_buy_desc2":"助你極速以港幣充值投資比特幣或送給朋友。",
   "activity_buy_desc3":"新年紅包獎勵規則：",
   "activity_buy_desc4":"•所有訂購用戶都會額外獲得$8港元的紅包額;",
   "activity_buy_desc5":"•頭20訂購用戶會贈送同等價值的禮品卡一張;",
   "activity_buy_exchange_history":"購買 & 兌換記錄",
   "activity_exchanged_code":"兌換碼",
   "activity_Bitcoin_card":"Bitcoin 禮品卡",
   "activity_history_progress":"處理中",
   "activity_history_transfer":"轉帳成功",
   "activity_history_delivered":"已寄出",
   "activity_history_cancelled":"交易取消",
   "home_more":"更多",
   "home_buy_speed":"極速買幣",
   "home_buy_desc":"現金 / 加密貨幣充值",
   "home_lucky_draw":"活動中心",
   "home_lucky_desc":"日日賺不停",
   "home_gift_card":"禮品卡中心",
   "home_gift_desc":"購買/兌換比特幣",
   "home_data":"數據",
   "fcm.title.redeemcard.buy.result.confirm":"",
   "fcm.body.redeemcard.buy.result.confirm":"你購買的虛擬禮品卡交易成功。查看兌換碼 >>",
   "fcm.title.redeemcard.buy.physical.result.confirm":"",
   "fcm.body.redeemcard.buy.physical.result.confirm":"你購買的實體禮品卡交易成功。",
   "fcm.title.redeemcard.buy.result.delivered":"",
   "fcm.body.redeemcard.buy.result.delivered":"你購買的實體禮品卡已成功寄出。查看詳情 >>",
   "fcm.title.redeemcard.buy.result.fail":"",
   "fcm.body.redeemcard.buy.result.fail":"你購買的禮品卡交易被取消了。查看詳情 >>",
   "follow_order_confirm":"跟單確認",
   "follow_order_confirm_tip":"由於交易員的跟單策略和初始持倉數據的統計差異，有機會導致可能跟單後和交易員的數據出現偏差。",
   "iKonw_1":"明白",
   "binance_api_tip1":"Binance暫時只支持現貨交易，以及永續合約的API接入。",
   "binance_api_tip2":"暫不支持槓桿交易和其他類型的交易，否則可能會數據統計不準確的問題。",
   "okex_api_tip1":"Okex暫時只支持現貨交易，以及永續合約的API接入。",
   "okex_api_tip2":"暫不支持槓桿交易及其它類型的交易，否則可能會出現數據統計不准確的問題。",
   "ftx_api_tip1":"FTX只支持永續合約的API接入",
   "ftx_api_tip2":"暫不支持現貨交易，槓桿交易和其他類型交易，否則可能會數據統計不準確的問題。",
   "bitmex_api_tip1":"Bitmex只支持永續合約的API接入。",
   "bitmex_api_tip2":"暫不支持現貨交易，槓桿交易和其他類型交易，否則可能會數據統計不準確的問題。",
   "api_integration_intro":"API接入說明",
   "lottery_tip1":"贏得人生第一枚比特幣",
   "lottery_tip2":"快來贏得人生第一枚比特幣吧！",
   "lottery_tip3":"免費送你",
   "lottery_tip4":"給好友送幣，可獲得免費抽取機會",
   "lottery_tip5":"當前有%{chance}次抽獎機會",
   "lottery_tip6":"當前贏取的比特幣",
   "lottery_tip7":"拿走比特幣",
   "lottery_tip8":"下載Kiki打開比特幣錢包",
   "lottery_tip9":"送幣給好友",
   "lottery_tip10":"邀請壹位好友，抽獎+1",
   "lottery_rule":"活动規則",
   "lottery_rule_1":"每個新用戶在登录前有三次抽取機會。注册成功后，增加一次抽奖机会。",
   "lottery_rule_2":"每邀請一位好友注册，可增加一次抽獎機會。邀请人数最多的前%{rank}位用户，可一同瓜分%{quantity}個比特幣（活动结束后发放至账户中）。",
   "lottery_rule_3_1":"抽中%{num}个",
   "lottery_rule_3_2":"可获得%{quantity}个比特幣",
   "lottery_rule_4":"獲得的比特幣，需要在Kikitrade App內查看並使用，詳細的使用方法，請下載Kikitrade。",
   "lottery_invite_rank":"邀請排行榜",
   "lottery_my_invite":"我的邀請",
   "lottery_user":"用户",
   "lottery_invite":"邀请",
   "lottery_share_txt":"分享鏈接複製成功，快去發給朋友把！",
   "lottery_warm_tip":"友情提示",
   "lottery_reg_tip":"註冊kikitrade后，可拥有自己专属的邀请链接。成功邀请好友可增加抽獎次數",
   "lottery_reg":"註冊kikitrade",
   "lottery_tip11":"抽獎次數已用完，邀請好友可增加抽獎次數",
   "lottery_win_prize":"恭喜您獲得%{quantity}個比特幣",
   "lottery_tip12":"恭喜獲得",
   "lottery_tip13":"您的比特幣已放入Kikitrade錢包裡，下載App拿走屬於你的比特幣吧！",
   "lottery_download_app":"下載APP",
   "lottery_return_home":"返回首页",
   "lottery_rank":"排名",
   "lottery_share_btc":"可瓜分比特幣",
   "lottery_tip15":"我發了一個比特幣紅包給你",
   "lottery_person":"人",
   "login_other_way":"其他登入方式",
   "login_open_auth_process":"正在登錄中..",
   "need.binding.email":"請填寫電郵",
   "email_binding":"填寫電郵",
   "login_oauth_cancel":"認證取消",
   "login_oauth_fail_retry":"登錄失敗",
   "login_oauth_not_support_apple":"目前版本不支持Apple帳號登錄",
   "customer.login.3rd.email.exist":"此電郵已經註冊過Kikitrade帳號",
   "customer.login.3rd.token.error":"登錄失敗(1105)",
   "login_oauth_timeout":"登錄超時",
   "fiat_transfer_confirm_title":"確認離開匯款",
   "fiat_transfer_confirm_desc1":"如你已經完成匯款，請必須提交轉帳憑證",
   "fiat_transfer_confirm_desc2":"我確定還沒有匯款",
   "fiat_transfer_confirm":"確認離開",
   "settings_rate":"匯率設定",
   "common_usd":"美元",
   "common_hkd":"港元",
   "apply_trader_desc1":"申請成為大獅，即可獲取高達",
   "apply_trader_desc2":"跟單人數越多，分紅越多！",
   "apply_master_welfare":"成為大獅福利",
   "apply_master_desc1":"申請並接入API可獲得高達100USDT",
   "apply_master_desc2":"第一個月正回報率再可獲得100USDT",
   "apply_master_desc3":"其他用戶跟單可分紅利",
   "apply_contact_way":"聯繫方式 (電話)",
   "apply_input_ph":"請輸入你的聯絡方式",
   "apply_submit":"大獅來了",
   "apply_item_title":"申請條款",
   "aplly_item1":"2. 成功接入API後即可獲得50 USDT, 接入10天後獲得25 USDT，接入20天後獲得25 USDT",
   "aplly_item2":"3. 當申請人數滿額後，獎勵金額或會改變\n(詳情請看Kikitrade公告)",
   "aplly_item3":"4. 如有任何爭議，Kikitrade保留最終決定權",
   "aplly_item4":"1. 提交申請後會有專人透過電郵或電話聯繫你。請留意電郵(垃圾電郵等)。如果沒有收到郵件，可以聯繫 info@kikitrade.com 或客服",
   "apply_item5":"點擊這裡。",
   "api_contract":"槓桿",
   "api_spot":"現貨",
   "api_info_title":"API信息",
   "api_range_title":"統計範圍",
   "api_range_desc":"請選擇實際有操作的交易方式。如果選擇尚未進行的操作，有可能造成API接入失敗或統計數據不準確",
   "api_way_title":"統計範圍出錯",
   "api_way_desc":"你尚未開通合約交易，API無法統計相關數據。請選擇不帶合約的統計方式。",
   "api_way_rechoose":"重新選擇",
   "market_entrust_cancel":"取消",
   "market_entrust_counts":"委託數量",
   "market_entrust_amount":"委託金額",
   "market_entrust_total":"委託總額",
   "market_entrust_price":"委託價格",
   "market_entrust_type":"委託類型",
   "market_entrust_fee":"手續費",
   "market_entrust_cancel_success":"取消成功",
   "market_entrust_limit":"限價委託",
   "market_entrust_market":"市價委託",
   "market_order_limit":"限價單",
   "market_order_market":"市價單",
   "market_status":"交易狀態",
   "market_status_success":"交易成功",
   "market_status_cancel":"交易取消",
   "market_status_failed":"交易失敗",
   "market_entrust_success":"委託成功",
   "market_entrust_failed":"委託失敗",
   "huobi_api_tip1":"HUOBI暫時只支持現貨交易，以及永續合約的API接入。",
   "netConnectError":"網絡連接失敗",
   "pullDownToRefresh":"向下拉動重新整理",
   "releaseToRefresh":"放開重新整理",
   "refreshingText":"重新整理中",
   "airplaneTip":"請關閉飛行模式或使用WiFi模式进行连接",
   "home_buy_speed_activity_1":"感恩入金回贈",
   "buyCrpotoActivity_detail":"活動詳情",
   "home_buy_desc_activity_1":"感恩用戶支持，入金多送更多",
   "liveness_desc_1":"人臉識別檢測提示",
   "liveness_desc_2":"如有配戴眼鏡，請除下眼鏡進行人臉識別檢測。",
   "timeout.error":"網絡緩慢，請稍後重試",
   "login.network.error":"網絡異常，請稍後重試",
   "login.timeout.error":"登錄失敗，請稍後重試",
   "new_activity_gift_card_text1":"Kikitrade祝大家新年快樂，牛市行大運！現已推出新年賀年比特幣紅包，頭20位訂購會多送一封，送禮自用兩相宜。",
   "activityCenter":"活動中心",
   "activity1":"活動",
   "activityDetail":"活動詳情",
   "activityIntro":"活動聲明",
   "activityVIP":"VIP 活動",
   "TrialBonusTitle":"500USDT免費體驗活動",
   "TrialBonusTip1":"1. 活動期間（2021.01.27-2021.02.06），新用戶註冊即送500USDT體驗金",
   "TrialBonusTip2":"2. 體驗理財年利率為14%，用戶將享有為期14天體驗金理財利息",
   "TrialBonusTip3":"3. 體驗理財利息會在註冊後次日自動發放。",
   "TrialBonusTip4":"4. 利息會發放到用戶現貨USDT資產帳戶，點擊下方的【查看我的帳戶】查看，亦可透過 \"我的-我的資產-現貨\"查看。",
   "TrialBonusTip5":"1. 體驗金只用於體驗KIKI理財服務，不會顯示在用戶的資產帳戶裡",
   "TrialBonusTip6":"2. 任何有關活動的問題請詢問KIKI客服，點擊",
   "TrialBonusTip7":"3. KIKITRADE官方享有活動內容的最終解釋權，針對違規行為用戶我們將有權進行處理",
   "TrialBonusTip8":"注册领取体验金",
   "TrialBonusTip9":"等你領取",
   "TrialBonusTip10":"体验中",
   "TrialBonusTip11":"理財體驗金",
   "TrialBonusTip12":"我的利息",
   "TrialBonusTip13":"已到帳",
   "TrialBonusTip14":"剩余天數",
   "TrialBonusTip15":"喂～你已經是我們的好朋友！",
   "TrialBonusTip16":"邀請好友領取體驗金，還可賺取高額獎勵金",
   "TrialBonusTip17":"查看我的資產",
   "TrialBonusTip18":"到帳日曆",
   "TrialBonusTip19":"邀請好友贏取獎勵金",
   "TrialBonusTip20":"成功邀請一位好友，",
   "TrialBonusTip21":"已失效",
   "TrialBonusTip22":"可贏取價值$20HKD的BTC",
   "TrialBonusTip23":"邀請",
   "TrialBonusTip24":" Kikitrade 客服",
   "TrialBonusTip25":"邀請說明",
   "TrialBonusTip26":"1. 好友只要通過你的邀請碼註冊Kiki並進行KYC後，你和好友都可免費獲得比特幣(價值港幣$20)",
   "TrialBonusTip27":"2. 只有當好友成功通過KYC後，才被視為成功邀請，你和好友才可獲得獎勵",
   "TrialBonusTip28":"3. 實際獲得的BTC金額，會按當時的BTC/HKD的市價計算",
   "TrialBonusTip29":"4. KIKITRADE官方享有活動內容的最終解釋權，針對違規行為用戶我們將有權進行處理。",
   "eventCenterTitle":"Kikitrade 活動中心開張大吉！",
   "eventCenterDesc":"各種福利活動等你領取，每日期待新驚喜！",
   "moreEvent":"更多活動，敬請期待",
   "NewCarouselViewTip1":"彈性投資",
   "NewCarouselViewTip2":"入金輕鬆無限制，買幣只需 1 美元",
   "NewCarouselViewTip3":"社交結合投資",
   "NewCarouselViewTip4":"加入二十萬人社區，",
   "NewCarouselViewTip5":"投資路上有伴不孤單",
   "NewCarouselViewTip6":"靈活理財",
   "NewCarouselViewTip7":"高達 8% 穩定幣年利率，\n Kikitrade 助你實現財務自由",
   "NewCarouselViewTip8":"與 KIKI 一起探索加密貨幣",
   "NewCarouselViewTip9":"立即註冊，\n體驗即時加密貨最新快訊及數據追蹤！",
   "CarouselViewTip10":"註冊新帳號",
   "CarouselViewTip11":"已有KIKI帳號？",
   "CarouselViewTip12":"登入",
   "freeTrialFund":"送體驗金",
   "treasure.transfer.amount.min.less":"小於最小理財金額",
   "originText_deleted":"抱歉，原文已刪除~",
   "originText_deleted1":"抱歉，該貼文已經刪除~",
   "announcement":"官方公告",
   "eventAward":"活動獎勵",
   "eventAward1":"活動獎勵已發放，查看帳戶詳情>",
   "interest1":"理財利息已經到帳，請查看帳戶詳情",
   "depositOtc":"入金",
   "depositOtcSuccess":"入金成功",
   "withdrawOtc":"出金",
   "withdrawOtcSuccess":"出金成功",
   "withdrawOtcFail":"出金失敗",
   "noticeCenter":"消息中心",
   "clearUnreadNotice":"已讀所有訊息",
   "clear":"確定",
   "originText_blocked":"該貼文已不存在",
   "viewAll":"查看更多",
   "emailLogin":"電郵登錄",
   "mobileLogin":"手機登入",
   "emailReg":"信箱註冊",
   "mobileReg":"手機註冊",
   "mobileVerification":"手機驗證",
   "verifyCodeSendToMobile":"驗證碼已發送到手機 %{mobile}",
   "enterRegion":"請輸入國家或地區",
   "enterMobileVerificationCode":"請輸入手機驗證碼",
   "mobileNumber":"手機號碼",
   "mobileNumberFormatError":"手機輸入錯誤",
   "KycTokenErrorCode418":"當日臉部檢測次數過多，請明天再試",
   "KycTokenErrorCode500":"系統內部錯誤，請通知工程師排查。",
   "KycTokenErrorCode414":"目前手機不支持臉部檢測驗證，請用另一部手機試試。",
   "KycLivenessErrorCodeZ1001":"系統錯誤",
   "KycLivenessErrorCodeZ1003":"驗證中斷",
   "KycLivenessErrorCodeZ1008":"驗證中斷",
   "KycLivenessErrorCodeZ2002":"網絡錯誤",
   "KycLivenessErrorCodeZ2003":"客戶端設備時間錯誤",
   "KycLivenessErrorCodeZ2006":"刷臉失敗",
   "KycLivenessErrorCodeZ1004":"Android系統版本過低",
   "KycLivenessErrorCodeZ1005":"刷臉超時(單次)",
   "KycLivenessErrorCodeZ1006":"多次刷臉超時",
   "KycLivenessErrorCodeZ1018":"無前置攝像頭",
   "KycLivenessErrorCodeZ1012":"請稍後重試",
   "KycLivenessErrorCodeZ1019":"攝像頭權限未賦予",
   "KycLivenessErrorCodeZ1020":"打開攝像頭失敗",
   "KycLivenessErrorCodeZ1024":"SDK認證流程正在進行中，請等待本地認證流程完成後再發起新調用",
   "KycLivenessErrorCodeZ5112":"上傳炫彩Meta信息失敗",
   "KycLivenessErrorCodeZ5113":"上傳炫彩視頻失敗",
   "KycLivenessErrorCodeZ6001":"OCR識別次數超限",
   "KycLivenessErrorCodeZ6002":"OCR圖片上傳網絡超時",
   "KycLivenessErrorCodeZ6003":"OSS Token過期",
   "KycLivenessErrorCodeZ6004":"人臉照片處理失敗",
   "KycLivenessErrorCodeZ5114":"用戶點擊Home鍵",
   "KycLivenessErrorCodeZ1011":"客戶端初始化網絡錯誤。",
   "KycLivenessErrorCodeZ1025":"客戶端初始化接口返回網絡錯誤。",
   "KycLivenessErrorCodeZ1026":"信息上傳網絡錯誤。",
   "KycLivenessErrorCodeZ1027":"服務端認證接口網絡錯誤。",
   "KycLivenessErrorCodeIOSZ1001":"拒絕開通相機權限",
   "KycLivenessErrorCodeIOSZ1002":"無法啟動相機",
   "KycLivenessErrorCodeIOSZ1005":"刷臉超時(單次)",
   "KycLivenessErrorCodeIOSZ1006":"多次刷臉超時",
   "KycLivenessErrorCodeIOSZ1008":"用戶主動退出認證",
   "KycLivenessErrorCodeIOSZ1000":"抱歉，系統出錯了，請您稍後再試",
   "KycLivenessErrorCodeIOSZ1007":"本地活體檢測出錯",
   "KycLivenessErrorCodeIOSZ1147":"本地活體檢測出錯",
   "KycLivenessErrorCodeIOSZ1146":"本地活體檢測出錯",
   "retryTakePhoto":"重新拍照",
   "takeIdTip1":"請將證件緊貼框內四角拍攝",
   "uploadPassport":"請上傳護照",
   "uploadHongKongId":"請上傳身分證（香港）",
   "mobileNumber1":"手機號碼",
   "twdFiat":"TWD 充值/提款",
   "twdFiatOny":"只適用於台灣用戶",
   "twdFiatTerms":"台幣 OTC 交易須知",
   "twdFiatTermsHasRead":"我已閱讀",
   "twdFiatTip":"您可通過 TWD 來買入或賣出 USDT。本次交易，由 Zomfast 提供交易服務。交易有風險，請仔細閱讀交易須知。",
   "twdFiatStart":"開始交易",
   "KikiBonusRegText1":"註冊即送500USDT理財體驗金",
   "KikiBonusRegText2":"利息每天自動到帳",
   "hasDone":"已完成",
   "hasExpired":"已無效",
   "goParticipate":"立即做",
   "KikiBonusTitle":"Kikitrade三重賞",
   "KikiBonusTitle1":"Kikitrade三重賞，BTC, USDT等你拿",
   "KikiBonusIniviteTitle":"邀请好友赚奖励金",
   "KikiBonusIniviteText1":"邀請一位好友",
   "KikiBonusIniviteText2":"即可獲得$20 HKD等值的BTC",
   "KikiBonusFiatTitle":"充值回贈獎勵",
   "KikiBonusFiatText1":"在Kiki充值",
   "KikiBonusFiatText2":"最高獲得XXUSDT獎勵",
   "LoginNow":"立即登錄",
   "referredFriends1":"已邀",
   "moreComment":"查看更多評論",
   "unBindSuccess":"解除綁定成功",
   "changeBindMobileSuccess":"更換綁定手機號碼成功",
   "changeBindEmailSuccess":"更換綁定電郵成功",
   "bindSuccess":"綁定成功",
   "changeBindMobile":"換綁手機號",
   "bindMobile":"手機號碼綁定",
   "enterMobile":"請輸入手機號碼",
   "unBindTip":"該登入帳號是註冊時候使用，不能更換或解除綁定",
   "changeBindMobile1":"更換綁定手機號碼",
   "unBindMobile":"解除綁定手機號碼",
   "changeBindEmail1":"更換綁定電郵",
   "unBindEmail":"解除綁定電郵",
   "changeBindMobileTip":"確定將綁定手機號碼更換為%{mobile}？",
   "changeBindEmailTip":"確定將綁定電郵更換為%{email}？",
   "unBindMobileTip":"確定要解綁手機號碼%{mobile}？",
   "unBindEmailTip":"確定要解綁電郵%{email}？",
   "unBindMobileTip1":"解除綁定後，你將不能使用該手機號碼登入，接收重要通知",
   "unBindEmailTip1":"解除綁定後，你將不能使用該電郵登入，接收重要通知",
   "cancelChangeBind":"你已取消更換綁定手機號碼",
   "cancelChangeBindEmail":"你已取消更換綁定電郵",
   "cancelUnbind":"取消解除綁定",
   "changeBindEmail":"換綁電郵",
   "bindEmail":"電郵綁定",
   "emailCodeTip":"如果沒有找到驗證碼電郵，請檢查垃圾電郵或重新發送",
   "bindTip1":"為提高帳號安全性，請綁定手機號碼或開啟谷歌驗證",
   "bindTip2":"為提高帳號安全性，請綁定電郵或開啟谷歌驗證",
   "unBind":"未綁定",
   "bindEd":"已綁定",
   "twdFiatTip1":"根據註冊地區的相關規定，無法進行該操作",
   "bankCardToCrypo":"銀行卡轉帳買入加密貨幣",
   "crypoDeposit":"已有加密貨幣充值",
   "inviteFriendText1":"邀請好友",
   "inviteFriendText2":"立即邀請好友",
   "inviteFriendText3":"大升級",
   "inviteFriendLoginTxt":"後邀請好友",
   "assetTxt1":"理財大升級，收益暴漲",
   "assetTxt2":"點擊瞭解詳情",
   "OldReferredRewardHead":"旧版邀请活动（奖励BTC）",
   "ReferredReward":"獎勵金",
   "fiatServiceTimeTip1":"您的申請預計會在48小時內處理完成。但由於行情帶來的流量，我們有大量的待審核任務，您的處理可能會延遲。",
   "fiatServiceTimeTip2":"您的申請將於下一個銀行工作天處理完成。但由於行情帶來的流量，我們有大量的待審核任務，您的處理可能會延遲。",
   "fiatServiceTimeTip3":"您的申請預計會在48小時內完成處理。但由於行情帶來的流量，我們有大量的待審核任務，您的處理可能會延遲。",
   "fiatServiceTimeTip4":"您的申請預計會在今日10am前完成處理。但由於行情帶來的流量，我們有大量的待審核任務，您的處理可能會延遲。",
   "fiatServiceTimeTip5":"您的申請預計會在1小時內完成處理。但由於行情帶來的流量，我們有大量的待審核任務，您的處理可能會延遲。",
   "fiatServiceTimeTip6":"您的申請預計會在72小時內處理完成。但由於行情帶來的流量，我們有大量的待審核任務，您的處理可能會延遲。",
   "expectedFinishTime":"預計完成時間：",
   "submitApplyTime":"提交申請時間",
   "nextBankWorkHour":"下一個銀行工作天",
   "otc.order.deposit.frequency.limit":"提交頻繁，請15分鐘後再試",
   "forceUpdate.cancel":"取消",
   "forceUpdate.update":"升級",
   "forceUpdate.newVersionUpdate":"版本更新",
   "forceUpdate.desc":"您需要升級到最新版本，才可以正常使用。",
   "forceUpdate.latestVersion":"最新版本",
   "forceUpdate.updateContent":"更新內容",
   "savings":"理財",
   "flexibleSavings1":"活期理財",
   "fixedInvestmentPlan":"定投計畫",
   "lockUpPeriod":"鎖倉期限",
   "anytimeDepositWithdrawal":"隨存隨取",
   "todayAPYReference":"今日參考年化收益",
   "buyNow":"立即搶購",
   "autoPurchase":"自動申購",
   "purchase":"申購",
   "fixedCurrency":"定投幣種",
   "historicalRateReturn":"歷史收益率",
   "participateInFixedInvestment":"參與定投",
   "automaticBalanceTransferInvitation":"餘額自動轉入邀請",
   "withdrawBenefits":"隨時提取 輕享收益",
   "inviteSavingTip1":"開啟後，每日會自動將帳戶餘額轉入活期理財，享受最高 10.85% 年化收益。",
   "saveTimeWorry":"省時省心",
   "inviteSavingTip2":"餘額自動轉入賺取收益",
   "security":"安全保障",
   "inviteSavingTip3":"資金安全放心，明細可查",
   "turnOnAutoTransfer":"開啟餘額自動轉入",
   "notOpen":"暫不開啟",
   "autoPurchaseAll":"自動申購所有活期產品",
   "autoPurchaseTip1":"開啟自動申購後，會在每日 7:00~9:00 (HKT)，系統將使用您帳戶中所有可申購的餘額，自動申購此活期理財產品。",
   "turnOnAutoPurchase":"開啟自動申購",
   "autoPurchaseTip2":"您正在開啟 %{currency} %{num}個市場的自動申購功能，請確認！",
   "haveTurnOnAutoPurchase":"您已打開自動申購",
   "haveTurnOnAutoPurchaseTip1":"您已將全部市場的自動申購功能開啟，無需重複操作。如需關閉某個市場的自動申購，請下滑找到該市場並手動關閉",
   "purchaseAmount":"申購金額",
   "accountBalance1":"帳戶可用",
   "interestTime":"計息時間",
   "referenceAPY":"参考APY",
   "confirmPurchase":"確認申購",
   "confirmPurchaseTerms":"點擊“確認申購”按鈕則代表您已閱讀並同意",
   "kikiSavingsTerms":"KIKI財富服務協議",
   "purchaseAmount1":"申購數量",
   "purchaseTime":"申購時間",
   "purchaseSuccess":"申購成功",
   "purchaseFail":"申購失敗",
   "redeemAmount":"赎回数量",
   "redeemTime":"赎回时间",
   "redeemSuccess":"贖回成功",
   "redeemFail":"贖回失敗",
   "redeem":"贖回",
   "redeemCurrency":"贖回幣種",
   "redeemAmount1":"贖回額度",
   "canRedeemAmount":"可贖回",
   "interestEarned":"產生利息",
   "savingAPY":"存款APY",
   "redeemConfirm":"確定贖回",
   "redeemConfirmTip1":"贖回額度於今日達到現貨資產，請注意查收",
   "AutoBuyDetailTip1":"定投計畫，是通過定期的投資計畫，定期對數字資產投資的計畫型策略。將多種數字資產通過一定的比例組合在一起，定期自動進行買入的一種投資方式。",
   "AutoBuyDetailTip2":"普遍使用，省時省力",
   "AutoBuyDetailTip3":"從短期來看，數字幣市場是不可預測的，但從長遠來看，優質的數字貨幣是看漲的。但我們很難抉擇在何時入場及購買哪種數字貨幣。因此，定投ETF為您提供了良好的解決方案：",
   "AutoBuyDetailTip4":"平攤風險，聚沙成塔",
   "AutoBuyDetailTip5":"常見的定投方式是按月投。也就是每月將一部分資金通過積攢的形式投入。通過常年累月的資金積累加上複利效應。最終使財富增值。也避免了一次性大額投資所帶來的的高風險。",
   "AutoBuyDetailTip6":"點擊收起",
   "AutoBuyDetailTip7":"瞭解更多",
   "AutoBuyDetailTip8":"定投的方法是適合絕大多數的普通投資者的。我們為您篩選出的貨幣均適合長期定投，您可以選擇幾種打包，並設置定投週期和金額，就會按要求定期投資。",
   "change":"更改",
   "averageAPY3Years":"均衡投資前3年平均年化",
   "easy3Steps":"接下來將通過簡短的3步",
   "setUpYourRegularInvesmtentPlan":"設置您的定期購買計畫",
   "comboRatio":"組合比例",
   "amountAndCycle":"金額及週期",
   "confirmAutoPurchase":"確認定投計畫",
   "regularInvestmentPlanSetting":"設置定投計畫",
   "currencyAndRatio":"貨幣及比例",
   "confirmInvestCurrecnyRatio":"確定投資幣種",
   "ratio":"比例",
   "confirmSaving":"確定投資",
   "autoCircle":"定投週期",
   "confirmRegularInvestment":"確認定投計畫",
   "autoAmount":"定投金額",
   "savingDistribution":"投資分佈",
   "amountPerCycle":"每期投入金額",
   "purchaseFee":"認購費率",
   "autoTransferTip1":"定投自動轉入活期理財",
   "autoTransferTip2":"定投轉入活期理財",
   "autoTransferTip3":"選中後，如果定投所得幣種，為活期理財支持的幣種，則自動轉入活期理財。",
   "nextInvestDay":"最近扣款日",
   "readAndAgree":"我已閱讀並同意",
   "readAndAgreeModal":"我已閱讀並同意",
   "savingsTerms1":"《KIKI定投使用協議》",
   "confirmAndEffect":"確認並生效",
   "agreeSavingsTerms1":"請同意KIKI定投使用協議",
   "toReadAndAgreeSavingsTerms1":"請閱讀並同意",
   "myPosition":"我的持倉",
   "allPosition":"全部持倉",
   "allPositionValue":"全部持倉估值",
   "allAccountValue":"總賬戶資產估值",
   "yestordayProfit":"昨日收益",
   "yestordayProfitValue":"昨日持倉收益",
   "allProfit":"總收益",
   "flexibleSavings":"活期",
   "regularInvestment":"定投",
   "positionAmount":"持倉數量",
   "currentAPY":"當前APY",
   "autoPurchaseRate":"定投收益率",
   "autoPurchaseTotalAmount":"累計定投",
   "autoPurchaseAmount":"定投金額",
   "running":"進行中",
   "paused":"已暫停",
   "flexibleSavingsDetails":"活期理財明細",
   "positionAmount1":"持倉額度",
   "basicInformation":"基本信息",
   "buyAmount":"投入金額",
   "cumulativeEarns":"累計利息",
   "purchaseHistory":"購買歷史",
   "purchaseMore":"追加申購",
   "orderType":"交易類型",
   "investmentDetails":"投資明細",
   "confirmTime":"確認時間",
   "contractId":"合同編號",
   "investFee":"手續費",
   "investAmount":"投資數量",
   "fundInfo":"基金信息",
   "feeType":"費用項",
   "regularInvestDetails":"定投明細",
   "investHistory":"定投歷史",
   "stopAndDelete":"終止並刪除此計畫",
   "pauseInvest":"暫停定投",
   "resumeInvest":"恢复定投",
   "earnHistory":"理財歷史記錄",
   "startTime1":"開始時間",
   "endTime":"結束時間",
   "singlePrice":"單價",
   "purchaseFee1":"申購費",
   "redeemFee":"贖回費",
   "withdraw1":"撤回",
   "interest":"計息",
   "purchase1":"認購",
   "distribution":"分發",
   "money1":"金額",
   "daily":"每天",
   "weekly":"每週",
   "biWeekly":"每月2次",
   "monthly":"每月",
   "dailyAutoInvest":"每天自動投資",
   "weeklyAutoInvest":"每週一自動投資",
   "biWeeklyAutoInvest":"每月的1日和15日進行自動投資",
   "monthlyAutoInvest":"每月的1日進行自動投資",
   "turnOffAutoTransfer":"關閉自動轉入活期",
   "pauseInvest1":"暫停此定投計畫",
   "resumeInvest1":"恢復此定投計劃",
   "stopAndDeleteTip1":"終止並刪除後，此定投計畫會徹底刪除，不再出現於您的定投計畫列表中。",
   "pauseInvestTip1":"暫停後將不會繼續執行定投並且會重新計算此定投計劃的收益數據",
   "stopInvestTip1":"停止後將不享受活期利息和費率減免優待",
   "resumeInvestTip1":"恢復後將會繼續執行定投並且會重新計算此定投計劃的收益數據",
   "stopAndDelete1":"終止並刪除",
   "stopTransfer":"停止轉入",
   "tempNotClose":"暫不關閉",
   "chooseCurrency":"選擇貨幣",
   "currency":"幣種",
   "namePlan":"自定義定投名稱",
   "remarkPlan":"備註此定投的名稱為：",
   "autoInvestInterval":"定投投資間隔",
   "financing.product.param.invalid":"創建理財產品參數錯誤",
   "financing.product.not.exist":"該理財產品不存在或已下線",
   "financing.contract.not.exist":"尚未申購該活期理財產品",
   "financing.contract.close.auto.subscribe.fail":"關閉自動申購失敗",
   "financing.contract.not.open.auto.subscribe":"自動開啟申購失敗",
   "financing.contract.subscribe.fail":"申購失敗",
   "financing.contract.insufficient.balance":"帳戶餘額不足",
   "financing.subscribe.amount.less.than.min":"申購金額小於最小限額",
   "financing.subscribe.amount.more.than.max":"申購金額大於最大限額",
   "financing.subscribe.amount.not.increment":"申購金額必須是xxx的倍數",
   "financing.subscribe.success":"申購成功",
   "financing.contract.redeem.fail":"贖回失敗",
   "financing.account.amount.incorrect":"帳務異常, 請稍後重試",
   "financing.contract.revoke.fail":"撤銷申購失敗",
   "financing.subscribe.auto.success":"自動申購開啟成功",
   "financing.subscribe.auto.fail":"自動申購開啟失敗",
   "financing.product.already.exist":"理財產品已存在",
   "financing.revoke.fail":"撤銷失敗",
   "financing.bearing.fail":"計息失敗",
   "financing.product.already.exist.auto.subscribe":"已經開啟了自動申購",
   "investment.product.param.invalid":"創建定投產品參數錯誤",
   "investment.product.not.exist":"定投產品不存在或已下線",
   "investment.product.proportion.invalid":"定投比例不支持",
   "investment.product.pay.option.invalid":"定投支付選項不支持",
   "investment.subscribe.amount.less.than.min":"定投金額小於最小限額",
   "investment.subscribe.amount.more.than.max":"定投金額大於最大限額",
   "investment.subscribe.amount.not.increment":"定投金額必須是xxx的倍數",
   "investment.subscribe.period.invalid":"定投週期不支持",
   "investment.contract.not.exist":"還未有定投計劃",
   "investment.contract.name.format.invalid":"定投計劃名稱格式錯誤",
   "investment.contract.status.not.running":"定投計劃已停止",
   "investment.contract.status.not.working":"定投計劃已失效",
   "financing.operate.fail":"操作失敗",
   "fcm.body.investment.result.fail":"定投計畫失敗",
   "fcm.body.investment.result.fail.user.invalid":"定投計劃用戶錯誤",
   "fcm.body.investment.result.fail.balance.insufficient":"定投失敗，餘額不足",
   "earn.transfer.fail.need.upgrade":"轉帳失敗，需要升級版本",
   "treasure.transfer.fail.need.upgrade":"轉帳失敗，需要升級版本",
   "noFlexRecord":"尚未申購任何活期產品",
   "noAutoRecord":"尚未申購任何定投產品",
   "noEarningRecord":"尚未申購任何理財產品",
   "investment.contract.plan.already.effective":"定投計畫已生效",
   "investment.contract.enough.balance.invest":"請保持資金充足以確定定投可以順利執行",
   "minPurchaseAmount":"最小申購額度為%{amount} %{currency}",
   "exceedBalance":"超過可用餘額%{amount} %{currency}",
   "maxPurchaseAmount":"超過最大申購額度%{amount} %{currency}",
   "onlyMultiple":"只能是%{amount}的倍數",
   "exceedRedeemAmount":"超過可贖回額度%{amount} %{currency}",
   "pleaseSelect":"請選擇",
   "turnOffAutoTransferSuccess":"關閉自動轉入活期成功",
   "turnOnAutoTransferSuccess":"開啟自動轉入活期成功",
   "pauseInvestSuccess":"暫停定投成功",
   "resumeInvestSuccess":"恢復定投成功",
   "renameSuccess":"修改名稱成功",
   "stopAndDeleteSuccess":"終止並刪除成功",
   "otc.customer.remark.code.op.exception":"獲取備註碼出錯",
   "otc.customer.remark.code.consume.exception":"消費備註碼出錯",
   "depositRemark":"轉帳識別碼",
   "turnOnAutoBuyFlexSuccess":"開啟自動申購成功",
   "turnOffAutoBuyFlexSuccess":"關閉自動申購成功",
   "turnOffAutoBuyInvestmentSuccess":"關閉自動定投成功",
   "startNotOverEndTime":"起始時間不能大於結束時間",
   "fillInForm":"填寫表格",
   "aip.btc.1620892282130":"定投比特計畫",
   "aip.eth.1620895592746":"定投以太計畫",
   "aip.doge.1620895784252":"定投狗狗計畫",
   "product.key":"定投比特計畫",
   "eip.eth001.key":"定投以太計畫",
   "product.key2":"定投以太計畫",
   "product.key1":"定投狗狗計畫",
   "AccountSuspenseTip":"由於第三方國際風險系統檢測到您的個人資料屬潛在高風險類別，基於本平台的《用戶服務協議》和有關的監管制度和條例下，本平台未能為您提供服務，您的帳戶將會被暫時凍結。請您填寫以下表格，我們會盡快與您跟進有關後續處理及退款事宜。不便之處，敬請原諒。",
   "activity_gift_currency_HKD":"HKD",
   "activity_gift_currency_USD":"USD",
   "activity_gift_currency_USDT":"USDT",
   "activity_gift_pay_tip_1":"• 請把款項 %{total} %{currency} 轉帳至以下帳號，匯款時必須在備註填寫6位識別碼；",
   "activity_gift_pay_tip_2":"• 完成匯款後，請您在24小時內上傳轉帳截圖；",
   "activity_gift_account_select":"選擇付款方式",
   "activity_gift_account_kikitrade":"Kikitrade 帳戶餘額",
   "activity_gift_account_bank":"銀行卡轉帳",
   "activity_gift_pending_payment":"需付款",
   "activity_gift_balance_tips_title":"餘額說明",
   "activity_gift_balance_tips_content":"您%{currency}錢包賬戶可用資產",
   "activity_gift_bank_name_tips":"（請輸入轉帳銀行帳戶姓名）",
   "activity_gift_account_pay":"付款",
   "activity_gift_limit":"購買總金額不可大於$%{total} %{currency}",
   "activity_gift_purchase_total":"禮品卡單筆購買量不能超過%{total} %{currency}",
   "activity_purchase_kiki_virtual":"您購買了%{quantity}張 %{amount}%{currency}的虛擬禮品卡。一共需支付%{total}%{currency}，請確認支付。",
   "activity_purchase_kiki_physical":"您購買了%{quantity}張 %{amount}%{currency}的實體禮品卡。一共需支付%{total}%{currency}，請確認支付。",
   "activity_gift_history_cancel_reason":"取消原因：%{reason}",
   "activity_gift_wallet_balance":"帳戶餘額",
   "redeem.account.not.exist":"用戶帳戶不存在",
   "redeem.insufficient.balance":"用戶帳戶可用餘額不足",
   "redeem.card.order.duplicate":"禮品卡訂單號重複",
   "redeem.card.order.save.fail":"禮品卡購買失敗",
   "redeem.card.order.update.fail":"禮品卡購買失敗",
   "redeem.card.order.not.exist":"禮品卡不存在",
   "redeem.card.discard.fail":"礼品卡购买异常",
   "redeem.customer.remark.code.op.exception":"禮品卡識別碼顯示異常",
   "activity_gift_history_paid":"待發貨",
   "activity_gift_history_failed":"支付失敗",
   "image":"圖片",
   "video":"視頻",
   "publishPosting":"正在發佈中...",
   "publishPosting1":"發佈中...",
   "rePublishPost":"重新發佈",
   "save":"保留",
   "notSave":"不保留",
   "saveLongTextTip":"保留此次長文內容?",
   "publishPostFailTip":"帖子發佈失敗，請稍後重試！",
   "publishPostSuccess":"帖子發佈成功",
   "onlyInsertOneVideo":"僅允許插入一個視頻",
   "maxUploadImageCount":"圖片最多上傳%{count}張",
   "inputTitle":"輸入標題",
   "inputText":"輸入正文",
   "pubslishPost":"發帖",
   "pubslishLongLext":"發長文",
   "hasPostPublishing":"有帖子正在發佈中",
   "latestData":"最新數值",
   "riseAndFallToday":"今日升幅",
   "riseAndFallToday1":"升幅(24H)",
   "topRiseCurrencyAndRiseRate":"領升幣種/升幅(24H)",
   "fundingRate":"資金費率",
   "orderDetail":"訂單詳情",
   "avgPrice1":"成交均價",
   "filledQty":"成交總額",
   "tradeDetail":"成交詳情",
   "tradeTime":"成交時間",
   "entrust":"委託",
   "allOrder":"全部訂單",
   "zones":"板塊",
   "tradingVolume":"交易量",
   "latestPrice":"最新價",
   "riseRate24H":"24H 升幅",
   "24HVol":"24H 量",
   "spotOrder":"現貨訂單",
   "fiatOrder":"法幣訂單",
   "fiatDeposit":"法幣入金",
   "entrustPrice":"委託價",
   "tradePrice":"成交價",
   "entrustAmount":"委託量",
   "tradeType":"成交類型",
   "marketCap":"市值",
   "circulatingSupply":"流通供應量",
   "maxSupply":"最大供給量",
   "totalSupply":"總供應量",
   "officialWebsite":"官網",
   "tradesHistory":"歷史成交",
   "orderStatus_pending_create":"下單中",
   "orderStatus_partial_filled":"部分成交",
   "orderStatus_filled":"全部成交",
   "orderStatus_partial_filled_cancelled":"部分成交已撤銷",
   "orderStatus_partial_filled_rejected":"部分成交已駁回",
   "orderStatus_rejected":"已駁回",
   "search_care_coin_name":"搜索您關心的幣種名稱",
   "hot_search":"熱門搜索",
   "history_search":"歷史搜索",
   "history_search_clear":"清除",
   "search_result":"搜尋結果",
   "search_cancel":"取消",
   "choice_add":"添加",
   "edit_finish":"完成",
   "edit_choice":"編輯自選",
   "all_select":"全選",
   "favourite":"自選",
   "choice_header_name":"名稱",
   "choice_header_top":"置頂",
   "choice_header_drag":"拖動",
   "choice_auto_add":"一鍵添加",
   "choice_custom_add":"自定義添加",
   "input_transaction_volume":"輸入交易額",
   "buy_recharge":"充值",
   "add_favorite":"添加自選",
   "add_favorite_success":"添加自選成功",
   "add_favorite_failed":"添加自選失敗",
   "edit_favorite_success":"編輯自選成功",
   "edit_favorite_failed":"編輯自選失敗",
   "remove_favorite_success":"移除自選成功",
   "remove_favorite_failed":"移除自選失敗",
   "add_favorite_zone_success":"板塊添加自選成功",
   "add_favorite_zone_failed":"板塊添加自選失敗",
   "remove_favorite_zone_success":"板塊移除自選成功",
   "remove_favorite_zone_failed":"板塊移除自選失敗",
   "symbol_list":"交易對",
   "tsla":"特斯拉",
   "aapl":"蘋果",
   "fb":"Facebook",
   "amzn":"亞馬遜",
   "maotai":"茅台",
   "googl":"谷歌",
   "coinBase":"Coinbase",
   "tencent":"騰訊",
   "HSI":"恆生指數",
   "TWSE":"台灣加權指數",
   "nasdaq":"納斯達克指數",
   "XAUUSD":"黃金美元價格",
   "CL":"原油指數",
   "DINIW":"美元指數",
   "US30Y":"美元30年債券指數",
   "says":"說",
   "greed":"貪婪",
   "extremeGreed":"極度貪婪",
   "neutral":"中立",
   "fear":"恐懼",
   "extremeFear":"極度恐懼",
   "timeType15M":"15分",
   "timeType1H":"1小時",
   "timeType4H":"4小時",
   "timeType1D":"1天",
   "timeType1W":"1週",
   "timeType1M":"1月",
   "community_hot_topic_feed":"熱門與話題",
   "participationTopic":"參與話題",
   "relatedTransactions":"相關交易",
   "comeAndComment":"快來評論吧~",
   "postCountSuffix":"篇內容",
   "topic.not.found":"該話題已過期",
   "refreshPostCount1":"更新了",
   "refreshPostCount2":"篇內容",
   "refreshPostRecommend":"推薦內容已更新",
   "interest2":"利息收取",
   "liquidation1":"清算紀錄",
   "borrowed":"借款",
   "liquidationAmt":"清算額",
   "frozen":"凍結",
   "maxAvbl":"最大可用",
   "interestFee":"應付利息",
   "8HourInterestRate":"8小時利率",
   "balance1":"餘額",
   "maxWithdrawal":"最大可提",
   "marginTurnedOn":"開啟槓桿成功",
   "marginDisabled":"關閉槓桿成功",
   "marginTurnedOnFail":"開啟槓桿失敗",
   "marginDisabledFail":"關閉槓桿失敗",
   "wallet1":"錢包",
   "open":"開啟",
   "netAssetValue":"總淨資產估值",
   "initialMargin":"初始保證金IM",
   "maintenanceMargin":"維持保證金MM",
   "hideSmallBalances":"隱藏小額資產",
   "marginTip1":"當前您的賬戶已經開啟槓桿模式，不能使用理財自動申購功能。您可以手動申購理財產品。",
   "marginTip2":"您當前賬戶還存在借貸金額，您需要完全歸還當前借貸以後，才能關閉槓桿",
   "marginTip3":"• 當前賬戶還存在應付利息，系統已為您自動歸還應付利息。\n• 由於賬戶資產不足以抵扣應付利息，產生借貸金額。請完全歸還借貸以後，再關閉槓桿。",
   "marginTip4":"當前賬戶還存在槓桿訂單處於委託中，請撤銷槓桿委託單後，再關閉槓桿",
   "marginTip5":"請先撤銷掛單，再開啟槓桿",
   "marginTip6":"當前您的賬戶已經開啟槓桿模式，不能使用理財定投功能。您可以手動申購理財產品。",
   "marginTip7":"請先關閉理財自動申購，再開啟槓桿",
   "marginTip8":"請先關閉理財定投，再開啟槓桿",
   "yourDebt":"借款金額",
   "withMargin":"• 開啟槓桿時：",
   "maxAvblTip1":"最大可用 =  (NAV-IM)/IMR *（1-手續費率/IMR）*0.99 /Bid Price + abs(Sell Unwind Qty)",
   "maxAvblTip3":"最大可提數額 =  (資產凈值NAV - 初始保證金IM) *60% /Bid Price",
   "maxAvblTip4":"最大可提數額=餘額；",
   "maxAvblTip5":"最大可用 =  (資產凈值NAV - 初始保證金IM) *60% /Bid Price",
   "maxAvblTip6":"最大可用 = 賬戶餘額；",
   "withoutMargin":"• 未開啟槓桿時：",
   "maxAvblTip2":"最大可用 = 當前資產餘額",
   "interestFeeTip1":"Kikitrade收取利息時間每日固定為：",
   "interestFeeTip2":"00:00、08:00、16:00",
   "interestFeeTip3":"利息只有當借貸產生時才會收取，每個時間段將收取的利息可以在“應付利息”中查看。",
   "interestFeeTip4":"利息按照8小時利率計算，不足8小時以8小時計算。",
   "riskRateTip1":"此值越高，資產越安全，清算風險越小。\n若小於 1 則系統會進行清算作業。\n風險係數 = 總淨資產估值 / 維持保證金",
   "riskRate":"風險係數",
   "imTip1":"此為加倉時所需之最低保證金，此金額會隨著倉位的增大而增加。\n下單後需滿足「總淨資產估值 > IM」才能加倉。",
   "mmTip1":"此為帳戶資產之清算線。\n若「總淨資產估值 < MM」則會進行清算作業。",
   "marginModeClosed":"槓桿模式未開啟",
   "loanInterest":"借貸利息",
   "marginTrading":"槓桿交易",
   "10Bid":"買盤十檔",
   "10Ask":"賣盤十檔",
   "orderBook":"深度",
   "borrow":"借",
   "maxBuy":"最大可買",
   "maxSell":"最大可得",
   "spotBuy":"現金可買",
   "spotSell":"現金可得",
   "used":"本次已用",
   "borrowed1":"本次借",
   "marginAvbl":"槓桿可用",
   "spotAvbl":"現金可用",
   "spotAvbl1":"現貨可用",
   "enterTotalAmount":"輸入成交總量",
   "enterBuyAmount":"輸入購買量",
   "enterSellAmount":"輸入賣出量",
   "riskRateLiquidating":"清算中",
   "riskRateWarning":"極高風險",
   "riskRateHigh":"高風險",
   "riskRateMedium":"中風險",
   "riskRateLow":"低風險",
   "riskRateSafe":"安全",
   "minBuyMessage":"最小購買量不得小於%{amount}",
   "minSellMessage":"最小賣出量不得小於%{amount}",
   "maxBuyMessage":"最大可買量%{amount}",
   "maxSellMessage":"最大可賣量%{amount}",
   "oneMaxBuyMessage":"單筆最大購買量不得大於%{amount}",
   "oneMaxSellMessage":"單筆最大賣出量不得大於%{amount}",
   "minTotalMessage":"最小交易額不得小於%{amount}",
   "oneMaxTotalMessage":"單筆最大交易額不得大於%{amount}",
   "oneMinTotalMessage":"單筆最小交易額不得小於%{amount}",
   "maxAvblMessage":"最大可用額%{amount}",
   "openMarginTip":"您已用金額超過現貨最大可用量，可以通過打開槓桿來繼續交易。",
   "repaymentPending":"待還",
   "liquidatedAssets":"清算資產",
   "ordersCancelled":"委託取消",
   "spot.order.invalid.status":"撤單失敗",
   "spot.order.order.book.cancel.fail":"撤單失敗",
   "spot.order.update.fail":"更新失敗",
   "spot.order.trade.duplicate":"下單失敗",
   "spot.order.trade.state.illegal":"訂單狀態錯誤",
   "spot.customer.stop.trade":"交易狀態錯誤",
   "spot.order.book.invoke.fail":"下單失敗",
   "margin.interest.state.illegal":"關閉槓桿失敗",
   "margin.interest.duplicate":"槓桿利息處理重複",
   "margin.config.update.fail":"更新失敗",
   "margin.config.disable.forbidden":"槓桿關閉失敗",
   "margin.balance.insufficient":"餘額不足",
   "margin.lock.fail":"下單失敗",
   "margin.unlock.fail":"風險檢查解鎖失敗",
   "margin.orders.exceed":"槓桿下單數量超限",
   "margin.transfer.fail":"餘額不足",
   "margin.transfer.lock.fail":"開啟槓桿失敗，請稍後重試",
   "margin.open.country.forbidden":"您當前所在地不允許使用槓桿交易",
   "margin.open.saving.forbidden":"請先關閉理財自動申購，再開啟槓桿",
   "margin.open.invest.forbidden":"請先關閉理財定投，再開啟槓桿",
   "margin.open.order.forbidden":"請先撤銷掛單，再開啟槓桿",
   "margin.open.query.order.fail":"開啟槓桿失敗，請稍後重試",
   "margin.close.loan.forbidden":"關閉槓桿失敗-用戶有借款",
   "margin.close.order.forbidden":"請先撤銷掛單，再開啟槓桿",
   "margin.close.interest.cannot.afford":"關閉槓桿時，應付利息不足",
   "margin.interest.fail":"關閉槓桿失敗，請稍後重試",
   "margin.already.closed":"槓桿已關閉",
   "margin.close.fail":"槓桿關閉異常",
   "noFav":"暫時還沒有收藏",
   "noFeed":"暫時還沒有動態",
   "noFollow":"暫時還沒有關注",
   "noComment":"暫時還沒有任何評論",
   "noReply":"暫時還沒有任何回复",
   "noSystemNotice":"暫時還沒有通知",
   "noFans":"暫時還沒有粉絲",
   "noWow":"暫時還沒有收到贊",
   "noData":"暫時沒有任何數據",
   "noSearch":"沒有搜索到你想要的內容",
   "noPostComment":"暫時還沒有評論",
   "coinCoin":"幣幣",
   "closeAutoBuyTip":"此操作將關閉您的活期理財自動申購功能\n確定關閉？",
   "closeAutoInvestTip":"此操作將關閉您的理財定投功能\n確定關閉？",
   "marginTradeNotOpen":"槓桿功能上線準備中，敬請期待~",
   "borrowCoinTip1":"每次交易產生借貸時都需要確認提示",
   "borrowCoinTip2":"本次交易將產生≈%{amount} %{currency}借款金額，確定繼續交易？",
   "autoInvest":"自動定投",
   "system.status.upgrading":"系統升級維護中，請升級完成後重試。\n維護時段：2021-10-21 10:00-18:00",
   "credit_recharge":"信用卡買幣",
   "credit_desc":"支持Visa、MasterCard以及SEPA等",
   "credit_channels_close":"信用卡買幣服務臨時中止，請稍後重試！",
   "credit_buy_coin":"信用卡買幣",
   "buy_by_amount":"按金額購買",
   "buy_by_quantity":"按數量購買",
   "credit_spend":"花費",
   "credit_estimated":"預估購得",
   "credit_buy":"購買",
   "credit_estimated_cost":"預估花費",
   "credit_input_valid":"請輸入有效數字",
   "credit_not_min":"不得低於最小下單量",
   "credit_not_max":"不得超過最大下單量",
   "credit_order_next_step":"繼續",
   "credit_channel_type":"信用卡渠道",
   "credit_channel_info":"說明",
   "credit_estimated_error":"當前支付渠道的服務出現問題，請稍後重試！",
   "credit_channel_1_SWITCHERE":"• 通過 switchere 購買加密貨幣，需要完成該平台的註冊以及KYC。",
   "credit_channel_2_SWITCHERE":"• %{fiatCurrency}在switchere交易的最小下單量為%{fiatCurrencyMin}%{fiatCurrency}，最大下單量為%{fiatCurrencyMax}%{fiatCurrency}。",
   "credit_channel_3_SWITCHERE":"• 您在本頁面看到的，所購入的加密貨幣數量，僅為預估得到的數量，非實際最終成交的數額。實際確認的購買量，以最終在 switchere 上支付成交時的數額為準。",
   "credit_channel_4_1_SWITCHERE":"• 如果您有任何和 switchere 相關的問題，可通過 switchere 的",
   "credit_channel_4_2_SWITCHERE":"來查找您關心的問題。",
   "credit_order_history":"訂單歷史",
   "credit_order_confirmation":"訂單確認中",
   "credit_order_pending":"待支付",
   "credit_order_failed":"交易失敗",
   "credit_order_failure_reason":"失敗原因",
   "credit_order_successful":"交易成功",
   "credit_order_customer_service":"聯繫客服",
   "credit_order_payment_completed":"繼續支付",
   "credit_order_transaction_confirmation":"交易確認",
   "credit_order_billing_information":"賬單信息",
   "credit_order_payment_channel":"支付渠道",
   "credit_order_fiat_currency":"法幣幣種",
   "credit_order_fiat_amount":"法幣金額",
   "credit_order_purchase_currency":"購買幣種",
   "credit_order_estimated_quantity":"預估數量",
   "credit_order_purchase_quantity":"購買數量",
   "credit_order_estimated_amount":"預估金額",
   "credit_order_place_order":"下單",
   "credit_order_loading":"請稍後...",
   "credit_order_disclaimer":"免責聲明",
   "credit_disclaimer_tips1_SWITCHERE":"• 您即將離開Kikitrade前往Switchere.com，Switchere是由第三方獨立運營的法定貨幣與加密貨幣交易平臺，所有相關服務均由Switchere方提供。請在使用本服務前，仔細閱讀並同意Simplex的使用條款。",
   "credit_disclaimer_tips2_1_SWITCHERE":"• 有關交易的任何問題，請聯系",
   "credit_disclaimer_tips2_2_SWITCHERE":" 。",
   "credit_disclaimer_tips3_SWITCHERE":"• Kikitrade對因使用該服務遭受的任何損失或損害不承擔任何責任。",
   "credit_order_trading_channel":"交易渠道",
   "credit_order_currency":"支付幣種",
   "credit_order_amount":"支付金額",
   "credit_order_fee":"手續費",
   "credit_order_on_chain_fee":"鏈上手續費",
   "credit_card_transaction":"信用卡交易",
   "credit_order_reminder":"訂單提醒",
   "credit_order_whether_completed":"您當前這筆訂單是否已完成支付？",
   "credit_order_incomplete":"未完成",
   "credit_order_completed":"已完成",
   "keyboard_desc":"KIKI專屬鍵盤  輸入更安全",
   "credit_fiat_select":"選擇貨幣",
   "credit_crypto_select":"選擇幣種",
   "quickpay.order.create.fail":"訂單創建失敗",
   "quickpay.order.update.fail":"訂單更新失敗",
   "quickpay.channel.closed":"支付渠道已關閉",
   "quickpay.channel.not.support.the.service":"支付渠道不支持該服務",
   "quickpay.order.not.exist":"訂單不存在",
   "quickpay.channel.api.call.fail":"調用支付渠道api失敗",
   "fcm.title.quickpay.order.fail": "",
   "fcm.body.quickpay.order.fail": "您有一筆信用卡入金交易失敗，詳情查看這裡>>",
   "fcm.title.quickpay.order.success": "",
   "fcm.body.quickpay.order.success": "恭喜您成功入金 [%{payinCurrency}] %{payinAmount}，詳情查看這裡>>",
   "community":"社區",
   "news":"資訊",
   "explore":"發現",
   "deposit1":"入金",
   "hotCoin":"熱門幣種",
   "newCoin":"Kiki新幣",
   "lastEvent":"最新活動",
   "tags":"話題",
   "hotTheme":"主題板塊",
   "kikiStars":"熱門人物",
   "hotPicks":"精選",
   "kikiMaster":"kiki大師",
   "zoneNameETH":"ETH板塊",
   "zoneNameMAIN":"主流幣",
   "zoneNameDEFI":"Defi板塊",
   "zoneNameCOMMON":"公鏈",
   "zoneNamePOW":"POW生態",
   "zoneNameDOT":"DOT生態",
   "zoneNameMEME":"MEME生態",
   "zoneNameSOL":"SOL生態",
   "zoneNameBSC":"BSC生態",
   "zoneNameNFT":"NFT生態",
   "zoneNameGAMEFI":"GameFi",

   "deposit_step1": "第一步：登入",
   "deposit_step2": "第二步：KYC",
   "deposit_step3": "第三步：入金",
   "deposit_login": "登入",
   "deposit_KYC": "KYC",
   "deposit_deposit": "入金",
   "deposit_bankDeposit_title": "銀行卡入金",
   "deposit_bankDeposit_desc": "銀行卡轉賬買入數字貨幣",
   "deposit_creditDeposit_title": "信用卡入金",
   "deposit_creditDeposit_desc": "支持Visa、Master等多種信用卡",
   "deposit_c2cDeposit_title": "C2C入金",
   "deposit_c2cDeposit_desc": "用戶和用戶之間直接交易",
   "deposit_giftCard": "禮品卡",
   "deposit_giftCard_desc": "Kiki推出實體Bitcoin禮品卡  購買禮品卡   簡單三步，真正持有比特幣",
   "deposit_giftCard_button": "購買禮品卡",
   "deposit_guideForBeginners": "Kiki教室"

}
export default obj;
