// @flow
import config from '../utils/config';
import request from '../utils/request';
import helper from '../utils/helper';
import type { CommonResponse, ExchangeResult } from './base';
// model start
export interface OtcPaymentConfig{
  paymentName: string,
  paymentAccount: string,
  paymentBankNumber: string,
  paymentBankCode: string,
  paymentBankName: string,
  paymentBankAddress: string
}
export type PaymentConfigResponseType = ExchangeResult<OtcPaymentConfig> & CommonResponse;

// paymentDetail
export interface OtcCustomerPaymentWay{
  customerId: string,
  id: string,
  accountNo: string,
  bankName: string,
  userName: string,
  status: string,
  created: string,
  modified: string
}

export type PaymentWayDetailResponseType = ExchangeResult<OtcCustomerPaymentWay> & CommonResponse;

// paymentDetailList
export type PaymentWayListResponseType = ExchangeResult<OtcCustomerPaymentWay[]> & CommonResponse;

// depositApply
export interface OtcDepositApplyRequest {
  currency: string,
  payment_way_id: string,
  amount: number,
  reference_no: ?string,
  reference_img: string,
  transfer_method: string
}

export interface OtcOrder{
  customerId: string,
  orderNum: string,
  currency: string,
  side: string,
  requestAmount: number,
  amount: number,
  paymentWayId: string,
  accountNo: string,
  bankName: string,
  userName: string,
  referenceNo: string,
  referenceImage: string,
  transferMethod: string,
  rejectReason: string,
  status: string,
  processStatus: string,
  created: string,
  modified: string,
  fee: string,
  bankCharge?: number // @since v2.4.0, 老数据可能不存在
}

export type OtcDepositApplyResponseType = ExchangeResult<OtcOrder> & CommonResponse;
export type OtcDepositDetailResponseType = ExchangeResult<OtcOrder> & CommonResponse;
export type OtcDepositListResponseType = ExchangeResult<OtcOrder[]> & CommonResponse;
export type DepositRemarkcodeResponseType = ExchangeResult<OtcOrder[]> & CommonResponse;

// withdrawApply
export type OtcWithdrawApplyResponseType = ExchangeResult<OtcOrder> & CommonResponse;
export type OtcWithdrawDetailResponseType = ExchangeResult<OtcOrder> & CommonResponse;
export type OtcWithdrawListResponseType = ExchangeResult<OtcOrder[]> & CommonResponse;

// definition start
class OTCApi {
  getPayment (): Promise<PaymentConfigResponseType> {
    const URL = config.api.host_newapi +
      config.api.otc.payment

    return request.get.call({}, URL);
  };

  paymentWayAdd (params: {account_no: string, bank_name: string, user_name: string}): Promise<CommonResponse> {
    const URL =
      config.api.host_newapi +
      config.api.otc.paymentWayAdd + '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  paymentWayModify (params: {account_no: string, bank_name: string, user_name: string, id: string}): Promise<CommonResponse> {
    const URL =
      config.api.host_newapi +
      config.api.otc.paymentWayModify + '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  paymentWayRemove (params: {id: string}): Promise<CommonResponse> {
    const URL =
      config.api.host_newapi +
      config.api.otc.paymentWayRemove + '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  paymentWayDetail (params: {id: string}): Promise<PaymentWayDetailResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.otc.paymentWayRemove + '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  paymentWayList (): Promise<PaymentWayListResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.otc.paymentWayList
    return request.get.call({}, URL);
  };

  depositApply (params: OtcDepositApplyRequest): Promise<OtcDepositApplyResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.otc.depositApply + '?' +
      helper.mapToQueryString(params);

    return request.post.call({}, URL);
  };

  depositDetail (params: {orderNum: string}): Promise<OtcDepositDetailResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.otc.depositDetail + '?' +
      helper.mapToQueryString(params);

    return request.get.call(params, URL);
  };

  depositList (params: {from_order_num?: ?string, limit: number}): Promise<OtcDepositListResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.otc.depositList + '?' +
      helper.mapToQueryString(params);

    return request.get.call(params, URL);
  };

  depositRemarkcode (): Promise<DepositRemarkcodeResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.otc.depositRemarkcode + '?' +
      helper.mapToQueryString({});

    return request.get.call({}, URL);
  };

  withdrawApply (params: {currency: string, amount: string, payment_way_id: string}): Promise<OtcWithdrawApplyResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.otc.withdrawApply + '?' +
      helper.mapToQueryString(params);

    return request.post.call(params, URL);
  };

  withdrawDetail (params: {orderNum: string}): Promise<OtcWithdrawDetailResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.otc.withdrawDetail + '?' +
      helper.mapToQueryString(params);

    return request.get.call(params, URL);
  };

  withdrawList (params: {from_order_num?: ?string, limit: number}): Promise<OtcWithdrawListResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.otc.withdrawList + '?' +
      helper.mapToQueryString(params);

    return request.get.call(params, URL);
  };
}

const OTCAPI: OTCApi = new OTCApi();
export { OTCAPI };
