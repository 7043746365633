// @flow

const api = {
  // host: 'http://172.30.30.18:7002/',
  // host_newapi: 'http://172.30.30.17:8070/',
  host: 'http://api.dipbit.xyz:8193/',
  host_newapi: 'https://api.beta.dipbit.xyz/',
  host_api_v1: 'https://api.beta.dipbit.xyz/',
  // host: 'http://devapi.dipbit.xyz:7001/',
  // host_newapi: 'http://devnewapi.dipbit.xyz:8070/',
  supportUrl: 'https://cschat.antcloud.com.cn/index.htm?tntInstId=1Ab_9Aku&scene=SCE00941738',
  kline_url: 'http://www.dipbit.xyz/tradingview_dark_theme/index.html',
  profit_chart_url: 'https://upload.kikitrade.com/kikitrade_profit_chart/index.html',
  explore_areaChart_url: 'https://upload.kikitrade.com/kikitrade_chart/areaChart.html',
  explore_compareLineChart_url: 'https://upload.kikitrade.com/kikitrade_chart/compareLineChart.html',
  explore_stackBarChart_url: 'https://upload.kikitrade.com/kikitrade_chart/stackBarChart.html',
  explore_VSChart_url: 'https://upload.kikitrade.com/kikitrade_chart/vs.html',
  explore_analysisChart_url: 'https://upload.kikitrade.com/kikitrade_chart/analysis.html',
  liveness_react_app_url: 'https://upload.kikitrade.com/liveness_react_app/index.html',
  market_chart_url: 'https://upload.kikitrade.com/kikitrade_chart/spot.html',
  explore_areaChart_remote_url: 'https://chart.kikitrade.com/kiki_chart_node',
  upload_api: 'http://upload.dipbit.xyz/',
  share: 'http://share.dipbit.xyz/',
  kiki_website_url: 'http://m.dipbit.xyz/',
  simple_kline_host: 'http://api.dipbit.xyz:8080/cfd/kline/init',
  new_simple_kline_host: 'http://api.dipbit.xyz:8081/v1/market/klines/init',
  google_web_clientId: '983830855482-1bj95sdqi465uto9sjli0539b8i4tt7h.apps.googleusercontent.com'
};

export default api;
