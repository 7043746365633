/*
 *
 *
 *
 *     全部的reducers的入口文件，
 *     使用combineReducers将所有的reducer合并起来
 *     @flow
 *
 *
 * */
import { combineReducers, type CombinedReducer, type Reducer } from 'redux';
import TraderReducer from './TraderReducer';
import SocialReducer from './SocialReducer';
import AccountReducer from './AccountReducer';
import HomeReducer from './HomeReducer';
import EarningsReducer from './EarningsReducer';
import MarketReducer from './MarketReducer';
import { type StateType } from '../actions/common'
import { type AccountActionsType } from '../actions/AccountAction';
import { type SocialActionsType } from '../actions/SocialAction';
import { type TraderActionsType } from '../actions/TraderAction';
import { type HomeActionsType } from '../actions/HomeAction'
import type { AccountThunkActionsType } from '../actions/AccountAction';
import type { HomeThunkActionsType } from '../actions/HomeAction';
import type { TraderThunkActionsType } from '../actions/TraderAction';
import type { SocialThunkActionsType } from '../actions/SocialAction';
import type { EarningsThunkActionsType } from '../actions/EarningsAction'
import { ThunkDispatch } from 'redux-thunk';

const reducers = {
  AccountReducer,
  SocialReducer,
  TraderReducer,
  EarningsReducer,
  HomeReducer,
  MarketReducer
};
export type CombinedReducerType = typeof reducers;
export type ActionType = AccountActionsType | SocialActionsType | TraderActionsType | HomeActionsType;
export type RootThunkActionType = AccountThunkActionsType | HomeThunkActionsType | TraderThunkActionsType | EarningsThunkActionsType | SocialThunkActionsType;

// eslint-disable-next-line flowtype/no-weak-types
export type CombinedStateType = $ObjMap<CombinedReducerType, <S>(r: Reducer<S, any>) => S>;
export type CombinedRootReducersType = CombinedReducer<CombinedStateType, RootThunkActionType>;
const rootReducer: CombinedRootReducersType = combineReducers<CombinedReducerType, RootThunkActionType>(reducers);
export default rootReducer;

// /////////////////////////  一下给页面使用的公共组件react redux
// 给页面用的， 简化页面connect 使用
export type MapStateToPropsType = CombinedReducerType;

export type RootThunkDispatchType = ThunkDispatch<CombinedReducerType, void, RootThunkActionType>;
export interface DispatchProps {
  dispatch: ThunkDispatch<CombinedReducerType, void, RootThunkActionType>
}

export function defaultMapDispatchProp<T> (): (ThunkDispatch<CombinedReducerType, void, RootThunkActionType>, T) => DispatchProps {
  return (dispatch: ThunkDispatch<CombinedReducerType, void, RootThunkActionType>, ownProps: T): DispatchProps => {
    return { dispatch }; // 增加navigation
  };
}
export function defaultMapStateProp<T> (): (StateType, T) => $Shape<StateType> {
  return (state: StateType, ownProps: T): $Shape<StateType> => {
    return {
      AccountReducer: state.AccountReducer,
      SocialReducer: state.SocialReducer,
      HomeReducer: state.HomeReducer,
      TraderReducer: state.TraderReducer,
      EarningsReducer: state.EarningsReducer
    };
  }
}

export function noWsDataPushMapStateProp<T> (): (StateType, T) => $Shape<StateType> {
  return (state: StateType, ownProps: T): $Shape<StateType> => {
    return {
      AccountReducer: state.AccountReducer,
      SocialReducer: state.SocialReducer,
      HomeReducer: state.HomeReducer
    };
  }
}

export function customMapStateProp<T> (options: {
  AccountReducer?: number,
  SocialReducer?: number,
  HomeReducer?: number,
  EarningsReducer?: number,
  TraderReducer?: number,
  MarketReducer?: number
}): (StateType, T) => $Shape<StateType> {
  return (state: StateType, ownProps: T): $Shape<StateType> => {
    const reducerUnion = {}
    if (options && options.AccountReducer === 1) {
      reducerUnion.AccountReducer = state.AccountReducer
    }
    if (options && options.SocialReducer === 1) {
      reducerUnion.SocialReducer = state.SocialReducer
    }
    if (options && options.HomeReducer === 1) {
      reducerUnion.HomeReducer = state.HomeReducer
    }
    if (options && options.EarningsReducer === 1) {
      reducerUnion.EarningsReducer = state.EarningsReducer
    }
    if (options && options.TraderReducer === 1) {
      reducerUnion.TraderReducer = state.TraderReducer
    }
    if (options && options.MarketReducer === 1) {
      reducerUnion.MarketReducer = state.MarketReducer
    }

    return reducerUnion
  }
}
