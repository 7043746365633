import ContentLoader from 'react-content-loader';
import React from 'react';

module.exports = {
  ContentLoader: ContentLoader,
  Circle: function (props) {
    return <circle {...props}/>
  },
  Rect: function (props) {
    return <rect {...props}/>
  }
}
