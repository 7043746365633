// @flow
import { ActionTypes } from './ActionTypes';
import { getClient, getUserInfo } from '../api/social';
import type {
  RankingAllResponseType,
  RankingBatchResponseType,
  RankingResponseType,
  RankingResultResponseType,
  RankingTypeListResult
} from '../api/trader';
import TraderAPI from '../api/trader';
import type { TraderSetting } from '../api/cfdTrader';
// import { CFDTraderAPI } from '../api/cfdTrader';
import { isEmpty } from 'lodash';
import type { CfdOrderLite, CfdOrderLiteListResponseType } from '../api/cfdTrade';
import { FavoriteCustomAPI } from '../api/favoriteCustom';
import { CFDTradeAPI } from '../api/cfdTrade';
import helper from '../utils/helper';
import { OTCAPI } from '../api/otc';
import { KlineAPI } from '../api/klineApi';

import moment from 'moment';
import { SpotTradeAPI } from '../api/spotTrade';
import type { KolListInfo } from '../models/social';
import { type Action } from 'redux';
import { type ThunkAction, ThunkDispatch } from 'redux-thunk';
import type { ErrorActionType, StateType } from './common';

import type { KlineResponseType } from '../api/klineApi';

import type { CustomerMisc, RankingItem } from '../api/base';
import type { PnlOrderMapType, SymbolList, ExtractMarketData } from '../models/market';
import type { OtcDepositListResponseType, OtcOrder } from '../api/otc';
import { AccountAPI } from '../api/account';
import type { ExdmTransaction, TransactionListResponseType } from '../api/account';
import type { SpotOrderEntrust, SpotOrderEntrustListResponseType } from '../api/spotTrade';
import type { FavoriteListResponseType } from '../api/favoriteCustom';
import bugsnag from '../utils/bugsnag'

// 定义所有TraderAction
interface TraderData {
  nodata: boolean,
  recommandList: KolListInfo[],
  masterList: KolListInfo[],
  weekStarList: KolListInfo[],
  allTraderList: KolListInfo[],
  token: ?string
}

export type RankType = 'profitRate' | 'recommend' | 'subscribe' | 'new' | 'profitRate' | 'profitAmount' | 'hotMoney';

export type MarketType = 'fiat' | 'spot' | 'swap';

interface TraderRankData {
  rankType: RankType,
  options: ?{ week: boolean },
  temKey: string,
  weekKey: string,
  nodata: boolean,
  rankList: KolListInfo[],
  masterList: KolListInfo[],
  temKey: string,
  weekKey: string,
  rankTokenInfo: {
    token: ?string,
    rankType: string
  },
  currencyList: [], // TODO
  token: ?string
}

interface SymbolInfoListData {
  spotSymbols: SymbolList[]
}

interface RevenueEventTimeData {
  dcMaxTime?: number,
  maxTime?: number
}
interface CoinDetailData {
  coinDetail: ExtractMarketData,
  otherRateDetail: ExtractMarketData
}

export type TraderFetchDataRequestActionType = Action<typeof ActionTypes.TRADER_FETCH_DATA_REQUEST>;
export type TraderFetchDataErrorActionType = Action<typeof ActionTypes.TRADER_FETCH_DATA_ERROR> & ErrorActionType;
export type TraderFetchDataActionType = Action<typeof ActionTypes.TRADER_FETCH_DATA> & TraderData;
export type TraderFetchMoreDataActionType = Action<typeof ActionTypes.TRADER_FETCH_MORE_DATA> & TraderData;

export type TraderFetchMoreDataErrorActionType =
  Action<typeof ActionTypes.TRADER_FETCH_MORE_DATA_ERROR>
  & ErrorActionType;

export type TraderFetchRankDataErrorActionType =
  Action<typeof ActionTypes.TRADER_FETCH_RANK_DATA_ERROR>
  & ErrorActionType;

export type TraderFetchRankDataActionType = Action<typeof ActionTypes.TRADER_FETCH_RANK_DATA> & TraderRankData;
export type TraderMyMiscRequestActionType = Action<typeof ActionTypes.TRADER_MY_MISC_REQUEST>;
export type TraderMyMiscActionType = Action<typeof ActionTypes.TRADER_MY_MISC> & { myMisc: CustomerMisc};
export type TraderMyMiscResetActionType = Action<typeof ActionTypes.TRADER_MY_MISC_RESET>;
export type TraderMyMiscErrorActionType = Action<typeof ActionTypes.TRADER_MY_MISC_ERROR> & ErrorActionType;

export type TraderMySettingRequestActionType = Action<typeof ActionTypes.TRADER_MY_SETTING_REQUEST>;
export type TraderMySettingActionType = Action<typeof ActionTypes.TRADER_MY_SETTING> & { mySetting: TraderSetting };
export type TraderMySettingErrorActionType = Action<typeof ActionTypes.TRADER_MY_SETTING_ERROR> & ErrorActionType;
export type TraderMySettingResetActionType = Action<typeof ActionTypes.TRADER_MY_SETTING_RESET>;

export type CfdRefreshSymbolInfoActionType = Action<typeof ActionTypes.CFD_REFRESH_SYMBOL_INFO> & { symbols: SymbolList[] };
export type CfdRefreshFiatSymbolInfoActionType = Action<typeof ActionTypes.CFD_REFRESH_FIAT_SYMBOL_INFO> & { fiatSymbols: SymbolList[] };
export type CfdRefreshSpotSymbolInfoActionType = Action<typeof ActionTypes.CFD_REFRESH_SPOT_SYMBOL_INFO> & SymbolInfoListData;
export type RevenueEventTimeActionType = Action<typeof ActionTypes.REVENUE_EVENT_TIME> & RevenueEventTimeData;
export type CfdFetchAllPnlOrdersActionType = Action<typeof ActionTypes.CFD_FETCH_ALL_PNL_ORDERS> & { pnlOrderMap: PnlOrderMapType};
export type TraderSymbolDataActionType = Action<typeof ActionTypes.TRADER_SYMBOL_DATA> & CoinDetailData;
export type TraderSymbolDataErrorActionType = Action<typeof ActionTypes.TRADER_SYMBOL_DATA_ERROR> & ErrorActionType;
export type TraderEntrustDataActionType = Action<typeof ActionTypes.TRADER_ENTRUST_DATA> & { entrustList: SpotOrderEntrust[], entrustHistoryList: SpotOrderEntrust[], nextPage?: boolean, nextOffset: ?number, loadingMoreEntrust?: boolean };
export type TraderEntrustDataErrorActionType = Action<typeof ActionTypes.TRADER_ENTRUST_DATA_ERROR> & ErrorActionType;
export type TraderUserInfoCleanActionType = Action<typeof ActionTypes.ACCOUNT_USER_INFO_CLEAN>;
export type SpotSymbolListActionType = Action<typeof ActionTypes.SPOT_SYMBOL_LIST> & { spotMarketItems: SymbolList[] };
export type FiatSymbolListActionType = Action<typeof ActionTypes.FIAT_SYMBOL_LIST> & { fiatMarketItems: SymbolList[] };
export type SwapSymbolListActionType = Action<typeof ActionTypes.SWAP_SYMBOL_LIST> & { swapMarketItems: SymbolList[] };
export type FullSymbolListActionType = Action<typeof ActionTypes.FULL_SYMBOL_LIST> & { swapMarketItems: SymbolList[], spotMarketItems: SymbolList[], fiatMarketItems: SymbolList[], rateType: string };
export type HistorySearchDataActionType = Action<typeof ActionTypes.TRADER_HISTORY_SEARCH>;
export type FavoriteListFetchActionType = Action<typeof ActionTypes.FAVORITE_LIST_FETCH>;
export type FirstShowCustomChoicesActionType = Action<typeof ActionTypes.TRADER_CUSTOM_FIRST_SHOW>;

// 暴露统一的TraderActionType
export type TraderActionsType =
  TraderFetchDataRequestActionType
  | TraderFetchDataErrorActionType
  | TraderFetchDataActionType
  | TraderFetchMoreDataActionType
  | TraderFetchMoreDataErrorActionType
  |
  TraderFetchRankDataErrorActionType
  | TraderFetchRankDataActionType
  |
  TraderMyMiscActionType
  | TraderMyMiscRequestActionType
  | TraderMyMiscResetActionType
  | TraderMyMiscErrorActionType
  |
  TraderMySettingRequestActionType
  | TraderMySettingActionType
  | TraderMySettingErrorActionType
  | TraderMySettingResetActionType
  |
  CfdRefreshSymbolInfoActionType
  | CfdRefreshSpotSymbolInfoActionType
  | CfdFetchAllPnlOrdersActionType
  | CfdRefreshFiatSymbolInfoActionType
  |
  RevenueEventTimeActionType
  |
  TraderSymbolDataActionType
  | TraderSymbolDataErrorActionType
  |
  TraderEntrustDataActionType
  | TraderEntrustDataErrorActionType
  |
  TraderUserInfoCleanActionType
  | SpotSymbolListActionType
  | FiatSymbolListActionType
  | SwapSymbolListActionType
  | FullSymbolListActionType;

const mapData = (item: RankingItem): KolListInfo => {
  return {
    nickname: item.nickName ?? '-',
    riskLevel: item.riskLevel ? item.riskLevel + '' : '1',
    level: item.level ?? 0,
    brief: item.intro ?? '-',
    follower: item.subscribeCount ?? 0,
    profit: item.profitAmount ?? 0,
    rate: item.profitRate ?? 0,
    avatar: item.avatar ?? '-',
    isFollow: item.subscribed ?? false,
    customerId: item.customer_id,
    recommandUrl: item.recommandUrl ?? '',
    currencies: item.currencies ?? '',
    profitAmount: item.profitAmount ?? '',
    profitRate: item.profitRate ?? '',
    hotMoney: item.hotMoney ?? 0
  };
};

// ///////////////////////////////////////////////////////////////////////////// ///////////////////////////////////////////////////////////////////////////
// util mapper
// ///////////////////////////////////////////////////////////////////////////// ///////////////////////////////////////////////////////////////////////////

function mapper (item: RankingItem, index: number, array: Array<RankingItem>): KolListInfo {
  return mapData(item);
}

// ///////////////////////////////////////////////////////////////////////////
// 业务异步action定义
// ///////////////////////////////////////////////////////////////////////////
export type FetchTraderInfoThunkActionType = ThunkAction<void, StateType, void, TraderFetchDataRequestActionType | TraderFetchDataErrorActionType | TraderFetchDataActionType>;
export type FetchTraderInfoThunkDispatchType = ThunkDispatch<StateType, void, TraderFetchDataRequestActionType | TraderFetchDataErrorActionType | TraderFetchDataActionType>;

// preload trader info
export function FetchTraderInfo (props: { force: boolean }): FetchTraderInfoThunkActionType {
  return (dispatch: FetchTraderInfoThunkDispatchType, getState: ()=> StateType) => {
    dispatch({ type: ActionTypes.TRADER_FETCH_DATA_REQUEST });
    const { TraderReducer } = getState();
    const last = new Date(TraderReducer.traderUpdateTs);
    if (
      // __DEV__ ||
      TraderReducer.traderUpdateTs === 0 ||
      new Date().getTime() - last > 1000 * 60 * 5 ||
      props.force
    ) {
      console.log('fetch trader info at ...' + new Date().getTime());
      // request to api, set trigger SetTraderInfo
      const traderAPI = new TraderAPI(getClient(), null, {});
      traderAPI
        .getAllRanking({})
        .then((r: RankingAllResponseType) => {
          console.log('fetch trader info at>>>' + JSON.stringify(r));
          const dayResult = r.result.day.items;
          const weekResult = r.result.week.items;
          const traderResult = r.result.follower.items;
          const recommandResult = r.result.recommand.items;
          dispatch({
            type: ActionTypes.TRADER_FETCH_DATA,
            recommandList: recommandResult ? recommandResult.map(mapper) : null,
            // master list
            masterList: !dayResult
              ? null
              : dayResult.map(mapper),
            // week star
            weekStarList: !weekResult
              ? null
              : weekResult.map(mapper),
            // trader
            allTraderList: !traderResult
              ? null
              : traderResult.map(mapper),
            token: isEmpty(r.result.follower.next_token)
              ? null
              : r.result.follower
          });
        })
        .catch((err: Error) => {
          console.log('fetch trader info at>' + JSON.stringify(err));
          bugsnag.notify(new Error('[trader] -- [FetchTraderInfo] -- [error]: ' + err.toString()));
          dispatch({
            type: ActionTypes.TRADER_FETCH_DATA_ERROR,
            error: err
          });
        });
    } else {
      dispatch({
        type: ActionTypes.TRADER_FETCH_DATA,
        nodata: true
      });
    }
  };
};

export type FetchMoreTraderInfoThunkActionType = ThunkAction<void, void, void, TraderFetchMoreDataErrorActionType | TraderFetchMoreDataActionType>;
export type FetchMoreTraderInfoThunkDispatchType = ThunkDispatch<void, void, TraderFetchMoreDataErrorActionType | TraderFetchMoreDataActionType>;

export function FetchMoreTraderInfo (moreToken: ?string): FetchMoreTraderInfoThunkActionType {
  return (dispatch: FetchMoreTraderInfoThunkDispatchType) => {
    const trader = new TraderAPI(getClient(), null, {});
    const param = isEmpty(moreToken)
      ? { limit: 10 }
      : {
          limit: 10,
          token: moreToken
        };
    trader
      .getRanking('follower', param)
      .then((r: RankingResultResponseType) => {
        // 数据为空不更新
        const result: RankingTypeListResult = r.result;
        if (result.follower?.items && result.follower.items.length === 0) {
          dispatch({
            type: ActionTypes.TRADER_FETCH_MORE_DATA,
            nodata: true
          });
        } else {
          dispatch({
            type: ActionTypes.TRADER_FETCH_MORE_DATA,
            allTraderList: result.follower?.items.map((item: RankingItem, index: number, array: RankingItem[]): KolListInfo => {
              return mapData(item);
            }),
            token: result.follower && result.follower.next_token
              ? result.follower.next_token
              : null
          });
        }
      })
      .catch((err: Error) => {
        console.log(JSON.stringify(err));
        bugsnag.notify(new Error('[trader] -- [FetchMoreTraderInfo] -- [error]: ' + err.toString()));
        dispatch({
          type: ActionTypes.TRADER_FETCH_DATA_ERROR,
          error: err
        });
      });
  };
};

export type FetchTraderRankThunkActionType = ThunkAction<void, StateType, void, TraderFetchRankDataErrorActionType | TraderFetchRankDataActionType>;
export type FetchTraderRankThunkDispatchType = ThunkDispatch<StateType, void, TraderFetchRankDataErrorActionType | TraderFetchRankDataActionType>;

export function FetchTraderRank (props: { type: RankType, force?: ?boolean }, options?: { week?: boolean, riskLevels?: string, currency?: string, limit?: number, token?: string }): FetchTraderRankThunkActionType {
  return (dispatch: FetchTraderRankThunkDispatchType, getState: ()=> StateType) => {
    dispatch({ type: ActionTypes.TRADER_FETCH_RANK_DATA_REQUEST });
    const { TraderReducer } = getState();
    let weekKey = '';
    let temKey = JSON.stringify(props.type) + (options ? JSON.stringify(options) : '');

    if (options && options.week !== undefined) {
      weekKey = temKey;
      temKey = 'weekKey';
    }

    const last = new Date(TraderReducer.rankUpdateTs[temKey]).getTime() || 0;

    if (
      // __DEV__ ||
      last === 0 ||
      new Date().getTime() - last > 1000 * 60 * 5 ||
      (props.force ?? false) ||
      weekKey !== TraderReducer.weekKey
    ) {
      console.log(
        'fetch trader info at ...props' +
        JSON.stringify(props) +
        '--' +
        new Date().getTime()
      );
      // request to api, set trigger SetTraderInfo
      const trader = new TraderAPI(getClient(), null, {});
      trader
        .getTraderRank(props.type, options)
        .then((r: RankingResponseType) => {
          const result = r.result;
          const rankList = result.items;
          dispatch({
            type: ActionTypes.TRADER_FETCH_RANK_DATA,
            rankList: !rankList
              ? null
              : rankList.map((item: RankingItem, index: number, array: RankingItem[]): KolListInfo => {
                return mapData(item);
              }),
            rankType: props.type,
            options: options,
            temKey: temKey,
            weekKey: weekKey,
            rankTokenInfo: {
              token: isEmpty(r.result.next_token)
                ? null
                : r.result.next_token,
              rankType: props.type
            }
          });
        })
        .catch((err: Error) => {
          console.log('fetch trader info at' + JSON.stringify(err));
          bugsnag.notify(new Error('[trader] -- [FetchTraderRank] -- [error]: ' + err.toString()));
          dispatch({
            type: ActionTypes.TRADER_FETCH_RANK_DATA_ERROR,
            error: err
          });
        });
    } else {
      dispatch({
        rankType: props.type,
        options: options,
        temKey: temKey,
        weekKey: weekKey,
        type: ActionTypes.TRADER_FETCH_RANK_DATA,
        nodata: true
      });
    }
  };
};

export type TraderCopyDataType = { kolCustomerId: string};
export type TraderCopyTriggerThunkActionType = ThunkAction<void, void, void, TraderCopyDataType>;
export type TraderCopyTriggerThunkDispatchType = ThunkDispatch<void, void, TraderCopyDataType>;

export function TraderCopyTrigger (kolCustomerId: string): TraderCopyTriggerThunkActionType {
  return (dispatch: TraderCopyTriggerThunkDispatchType) => {
    dispatch({ type: ActionTypes.TRADER_COPY_TRIGGER, kolCustomerId });
  }
}

export type TraderStopCopyTriggerThunkActionType = ThunkAction<void, void, void, TraderCopyDataType>;
export type TraderStopCopyTriggerThunkDispatchType = ThunkDispatch<void, void, TraderCopyDataType>;

export function TraderStopCopyTrigger (kolCustomerId: string): TraderStopCopyTriggerThunkActionType {
  return (dispatch: TraderStopCopyTriggerThunkDispatchType) => {
    dispatch({ type: ActionTypes.TRADER_STOP_COPY_TRIGGER, kolCustomerId });
  }
}

export type FetchMarketFiatKlineDataActionType = ThunkAction<void, StateType, void, TraderFetchRankDataErrorActionType | TraderFetchRankDataActionType>;
export type FetchMarketFiatKlineDataDispatchType = ThunkDispatch<StateType, void, TraderFetchRankDataErrorActionType | TraderFetchRankDataActionType>;

export function FetchMarketKlineData (props: { type: MarketType, symbol: string}, chartParams: { symbol: string, resolution: string, from: number, to: number}): FetchMarketFiatKlineDataActionType {
  return (dispatch: FetchMarketFiatKlineDataDispatchType, getState: ()=> StateType) => {
    KlineAPI.newSimpleKlineData(chartParams)
      .then((response: KlineResponseType) => {
        if (response.success && response.obj && response.obj.bars) {
          // let dataList = response.obj.bars?.splice(0, 30);
          const dataList: string[] = response.obj.bars && response.obj.bars.splice(0, 30);

          const data = []
          dataList.forEach(function (_item: string) {
            const obj = {}
            obj.ts = moment(moment.unix(Number(_item.split(',')[0])).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').valueOf();
            obj.value = _item.split(',')[1] ? Number(_item.split(',')[1]) : 0;
            data.push(obj)
          })
          console.log(data)

          // dispatch({
          //   rankType: props.type,
          //   options: options,
          //   temKey: temKey,
          //   weekKey: weekKey,
          //   type: ActionTypes.TRADER_FETCH_RANK_DATA,
          //   nodata: true
          // });
          if (props.type === 'fiat') {
            dispatch({
              type: ActionTypes.MARKET_FIAT_KLINE_DATA,
              symbol: props.symbol,
              resolution: chartParams.resolution,
              fiatKlineList: data.reverse()
            })
          }

          if (props.type === 'swap') {
            dispatch({
              type: ActionTypes.MARKET_SWAP_KLINE_DATA,
              symbol: props.symbol,
              resolution: chartParams.resolution,
              swapKlineList: data.reverse()
            })
          }

          if (props.type === 'spot') {
            dispatch({
              type: ActionTypes.MARKET_SPOT_KLINE_DATA,
              symbol: props.symbol,
              resolution: chartParams.resolution,
              spotKlineList: data.reverse()
            })
          }
        }
      })
      .catch((error: Error) => {
        console.log(error);
        bugsnag.notify(new Error('[trader] -- [FetchMarketKlineData] -- [error]: ' + error.toString()));
        if (props.type === 'fiat') {
          dispatch({
            type: ActionTypes.MARKET_FIAT_KLINE_ERROR,
            error
          });
        }

        if (props.type === 'swap') {
          dispatch({
            type: ActionTypes.MARKET_SWAP_KLINE_ERROR,
            error
          });
        }

        if (props.type === 'spot') {
          dispatch({
            type: ActionTypes.MARKET_SPOT_KLINE_ERROR,
            error
          });
        }
      });
  };
};

export type FetchCurrencyListThunkActionType = ThunkAction<void, StateType, void, TraderFetchRankDataErrorActionType | TraderFetchRankDataActionType>;
export type FetchCurrencyListThunkDispatchType = ThunkDispatch<StateType, void, TraderFetchRankDataErrorActionType | TraderFetchRankDataActionType>;

export function FetchCurrencyList (props: { force: boolean }): FetchCurrencyListThunkActionType {
  return (dispatch: FetchCurrencyListThunkDispatchType, getState: ()=> StateType) => {
    dispatch({ type: ActionTypes.TRADER_FETCH_CURRENCY_DATA_REQUEST });
    const { TraderReducer } = getState();
    const last = new Date(TraderReducer.traderUpdateTs);
    if (
      // __DEV__ ||
      TraderReducer.traderUpdateTs === 0 ||
      new Date().getTime() - last > 1000 * 60 * 5 ||
      props.force
    ) {
      // console.log('fetch trader info at ...' + new Date().getTime());
      // request to api, set trigger SetTraderInfo
      const trader = new TraderAPI(getClient(), null, {});
      trader
        .getCurrencyTypeList()
        .then((r: RankingBatchResponseType) => {
          console.log(JSON.stringify(r));
          const currencyList = r.result.top_currencies;
          dispatch({
            type: ActionTypes.TRADER_FETCH_CURRENCY_DATA,
            currencyList: !currencyList ? null : currencyList
          });
        })
        .catch((err: Error) => {
          console.log(JSON.stringify(err));
          bugsnag.notify(new Error('[trader] -- [FetchCurrencyList] -- [error]: ' + err.toString()));
          dispatch({
            type: ActionTypes.TRADER_FETCH_RANK_DATA_ERROR,
            error: err
          });
        });
    } else {
      dispatch({
        type: ActionTypes.TRADER_FETCH_CURRENCY_DATA,
        nodata: true
      });
    }
  };
};

type MyMiscUnionType =
  TraderMyMiscRequestActionType
  | TraderMyMiscResetActionType
  | TraderMyMiscErrorActionType
  | TraderMyMiscActionType;
export type FetchMyTraderInfoThunkActionType = ThunkAction<Promise<void>, void, void, MyMiscUnionType>;
export type FetchMyTraderInfoThunkDispatchType = ThunkDispatch<void, void, MyMiscUnionType>;

/**
 * 个人trader social设置
 * @param id
 * @param props
 * @returns {function(...[*]=)}
 * @constructor
 */
export function FetchMyTraderInfo (id: string, props: { ignoreError: boolean, backgroundRequest: boolean } = {
  ignoreError: true,
  backgroundRequest: true
}): FetchMyTraderInfoThunkActionType {
  return async (dispatch: FetchMyTraderInfoThunkDispatchType) => {
    if (!props.backgroundRequest) {
      dispatch({ type: ActionTypes.TRADER_MY_MISC_REQUEST });
    }
    try {
      const response: CustomerMisc = await getUserInfo(id);
      const customer = response;
      if (customer) {
        dispatch({
          type: ActionTypes.TRADER_MY_MISC,
          myMisc: customer
        });
      } else {
        dispatch({
          type: ActionTypes.TRADER_MY_MISC_RESET
        });
      }
    } catch (error) {
      bugsnag.notify(new Error('[trader] -- [FetchMyTraderInfo] -- [error]: ' + error.toString()));
      if (!props.ignoreError) {
        dispatch({
          type: ActionTypes.TRADER_MY_MISC_ERROR,
          error: 'system.error'
        });
      }
    }
  };
};

export type FetchAllPnlOrdersThunkActionType = ThunkAction<Promise<void>, void, void, CfdFetchAllPnlOrdersActionType>;
export type FetchAllPnlOrdersThunkDispatchType = ThunkDispatch<void, void, CfdFetchAllPnlOrdersActionType>;

export function FetchAllPnlOrders (): FetchAllPnlOrdersThunkActionType {
  return async (dispatch: FetchAllPnlOrdersThunkDispatchType) => {
    const params = {
      customerId: USER_ID,
      saas_id: 'kiki',
      offset: 0,
      limit: 100,
      orderType: [0, 1],
      marginMode: [0, 1]
    };
    const pnlOrderMap = {};
    try {
      const response: CfdOrderLiteListResponseType = await CFDTradeAPI.orderLiteList(params);
      if (response && response.success) {
        const orderList = response.obj;
        if (orderList && orderList.length > 0) {
          let accountType = 'SPOT';
          orderList.forEach((o: CfdOrderLite) => {
            if (o.orderType === 1) {
              accountType = 'CROSS';
            } else {
              accountType = 'ISOLATED';
            }
            const accountMap = pnlOrderMap[accountType] ?? {};
            const orders = accountMap[o.quoteCurrency] ?? [];
            orders.push(o);
            accountMap[o.quoteCurrency] = orders;
            pnlOrderMap[accountType] = accountMap;
          });
        }
      }
      dispatch({
        type: ActionTypes.CFD_FETCH_ALL_PNL_ORDERS,
        pnlOrderMap: pnlOrderMap
      });
    } catch (e) {
      bugsnag.notify(new Error('[trader] -- [FetchAllPnlOrders] -- [error]: ' + e.toString()));
      dispatch({
        type: ActionTypes.CFD_FETCH_ALL_PNL_ORDERS,
        pnlOrderMap: pnlOrderMap
      });
    }
  };
};

// REVENUE_EVENT_TIME
export type TriggerRevenueEventThunkActionType = ThunkAction<Promise<void>, StateType, void, RevenueEventTimeActionType>;
export type TriggerRevenueEventThunkDispatchType = ThunkDispatch<StateType, void, RevenueEventTimeActionType>;

export function TriggerRevenueEvent (): TriggerRevenueEventThunkActionType {
  return async (dispatch: TriggerRevenueEventThunkDispatchType, getState: ()=> StateType) => {
    const { TraderReducer } = getState();
    let maxTime = TraderReducer.maxTime;
    let dcMaxTime = TraderReducer.dcMaxTime;
    const current = new Date().getTime();
    if (maxTime + 1000 * 60 * 5 < current) {
      // 5分钟只取一次
      OTCAPI.depositList({
        limit: 5
      })
        .then((response: OtcDepositListResponseType) => {
          if (response.success) {
            const itemList = response.obj || [];
            itemList.forEach((item: OtcOrder) => {
              const modified = moment(item.modified).valueOf();
              if (
                item.status === 'deposit_success' && modified > maxTime) {
                helper.adjustTrackRevenueEvent(
                  false,
                  item.amount,
                  item.currency,
                  item.orderNum
                );
              }
              maxTime = Math.max(maxTime, modified);
            });
            dispatch({
              type: ActionTypes.REVENUE_EVENT_TIME,
              maxTime: maxTime
            });
          }
        })
        .catch((error: Error) => {
          // TODO fix empty handle
          bugsnag.notify(new Error('[trader] -- [OTCAPI.depositList] -- [error]: ' + error.toString()));
        });
    }

    if (dcMaxTime + 1000 * 60 * 5 < current) {
      // 5分钟只取一次
      try {
        const response: TransactionListResponseType = await AccountAPI.transactionList({ type: 1, status: 12, limit: 5, isV1API: true });
        if (response.success) {
          const ret = response.obj.rows || [];
          ret.forEach((item: ExdmTransaction) => {
            const modified = moment(item.modified).valueOf();
            if (item.status === 12 && modified > dcMaxTime) {
              helper.adjustTrackRevenueEvent(
                true,
                item.transactionMoney,
                item.coinCode,
                item.transactionNum
              );
            }
            dcMaxTime = Math.max(dcMaxTime, modified);
          });
          dispatch({
            type: ActionTypes.REVENUE_EVENT_TIME,
            dcMaxTime: dcMaxTime
          });
        }
      } catch (e) {
        // TODO fix empty cache
        bugsnag.notify(new Error('[trader] -- [AccountAPI.transactionList] -- [error]: ' + e.toString()));
      }
    }
  };
};

export type SetCleanTraderDataThunkActionType = ThunkAction<void, void, void, TraderUserInfoCleanActionType>;
export type SetCleanTraderDataThunkDispatchType = ThunkDispatch<void, void, TraderUserInfoCleanActionType>;

export function SetCleanTraderData (): SetCleanTraderDataThunkActionType {
  return (dispatch: SetCleanTraderDataThunkDispatchType) => {
    dispatch({ type: ActionTypes.TRADER_USER_INFO_CLEAN });
  };
};

// TRADER_ENTRUST_DATA
type TraderEntrustDataUnionActionType = TraderEntrustDataActionType | TraderEntrustDataErrorActionType;
export type FetchEntrustListThunkActionType = ThunkAction<void, void, void, TraderEntrustDataUnionActionType>;
export type FetchEntrustListThunkDispatchType = ThunkDispatch<void, void, TraderEntrustDataUnionActionType>;

export function FetchEntrustList (params: { offset?: string, list?: ?Array<*>, limit?: number, orderType?: string, side?: string, symbol?: string, type: string} = {}): FetchEntrustListThunkActionType {
  return (dispatch: FetchEntrustListThunkDispatchType) => {
    // params.status = 'success, fail, cancel';
    // params.status = 'pending';
    const { offset, limit, orderType, side, symbol } = params;
    const type = params.type
    const entrustList = params.list || [];

    dispatch({
      type: ActionTypes.TRADER_ENTRUST_DATA_REQUEST
    });

    // const paramsItem = params;
    SpotTradeAPI.getTradeOpenOrders({ from_order_id: offset ?? '', limit: limit ?? 10, side: side ?? '', symbol: symbol ?? '', order_type: orderType ?? '', type: type, query_status: 'open' })
      .then((response: SpotOrderEntrustListResponseType) => {
        if (response.success && response.obj) {
          const list: SpotOrderEntrust[] = response.obj;
          const nextOffset = response.obj[response.obj.length - 1]?.orderId ?? '';

          dispatch({
            nextPage: list.length === 10,
            nextOffset: nextOffset,
            loadingMoreEntrust: false,
            type: ActionTypes.TRADER_ENTRUST_DATA,
            entrustList: entrustList.concat(list || [])
          });
        }
      })
      .catch((error: Error) => {
        bugsnag.notify(new Error('[trader] -- [FetchEntrustList] -- [error]: ' + error.toString()));
        dispatch({
          type: ActionTypes.TRADER_FETCH_DATA_ERROR,
          error: error
        });
        console.log(error)
      });
  };
};

export type HistorySearchDataThunkActionType = ThunkAction<void, void, void, HistorySearchDataActionType>;
export type HistorySearchDataThunkDispatchType = ThunkDispatch<void, void, HistorySearchDataActionType>;

export function historySearchDataChange (value: ExtractMarketData[]): HistorySearchDataThunkActionType {
  return (dispatch: HistorySearchDataThunkDispatchType) => {
    dispatch({
      type: ActionTypes.TRADER_HISTORY_SEARCH,
      historySearchList: value
    });
  };
};

export type FavoriteListFetchThunkActionType = ThunkAction<void, void, void, FavoriteListFetchActionType>;
export type FavoriteListFetchThunkDispatchType = ThunkDispatch<void, void, FavoriteListFetchActionType>;

export function FavoriteListFetch (callback?: function): FavoriteListFetchThunkActionType {
  return (dispatch: FavoriteListFetchThunkDispatchType) => {
    FavoriteCustomAPI
      .favoriteList()
      .then((response: FavoriteListResponseType) => {
        if (response && response.success) {
          const data = (response.obj);
          dispatch({
            type: ActionTypes.FAVORITE_LIST_FETCH,
            favoriteObject: data
          });
          callback && callback()
        } else {
          console.log('response.ob---error', response)
        }
      })
      .catch((error: Error) => {
        console.log(error)
        bugsnag.notify(new Error('[trader] -- [FavoriteListFetch] -- [error]: ' + error.toString()));
      })
  };
};

export type FirstShowCustomChoicesThunkActionType = ThunkAction<void, void, void, FirstShowCustomChoicesActionType>;
export type FirstShowCustomChoicesThunkDispatchType = ThunkDispatch<void, void, FirstShowCustomChoicesActionType>;

export function FirstShowCustomChoicesChange (value: boolean): FirstShowCustomChoicesThunkActionType {
  return (dispatch: FirstShowCustomChoicesThunkDispatchType) => {
    dispatch({
      type: ActionTypes.TRADER_CUSTOM_FIRST_SHOW,
      firstShowCustomChoices: value
    });
  };
};

/// /// MUST BE last setsion for exporting
export type TraderThunkActionsType =
  FetchEntrustListThunkActionType
  | SetCleanTraderDataThunkActionType
  |
  TriggerRevenueEventThunkActionType
  | FetchAllPnlOrdersThunkActionType
  |
  FetchMyTraderInfoThunkActionType |
  FetchCurrencyListThunkActionType |
  FetchTraderRankThunkActionType |
  TraderCopyTriggerThunkActionType |
  TraderStopCopyTriggerThunkActionType |
  FetchMoreTraderInfoThunkActionType |
  FetchTraderInfoThunkActionType;
