// @flow
import React, { Component } from 'react'
import type { Element } from 'react';
import { Animated, View, StyleSheet, Text } from 'react-native'
import FastImage from 'react-native-fast-image'
import p from '../utils/Transfrom';
import I18n from '../utils/i18n';
import { colors } from '../styles/Common'
import type { CombinedReducerType } from '../reducers';

type SourceType = {
  uri: string
};
type OwnPropType = {
  style: {}, // TODO
  isGif: boolean,
  isLongImg: boolean,
  isLoaded: boolean,
  text: string,
  source: SourceType | string,
  imgStyle: {}, // TODO
  // unloadImgStyle: {},
  imgLenCom: {} // view //TODO
};
type StateType = {
  loaded: boolean,
  style: {}, // TODO
  fadeAnim: number
};

type ProsType = OwnPropType & CombinedReducerType;

class GifTip extends Component <ProsType, StateType> {
  render (): Element<*> {
    return (
        <View style={{ position: 'absolute', justifyContent: 'center', alignItems: 'center', zIndex: 9, top: 0, right: 0, borderRadius: p(3), width: p(42), height: p(26), backgroundColor: 'rgba(0,0,0,0.25)' }}>
            <Text style={{ fontSize: p(18), color: '#fff' }}>{I18n.t('gif')}</Text>
        </View>
    )
  }
}

class LongImgTip extends Component <ProsType, StateType> {
  render (): Element<*> {
    return (
        <View style={{ position: 'absolute', justifyContent: 'center', alignItems: 'center', zIndex: 9, top: 0, right: 0, borderRadius: p(3), width: p(42), height: p(26), backgroundColor: 'rgba(0,0,0,0.25)' }}>
            <Text style={{ fontSize: p(18), color: '#fff' }}>{I18n.t('longPhoto')}</Text>
        </View>
    )
  }
}

export default class PlaceHolderFastImage extends Component <ProsType, StateType> {
  constructor (props: ProsType) {
    super(props)

    this.state = {
      loaded: false,
      style: StyleSheet.flatten(props.style),
      fadeAnim: new Animated.Value(0)
    }
  }

  onLoadEnd: () => void = () => {
    const that = this
    const { fadeAnim } = this.state
    setTimeout(function () {
      that.setState({ loaded: true })
      Animated.timing(
        fadeAnim,
        {
          toValue: 1,
          duration: 500,
          useNativeDriver: true
        }
      ).start();
    }, 1)
  };

  render (): Element<*> {
    const { isGif, isLongImg, isLoaded, text = 'KikiTrade', textStyle = {} } = this.props
    let { loaded } = this.state
    if (typeof isLoaded !== 'undefined') {
      loaded = loaded && isLoaded
    }
    return <View style={[{ width: p(300) }, this.props.style]}>
      {
        loaded && isGif
          ? <GifTip/>
          : null
      }
      {
        loaded && isLongImg
          ? <LongImgTip/>
          : null
      }

      {
        !loaded &&
        <View style={[{ backgroundColor: colors.white5, width: '100%', height: '100%', borderRadius: p(6), alignItems: 'center', justifyContent: 'center' }, textStyle]}>
            <Text style={{ color: '#333' }}>{text}</Text>
        </View>
      }

      <View style={{
        width: loaded ? '100%' : 0,
        height: loaded ? '100%' : 0,
        opacity: 1
      }}>
        <FastImage
          source={this.props.source}
          {...this.props}
          style={loaded ? [this.props.imgStyle] : [{ width: 0, height: 0 }]}
          // style={loaded ? [this.props.imgStyle] : [{ width: 0, height: 0 }, this.props.unloadImgStyle]}
          onLoadEnd={this.onLoadEnd}
        >
          {this.props.imgLenCom}
        </FastImage>
      </View>

    </View>
  }
}
