// @flow
/* eslint-disable no-case-declarations */
import AsyncStorage from '@react-native-async-storage/async-storage';
import persistReducer from 'redux-persist/es/persistReducer';
import { API_ENV } from '@env';
import { ActionTypes } from '../actions/ActionTypes';
import type { Reducer } from 'redux';
import type { PersistActionType, MarketStateType } from '../actions/common';
import type {
  MarketActionsType
} from '../actions/MarketAction';
import type { ExtractMarketData, SymbolList } from '../models/market';
import helper from '../utils/helper';
import defaultData from '../offlineData';

const initialState: MarketStateType = {
  fiatMarketItems: defaultData.marketItems,
  spotMarketItems: defaultData.marketItems1,
  swapMarketItems: defaultData.marketItems1,
  symbolMap: {},
  spotSymbolMap: {},
  fiatSymbolMap: {},
  marketSymbols: [],
  coinDetail: null,
  otherRateDetail: null
};

export type PersistMarketActionType = MarketActionsType | PersistActionType;
type VoidMarketStateType = MarketStateType | void;

/**
 * margin 盘口列表
 * @param state
 * @param action
 * @returns {{[p: string]: SymbolList}}
 */
function extractSymbolMap (state: MarketStateType, action: { symbols: SymbolList[] }): {[key: string]: SymbolList} {
  const symbolMap = state.symbolMap;
  if (action.symbols && action.symbols.length > 0) {
    action.symbols.forEach((symbol: SymbolList) => {
      symbolMap[symbol.coinCode] = symbol;
    });
  }
  return symbolMap;
}

/**
 * spot 盘口列表
 * @param state
 * @param action
 * @returns {{[p: string]: SymbolList}}
 */
function extractSpotSymbolMap (state: MarketStateType, action: { spotSymbols: SymbolList[] }): {[key: string]: SymbolList} {
  const spotSymbolMap = state.spotSymbolMap;
  if (action.spotSymbols && action.spotSymbols.length > 0) {
    action.spotSymbols.forEach((symbol: SymbolList) => {
      spotSymbolMap[symbol.coinCode] = symbol;
    });
  }
  return spotSymbolMap;
}

/**
 * fiat 盘口列表
 * @param state
 * @param action
 * @returns {{[p: string]: SymbolList}}
 */
function extractFiatSymbolMap (state: MarketStateType, action: { fiatSymbols: SymbolList[] }): {[key: string]: SymbolList} {
  const fiatSymbolMap = state.fiatSymbolMap;
  if (action.fiatSymbols && action.fiatSymbols.length > 0) {
    action.fiatSymbols.forEach((symbol: SymbolList) => {
      fiatSymbolMap[symbol.coinCode] = symbol;
    });
  }
  return fiatSymbolMap;
}

function extractFiatCoinDetail (rateType: ?string, action: { fiatSymbols: SymbolList[] }): {coinDetail: ?SymbolList, otherRateDetail: ?SymbolList} {
  let otherRateSymbol = 'USDT_HKD';
  let coinDetail: SymbolList;
  let otherRateDetail: SymbolList;
  if (rateType) {
    const quoteCurrency = rateType.split('_')[1];
    if (quoteCurrency) {
      if (quoteCurrency === 'HKD') {
        otherRateSymbol = 'USDT_USD';
      }
      action.fiatSymbols.forEach((item: SymbolList) => {
        if (item.coinCode === rateType) {
          coinDetail = item;
        }
        if (item.coinCode === otherRateSymbol) {
          otherRateDetail = item
        }
      })
      return { coinDetail, otherRateDetail };
    }
  }
  return { coinDetail, otherRateDetail };
}

function extractFiatCoinDetailFromMarket (rateType: ?string, fiatMarketData: ExtractMarketData[]): {coinDetail: ?ExtractMarketData, otherRateDetail: ?ExtractMarketData} {
  let otherRateSymbol = 'USDT_HKD';
  let coinDetail: ExtractMarketData;
  let otherRateDetail: ExtractMarketData;
  if (rateType) {
    const quoteCurrency = rateType.split('_')[1];
    if (quoteCurrency) {
      if (quoteCurrency === 'HKD') {
        otherRateSymbol = 'USDT_USD';
      }
      fiatMarketData.forEach((item: ExtractMarketData) => {
        if (item.coinCode === rateType) {
          coinDetail = item;
        }
        if (item.coinCode === otherRateSymbol) {
          otherRateDetail = item
        }
      })
      return { coinDetail, otherRateDetail };
    }
  }
  return { coinDetail, otherRateDetail }
}

const marketReducer: Reducer<MarketStateType, PersistMarketActionType> = (state: VoidMarketStateType, action: PersistMarketActionType): MarketStateType => {
  if (action.type === ActionTypes.REHYDRATE) {
    console.log('get rehydrate return');
    return state ?? initialState;
  }
  if (!state) {
    return initialState;
  }
  switch (action.type) {
    // 获取全部数据

    case ActionTypes.CFD_REFRESH_SYMBOL_INFO: {
      const symbolMap = extractSymbolMap(state, action);
      return Object.assign({}, state, {
        symbolMap: symbolMap
      });
    }

    case ActionTypes.CFD_REFRESH_SPOT_SYMBOL_INFO: {
      const spotSymbolMap = extractSpotSymbolMap(state, action);
      return Object.assign({}, state, {
        spotSymbolMap: spotSymbolMap
      });
    }

    case ActionTypes.CFD_REFRESH_FIAT_SYMBOL_INFO: {
      const fiatSymbolMap = extractFiatSymbolMap(state, action);
      return Object.assign({}, state, {
        fiatSymbolMap: fiatSymbolMap
      });
    }

    case ActionTypes.SPOT_SYMBOL_LIST:
      return Object.assign({}, state, { spotMarketItems: helper.extractMarketDataList(action.spotMarketItems) });
    case ActionTypes.FIAT_SYMBOL_LIST:
      return Object.assign({}, state, { fiatMarketItems: helper.extractMarketDataList(action.fiatMarketItems) });
    case ActionTypes.SWAP_SYMBOL_LIST:
      return Object.assign({}, state, { swapMarketItems: helper.extractMarketDataList(action.swapMarketItems) });
    case ActionTypes.FULL_MARKET_SYMBOLS:
      const { coinDetail, otherRateDetail } = extractFiatCoinDetailFromMarket(action.rateType, action.marketSymbols);
      return Object.assign({}, state, { marketSymbols: helper.extractMarketDataList(action.marketSymbols) }, coinDetail ? { coinDetail } : {}, otherRateDetail ? { otherRateDetail } : {});
    case ActionTypes.FULL_MARKET_SYMBOLS_REFRESH:
      return Object.assign({}, state, { marketSymbols: action.marketSymbols });

    case ActionTypes.FULL_SYMBOL_LIST:
      const extractedFiatCoinDetail = extractFiatCoinDetail(action.rateType, { fiatSymbols: action.fiatMarketItems });
      return Object.assign({}, state, {
        swapMarketItems: helper.extractMarketDataList(action.swapMarketItems),
        fiatMarketItems: helper.extractMarketDataList(action.fiatMarketItems),
        spotMarketItems: helper.extractMarketDataList(action.spotMarketItems),
        symbolMap: extractSymbolMap(state, { symbols: action.swapMarketItems }),
        spotSymbolMap: extractSpotSymbolMap(state, { spotSymbols: action.spotMarketItems }),
        fiatSymbolMap: extractFiatSymbolMap(state, { fiatSymbols: action.fiatMarketItems })
      }, extractedFiatCoinDetail.coinDetail ? { coinDetail: helper.extractMarketDataItem(extractedFiatCoinDetail.coinDetail) } : {},
      extractedFiatCoinDetail.otherRateDetail ? { otherRateDetail: helper.extractMarketDataItem(extractedFiatCoinDetail.otherRateDetail) } : {});

    case ActionTypes.TRADER_SYMBOL_DATA: {
      let coinDetail = state.coinDetail;
      if (action.coinDetail) {
        coinDetail = action.coinDetail;
      }
      let otherRateDetail = state.otherRateDetail;
      if (action.otherRateDetail) {
        otherRateDetail = action.otherRateDetail;
      }
      return Object.assign({}, state, {
        coinDetail: coinDetail,
        otherRateDetail: otherRateDetail
      });
    }

    default:
      return state;
  }
};

const marketPersistConfig = {
  key: 'market',
  debug: API_ENV === 'prod' || API_ENV === 'pro',
  timeout: 10000,
  storage: AsyncStorage,
  blacklist: [
  ]
};

const persistMarketReducer: (MarketStateType, PersistMarketActionType) => MarketStateType = persistReducer<MarketStateType, PersistMarketActionType>(marketPersistConfig, marketReducer);

export default persistMarketReducer;
