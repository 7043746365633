// @flow

import React from 'react';
import p from '../utils/Transfrom';
import { Animated, View } from 'react-native';
import type { Element } from 'react';
import { RefreshHeader } from 'react-native-spring-scrollview';
import LottieView from 'lottie-react-native';

const defaultTopIndicatorHeight = p(170)

type StateType = {};

type ProsType = {};

export default class LottieHeader extends RefreshHeader<StateType, ProsType> {
  // 刷新组件的高度
  static height: number = defaultTopIndicatorHeight;

  constructor (props: ProsType) {
    super(props);
    this.state = {};
  }

  render (): Element<*> {
    // let progress = this.props.offset.interpolate({
    //   inputRange: [-defaultTopIndicatorHeight - 1, -defaultTopIndicatorHeight, 0],
    //   outputRange: [1, 1, 0]
    // });
    const fadeAnim = this.props.offset.interpolate({
      inputRange: [-defaultTopIndicatorHeight - 1, -defaultTopIndicatorHeight, 0],
      outputRange: [1, 1, 0]
    });
    const scaleAnim = this.props.offset.interpolate({
      inputRange: [-defaultTopIndicatorHeight - 1, -defaultTopIndicatorHeight, 0],
      outputRange: [1, 1, 0]
    });

    return (
      <View style={{ flex: 1 }}>
        <Animated.View style={{ alignItems: 'center', opacity: fadeAnim, transform: [{ scale: scaleAnim }] }} >
          <LottieView
            style={{
              height: defaultTopIndicatorHeight,
              width: p(300)
            }}
            autoSize={false}
            autoPlay={this.state.status === 'refreshing'}
            loop={this.state.status === 'refreshing'}
            // eslint-disable-next-line flowtype/no-weak-types
            ref={(animation: any) => {
              if (animation) {
                animation.play();
              }
            }}
            // progress={progress}
            imageAssetsFolder={'lottie/image'}
            /* $FlowFixMe */
            source={require('../animations/pulltorefresh.json')}
          />

        </Animated.View>
      </View>
      // <View style={{ flex: 1, marginTop: 20 }}>
      //   <LottieView
      //     // source={
      //     //   this.state.status === 'refreshing' ? require('../animations/refreshing2.json') : require('../animations/refreshing.json')
      //     // }
      //     imageAssetsFolder={'lottie/image'}
      //     source={require('../animations/pulltorefresh.json')}
      //     progress={progress}
      //     autoPlay={this.state.status === 'refreshing'}
      //     loop={this.state.status === 'refreshing'}
      //   />
      // </View>
    )
  }
}
