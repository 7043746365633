// @flow
import React from 'react';
import type { Element } from 'react';
import {
  StyleSheet,
  Platform
} from 'react-native';
import PlaceHolderFastImage from './PlaceHolderFastImage'
import { TouchableOpacity } from 'react-native-gesture-handler';

import helper from '../utils/helper'
import { width, height, colors, radius } from '../styles/Common'

function EventItem ({ item, navigation, isLogin }: {
  item: {image: string | number, url: string, title: string},
  // eslint-disable-next-line flowtype/no-weak-types
  navigation: {navigate: (route: string, params: any)=>{}},
  isLogin: boolean
}): Element<*> {
  return (
    <TouchableOpacity activeOpacity={0.8} onPress={() => {
      helper.url_redirect(
        item.url,
        item.title,
        navigation.navigate,
        isLogin
      );
      try {
        helper.trackEvent('NewHome Click EventItem ' + item.title, { item: JSON.stringify(item) });
      } catch (e) {
      }
    }}>
      <PlaceHolderFastImage
        imgStyle={styles.eventsRowStyle}
        style={styles.eventsRowStyle}
        text={''}
        textStyle={styles.eventsRowStyle}
        source={
          typeof item.image === 'string'
            ? { uri: item.image }
            : item.image
        }
        fallback={Platform.OS === 'android'}
      />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  eventsRowStyle: {
    width: width.w400,
    height: height.h192,
    borderRadius: radius.r36,
    backgroundColor: colors.white5
  }
})
// eslint-disable-next-line flowtype/no-weak-types
const MemoizedEventItem: any = React.memo(EventItem);
export default MemoizedEventItem
