import {
  ImageBackground,
  Image,
  View
} from 'react-native';
import React from 'react';

function canUseWebP () {
  const elem = document.createElement('canvas');

  if (elem.getContext && elem.getContext('2d')) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }

  // very old browser like IE 8, canvas not supported
  return false;
}

ImageBackground.resizeMode = {
  stretch: 'stretch',
  cover: 'cover',
  contain: 'contain'
}

export default function (props) {
  const _props = Object.assign({}, props)
  if (!_props.source) {
    return (
             <View {..._props}>
                {_props.children}
             </View>
    )
  }

  try {
    if (!_props.source.uri && !canUseWebP() && _props?.source?.indexOf('webp') !== -1) {
      _props.source = '/assets/' + _props.source.split('.')[1] + '.png'
      console.log('fallback to use png' + _props.source.split('.')[1] + '.png')
    }

    if (_props?.source?.uri && !canUseWebP() && _props?.source?.uri?.indexOf('webp') !== -1) {
      _props.source.uri = _props.source.uri.replace('webp', 'png')
    }
  } catch (e) {
    console.log(_props)
    console.log(e)
  }

  console.log(_props)

  let imageStyle = {
  }
  if (_props?.style?.borderRadius) {
    imageStyle.borderRadius = _props?.style?.borderRadius
  }

  if (typeof _props?.style === 'number') {
    imageStyle = _props?.style
  }

  if (_props.children) {
    return <ImageBackground {..._props} imageStyle={imageStyle} />
  } else {
    return <Image {..._props} imageStyle={imageStyle} />
  }
}
