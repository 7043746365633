// @flow

import React, { Component } from 'react';
import type { Element } from 'react';
import { Text, View } from 'react-native';

type WalletProps = {};
class Wallet extends Component {
  constructor (props: WalletProps) {
    super(props);
    this.state = {
    };
  }

  componentDidMount () {
  }

  render (): Element<*> {
    return (
        <View>
              <Text>Wallet</Text>
      </View>

    )
  }
}
export default Wallet;
