import oss from 'ali-oss'
let client;
let uploadProgressListener
const ALiyunOSS = {
  initWithSecurityToken: function (
    SecurityToken,
    OSS_ACCESSKEYID,
    OSS_ACCESSKEYSECRET,
    endpoint,
    bucket,
    region) {
    client = oss(
      {
        region: region,
        accessKeyId: OSS_ACCESSKEYID,
        accessKeySecret: OSS_ACCESSKEYSECRET,
        bucket: bucket,
        stsToken: SecurityToken,
        endpoint: endpoint
      }
    )
  },
  enableDevMode: function () {
    return true
  },
  asyncUpload: function (name, localFile, opt) {
    opt = opt || {}
    if (opt && opt.progress) {
      opt.progress = uploadProgressListener
    }
    return client.multipartUpload(name, localFile, opt)
  },
  addEventListener: function (name, handler) {
    if (name === 'uploadProgress') {
      uploadProgressListener = handler
    }
  }
}

export default ALiyunOSS;
