// @flow
import React, { Component } from 'react';
import type { Element } from 'react';
// import p from '../utils/Transfrom';
import { Defs, LinearGradient, Stop } from 'react-native-svg';
import { AreaChart, Path } from 'react-native-svg-charts';
import { curveNatural } from 'd3-shape';

type AreaChartOwnPropType = {
  data: Array<number>,
  areaStyle: {height: number}
};
type AreaChartStateType = {};

type AreaChartProsType = AreaChartOwnPropType;

export default class AreaCharts extends Component <AreaChartProsType, AreaChartStateType> {
  constructor (props: AreaChartProsType) {
    super(props);
    this.state = {};
  }

  render (): Element<*> {
    const { data, areaStyle } = this.props;

    // eslint-disable-next-line flowtype/no-weak-types
    const Gradient = (): any => (
      <Defs key={ 'defs' }>
        <LinearGradient id={ 'gradient' } x1={ '0%' } y={ '0%' } x2={ '0%' } y2={ '100%' }>
          <Stop offset={ '0%' } stopColor={ '#6F40EE' } stopOpacity={ 0.2 }/>
          <Stop offset={ '100%' } stopColor={ '#A874F8' } stopOpacity={ 0 }/>
        </LinearGradient>
      </Defs>
    )

    // eslint-disable-next-line flowtype/no-weak-types
    const LineGradient = (): any => (
      <Defs key={ 'defs' }>
        <LinearGradient id={ 'line-gradient' } x1={ '0%' } y1={ '0%' } x2={ '100%' } y2={ '100%' }>
          <Stop offset={ '0%' } stopColor={ '#6F40EE' } stopOpacity={ 0 }/>
          <Stop offset={ '25%' } stopColor={ '#6F40EE' } stopOpacity={ 0.6 }/>
          <Stop offset={ '75%' } stopColor={ '#6F40EE' } stopOpacity={ 0.6 }/>
          <Stop offset={ '100%' } stopColor={ '#6F40EE' } stopOpacity={ 0 }/>
        </LinearGradient>
      </Defs>
    )

    // eslint-disable-next-line flowtype/no-weak-types
    const Line = (props: {line: any}): any => (
      <Path
        key={ 'line' }
        d={ props.line }
        strokeWidth={2}
        stroke={ 'url(#line-gradient)' }
        fill={ 'none' }
      />
    )

    return (
      <AreaChart
        style={areaStyle}
        data={data}
        curve={curveNatural}
        // contentInset={{ top: p(10), bottom: p(12) }}
        contentInset={{ top: 10, bottom: 12 }}
        svg={{
          fill: 'url(#gradient)',
          clipPath: 'url(#clip-path-1)'
        }}
        start={Math.min(...data)}
      >
        <Gradient/>
        <LineGradient/>
        <Line line={[]}/>
      </AreaChart>
    );
  }
}
