import React from 'react';
import {
  View,
  Text
} from 'react-native';

import { colors, fontSize, lineHeight, margin } from '../../styles/Common'

function Hyperlink (props) {
  // 用于找 url 的正则表达式
  // console.log(text)
  const text = props?.text ?? ''
  const delimiter = /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9-]{1,61}[a-z0-9])?\.[^.|\s])+[a-z.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_/~#&=;%+?\-\\(\\)]*)/gi;
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap'
      }}
    >
      {/* 按正则分割为数组，最终渲染时被显示在了一起 */}
      {text.split(delimiter).map(word => {
        // foo bar baz
        // http://example.org
        //  bar
        // 从以上分割的内容中找到具体的url的部分
        // 进行超链接的处理
        const match = word.match(delimiter);
        if (match) {
          const url = match[0];
          return (
            <a style={{ color: '#2980b9' }} href={url.startsWith('http') ? url : `http://${url}`} key={url} target="_blank">{url}</a>
          );
        }
        return <Text style={{ color: colors.black1, fontSize: fontSize.f28, lineHeight: lineHeight.l46, fontFamily: 'DINPro' }}>{word}</Text>;
      })}
    </View>
  );
}

export default Hyperlink
