// @flow
import config from '../utils/config';
import request from '../utils/request';
import helper from '../utils/helper';
import I18n from '../utils/i18n';
import type { OptType } from '../utils/request'
import type { CommonResponse, CustomerInfo, CustomerSimpleInfo, ExchangeResult } from './base';
// //// model definition start

// //// verifyCode
export type VerifyCodeResponseType = ExchangeResult<string> & CommonResponse; // return the verify code

// //// googleVerify
export interface GoogleVerify {
  wsKey: string,
  tokenId: string
}

export type GoogleVerifyResponseType = ExchangeResult<GoogleVerify> & CommonResponse;

// //// register
export interface RegisterRequest {
  // slotCookie: string, Cookie Variable
  email: string,
  mobile?: ?string,
  password: string, // client md5
  rpassword: string,
  source?: ?number,
  token: ?string,
  tokenType: string,
  inviteCode: string,
  activityType: ?number,
  verifyToken: string,
  deviceId: string,
  country: ?string,
  gender: number,
  userAgent?: ?string
}

// app注册source=1时会返回的注册信息
interface RegisterAppSource {
  id: string,
  email: string,
  userName: string,
  status: number,
  idCertifiedStatus: boolean,
  isEmailCertified: boolean,
  isBindEmail: boolean,
  isPhoneCertified: boolean,
  isBindPhone: boolean,
  isGoogleCertified: boolean,
  isBindGoogle: boolean,
  updateTime: string,
  locale: string,
  phone: string
}

export type RegisterResponseType = ExchangeResult<RegisterAppSource> & CommonResponse;

// fullVerity 当前app 产品支持email, google, mobile验证，
export interface FullVerifyRequest {
  email?: string,
  mobile?: string,
  emailVerifyCode?: string,
  smsVerifyCode?: string,
  googleVerifyCode?: string,
  type: number
}

export type TokenIdType = ?string;
export type FullyVerifyResponseType = ExchangeResult<TokenIdType> & CommonResponse;

// rebateInfo
export interface RebateInfo {
  invitationCode: string,
  rule: string,
  desc: string,
  rebateRatio: string,
  totalReferred: number,
  totalReward: number
}

export type RebateInfoResponseType = ExchangeResult<RebateInfo> & CommonResponse;

// Referral
export interface Referral {
  referrerId: string,
  registerTime: string,
  customerId: string,
  userName: string,
  invitationCode: string,
  referrerUserName: string,
  createTime: string,
  updateTime: string,
  rootReferrerId: string,
  rootReferrerUserName: string,
  partnerShip: boolean,
  partnerTime: string,
  activityType: number,
  isPhoneCertified: boolean,
  isGoogleCertified: boolean,
  isBoundPhone: boolean
}

export type ReferralResponseType = ExchangeResult<Referral> & CommonResponse;

// reward History

/**
 *RewardHistory.type
 //1-注册送币
 //2-推荐返佣
 //3-充值瓜分
 //4-充值随机
 //5-交易免手续费 (会员交易鼓励金)
 //6-理财贴息
 //7-充值免提币手续费
 //8-邀请送币
 //9-随存随取
 //10-绑定手机送币
 //11-绑定手机给邀请人送币
 //12-折扣购买
 //13-注册送体验金
 //14-三级推荐送体验金
 //15-开宝箱
 //16-交易时给邀请人返佣金（鼓励金，手续费）
 //18-被邀请人kyc通过邀请奖励金
 //19-法币重置
 //20-轮盘游戏
 */
export interface RewardHistory {
  amount: string,
  coin: string,
  allocatedTime: string,
  totalNum: number,
  type: number
}

export type RewardHistoryResponseType = ExchangeResult<RewardHistory[]> & CommonResponse;

// referredHistory
export interface ReferralHistory {
  account: string,
  registerTime: string,
  isBoundPhone: boolean,
  isPhoneCertified: boolean,
  isGoogleCertified: boolean,
  totalReferred: number,
  totalNum: number
}

export type ReferralHistoryResponseType = ExchangeResult<ReferralHistory[]> & CommonResponse;
// verifyRebateCode:  return username
export type VerifyRebateCodeResponseType = ExchangeResult<string> & CommonResponse;
// querycustomer

export type QueryCustomerResponseType = ExchangeResult<CustomerSimpleInfo> & CommonResponse;

// getLuckyBoxCount
export interface LuckyBoxCount {
  open: number,
  share: number
}

export type LuckyBoxCountResponseType = ExchangeResult<LuckyBoxCount> & CommonResponse;

// openLuckyBox
export interface LuckyBoxRecord {
  activityId: number,
  customer: string,
  bizId: string,
  win: boolean,
  currency: string,
  amount: number,
  created: string,
  precision: number
}

export type OpenLuckyBoxResponseType = ExchangeResult<LuckyBoxRecord> & CommonResponse;
export type LuckyBoxRecordsResponseType = ExchangeResult<LuckyBoxRecord[]> & CommonResponse;

// customer info
export type CustomerInfoType = ExchangeResult<CustomerInfo> & CommonResponse;

// login
export interface LoginError{
  errorCount: number,
  updateTime: string
}
// oauth login
export interface OauthLoginError{
  errorCount: number,
  updateTime: string,
  userName?: string
}
export type LoginResponseType = CommonResponse & ExchangeResult<LoginError | CustomerSimpleInfo>;

// oauth
export type OauthLoginResponseType = ExchangeResult<OauthLoginError | CustomerSimpleInfo | string> & CommonResponse;
// //// Class Customer API start
class CustomerApi {
  /**
   * sendVerifyCode
   * @param params,  type: {
   *   0: customer_register,
   *   1: customer_forgot_password,
   *   2: customer_active,
   *   3: sms_en_phone,
   *   7: sms_take_phone,
   *   5: customer_close_verify,
   *   6: customer_reset_pwd,
   *  8: email_binding_verify,
   *  9: email_remove_verify,
   *  10: phone_remove_verify
   * }
   * @returns {*}
   */
  sendVerifyCode (params: { email?: ?string, mobile?: ?string, type?: ?number}, options?: OptType): Promise<CommonResponse> {
    // params.saas_id = 'kiki';
    if (params.email) {
      const URL =
        config.api.host_newapi +
        config.api.customer.sendEmailCode +
        '?' +
        helper.mapToQueryString(params);
      return request.post.call({}, URL, options);
    } else {
      const URL =
        config.api.host_newapi +
        config.api.customer.sendPhoneCode +
        '?' +
        helper.mapToQueryString(params);
      return request.post.call({}, URL, options);
    }
  };

  /**
   * verity Code
   * @param params
   * @returns Promise<VerifyCodeResponseType>
   */
  verifyCode (params: { email: string, mobile: string, verifyCode: string, type: number }): Promise<VerifyCodeResponseType> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.customer.verify +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  /**
   * google second verification, for login
   * @param params
   * @returns Promise<GoogleVerifyResponseType>
   */
  verifyGoogleCode (params: { email: ?string, phone?: ?string, codes: string }): Promise<GoogleVerifyResponseType> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.customer.verifyGoogle +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  bindMobile (params: { mobile: string, verifyCode: string, tokenId: string, type: string}): Promise<CommonResponse> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.customer.bindPhone +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  unBindMobile (params: { tokenId: string }): Promise<CommonResponse> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.customer.unBindPhone +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  bindEmail (params: { email: string, verifyCode: string, tokenId: string, type: string}): Promise<CommonResponse> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.customer.bindEmail +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  unBindEmail (params: { tokenId: string }): Promise<CommonResponse> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.customer.unBindEmail +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  register (params: RegisterRequest): Promise<RegisterResponseType> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.customer.register +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  logout (): Promise<CommonResponse> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.customer.logout;
    return request.post.call({}, URL);
  };

  /**
   * forgot password 申请: 发送验证码到email（mobile）
   * @param params
   * @returns {*}
   */
  forgotPwd (params: { email: string, mobile?: string, source?: number }, options?: OptType): Promise<CommonResponse> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.customer.forgotPwd +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call(params, URL, options);
  };

  /**
   * 忘记密码 password 重置密码
   * @param params
   * @returns {*}
   */
  forgotPwdNew (params: { tokenId: string, password: string, rpassword: string }): Promise<CommonResponse> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.customer.forgotPwdNew +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  fullVerity (params: FullVerifyRequest): Promise<FullyVerifyResponseType> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.customer.fullVerify +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  /**
   * 登录状态重设密码
   * @param params
   * @returns Promise<CommonResponse>
   */
  pwdReset (params: { tokenId: string, oldPwd: string, newPwd: string, reNewPwd: string }): Promise<CommonResponse> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.customer.pwdReset +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  /**
   * 获取邀请码,活动规则链接，活动描述,返佣比例等基础信息
   * @param params
   * @returns {*}
   */
  rebateInfo (params: ?{ locale: ?string }): Promise<RebateInfoResponseType> {
    // params.saas_id = 'kiki';
    const locale = I18n.locale === 'en' ? 'en' : 'zh_CN'; // TODO 获取系统设置的语言
    const URL =
      config.api.host_newapi +
      config.api.customer.rebateInfo +
      '?locale=' +
      locale;
    return request.get.call({}, URL);
  };

  /**
   * 推荐返佣状态
   * @param params
   * @returns {*}
   */
  referInfo (): Promise<ReferralResponseType> {
    const URL = config.api.host_newapi + config.api.customer.referInfo;
    return request.get.call({}, URL);
  };

  rebateHistory (params: { offset: number, limit: number }): Promise<RewardHistoryResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.customer.rebateHistory +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  verifyRebateCode (params: { referee: string }): Promise<VerifyRebateCodeResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.customer.verifyRebateCode +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  query (params: { email?: string, mobile?: string }): Promise<QueryCustomerResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.customer.query +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  // 活动
  getLuckyBoxCount (): Promise<LuckyBoxCountResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.activity.luckyBoxCount;
    return request.get.call({}, URL);
  };

  addLuckyBoxCount (): Promise<CommonResponse> {
    const URL =
      config.api.host_newapi +
      config.api.activity.addLuckyBoxCount;
    return request.post.call({}, URL);
  };

  openLuckyBox (): Promise<OpenLuckyBoxResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.activity.openLuckyBox;
    return request.post.call({}, URL);
  };

  getLuckyBoxResult (params: { bizId: string }): Promise<OpenLuckyBoxResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.activity.getLuckyBoxResult +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  getLuckyBoxRecords (params: { offset: number, limit: number }): Promise<LuckyBoxRecordsResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.activity.getLuckyBoxRecords +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call(params, URL);
  };

  customerInfo (params: { isSilent?: boolean, jwtKey?: string}): Promise<CustomerInfoType> {
    const URL = config.api.host_newapi + config.api.customer.customer;
    return request.get.call({}, URL, { isSilent: params && params.isSilent, jwtKey: params.jwtKey });
  }

  login (params: {
    email: string,
    source: number,
    password: string,
    pTokenType: 'android' | 'web' | 'ios',
    tokenType?: number,
    mobile: string,
    token: string,
    tokenId: string,
    deviceId: string
  }): Promise<LoginResponseType> {
    const _params = Object.assign({}, params);
    if (_params.mobile.indexOf('_') !== -1) {
      _params.mobile = _params.mobile.replace('_', '')
    }

    const URL = config.api.host_newapi +
      config.api.login.login +
      '?' +
      helper.mapToQueryString(_params);
    return request.post.call(this, URL, {
      verificationType: 2,
      isSilent: true,
      sessionId: 'data_from_verification'
    });
  }

  loginOauth (params: {idToken: string, password: ?string, email: ?string, token: ?string}): Promise<OauthLoginResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.login.login_oauth +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL)
  }
}

const CustomerAPI: CustomerApi = new CustomerApi();
export { CustomerAPI, CustomerApi };
