/* eslint-disable react-native/no-inline-styles */
/* @flow */

import React, { Component } from 'react';
import type { Element } from 'react';
import { View, Text, Animated, Easing } from 'react-native';
import I18n from '../../utils/i18n';
import p from '../../utils/Transfrom';

type PropsType = {
  postType: string,
  type: string
};
type StateType = {
  postType: string
};

export class RefreshPostCount extends Component <PropsType, StateType> {
  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  constructor (props: PropsType) {
    super(props);
    const topValue = new Animated.Value(-p(10));
    const opacityValue = new Animated.Value(0);
    this.state = {
      topValue: topValue,
      opacityValue: opacityValue
    };
  }

  _isMounted: boolean;

  componentDidMount () {
    this._isMounted = true;
    setTimeout(() => {
      this._startAnimated()
    }, 1000);
  }

  // static getDerivedStateFromProps (nextProps: PropsType, state: StateType): StateType | void {
  //   if (nextProps.postType !== state.postType) {
  //     return { postType: nextProps.postType }
  //   }
  // }

  componentWillUnmount () {
    this._isMounted = false
    if (this.listener1) {
      this.listener1.remove();
    }
    if (this.tagChangeListener) {
      this.tagChangeListener.remove();
    }
  }

  _startAnimated: () => void = () => {
    const animatedTop = Animated.timing(
      this.state.topValue,
      {
        toValue: p(30),
        duration: 500,
        // easing: Easing.linear,
        easing: Easing.bezier(0.15, 0.73, 0.37, 1.2),
        useNativeDriver: false
      }
    );
    const animatedOpacity = Animated.timing(
      this.state.opacityValue,
      {
        toValue: 1,
        duration: 400,
        easing: Easing.linear,
        useNativeDriver: false
      }
    );
    Animated.parallel([animatedTop, animatedOpacity]).start(() => {})
  };

  render (): Element<*> {
    const { topValue, opacityValue } = this.state;
    const { refreshPostCount, type } = this.props;
    return (
      <Animated.View style={{
        position: 'absolute',
        top: topValue,
        opacity: opacityValue,
        zIndex: 999,
        alignItems: 'center',
        alignContent: 'center',
        width: '100%',
        backgroundColor: 'transparent'
      }}>
        <View style={{ backgroundColor: '#6F40EE', height: p(60), paddingHorizontal: p(32), paddingVertical: p(14), borderRadius: p(35) }}>
          <Text style={{ color: '#FFFFFF', fontSize: p(24), lineHeight: p(33) }}>
            {type === 'recommend' ? I18n.t('refreshPostRecommend') : `${I18n.t('refreshPostCount1')}${refreshPostCount}${I18n.t('refreshPostCount2')}`}
          </Text>
        </View>
      </Animated.View>
    );
  }
}

export default (RefreshPostCount);
