// @flow
import React, { Component } from 'react';
import type { Element } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import p from '../utils/Transfrom';
import I18n from '../utils/i18n';
import ScrollUpDown from './ScrollUpDown';
import type { CombinedReducerType, DispatchProps } from '../reducers';
import type { Feed } from '../api/topicPoll';
import { width, height, justifyContent, lineHeight, paddingHorizontal, paddingVertical, alignItems, colors, radius, margin, fontSize, fontWeight } from '../styles/Common'

type OwnPropType = {
    itemData: {}, // TODO
    title: string
};
type StateType = {

};

type ProsType = OwnPropType & CombinedReducerType & DispatchProps;

export default class Topic extends Component <ProsType, StateType> {
  constructor (props: ProsType) {
    super(props);
    this.state = {};
  }

  render (): Element<*> {
    const { itemData } = this.props;
    const latestPostList = (itemData.latestPostList ? itemData.latestPostList : []).filter((latestPostListItem: Feed, index: number): boolean => index < 3);
    const isTopic = !itemData?.pollViewId;
    const title = isTopic ? I18n.t('community_hot_topic') : I18n.t('community_poll');
    const titleBackgroundColor = isTopic ? '#FF6100' : '#6F40EE';
    return (
    <View style={styles.card}>
      <Text
          numberOfLines={2}
          style={styles.cardTitleSection}>
          <View
            style={[styles.hotView, { backgroundColor: titleBackgroundColor }]}
          >
            <Text
              numberOfLines={1}
              style={styles.hotViewText}
            >
              {title}
            </Text>
          </View>
           {` ${itemData.content ?? ''}`}
      </Text>
      {latestPostList.length > 0
        ? (<ScrollUpDown data={latestPostList} kbContainer={{ backgroundColor: 'rgba(111,64,238,0.1)' }}/>)
        : (<View
              style={{
                overflow: 'hidden',
                height: this.state.scrollHeight,
                marginTop: p(30),
                flexDirection: 'row',
                backgroundColor: 'rgba(255,255,255,0.40)',
                paddingHorizontal: p(10),
                borderRadius: p(24)
              }}
            >
            <View style={{ height: p(44), flexDirection: 'row', alignItems: 'center', width: '100%' }}>
              <Text
                numberOfLines={1}
                ellipsizeMode='tail'
                style={{ color: '#696F7F', fontSize: p(18), marginLeft: p(10), width: p(230) }}>
                {I18n.t('comeAndComment')}
              </Text>
             </View>
            </View>
          )
       }
    </View>
    )
    ;
  }
};

const styles = StyleSheet.create({
  card: {
    width: width.w345,
    height: height.h192,
    borderRadius: radius.r18,
    backgroundColor: colors.white2,
    paddingHorizontal: paddingHorizontal.ph20,
    paddingVertical: paddingVertical.pv24,
    justifyContent: justifyContent.spaceBetween
  },
  cardTitleSection: {
    color: colors.black3,
    fontSize: fontSize.f28,
    fontWeight: fontWeight.f700,
    flexWrap: 'wrap'
  },
  hotView: {
    alignItems: alignItems.center,
    justifyContent: justifyContent.center,
    borderRadius: radius.r8,
    paddingHorizontal: paddingHorizontal.ph6,
    height: height.h28,
    flexWrap: 'wrap',
    marginRight: margin.m6
  },
  hotViewText: {
    color: colors.pureWhite,
    fontSize: fontSize.f20,
    fontWeight: fontWeight.f700,
    lineHeight: lineHeight.l28

  }
});
