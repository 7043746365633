// @flow
import React, { Component } from 'react';
import type { Element } from 'react';
import { Dimensions, SafeAreaView, StyleSheet, Text, TouchableOpacity } from 'react-native';
import Modal from 'react-native-modal';
import p from '../utils/Transfrom';
import I18n from '../utils/i18n';
import type { CombinedReducerType, DispatchProps } from '../reducers';

declare function onClickDeletePost(): void;
declare function onClickHideUser(): void;
declare function onClickBlockPost(): void;
type OwnPropType = {
    visible: boolean,
    isManage: boolean,
    userID: string,
    onClickDeletePost: typeof onClickDeletePost,
    onClickHideUser: typeof onClickHideUser,
    onClickBlockPost: typeof onClickBlockPost
};
type StateType = {

};

type ProsType = OwnPropType & CombinedReducerType & DispatchProps;

export default class PostMoreOptionModal extends Component <ProsType, StateType> {
  constructor (props: ProsType) {
    super(props);
    this.state = {};
  }

  hideModal: () => void = () => {
    this.props.toggleModal(false);
  };

  render (): Element<*> {
    const { visible, isManage, userID, userId } = this.props;
    return (
      <Modal
        onBackdropPress={() => {
          this.hideModal();
        }}
        deviceHeight={Math.max(
          Dimensions.get('window').height,
          Dimensions.get('screen').height
        )}
        isVisible={visible}
        style={{
          margin: 0,
          flexDirection: 'column',
          justifyContent: 'flex-end'
        }}>
        <SafeAreaView style={{ margin: p(30) }}>
          {
            isManage || userID === userId
              ? <TouchableOpacity
              activeOpacity={0.8}
              style={[styles.btn, userID === userId ? {} : { borderBottomLeftRadius: 0, borderBottomRightRadius: 0, marginBottom: p(18) }]}
              onPress={this.props.onClickDeletePost}>
              <Text style={{ color: '#1F2126', fontSize: p(30) }}>{I18n.t('deleteConfirm')}</Text>
            </TouchableOpacity>
              : null
          }

          {
            userID !== userId
              ? <TouchableOpacity
                activeOpacity={0.8}
                style={[styles.btn, isManage ? { borderRadius: 0 } : { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }]}
                onPress={this.props.onClickHideUser}>
                <Text style={{ color: '#1F2126', fontSize: p(30) }}>{I18n.t('community_block')}</Text>
            </TouchableOpacity>
              : null
          }
          {
            userID !== userId
              ? <TouchableOpacity
              activeOpacity={0.8}
              style={[styles.btn, { borderTopLeftRadius: 0, borderTopRightRadius: 0, marginTop: p(18) }]}
              onPress={this.props.onClickBlockPost}>
              <Text style={{ color: '#1F2126', fontSize: p(30) }}>{I18n.t('community_report')}</Text>
            </TouchableOpacity>
              : null
          }

          <TouchableOpacity activeOpacity={0.8}
            style={[styles.btn, { marginTop: p(18) }]}
            onPress={() => {
              this.hideModal();
            }}>
            <Text
              style={{
                color: '#3D15AA',
                fontSize: p(30),
                fontWeight: 'bold'
              }}>
              {I18n.t('common_cancel')}
            </Text>
          </TouchableOpacity>
        </SafeAreaView>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  btn: {
    borderRadius: p(24),
    width: '100%',
    height: p(112),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff'
  }
});
