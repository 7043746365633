import React from 'react';
import ReactDOM from 'react-dom';
import {
  View,
  StyleSheet,
  AppRegistry,
  DeviceEventEmitter,
  Platform
} from 'react-native';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { AppContainer } from 'react-hot-loader';
// import { BrowserRouter } from 'react-router-dom'
import { createStore, combineReducers, applyMiddleware } from 'redux';
// import { routerReducer } from 'react-router-redux';
// import { PersistGate } from 'redux-persist/integration/react';
// import { subscribe } from './api/loginRequired';
import { NavigationContainer } from '@react-navigation/native';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig, fetchAndActivate, getValue } from "firebase/remote-config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAkNt4CWe2RANTzjS9NkM8q5vrPZXcrlkQ",
  authDomain: "kiki-beta.firebaseapp.com",
  databaseURL: "https://kiki-beta.firebaseio.com",
  projectId: "kiki-beta",
  storageBucket: "kiki-beta.appspot.com",
  messagingSenderId: "983830855482",
  appId: "1:983830855482:web:dbf16766eb95ee48f89a03",
  measurementId: "G-X286KB4WVQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
remoteConfig = getRemoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

window.remoteConfig = remoteConfig
console.log(remoteConfig)
    const val = getValue(window.remoteConfig, "discover");

fetchAndActivate(remoteConfig)
  .then((data) => {
    // ...
    DeviceEventEmitter.emit('firebaseWebReady')


  })
  .catch((err) => {
    // ...
  });


// import { AppNavigator } from './navigationConfiguration';
import { createStackNavigator } from '@react-navigation/stack';


const Stack = createStackNavigator();
// import WebScreen from './layouts/demo/Web';
// import WebScreen from './layouts/demo/webIndex';
import Header from './layouts/demo/Header';
import Footer from './layouts/demo/Footer';
import type { LayoutEvent } from 'react-native/Libraries/Types/CoreEventTypes';
import Home from './layouts/home/newIndex';

import { store } from './store';

// import PrimaryLayout from './AuthorizedRoute/PrimaryLayout';
// import getStore from './store';
// import Preload from './actions/Preload';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

require("antd/lib/spin/style/index.css")

import './web/css/base.less';
import p from './utils/Transfrom';


// let createStoreResult = getStore();
// let _store = createStoreResult.store;
// let persistor = createStoreResult.persistor;
const _store = store;

global = window
global.Platform = Platform
// let { member } = _store.getState().HomeReducer;
// console.log(_store.getState())
// global.USER_ID = member?.memberInfo?.id || '';
// subscribe(_store);

window.LARGE_SCREEN_VALUE =  768
global.IMAGE_96 =
  '?x-oss-process=image/auto-orient,1/interlace,1/resize,m_fixed,w_96,h_96/format,jpg';
let hostname = location.hostname;

// global.IS_LARGE_SCREEN = window.innerWidth > LARGE_SCREEN_VALUE 

// if(hostname.indexOf("gwave.io") !== -1){
//   ReactGA.initialize('UA-79751510-11');
//   ReactGA.pageview(window.location.pathname + window.location.search);
// }



// const middleware = [ thunk ];

// const store = createStore(
//     combineReducers({
//         routing: routerReducer
//     }),
//     applyMiddleware(...middleware)
// );

document.getElementById("overlay-loading").style.display = "none"



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spinning:false,
      tip:"",
      screen_size:window.innerWidth > LARGE_SCREEN_VALUE ? "large_screen" :  "small_screen"
    };
  }

  componentDidMount = () => {
    let that = this
    const init_window_width  = window.innerWidth 
    if(window.innerWidth > LARGE_SCREEN_VALUE) {
      document.getElementsByTagName("html")[0].style.fontSize = 1 / 2 / (window.innerWidth / 750)  + "px"
    }
    let last_window_width = window.innerWidth 

    DeviceEventEmitter.addListener("ModalIndicator", function(data){
      if(typeof data == "object") {
        that.setState({
          spinning:data.show,
          tip:data.text
        })
      } else {
        that.setState({
          spinning:data,
          tip:""
        })
      }

    })
    window.onresize = function(event) {
      try{
        if (init_window_width > LARGE_SCREEN_VALUE && window.innerWidth > LARGE_SCREEN_VALUE && (last_window_width > LARGE_SCREEN_VALUE)) return
        
        last_window_width = window.innerWidth
        if(window.innerWidth > LARGE_SCREEN_VALUE) {
          if(init_window_width < LARGE_SCREEN_VALUE ){
            document.getElementsByTagName("html")[0].style.fontSize = 1 / 2 / (Math.min(window.innerWidth, init_window_width)/ 750)  + "px"
          } else {
            document.getElementsByTagName("html")[0].style.fontSize = 1 / 2 / (Math.max(window.innerWidth, init_window_width)/ 750)  + "px"
          }
        } else {
          document.getElementsByTagName("html")[0].style.fontSize = (window.innerWidth / init_window_width)  + "px"
        }
        that.setState({
          screen_size:window.innerWidth > LARGE_SCREEN_VALUE ? "large_screen" :  "small_screen"
        });
        // global.IS_LARGE_SCREEN = window.innerWidth > LARGE_SCREEN_VALUE 
      }catch(e){

      }
    };

  }

  render() {
    let {screen_size, spinning, tip} = this.state;
    return (
      <View style={{ flex: 1, position: 'relative' }} >
        <Provider store={_store}>
          <AppContainer>
            <Spin spinning={spinning} tip={tip}>
              {screen_size === "large_screen" ? (<>
                <Header {...this.props} screenProps={{screenSize:screen_size}}/>
                <Footer />
              </>) : (<NavigationContainer >
                <Stack.Navigator
                  initialRouteName="Web"
                  screenOptions={{ gestureEnabled: false }}
                >
                  <Stack.Screen
                    name="Web"
                    options={{ title: 'My app' }}
                  >
                  {props => <Home {...props} screenProps={{screenSize:screen_size}}/>}
                 </Stack.Screen>

                </Stack.Navigator>
              </NavigationContainer>)}
            </Spin>
          </AppContainer>
        </Provider>
      </View>
    );
  }
}



AppRegistry.registerComponent('App', () => App);
AppRegistry.runApplication('App', { rootTag: document.getElementById('root') });





