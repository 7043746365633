// @flow
import React from 'react';
import type { Element } from 'react';
import {
  View,
  StyleSheet,
  Text,
  ImageBackground
} from 'react-native';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';

// import I18n from '../utils/i18n';

import helper from '../utils/helper'

import { alignItems, width, height, radius, paddingHorizontal, justifyContent, lineHeight, colors, margin, padding, fontSize, fontWeight } from '../styles/Common'

import type { KikiMasterConfigType } from '../actions/common';

function StarPeople ({ item, navigation }: {
  item: KikiMasterConfigType,
  // eslint-disable-next-line flowtype/no-weak-types
  navigation: {navigate: (route: string, params: any)=>{}}
}): Element<*> {
  return (

    <TouchableWithoutFeedback onPress={() => {
      try {
        helper.trackEvent('NewHome Click KOL ' + item.nickName, { item: JSON.stringify(item) });
      } catch (e) {
      }

      navigation.navigate('NormalUser', { itemData: { customerMisc: { customerId: item.customerId } } })
    }}>
      <ImageBackground
        source={{ uri: item.avatar }}
        style={styles.starPeople}>
        <View style={styles.blurWrap}>
          <ImageBackground
            source={{ uri: item.avatar }}
            blurRadius={80}
            style={styles.blurImageStyle}>
            <View style={styles.titleSection}>
              <Text style={styles.starPeopleName}>{item.nickName}</Text>
              <Text style={styles.starPeopleIntro}>{item.intro}</Text>
            </View>
          </ImageBackground>
        </View>
      </ImageBackground>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  starPeople: {
    width: width.w560,
    height: height.h680,
    borderRadius: radius.r48,
    backgroundColor: colors.red1,
    justifyContent: 'flex-end',
    overflow: 'hidden'
  },
  blurWrap: {
    height: height.h212,
    width: width.w560,
    borderBottomLeftRadius: radius.r48,
    borderBottomRightRadius: radius.r48,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0
  },
  blurImageStyle: {
    width: width.w560,
    height: height.h680,
    borderRadius: radius.r48,
    position: 'absolute',
    bottom: 0,
    justifyContent: 'flex-end'
  },
  titleSection: {
    height: height.h212,
    width: width.w560,
    borderBottomLeftRadius: radius.r48,
    borderBottomRightRadius: radius.r48,
    backgroundColor: colors.transparent,
    paddingHorizontal: paddingHorizontal.ph30,
    paddingTop: padding.p30
  },
  starPeopleName: {
    color: colors.pureWhite,
    fontWeight: fontWeight.f700,
    fontSize: fontSize.f36,
    lineHeight: lineHeight.l46
  },
  starNameRow: {
    flexDirection: 'row',
    alignItems: alignItems.center,
    justifyContent: justifyContent.spaceBetween
  },
  starPeopleIntro: {
    color: colors.white6,
    fontSize: fontSize.f24,
    lineHeight: lineHeight.l33,
    marginTop: margin.m16
  },
  starFollow: {
    width: width.w120,
    height: height.h48,
    borderRadius: radius.r24,
    backgroundColor: colors.white7,
    alignItems: alignItems.center,
    justifyContent: justifyContent.center
  },
  starFollowText: {
    color: colors.pureWhite,
    fontSize: fontSize.f24,
    lineHeight: lineHeight.l31
  }

})

// eslint-disable-next-line flowtype/no-weak-types
const MemoizedStarPeople: any = React.memo(StarPeople);
export default MemoizedStarPeople
