// @flow
import React from 'react';
import type { Element } from 'react';
import {
  StyleSheet,
  Platform,
  View,
  Text
} from 'react-native';
import PlaceHolderFastImage from './PlaceHolderFastImage'
import type { ZoneItemType } from '../layouts/market/SectionList'
import { TouchableOpacity } from 'react-native-gesture-handler';
import helper from '../utils/helper'

import { width, height, colors, radius, margin, imageRounded80, alignItems, paddingHorizontal, fontWeight, fontSize, lineHeight } from '../styles/Common'

function ThemeItem ({ item, navigation }: {
  item: ZoneItemType,
  // eslint-disable-next-line flowtype/no-weak-types
  navigation: {navigate: (route: string, params: any)=>{}}
}): Element<*> {
  return (
    <TouchableOpacity activeOpacity={0.8} onPress={() => {
      try {
        helper.trackEvent('NewHome Click Theme ' + item.categoryKey, { item: JSON.stringify(item) });
      } catch (e) {
      }

      navigation.navigate('SectionDetail', { item: item })
    }}>
      <View style={styles.themeRowStyle}>
        <PlaceHolderFastImage
          imgStyle={imageRounded80}
          style={imageRounded80}
          text={''}
          textStyle={imageRounded80}
          source={
            item.picPaths && item.picPaths.length
              ? { uri: item.picPaths[0] }
              : null
          }
          fallback={Platform.OS === 'android'}
        />

        <Text style={styles.themeTextStyle}>{item.localeText}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  themeRowStyle: {
    width: width.w380,
    height: height.h140,
    borderRadius: radius.r36,
    backgroundColor: colors.white2,
    alignItems: alignItems.center,
    flexDirection: 'row',
    paddingHorizontal: paddingHorizontal.ph24
  },
  themeTextStyle: {
    color: colors.black1,
    fontSize: fontSize.f28,
    fontWeight: fontWeight.f500,
    lineHeight: lineHeight.l36,
    marginLeft: margin.m24
  }
})
// eslint-disable-next-line flowtype/no-weak-types
const MemoizedThemeItem: any = React.memo(ThemeItem);
export default MemoizedThemeItem
