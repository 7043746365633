/**
 *
 *
 *
 *  @flow
 *    全部的action样式
 *
 *    要大写;作为常量来使用.
 *
 *
 * */
// 用户数据    登录后获取用户数据
export class ActionTypes {
  static REHYDRATE: 'persist/REHYDRATE' = 'persist/REHYDRATE';
  static ACCOUNT_USER_INFO: 'ACCOUNT_USER_INFO' = 'ACCOUNT_USER_INFO';
  static ACCOUNT_SAVE_USER: 'ACCOUNT_SAVE_USER' = 'ACCOUNT_SAVE_USER';
  static ACCOUNT_SAVE_USER_MOBILE: 'ACCOUNT_SAVE_USER_MOBILE' = 'ACCOUNT_SAVE_USER_MOBILE';

  static ACCOUNT_USER_INFO_REQUEST: 'ACCOUNT_USER_INFO_REQUEST' = 'ACCOUNT_USER_INFO_REQUEST';
  static ACCOUNT_USER_INFO_ERROR: 'ACCOUNT_USER_INFO_ERROR' = 'ACCOUNT_USER_INFO_ERROR';
  static ACCOUNT_USER_INFO_RESET: 'ACCOUNT_USER_INFO_RESET' = 'ACCOUNT_USER_INFO_RESET';
  static ACCOUNT_LOGOUT: 'ACCOUNT_LOGOUT' = 'ACCOUNT_LOGOUT'; // 用户登出
  static ACCOUNT_LOST_SESSION: 'ACCOUNT_LOST_SESSION' = 'ACCOUNT_LOST_SESSION'; // 用户登出
  static ACCOUNT_LOGOUT_REQUEST: 'ACCOUNT_LOGOUT_REQUEST' = 'ACCOUNT_LOGOUT_REQUEST';
  static ACCOUNT_LOGOUT_ERROR: 'ACCOUNT_LOGOUT_ERROR' = 'ACCOUNT_LOGOUT_ERROR';
  static ACCOUNT_LOGOUT_RESET: 'ACCOUNT_LOGOUT_RESET'= 'ACCOUNT_LOGOUT_RESET';
  static ACCOUNT_SECOND_VERIFY: 'ACCOUNT_SECOND_VERIFY' = 'ACCOUNT_SECOND_VERIFY'; // 二次验证开启
  static ACCOUNT_SECOND_VERIFY_QUIT: 'ACCOUNT_SECOND_VERIFY_QUIT' = 'ACCOUNT_SECOND_VERIFY_QUIT'; // 二次验证中断验证
  static ACCOUNT_LOCKED: 'ACCOUNT_LOCKED' = 'ACCOUNT_LOCKED'; // 账户锁定
  static ACCOUNT_SUSPENSED: 'ACCOUNT_SUSPENSED' = 'ACCOUNT_SUSPENSED'; // 账户锁定
  static ACCOUNT_SUSPENSED_RESET: 'ACCOUNT_SUSPENSED_RESET' = 'ACCOUNT_SUSPENSED_RESET'; // 账户锁定

  static ACCOUNT_LOCK_RESET: 'ACCOUNT_LOCK_RESET' = 'ACCOUNT_LOCK_RESET'; // 账户锁定
  static ACCOUNT_USER_INFO_CLEAN: 'ACCOUNT_USER_INFO_CLEAN' = 'ACCOUNT_USER_INFO_CLEAN';
  static DEPOSIT_CONFIG: 'DEPOSIT_CONFIG' = 'DEPOSIT_CONFIG'; // 入金状态
  // 账务数据
  static ACCOUNT_ASSET_REQUEST: 'ACCOUNT_ASSET_REQUEST' = 'ACCOUNT_ASSET_REQUEST';
  static ACCOUNT_ASSET: 'ACCOUNT_ASSET' = 'ACCOUNT_ASSET';
  static ACCOUNT_ASSET_ERROR: 'ACCOUNT_ASSET_ERROR' = 'ACCOUNT_ASSET_ERROR';
  static ACCOUNT_ASSET_RESET: 'ACCOUNT_ASSET_RESET' = 'ACCOUNT_ASSET_RESET';
  static ACCOUNT_ASSET_ALL_REQUEST: 'ACCOUNT_ASSET_ALL_REQUEST' = 'ACCOUNT_ASSET_ALL_REQUEST';
  static ACCOUNT_ASSET_ALL: 'ACCOUNT_ASSET_ALL' = 'ACCOUNT_ASSET_ALL';
  static ACCOUNT_ASSET_ALL_ERROR: 'ACCOUNT_ASSET_ALL_ERROR' = 'ACCOUNT_ASSET_ALL_ERROR';
  static ACCOUNT_ASSET_ALL_RESET: 'ACCOUNT_ASSET_ALL_RESET' = 'ACCOUNT_ASSET_ALL_RESET';

  static ACCOUNT_INFO_REQUEST: 'ACCOUNT_INFO_REQUEST' = 'ACCOUNT_INFO_REQUEST';
  static ACCOUNT_INFO: 'ACCOUNT_INFO' = 'ACCOUNT_INFO';
  static ACCOUNT_INFO_ERROR: 'ACCOUNT_INFO_ERROR' = 'ACCOUNT_INFO_ERROR';
  static ACCOUNT_INFO_RESET: 'ACCOUNT_INFO_RESET' = 'ACCOUNT_INFO_RESET';
  static ACCOUNT_HISTORY_REQUEST: 'ACCOUNT_HISTORY_REQUEST' = 'ACCOUNT_HISTORY_REQUEST';
  static ACCOUNT_HISTORY: 'ACCOUNT_HISTORY' = 'ACCOUNT_HISTORY';
  static ACCOUNT_HISTORY_ERROR: 'ACCOUNT_HISTORY_ERROR' = 'ACCOUNT_HISTORY_ERROR';
  static ACCOUNT_HISTORY_RESET: 'ACCOUNT_HISTORY_RESET' = 'ACCOUNT_HISTORY_RESET';
  static ACCOUNT_ASSET_INFO_CLEAN: 'ACCOUNT_ASSET_INFO_CLEAN' = 'ACCOUNT_ASSET_INFO_CLEAN';
  static ACCOUNT_YUEBAO_PRODUCT_LIST: 'ACCOUNT_YUEBAO_PRODUCT_LIST' = 'ACCOUNT_YUEBAO_PRODUCT_LIST';

  // 生成充币地址
  static CREATE_DEPOSIT_ADDR_REQUEST: 'CREATE_DEPOSIT_ADDR_REQUEST' = 'CREATE_DEPOSIT_ADDR_REQUEST';
  static CREATE_DEPOSIT_ADDR: 'CREATE_DEPOSIT_ADDR' = 'CREATE_DEPOSIT_ADDR';
  static CREATE_DEPOSIT_ADDR_ERROR: 'CREATE_DEPOSIT_ADDR_ERROR' = 'CREATE_DEPOSIT_ADDR_ERROR';
  static CREATE_DEPOSIT_ADDR_RESET: 'CREATE_DEPOSIT_ADDR_RESET' = 'CREATE_DEPOSIT_ADDR_RESET';
  // 展示充币地址
  static DEPOSIT_ADDR_REQUEST: 'DEPOSIT_ADDR_REQUEST' = 'DEPOSIT_ADDR_REQUEST';
  static DEPOSIT_ADDR: 'DEPOSIT_ADDR' = 'DEPOSIT_ADDR';
  static DEPOSIT_ADDR_ERROR: 'DEPOSIT_ADDR_ERROR' = 'DEPOSIT_ADDR_ERROR';
  static DEPOSIT_ADDR_RESET: 'DEPOSIT_ADDR_RESET' = 'DEPOSIT_ADDR_RESET';
  // 充提币历史
  static ACCOUNT_TRANSACTIONS_REQUEST: 'ACCOUNT_TRANSACTIONS_REQUEST' = 'ACCOUNT_TRANSACTIONS_REQUEST';
  static ACCOUNT_TRANSACTIONS: 'ACCOUNT_TRANSACTIONS' = 'ACCOUNT_TRANSACTIONS';
  static ACCOUNT_TRANSACTIONS_ERROR: 'ACCOUNT_TRANSACTIONS_ERROR' = 'ACCOUNT_TRANSACTIONS_ERROR';
  static ACCOUNT_TRANSACTIONS_RESET: 'ACCOUNT_TRANSACTIONS_RESET' = 'ACCOUNT_TRANSACTIONS_RESET';
  // 划转
  static ACCOUNT_TRANSFER_REQUEST: 'ACCOUNT_TRANSFER_REQUEST' = 'ACCOUNT_TRANSFER_REQUEST';
  static ACCOUNT_TRANSFER: 'ACCOUNT_TRANSFER' = 'ACCOUNT_TRANSFER';
  static ACCOUNT_TRANSFER_ERROR: 'ACCOUNT_TRANSFER_ERROR' = 'ACCOUNT_TRANSFER_ERROR';
  static ACCOUNT_TRANSFER_RESET: 'ACCOUNT_TRANSFER_RESET' = 'ACCOUNT_TRANSFER_RESET';
  // 币列表
  static COIN_LIST_REQUEST: 'COIN_LIST_REQUEST' = 'COIN_LIST_REQUEST';
  static COIN_LIST: 'COIN_LIST' = 'COIN_LIST';
  static COIN_LIST_ERROR: 'COIN_LIST_ERROR' = 'COIN_LIST_ERROR';
  static COIN_LIST_RESET: 'COIN_LIST_RESET' = 'COIN_LIST_RESET';
  // 币详情
  static CURRENCY_REQUEST: 'CURRENCY_REQUEST' = 'CURRENCY_REQUEST';
  static CURRENCY: 'CURRENCY' = 'CURRENCY';
  static CURRENCY_ERROR: 'CURRENCY_ERROR' = 'CURRENCY_ERROR';
  static CURRENCY_RESET: 'CURRENCY_RESET' = 'CURRENCY_RESET';
  // 提币手续费
  static WITHDRAW_FEE_REQUEST: 'WITHDRAW_FEE_REQUEST' = 'WITHDRAW_FEE_REQUEST';
  static WITHDRAW_FEE: 'WITHDRAW_FEE' = 'WITHDRAW_FEE';
  static WITHDRAW_FEE_ERROR: 'WITHDRAW_FEE_ERROR' = 'WITHDRAW_FEE_ERROR';
  static WITHDRAW_FEE_RESET: 'WITHDRAW_FEE_RESET' = 'WITHDRAW_FEE_RESET';
  // 提币地址列表
  static WITHDRAW_ADDR_LIST_REQUEST: 'WITHDRAW_ADDR_LIST_REQUEST' = 'WITHDRAW_ADDR_LIST_REQUEST';
  static WITHDRAW_ADDR_LIST: 'WITHDRAW_ADDR_LIST' = 'WITHDRAW_ADDR_LIST';
  static WITHDRAW_ADDR_LIST_ERROR: 'WITHDRAW_ADDR_LIST_ERROR' = 'WITHDRAW_ADDR_LIST_ERROR';
  static WITHDRAW_ADDR_LIST_RESET: 'WITHDRAW_ADDR_LIST_RESET' = 'WITHDRAW_ADDR_LIST_RESET';
  // 添加提币地址
  static WITHDRAW_ADDR_REQUEST: 'WITHDRAW_ADDR_REQUEST' = 'WITHDRAW_ADDR_REQUEST';
  static WITHDRAW_ADDR: 'WITHDRAW_ADDR' = 'WITHDRAW_ADDR';
  static WITHDRAW_ADDR_ERROR: 'WITHDRAW_ADDR_ERROR' = 'WITHDRAW_ADDR_ERROR';
  static WITHDRAW_ADDR_RESET: 'WITHDRAW_ADDR_RESET' = 'WITHDRAW_ADDR_RESET';
  // 用户提币
  static WITHDRAW_APPLY_REQUEST: 'WITHDRAW_APPLY_REQUEST' = 'WITHDRAW_APPLY_REQUEST';
  static WITHDRAW_APPLY: 'WITHDRAW_APPLY' = 'WITHDRAW_APPLY';
  static WITHDRAW_APPLY_ERROR: 'WITHDRAW_APPLY_ERROR' = 'WITHDRAW_APPLY_ERROR';
  static WITHDRAW_APPLY_RESET: 'WITHDRAW_APPLY_RESET' = 'WITHDRAW_APPLY_RESET';

  // end of 用户数据
  // trader列表数据
  static TRADER_FETCH_DATA_REQUEST: 'TRADER_FETCH_DATA_REQUEST' = 'TRADER_FETCH_DATA_REQUEST';
  static TRADER_FETCH_DATA: 'TRADER_FETCH_DATA' = 'TRADER_FETCH_DATA';
  static TRADER_FETCH_DATA_ERROR: 'TRADER_FETCH_DATA_ERROR' = 'TRADER_FETCH_DATA_ERROR';
  static TRADER_FETCH_DATA_RESET: 'TRADER_FETCH_DATA_RESET' = 'TRADER_FETCH_DATA_RESET';

  static TRADER_FETCH_MORE_DATA_REQUEST: 'TRADER_FETCH_MORE_DATA_REQUEST' = 'TRADER_FETCH_MORE_DATA_REQUEST';
  static TRADER_FETCH_MORE_DATA: 'TRADER_FETCH_MORE_DATA' = 'TRADER_FETCH_MORE_DATA';
  static TRADER_FETCH_MORE_DATA_ERROR: 'TRADER_FETCH_MORE_DATA_ERROR' = 'TRADER_FETCH_MORE_DATA_ERROR';
  static TRADER_FETCH_MORE_DATA_RESET: 'TRADER_FETCH_MORE_DATA_RESET';

  // trader 社交misc 数据
  static TRADER_MY_MISC_REQUEST: 'TRADER_MY_MISC_REQUEST' = 'TRADER_MY_MISC_REQUEST';
  static TRADER_MY_MISC: 'TRADER_MY_MISC' = 'TRADER_MY_MISC';
  static TRADER_MY_MISC_ERROR: 'TRADER_MY_MISC_ERROR' = 'TRADER_MY_MISC_ERROR';
  static TRADER_MY_MISC_RESET: 'TRADER_MY_MISC_RESET' = 'TRADER_MY_MISC_RESET';
  static TRADER_USER_INFO_CLEAN: 'TRADER_USER_INFO_CLEAN' = 'TRADER_USER_INFO_CLEAN';

  // trader 交易跟单设置
  static TRADER_MY_SETTING_REQUEST: 'TRADER_MY_SETTING_REQUEST' = 'TRADER_MY_SETTING_REQUEST';
  static TRADER_MY_SETTING: 'TRADER_MY_SETTING' = 'TRADER_MY_SETTING';
  static TRADER_MY_SETTING_ERROR: 'TRADER_MY_SETTING_ERROR' = 'TRADER_MY_SETTING_ERROR';
  static TRADER_MY_SETTING_RESET: 'TRADER_MY_SETTING_RESET' = 'TRADER_MY_SETTING_RESET';
  // social
  static SOCIAL_TOKEN_ANONYMOUS_REFRESH: 'SOCIAL_TOKEN_ANONYMOUS_REFRESH' = 'SOCIAL_TOKEN_ANONYMOUS_REFRESH';
  static SOCIAL_TOKEN_REFRESH: 'SOCIAL_TOKEN_REFRESH' = 'SOCIAL_TOKEN_REFRESH';
  static SOCIAL_USER_INFO_CLEAN: 'SOCIAL_USER_INFO_CLEAN' = 'SOCIAL_USER_INFO_CLEAN';
  // 盘口数据， 用于交易
  static CFD_REFRESH_SYMBOL_INFO: 'CFD_REFRESH_SYMBOL_INFO' = 'CFD_REFRESH_SYMBOL_INFO';
  static CFD_REFRESH_SPOT_SYMBOL_INFO: 'CFD_REFRESH_SPOT_SYMBOL_INFO' = 'CFD_REFRESH_SPOT_SYMBOL_INFO';
  static CFD_REFRESH_FIAT_SYMBOL_INFO: 'CFD_REFRESH_FIAT_SYMBOL_INFO' = 'CFD_REFRESH_FIAT_SYMBOL_INFO';
  static CFD_FETCH_ALL_PNL_ORDERS: 'CFD_FETCH_ALL_PNL_ORDERS' = 'CFD_FETCH_ALL_PNL_ORDERS';
  static I18N_LANG: 'I18N_LANG' = 'I18N_LANG';

  // 交易员列表数据
  static TRADER_FETCH_RANK_DATA_REQUEST: 'TRADER_FETCH_RANK_DATA_REQUEST' = 'TRADER_FETCH_RANK_DATA_REQUEST';
  static TRADER_FETCH_RANK_DATA: 'TRADER_FETCH_RANK_DATA' = 'TRADER_FETCH_RANK_DATA';
  static TRADER_FETCH_RANK_DATA_ERROR: 'TRADER_FETCH_RANK_DATA_ERROR' = 'TRADER_FETCH_RANK_DATA_ERROR';
  static TRADER_FETCH_RANK_DATA_RESET: 'TRADER_FETCH_RANK_DATA_RESET' = 'TRADER_FETCH_RANK_DATA_RESET';
  static TRADER_COPY_TRIGGER: 'TRADER_COPY_TRIGGER' = 'TRADER_COPY_TRIGGER';
  static TRADER_STOP_COPY_TRIGGER: 'TRADER_STOP_COPY_TRIGGER' = 'TRADER_STOP_COPY_TRIGGER';

  // 币列表数据
  static TRADER_FETCH_CURRENCY_DATA_REQUEST: 'TRADER_FETCH_CURRENCY_DATA_REQUEST' = 'TRADER_FETCH_CURRENCY_DATA_REQUEST';
  static TRADER_FETCH_CURRENCY_DATA: 'TRADER_FETCH_CURRENCY_DATA' = 'TRADER_FETCH_CURRENCY_DATA';
  static TRADER_FETCH_CURRENCY_DATA_ERROR: 'TRADER_FETCH_CURRENCY_DATA_ERROR' = 'TRADER_FETCH_CURRENCY_DATA_ERROR';
  static TRADER_FETCH_CURRENCY_DATA_RESET: 'TRADER_FETCH_CURRENCY_DATA_RESET' = 'TRADER_FETCH_CURRENCY_DATA_RESET';
  // remote config
  static REMOTE_CONFIG: 'REMOTE_CONFIG' = 'REMOTE_CONFIG';
  static BANNER_CONFIG: 'BANNER_CONFIG' = 'BANNER_CONFIG';
  static BANNER_OFFLINE_CONFIG: 'BANNER_OFFLINE_CONFIG' = 'BANNER_OFFLINE_CONFIG';

  static GIFTCARD_CONFIG: 'GIFTCARD_CONFIG' = 'GIFTCARD_CONFIG';
  static REFER_INFO: 'REFER_INFO' = 'REFER_INFO';
  static REVENUE_EVENT_TIME: 'REVENUE_EVENT_TIME' = 'REVENUE_EVENT_TIME';
  static SOCIAL_HOTTOPIC_FETCH: 'SOCIAL_HOTTOPIC_FETCH' = 'SOCIAL_HOTTOPIC_FETCH';
  static SOCIAL_HOTPOLL_FETCH: 'SOCIAL_HOTPOLL_FETCH' = 'SOCIAL_HOTPOLL_FETCH';
  static SAVE_LONG_TEXT: 'SAVE_LONG_TEXT' = 'SAVE_LONG_TEXT';
  static REFRESH_OPEN_AUTH_INFO: 'REFRESH_OPEN_AUTH_INFO' = 'REFRESH_OPEN_AUTH_INFO';
  static CLEAN_OPEN_AUTH_INFO: 'CLEAN_OPEN_AUTH_INFO' = 'CLEAN_OPEN_AUTH_INFO';
  static CURRENT_RATE_TYPE: 'CURRENT_RATE_TYPE' = 'CURRENT_RATE_TYPE';
  static TRADER_SYMBOL_DATA: 'TRADER_SYMBOL_DATA' = 'TRADER_SYMBOL_DATA';
  static TRADER_SYMBOL_DATA_ERROR: 'TRADER_SYMBOL_DATA_ERROR' = 'TRADER_SYMBOL_DATA_ERROR';
  static TRADER_ENTRUST_DATA: 'TRADER_ENTRUST_DATA' = 'TRADER_ENTRUST_DATA';
  static TRADER_ENTRUST_DATA_UPDATE: 'TRADER_ENTRUST_DATA_UPDATE' = 'TRADER_ENTRUST_DATA_UPDATE';

  static TRADER_ENTRUST_DATA_ERROR: 'TRADER_ENTRUST_DATA_ERROR' = 'TRADER_ENTRUST_DATA_ERROR';
  static TRADER_HISTORY_SEARCH: 'TRADER_HISTORY_SEARCH' = 'TRADER_HISTORY_SEARCH';
  static FAVORITE_LIST_FETCH: 'FAVORITE_LIST_FETCH' = 'FAVORITE_LIST_FETCH';

  static VERSION_CONFIG: 'VERSION_CONFIG' = 'VERSION_CONFIG';
  static FIRST_UPDATE_SHOW: 'FIRST_UPDATE_SHOW' = 'FIRST_UPDATE_SHOW';
  static PROMPTED_VERSION_INFO: 'PROMPTED_VERSION_INFO' = 'PROMPTED_VERSION_INFO';
  static GUIDEBEGINNERS_CONFIG: 'GUIDEBEGINNERS_CONFIG' = 'GUIDEBEGINNERS_CONFIG';

  static TRADER_CUSTOM_FIRST_SHOW: 'TRADER_CUSTOM_FIRST_SHOW' = 'TRADER_CUSTOM_FIRST_SHOW';
  static TRADER_CUSTOM_FIRST_SHOW_FETCH: 'TRADER_CUSTOM_FIRST_SHOW_FETCH' = 'TRADER_CUSTOM_FIRST_SHOW_FETCH';

  // vs data
  static VSDATA_CONFIG: 'VSDATA_CONFIG' = 'VSDATA_CONFIG';

  // market data
  static SPOT_SYMBOL_LIST: 'SPOT_SYMBOL_LIST' = 'SPOT_SYMBOL_LIST';
  static FIAT_SYMBOL_LIST: 'FIAT_SYMBOL_LIST' = 'FIAT_SYMBOL_LIST';
  static SWAP_SYMBOL_LIST: 'SWAP_SYMBOL_LIST' = 'SWAP_SYMBOL_LIST';
  static FULL_SYMBOL_LIST: 'FULL_SYMBOL_LIST' = 'FULL_SYMBOL_LIST';

  static SPOT_SYMBOL_LIST_ERROR: 'SPOT_SYMBOL_LIST_ERROR' = 'SPOT_SYMBOL_LIST_ERROR';
  static FIAT_SYMBOL_LIST_ERROR: 'FIAT_SYMBOL_LIST_ERROR' = 'FIAT_SYMBOL_LIST_ERROR';
  static SWAP_SYMBOL_LIST_ERROR: 'SWAP_SYMBOL_LIST_ERROR' = 'SWAP_SYMBOL_LIST_ERROR';
  static SPOT_SYMBOL_LIST_RESET: 'SPOT_SYMBOL_LIST_RESET' = 'SPOT_SYMBOL_LIST_RESET';
  static FIAT_SYMBOL_LIST_RESET: 'FIAT_SYMBOL_LIST_RESET' = 'FIAT_SYMBOL_LIST_RESET';
  static SWAP_SYMBOL_LIST_RESET: 'SWAP_SYMBOL_LIST_RESET' = 'SWAP_SYMBOL_LIST_RESET';

  static MARKET_FIAT_KLINE_DATA: 'MARKET_FIAT_KLINE_DATA' = 'MARKET_FIAT_KLINE_DATA';
  static MARKET_FIAT_KLINE_REQUEST: 'MARKET_FIAT_KLINE_REQUEST' = 'MARKET_FIAT_KLINE_REQUEST';
  static MARKET_FIAT_KLINE_RESET: 'MARKET_FIAT_KLINE_RESET' = 'MARKET_FIAT_KLINE_RESET';
  static MARKET_FIAT_KLINE_ERROR: 'MARKET_FIAT_KLINE_ERROR' = 'MARKET_FIAT_KLINE_ERROR';

  static MARKET_SWAP_KLINE_DATA: 'MARKET_SWAP_KLINE_DATA' = 'MARKET_SWAP_KLINE_DATA';
  static MARKET_SWAP_KLINE_REQUEST: 'MARKET_SWAP_KLINE_REQUEST' = 'MARKET_SWAP_KLINE_REQUEST';
  static MARKET_SWAP_KLINE_RESET: 'MARKET_SWAP_KLINE_RESET' = 'MARKET_SWAP_KLINE_RESET';
  static MARKET_SWAP_KLINE_ERROR: 'MARKET_SWAP_KLINE_ERROR' = 'MARKET_SWAP_KLINE_ERROR';

  static MARKET_SPOT_KLINE_DATA: 'MARKET_SPOT_KLINE_DATA' = 'MARKET_SPOT_KLINE_DATA';
  static MARKET_SPOT_KLINE_REQUEST: 'MARKET_SPOT_KLINE_REQUEST' = 'MARKET_SPOT_KLINE_REQUEST';
  static MARKET_SPOT_KLINE_RESET: 'MARKET_SPOT_KLINE_RESET' = 'MARKET_SPOT_KLINE_RESET';
  static MARKET_SPOT_KLINE_ERROR: 'MARKET_SPOT_KLINE_ERROR' = 'MARKET_SPOT_KLINE_ERROR';

  static ALL_SYMBOL_LIST: 'ALL_SYMBOL_LIST' = 'ALL_SYMBOL_LIST'; // 全量symbol， 优化ws每秒多次dispatch导致页面渲染一直执行

  static FIATDEPOSIT_CONFIG: 'FIATDEPOSIT_CONFIG' = 'FIATDEPOSIT_CONFIG';
  static EVENTCENTER_CONFIG: 'EVENTCENTER_CONFIG' = 'EVENTCENTER_CONFIG';
  static VIP_EVENTCENTER_CONFIG: 'VIP_EVENTCENTER_CONFIG' = 'VIP_EVENTCENTER_CONFIG';
  static REG_TRIAL_FUND_CONFIG: 'REG_TRIAL_FUND_CONFIG' = 'REG_TRIAL_FUND_CONFIG';
  static SHOW_ASSET_AMOUNT: 'SHOW_ASSET_AMOUNT' = 'SHOW_ASSET_AMOUNT';
  static SOCIA_NEWS_FETCH: 'SOCIA_NEWS_FETCH' = 'SOCIA_NEWS_FETCH';
  static RECOMMEND_POST_FETCH: 'RECOMMEND_POST_FETCH' = 'RECOMMEND_POST_FETCH';
  static EXPLORE_LONGSHORT_FETCH: 'EXPLORE_LONGSHORT_FETCH' = 'EXPLORE_LONGSHORT_FETCH';
  static EXPLORE_LONGSHORT_REQUEST: 'EXPLORE_LONGSHORT_REQUEST' = 'EXPLORE_LONGSHORT_REQUEST';
  static EXPLORE_LONGSHORT_ERROR: 'EXPLORE_LONGSHORT_ERROR' = 'EXPLORE_LONGSHORT_ERROR';

  static NOTICE_SYSTEM: 'NOTICE_SYSTEM' = 'NOTICE_SYSTEM';
  static NOTICE_OTC: 'NOTICE_OTC' = 'NOTICE_OTC';
  static NOTICE_REWARD: 'NOTICE_REWARD' = 'NOTICE_REWARD';
  static NOTICE_ALL: 'NOTICE_ALL' = 'NOTICE_ALL';
  static REGBONUS_OPENTIME: 'REGBONUS_OPENTIME' = 'REGBONUS_OPENTIME';
  static REG_BONUS_CONFIG: 'REG_BONUS_CONFIG' = 'REG_BONUS_CONFIG';
  static DISCOVER_CONFIG: 'DISCOVER_CONFIG' = 'DISCOVER_CONFIG';
  static TRADER_ENTRUST_DATA_REQUEST: 'TRADER_ENTRUST_DATA_REQUEST' = 'TRADER_ENTRUST_DATA_REQUEST';
  static FIRST_SHOW: 'FIRST_SHOW' = 'FIRST_SHOW';

  static FINANCING_PRODUCTS: 'FINANCING_PRODUCTS' = 'FINANCING_PRODUCTS';
  static FINANCING_PRODUCTS_REQUEST: 'FINANCING_PRODUCTS_REQUEST' = 'FINANCING_PRODUCTS_REQUEST';
  static FINANCING_PRODUCTS_ERROR: 'FINANCING_PRODUCTS_ERROR' = 'FINANCING_PRODUCTS_ERROR';
  static FINANCING_PRODUCTS_RESET: 'FINANCING_PRODUCTS_RESET' = 'FINANCING_PRODUCTS_RESET';
  static UPDATE_FINANCING_PRODUCTS: 'UPDATE_FINANCING_PRODUCTS' = 'UPDATE_FINANCING_PRODUCTS';
  static UPDATE_INVESTMENT_PRODUCTS: 'UPDATE_INVESTMENT_PRODUCTS' = 'UPDATE_INVESTMENT_PRODUCTS';

  static INVESTMENT_CONTRACTS_REQUEST: 'INVESTMENT_CONTRACTS_REQUEST' = 'INVESTMENT_CONTRACTS_REQUEST';
  static INVESTMENT_CONTRACTS: 'INVESTMENT_CONTRACTS' = 'INVESTMENT_CONTRACTS';
  static INVESTMENT_CONTRACTS_ERROR: 'INVESTMENT_CONTRACTS_ERROR' = 'INVESTMENT_CONTRACTS_ERROR';
  static INVESTMENT_CONTRACTS_RESET: 'INVESTMENT_CONTRACTS_RESET' = 'INVESTMENT_CONTRACTS_RESET';

  static INVESTMENT_PRODUCTS_REQUEST: 'INVESTMENT_PRODUCTS_REQUEST' = 'INVESTMENT_PRODUCTS_REQUEST';
  static INVESTMENT_PRODUCTS: 'INVESTMENT_PRODUCTS' = 'INVESTMENT_PRODUCTS';
  static INVESTMENT_PRODUCTS_ERROR: 'INVESTMENT_PRODUCTS_ERROR' = 'INVESTMENT_PRODUCTS_ERROR';
  static INVESTMENT_PRODUCTS_RESET: 'INVESTMENT_PRODUCTS_RESET' = 'INVESTMENT_PRODUCTS_RESET';

  static FINANCING_CONTRACTS_REQUEST: 'FINANCING_CONTRACTS_REQUEST' = 'FINANCING_CONTRACTS_REQUEST';
  static FINANCING_CONTRACTS: 'FINANCING_CONTRACTS' = 'FINANCING_CONTRACTS';
  static FINANCING_CONTRACTS_ERROR: 'FINANCING_CONTRACTS_ERROR' = 'FINANCING_CONTRACTS_ERROR';
  static FINANCING_CONTRACTS_RESET: 'FINANCING_CONTRACTS_RESET' = 'FINANCING_CONTRACTS_RESET';

  static INVESTMENT_CONTRACTS_REQUEST: 'INVESTMENT_CONTRACTS_REQUEST' = 'INVESTMENT_CONTRACTS_REQUEST';
  static INVESTMENT_CONTRACTS: 'INVESTMENT_CONTRACTS' = 'INVESTMENT_CONTRACTS';
  static INVESTMENT_CONTRACTS_ERROR: 'INVESTMENT_CONTRACTS_ERROR' = 'INVESTMENT_CONTRACTS_ERROR';
  static INVESTMENT_CONTRACTS_RESET: 'INVESTMENT_CONTRACTS_RESET' = 'INVESTMENT_CONTRACTS_RESET';

  static FINANCING_ACCOUNT: 'FINANCING_ACCOUNT' = 'FINANCING_ACCOUNT';

  static SHOW_MIN_COINCODE: 'SHOW_MIN_COINCODE' = 'SHOW_MIN_COINCODE';

  static FULL_MARKET_SYMBOLS: 'FULL_MARKET_SYMBOLS' = 'FULL_MARKET_SYMBOLS';
  static FULL_MARKET_SYMBOLS_REFRESH: 'FULL_MARKET_SYMBOLS_REFRESH' = 'FULL_MARKET_SYMBOLS_REFRESH';
}
