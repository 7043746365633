/* @flow */

import React from 'react';
import type { Element } from 'react';
import {
  Animated,
  DeviceEventEmitter,
  FlatList,
  StyleSheet,
  Text,
  View,
  InteractionManager,
  ImageBackground
} from 'react-native';
import { TouchableOpacity, TouchableWithoutFeedback } from 'react-native-gesture-handler';
import EasyToast from 'react-native-easy-toast';
import { Toast } from 'teaset';
import { defaultMapDispatchProp, customMapStateProp } from '../../reducers';

import helper, { isSocialLogin } from '../../utils/helper';
import p from '../../utils/Transfrom';
import { PostItemContentLoading, NewsItemContentLoading } from '../../components/ContentLoading';
import PostComponent from '../../components/PostComponent';
import DeleteModal from '../../components/DeleteModal';
import ImageZoomViewer from '../../components/ImageZoomViewer';
import PostMoreOptionModal from '../../components/PostMoreOptionModal';
import Topic from '../../components/Topic';
import bugsnag from '../../utils/bugsnag';
import config from '../../utils/config';
import share from '../../utils/share';
import Swiper from 'react-native-swiper';
import I18n from '../../utils/i18n';
import UIStyle from '../../components/UIStyle';
import _ from 'lodash'
import CommentAndReplyModal from './CommentAndReplyModal'
import RefreshPostCount from './RefreshPostCount'
import { getClient } from '../../api/social';
import type {
  FollowEventType,
  LikeEventType
} from '../../events/SocialEvents';
import {
  COMMENT_EVENT,
  FOLLOW_EVENT,
  LIKE_EVENT,
  TOPIC_POLL_EVENT
} from '../../events/SocialEvents';
import TraderAPI from '../../api/trader';
import FastImage from 'react-native-fast-image';
import LottieLoading from '../../components/LottieLoading';
import { sectionTitleStyle, margin } from '../../styles/Common'

import { connect } from 'react-redux';
import SocialBaseComponent from '../../components/SocialBaseComponent';
import EmptyComponent from '../../components/EmptyComponent';
import NetInfo from '@react-native-community/netinfo';
import type { NetInfoState } from '@react-native-community/netinfo';
import type { SocialResult } from '../../api/base'
import type { FetchTopicAllItemResponseType } from '../../api/topicPoll'
import type { Feed } from '../../models/social'
import { SetNewsData, SetRecomendPostData, GetHotTopic } from '../../actions/SocialAction';
import type { ItemDataType } from '../../components/PostComponent';
import type { ContentItemType } from '../../components/LongTextPublishedComponent';

import type { InfoResponseType, BlockUserResponseType } from '../../api/trader'

import { SpringScrollView } from 'react-native-spring-scrollview'
import LottieHeader from '../../components/LottieHeader'

const striptags = require('striptags');

export type PropsType = {
  activeRouteName: string,
  type: string
};
export type ImageType = {
  url: string,
  images: Array<string>
};

export type VideoDataType = {
  id: string
};

type TopicItemType = {
  [key: string]: number,
  foo: string,
  favorite: boolean,
  like: boolean,
  id: string,
  reactionId: string
};

export type ItemType = {
  customerMisc: {
    customerId: string,
    avatar: string,
    level: number,
    follow: boolean,
    nickName: string
  },
  title: string,
  customerId: string,
  syncId: string,
  commentCount: number,
  comments: Array<ItemDataType>,
  like: boolean,
  likeCount: number,
  id: string,
  favorite: boolean,
  favoriteCount: number,
  shareCount: number,
  text: string,
  isVideo?: boolean,
  images: Array<string>,
  videoImages?: Array<string>,
  videos?: Array<string>,
  videoSource?: string,
  videoThumbnail?: string,
  width?: number,
  height?: number,
  image_width?: number,
  image_height?: number
};
export type PostItemType = {
  item: ItemType,
  index: number
};

// type StateType = {
//   modalVisible: boolean,
//   loading_recommend: boolean,
//   loading_myFollow: boolean,
//   unread: '',
//   index: number,
//   textSizeAnimate1: number,
//   textSizeAnimate2: number,
//   loading: {},
//   activeRouteName: string,
//   refreshCount: number,
//   isRefresh: boolean,
//   offsetY: number,
//   currentRouteIndex: number,
//   contentOffsetY: number,
//   loadmoreList: Array<{}>,
//   recommends: Array<{}>,
//   myFollows: Array<{}>,
//   myFeeds: Array<{}>,
//   myFavs: Array<{}>,
//   recommendLastFeedId: string,
//   myFollowLastFeedId: string,
//   feeds: Array<{}>,
//   type: string,
//   someoneUserId: string,
//   followList: Array<{}>,
//   currentUser: string,
//   noMore: boolean,
//   selectType: number,
//   data: Array<{}>,
//   pollList: Array<{}>,
//   topicList: Array<{}>,
//   moreFeedRequestNum: number
// };

type OptType = {
  limit: number
};

type Opt1Type = {
  getStream: boolean,
  limit?: number
};

type Opt2Type = {
  hash: string
};

function PublishPostBar ({ publishPosting, publishPostFail, publishPostText, type, onPressRePublish }: {
  publishPosting: boolean,
  publishPostFail: boolean,
  publishPostText: string,
  type: string,
  onPressRePublish: () => void
}): Element<*> {
  console.log('PublishPostBar')
  return (
    <>
        {
          publishPosting && type === 'recent'
            ? <View style={[{ backgroundColor: '#fff', flexDirection: 'row', paddingRight: p(30), paddingLeft: p(20), width: p(750), height: p(88), borderRadius: p(24), marginBottom: p(20), alignItems: 'center', justifyContent: 'space-between' }, UIStyle.shadow]}>
            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
              <Text numberOfLines={1} style={{ fontSize: p(24), color: '#696F7F' }}>【{publishPostText}】</Text>
              {
                !publishPostFail
                  ? <Text style={{ fontSize: p(24), color: '#696F7F' }}>{I18n.t('publishPosting1')}</Text>
                  : null
              }

            </View>
            {
              publishPostFail
                ? <TouchableWithoutFeedback onPress={onPressRePublish}>
                <View style={{ backgroundColor: 'rgba(111,64,238,0.1)', paddingHorizontal: p(20), height: p(40), alignItems: 'center', justifyContent: 'center', borderRadius: p(6) }}>
                  <Text style={{ color: '#6F40EE', fontSize: p(24) }}>{I18n.t('rePublishPost')}</Text>
                </View>
              </TouchableWithoutFeedback>
                : null
            }

          </View>
            : null

        }

    </>

  )
}

const MemoizedPublishPostBar = React.memo(PublishPostBar);

class Posts extends SocialBaseComponent {
  _isMounted: boolean;
  constructor (props: PropsType) {
    super(props);
    this._isMounted = false;
    this.instance = null;
    this.state = {
      modalVisible: false,
      loading_recommend: false,
      loading_recents: false,
      loading_myFollow: false,
      unread: '',
      index: 0,
      publishPosting: false,
      publishPostFail: false,
      // textSizeAnimate1: new Animated.Value(p(42)),
      // textSizeAnimate2: new Animated.Value(p(24)),
      loading: {},
      activeRouteName: this.props.activeRouteName,
      // socialLists:postsData.socialLists,
      // refreshList:postsData.refreshList,
      refreshCount: 0,
      isRefresh: false,
      offsetY: 0,
      currentRouteIndex: 0,
      loadmoreList: [],
      recommends: [],
      recents: [],
      myFollows: [],
      myFeeds: [],
      myFavs: [],
      recommendLastFeedId: '',
      myFollowLastFeedId: '',
      feeds: [],
      type: this.props.type || '',
      someoneUserId: '', // 获取其他人的个人feed流
      followList: [],
      currentUser: 'all',
      noMore: false,
      // selectType: 0,
      data: [],
      pollList: [],
      topicList: [],
      moreFeedRequestNum: 0,
      modalMoreOptionVisible: false, // 控制更多操作显示与隐藏
      countVisible: false,
      currentTextData: '' // 存储发帖时当前正在处理的贴文
    };
  }

  componentWillUnmount () {
    this._isMounted = false
    if (this.followEventListener) {
      this.followEventListener.remove();
    }
    if (this.likeEventListener) {
      this.likeEventListener.remove();
    }
    if (this.commentEventListener) {
      this.commentEventListener.remove();
    }
    if (this.topicPollEventListener) {
      this.topicPollEventListener.remove();
    }
    if (this.listener3) {
      this.listener3.remove();
    }
    if (this.publishPostListener) {
      this.publishPostListener.remove()
    }

    if (this.publishPostDoneListener) {
      this.publishPostDoneListener.remove()
    }

    if (this.publishPostDoneListener) {
      this.publishPostDoneListener.remove()
    }

    if (this.refreshFeedsListener) {
      this.refreshFeedsListener.remove()
    }
  }

  componentDidMount () {
    const that = this;
    // const { type } = this.props;
    this._isMounted = true
    const type = this.props.type || this.state.type;

    // 修改所有 myFeeds  ,当前页面只有两个地方触发 1） kol首页  2） 普通用户首页， 这两处展示的都是type=myfeed
    this.followEventListener = DeviceEventEmitter.addListener(
      FOLLOW_EVENT,
      function (event: FollowEventType) {
        if (that._isMounted) {
          const feedsList = [
            that.state.myFeeds,
            that.state.recommends,
            that.state.recents,
            that.state.myFollows,
            that.state.myFavs
          ];
          feedsList.forEach((list: Array<ItemType>) => {
            if (list) {
              list.forEach((item: ItemType) => {
                if (item.customerMisc.customerId === event.customerId) {
                  if (item.customerMisc.follow !== event.follow) {
                    item.customerMisc.follow = event.follow;
                  }
                }
              });
            }
          });

          that.setState({
            myFeeds: feedsList[0],
            recommends: feedsList[1],
            recents: feedsList[2],
            myFollows: feedsList[3],
            myFavs: feedsList[4]
          });
        }
      }
    );

    this.likeEventListener = DeviceEventEmitter.addListener(
      LIKE_EVENT,
      function (likeEvent: LikeEventType) {
        // 修改所有 feed
        if (that._isMounted) {
          // console.log('receive event:' + JSON.stringify(likeEvent));
          const feedsList = [
            that.state.myFeeds,
            that.state.recommends,
            that.state.recents,
            that.state.myFollows,
            that.state.myFavs
          ];
          switch (likeEvent.targetType) {
            case 'post':
            // 修改所有 feeds  ,当前页面只有两个地方触发 1） kol首页  2） 普通用户首页， 这两处展示的都是type=myfeed的 feed流数据
              feedsList.forEach((list: Array<ItemType>) => {
                if (list) {
                  const newFeeds = list;
                  newFeeds.forEach((item: ItemType) => {
                    if (item.id === likeEvent.targetId || item.syncId === likeEvent.targetId) {
                      item.like = likeEvent.like;
                      item.likeCount += likeEvent.like ? 1 : -1;
                    }
                  });
                }
              });
              that.setState({
                myFeeds: feedsList[0],
                recommends: feedsList[1],
                recents: feedsList[2],
                myFollows: feedsList[3],
                myFavs: feedsList[4]
              });
              break;
            case 'comment':
              break;
            case 'reply':
              break;
          }
        }
      }
    );

    this.commentEventListener = DeviceEventEmitter.addListener(
      COMMENT_EVENT,
      function (commentEvent: {postId: string, commentItem: ItemDataType}) {
        const customerMisc = that.props.SocialReducer.socialUserInfo;
        if (that._isMounted) {
          const feedsList = [
            that.state.myFeeds,
            that.state.recommends,
            that.state.recents,
            that.state.myFollows,
            that.state.myFavs
          ];
          feedsList.forEach((list: Array<ItemType>) => {
            if (list) {
              const newFeeds = list;
              newFeeds.forEach((item: ItemType) => {
                if (item.id === commentEvent.postId || item.syncId === commentEvent.postId) {
                  item.commentCount += 1;
                  commentEvent.commentItem && (item.comments || []).unshift({ ...commentEvent.commentItem, customerMisc })
                }
              });
            }
          });

          that.setState({
            myFeeds: feedsList[0],
            recommends: feedsList[1],
            recents: feedsList[2],
            myFollows: feedsList[3],
            myFavs: feedsList[4]
          });
        }
      }
    );

    this.topicPollEventListener = DeviceEventEmitter.addListener(
      TOPIC_POLL_EVENT,
      () => {
        if (that._isMounted) {
          this._onRefresh();
        }
      }
    );
    if (type === 'recent') {
      that.publishPostListener = DeviceEventEmitter.addListener('publishPosting', function (longTextData: {title: string, content: Array<ContentItemType>}) {
        if (that._isMounted && !that.state.publishPosting) {
          that.setPublishPostText(longTextData)
        }
      });

      that.publishPostDoneListener = DeviceEventEmitter.addListener('publishPostDone', function () {
        if (that._isMounted) {
          that.setState({
            publishPosting: false
          })
        }
      });

      that.publishPostDoneListener = DeviceEventEmitter.addListener('publishPostFail', function () {
        if (that._isMounted) {
          that.setState({
            publishPostFail: true
          })
        }
      });
    }

    if (type === 'recommend' || that.props.squareType === 'square_news') {
      that.initStream({ getStream: true, limit: this.props.limit });
      if (this.props.hasTopic) {
        const { socialToken } = this.props.SocialReducer;
        const { userInfo } = this.props.HomeReducer;
        that.props.dispatch(GetHotTopic(socialToken ?? userInfo?.jwtKey))
      }

      that.listener3 = DeviceEventEmitter.addListener(
        'refreshRecommend',
        function () {
          if (that._isMounted) {
            that._onRefresh()
            that.setState(
              {
                recommendLastFeedId: '',
                isRefresh: true,
                moreFeedRequestNum: 0,
                recommends: []
              },
              function () {
                that.initStream({ getStream: true, limit: that.props.limit });
              }
            );
          }
        }
      );
    }

    if (type === 'myFollow' || type === 'recent' || (type === 'recommend' && !this.props.newsTitle)) {
      that.socialRefresh = DeviceEventEmitter.addListener(
        'socialRefresh',
        function (currentRefreshTabType: string) {
          if (that._isMounted && (that.state.type === currentRefreshTabType)) {
            that._onSocialRefresh(currentRefreshTabType);
          }
        }
      );
    }

    that.refreshFeedsListener = DeviceEventEmitter.addListener(
      'refreshFeeds',
      function (data: {type: string, limit: number}) {
        if (!that.props.noAddMore) {
          if (
            (type === 'recommend' && that.state.recommends.length) ||
              (type === 'myFeed' && that.state.myFeeds.length && (that.props.someoneUserId === that.state.someoneUserId)) ||
              (type === 'myFav' && that.state.myFavs.length) ||
              (type === 'recent' && that.state.recents.length) ||
              (type === 'myFollow' && that.state.myFollows.length)

          ) {
            return
          }

          that.initStream({ getStream: true, limit: data.limit ?? 6 });
        }
      }
    );

    that.blurSubscription = this.props.navigation.addListener(
      'focus',
      () => {
        if (
          (type === 'recommend' && that.state.recommends.length) ||
            (type === 'myFeed' && that.state.myFeeds.length && (that.props.someoneUserId === that.state.someoneUserId)) ||
            (type === 'myFav' && that.state.myFavs.length) ||
            (type === 'recent' && that.state.recents.length) ||
            (type === 'myFollow' && that.state.myFollows.length)

        ) {
          return
        }

        if (that.state.recommends.length < 3 && that.state.recommendLastFeedId) {
          if (that.state.loadingMore && that.state.data_fetch_error) {
            return;
          }
          that.setState({
            loadingMore: true
          });

          that.refreshFeedStream(true, { limit: 3 }, function () {
            that.setState({
              loadingMore: false
            });
          });
        }

        that.setState(
          {
            type: type,
            // selectType: type === 'myFollow' ? 1 : 0,
            myFollows: [],
            someoneUserId:
              type === 'myFeed'
                ? that.props.someoneUserId
                : (that.state.someoneUserId || that.props.someoneUserId || '')
          },
          function () {
            if (this.props.noAddMore) {
              that.initStream({ getStream: true, limit: this.props.limit });
            } else {
              that._onRefresh();
            }
          }
        );
      }
    );
  };

  setPublishPostText: (longTextData: {title: string, content: Array<ContentItemType>}) => void = (longTextData: {title: string, content: Array<ContentItemType>}) => {
    const that = this
    let publishPostText
    try {
      const _longTextData = longTextData
      if (_longTextData.title) {
        publishPostText = _longTextData.title.length > 15 ? (_longTextData.title.substr(0, 15) + '...') : _longTextData.title
      } else {
        if (_longTextData.content.length > 0 && (_longTextData.content[0].type === 'image' || _longTextData.content[0].type === 'video')) {
          const textArr = _.filter(_longTextData.content, function (item: ContentItemType): boolean { return item.type === 'text' && item.content?.trim() !== '' })
          if (!textArr.length) {
            publishPostText = _longTextData.content[0].type === 'image' ? I18n.t('image') : I18n.t('video')
          } else {
            publishPostText = (textArr[0].content ?? '').length > 15 ? ((textArr[0].content ?? '').substr(0, 15) + '...') : textArr[0].content
          }
        } else {
          const textArr = _.filter(_longTextData.content, function (item: ContentItemType): boolean { return item.type === 'text' && item.content?.trim() !== '' })
          publishPostText = (textArr[0].content ?? '').length > 15 ? ((textArr[0].content ?? '').substr(0, 15) + '...') : textArr[0].content
        }
      }

      that.setState({
        publishPosting: true,
        publishPostText: publishPostText,
        currentTextData: longTextData
      })
    } catch (error) {
      bugsnag.notify(new Error('[Posts]--[setPublishPostText]--error:' + error.toString()));
    }
  };

  measureLayout: () => void = () => {
    const that = this;
    setTimeout(function () {
      that.postsComponent &&
        that.postsComponent.measure((fx: number, fy: number, width: number, height: number, px: number, py: number) => {
          that.setState({
            layoutHeight: height,
            layoutPy: py
          });

          that.props.onScrollChange &&
            that.props.onScrollViewLayout(
              that.state.type || that.props.type,
              height,
              py
            );
        });
    }, 10);
  };

  initStream: (opt: Opt1Type) => void = (opt: Opt1Type) => {
    if (opt && opt.getStream) {
      this.refreshFeedStream(false, { limit: (opt && opt.limit) || 4 }, function () {});
    }
  };

  getMoreFeedList: (arr: Feed[], num: number) => void = (arr: Feed[], num: number) => {
    const that = this;
    const len = arr.length;
    if (len < 3 && num < 5) {
      // if (that.state.loadingMore && that.state.data_fetch_error) {
      //   return;
      // }
      that.setState({
        loadingMore: true
      });

      that.refreshFeedStream(true, { limit: 3 }, function () {
        that.setState({
          loadingMore: false
        });
      });
    }
  };

  refreshFeedStream: (addMore: boolean, opt: OptType, callback: () => void) => void = (addMore: boolean, opt: OptType, callback: () => void) => {
    console.log(' refreshFeedStream  --- ');

    const locale = I18n.locale_new;

    if (this.refreshFeed) {
      return;
    }
    this.refreshFeed = true;
    const that = this;
    const {
      recommendLastFeedId,
      recentLastFeedId,
      myFollowLastFeedId,
      myFeedLastFeedId,
      myFavLastFeedId,
      type,
      recommends,
      recents,
      myFollows,
      myFeeds,
      myFavs,
      refreshViewId
    } = that.state;

    const { userId } = this.props.SocialReducer;

    const language = locale;
    const queryParam = {
      limit: (opt && opt.limit) || this.props.limit || 4,
      language: language,
      id_lte: '',
      refreshViewId: ''
    };
    let feedParamUser = 'post';
    let feedParamType = 'square';

    if (this.state.data_fetch_error) {
      this.setState({
        no_internet: false,
        server_busy: false,
        data_fetch_error: false
      });
      if (type === 'recommend') {
        this.setState({
          recommends: []
        });
      }

      if (type === 'recent') {
        this.setState({
          recents: []
        });
      }

      if (type === 'myFollow') {
        this.setState({
          myFollows: []
        });
      }
      if (type === 'myFeed') {
        this.setState({
          myFeeds: []
        });
      }
      if (type === 'myFav') {
        this.setState({
          myFavs: []
        });
      }
    }

    if (type === 'recommend') {
      if (this.state.data_fetch_error) {
        this.setState({
          recommends: []
        });
      }
      // recommend
      feedParamType = type

      if (addMore !== true && recommends.length === 0) {
        that.setState({
          loading_recommend: true
        });
      }
      if (refreshViewId) {
        queryParam.refreshViewId = refreshViewId;
      }
      if (recommendLastFeedId) {
        queryParam.id_lte = recommendLastFeedId;
        queryParam.language = language;
      }

      if (this.props.squareType === 'square_news') {
        feedParamType = 'square';
        feedParamUser = 'news';
      }
    } else if (type === 'recent') {
      if (this.state.data_fetch_error) {
        this.setState({
          recents: []
        });
      }

      // recents
      if (addMore !== true && recents.length === 0) {
        that.setState({
          loading_recents: true
        });
      }
      if (refreshViewId) {
        queryParam.refreshViewId = refreshViewId;
      }
      if (recentLastFeedId) {
        queryParam.id_lte = recentLastFeedId;
        queryParam.language = language;
      }
    } else if (type === 'myFollow') {
      // myFollow

      if (addMore !== true && myFollows.length === 0) {
        that.setState({
          loading_myFollow: true
        });
      }

      // eslint-disable-next-line no-undef
      feedParamType = USER_ID || userId;
      if (refreshViewId) {
        queryParam.refreshViewId = refreshViewId
      }
      if (myFollowLastFeedId) {
        queryParam.id_lte = myFollowLastFeedId;
        queryParam.language = language;
      }
    } else if (type === 'myFeed') {
      // myFollow

      if (addMore !== true && myFeeds.length === 0) {
        that.setState({
          loading_myFeed: true
        });
      }

      feedParamUser = 'my';
      // eslint-disable-next-line no-undef
      if (that.state.someoneUserId || that.props.someoneUserId) {
        // 获取其他人的个人主页feed流
        feedParamType = that.state.someoneUserId || that.props.someoneUserId;
      } else {
        feedParamType = USER_ID || userId;
        queryParam.limit = 6;
      }
      if (myFeedLastFeedId) {
        queryParam.id_lte = myFeedLastFeedId;
        queryParam.language = language;
      }
    } else if (type === 'myFav') {
      if (addMore !== true && myFavs.length === 0) {
        that.setState({ loading_myFav: true });
      }
      feedParamUser = 'posts';
      // eslint-disable-next-line no-undef
      feedParamType = USER_ID || userId;
      if (myFavLastFeedId) {
        queryParam.id_lte = myFavLastFeedId;
        queryParam.language = language;
      }
    }

    const successFn = function (body: SocialResult<Feed[]>) {
      console.log(body);
      console.log(JSON.stringify(body));
      that.refreshFeed = false;
      // console.log(body.result.length)
      // if (body.code && body.code !== 200) {
      // that.setState({
      //   loading_recommend: false,
      //   loading_myFollow: false,
      //   loading_myFeed: false,
      //   loading_myFav: false
      //   // data_fetch_error: true
      // });
      // that.setState({
      //   server_busy: true,
      // });
      // return that.refs.toast.show(I18n.t('common_serverBusy'));
      // }
      if (body && body.result) {
        const tempFeeds = body.result;
        let feeds: Feed[] = that.state.recommends;
        if (type === 'recent') {
          feeds = that.state.recents;
        } else if (type === 'myFollow') {
          feeds = that.state.myFollows;
        } else if (type === 'myFeed') {
          feeds = that.state.myFeeds;
        } else if (type === 'myFav') {
          feeds = that.state.myFavs;
        }

        if (tempFeeds.length < 3 && body.nextId) {
          const num = that.state.moreFeedRequestNum;
          that.setState({
            moreFeedRequestNum: num + 1
          })
          if (type === 'recent') {
            that.setState({
              recentLastFeedId: body.nextId
            }, () => {
              that.getMoreFeedList(tempFeeds, num + 1)
            });
          } else if (type === 'myFollow') {
            that.setState({
              myFollowLastFeedId: body.nextId
            }, () => {
              that.getMoreFeedList(tempFeeds, num + 1)
            });
          } else if (type === 'myFeed') {
            that.setState({
              myFeedLastFeedId: body.nextId
            }, () => {
              that.getMoreFeedList(tempFeeds, num + 1)
            });
          } else if (type === 'myFav') {
            that.setState({
              myFavLastFeedId: body.nextId
            }, () => {
              that.getMoreFeedList(tempFeeds, num + 1)
            });
          } else if (type === 'recommend') {
            that.setState({
              recommendLastFeedId: body.nextId
            }, () => {
              that.getMoreFeedList(tempFeeds, num + 1)
            });
          }
        }

        // if (that.props.getMoreMyFeed) {
        //   that.refreshFeedStream(true, { limit: 3 }, function () {
        //     that.setState({
        //       loadingMore: false
        //     });
        //   });
        // }

        if (tempFeeds.length === 0 && feeds && feeds.length > 0 && that.state.loadingMore && !body.nextId) {
          // 如果存在屏蔽的帖子，会返回result为空，但是nextId存在，需要处理，通过nextId继续请求,此处不能return请求
          that.setState({
            noMore: true,
            loadingMore: false,
            no_internet: false,
            server_busy: false
          });
          setTimeout(() => {
            that.setState({ noMore: false });
          }, 2000);
          return;
        }

        if (tempFeeds.length === 0 && addMore !== true) {
          // that.refs.toast.show('Feed没有数据');
          that.setState({
            isRefresh: false,
            loading_recommend: false,
            loading_recents: false,
            loading_myFollow: false,
            loading_myFeed: false,
            loading_myFav: false
          });
          that.updateCurrentFeed([])

          return;
        }

        if (addMore === true) {
          for (let e = 0; e < tempFeeds.length; e++) {
            let isContain = false; // 是否已经存在老的feeds中
            for (let m = 0; m < feeds.length; m++) {
              if (type === 'myFav') {
                // 我的收藏中，如果存在被删除的帖子，则数据中不会有id字段,此处对于获取到的新数据是删除状态的，则判断reactionId字段是否一致
                if (tempFeeds[e].id && feeds[m].id && feeds[m].id === tempFeeds[e].id) {
                  // 对于正常数据来说，采用id判断
                  isContain = true;
                } else {
                  // 已删除数据，通过reactionId判断
                  isContain = (!tempFeeds[e].id) && (tempFeeds[e].deleted) && (feeds[m].reactionId === tempFeeds[e].reactionId);
                }
              } else {
                if (feeds[m].id === tempFeeds[e].id) {
                  isContain = true;
                }
              }
            }
            if (!isContain) {
              feeds.push(tempFeeds[e]);
            }
          }
        } else {
          feeds = tempFeeds;
        }

        if (type === 'recommend') {
          // if (addMore !== false) {
          //   that.setState({
          //   });
          // }
          that.setState({
            recommendLastFeedId: body.nextId,
            recommends: that.props.limit && that.props.limit > 0 && that.props.noAddMore ? feeds.slice(0, that.props.limit) : feeds,
            isRefresh: false,
            loading_recommend: false,
            refreshPostCount: body.refreshCount || 0
          });
        } else if (type === 'recent') {
          // if (addMore !== false) {
          //   that.setState({
          //   });
          // }
          that.setState({
            recentLastFeedId: body.nextId,
            recents: feeds,
            isRefresh: false,
            loading_recents: false,
            refreshPostCount: body.refreshCount || 0
          });
        } else if (type === 'myFollow') {
          // if (addMore !== false) {
          //   that.setState({
          //     loading_myFollow: false
          //   });
          // }

          that.setState({
            myFollowLastFeedId: body.nextId,
            myFollows: feeds,
            loading_myFollow: false,
            isRefresh: false,
            refreshPostCount: body.refreshCount || 0
          });
        } else if (type === 'myFeed') {
          if (addMore !== true) {
            that.setState({
              // loading_myFeed: false
            });
          }

          // add relation of the user and USER
          if (feeds && feeds.length > 0) {
            const traderAPI = new TraderAPI(getClient());
            const params = {};
            if (isSocialLogin(that.props.SocialReducer)) {
              params.loginUserId = that.props.SocialReducer.userId;
            }
            traderAPI
              .getInfo(feeds[0].customerMisc.customerId || '', params)
              .then((r: InfoResponseType) => {
                if (r && r.result) {
                  feeds.forEach((item: Feed) => {
                    if (r.result.isFollow !== undefined) {
                      item.customerMisc.follow = r.result.isFollow;
                    }
                  });
                  that.setState({
                    myFeedLastFeedId: body.nextId,
                    myFeeds: feeds,
                    isRefresh: false,
                    loading_myFeed: false
                  });
                }
              })
              .catch((error: Error) => {
                bugsnag.notify(new Error('[Posts]--[myFeed]--error:' + error.toString()));

                // ignore error and set the feed
                that.setState({
                  myFeedLastFeedId: body.nextId,
                  myFeeds: feeds,
                  isRefresh: false,
                  loading_myFeed: false
                });
              });
          } else {
            that.setState({
              myFeedLastFeedId: body.nextId,
              myFeeds: feeds
              // isRefresh: false
            });
          }
        } else if (type === 'myFav') {
          that.setState({
            myFavLastFeedId: body.nextId,
            myFavs: feeds,
            isRefresh: false,
            loading_myFav: false
          });
        }

        callback && callback();
      } else {
        that.setState({
          loading_recommend: false,
          loading_recents: false,
          loading_myFollow: false,
          loading_myFeed: false,
          loading_myFav: false
          // data_fetch_error: true
        });
      }
    };

    if (type === 'myFav') {
      queryParam.limit = 6;
      const requestParams = {
        limit: queryParam.limit,
        id_lte: queryParam.id_lte,
        language: queryParam.language,
        user_id: global.USER_ID || userId,
        kind: 'FAVORITE',
        version: 2
      };

      getClient()
        .reactions.filter(requestParams)
        .then((body: SocialResult<Feed[]>) => {
          successFn(body);
        })
      // eslint-disable-next-line flowtype/no-weak-types
        .catch((error: any) => {
          that.refreshFeed = false;
          console.log(error);
          bugsnag.notify(new Error('[Posts]--[myFav]--error : ' + error.toString()));
          callback && callback();
          if (this.state.data_fetch_error) {
            return;
          }
          that.setState({
            isRefresh: false,
            loading_recommend: false,
            loading_recents: false,
            loading_myFollow: false,
            loading_myFeed: false,
            loading_myFav: false,
            data_fetch_error: true
          });

          NetInfo.fetch().then((state: NetInfoState) => {
            if (
              !state.isConnected ||
                (error.message && error.message.indexOf('Network request failed') !== -1)
            ) {
              that.setState({
                no_internet: true
              });

              Toast.show({
                text: I18n.t('common_networkError'),
                position: 'center'
              });
            } else {
              that.setState({
                server_busy: true
              });
              Toast.show({
                text: I18n.t('common_serverBusy'),
                position: 'center'
              });
            }
          });
        });
    } else {
      if (!feedParamType) {
        that.refreshFeed = false;
        that.setState({
          isRefresh: false
        });
        return;
      }

      if (!addMore) {
        if (this.props.squareType === 'square_news') {
          if (this.props.SocialReducer.newsData.result && this.props.SocialReducer.newsData.result.length > 0) {
            successFn(this.props.SocialReducer.newsData);
          }
        }
        if ((this.props.squareType !== 'square_news' && this.props.squareType !== 'discover_recommend') && type === 'recommend' && !addMore) {
          if (this.props.SocialReducer.recomendPostData.result && this.props.SocialReducer.recomendPostData.result.length > 0) {
            successFn(this.props.SocialReducer.recomendPostData);
          }
        }
      }

      getClient()
        .feed(feedParamUser, feedParamType)
        .get(queryParam)
        .then((body: SocialResult<Feed[]>) => {
          if (!addMore && this.props.squareType === 'square_news' && body.result.length > 0) {
            that.props.dispatch(SetNewsData(body));
          }
          if (!addMore && (this.props.squareType !== 'square_news' && this.props.squareType !== 'discover_recommend') && type === 'recommend') {
            that.props.dispatch(SetRecomendPostData(body));
          }
          console.log('getClient()', feedParamUser, feedParamType, body)
          successFn(body);
        })
        // eslint-disable-next-line flowtype/no-weak-types
        .catch((error: Error, xhr: any) => {
          that.refreshFeed = false;
          // alert(error.toString())
          console.log(error);
          // console.log(234234)

          // const errorText = xhr && xhr.responseText && typeof xhr.responseText === 'string' ? xhr.responseText : ''
          bugsnag.notify(new Error('[Posts]--[feedDetail]--error : ' + error.toString()));

          callback && callback();
          if (this.state.data_fetch_error) {
            return;
          }
          that.setState({
            isRefresh: false,
            loading_recommend: false,
            loading_myFollow: false,
            loading_myFeed: false,
            loading_myFav: false,
            loading_recents: false,
            data_fetch_error: true
          });
        });
    }
  };

  // eslint-disable-next-line flowtype/no-weak-types
  handleScroll: (event: any) => void = (event: any) => {
    try {
      const scrollHeight = event.nativeEvent.contentOffset.y;
      const { onScrollChange } = this.props;
      console.log('handleScroll scrollHeight：' + scrollHeight);
      onScrollChange && onScrollChange(
        this.state.type || this.props.type,
        scrollHeight
      );
    } catch (e) {
      bugsnag.notify(new Error('[Posts]--[handleScroll]--error : ' + e.toString()));
    }
  };

  _onSocialRefresh: (currentRefreshTabType: string) => void = (currentRefreshTabType: string) => {
    const that = this;
    const type = currentRefreshTabType;
    this.setState({ isRefresh: true, countVisible: true });
    const callbackFunc = () => {
      InteractionManager.runAfterInteractions(() => {
        that.initStream({ getStream: true, limit: 6 });
        // const { onScrollChange } = that.props;
        if (type === 'recommend') {
          const { socialToken } = this.props.SocialReducer;
          const { userInfo } = this.props.HomeReducer;
          that.props.dispatch(GetHotTopic(socialToken ?? userInfo?.jwtKey))
        }
        setTimeout(() => {
          this.instance && this.instance.scrollTo({ x: 0, y: -500, animated: true });
          // onScrollChange && onScrollChange(currentRefreshTabType, 0);
        }, 2100);
        setTimeout(() => {
          this.setState({ countVisible: false, refreshViewId: '', refreshCount: 0 })
        }, 6000);
      });
    }

    if (that.props.noAddMore) {
      return;
    }
    if (this.state.isRefresh) {
      return;
    }
    if (type === 'recommend') {
      const { recommends } = that.state;
      this.setState({
        loading_recommend: true,
        isRefresh: true,
        recommendLastFeedId: '',
        recommends: [],
        refreshViewId: recommends[0]?.id,
        refreshCount: 0
      }, callbackFunc);
    }

    if (type === 'recent') {
      const { recents } = that.state;
      this.setState({
        isRefresh: true,
        loading_recents: true,
        recentLastFeedId: '',
        recents: [],
        refreshViewId: recents[0]?.id,
        refreshCount: 0
      }, callbackFunc);
    }

    if (type === 'myFollow') {
      const { myFollows } = that.state;
      this.setState({
        isRefresh: true,
        loading_myFollow: true,
        myFollowLastFeedId: '',
        myFollows: [],
        refreshViewId: myFollows[0]?.id,
        refreshCount: 0
      }, callbackFunc);
    }
  };

  _onRefresh: () => void = () => {
    const that = this;
    if (that.props.noAddMore) {
      return;
    }
    console.log('this.state.isRefresh before ' + this.state.isRefresh);
    if (this.state.isRefresh) {
      return;
    }

    console.log('this.state.isRefresh after ' + this.state.isRefresh);

    setTimeout(() => {
      that.instance && that.instance.endRefresh && that.instance.endRefresh();
    }, 2000);

    that.setState(
      {
        recommendLastFeedId: '',
        recentLastFeedId: '',
        myFollowLastFeedId: '',
        myFeedLastFeedId: '',
        myFavLastFeedId: '',
        followList: [],
        someoneUserId: this.state.someoneUserId || this.props.someoneUserId || '',
        type: this.state.type || this.props.type || 'recommend',
        currentUser: 'all',
        isRefresh: true,
        recommends: [],
        recents: [],
        myFeeds: [],
        myFavs: [],
        moreFeedRequestNum: 0
      },
      function () {
        that.refreshFeedStream(false, { limit: 6 }, function () {});
        const { socialToken } = that.props.SocialReducer;
        const { userInfo } = that.props.HomeReducer;
        if (that.props.type === 'recommend') {
          that.props.dispatch(GetHotTopic(socialToken ?? userInfo?.jwtKey))
        }
      }
    );
  };

  SocialListItemSeparator: ()=> Element<*> = (): Element<*> => {
    const { squareType } = this.props;

    return (
      // Item Separator
      squareType === 'square_news'
        ? (
        <View
          style={{
            height: p(24)
          }}/>

          )
        : (
        <View style={{ height: StyleSheet.hairlineWidth, marginBottom: p(48), marginTop: p(40), marginHorizontal: p(30), backgroundColor: '#E2E2E2', borderRadius: p(24) }} />
          )
    );
  };

  SocialListItemLoadingSeparator: ()=> Element<*> = (): Element<*> => {
    return (
    // Item Separator
        <View style={{ height: StyleSheet.hairlineWidth, marginHorizontal: p(30), backgroundColor: '#E2E2E2', borderRadius: p(24) }} />
    );
  };

  NewsListItemLoadingSeparator: ()=> Element<*> = (): Element<*> => {
    return (
      // Item Separator
      <View style={{ height: p(54), backgroundColor: '#fff' }} />
    );
  };

  // eslint-disable-next-line flowtype/no-weak-types
  _contentViewScroll: () => void = () => {
    const that = this;
    const offsetY = that.instance._contentOffset.y; // 滑动距离
    const contentSizeHeight = that.instance._contentHeight; // scrollView contentSize高度
    const oriageScrollHeight = that.instance._height; // scrollView高度
    const endReachedThreshold = p(150);

    if (that.props.noAddMore || this.state.loading_recommend || this.state.loading_myFav || this.state.loading_myFeed || this.state.loading_myFollow) {
      return;
    }

    if (
      offsetY + oriageScrollHeight + endReachedThreshold >=
      contentSizeHeight
    ) {
      if (that.state.loadingMore && that.state.data_fetch_error) {
        return;
      }
      that.setState({
        loadingMore: true
      });

      that.refreshFeedStream(true, { limit: 3 }, function () {
        that.setState({
          loadingMore: false
        });
      });
    } else if (offsetY + oriageScrollHeight <= 1) {
      // 没有数据了然后给了false  得时候还在往上拉
    } else if (offsetY === 0) {
      // 下拉刷新，到顶了还在指行，可以在这个地方进行处理需要刷新得数据
    }
  };

  getMoreList () {
    const arr = this.state.refreshList;
    this.setState({
      loadmoreList: arr
    });
  }

  onClickImageView: () => void = () => {
    this.setState({
      modalVisible: false
    });
  };

  onDelete: (type: string, index: number) => void = (type: string, index: number) => {
    console.log(index);
    const currentFeeds = this.getCurrentFeed(type);
    currentFeeds.splice(index, 1)
    this.updateCurrentFeed(currentFeeds)
  };

  getCurrentFeed: (type: string) => Array<ItemType> = (type: string): Array<ItemType> => {
    let currentFeeds;
    const { recommends, recents, myFollows, myFeeds, myFavs } = this.state;
    currentFeeds = recommends;
    if (type === 'recent') {
      currentFeeds = recents;
    } else if (type === 'myFollow') {
      currentFeeds = myFollows;
    } else if (type === 'myFeed') {
      currentFeeds = myFeeds;
    } else if (type === 'myFav') {
      currentFeeds = myFavs;
    }
    return currentFeeds
  };

  updateCurrentFeed: (currentFeeds: Array<ItemType>) => void = (currentFeeds: Array<ItemType>) => {
    const { type } = this.state;
    if (type === 'recommend') {
      this.setState({
        recommends: currentFeeds
      });
    } else if (type === 'recent') {
      this.setState({
        recents: currentFeeds
      });
    } else if (type === 'myFollow') {
      this.setState({
        myFollows: currentFeeds
      });
    } else if (type === 'myFeed') {
      this.setState({
        myFeeds: currentFeeds
      });
    } else if (type === 'myFav') {
      this.setState({
        myFavs: currentFeeds
      });
    }
  };

  onLove: (newLove: boolean, type: string, index: number) => void = (newLove: boolean, type: string, index: number) => {
    console.log(newLove);
    console.log(type);
    console.log(index);
    const currentFeeds = this.getCurrentFeed(type);

    currentFeeds[index].like = newLove;
    if (newLove) {
      currentFeeds[index].likeCount += 1;
    } else {
      const tmpLikeCount = currentFeeds[index].likeCount - 1;
      currentFeeds[index].likeCount = tmpLikeCount === 0 ? 0 : tmpLikeCount;
    }

    // console.log(currentFeeds[index])
    this.updateCurrentFeed(currentFeeds)
  };

  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  onFav: (newFav: boolean, type: string, index: number) => void = (newFav: boolean, type: string, index: number) => {
    console.log(newFav);
    console.log(type);
    console.log(index);
    const currentFeeds = this.getCurrentFeed(type);

    currentFeeds[index].favorite = newFav;
    console.log(currentFeeds[index]);

    if (newFav) {
      currentFeeds[index].favoriteCount += 1;
    } else {
      currentFeeds[index].favoriteCount =
        currentFeeds[index].favoriteCount - 1 === 0
          ? 0
          : currentFeeds[index].favoriteCount - 1;
    }
    this.updateCurrentFeed(currentFeeds)
  };

  onComment: (item: ItemType, type: string, keyIndex: number) => void = (item: ItemType, type: string, keyIndex: number) => {
    this.onTextClick(item, type, keyIndex, { hash: 'comment' });
  };

  onTextClick: (item: ItemType, type: string, keyIndex: number, opt: Opt2Type) => void = (item: ItemType, type: string, keyIndex: number, opt: Opt2Type) => {
    const obj = {
      item: item,
      keyIndex: keyIndex,
      type: type,
      updateFollow: this.onFollow,
      updateFav: this.onFav,
      updateLove: this.onLove,
      deletePost: this.onDelete,
      hash: '',
      TRACK_PARAM: helper.toTrackParam(type, item.id)
    };
    if (opt) {
      Object.assign(obj, opt);
    }
    console.log(obj);
    helper.navigate(this.props.navigation.navigate, 'TopicDetail', obj);
  };

  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  onShare: (detailItem: ItemType) => void = (detailItem: ItemType) => {
    // return this.refs.toast.show(I18n.t('share_not_available'));
    const that = this
    let text = detailItem.title ? detailItem.title : striptags(detailItem.text)

    if (text.length > 25) {
      text = text.substr(0, 25) + '..'
    }
    const shareText =
      detailItem.customerMisc.nickName +
      ' ' + I18n.t('says') + ':' +
      text +
      ', ' +
      config.api.share +
      'kiki_post?id=' +
      detailItem.id;
    const shareTitle = shareText;

    share.toShare(
      '',
      shareTitle,
      shareText,
      '',
      config.api.share + 'kiki_post?id=' + detailItem.id,
      function (error: string) {
        that.shareCallback(error, detailItem.id)
      }
    );
  };

  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  shareCallback: (error: string, id: string) => void = (error: string, id: string) => {
    const that = this
    if (error) {
      console.log(error);
    } else {
      Toast.show({
        text: I18n.t('share_success'),
        position: 'center'
      });

      getClient()
        .reactions.add('SHARE', id)
        .then(() => {
          const currentFeeds = this.getCurrentFeed(this.state.type);

          const index = _.findIndex(currentFeeds, function (item: ItemType): boolean { return item.id === id })
          currentFeeds[index].shareCount += 1;

          that.updateCurrentFeed(currentFeeds)
        });
    }
  };

  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  onImageClick: (index: number, item: ImageType) => void = (index: number, item: ImageType) => {
    console.log(' onImageClick index ' + index);
    const t = [];
    // eslint-disable-next-line flowtype/no-types-missing-file-annotation,flowtype/require-return-type
    item.images.map(function (imgItem: string) {
      const obj = {};
      obj.url = imgItem;
      return t.push(obj);
    });
    this.setState({
      modalVisible: true,
      imageViewInitIndex: index,
      imageViewList: t
    });
  };

  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  setCurrentVideo: (videoData: VideoDataType) => void = (videoData: VideoDataType) => {
    if (videoData) {
      if (this.state.activeVideoId === videoData.id) {
        return;
      }
      console.log('new  activeVideoId : ' + videoData.id);
      this.setState({
        activeVideoId: videoData.id
      });
    }
  };

   onTouchEnd: () => void = () => {
     // viewableItems will show you what items are in view
     // console.log("onViewableItemsChanged")
     // console.log(viewableItems)
     // DeviceEventEmitter.emit("visibleAreaChange", {scrollHeight: event.nativeEvent.contentOffset.y, layoutHeight : this.state.layoutHeight, layoutPy: this.state.layoutPy})
     const that = this;
     const { recommendLastFeedId, recommends } = this.state;
     if (recommends.length < 3 && recommendLastFeedId) {
       if (that.state.loadingMore && that.state.data_fetch_error) {
         return;
       }
       that.setState({
         loadingMore: true
       });

       that.refreshFeedStream(true, { limit: 3 }, function () {
         that.setState({
           loadingMore: false
         });
       });
     }
   };

  // eslint-disable-next-line flowtype/no-types-missing-file-annotation,flowtype/require-return-type
  renderHeader: (isNews: boolean) => Element<*> = (isNews: boolean): Element<*> => {
    return isNews && !this.props.newsTitle
      ? (
        <Text
          style={[sectionTitleStyle, { marginBottom: margin.m32 }]}>
          {I18n.t('community_news_headlines')}
        </Text>
        )
      : <View/>;
  };

  // eslint-disable-next-line flowtype/no-types-missing-file-annotation,flowtype/require-return-type
  onFavoirite: (item: TopicItemType, keyIndex: number) => void = (item: TopicItemType, keyIndex: number): void => {
    const that = this;

    if (that.sending) {
      return
    }
    that.sending = true;

    getClient()
      .reactions.add('FAVORITE', item.reactionId, { reverse: true })
    // eslint-disable-next-line flowtype/require-parameter-type

      .then((body: SocialResult<Feed>) => {
        that.sending = false;
        if (body && body.result) {
          Toast.show({
            text: I18n.t('community_cancelFavSuccess'),
            position: 'center'
          });

          const _post = this.state.myFavs
          _post.splice(keyIndex, 1)
          this.setState({
            myFavs: _post
          })
        }
      })
      .catch((e: Error) => {
        console.log(e)

        bugsnag.notify(new Error('[Posts]--[onFavoirite]--error : ' + e.toString()));
      });
  };

  renderNewsSwiper: (isNews: boolean)=> Element<*> = (isNews: boolean): Element<*> => {
    const { recommends } = this.state;

    const { showNewsSwiper } = this.props;
    const SwiperView =
        (
            /*  */
            <Swiper
                autoplay
                showsPagination={false}
                autoplayTimeout={5}
              >
              {[0, 1, 2].map((item: number, index: number): Element<*> => {
                const img =
                    recommends[index] &&
                    recommends[index].images &&
                    recommends[index].images[0];
                const text = recommends[index] && recommends[index].text;

                const blurImage = <View style={styles.blurWrapper}>
                  <ImageBackground
                    style={styles.blurImage}
                    blurRadius={80}
                    source={ img ? { uri: img } : require('../../images/new_default_big.webp') }>
                    <View
                      style={{
                        position: 'absolute',
                        width: p(690),
                        height: p(96),
                        bottom: 0,
                        justifyContent: 'center',
                        paddingHorizontal: p(30)
                      }}>
                      <Text
                        numberOfLines={2}
                        style={{
                          color: '#fff',
                          fontSize: p(28),
                          fontWeight: 'bold'
                        }}>
                        {text}
                      </Text>
                    </View>
                  </ImageBackground>
                </View>

                return (
                    <TouchableWithoutFeedback
                        key={index}
                        onPress={() => {
                          this.props.navigation.navigate('WebViewPage', {
                            url: recommends[index].link,
                            title: I18n.t('community_news'),
                            TRACK_PARAM: recommends[index].link
                          });
                        }}>
                      <View style={{ alignItems: 'center', borderRadius: p(36) }}>

                        {
                          img
                            ? (
                              <FastImage
                                style={[
                                  {
                                    width: p(690),
                                    height: p(280),
                                    borderRadius: p(36),
                                    justifyContent: 'center'
                                  }
                                ]}
                                source={{
                                  uri: img
                                }}
                              >
                                {blurImage}
                              </FastImage>

                              )
                            : (
                              <FastImage
                                style={{
                                  width: p(690),
                                  height: p(280),
                                  borderRadius: p(36)
                                }}
                                source={require('../../images/new_default_big.webp')}
                              >
                                {blurImage}
                              </FastImage>
                              )
                        }
                      </View>
                    </TouchableWithoutFeedback>
                );
              })}
            </Swiper>
        )
    return isNews && showNewsSwiper
      ? (
          <View style={{ height: p(280), overflow: 'hidden', marginBottom: p(0), marginHorizontal: p(0), borderRadius: p(36) }}>
            {recommends.length > 0 ? SwiperView : null}
          </View>
        )
      : <View/>;
  };

  viewRef: (component: Element<*>) => void = (component: Element<*>) => {
    this.postsComponent = component
  };

  renderItem: ()=>Element<*> = (): Element<*> => {
    return (
        <View
            style={[
              styles.contentLoadingStyle,
              this.props.postListWraper
            ]}>
          <PostItemContentLoading />
        </View>
    )
  };

  renderNewsItem: ()=>Element<*> = (): Element<*> => {
    return (
        <View
            style={[
              styles.contentLoadingStyle,
              this.props.postListWraper
            ]}>
          <NewsItemContentLoading />
        </View>
    )
  };

  keyExtractor: (item: ItemType, index: number)=> string = (item: ItemType, index: number): string => {
    return index.toString()
  };

  onPressRePublish: () => void = () => {
    const { currentTextData } = this.state;
    DeviceEventEmitter.emit('publishPosting', currentTextData)
    this.setState({
      publishPostFail: false
    })
  };

  // 更多操作控制
  toggleModalPostMore: (newModalMoreOptionVisible: boolean, itemData?: ItemType, kIndex?: number) => void = (newModalMoreOptionVisible: boolean, itemData?: ItemType, kIndex?: number) => {
    this.setState({
      modalMoreOptionVisible: newModalMoreOptionVisible,
      detailItem: itemData,
      kIndex
    });
  };

  // 举报按钮响应
  onClickBlockPost: () => void = () => {
    const that = this;
    if (!isSocialLogin(this.props.SocialReducer)) {
      helper.navigate(this.props.navigation.navigate, 'Login');
    } else {
      this.setState({ modalMoreOptionVisible: false },
        function () {
          this.props.navigation.navigate('PostBlock', {
            item: that.state.detailItem
          });
        }
      );
    }
  };

  onClickDeletePost: () => void = () => {
    if (!isSocialLogin(this.props.SocialReducer)) {
      helper.navigate(this.props.navigation.navigate, 'Login');
    } else {
      const that = this;
      this.setState({ modalMoreOptionVisible: false },
        function () {
          setTimeout(function () {
            that.setState({ deleteModalVisble: true })
          }, 500)
        }
      );
    }
  };

  onClickHideUser: () => void = () => {
    if (!isSocialLogin(this.props.SocialReducer)) {
      helper.navigate(this.props.navigation.navigate, 'Login');
    } else {
      const that = this;
      this.setState({ modalMoreOptionVisible: false },
        function () {
          if (that.state.detailItem && that.state.detailItem.customerMisc) {
            that.blockPost(that.state.detailItem.customerMisc.customerId)
          }
        }
      );
    }
  };

  onPressDelete: (item: TopicItemType) => void = (item: TopicItemType) => {
    if (!isSocialLogin(this.props.SocialReducer)) {
      helper.navigate(this.props.navigation.navigate, 'Login');
    } else {
      this.setState({
        deleteModalVisble: false
      }, function () {
        this.deletePostItem(this.state.detailItem.id, 'post')
      })
    }
  };

  deletePostItem: (activityId: string, feedType: string) => void = (activityId: string, feedType: string) => {
    const feedParamUser = feedType;
    const { userId } = this.props.SocialReducer;

    const feedParamType = global.USER_ID || userId
    const { kIndex, type } = this.state
    getClient()
      .feed(feedParamUser, feedParamType)
      .removeActivity(activityId)
    // eslint-disable-next-line flowtype/require-parameter-type
      .then((body: SocialResult<Feed[]>) => {
        Toast.show({
          text: I18n.t('successfullyDelete'),
          position: 'center'
        });
        this.onDelete(type, kIndex);
      })
      .catch((e: Error) => {
        bugsnag.notify(new Error('[Posts]--[deletePostItem]--error: ' + e.toString()));

        Toast.show({
          text: I18n.t('common_network_error'),
          position: 'center'
        });
      }
      )
  };

  blockPost: (userId: string)=>void = (userId: string) => {
    const that = this;
    const traderAPI = new TraderAPI(getClient());
    traderAPI
      .blockPost(userId)
      .then((response: BlockUserResponseType) => {
        console.log(response)
        if (!response.result) {
          that.refs.toast.show(I18n.t('community_block_failed'));
        }
      })
      .catch((e: Error) => {
        bugsnag.notify(new Error('[Posts]--[blockPost]--error: ' + e.toString()));

        that.refs.toast.show(I18n.t('common_network_error'));
      });
  };

  onChangeCommentText: (text: string) => void = (text: string) => {
    if (text.trim().split('\n').length < 7) {
      this.setState({
        commentText: text
      })
    }
  };

  closeCommentAndReplyModal: () => void = () => {
    this.setState({
      commentAndReplyModalVisible: false,
      commentText: ''
    })
  };

  renderCommentAndReplyModal: ()=> Element<*> = (): Element<*> => {
    // 渲染评论弹窗
    return (
      <CommentAndReplyModal
        commentType={this.state.commentType}
        commentText={this.state.commentText}
        commentItemData={this.state.commentItemData}
        navigation={this.props.navigation}
        wordLimit={this.state.commentType === 'reply' ? 200 : 300}
        modalVisible={this.state.commentAndReplyModalVisible}
        onChangeCommentText={this.onChangeCommentText}
        closeModal={this.closeCommentAndReplyModal}
      />
    )
  };

  renderPostMoreOptionModal: ()=> Element<*> = (): Element<*> => {
    const { modalMoreOptionVisible } = this.state;
    // 列表更多操作弹窗
    return (
      <PostMoreOptionModal
        onClickBlockPost={this.onClickBlockPost}
        onClickHideUser={this.onClickHideUser}
        onClickDeletePost={this.onClickDeletePost}
        isManage={this.props.SocialReducer.socialUserInfo?.type === 20}
        visible={modalMoreOptionVisible}
        toggleModal={this.toggleModalPostMore}
        userId={this.props.SocialReducer.userId}
        userID={this.state.detailItem ? this.state.detailItem?.customerMisc?.customerId : ''}
      />
    )
  };

  toggleDeleteModal: () => void = () => {
    const { deleteModalVisble } = this.state;
    this.setState({
      deleteModalVisble: !deleteModalVisble
    })
  };

  renderDeleteModal: ()=> Element<*> = (): Element<*> => {
    // 确认删除操作弹窗
    const { deleteModalVisble } = this.state;
    return (
      <DeleteModal
        onPressDelete={this.onPressDelete}
        visible={deleteModalVisble}
        tip={I18n.t('community_delete_post_tip')}
        title={I18n.t('deleteConfirm')}
        // eslint-disable-next-line flowtype/require-return-type
        toggleModal={this.toggleDeleteModal}
      />
    )
  };

  renderImageViwer: () => Element<*> = (): Element<*> => {
    // 渲染预览图片组件
    const { modalVisible, imageViewList, imageViewInitIndex } = this.state;
    return (
      <>
        {modalVisible
          ? (
              <ImageZoomViewer
                imageViewList={imageViewList}
                onClickImageView={this.onClickImageView}
                imageViewInitIndex={imageViewInitIndex}
              />
            )
          : null
        }
      </>
    )
  };

  showCommentAndReplyModal: (type: string, item: ItemDataType) => void = (type: string, item: ItemDataType) => {
    this.setState({
      commentAndReplyModalVisible: true,
      commentType: type,
      commentItemData: item
    })
  };

  render (): Element<*> {
    const that = this;
    const {
      isRefresh,
      noInternet,
      serverBusy,
      type,
      publishPosting,
      publishPostFail,
      publishPostText,
      countVisible,
      refreshPostCount,
      animatedScrollYValue = new Animated.Value(0)
    } = this.state;
    const { squareType } = this.props;

    const { vipActivityOpen } = this.props.HomeReducer.remoteConfig;

    const { hotTopic } = this.props.SocialReducer;
    const topicPollData = _.chunk(hotTopic, 2);
    const isNews = squareType === 'square_news';
    const currentFeeds = this.getCurrentFeed(type);
    const scrollEnabled = this.props.scrollEnabled ?? true;
    const SwiperView = (
        <Swiper
            scrollViewStyle={{ borderRadius: p(18) }}
            autoplay
            autoplayTimeout={8}
            showsPagination
            paginationStyle={{ bottom: p(0) }}
            activeDot={
              <View
                  style={{
                    height: p(8),
                    width: p(18),
                    backgroundColor: '#6F40EE',
                    borderRadius: p(6),
                    marginHorizontal: p(5)
                  }}
              />
            }
            dot={
              <View
                  style={{
                    height: p(8),
                    width: p(18),
                    backgroundColor: '#EBE7F6',
                    borderRadius: p(6),
                    marginHorizontal: p(5)
                  }}
              />
            }>
          {
            topicPollData.map((item: FetchTopicAllItemResponseType[], index: number): Element<*> => (
              <View key={index} style={[{ flexDirection: 'row', borderRadius: p(18), marginHorizontal: p(20), flex: 1, justifyContent: 'space-between' }]}>
                {
                  item.map((rowItem: FetchTopicAllItemResponseType, _index: number): Element<*> =>
                    (<TouchableWithoutFeedback
                        onPress={() => {
                          if (rowItem) {
                            this.props.navigation.navigate(
                              'HotTopicFeed',
                              {
                                item: Object.assign(rowItem, { topicId: rowItem.id }),
                                TRACK_PARAM: rowItem.id
                              }
                            );
                          }
                        }}
                      >
                      <View style={{}}><Topic itemData={rowItem} /></View>
                    </TouchableWithoutFeedback>
                    )
                  )
                }
              </View>
            ))
          }
        </Swiper>
    )

    return (
      <View
        style={[this.props.postsOutStyle]}
        // eslint-disable-next-line flowtype/no-weak-types
        ref={(ref: any) => { this.viewRef = ref; }}
        renderToHardwareTextureAndroid={true}
      >
        {this.props.postBgView && this.props.postBgView()}

        <MemoizedPublishPostBar publishPosting={publishPosting} publishPostFail={publishPostFail} publishPostText={publishPostText} type={type} onPressRePublish={this.onPressRePublish} />
        {countVisible && <RefreshPostCount refreshPostCount={refreshPostCount} type={type} />}

        <SpringScrollView
          onRefresh={this._onRefresh}
          refreshHeader={LottieHeader}
          style={this.props.scrollViewWraperStyle}
          scrollEnabled={scrollEnabled}
          onTouchEnd={this.onTouchEnd}
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: { y: animatedScrollYValue }
                }
              }
            ],
            {
              useNativeDriver: false,
              // eslint-disable-next-line flowtype/no-weak-types
              listener: (event: any) => {
                this.handleScroll(event)
                this.props.setAnimatedScrollYValue && this.props.setAnimatedScrollYValue(animatedScrollYValue)
              }
            }
          )}

          // eslint-disable-next-line flowtype/no-weak-types
          ref={(instance: any): void => (this.instance = instance)}
          // 触底事件 这个很重要
          onMomentumScrollEnd={this._contentViewScroll}
          automaticallyAdjustContentInsets={false}
          // 是否新显示滚动条
          showsVerticalScrollIndicator={false}>
          {!isRefresh && serverBusy
            ? (
            <View
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: p(100)
              }}>
              <FastImage
                source={require('../../images/server_busy.webp')}
                style={{ width: p(512), height: p(438) }}
              />
              <Text
                style={{
                  color: '#696F7F',
                  fontSize: p(28),
                  fontWeight: 'bold',
                  marginTop: p(10)
                }}>
                {I18n.t('load_failed')}
              </Text>
              <TouchableOpacity activeOpacity={0.8}
                onPress={this._onRefresh}
                style={{
                  marginTop: p(30),
                  backgroundColor: '#3D15AA',
                  borderRadius: p(29),
                  height: p(58),
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingHorizontal: p(30)
                }}>
                <Text style={{ color: '#fff', fontSize: p(28) }}>
                  {I18n.t('click_retry')}
                </Text>
              </TouchableOpacity>
            </View>
              )
            : <View/>}
          {!isRefresh && noInternet
            ? (
            <View
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: p(100)
              }}>
              <FastImage
                source={require('../../images/nonet.webp')}
                style={{ width: p(512), height: p(438) }}
              />
              <Text
                style={{
                  color: '#696F7F',
                  fontSize: p(28),
                  fontWeight: 'bold',
                  marginTop: p(10)
                }}>
                {I18n.t('no_internet')}
              </Text>
              <TouchableOpacity activeOpacity={0.8}
                onPress={this._onRefresh}
                style={{
                  marginTop: p(30),
                  backgroundColor: '#3D15AA',
                  borderRadius: p(29),
                  height: p(58),
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingHorizontal: p(30)
                }}>
                <Text style={{ color: '#fff', fontSize: p(28) }}>
                  {I18n.t('click_retry')}
                </Text>
              </TouchableOpacity>
            </View>
              )
            : <View/>}

          {this.props.showTap && topicPollData.length > 0
            ? (
                <View style={[{ height: p(225), marginBottom: margin.m32 }]}>
                  {SwiperView}
                </View>
              )
            : <View/>
          }
          <View/>

          {/* 以下部分渲染列表数据-我的关注加载中效果 */}
          {this.state.loading_myFollow && type === 'myFollow'
            ? (
            <FlatList
              data={[1, 2, 3, 4]}
              ItemSeparatorComponent={this.SocialListItemLoadingSeparator}
              renderItem={this.renderItem}
              keyExtractor={this.keyExtractor}
            />
              )
            : null}
          {/* 我的推荐加载中效果 */}
          {(this.state.loading_recommend && type === 'recommend')
            ? (
              <FlatList
                data={[1, 2, 3]}
                ItemSeparatorComponent={squareType === 'square_news' ? this.NewsListItemLoadingSeparator : this.SocialListItemLoadingSeparator}
                scrollEnabled={scrollEnabled}
                renderItem={squareType === 'square_news' ? this.renderNewsItem : this.renderItem}
                keyExtractor={this.keyExtractor}
              />
              )
            : null}
            {/* 最新 加载中效果 */}
            {this.state.loading_recents && type === 'recent'
              ? (
                <FlatList
                  data={[1, 2, 3, 4]}
                  ItemSeparatorComponent={this.SocialListItemLoadingSeparator}
                  renderItem={this.renderItem}
                  keyExtractor={this.keyExtractor}
                />
                )
              : null}

          {this.state.loading_myFeed && type === 'myFeed' && !this.props.showUserList
            ? (
              <FlatList
                data={[1, 2, 3, 4]}
                ItemSeparatorComponent={this.SocialListItemLoadingSeparator}
                renderItem={this.renderItem}
                keyExtractor={this.keyExtractor}
              />
              )
            : null}

          {this.state.loading_myFav && type === 'myFav'
            ? (
              <FlatList
                data={[1, 2, 3, 4]}
                ItemSeparatorComponent={this.SocialListItemLoadingSeparator}
                renderItem={this.renderItem}
                keyExtractor={this.keyExtractor}
              />
              )
            : null}

          {!noInternet && !serverBusy && (!this.state.loading_myFollow && !this.state.loading_recents && !this.state.loading_myFeed && !this.state.loading_myFav && !this.state.loading_recommend)
            ? (
            <View
              style={[
                this.props.showUserList
                  ? {
                      position: 'relative',
                      bottom: p(58),
                      marginTop: isNews ? p(85) : p(58)
                    }
                  : { marginTop: p(100) },
                this.props.homeNewsStyle,
                this.props.feedTopStyle || {}
              ]}>

              {this.renderNewsSwiper(isNews)}
              <View
                style={[{
                  backgroundColor: '#fff',
                  borderRadius: p(24),
                  paddingBottom: p(20)
                }, that.props.myFeedTopStyle]}>
                {this.renderHeader(isNews)}
                <FlatList
                  data={currentFeeds}
                  scrollEnabled={scrollEnabled}
                  ItemSeparatorComponent={this.SocialListItemSeparator}
                  renderItem={({ item, index }: PostItemType): Element<*> => (
                    <PostComponent
                      key={index}
                      keyIndex={index}
                      {...this.props}
                      activeRouteName={this.state.activeRouteName}
                      vipActivityOpen={vipActivityOpen}
                      type={type}
                      activeVideoId={this.state.activeVideoId}
                      setCurrentVideo={this.setCurrentVideo.bind(this)}
                      itemData={helper.formatPostData(item)}
                      onTextClick={this.onTextClick}
                      showCommentAndReplyModal={this.showCommentAndReplyModal}
                      toggleModalPostMore={this.toggleModalPostMore}
                      onFavoirite={this.onFavoirite}
                      onImageClick={this.onImageClick}
                      onComment={this.onComment}
                      onFav={this.onFav}
                      onShare={this.onShare}
                      followFun={this.follow.bind(this)}
                      likeFun={this.like.bind(this)}
                      onLove={this.onLove}
                    />
                  )}
                  keyExtractor={this.keyExtractor}
                  ListEmptyComponent={
                    !this.props.noEmptyComponent
                      ? <View style={{ marginTop: p(100) }}>
                      <EmptyComponent
                        desc={I18n.t(type === 'myFav' ? 'noFav' : 'noFeed')}
                        source={type === 'myFav' ? require('../../images/noFav.webp') : require('../../images/noFeed.webp')}
                        emptyImageStyle={{ width: p(420), height: p(420) }}
                        />
                    </View>
                      : null
                  }
                />
              </View>
            </View>
              )
            : <View/>}

          {this.state.noMore && !noInternet && !serverBusy
            ? (
            <View style={{ alignItems: 'center', paddingBottom: p(60) }}>
              <FastImage
                style={{ height: p(192), width: p(560) }}
                source={require('../../images/no_more.png')}
              />
              <Text
                style={{
                  color: '#8E939F',
                  fontSize: p(24),
                  position: 'relative',
                  top: p(-30)
                }}>
                {I18n.t('community_no_more')}
              </Text>
            </View>
              )
            : <View/>
          }
          {this.state.loadingMore && !noInternet && !serverBusy
            ? (
              <LottieLoading style={{
                alignItems: 'center',
                height: p(400),
                paddingVertical: p(80),
                marginTop: -p(100)
              }}/>
              )
            : !this.props.noEmptyComponent
                ? (
                    (<View style={{ height: this.props.newsTitle ? p(48) : p(100) }} />)
                  )
                : null
          }
        </SpringScrollView>
        {this.renderImageViwer()}
        {this.renderCommentAndReplyModal()}
        {this.renderPostMoreOptionModal()}
        {this.renderDeleteModal()}
        <EasyToast
          ref="toast"
          style={{ backgroundColor: 'black' }}
          position="center"
          positionValue={200}
          fadeInDuration={750}
          fadeOutDuration={1000}
          opacity={0.8}
          textStyle={{ color: 'white' }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  followBar: {
    flexDirection: 'row'
  },
  followItem: {
    marginLeft: p(36),
    flexDirection: 'row'
  },
  userItem: {
    width: p(120),
    height: p(120),
    borderRadius: p(120),
    marginBottom: p(11),
    alignItems: 'center',
    justifyContent: 'center'
  },
  userItemShadow: {
    backgroundColor: '#fff',
    shadowColor: 'rgba(0,0,0,0.1)',
    shadowOpacity: 1,
    shadowOffset: {
      width: 0,
      height: p(4)
    },
    elevation: 1.5,
    zIndex: 1
  },
  userActive: {
    width: p(130),
    height: p(130),
    borderWidth: p(10),
    borderColor: '#6F40EE',
    zIndex: 999999
  },
  userText: {
    color: '#696F7F',
    fontSize: p(20),
    fontWeight: 'bold'
  },
  contentLoadingStyle: {
    marginHorizontal: p(0),
    paddingHorizontal: p(30),
    backgroundColor: '#fff'
  },
  peopleIcon: {
    height: p(120),
    width: p(120)
  },
  newsPoster: {
    width: p(690),
    height: p(280),
    borderRadius: p(36),
    justifyContent: 'center'
  },
  blurWrapper: {
    position: 'absolute',
    bottom: 0,
    width: p(690),
    height: p(96),
    borderBottomLeftRadius: p(36),
    borderBottomRightRadius: p(36),
    overflow: 'hidden'
  },
  blurImage: {
    width: p(690),
    height: p(96),
    borderBottomLeftRadius: p(36),
    borderBottomRightRadius: p(36),
    position: 'absolute',
    bottom: 0,
    justifyContent: 'flex-end'
  }
});

type PageOwnPropType = {};
const connector = connect(customMapStateProp<PageOwnPropType>(
  {
    SocialReducer: 1,
    HomeReducer: 1,
    AccountReducer: 1
  }
), defaultMapDispatchProp<PageOwnPropType>());
export default (connector(Posts): Class<Posts>);
