// @flow
import config from '../utils/config';
import request from '../utils/request';
import stream, { connect } from 'getstream';
import AliyunOSS from 'aliyun-oss-react-native';
import { Platform } from 'react-native';
import type { CustomerMisc, SocialResult, CommonResponse } from './base';
import { readonlyToken } from './base';
import { subscribe } from 'redux-subscriber';
import type { StateType } from '../actions/common';
import bugsnag from '../utils/bugsnag';

import helper from '../utils/helper';
import type { HotKOL, HotTopic } from '../models/social';

let socialToken: ?string = null;
let socialUserId: ?string = '';
let jwtKey: ?string;

subscribe('SocialReducer', (state: StateType) => {
  const { SocialReducer } = state;
  socialToken = SocialReducer.socialToken;
  socialUserId = SocialReducer.userId;
});
subscribe('HomeReducer', (state: StateType) => {
  const { HomeReducer } = state;
  jwtKey = HomeReducer.userInfo?.jwtKey ?? '';
});
/** TraderAction
 * 除非非常必要，请勿在程序代码中调用该方法，该方法作为登录验证后系统调用流程，不作为程序逻辑供开发调用
 * 程序中获取，请直接使用SocialReducer.socialToken.token, 系统会定时进行刷新或触发登录刷新
 * @param opt
 * @returns {Promise<R>}
 */
export interface TokenResult {
  token: string,
  login: boolean,
  time: number,
  userId: string
}

const getToken = async (jwtKey?: ?string = readonlyToken): Promise<TokenResult> => {
  return new Promise((resolve: (TokenResult)=> void, reject: (Error)=> void) => {
    // force refresh
    console.log('force refresh social token...');
    // TODO USERID 会在上线后屏蔽掉，该方法授权key仅在登录后有效。
    const url: string = config.api.host + config.api.social.getToken;
    request.get
      .call(this, url, jwtKey ? { Authorization: jwtKey } : {})
      .then((res: SocialResult<TokenResult>) => {
        if (res && res.result && res.result.ok === undefined) {
          const result: TokenResult = res.result;
          const token = result.token;
          const login = result.login;
          resolve({
            token,
            login,
            time: res.result.time,
            userId: res.result.userId
          });
        } else {
          reject(new Error('error network'));
        }
      })
      .catch((error: Error) => {
        bugsnag.notify(new Error('[SocialAPI]--[getToken]--error  : ' + error.toString()));
        if (error.message.indexOf('Network request failed') !== -1) {
          reject(error);
        }
        console.log(error);
      });
  });
};

const getClient = (token: ?string = null): stream.Client => {
  try {
    return connect(
      'key',
      !token
        ? socialToken ?? jwtKey ?? readonlyToken
        : token,
      'app_id',
      defaultOptions
    );
  } catch (e) {
    bugsnag.notify(new Error('[SocialAPI]--[getClient]--error  : ' + e.toString()));
    return connect(
      'key',
      !token
        ? socialToken ?? readonlyToken
        : token,
      'app_id',
      defaultOptions
    );
  }
};

export interface StsToken {
  securityToken?: string,
  bucket: string,
  endpoint?: string,
  accessKey?: string,
  expireInSeconds?: number,
  accessSecret?: string
}

const initOss = (token: string = socialToken ?? jwtKey ?? readonlyToken): Promise<StsToken> => {
  const configuration = {
    maxRetryCount: 3,
    timeoutIntervalForRequest: 5,
    timeoutIntervalForResource: 24 * 60 * 60
  };
  const URL = config.api.host + config.api.social.ossKey;
  console.log(URL)
  return new Promise<StsToken>((resolve: (StsToken)=> void, reject: (Error)=> void) => {
    request.get
      .call(this, URL, { Authorization: token })
      .then((response: SocialResult<StsToken>) => {
        if (response && response.result && response.result.ok === undefined) {
          console.log(JSON.stringify(response));

          const OSS_ACCESSKEYID = response.result.accessKey ?? '';
          const OSS_ACCESSKEYSECRET = response.result.accessSecret ?? '';
          const SecurityToken = response.result.securityToken ?? '';
          const endPoint = response.result.endpoint ?? '';
          // const bucket = response.result.bucket ?? '';
          // const region = endPoint ? endPoint.split('.')[0] : '';
          if (!(OSS_ACCESSKEYID || OSS_ACCESSKEYSECRET || SecurityToken || endPoint)) {
            reject(new Error('fetch osskey error'));
          } else {
            try {
              if (Platform.OS === 'web') {
                AliyunOSS.initWithSecurityToken(
                  SecurityToken,
                  OSS_ACCESSKEYID,
                  OSS_ACCESSKEYSECRET,
                  endPoint
                );
              } else {
                AliyunOSS.initWithSecurityToken(
                  SecurityToken,
                  OSS_ACCESSKEYID,
                  OSS_ACCESSKEYSECRET,
                  endPoint,
                  configuration
                );
              }
              resolve(response.result);
            } catch (error) {
              bugsnag.notify(new Error('[SocialAPI]--[initWithSecurityToken]--error  : ' + error.toString()));
              reject(new Error('fetch osskey error'));
            }
          }
        } else {
          reject(new Error('fetch osskey error'));
        }
      })
      .catch((error: Error) => {
        bugsnag.notify(new Error('[SocialAPI]--[getOssKey]--error  : ' + error.toString()));
        console.log('init oss fail', JSON.stringify(error));
        reject(error);
      });
  });
};

const getUserInfo = (userId: ?string, token: ?string): Promise<?CustomerMisc> => {
  const client = getClient(token);
  const user = userId || socialUserId || '';

  return new Promise<?CustomerMisc>((resolve: (?CustomerMisc)=> void, reject: (Error)=> void) => {
    client
      .user(user)
      .get({})
      .then((body: { full: ?{ result: ?CustomerMisc } }) => {
        const result = body.full && body.full.result;
        // console.log('getUserInfo=>' + body);
        resolve(result);
      })
      .catch((err: Error) => {
        bugsnag.notify(new Error('[SocialAPI]--[getClientUser]--error  : ' + err.toString()));
        reject(err);
      });
  });
};

const updateReadCounts = (type: { unreadComment: ?number} | {unreadLike: ?number} | {unreadPost: ?number} | {unreadReply: ?number}): Promise<CustomerMisc> => {
  const client = getClient();

  return new Promise((resolve: (CustomerMisc)=> void, reject: (Error)=> void) => {
    client
      .user(socialUserId ?? '')
      .update(type)
      .then((body: { full: { result: ?CustomerMisc } }) => {
        // update store

        const result = body.full && body.full.result;
        if (result) {
          resolve(result);
        } else {
          reject(new Error('api error'))
        }
      })
      .catch((err: Error) => {
        bugsnag.notify(new Error('[SocialAPI]--[updateClientUser]--error  : ' + err.toString()));
        reject(err);
      });
  });
};
const defaultOptions = {
  urlOverride: {
    api: config.api.host
  },
  timeout: 10000,
  browser: true,
  version: 'api/social'
};

export interface HotUsers {
  result: {
    res: Array<HotKOL>
  }
}

export interface HotTopics {
  result: {
    res: Array<HotTopic>
  }
}

export type HotUsersResponseType = HotUsers & CommonResponse;
export type HotTopicsResponseType = HotTopics & CommonResponse;

class SocialApi {
  getHotUsers (params: {limit: number}): Promise<HotUsersResponseType> {
    const URL = config.api.host + config.api.social.hotUsers +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL, { Authorization: jwtKey ?? '' });
  };

  getHotTopic (params: {limit: number}): Promise<HotTopicsResponseType> {
    const URL = config.api.host + config.api.social.hotTopic +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL, { Authorization: jwtKey ?? '' });
  };
}
const SocialAPI: SocialApi = new SocialApi();

export {
  getToken,
  getUserInfo,
  defaultOptions,
  getClient,
  initOss,
  updateReadCounts,
  SocialAPI
};
