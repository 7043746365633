// @flow
import React, { Component } from 'react';
import type { Element } from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  SafeAreaView,

  View,
  Dimensions

} from 'react-native';
import Modal from 'react-native-modal';
import p from '../utils/Transfrom';
import I18n from '../utils/i18n';
import type { CombinedReducerType, DispatchProps } from '../reducers';

declare function toggleModal(toggle: boolean): void;
declare function onChoose(type: number): void;

type EditTopicOrVoteModalOwnPropType = {
    visible: boolean,
    toggleModal: typeof toggleModal,
    onChoose: typeof onChoose
};
type EditTopicOrVoteModalStateType = {

};

type EditTopicOrVoteModalProsType = EditTopicOrVoteModalOwnPropType & CombinedReducerType & DispatchProps;

export default class EditTopicOrVoteModal extends Component <EditTopicOrVoteModalProsType, EditTopicOrVoteModalStateType> {
  constructor (props: EditTopicOrVoteModalProsType) {
    super(props);
    this.state = {};
  }

  hideModal: () => void = () => {
    this.props.toggleModal(false);
  };

  render (): Element<*> {
    const { visible } = this.props;
    return (
      <Modal
        onBackdropPress={() => {
          this.hideModal();
        }}
        deviceHeight={Math.max(
          Dimensions.get('window').height,
          Dimensions.get('screen').height
        )}
        isVisible={visible}
        style={{
          margin: 0,
          flexDirection: 'column',
          justifyContent: 'flex-end'
        }}>
        <SafeAreaView style={{ margin: p(30) }}>
          <TouchableOpacity activeOpacity={0.8}
            style={[styles.btn, { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }]}
            onPress={() => {
              this.props.onChoose(1);
            }}>
            <Text style={{ color: '#1F2126', fontSize: p(30) }}>{I18n.t('market_fiat_delete')}</Text>
          </TouchableOpacity>
          <View style={{ height: 1, backgroundColor: '#efefef' }}/>
          <TouchableOpacity activeOpacity={0.8}
            style={[styles.btn, { borderTopLeftRadius: 0, borderTopRightRadius: 0 }]}
            onPress={() => {
              this.props.onChoose(2);
            }}>
            <Text style={{ color: '#1F2126', fontSize: p(30) }}>
              {I18n.t('community_share_increase')}
            </Text>
          </TouchableOpacity>

          <TouchableOpacity activeOpacity={0.8}
            style={[styles.btn, { marginTop: p(18) }]}
            onPress={() => {
              this.hideModal();
            }}>
            <Text
              style={{
                color: '#6F40EE',
                fontSize: p(30),
                fontWeight: 'bold'
              }}>
              {I18n.t('common_cancel')}
            </Text>
          </TouchableOpacity>
        </SafeAreaView>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  btn: {
    borderRadius: p(24),
    width: '100%',
    height: p(112),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff'
  }
});
