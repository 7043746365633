// @flow
import React from 'react';
import type { Element } from 'react';
import {
  View,
  StyleSheet,
  Text
} from 'react-native';
import FastImage from 'react-native-fast-image';
import { TouchableOpacity } from 'react-native-gesture-handler';

import I18n from '../utils/i18n';
import p from '../utils/Transfrom';
import type {
  Item
} from '../api/exploreData';
import type { SymbolList } from '../models/market';

import helper from '../utils/helper'

import { alignItems, width, height, imageRounded40, paddingHorizontal, radius, lineHeight, colors, margin, padding, fontSize, fontWeight, justifyContent } from '../styles/Common'
import AreaChart from './AreaChart'

function LongShortData ({ item, symbol, data, navigation }: {
  item: Item,
  symbol: SymbolList,
  data: Array<string>,
  navigation: {
    navigate: (name: string, opt?: {})=> void
  }
}): Element<*> {
  console.log(data)
  return (
    <TouchableOpacity onPress={() => {
      try {
        helper.trackEvent('NewHome Click ExploreDataCoin', { item: JSON.stringify(item) });
      } catch (e) {
      }
      item.interval = '5m';
      navigation.navigate('ExploreDataCoin', { item: item, TRACK_PARAM: helper.toTrackParam(item.type) });
    }} activeOpacity={0.8}>
        <View style={styles.dataRowStyle}>
          <View>
            <View style={styles.dataRowHeaderStyle}>
              <FastImage
                style={imageRounded40}
                source={{ uri: symbol ? symbol.picturePath : '' }}
              />
              <Text style={styles.dataRowHeaderTextStyle}>{item.symbol}{I18n.locale === 'en' ? ' ' : ''}{I18n.t('long_short_ratio')}</Text>
            </View>
            <Text style={styles.dataRowRadioStyle}>{helper.fixNumString(data[0], 2)}</Text>

          </View>

          {data.length > 0 ? <AreaChart data={data} areaStyle={{ height: p(145) }}/> : <View/>}

        </View>
    </TouchableOpacity>

  )
}

const styles = StyleSheet.create({
  dataRowStyle: {
    width: width.w380,
    height: height.h340,
    paddingTop: padding.p30,
    backgroundColor: colors.white3,
    borderRadius: radius.r36,
    justifyContent: justifyContent.spaceBetween,
    overflow: 'hidden'
  },
  dataRowHeaderStyle: {
    flexDirection: 'row',
    paddingHorizontal: paddingHorizontal.ph24,
    alignItems: alignItems.center
  },
  dataRowHeaderTextStyle: {
    color: colors.black1,
    fontSize: fontSize.f24,
    lineHeight: lineHeight.l31,
    fontWeight: fontWeight.f500,
    marginLeft: margin.m8
  },
  dataRowRadioStyle: {
    paddingHorizontal: paddingHorizontal.ph24,
    color: colors.black,
    fontSize: fontSize.f46,
    lineHeight: lineHeight.l59,
    fontWeight: fontWeight.f700,
    marginTop: margin.m24
  }

})

// eslint-disable-next-line flowtype/no-weak-types
const MemoizedLongShortData: any = React.memo(LongShortData);
export default MemoizedLongShortData
