// @flow
import React from 'react';
import type { Element } from 'react';
import {
  View,
  Text
} from 'react-native';
import type { HotTopic } from '../models/social'
import HomeStyle from '../layouts/home/styles/home'
import { TouchableOpacity } from 'react-native-gesture-handler';
import helper from '../utils/helper'

function TagView ({ item, navigation }: {
  item: HotTopic,
  // eslint-disable-next-line flowtype/no-weak-types
  navigation: {navigate: (route: string, params: any)=>{}}
}): Element<*> {
  return (
    <TouchableOpacity activeOpacity={0.8} onPress={() => {
      try {
        helper.trackEvent('NewHome Click Tag ' + item.content, { item: JSON.stringify(item) });
      } catch (e) {
      }

      navigation.navigate('HotTopicFeed', { item: Object.assign(item, { topicId: item.id }) })
    }}>
      <View style={HomeStyle.tagButton}>
        <Text style={HomeStyle.tagButtonText}>#{item.content}</Text>
      </View>
    </TouchableOpacity>

  )
}

// eslint-disable-next-line flowtype/no-weak-types
const MemoizedTagView: any = React.memo(TagView);
export default MemoizedTagView
