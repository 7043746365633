// @flow
import config from '../utils/config';
import request from '../utils/request';
import helper from '../utils/helper';
import type { CommonResponse, ExchangeResult } from './base';
// model start
export interface SpotOrderRequest{
  order_id: string,
  base_currency: string,
  quote_currency: string,
  order_type: string,
  base_qty: string,
  quote_qty: string,
  side: string,
  price: number
}

export interface TradeAcquireOrderId {
  id: string
}

export interface ChangeMargin {
  currencies: string[]
}

export type TradeAcquireOrderIdResponseType = CommonResponse & ExchangeResult<TradeAcquireOrderId>;
export type ChangeMarginResponseType = CommonResponse & ExchangeResult<ChangeMargin>;

export interface SpotOrder{
  customerId: string,
  orderNum: string,
  orderType: string,
  quantity: number,
  quoteQuantity: number,
  side: string,
  status: string,
  symbol: string,
  type: string,
  currency: string,
  quoteCurrency: string,
  source: string,
  accountId: string,
  quoteAccountId: string,
  fee: number,
  marketPrice: number,
  created: string,
  modified: string,
  orderTime: number
}

export interface SpotOrderEntrust{
  customerId: string,
  entrustNum: string,
  orderType: string,
  quantity: number,
  quoteQuantity: number,
  side: string,
  price: number,
  status: string,
  symbol: string,
  type: string,
  opStatus: number,
  limitPrice: string,
  triggerPrice: number,
  currency: string,
  quoteCurrency: string,
  source: string,
  accountId: string,
  quoteAccountId: string,
  created: string,
  modified: string,
  triggerTime: string,
  fee: number,
  marketPrice: number,
  remark: string,
  errorCount: number,
  oldStatus: string,
  oldOpStatus: number
}

export interface ZoneListItem {
  categoryName: string,
  categoryKey: string,
  coinAndKeys: {[key: string]: string},
  picPaths: string[],
  leadingCurrency: string,
  maxRiseAndFall: number,
  zoneRiseAndFall: number
}

export interface ZoneDetailItem {
  tickerList: [{[key: string]: string}],
  categoryKey: string,
  categoryName: string
}

export interface CurrencyDetailItem {
  [key: string]: string
}
export interface OrderBookItem {
  ts: number,
  symbol: string,
  asks: [{
    p: string,
    v: string,
    sum: number,
    barPercent: number
  }],
  bids: [{
    p: string,
    v: string,
    sum: number,
    barPercent: number
  }]
}

export interface MarginBalance {
  additional: number,
  baseAvailable: number,
  baseFrozen: number,
  customerId: string,
  openMargin: boolean,
  quoteAvailable: number,
  quoteMarketAvailable: number,
  quoteFrozen: number,
  symbol: string,
  unwindBuyQty: number,
  unwindSellQty: number
}

export type SpotOrderResponseType = ExchangeResult<SpotOrder | SpotOrderEntrust> & CommonResponse;
export type SpotOrderListResponseType = ExchangeResult<SpotOrder[]> & CommonResponse;
export type SpotOrderEntrustListResponseType = ExchangeResult<SpotOrderEntrust[]> & CommonResponse;
export type ZoneListResponseType = ExchangeResult<ZoneListItem[]> & CommonResponse;
export type ZoneDetailResponseType = ExchangeResult<ZoneDetailItem> & CommonResponse;
export type CurrencyDetailResponseType = ExchangeResult<CurrencyDetailItem> & CommonResponse;
export type OrderBooksResponseType = CommonResponse & ExchangeResult<OrderBookItem>;
export type MarginBalanceResponseType = ExchangeResult<MarginBalance> & CommonResponse;

// class definition start
class SpotTradeApi {
  /**
   * 现货下单API
   * @param params
   * @returns {Promise<unknown>}
   */
  placeOrder (params: SpotOrderRequest): Promise<SpotOrderResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.spot.placeOrder +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  listOrders (params: {symbol: ?string, type: string, nextToken: ?string, limit: ?number}): Promise<SpotOrderListResponseType> {
    const URL =
      config.api.host_newapi +
      config.api.spot.listOrders +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  entrustList (params: {orderType: string, symbol?: string, status?: string[], offset?: number, limit?: number}): Promise<SpotOrderEntrustListResponseType> {
    // params.saas_id = 'kiki';
    const URL =
        config.api.host_newapi +
        config.api.spot.entrustList +
        '?' +
        helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  tradeAcquireOrderId (): Promise<TradeAcquireOrderIdResponseType> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.acquireOrderId
    return request.post.call({}, URL);
  };

  marginBalance (params: { base: string, quote: string, side: string}): Promise<MarginBalanceResponseType> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.marginBalance +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  changeMargin (params: { action: string }): Promise<ChangeMarginResponseType> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.changeMargin +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  marginInterests (params: { from_settle_time: string, from_currency: string, limit: number }): Promise<CommonResponse> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.marginInterests +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  marginSettleOrders (params: { from_order_id: string, limit: number }): Promise<CommonResponse> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.marginSettleOrders +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  tradingInfo (): Promise<CommonResponse> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.tradingInfo;
    return request.get.call({}, URL);
  };

  createTradeOrder (params: { order_id: string, base_currency: string, quote_currency: string, order_type: string, base_qty: string, quote_qty: string, side: string, price: string, business_type: string, type: string }): Promise<CommonResponse> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.order +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  cancelTradeOrder (params: { order_id: string }): Promise<CommonResponse> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.cancelOrder.replace(/{orderId}/, params.order_id) +
      '?' +
      helper.mapToQueryString(params);
    return request.delete.call({}, URL);
  };

  getTradeOrderDetail (params: { order_id: string }): Promise<CommonResponse> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.orderDetailFull.replace(/{orderId}/, params.order_id) +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  getTradeOpenOrders (params: { symbol: string, order_type: string, side?: string, from_order_id: string, limit: number, type: string }): Promise<SpotOrderEntrustListResponseType> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.ordersOpen +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  getTradingTrades (params: { symbol: string, order_type: string, side?: string, from_order_id: string, limit: number, type: string }): Promise<CommonResponse> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.ordersHistory +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  getTradingOrdersHistory (params: { from_trade_id: string, limit: number, type: string }): Promise<CommonResponse> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.tradingTrades +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  getTradingTrade (params: { trade_id: string }): Promise<CommonResponse> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.tradingTrade +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  getOrderbooks (params: { symbol: string }): Promise<OrderBooksResponseType> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.orderbooks.replace(/{symbol}/, params.symbol) +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  getZoneList (): Promise<ZoneListResponseType> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.zoneList
    return request.get.call({}, URL);
  };

  getZoneDetail (params: {key: string, currency: string }): Promise<ZoneDetailResponseType> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.zoneDetail.replace(/{key}/, params.key).replace(/{currency}/, params.currency) +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  getCurrencyDetail (params: {currency: string }): Promise<CurrencyDetailResponseType> {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.currencyDetail.replace(/{currency}/, params.currency) +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  entrustCancel (params: {entrustNum: string}): Promise<CommonResponse> {
    // params.saas_id = 'kiki';
    // params.customerId = USER_ID;
    const URL =
        config.api.host_newapi +
        config.api.spot.entrustCancel +
        '?' +
        helper.mapToQueryString(params);
    return request.post.call(params, URL);
  };
}
const SpotTradeAPI: SpotTradeApi = new SpotTradeApi();
export { SpotTradeAPI };
