/* @flow */

import React, { Component } from 'react';
import type { Element } from 'react';
import {
  StyleSheet,
  Text,
  DeviceEventEmitter,
  StatusBar,
  Platform,
  Dimensions,
  View,
  ScrollView
} from 'react-native';
// import _ from 'lodash'
import { TouchableOpacity, TouchableWithoutFeedback } from 'react-native-gesture-handler';

import FastImage from 'react-native-fast-image';
import Toast from 'react-native-easy-toast';
import { TabView, SceneMap } from 'react-native-tab-view';
import { connect } from 'react-redux';
import bugsnag from '../../utils/bugsnag';

import helper from '../../utils/helper';
import p from '../../utils/Transfrom';
import I18n from '../../utils/i18n';
import ReleaseModal from './ReleaseModal'
import Posts from './Posts';
import Manage from './Manage'
import PublishPost from './PublishPost'
import { FetchMyTraderInfo } from '../../actions/TraderAction';
// import type { CompositeAnimation } from 'react-native/Libraries/Animated/AnimatedMock';
import { defaultMapDispatchProp, customMapStateProp } from '../../reducers';
import type { CombinedReducerType, DispatchProps } from '../../reducers';

const width = Math.round(Dimensions.get('window').width);
const height = Math.round(Dimensions.get('window').height);
const refreshHeight = 2000;

const PostsScrollHeight = {};
const Routes = [
  { key: 'first', title: 'community_recommend' },
  { key: 'second', title: 'recent' }
  // { key: 'fourth', title: 'community_manage' },

  // {key: 'fourth', title: 'Learn', },
]
// const ManageRoutes = Routes.concat([
//   { key: 'fourth', title: 'community_manage' }
// ])

type ItemType = {
  someoneUserId: string,
  currentUser: {}
};

// type RouteItemType = {
//   key: string,
//   title: string
// };

type PageOwnPropType = {};
type StateType = {};
// const mapType1 = ['recommend', 'recent', 'myFollow']
const mapType2 = ['recommend', 'recent']

type PropsType = PageOwnPropType & CombinedReducerType & DispatchProps;
const defaultHeight = { recommend: 0, myFollow: 0, recent: 0 };
class Social extends Component <PropsType, StateType> {
  constructor (props: PropsType) {
    super(props);
    // let _routes;
    // this.isLogin = isSocialLogin(this.props.SocialReducer);
    // // this.isLogin = this.props.HomeReducer?.isLogin
    // if (this.props.SocialReducer?.socialUserInfo?.type === 20) {
    //   _routes = ManageRoutes;
    // } else {
    //   _routes = Routes;
    // }
    // if (!this.isLogin) {
    //   // eslint-disable-next-line flowtype/require-return-type
    //   _routes = _routes.filter((routeItem: RouteItemType) => !['third'].includes(routeItem.key));
    // }

    this.state = {
      unread: '',
      userFullData: {},
      videoFullScreenVisible: true,
      index: parseInt(this.props.route.params?.index ?? '0'),
      currentFeed: 'recommend',
      currentUser: 'all',
      mapType: mapType2,
      publishPosting: false,
      publishPostFail: false,
      routes: Routes,
      showfollows: false,
      releaseModalVisible: false,
      postType: 'long',
      tagItem: null,
      typeScrollHeight: defaultHeight
    };
  }

  _isMounted: boolean;

  componentWillUnmount () {
    this._isMounted = false

    if (this.publishPostListener) {
      this.publishPostListener.remove()
    }

    if (this.publishPostDoneListener) {
      this.publishPostDoneListener.remove()
    }

    if (this.publishPostDoneListener) {
      this.publishPostDoneListener.remove()
    }
    if (this.tagChangeListener) {
      this.tagChangeListener.remove()
    }
    if (this.postTypeChangeListener) {
      this.postTypeChangeListener.remove();
    }
  }

  componentDidMount: () => void = () => {
    const that = this;
    if (helper.needFillUserInfo(that.props.SocialReducer, that.props.HomeReducer)) {
      that.props.navigation.navigate('RegUserInfo');
    }
    that.blurSubscription = this.props.navigation.addListener(
      'focus',
      () => {
        StatusBar.setBarStyle('dark-content');
        if (helper.needFillUserInfo(that.props.SocialReducer, that.props.HomeReducer)) {
          that.props.navigation.navigate('RegUserInfo');
        }
        if (this.props.route.params?.index) {
          that.setState({
            index: parseInt(this.props.route.params.index)
          })
          that.props.navigation.setParams({ index: 0 });
        }
        // 缓存里有数据也可以
        const { userId } = that.props.SocialReducer;
        if (userId) {
          this.props.dispatch(
            FetchMyTraderInfo(userId)
          );
        }
      }
    );
    that._isMounted = true
    that.publishPostListener = DeviceEventEmitter.addListener('publishPosting', function () {
      if (that._isMounted && !that.state.publishPosting) {
        that.setState({
          publishPosting: true,
          publishPostFail: false
        })
      }
    });

    that.publishPostDoneListener = DeviceEventEmitter.addListener('publishPostFail', function () {
      if (that._isMounted) {
        that.setState({
          publishPostFail: true
        })
      }
    });

    that.publishPostDoneListener = DeviceEventEmitter.addListener('publishPostDone', function () {
      if (that._isMounted) {
        that.setState({
          publishPosting: false,
          tagItem: null
        })
      }
    });

    that.listener1 = DeviceEventEmitter.addListener('selectedTab', function (
      selectedTab: {name: string, index: number}) {
      if (selectedTab.name === 'explore' || selectedTab.name === 'manage') {
        that.setState({
          index: selectedTab.index
        });
      }
    });

    that.postTypeChangeListener = DeviceEventEmitter.addListener('postTypeChange', function (postType: string) {
      if (that._isMounted) {
        that.setState({
          postType: postType
        })
      }
    });
  };

  onScrollChange: (type: string, scrollHeight: number) => void = (type: string, scrollHeight: number) => {
    PostsScrollHeight[type] = PostsScrollHeight[type] || {};
    // eslint-disable-next-line no-undef
    // PostsScrollHeight[type].scrollHeight = scrollHeight;
    // console.log('PostsScrollHeight + ' + JSON.stringify(PostsScrollHeight));

    const { typeScrollHeight, isShowSocialRefresh } = this.state;

    if ((type === 'myFollow' && this.isLogin) || type === 'recent' || type === 'recommend') {
      if (scrollHeight > refreshHeight) {
        if (!isShowSocialRefresh) {
          DeviceEventEmitter.emit('socialStatusChange', { socialStatus: 'refresh', currentRefreshTabType: type });
          /* $FlowFixMe */
          this.setState({ isShowSocialRefresh: true, typeScrollHeight: { ...typeScrollHeight, [type]: scrollHeight } });
        }
      } else {
        // if (isShowSocialRefresh) {
        DeviceEventEmitter.emit('socialStatusChange', { socialStatus: 'done', currentRefreshTabType: '' });
        /* $FlowFixMe */
        this.setState({ isShowSocialRefresh: false, typeScrollHeight: { ...typeScrollHeight, [type]: scrollHeight } });
        // }
      }
    }
  };

  onScrollViewLayout: (type: number, layoutHeight: number, layoutPy: number) => void = (type: number, layoutHeight: number, layoutPy: number) => {
    PostsScrollHeight[type] = PostsScrollHeight[type] || {};
    PostsScrollHeight[type].layoutHeight = layoutHeight;
    PostsScrollHeight[type].layoutPy = layoutPy;
  };

  getTabBarView: (_routes: Array<*>, index: number) => Element<*> = (_routes: Array<*>, index: number): Element<*> => {
    return (
      <View style={styles.typeListStyle}>
        <ScrollView
         style={{ marginHorizontal: p(30) }}
         horizontal
        //  pagingEnabled
         showsHorizontalScrollIndicator={false}
         keyboardShouldPersistTaps={'always'}
        //  contentContainerStyle={styles.typeListScrollViewContainerStyle}
        >
          {_routes.map((item: {title: string}, i: number): Element<*> => {
            return (
              <TouchableOpacity
                activeOpacity={0.8}
                key={item.title}
                style={[
                  styles.typeItemStyle,
                  { backgroundColor: i === index ? '#6F40EE' : '#F5F5F5' }
                ]}
                onPress={() => {
                  if (i !== index) {
                    this._typeIndexChange(i);
                  }
                }}>
                <Text
                  style={{
                    color: i === index ? '#fff' : '#1F2126',
                    fontSize: p(24),
                    fontWeight: 'bold'
                  }}>
                  {I18n.t(item.title)}
                </Text>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
        </View>
    )
  };

  _renderTypeTabBar = (): Element<*> => {
    // 初始化已经过滤了管理端和非管理端数据，在这里进行统一处理routes
    const { index, routes } = this.state;
    // this.isLogin = isSocialLogin(this.props.SocialReducer);
    // // this.isLogin = this.props.HomeReducer?.isLogin
    // let _routes = routes;
    // if (!this.isLogin) {
    //   // eslint-disable-next-line flowtype/require-return-type
    //   _routes = routes.filter((routeItem: RouteItemType) => !['third', 'six'].includes(routeItem.key));
    // } else {
    //   if (this.props.SocialReducer?.socialUserInfo?.type !== 20) {
    //     // 登陆后，不是管理员的情况下，过滤掉管理员tab
    //     // eslint-disable-next-line flowtype/require-return-type
    //     _routes = routes.filter((routeItem: RouteItemType) => !['six'].includes(routeItem.key));
    //   }
    // }

    return this.getTabBarView(routes, index)
  };

  _typeIndexChange = (i: number) => {
    this.setState({ index: i });
    const type = this.state.mapType[i];
    DeviceEventEmitter.emit('refreshFeeds', { type: type });

    const { typeScrollHeight } = this.state;
    if ((type === 'myFollow' && this.isLogin) || type === 'recent' || type === 'recommend') {
      if (typeScrollHeight[type] > refreshHeight) {
        DeviceEventEmitter.emit('socialStatusChange', { socialStatus: 'refresh', currentRefreshTabType: type });
        this.setState({ isShowSocialRefresh: true });
      } else {
        // if (isShowSocialRefresh) {
        DeviceEventEmitter.emit('socialStatusChange', { socialStatus: 'done', currentRefreshTabType: '' });
        this.setState({ isShowSocialRefresh: false });
        // }
      }
    }
  };

  currentFollowUser: (data: ItemType) => void = (data: ItemType) => {
    this.setState({
      someoneUserId: data.someoneUserId,
      currentUser: data.currentUser
    });
  };

  BaseTypeRoute: (showTap?: boolean, contentType?: string) => Element<*> = (showTap?: boolean, contentType?: string): Element<*> => {
    const { someoneUserId } = this.state;
    const firstRoute = (
      <Posts
        type={contentType}
        index={someoneUserId ? 2 : 0}
        showUserList={true}
        limit={6}
        showTap={showTap}
        activeRouteName={someoneUserId ? 'myFeed' : contentType}
        postListWraper={styles.postListWraper}
        postsOutStyle={styles.postsOutStyle}
        postCellContainer={styles.postCellContainer}
        postCellOne={styles.postCellOne}
        postCellTwo={styles.postCellTwo}
        {...this.props}
        onScrollViewLayout={this.onScrollViewLayout}
        onScrollChange={this.onScrollChange}
        currentFollowUser={this.currentFollowUser}
      />
    );

    return (
    // <ScrollView style={{
    //   backgroundColor:'#FAFAFC',
    // }}>
    //   <View style={{
    //     height:p(240),
    //     margin:p(18),
    //     paddingHorizontal:p(30)
    //   }}>
    //     <Text>active</Text>
    //   </View>
    //  <RNTapView firstRoute={firstRoute} secondRoute={secondRoute} />

      // </ScrollView>
      firstRoute
    );
  };

  FirstTypeRoute: () => Element<*> = (): Element<*> => {
    return this.BaseTypeRoute(true, 'recommend');
  };

  SecondTypeRoute: () => Element<*> = (): Element<*> => {
    return <View/>
    // return this.BaseTypeRoute(false, 'recent');
  };

  ThirdTypeRoute: () => Element<*> = (): Element<*> => {
    return <View/>
    // return this.BaseTypeRoute(false, 'myFollow');
  };

  FourthTypeRoute: () => Element<*> = (): Element<*> => {
    return <Manage {...this.props} />;
  };

  _renderTypeScene = SceneMap({
    first: this.FirstTypeRoute,
    second: this.SecondTypeRoute

  })

  goNotice: () => void = (): void => {
    if (!this.isLogin) {
      return helper.navigate(this.props.navigation.navigate, 'Login');
    }
    const { socialUserInfo } = this.props.SocialReducer;
    this.props.navigation.navigate('MyNotice', { userFullData: socialUserInfo });
  };

  render (): Element<*> {
    const {
      index,
      releaseModalVisible,
      publishPosting,
      publishPostFail,
      routes,
      postType
    } = this.state;
    // const { socialUserInfo } = this.props.SocialReducer;
    // const { myMisc } = this.props.TraderReducer;
    // const userFullData = socialUserInfo;
    let unread = 0;
    const _routes = routes;
    // if (this.isLogin) {
    //   unread = myMisc?.unreadComment + myMisc?.unreadLike + myMisc?.unreadReply;
    //   const { noticeReward, noticeOtc, noticeSystem } = this.props.HomeReducer
    //   const noticeRewardUnread = _.filter(noticeReward, function (item: string): boolean { return JSON.parse(item).cid === userFullData?.customerId && !JSON.parse(item).isRead })
    //   const noticeOtcUnread = _.filter(noticeOtc, function (item: string): boolean { return JSON.parse(item).cid === userFullData?.customerId && !JSON.parse(item).isRead })
    //   const noticeSystemUnread = _.filter(noticeSystem, function (item: string): boolean { return !JSON.parse(item).isRead })

    //   unread += noticeRewardUnread.length + noticeOtcUnread.length + noticeSystemUnread.length;
    //   // eslint-disable-next-line flowtype/require-return-type
    //   // 如果是登录状态下，进行判断是否为管理员，按照权限进行过滤
    //   if (socialUserInfo?.type !== 20) {
    //     // eslint-disable-next-line flowtype/require-return-type
    //     _routes = routes.filter((routeItem: RouteItemType) => !['six'].includes(routeItem.key));
    //   }
    // } else {
    //   // eslint-disable-next-line flowtype/require-return-type
    //   _routes = routes.filter((routeItem: RouteItemType) => !['third', 'six'].includes(routeItem.key));
    // }

    if (unread > 99) {
      unread = '99+'
    }

    return (
      <View
        style={[
          styles.container
        ]}>

        <TabView
          navigationState={{ index: index, routes: _routes }}
          renderScene={this._renderTypeScene}
          swipeEnabled={false}
          renderTabBar={this._renderTypeTabBar}
          onIndexChange={this._typeIndexChange}
          // initialLayout={initialLayout}
        />

        {(index === 0 || index === 1) && (
          <View
            style={{
              position: 'absolute',
              bottom: Platform.OS === 'ios' ? p(60 + 16) : p(16),
              right: p(36)
            }}>
            <TouchableWithoutFeedback
              onPress={(): void => {
                if (publishPosting && !publishPostFail) {
                  this.refs.toast.show(I18n.t('hasPostPublishing'));
                  return
                }
                if (!this.isLogin) {
                  return helper.navigate(
                    this.props.navigation.navigate,
                    'Login'
                  );
                }
                this.setState({
                  releaseModalVisible: true
                })
              }}>
              <FastImage
                style={{ width: p(120), height: p(120) }}
                source={require('../../images/release_post.webp')}
              />
            </TouchableWithoutFeedback>
          </View>
        )}

        <Toast
          ref="toast"
          style={{ backgroundColor: 'black' }}
          position="center"
          fadeInDuration={750}
          fadeOutDuration={1000}
          opacity={0.8}
          textStyle={{ color: 'white' }}
        />
        <PublishPost postType={postType}/>

        <ReleaseModal
          modalVisible={releaseModalVisible}
          closeModal={() => {
            this.setState({
              releaseModalVisible: false
            })
          }}
          onPressRelasePost={() => {
            try {
              this.setState({
                releaseModalVisible: false,
                postType: 'normal'
              }, () => {
                this.props.navigation.navigate('ReleaseLongPost', { postType: 'normal' });
              })
            } catch (e) {
              bugsnag.notify(new Error('[SocialIndex]--[onPressRelasePost]--error:' + e.toString()));
            }
          }}
          onPressRelaseLongPost={() => {
            this.setState({
              releaseModalVisible: false,
              postType: 'long'
            }, () => {
              this.props.navigation.navigate('ReleaseLongPost', { postType: 'long' });
            })
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: height
  },

  comment: {
    color: '#696F7F',
    padding: p(40),
    backgroundColor: '#fff'
  },
  love: {
    color: '#696F7F'
  },
  headTitle: {
    fontSize: p(24),
    fontWeight: '500',
    height: p(88),
    lineHeight: p(88),
    color: '#696F7F'
  },
  top_style: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
    height: p(88),
    paddingHorizontal: p(30),
    // position: 'absolute',
    right: 0,
    zIndex: 3
  },
  activeTabText: {
    fontWeight: 'bold',
    fontSize: p(42)
  },
  tabText: {
    fontSize: p(24),
    color: '#434B5F'
  },
  topLeft: {
    flexDirection: 'row'
  },
  typeListStyle: {
    flexDirection: 'row',
    marginBottom: p(36),
    marginTop: p(12)
  },
  typeListScrollViewContainerStyle: {
    flexDirection: 'row',
    paddingHorizontal: p(30),
    paddingBottom: p(20),
    marginBottom: p(20),
    height: p(10),
    borderBottomLeftRadius: p(24),
    borderBottomRightRadius: p(24)
    // height: p(80),
  },
  typeItemStyle: {
    width: p(112),
    height: p(54),
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: p(20),
    backgroundColor: '#F5F5F5',
    borderRadius: p(27)
  },
  tabBar: {
    flexDirection: 'row',
    alignItems: 'center',
    height: p(88),
    paddingLeft: p(30),
    justifyContent: 'center'
  },
  tabItem: {
    // paddingRight: p(20),
    height: p(60),
    flexDirection: 'row',
    alignItems: 'center'
  },
  followBar: {
    flexDirection: 'row',
    alignItems: 'center',
    height: p(88),
    paddingRight: p(36),
    justifyContent: 'center'
  },
  followItem: {
    // paddingLeft: p(30),
    paddingRight: p(36),
    height: p(88),
    flexDirection: 'row'
    // alignItems: 'center',
  },
  userItem: {
    width: p(120),
    height: p(120),
    borderRadius: p(120),
    marginBottom: p(11),
    alignItems: 'center',
    justifyContent: 'center'
  },
  userItemShadow: {
    backgroundColor: '#fff',
    shadowOffset: { width: 0, height: 12 },
    // shadowOpacity: 0.5,
    shadowRadius: 6,
    shadowColor: 'rgba(0,0,0,0.10)',
    elevation: 6,
    zIndex: 1
  },
  userActive: {
    borderWidth: p(6),
    borderColor: '#6F40EE'
  },
  userText: {
    color: '#696F7F',
    fontSize: p(20),
    fontWeight: 'bold'
  },
  postsOutStyle: {
    backgroundColor: '#fff'
  },
  postListWraper: {
    borderRadius: p(24)
  },
  postCellContainer: {
    width: (width - p(60 + 60 + 24)) / 3,
    height: (width - p(60 + 60 + 24)) / 3
  },
  postCellOne: {
    width: width - p(60 + 60 + 12),
    height: width - p(60 + 60 + 12)
  },
  postCellTwo: {
    width: (width - p(60 + 9)) / 2,
    height: (width - p(60 + 9)) / 2
  }
});

const connector = connect(customMapStateProp<PageOwnPropType>(
  {
    SocialReducer: 1,
    HomeReducer: 1,
    AccountReducer: 1,
    TraderReducer: 1
  }
), defaultMapDispatchProp<PageOwnPropType>());
export default (connector(Social): Class<Social>);
