// @flow

import { DeviceEventEmitter } from 'react-native';
import { FetchMyTraderInfo } from '../actions/TraderAction';
import { store } from '../store';
import { subscribe } from 'redux-subscriber';
import type { StateType } from '../actions/common';
// follow 事件
export const FOLLOW_EVENT = 'FOLLOW_EVENT';
export interface FollowEventType {
  follow: boolean,
  customerId: string
}
let userId: string;
subscribe('HomeReducer', (state: StateType) => {
  const { HomeReducer } = state;
  const { userInfo } = HomeReducer
  userId = userInfo?.id ?? '';
});

export function emitFollowEvent (event: FollowEventType) {
  DeviceEventEmitter.emit(FOLLOW_EVENT, event);
  if (userId) {
    store.dispatch(FetchMyTraderInfo(userId));
  }
}
// *******************************************************  //
// like 事件
export const LIKE_EVENT = 'LIKE_EVENT';
export interface LikeEventType {
  customerId: string,
  targetId: string,
  targetType: 'post' | 'comment' | 'reply',
  like: boolean
}
export function emitLikeEvent (event: LikeEventType) {
  DeviceEventEmitter.emit(LIKE_EVENT, event);
  if (userId === event.customerId) {
    store.dispatch(FetchMyTraderInfo(userId));
  }
}
// favour 事件(目前没有地方需要同步，暂时保存)
export const FAVOUR_EVENT = 'FAVOUR_EVENT';
export interface FavourEventType {
  customerId: string,
  postId: string
}
export function emitFavourEvent (event: FavourEventType) {
  DeviceEventEmitter.emit(FAVOUR_EVENT, event);
}

// share 事件, share只能分享帖子
export const SHARE_EVENT = 'SHARE_EVENT';
export interface ShareEventType {
  customerId: string,
  postId: string
}
export function emitShareEvent (event: ShareEventType) {
  DeviceEventEmitter.emit(SHARE_EVENT, event);
}

// 评论 事件, 评论次数增加, 只针对帖子增加次数
export const COMMENT_EVENT = 'COMMENT_EVENT';
export interface CommentEventType {
  customerId: string,
  postId: string
}
export function emitCommentEvent (event: CommentEventType) {
  DeviceEventEmitter.emit(COMMENT_EVENT, event);
}

//
export const REPLY_EVENT = 'REPLY_EVENT';
export interface ReplyEventType {
  customerId: string,
  postId: string
}
export function emitReplyEvent (event: CommentEventType) {
  DeviceEventEmitter.emit(REPLY_EVENT, event);
}

// *******************************************************  //
// 在子组件里新增了评论等，需要刷新detail页面
export const SOCIAL_DETAIL_EVENT = 'SOCIAL_DETAIL_EVENT';
export function emitRefreshDetailEvent (event?: {trigger: boolean} = { trigger: true }) {
  DeviceEventEmitter.emit(SOCIAL_DETAIL_EVENT, event);
}
export const TOPIC_POLL_EVENT = 'TOPIC_POLL_EVENT';
export function emitRefreshPollTopicEvent (event?: { type: string}) {
  DeviceEventEmitter.emit(TOPIC_POLL_EVENT, event);
}
