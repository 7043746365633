import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  ImageBackground,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  Text
} from 'react-native';
import shaka from 'shaka-player';

const BackgroundImage = ImageBackground || Image; // fall back to Image if RN < 0.46

const styles = StyleSheet.create({
  preloadingPlaceholder: {
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center'
  },
  thumbnail: {
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center'
  },
  playButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    width: 64,
    height: 64,
    borderRadius: 32,
    justifyContent: 'center',
    alignItems: 'center'
  },
  playArrow: {
    color: 'white'
  },
  video: {
    backgroundColor: 'black'
  },
  timeSyle: {
    fontSize: 10,
    marginRight: 10,
    color: '#fff'
  },
  controls: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    height: 29,
    marginTop: -29,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10
  },
  playControl: {
    color: 'white',
    padding: 8
  },
  extraControl: {
    color: 'white',
    padding: 8
  },
  seekBar: {
    alignItems: 'center',
    height: 30,
    flexGrow: 1,
    flexDirection: 'row',
    paddingHorizontal: 10,
    marginLeft: -10,
    marginRight: -5
  },
  seekBarFullWidth: {
    marginLeft: 0,
    marginRight: 0,
    paddingHorizontal: 0,
    marginTop: -3,
    height: 3
  },
  seekBarProgress: {
    height: 3,
    backgroundColor: '#fff'
  },
  seekBarKnob: {
    width: 20,
    height: 20,
    marginHorizontal: -8,
    marginVertical: -10,
    borderRadius: 10,
    backgroundColor: '#fff',
    transform: [{ scale: 0.8 }],
    zIndex: 1
  },
  seekBarBackground: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    height: 2
  },
  overlayButton: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default class VideoPlayer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isStarted: props.isStarted,
      initLoad: false,
      isPlaying: props.isPlaying,
      width: 360,
      progress: 0,
      isMuted: props.defaultMuted,
      isControlsVisible: !props.hideControlsOnStart,
      duration: 0,
      currentTime: 0,
      isSeeking: false
    };

    this.seekBarWidth = 200;
    this.wasPlayingBeforeSeek = props.autoplay;
    this.seekTouchStart = 0;
    this.seekProgressStart = 0;

    this.onLayout = this.onLayout.bind(this);
    this.onStartPress = this.onStartPress.bind(this);
    this.onProgress = this.onProgress.bind(this);
    this.onEnd = this.onEnd.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.onFullscreenPlayerDidDismiss = this.onFullscreenPlayerDidDismiss.bind(
      this
    );
    this.onFullscreenPlayerDidPresent = this.onFullscreenPlayerDidPresent.bind(
      this
    );
    this.onFullscreenPlayerWillDismiss = this.onFullscreenPlayerWillDismiss.bind(
      this
    );
    this.onPlayPress = this.onPlayPress.bind(this);
    this.onMutePress = this.onMutePress.bind(this);
    this.showControls = this.showControls.bind(this);
    this.onToggleFullScreen = this.onToggleFullScreen.bind(this);
    this.onSeekBarLayout = this.onSeekBarLayout.bind(this);
    this.onSeekGrant = this.onSeekGrant.bind(this);
    this.onSeekRelease = this.onSeekRelease.bind(this);
    this.onSeek = this.onSeek.bind(this);
    // Creating reference to store video component on DOM
    this.videoComponent = React.createRef();
  }

  componentDidMount () {
    shaka.polyfill.installAll();

    if (this.props.autoplay) {
      this.hideControls();
    }
  }

  initPlayer () {
    // Create a Player instance.
    const v = this.videoComponent.current;
    this.player = new shaka.Player(v);
    console.log('whz---initPlayer', this.player)
    const { video } = this.props;
    console.log('whz---Video', video)
    const that = this;
    // debugger;
    this.player
      .load(video.uri)
      .then(data => {
        // This runs if the asynchronous load is successful.
        const range = that.player.seekRange();
        that.onLoad({ duration: range.end });
        v.addEventListener('timeupdate', event => {
          that.onProgress({ currentTime: v.currentTime });
        });
        v.addEventListener('ended', event => {
          that.onEnd(event);
        });
        that.videoComponent.current.play();
      })
      .catch(error => {
        console.log(error);
        console.log('play error');
      });
  }

  componentWillUnmount () {
    if (this.controlsTimeout) {
      clearTimeout(this.controlsTimeout);
      this.controlsTimeout = null;
    }
    if (this.player) {
      this.player.destory();
    }
  }

  onLayout (event) {
    const { width } = event.nativeEvent.layout;
    this.setState({
      width
    });
  }

  onStartPress () {
    if (this.state.initLoad) {
      // 应初始化， 按照播放和取消的逻辑处理
      this.onPlayPress();
    } else {
      this.setState({ initLoad: true }, () => {
        // Check to see if the browser supports the basic APIs Shaka needs.
        if (shaka.Player.isBrowserSupported()) {
          // Everything looks good!
          this.initPlayer();
        } else {
          // This browser does not have the minimum set of APIs we need.
          console.error('Browser not supported!');
        }
        this.onPlayPress();
      });
    }
    if (this.props.onStart) {
      this.props.onStart();
    }
    this.props.onTogglePlaying && this.props.onTogglePlaying(true);
    this.props.onToggleIsStarted && this.props.onToggleIsStarted(true);
    this.setState(state => ({
      progress: state.progress === 1 ? 0 : state.progress
    }));
    // this.hideControls();
  }

  onProgress (event) {
    if (this.state.isSeeking) {
      return;
    }
    if (this.props.onProgress) {
      this.props.onProgress(event);
    }
    this.setState({
      progress:
        event.currentTime / (this.props.duration || this.state.duration),
      currentTime: parseInt(event.currentTime)
    });
  }

  onEnd (event) {
    const that = this;
    if (this.props.onEnd) {
      this.props.onEnd(event);
    }

    if (this.props.onToggleIsStarted) {
      this.props.onToggleIsStarted(false);
    }

    if (this.props.dismissFullscreenPlayerOnEnd) {
      this.closeFullscreen();
    }

    if (this.props.endWithThumbnail) {
      this.props.onToggleIsStarted(false);
      this.closeFullscreen();
    }

    this.setState({ progress: 0, currentTime: 0 });

    if (!this.props.loop) {
      this.props.onTogglePlaying(false, function () {
        that.player && that.seek(0)
      });
    } else {
      that.seek(0)
    }
  }

  onLoad (event) {
    if (this.props.onLoad) {
      this.props.onLoad(event);
    }

    const { duration } = event;
    this.setState({ duration });
  }

  onFullscreenPlayerWillDismiss (event) {
    this.props.onTogglePlaying(false);
  }

  onFullscreenPlayerDidDismiss (event) {
    this.props.onTogglePlaying(true);
  }

  onFullscreenPlayerDidPresent (event) {}

  onPlayPress () {
    const _isPlaying = !this.props.isPlaying;
    if (this.props.isPlaying) {
      this.videoComponent.current.pause();
    } else {
      this.videoComponent.current.play();
    }
    if (this.props.muted) {
      if (this.props.onPlayPress) {
        this.props.onPlayPress(true);
      }
      this.props.onToggleMuted && this.props.onToggleMuted(false);
      this.props.onTogglePlaying(true);
    } else {
      if (this.props.onPlayPress) {
        this.props.onPlayPress(_isPlaying);
      }

      this.props.onTogglePlaying(_isPlaying);
    }

    this.showControls();
  }

  onMutePress () {
    const isMuted = !this.state.isMuted;
    if (this.props.onMutePress) {
      this.props.onMutePress(isMuted);
    }
    this.setState({
      isMuted
    });
    this.showControls();
  }

  openFullscreen () {
    const elem = this.videoComponent.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  closeFullscreen () {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
  }

  onToggleFullScreen () {
    this.openFullscreen();
    return this.props.onToggleFullScreen();
  }

  onSeekBarLayout ({ nativeEvent }) {
    const customStyle = this.props.customStyles.seekBar;
    let padding = 0;
    if (customStyle && customStyle.paddingHorizontal) {
      padding = customStyle.paddingHorizontal * 2;
    } else if (customStyle) {
      padding = customStyle.paddingLeft || 0;
      padding += customStyle.paddingRight ? customStyle.paddingRight : 0;
    } else {
      padding = 20;
    }

    this.seekBarWidth = nativeEvent.layout.width - padding;
  }

  onSeekStartResponder () {
    return true;
  }

  onSeekMoveResponder () {
    return true;
  }

  onSeekGrant (e) {
    this.seekTouchStart = e.nativeEvent.pageX;
    this.seekProgressStart = this.state.progress;
    this.wasPlayingBeforeSeek = this.props.isPlaying;
    this.props.onTogglePlaying(false);
    this.setState({
      isSeeking: true
    });
  }

  onSeekRelease () {
    this.props.onTogglePlaying(this.wasPlayingBeforeSeek);
    this.setState({
      isSeeking: false
    });
    this.showControls();
  }

  onSeek (e) {
    const diff = e.nativeEvent.pageX - this.seekTouchStart;
    const ratio = 100 / this.seekBarWidth;
    const progress = this.seekProgressStart + (ratio * diff) / 100;

    this.setState({
      progress
    });
    const seekProgress = progress * this.state.duration;
    this.videoComponent.current.currentTime = seekProgress;
    this.props.seekProgress && this.props.seekProgress(seekProgress);
  }

  getSizeStyles () {
    const { videoWidth, videoHeight } = this.props;
    const { width } = this.state;
    const ratio = videoHeight / videoWidth;
    // console.log({ videoWidth, videoHeight } )
    // console.log( { width })
    // console.log("getSizeStyles")

    // console.log({
    //   height: width * ratio,
    //   width,
    // })

    return {
      height: width * ratio,
      width
    };
  }

  hideControls () {
    if (this.props.onHideControls) {
      this.props.onHideControls();
    }

    if (this.props.disableControlsAutoHide) {
      return;
    }

    if (this.controlsTimeout) {
      clearTimeout(this.controlsTimeout);
      this.controlsTimeout = null;
    }
    this.controlsTimeout = setTimeout(() => {
      this.setState({ isControlsVisible: false });
    }, this.props.controlsTimeout);
  }

  showControls () {
    if (this.props.onShowControls) {
      this.props.onShowControls();
    }

    this.setState({
      isControlsVisible: true
    });
  }

  seek (t) {
    // console.log(this.player)
    if (t === 0) {
      this.setState({
        progress: 0,
        currentTime: 0
      });
    }
    this.videoComponent.current.currentTime = t;
  }

  formatTime (seconds) {
    let hour = parseInt(seconds / 3600);
    let minute = parseInt((seconds - hour * 3600) / 60);
    let sec = parseInt(seconds % 60);
    // console.log("Test input sec=", seconds, "hour=", hour, "minute=", minute, "sec=", sec);
    let formatTime = 0;
    if (hour > 99) {
      return formatTime;
    }
    if (seconds === 0) {
      return '00:00';
    }
    if (hour < 10 && hour > 0) {
      hour = '0' + hour.toString();
    }
    if (minute < 10) {
      minute = '0' + minute.toString();
    }
    if (sec < 10) {
      sec = '0' + sec.toString();
    }
    if (hour > 0) {
      formatTime = hour + ':' + minute + ':' + sec;
    } else {
      formatTime = minute + ':' + sec;
    }
    return formatTime;
  }

  renderStartButton () {
    const { customStyles } = this.props;
    return (
      <TouchableOpacity
        style={[customStyles.playButton]}
        onPress={this.onStartPress}>
        <Image
          style={{ width: 40, height: 40 }}
          source={require('./start.png')}
        />
      </TouchableOpacity>
    );
  }

  renderPauseButton () {
    const { customStyles } = this.props;
    return (
      <TouchableOpacity
        style={[customStyles.playButton]}
        onPress={this.onStartPress}>
        <Image
          style={{ width: 40, height: 40 }}
          source={require('./pause.png')}
        />
      </TouchableOpacity>
    );
  }

  renderThumbnail () {
    const { thumbnail, style, customStyles, ...props } = this.props;
    return (
      <View
        style={[
          {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          },
          this.props.fullscreenVideo ? customStyles.contentStyle : {}
        ]}>
        <BackgroundImage
          {...props}
          style={[
            styles.thumbnail,
            this.getSizeStyles(),
            style,
            customStyles.thumbnail
          ]}
          source={thumbnail}>
          {this.renderStartButton()}
        </BackgroundImage>
      </View>
    );
  }

  renderSeekBar (fullWidth) {
    const { customStyles, disableSeek } = this.props;
    return (
      <View
        style={[
          styles.seekBar,
          fullWidth ? styles.seekBarFullWidth : {},
          customStyles.seekBar,
          fullWidth ? customStyles.seekBarFullWidth : {}
        ]}
        onLayout={this.onSeekBarLayout}>
        <View
          style={[
            { flexGrow: this.state.progress },
            styles.seekBarProgress,
            customStyles.seekBarProgress
          ]}
        />

        {!fullWidth && !disableSeek
          ? (
          <View
            style={[
              styles.seekBarKnob,
              customStyles.seekBarKnob,
              this.state.isSeeking ? { transform: [{ scale: 1 }] } : {},
              this.state.isSeeking ? customStyles.seekBarKnobSeeking : {}
            ]}
            hitSlop={{ top: 20, bottom: 20, left: 10, right: 20 }}
            onStartShouldSetResponder={this.onSeekStartResponder}
            onMoveShouldSetPanResponder={this.onSeekMoveResponder}
            onResponderGrant={this.onSeekGrant}
            onResponderMove={this.onSeek}
            onResponderRelease={this.onSeekRelease}
            onResponderTerminate={this.onSeekRelease}
          />
            )
          : null}
        <View
          style={[
            styles.seekBarBackground,
            { flexGrow: 1 - this.state.progress },
            customStyles.seekBarBackground
          ]}
        />
      </View>
    );
  }

  renderControls () {
    const { customStyles, fullscreenVideo } = this.props;
    return (
      <View
        style={[
          styles.controls,
          customStyles.controls,
          fullscreenVideo ? {} : {}
        ]}>
        {
          <Text style={[styles.timeSyle, { marginRight: 10 }]}>
            {this.formatTime(this.state.currentTime)}
          </Text>
        }
        {this.renderSeekBar()}
        {
          <Text style={styles.timeSyle}>
            {this.formatTime(this.state.duration)}
          </Text>
        }

        {this.props.disableFullscreen
          ? null
          : (
          <TouchableOpacity
            onPress={this.onToggleFullScreen}
            style={[
              customStyles.controlButton,
              {
                height: '100%',
                width: 24,
                alignItems: 'center',
                justifyContent: 'center'
              }
            ]}>
            {fullscreenVideo
              ? (
              <Image
                style={{ width: 12, height: 12 }}
                source={require('./fullscreenOut.png')}
              />
                )
              : (
              <Image
                style={{ width: 12, height: 12 }}
                source={require('./fullscreen.png')}
              />
                )}
          </TouchableOpacity>
            )}
      </View>
    );
  }

  renderVideo () {
    const {
      pauseOnPress,
      fullScreenOnLongPress,
      customStyles,
      fullscreenVideo
    } = this.props;
    return (
      <View style={[customStyles.videoWrapper]}>
        <View
          style={
            fullscreenVideo
              ? [
                  customStyles.contentStyle,
                  {
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }
                ]
              : { flex: 1 }
          }>
          <video
            className="shaka-video"
            paused={(!this.props.isStarted).toString()}
            ref={this.videoComponent}
            poster={this.props.thumbnail.uri}
          />
          {/* <Video */}
          {/*  {...props} */}
          {/*  style={[ */}
          {/*    styles.video, */}
          {/*    this.getSizeStyles(), */}
          {/*    style, */}
          {/*    customStyles.video, */}
          {/*  ]} */}
          {/*  ref={p => { */}
          {/*    this.player = p; */}
          {/*  }} */}
          {/*  muted={this.props.muted} */}
          {/*  paused={!this.props.isPlaying} */}
          {/*  onProgress={this.onProgress} */}
          {/*  onEnd={this.onEnd} */}
          {/*  onBuffer={this.onBuffer} */}
          {/*  onLoad={this.onLoad} */}
          {/*  onFullscreenPlayerWillDismiss={this.onFullscreenPlayerWillDismiss} */}
          {/*  onFullscreenPlayerDidPresent={this.onFullscreenPlayerDidPresent} */}
          {/*  onFullscreenPlayerDidDismiss={this.onFullscreenPlayerDidDismiss} */}
          {/*  source={video} */}
          {/*  resizeMode={resizeMode} */}
          {/* /> */}
        </View>
        <View
          style={
            fullscreenVideo
              ? [
                  customStyles.contentStyle,
                  {
                    marginTop: -customStyles.contentStyle.height
                  }
                ]
              : [
                  this.getSizeStyles(),
                  { marginTop: -this.getSizeStyles().height }
                ]
          }>
          <TouchableOpacity
            style={styles.overlayButton}
            onPress={() => {
              this.showControls();
              if (pauseOnPress) {
                this.onPlayPress();
              }
            }}
            onLongPress={() => {
              if (fullScreenOnLongPress && Platform.OS !== 'android') {
                this.onToggleFullScreen();
              }
            }}>
            {!this.props.isPlaying ? this.renderPauseButton() : null}
          </TouchableOpacity>
        </View>
        {this.state.isControlsVisible ? this.renderControls() : null}
      </View>
    );
  }

  renderContent () {
    const { thumbnail, style } = this.props;
    const { isStarted } = this.props;
    // console.log("isStarted " + isStarted)
    if (!isStarted && thumbnail && !this.state.initLoad) {
      return this.renderThumbnail();
    } else if (!isStarted && !this.state.initLoad) {
      return (
        <View
          style={[styles.preloadingPlaceholder, this.getSizeStyles(), style]}>
          {this.renderStartButton()}
        </View>
      );
    }
    return this.renderVideo();
  }

  render () {
    return (
      <View onLayout={this.onLayout} style={[this.props.customStyles.wrapper]}>
        {this.renderContent()}
      </View>
    );
  }
}
VideoPlayer.propTypes = {
  video: PropTypes.object,
  videoId: PropTypes.object,
  thumbnail: PropTypes.object,
  isStarted: PropTypes.bool,
  isPlaying: PropTypes.bool,
  videoWidth: PropTypes.number,
  videoHeight: PropTypes.number,
  duration: PropTypes.number,
  autoplay: PropTypes.bool,
  paused: PropTypes.bool,
  defaultMuted: PropTypes.bool,
  muted: PropTypes.bool,
  style: PropTypes.object,
  controlsTimeout: PropTypes.number,
  disableControlsAutoHide: PropTypes.bool,
  disableFullscreen: PropTypes.bool,
  loop: PropTypes.bool,
  resizeMode: PropTypes.string,
  hideControlsOnStart: PropTypes.bool,
  endWithThumbnail: PropTypes.bool,
  disableSeek: PropTypes.bool,
  pauseOnPress: PropTypes.bool,
  fullScreenOnLongPress: PropTypes.bool,
  fullscreenVideo: PropTypes.bool,
  customStyles: PropTypes.shape({
    wrapper: PropTypes.object,
    video: PropTypes.any,
    videoWrapper: PropTypes.object,
    controls: PropTypes.object,
    controlIcon: PropTypes.object,
    playIcon: PropTypes.object,
    seekBar: PropTypes.object,
    seekBarFullWidth: PropTypes.object,
    seekBarProgress: PropTypes.object,
    seekBarKnob: PropTypes.object,
    seekBarKnobSeeking: PropTypes.object,
    seekBarBackground: PropTypes.object,
    thumbnail: PropTypes.object,
    playArrow: PropTypes.object
  }),
  seekProgress: PropTypes.func,
  onToggleMuted: PropTypes.func,
  dismissFullscreenPlayerOnEnd: PropTypes.func,
  onToggleFullScreen: PropTypes.func,
  onToggleIsStarted: PropTypes.func,
  onTogglePlaying: PropTypes.func,
  onEnd: PropTypes.func,
  onProgress: PropTypes.func,
  onLoad: PropTypes.func,
  onStart: PropTypes.func,
  onPlayPress: PropTypes.func,
  onHideControls: PropTypes.func,
  onShowControls: PropTypes.func,
  onMutePress: PropTypes.func
};

VideoPlayer.defaultProps = {
  videoWidth: 1280,
  videoHeight: 720,
  autoplay: false,
  controlsTimeout: 2000,
  loop: false,
  resizeMode: 'contain',
  disableSeek: false,
  pauseOnPress: false,
  fullScreenOnLongPress: false,
  customStyles: {}
};
