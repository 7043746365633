// @flow
import config from '../utils/config';
import request from '../utils/request';
import helper from '../utils/helper';
import type { SymbolList } from '../models/market';
import type { CommonResponse, ExchangeResult, PageResult } from './base';

// ////////////////////// model 定义
export type SymbolListInfoResponseType = CommonResponse & ExchangeResult<SymbolList[]>;

// ////////////////////// cfdBalance 定义
export interface BalanceItem {
  customerId: string,
  currency: string,
  hotMoney: string,
  coldMoney: string,
  freeMargin: string
}

export type CfdBalanceResponseType = ExchangeResult<BalanceItem> & CommonResponse;

// ////////////////////// cfdOrder 开始
export interface CfdOrder {
  customerId: string,
  orderType: number,
  marginMode: number,
  orderNum: string,
  copyNum: string,
  entrustTime: string,
  entrustWay: number, // 0 市价， 1 计划
  transactionAvgPrice: number,
  margin: number,
  position: number,
  amount: number,
  copyChannel: string,
  marginedType: string,
  opStatus: number,
  status: number, // 0-持仓中 //1-用户平仓 //2-止盈止损 //3-系统强平 //4-KOL平仓 //5-copyUser
  lever: string,
  symbol: string,
  stopLossPrice: string,
  takeProfitPrice: string,
  liquidationPrice: string,
  type: number,
  hotMoney: string,
  coldMoney: string,
  openPositionTime: string,
  remark: string,
  fundingFee: number,
  fundingRate: number,
  fundingTime: number,
  currency: string,
  quoteCurrency: string,
  closePositionTime: string,
  closeAvgPrice: number,
  realizedPnl: number,
  transactionFee: number,
  source: string,
  copyTs: number,
  copyBizNo: string,
  created: string,
  modified: string,
  accountId: string,
  maxTakeProfitPrice: string,
  entrustNum: string,
  errorCount: number,
  processType: string,
  origOrderInfo: string,
  initiative: boolean,
  copyCrossMargin: number,
  markPrice: number,
  lastOrder: boolean,
  unrealizedPnl: number,
  unrealizedPnlPercent: string,
  realizedPnlPercent: string,
  businessId: string,
  oldStatus: number,
  oldOpStatus: number,
  marketPrice: number,
  pricePrecision: number,
  kolId: string,
  usdtMoney: string,
  processTypeModified: string,
  closedUnrealizedPnl: number,
  fundingSettle: {
    customerId: string,
    orderType: string,
    orderNum: string,
    settleTime: number,
    position: number,
    fundingRate: number,
    fundingFee: number,
    loanAmount: number,
    entrustType: string,
    durationMinutes: number,
    created: number,
    modified: number
  }
}

export interface CfdEntrust {
  customerId: string,
  marginMode: number,
  entrustNum: string,
  opStatus: number,
  status: number,
  symbol: string,
  triggerPrice: string,
  entrustTime: string,
  type: number,
  remark: string,
  entrustWay: number,
  transactionAvgPrice: number,
  margin: number,
  amount: number,
  position: number,
  lever: string,
  stopLossPrice: string,
  takeProfitPrice: string,
  openPositionTime: string,
  currency: string,
  quoteCurrency: string,
  transactionFee: number,
  source: number,
  created: string,
  modified: string,
  accountId: string,
  liquidationPrice: string,
  maxTakeProfitPrice: string,
  errorCount: number,
  failReason: string,
  markPrice: number,
  oldStatus: number,
  oldOpStatus: number,
  dealNow: boolean,
  marketPrice: number,
  businessId: string,
  order_id: string
}

export type CfdOrderResponseType = ExchangeResult<CfdOrder[] | CfdEntrust[]> & CommonResponse;

// /////////////////////  cfdContractInfo
export interface CfdContractInfo {
  customerId: string,
  type: number,
  marketPrice: number,
  stopLossRate: number,
  takeProfitRate: number,
  maxTakeProfitRate: number,
  lastFundingRate: number,
  currency: string,
  quoteCurrency: string,
  keepMargin: number,
  orderAmountMin: number,
  orderAmountMax: number,
  orderMarginMin: number,
  orderMarginMax: number,
  pricePrecision: number,
  symbol: string,
  nextFundingTime: number,
  fundingTime: number,
  crossMarginRate: number
}

export type CfdContractInfoResponseType = ExchangeResult<CfdContractInfo> & CommonResponse;

// ////////////////////// Lever 开始
export interface CfdLever {
  level: string,
  initRate: number,
  maxPosition: number
}

export type CfdLeverResponseType = ExchangeResult<{ [key: string]: CfdLever }> & CommonResponse;

// ////////////////////// maringRate 开始
export interface CfdMarginRate {
  id: number,
  positionFrom: number,
  positionTo: number,
  rate: number,
  deduction: number
}

export type CfdMarginRateResponseType = ExchangeResult<CfdMarginRate[]> & CommonResponse;

// ///////////////////// cfdAddEntrust
export interface CfdAddEntrustRequest {
  customerId?: string,
  currency: string,
  source: number,
  entrustWay: number,
  type: number,
  marginMode: number,
  triggerPrice?: number,
  margin?: number,
  amount?: number,
  lever: string,
  stopLossPrice: string,
  takeProfitPrice: string
}

export type CfdAddEntrustResponseType = ExchangeResult<CfdEntrust | CfdOrder> & CommonResponse;

// ///////////////////// cfdOrderLite
export interface CfdOrderLite {
  customerId: string,
  orderType: number,
  marginMode: number,
  orderNum: string,
  transactionAvgPrice: number,
  margin: number,
  position: number,
  amount: number,
  status: number,
  lever: string,
  psymbol: string,
  type: number,
  fundingFee: number,
  currency: string,
  quoteCurrency: string,
  realizedPnl: number,
  unrealizedPnlPercent: string,
  transactionFee: number,
  marketPrice: number
}

export type CfdOrderLiteListResponseType = ExchangeResult<CfdOrderLite[]> & CommonResponse;

// ///////////////////// fundingfee
export interface CfdFundingSettle{
  customerId: string,
  orderType: number,
  orderNum: string,
  settleTime: number,
  position: number,
  fundingRate: number,
  fundingFee: number,
  loanAmount: number,
  entrustType: string,
  durationMinutes: number,
  created: number,
  modified: number
}
export type CfdFundingFeeResponseType = ExchangeResult<PageResult<CfdFundingSettle>> & CommonResponse;

// ////////////////////// class 开始

class CfdTradeAPI {
  /**
   * cfd合约余额查询
   * @param params (saas_id=kiki,customerId,quoteCurrency, marginMode(0), )
   * @returns {Promise<CfdBalanceResponseType>}
   */
  cfdBalance (params: { customerId?: ?string, marginMode?: ?string, quoteCurrency: string }): Promise<CfdBalanceResponseType> {
    params.customerId = USER_ID;
    const URL =
      config.api.host_newapi +
      config.api.cfd.contractBalance +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  /**
   * cfdMarketPrice
   * @param params
   * @returns {symbol: price}
   */
  cfdMarketPrice (params: { symbols: string[] }): Promise<ExchangeResult<{ [key: string]: number }>> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.cfd.marketPrice +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  cfdOrderOrEntrustList (params: {
    customerId?: ?string, orderType?: ?number, marginMode: ?number,
    status: ?string, offset: number, limit: number, category: 'order' | 'entrust'
  }): Promise<CfdOrderResponseType> {
    // params.saas_id = 'kiki';
    if (!params.customerId) {
      params.customerId = USER_ID;
    }
    const apiSuffix =
      params.category === 'order'
        ? config.api.cfd.orderList
        : config.api.cfd.entrustList;
    const URL =
      config.api.host_newapi +
      apiSuffix +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  cfdCancelEntrust (params: { marginMode?: ?number, entrustNum: string, remark?: ?string, customerId?: ?string }): Promise<CommonResponse> {
    // params.saas_id = 'kiki';
    if (!params.customerId) {
      params.customerId = USER_ID;
    }
    const URL =
      config.api.host_newapi +
      config.api.cfd.cancelEntrust +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  cfdContractInfo (params: { customerId?: string, currency: string, quoteCurrency: string, type: number }): Promise<CfdContractInfoResponseType> {
    // params.saas_id = 'kiki';
    if (!params.customerId) {
      params.customerId = USER_ID;
    }
    const URL =
      config.api.host_newapi +
      config.api.cfd.contractInfo +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  cfdConfigLever (): Promise<CfdLeverResponseType> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.cfd.configLever;
    return request.get.call({}, URL);
  };

  cfdMarginRates (): Promise<CfdMarginRateResponseType> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.cfd.marginRate;
    return request.get.call({}, URL);
  };

  cfdAddEntrust (params: CfdAddEntrustRequest): Promise<CfdAddEntrustResponseType> {
    // params.saas_id = 'kiki';
    if (!params.customerId) {
      params.customerId = USER_ID;
    }
    const URL =
      config.api.host_newapi +
      config.api.cfd.addEntrust +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  cfdCloseOrder (params: { customerId: ?string, orderType: ?number, marginMode: ?number, orderNum: string }): Promise<CommonResponse> {
    // params.saas_id = 'kiki';
    if (!params.customerId) {
      params.customerId = USER_ID;
    }
    const URL =
      config.api.host_newapi +
      config.api.cfd.closeOrder +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  cfdStopLossTakeProfitOrder (params: {
    customerId: ?string, orderType: ?number, marginMode: ?number, orderNum: string,
    stopLossPrice: number, takeProfitPrice: number
  }): Promise<CommonResponse> {
    // params.saas_id = 'kiki';
    if (!params.customerId) {
      params.customerId = USER_ID;
    }
    const URL =
      config.api.host_newapi +
      config.api.cfd.stopLossTakeProfitOrder +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  marketSymbols (): Promise<SymbolListInfoResponseType> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_api_v1 +
      config.api.cfd.marketSymbols;
    return request.get.call({}, URL);
  };

  /**
   * CFD 合约盘口列表（默认， 其他类型见@see cfdSpotSymbolList）
   * @returns {Promise<unknown>}
   */
  cfdSymbolList (): Promise<SymbolListInfoResponseType> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.cfd.symbolList;
    return request.post.call({}, URL);
  };

  /**
   * 现货盘口列表
   * @returns {Promise<unknown>}
   */
  cfdSpotSymbolList (): Promise<SymbolListInfoResponseType> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.cfd.spotSymbolList;
    return request.post.call({}, URL);
  };

  cfdOtcSymbolList (): Promise<SymbolListInfoResponseType> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.cfd.otcSymbolList;
    return request.post.call({}, URL);
  };

  /**
   * 永续盘口列表
   * @returns {Promise<unknown>}
   */
  cfdSwapSymbolList (): Promise<SymbolListInfoResponseType> {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.cfd.swapSymbolList;
    return request.post.call({}, URL);
  };

  orderLiteList (params: {
    customerId: ?string,
    offset: number,
    limit: number,
    orderType: ?number[],
    marginMode: ?number[]
  }): Promise<CfdOrderLiteListResponseType> {
    if (!params.customerId) {
      params.customerId = USER_ID;
    }
    const URL = config.api.host_newapi +
      config.api.cfd.orderLiteList +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  cfdFundingFees (params: {customerId?: ?string, orderType?: ?number, orderNum: string, offset: number, limit: number}): Promise<CfdFundingFeeResponseType> {
    // params.saas_id = 'kiki';
    if (!params.customerId) {
      // eslint-disable-next-line no-undef
      params.customerId = USER_ID;
    }
    const URL =
      config.api.host_newapi +
      config.api.cfd.fundingFees +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };
}

const CFDTradeAPI: CfdTradeAPI = new CfdTradeAPI();
export { CFDTradeAPI };
