// @flow
import React from 'react';
import type { Element } from 'react';
import { getLinkPreview } from 'link-preview-js';
import {
  Text,
  View
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import FastImage from 'react-native-fast-image';
import p from '../utils/Transfrom';
import helper from '../utils/helper';
import bugsnag from '../utils/bugsnag'

declare function _onLinkPressed(): void;
type LinkToPreviewOwnPropType = {
  text: string,
  _onLinkPressed: typeof _onLinkPressed
  // showOrignText: boolean
};
type LinkToPreviewStateType = {
  isUri: boolean,
  linkTitle: string,
  linkDesc: string,
  linkFavicon: string,
  linkImg: string,
  showText: boolean
};

type LinkToPreviewProsType = LinkToPreviewOwnPropType;

export default class linkToPreview extends React.PureComponent<LinkToPreviewProsType, LinkToPreviewStateType> {
  constructor (props: LinkToPreviewProsType) {
    super(props);
    this.state = {
      isUri: false,
      linkTitle: '',
      linkDesc: '',
      linkFavicon: '',
      linkImg: '',
      showText: false
    };
    this.getPreview(props.text);
  }

  getPreview: (text: string) => void = (text: string) => {
    getLinkPreview(text)
      // eslint-disable-next-line flowtype/no-weak-types
      .then((data: any) => {
        // eslint-disable-next-line no-unused-vars
        const { url, title, siteName, description, mediaType, contentType, images, videos, favicons } = data;
        this.setState({
          isUri: !!url,
          linkTitle: title ?? '',
          linkDesc: description ?? '',
          linkImg:
            images && Array.isArray(images) && images.length > 0
              ? (images: string[]).find((element: string): boolean => {
                  return (
                    element.includes('.png') || element.includes('.jpg') || element.includes('.jpeg')
                  );
                })
              : '',
          linkFavicon:
            data.favicons && data.favicons.length > 0
              ? data.favicons[data.favicons.length - 1]
              : ''
        });
      })
      .catch((error: string) => {
        bugsnag.notify(new Error('[components-linkToPreview] -- [getLinkPreview] -- [error]: ' + error.toString()));
        this.setState({ isUri: false, showText: true });
      });
  };

  componentDidUpdate: (nextProps: {text: string}) => void = (nextProps: {text: string}) => {
    if (nextProps.text !== this.props.text) {
      this.getPreview(nextProps.text);
    } else if (nextProps.text === null) {
      this.setState({ isUri: false });
    }
  };

  _onLinkPressed: () => void = () => {
    this.props._onLinkPressed && this.props._onLinkPressed();
  };

  renderLinkPreview: () => Element<*> = (): Element<*> => {
    const { linkTitle, linkImg } = this.state;
    const REGEX = /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/g;

    const matched = this.props.text.match(REGEX);
    let linkText = matched && matched[0];
    if (linkText) {
      const urlObj = helper.parseUrl(linkText) || {};
      linkText = urlObj && urlObj.host && urlObj.host.toUpperCase();
    }
    return (
      <TouchableOpacity activeOpacity={0.8}
        style={styles.containerStyle}
        activeOpacity={0.9}
        onPress={(): void => this._onLinkPressed() }>
        <View style={styles.textContainerStyle}>
          <Text numberOfLines={2} style={styles.titleStyle}>
            {linkTitle}
          </Text>
          <Text numberOfLines={1} style={styles.titleStyle}>
            {linkText}
          </Text>
        </View>
        <FastImage style={styles.imageStyle} source={{ uri: linkImg }} >
          <View style={{
            opacity: 0.4,
            justifyContent: 'center',
            alignItems: 'center',
            height: p(130),
            width: p(170),
            backgroundColor: '#000'
          }} />

          <View style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 99,
            justifyContent: 'center',
            alignItems: 'center',
            height: p(130),
            width: p(170)
          }}>
            <FastImage
              style={{ width: p(59), height: p(59) }}
              source={require('../images/play1.webp')}
            />
          </View>
        </FastImage>

      </TouchableOpacity>
    );
  };

  render (): Element<*> {
    const { showText } = this.state;
    const { text } = this.props;
    return this.state.isUri
      ? (
          this.renderLinkPreview()
        )
      : showText
        ? (
      <View
        style={{
          flex: 1,
          marginRight: p(18)
        }}>
        <Text
          style={{
            color: '#1F2126',
            fontSize: p(28),
            lineHeight: p(40)
          }}>
          {text}
        </Text>
      </View>
          )
        : (<View/>);
  }
}

const styles = {
  containerStyle: {
    flexDirection: 'row',
    backgroundColor: '#F6F5FA',
    height: p(130),
    flex: 1,
    borderRadius: p(12)
  },
  imageStyle: {
    width: p(170),
    height: p(130),
    borderRadius: p(12)
  },
  textContainerStyle: {
    flex: 1,
    paddingRight: p(18),
    marginTop: p(24),
    paddingLeft: p(20)
  },
  titleStyle: {
    fontSize: p(20),
    color: '#696F7F',
    marginBottom: p(10)
  }
};
