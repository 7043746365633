const asyncLocalStorage = {
  save: function (key, value) {
    return Promise.resolve().then(function () {
      if (typeof value === 'object') {
        value = JSON.stringify(value)
      }
      localStorage.setItem(key, value);
    });
  },
  get: function (key) {
    return Promise.resolve().then(function () {
      const ret = localStorage.getItem(key);
      try {
        return JSON.parse(ret)
      } catch (e) {
        return ret
      }
    });
  },
  update: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  delete: function (key) {
    localStorage.removeItem(key);
  }
};

export default asyncLocalStorage
