/* @flow */

import {
  StyleSheet,
  Platform,
  Dimensions
} from 'react-native';

import p from '../utils/Transfrom'

const { width } = Dimensions.get('window')

// eslint-disable-next-line flowtype/no-weak-types
const UIStyle: any = StyleSheet.create({
  // default Text style
  defaultFontFamily: {
    ...Platform.select({
      android: {
        fontFamily: 'Arial'
      }
    })
  },
  // RNModal style
  RNModalStyle: {
    paddingBottom: p(100),
    position: 'relative',
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center'

  },
  RNModalBodyStyle: {
    backgroundColor: 'transparent',
    paddingTop: p(60)
  },
  RNModalContStyle: {
    borderRadius: p(8),
    paddingTop: p(45),
    alignItems: 'center',
    backgroundColor: '#fff',
    justifyContent: 'center'
    // paddingHorizontal: p(10)
  },

  RNModalCodeTextStyle: {
    color: '#3B85FF',
    fontSize: p(24)
  },
  RNModalInputTextView: {
    alignItems: 'center',
    justifyContent: 'center',
    height: p(80),
    flex: 1,
    backgroundColor: '#fff'
  },
  RNModalInputBlockStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: p(35),
    borderBottomWidth: p(1),
    borderColor: '#CBD2DF',
    height: p(90),
    paddingHorizontal: p(0)
  },
  RNModalTitleStyle: {
    fontSize: p(36),
    textAlign: 'center',
    color: '#202558',
    marginTop: p(0),
    fontWeight: 'bold'
  },
  RNModalDescStyle: {
    fontSize: p(30),
    textAlign: 'center',
    color: '#98A6BF',
    marginTop: p(10),
    fontWeight: 'bold',
    marginBottom: p(60),
    lineHeight: p(45)
  },
  RNModalDeleteStyle: {
    fontSize: p(30),
    textAlign: 'center',
    color: '#203152',
    marginTop: p(10),
    marginBottom: p(60),
    lineHeight: p(45)
  },
  RNModalCloseBtnStyle: {
    flex: 1,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: '#E4E7F0',
    borderRightWidth: StyleSheet.hairlineWidth,
    borderRightColor: '#E4E7F0',
    alignItems: 'center',
    justifyContent: 'center'
  },
  RNModalCloseBtnTextStyle: {
    color: '#98A6BF',
    fontSize: p(30),
    fontWeight: 'bold'
  },
  RNModalConfirmBtnStyle: {
    flex: 1,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: '#E4E7F0',
    alignItems: 'center',
    justifyContent: 'center'
  },
  RNModalSaveQRStyle: {
    backgroundColor: '#FFC241',
    borderRadius: p(40),
    height: p(80),
    alignItems: 'center',
    justifyContent: 'center'
  },
  RNModalConfirmBtnTextStyle: {
    color: '#203152',
    fontSize: p(30),
    fontWeight: 'bold'
  },
  RNModalBtnBlockStyle: {
    flexDirection: 'row',
    marginTop: p(85),
    height: p(96),
    borderBottomLeftRadius: p(8),
    borderBottomRightRadius: p(8),
    backgroundColor: '#F8F9FF'
  },
  RNModalBtnBlockStyle2: {
    flexDirection: 'row',
    marginTop: p(30),
    height: p(96),
    borderBottomLeftRadius: p(8),
    borderBottomRightRadius: p(8),
    backgroundColor: '#F8F9FF'
  },

  RNModalIconStyle: {
    position: 'absolute',
    top: p(0),
    left: Platform.OS === 'ios' ? width / 2 - p(60 + 15) - p(63) : (width - p(126 + 60)) / 2,
    // left:Platform.OS === "ios" ? width / 2 - p(46) - p(63) : (width - p(126 + 60))/ 2 ,
    width: p(126),
    height: p(126),
    resizeMode: 'stretch',
    zIndex: 990

  },
  RNModalCancelStyle: {
    position: 'absolute',
    bottom: p(0),
    left: width / 2 - p(95),
    width: p(62),
    height: p(62)

  },
  RNModalQRStyle: {
    width: p(332),
    height: p(332)
  },
  // code btn style
  codeObtain: {
    backgroundColor: '#fff',
    paddingHorizontal: p(20),
    height: p(56),
    borderRadius: p(28),
    borderWidth: StyleSheet.hairlineWidth,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#3B85FF'
  },

  // up down color
  up_blue_color: {
    backgroundColor: '#41C080'
  },
  down_red_color: {
    backgroundColor: '#E43F3F'
  },
  // up down color
  up_blue_color_2: {
    color: '#41C080'
  },
  down_red_color_2: {
    color: '#E43F3F'
  },
  shadow: {
    shadowColor: 'rgba(0,0,0,0.08)',
    shadowOpacity: 1,
    shadowOffset: {
      width: 0,
      height: p(4)
    },
    elevation: 1.5
  }

});

module.exports = UIStyle
