// @flow
import axios from 'axios';
import I18n from './i18n';
import { subscribe } from 'redux-subscriber';
import type { StateType } from '../actions/common';
import { API_ENV } from '@env'

import bugsnag from './bugsnag'
import helper from './helper'
import type { CommonResponse } from '../api/base';
let jwtKey: ?string;
subscribe('HomeReducer', (state: StateType) => {
  const { HomeReducer } = state;
  jwtKey = HomeReducer.userInfo?.jwtKey ?? '';
});
export type OptType = {
  ignoreError?: boolean,
  verificationType?: number,
  sessionId?: string,
  Authorization?: string,
  needLogin?: boolean,
  isSilent?: boolean,
  jwtKey?: string,
  traffic?: string,
  scene?: string,
  token?: string,
  sig?: string
};

const defaultOpt: OptType = {
  ignoreError: false,
  isSilent: false,
  needLogin: false
}

const request: {
  get: (url: string, opts?: OptType) => Promise<*>,
  post: (url: string, opts?: OptType) => Promise<*>,
  delete: (url: string, opts?: OptType) => Promise<*>
} = {};

const defaultErrorResponse = {
  code: 'system.error',
  msg: 'system or client request error',
  msgKey: 'system.error',
  msgParam: [],
  obj: {},
  success: false
};

// eslint-disable-next-line flowtype/no-weak-types
type KikiResponseType = CommonResponse & {data?: Object};

async function doRequest (method: string, url: string, opts?: OptType = defaultOpt): Promise<KikiResponseType> {
  url = await joinParamsPost(url);
  const startRequestTime = new Date().getTime()
  const headers: OptType = opts.verificationType === 1
    ? {
        Accept: 'application/json;charset=utf-8',
        'Content-Type': 'application/json',
        saas_id: 'kiki',
        'SG-Verification-Type': opts.verificationType,
        'SG-Verification-Sig': opts.sig,
        'SG-Verification-Token': opts.token,
        'SG-Verification-Scene': opts.scene,
        'SG-Verification-Session-Id': opts.sessionId,
        JWT_TOKEN: opts.jwtKey ?? jwtKey ?? ''
      }
    : (opts.Authorization
        ? {
            Accept: 'application/json;charset=utf-8',
            'Content-Type': 'application/json',
            saas_id: 'kiki',
            'SG-Verification-Type': opts.verificationType,
            Authorization: opts.Authorization,
            'SG-Verification-Session-Id': opts.sessionId,
            JWT_TOKEN: opts.jwtKey ?? jwtKey ?? ''
          }
        : {
            Accept: 'application/json;charset=utf-8',
            'Content-Type': 'application/json',
            saas_id: 'kiki',
            JWT_TOKEN: opts.jwtKey ?? jwtKey ?? ''
          });
  if (API_ENV === 'pre') {
    headers.traffic = 'green'
  }
  const fetchOpt = {
    url,
    timeout: 5 * 1000,
    method,
    data: null,
    withCredentials: true,
    headers
  }
  if (method === 'POST') {
    const _params = url.split('?')[1]
    if (_params) {
      console.log(_params)
      console.log(url)
      fetchOpt.data = JSON.parse('{"' + _params.replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
      fetchOpt.data.saas_id = 'kiki'
    }
  }
  console.log('fetchOpt', fetchOpt);
  let responseData: KikiResponseType;
  try {
    // eslint-disable-next-line flowtype/no-weak-types
    const response = await axios<*, KikiResponseType>(fetchOpt);
    const requestTime = new Date().getTime() - startRequestTime
    console.log(new Date().getTime() + '  url: ' + url + ', requestTime ' + requestTime)
    if (response.status !== 200) {
      console.log(response);
      // bugsnag.notify(new Error("Test!!!"))
      bugsnag.notify(new Error('time:' + helper.toLocaleTime(new Date().getTime()) + ' method : ' + method + ',  url :' + url + ', params : ' + JSON.stringify(opts) + ', responseStatus: ' + response.status))
      return defaultErrorResponse;
    }
    responseData = response.data;
    if (responseData.msgKey === 'system.error') {
      bugsnag.notify(new Error('time:' + helper.toLocaleTime(new Date().getTime()) + ', url :' + url + ', method : ' + method + ', params : ' + JSON.stringify(opts) + ', responseJSON: ' + JSON.stringify(responseData)))
      throw new Error('system.error')
    }

    if (responseData.code === '500' || responseData.code === '405') {
      bugsnag.notify(new Error('time:' + helper.toLocaleTime(new Date().getTime()) + ', url :' + url + ', method : ' + method + ', params : ' + JSON.stringify(opts) + ', responseJSON: ' + JSON.stringify(responseData)))
      throw new Error('system.error')
    }
    console.log(responseData);
    return responseData;
  } catch (error) {
    console.log(url)
    console.log(JSON.stringify(fetchOpt ?? {}))
    console.log(error)
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      bugsnag.notify(new Error(
        'time:' + helper.toLocaleTime(new Date().getTime()) +
          ', url :' + url +
          ', method : ' + method +
          ', params : ' + JSON.stringify(opts) +
          ', axios___error__response__status: ' + error.response.status +
          ', axios___error__response__data: ' + error.response.data +
          ', axios___error__response__headers: ' + JSON.stringify(error.response.headers) +
          ', axios___error__response__message : ' + error.message
      ));
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);

      // bugsnag.notify(new Error(
      //   'time:' + helper.toLocaleTime(new Date().getTime()) +
      //     ', url :' + url +
      //     ', method : ' + method +
      //     ', params : ' + JSON.stringify(opts) +
      //     ', axios___error__message: The request was made but no response was received'
      // ));
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);

      bugsnag.notify(new Error(
        'time:' + helper.toLocaleTime(new Date().getTime()) +
          ', url :' + url +
          ', method : ' + method +
          ', params : ' + JSON.stringify(opts) +
          ', axios___error__message : ' + error.message
      ));
    }

    throw new Error('system.error')
  }
}

request.get = async function (url: string, opts?: OptType = defaultOpt): Promise<KikiResponseType> {
  return doRequest('GET', url, opts);
}

request.post = async function (url: string, opts?: OptType = defaultOpt): Promise<KikiResponseType> {
  return doRequest('POST', url, opts);
}

request.delete = async function (url: string, opts?: OptType = defaultOpt): Promise<KikiResponseType> {
  return doRequest('DELETE', url, opts);
}

const joinParamsPost = async function (params: string): Promise<*> {
  let language = ''
  const locale = I18n.locale;
  // if (locale === 'zh') {
  //   locale = 'zh_CN';
  // }

  language = locale;
  language = language.replace(/,/g, '')
  if (params.indexOf('language') === -1) {
    if (params.indexOf('?') >= 0) {
      params += '&language=' + language;
    } else {
      params += '?language=' + language
    }
  }

  // console.log("url",params);
  return params;
}

export default request;
