// @flow
import React, { Component } from 'react';
import type { Element } from 'react';
import { View } from 'react-native';
import SelectLanguage from './SelectLanguage';
import Notice from './Notice';
import Profile from './Profile';

type PropsType = {
    onSelect?: Function
};
type StateType = {};
class HeaderRight extends Component<PropsType, StateType> {
  constructor (props: any) {
    super(props);
  }

  componentDidMount () {
  }

  render (): Element<*> {
    return (
        <View style={ this.props.style } >
            <SelectLanguage />
            <Notice
                style={{
                  paddingRight: 20,
                  paddingLeft: 10
                }}
            />
            <Profile />
        </View>
    )
  }
}
export default HeaderRight;
