const Obj = {
  trackEvent: function (node, opt) {
    return new Promise((resolve, reject) => {
      try {
        resolve();
      } catch (e) {
        reject(e);
      }
    })
  }
}

export default Obj
