// @flow
import React from 'react';
import type { Element } from 'react';
import {
  View,
  StyleSheet,
  Text
} from 'react-native';
import FastImage from 'react-native-fast-image';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';

import I18n from '../utils/i18n';

import helper from '../utils/helper'

import { alignItems, width, imageRounded120, textAlign, lineHeight, colors, margin, padding, fontSize, fontWeight } from '../styles/Common'
import { Button } from '../styles/Button'
import type { HotKOL } from '../models/social';

function KOL ({ itemstr, navigation, follow, isLogin }: {
  itemstr: string,
  isLogin: boolean,
  follow: (item: HotKOL) => void,
  // eslint-disable-next-line flowtype/no-weak-types
  navigation: {navigate: (route: string, params: any)=>{}}
}): Element<*> {
  try {
    const item = JSON.parse(itemstr) || {}
    console.log(item)

    return (
        <View style={styles.socialKolRowStyle}>
          <TouchableWithoutFeedback onPress={() => {
            try {
              helper.trackEvent('NewHome Click PeopleToFollow ' + item.nickName, { item: JSON.stringify(item) });
            } catch (e) {
            }
            navigation.navigate('NormalUser', { itemData: { customerMisc: item } })
          }}>
            <View>
              <FastImage
                style={styles.socialKolAvatarStyle}
                source={
                  item.avatar
                    ? { uri: item.avatar }
                    : require('../images/defaultAvatar.webp')
                }
              />
              <Text ellipsizeMode={'tail'} numberOfLines={1} style={styles.socialKolNickNameStyle}>{item.nickName ?? '--'}</Text>
            </View>
          </TouchableWithoutFeedback>
          {!item.isFollow && global.USER_ID !== item.customerId && isLogin
            ? (
            <TouchableWithoutFeedback
              onPress={() => {
                follow(item)
              }}>
              <View style={Button.followBtnStyle}>
                <Text style={Button.followBtnTextStyle}>
                  {I18n.t('community_follow')}
                </Text>
              </View>
            </TouchableWithoutFeedback>
              )
            : null}

        </View>

    )
  } catch (e) {
    return (<View/>)
  }
}

const styles = StyleSheet.create({
  socialKolRowStyle: {
    alignItems: alignItems.center,
    paddingBottom: padding.p4,
    width: width.w120
  },
  socialKolAvatarStyle: imageRounded120,
  socialKolNickNameStyle: {
    color: colors.black1,
    fontSize: fontSize.f24,
    fontWeight: fontWeight.f500,
    lineHeight: lineHeight.l31,
    marginTop: margin.m32,
    marginBottom: margin.m12,
    textAlign: textAlign.center
  },

  followBtnStyle: Button.followBtnStyle,
  followBtnTextStyle: Button.followBtnTextStyle

})

// eslint-disable-next-line flowtype/no-weak-types
const MemoizedKol: any = React.memo(KOL);
export default MemoizedKol
