// @flow
'use strict';

import React, { Component } from 'react';
import type { Element } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Platform,
  Dimensions
} from 'react-native';
import FastImage from 'react-native-fast-image';
import Toast from 'react-native-easy-toast';
import Modal from 'react-native-modal';
import { ModalIndicator } from 'teaset';
import LottieLoading from './LottieLoading';

import p from '../utils/Transfrom';
import I18n from '../utils/i18n';

import helper from '../utils/helper';
import ImageViewer from 'react-native-image-zoom-viewer';
import CameraRoll from '@react-native-community/cameraroll';

import share from '../utils/share';
import type { CombinedReducerType, DispatchProps } from '../reducers';
import bugsnag from '../utils/bugsnag'

const RNFS = require('react-native-fs');

declare function onClickImageView(): void;
// type ImageViewListType = {
//   url: string
// };
type ImageZoomViewerOwnPropType = {
  onClickImageView: typeof onClickImageView,
  imageViewInitIndex: number,
  imageViewList: Array<*>,
  isHideIndex: boolean
};

type ImageZoomViewerStateType = {
  imageViewInitIndex: number,
  imageViewList: Array<*>,
  currentBigUrl: string
};

type ImageZoomViewerProsType = ImageZoomViewerOwnPropType & CombinedReducerType & DispatchProps;

const { width } = Dimensions.get('window');

export default class ImageZoomViewer extends Component <ImageZoomViewerProsType, ImageZoomViewerStateType> {
  constructor (props: ImageZoomViewerProsType) {
    super(props);
    this.state = {
      imageViewInitIndex: this.props.imageViewInitIndex || 0,
      imageViewList: this.props.imageViewList,
      currentBigUrl: this.props.imageViewList[
        this.props.imageViewInitIndex || 0
      ]
        ? this.props.imageViewList[
          this.props.imageViewInitIndex || 0
        ].url
        : ''
    };
  }

  toDataURL: (url: string) => Promise<*> = async (url: string): Promise<*> => {
    return fetch(url).then((response: Response): Promise<*> => {
      return response.blob();
    }).then((blob: Blob): string => {
      return URL.createObjectURL(blob);
    });
  };

  bigShareImg: () => Promise<*> = async () => {
    if (Platform.OS === 'web') {
      alert('分享')
    }

    // this.refs.imageViewerToast.show(I18n.t('common_loading'))
    // ModalIndicator.show(I18n.t('common_loading'));
    this.setState({
      showModalIndicator: true
    })
    if (Platform.OS === 'android') {
      await helper.checkAndroidPermission();
    }

    const that = this;
    const fileExtension =
      helper.getImageFileExtension(this.state.currentBigUrl) || 'png';
    const downloadDest = `${RNFS.DocumentDirectoryPath}/pic_share.${fileExtension}`;
    console.log(this.state.currentBigUrl);
    const options = {
      fromUrl: this.state.currentBigUrl,
      toFile: downloadDest,
      background: true,
      begin: (res: { contentLength: number }) => {
        console.log('begin', res);
        console.log('contentLength:', res.contentLength / 1024 / 1024, 'M');
      },
      progress: (res: { contentLength: number }) => {}
    };

    console.log(downloadDest);
    const uri = downloadDest
    try {
      const ret = RNFS.downloadFile(options);
      ret.promise
        .then((res: {}) => {
          console.log(res);

          if (Platform.OS === 'ios') {
            this.setState({
              snapshot: uri,
              visible: true
            }, function () {
              share.toShare('', '', '', this.state.snapshot, '', this.shareCallback)
            })

            that.setState({
              showModalIndicator: false
            })
          } else {
            console.log(uri)
            this.setState({
              snapshot: 'file://' + uri,
              visible: true
            }, function () {
              share.toShare('', '', '', this.state.snapshot, '', this.shareCallback)
            })

            that.setState({
              showModalIndicator: false
            })
          }
        })
        .catch((err: string) => {
          bugsnag.notify(new Error('[components-ImageZoomViewer] -- [bigShareImg-promise] -- [error]: ' + err.toString()));
          console.log('err', err);
          that.setState({
            showModalIndicator: false
          })
        });
    } catch (e) {
      bugsnag.notify(new Error('[components-ImageZoomViewer] -- [bigShareImg] -- [error]: ' + e.toString()));
      console.log(e);
      that.setState({
        showModalIndicator: false
      })
    }
  };

  downLoadImg: () => Promise<*> = async () => {
    if (Platform.OS === 'web') {
      const fileName = 'kikitrade_' + Date.now()
      const fileExtension = helper.getImageFileExtension(this.state.currentBigUrl) || 'png';
      const link = document.createElement('a');
      console.log(this.state.currentBigUrl)
      link.download = fileName + '.' + fileExtension;
      link.href = await this.toDataURL(this.state.currentBigUrl);
      link.click();

      return
    }
    if (Platform.OS === 'android') {
      await helper.checkAndroidPermission();
    }

    const that = this;
    const fileExtension =
      helper.getImageFileExtension(this.state.currentBigUrl) || 'png';
    const downloadDest = `${RNFS.DocumentDirectoryPath}/${(Math.random() * 1000) |
    0}.${fileExtension}`;
    console.log(this.state.currentBigUrl);
    const options = {
      fromUrl: this.state.currentBigUrl,
      toFile: downloadDest,
      background: true,
      begin: (res: { contentLength: number }) => {
        console.log('begin', res);
        console.log('contentLength:', res.contentLength / 1024 / 1024, 'M');
      },
      progress: (res: { contentLength: number }) => {}
    };

    try {
      const ret = RNFS.downloadFile(options);
      ret.promise
        .then((res: string) => {
          console.log(res);
          CameraRoll.save(downloadDest, { album: 'kikitrade' })
            .then(() => {
              console.log('save success');
              that.refs.imageViewerToast.show(
                I18n.t('community_imgSaved'),
                500,
                () => {
                  // something you want to do at close
                }
              );
            })
            .catch((err: string) => {
              bugsnag.notify(new Error('[components-ImageZoomViewer] -- [CameraRoll.save] -- [error]: ' + err.toString()));
              console.log(err);
            });
        })
        .catch((err: string) => {
          bugsnag.notify(new Error('[components-ImageZoomViewer] -- [downLoadImg-promise] -- [error]: ' + err.toString()));
          console.log('err', err);
        });
    } catch (e) {
      bugsnag.notify(new Error('[components-ImageZoomViewer] -- [downLoadImg] -- [error]: ' + e.toString()));
      console.log(e);
    }
  };

  shareCallback: (error: string, someData: {}) => void = (error: string, someData: {}) => {
    if (error) {
      console.log(error);
    } else {
      console.log(someData);
      this.refs.imageViewerToast.show(I18n.t('share_success'));
    }
  };

  onChangeImageViewer: (index: number) => void = (index: number) => {
    this.setState({
      currentBigUrl: this.props.imageViewList[index].url
    });
  };

  render (): Element<*> {
    console.log(this.props);
    const { showModalIndicator } = this.state
    const { imageViewList, imageViewInitIndex, isHideIndex } = this.props;
    const indexIndicator = isHideIndex ? { renderIndicator: () => {} } : {};
    return (
      <Modal
        deviceHeight={Math.max(
          Dimensions.get('window').height,
          Dimensions.get('screen').height
        )}
        isVisible
        coverScreen
        style={{ margin: 0, backgroundColor: '#000' }}>
        <ImageViewer
          imageUrls={imageViewList}
          {...indexIndicator}
          loadingRender={(): Element<*> => {
            return <LottieLoading style={{
              alignItems: 'center',
              height: p(400),
              paddingVertical: p(80)
            }}/>;
          }}
          // renderHeader={() => {
          //   return <View style={styles.header} />;
          // }}
          // renderFooter={(): Element<*> => {
          //   return <View style={styles.footer} />;
          // }}
          failImageSource={
            'https://cloud.githubusercontent.com/assets/7970947/21376663/ea10baf6-c771-11e6-9570-af3333428343.png'
          }
          saveToLocalByLongPress={false}
          index={imageViewInitIndex}
          imageWidth={width}
          enableImageZoom
          enableSwipeDown
          onSwipeDown={() => {
            this.setState({
              showModalIndicator: false
            })

            this.props.onClickImageView()
          }}
          onShowModal={this.onShowImageViewerModal}
          onChange={this.onChangeImageViewer}
          onClick={() => {
            this.setState({
              showModalIndicator: false
            })

            this.props.onClickImageView()
          }}
        />

        <TouchableOpacity activeOpacity={0.8}
          activeOpacity={0.8}
          onPress={this.props.onClickImageView}
          style={styles.backIcon1}>
          <FastImage
            source={require('../images/backIcon1.webp')}
            style={{ width: p(48), height: p(48) }}
          />
        </TouchableOpacity>

        <TouchableOpacity activeOpacity={0.8}
          activeOpacity={0.8}
          onPress={() => {
            this.bigShareImg()
          }}
          style={styles.bigShareImgWrap}>
          <FastImage
            source={require('../images/bigImageShare.webp')}
            style={styles.bigShareImg}
          />
        </TouchableOpacity>
        <TouchableOpacity activeOpacity={0.8}
          activeOpacity={0.8}
          onPress={() => {
            this.downLoadImg()
          }}
          style={styles.bigDownloadImgWrap}>
          <FastImage
            source={require('../images/bigImageDownload.webp')}
            style={styles.bigDownloadImg}
          />
        </TouchableOpacity>
        <Toast
          ref="imageViewerToast"
          style={{ backgroundColor: 'black' }}
          position="center"
          positionValue={200}
          fadeInDuration={750}
          fadeOutDuration={1000}
          opacity={0.8}
          textStyle={{ color: 'white' }}
        />
        {
          showModalIndicator &&
          <ModalIndicator.IndicatorView text={I18n.t('common_loading')}/>
        }

      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  header: {
    height: p(120),
    width: p(width),
    backgroundColor: '#000'
  },
  footer: {
    height: p(80),
    width: p(3 * width),
    backgroundColor: '#000'
  },
  bigShareImgWrap: {
    width: 32,
    height: 32,
    position: 'absolute',
    right: 20,
    bottom: 90
  },
  bigDownloadImgWrap: {
    width: 32,
    height: 32,
    position: 'absolute',
    right: 20,
    bottom: 30
  },
  bigShareImg: {
    width: 32,
    height: 32
  },
  bigDownloadImg: {
    width: 32,
    height: 32
  },
  backIcon1: {
    position: 'absolute',
    width: p(100),
    height: p(100),
    alignItems: 'center',
    justifyContent: 'center',
    top: p(50),
    left: p(10)

  }
});
