// @flow

/**
 * @Author:      vincent
 * @DateTime:    2018-03-28 21:56:05
 */

'use strict';

import appUrl from '../projectapp/urlConfig';
module.exports = {
  header: {
    method: 'POST',
    headers: {
      Accept: 'application/json;charset=utf-8',
      'Content-Type': 'application/json'
    }
  },
  website: 'https://www.kikitrade.com',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.kikitrade', // google play中下载地址
  appStoreUrl: 'https://apps.apple.com/us/app/kikitrade-crypto-for-everyone/id1507120441', // appStore中下载地址
  branchLink: 'https://db3u.test-app.link/MH0FFgc5d4',
  websocket: appUrl.websocket,
  supportUrl: appUrl.api.supportUrl,
  api: {
    host: appUrl.api.host, // webnode api
    host_newapi: appUrl.api.host_newapi, // api
    host_api_v1: appUrl.api.host_api_v1, // api_v1
    kline_url: appUrl.api.kline_url,
    kiki_website_url: appUrl.api.kiki_website_url,
    profit_chart_url: appUrl.api.profit_chart_url,
    explore_areaChart_url: appUrl.api.explore_areaChart_url,
    explore_compareLineChart_url: appUrl.api.explore_compareLineChart_url,
    explore_stackBarChart_url: appUrl.api.explore_stackBarChart_url,
    explore_VSChart_url: appUrl.api.explore_VSChart_url,
    explore_analysisChart_url: appUrl.api.explore_analysisChart_url,
    explore_areaChart_remote_url: appUrl.api.explore_areaChart_remote_url,
    market_chart_url: appUrl.api.market_chart_url,
    liveness_react_app_url: appUrl.api.liveness_react_app_url,
    upload_api: appUrl.api.upload_api,
    share: appUrl.api.share,
    simple_kline_host: appUrl.api.simple_kline_host,
    new_simple_kline_host: appUrl.api.new_simple_kline_host,
    google_web_clientId: appUrl.api.google_web_clientId,
    localeUpdate: 'customer/locale/update',
    otc: {
      payment: 'otc/payment',
      paymentWayAdd: 'otc/customer/paymentWay/add',
      paymentWayModify: 'otc/customer/paymentWay/modify',
      paymentWayRemove: 'otc/customer/paymentWay/remove',
      paymentWayDetail: 'otc/customer/paymentWay/detail',
      paymentWayList: 'otc/customer/paymentWay/list',
      depositRemarkcode: 'otc/customer/deposit/remarkcode',
      depositApply: 'otc/order/deposit/apply',
      depositDetail: 'otc/order/deposit/detail',
      depositList: 'otc/order/deposit/list',
      withdrawApply: 'otc/order/withdraw/apply',
      withdrawDetail: 'otc/order/withdraw/detail',
      withdrawList: 'otc/order/withdraw/list'
    },
    social: {
      getToken: 'api/social/getToken',
      ossKey: 'api/social/assumeRole',
      recommend: 'api/social/recommend',
      topicCategory: 'api/social/topic/category',
      topicAll: 'api/social/topic/all',
      topicPostFeed: 'api/social/topic/{topicId}/post',
      topicDetail: 'api/social/topic/{topicId}',
      hotUsers: 'api/social/users/hot',
      hotTopic: 'api/social/topic/hot'
    },
    cfd: {
      symbolList: 'cfd/symbol/listInfo',
      spotSymbolList: 'cfd/symbol/spot/listInfo',
      swapSymbolList: 'cfd/symbol/swap/listInfo',
      otcSymbolList: 'cfd/symbol/otc/listInfo',
      symbolListNew: 'v1/market/symbols/names',
      marketSymbols: 'v1/market/symbols',
      contractBalance: 'cfd/contract/balance',
      contractInfo: 'cfd/contract/info',
      configLever: 'cfd/config/lever',
      marginRate: 'cfd/config/marginRate',
      addEntrust: 'cfd/entrust/add',
      entrustList: 'cfd/entrust/list',
      orderList: 'cfd/order/list',
      closeOrder: 'cfd/order/close',
      stopLossTakeProfitOrder: 'cfd/order/sltp/config',
      cancelEntrust: 'cfd/entrust/cancel',
      marketPrice: 'cfd/contract/price',
      fundingFees: 'cfd/funding/fee/list',
      orderLiteList: 'cfd/order/lite/list'
    },
    trade: {
      acquireOrderId: 'v1/trading/order/id',
      marginBalance: 'v1/trading/balance',
      tradingInfo: 'v1/trading/info',
      changeMargin: 'v1/trading/margin',
      order: 'v1/trading/orders',
      cancelOrder: 'v1/trading/orders/{orderId}',
      orderDetail: 'v1/trading/orders/{orderId}',
      orderDetailFull: 'v1/trading/orders/{orderId}/detail',
      ordersOpen: 'v1/trading/orders',
      ordersHistory: 'v1/trading/orders',
      tradingTrades: '/v1/trading/trades',
      tradingTrade: 'v1/trading/trades/{tradeId}',
      zoneList: 'v1/market/zones',
      zoneDetail: 'v1/market/zones/{key}/{currency}',
      currencyDetail: 'v1/market/currencies/{currency}',
      orderbooks: '/v1/market/orderbooks/{symbol}',
      marginInterests: 'v1/trading/margin/interests',
      marginSettleOrders: 'v1/trading/margin/settle/orders'
    },

    config: {
      tabShow: 'cfd/config/tabShow'
    },
    cfdCopy: {
      copyOrder: 'copy/order/start',
      uncopyOrder: 'copy/order/close',
      balance: 'copy/balance',
      orderProfitModeModify: 'copy/order/sltp/config',
      copyOrderConfigQuery: 'copy/info',
      copyOrderList: 'copy/order/list',
      copyOrderDetail: 'copy/order/detail',
      copyFollowerRanking: 'copy/profit/ranking'
    },
    cfdTrader: {
      modifyDrawRate: 'trader/drawRate/modify',
      tokenSource: 'cfd/token/source',
      tokenApply: 'cfd/token/apply',
      tokenModify: 'cfd/token/modify',
      tokenRemove: 'cfd/token/remove',
      tokenQuery: 'cfd/token/get',
      traderPosition: 'trader/positions' // -->/type/customerId
    },
    spot: {
      placeOrder: 'spot/order',
      listOrders: 'spot/order/list/new',
      entrustCancel: 'spot/entrust/cancel',
      entrustList: 'spot/entrust/list'
    },
    support: {
      sign: 'support/sign'
    },
    login: {
      login: 'customer/login', // 登录
      login_oauth: 'customer/login/oauth' //
    },
    customer: {
      customer: 'customer',
      sendEmailCode: 'customer/verify/send/email/code',
      sendPhoneCode: 'phone/auth/code',
      bindPhone: 'phone/auth/binding',
      unBindPhone: 'phone/auth/remove',
      bindEmail: 'email/auth/binding',
      unBindEmail: 'email/auth/remove',
      verify: 'customer/verify',
      verifyGoogle: 'google/auth',
      register: 'customer/register', // 注册
      logout: 'customer/logout', // 登出
      forgotPwd: 'customer/pwd/forgot', //
      forgotPwdNew: 'customer/pwd/forgot/new',
      pwdReset: 'customer/pwd/reset',
      fullVerify: 'customer/full/verify',
      rebateInfo: 'rebate/get/rebate/baseinfo',
      rebateHistory: 'rebate/get/reward/history',
      verifyRebateCode: 'rebate/verify/code',
      referInfo: 'rebate/refer',
      query: 'customer/querycustomer'
    },

    account: {
      assetsDetail: 'v1/assets/{coinCode}',
      withdrawConfig: 'v1/pay/crypto/configs/{coinCode}',
      info: 'account/info',
      assets: 'account/assets',
      balance: 'account/getVirtualAccount',
      history: 'account/getCoinHistory',
      coinList: 'v1/market/currencies',
      depositAddress: 'dm/deposit/inner/address',
      showDepositAddress: 'dm/deposit/inner/address/show',
      currency: 'currency', // +USDT
      transactionList: 'dm/transaction/list',
      transfer: 'account/transfer',
      withdrawFee: 'dm/withdraw/fees', // 该接口返回所有product信息，即可以忽略currency
      withdrawAddress: 'dm/withdraw/outer/address',
      withdrawApply: 'dm/withdraw/apply',
      delWithdrawAddress: 'dm/withdraw/outer/address/delete',
      yuebaoProductList: 'yuebao/productList',
      yuebaoTransfer: 'yuebao/transfer',
      depositStatus: 'v1/pay/success/deposit/'
    },
    accountV1: {
      transactionList: 'v1/pay/crypto/transactions',
      delWithdrawAddress: 'v1/wallet/withdraw/addresses/{currency}/{id}',
      withdrawAddress: 'v1/wallet/withdraw/addresses/{currency}',
      depositAddress: 'v1/wallet/deposit/addresses/{currency}',
      withdrawApply: 'v1/pay/crypto/withdraw/apply',
      showDepositAddress: 'v1/wallet/deposit/addresses/{currency}'

    },

    google: {
      key: 'google/auth/key',
      binding: 'google/auth/binding',
      auth: 'google/auth',
      remove: 'google/auth/remove'
    },
    member: {
      level: 'member/level',
      orderPay: 'member/order/pay',
      cancelRenewl: 'member/cancel/renewl',
      orderList: 'member/order/list',
      orderDetail: 'member/order/detail',
      orderUpgrade: 'member/upgrade/price'
    },
    activity: {
      luckyBoxCount: '/cfd/activity/luckybox/remain',
      addLuckyBoxCount: '/cfd/activity/luckybox/addOneTime',
      openLuckyBox: '/cfd/activity/luckybox/open',
      getLuckyBoxResult: '/cfd/activity/luckybox/check',
      getLuckyBoxRecords: '/cfd/activity/luckybox/records/winning',
      redeem: 'redeemcard/redeem',
      history: 'redeemcard/history',
      redeemcardBuy: 'redeemcard/buy',
      buyHistory: 'redeemcard/buy/history',
      buyCards: 'redeemcard/buy/cards',
      referredRanking: 'cfd/activity/referred/ranking',
      inviteCode: 'customer/inviteCode',
      slotReferred: 'cfd/activity/slot/referred',
      giftRemarkCode: 'redeemcard/buy/remarkcode'
    },
    kyc: {
      kycResult: 'kyc/result',
      kycToken: 'kyc/token',
      kycLiveness: 'kyc/liveness',
      kycOcrUpload: 'kyc/ocr/upload'
    },
    credit: {
      channels: 'v1/quickpay/channels',
      currencies: 'v1/quickpay/currencies',
      orders: 'v1/quickpay/orders',
      ordersEstimate: 'v1/quickpay/estimate/orders',
      channelsContact: 'v1/quickpay/channels/{channelId}',
      channelsPay: 'v1/quickpay/orders/{channelId}/{orderId}/pay'
    },
    explore: {
      getLongShort: 'api/data/longshort',
      getList: 'api/data/list',
      getLiquidation: 'api/data/liquidation',
      getFundingfee: 'api/data/fundingfee',
      getFearAndGreedIndex: 'api/data/fearAndGreedIndex',
      getStockDataIndex: 'api/data/stockDataIndex'
    },
    earnings: {
      financingAccount: 'financing/account',
      financingProducts: 'financing/products',
      financingProductsAuto: 'financing/products/auto/currencies',
      financingAutoSubscribe: 'financing/auto/subscribe',
      financingProductsSubscribeDetail: 'financing/products/{productId}/subscribe',
      financingProductsRedeemDetail: 'financing/products/{productId}/redeem',
      financingContracts: 'financing/contracts',
      financingContractsHistory: 'financing/contracts/history',
      investmentProducts: 'investment/products',
      investmentProductsSubscribeDetail: 'investment/products/{productId}/subscribe',
      investmentContracts: 'investment/contracts',
      investmentContractsRenameDetail: 'investment/contracts/{contractId}/rename',
      investmentContractsHistoryDetail: 'investment/contracts/{contractId}/history',
      investmentContractsHistory: 'investment/contracts/history',
      investmentContractsPauseDetail: 'investment/contracts/{contractId}/pause',
      investmentContractsResumeDetail: 'investment/contracts/{contractId}/resume',
      investmentContractsStopDetail: 'investment/contracts/{contractId}/stop',
      investmentContractsAutoFinancingDetail: 'investment/contracts/{contractId}/auto/financing',
      investmentContractsLogDetail: 'investment/contracts/log/{operateId}',
      financingProductsDetail: 'financing/products/{productId}'
    },
    earningsV1: {
      financingProductsAutoSubscribe: 'v1/financing/products/{productId}/auto/subscribe',
      financingContractsAutoSubscribe: 'v1/financing/contracts/{contractId}/auto/subscribing',
      investmentProductsRunning: 'v1/investment/products/{productId}/running',
      investmentContractsStop: 'v1/investment/contracts/{contractId}/stop'
    },
    favoriteCustom: {
      favoriteRecommend: 'v1/market/favorites/recommend',
      hotSearch: 'v1/market/symbols/hot',
      favoriteAdd: 'v1/market/favorites',
      favoriteDelete: 'v1/market/favorites',
      favoriteSort: 'v1/market/favorites/sort',
      // favoriteZoneAdd: 'favorite/zone/add',
      // favoriteZoneDelete: 'favorite/zone/delete',
      // favoriteZoneSort: 'favorite/zone/sort',
      favoriteList: 'v1/market/favorites'
    }
  }
};
