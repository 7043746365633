// @flow

import React from 'react';
import type { Element } from 'react';
import {
  Text,
  DeviceEventEmitter,
  Animated,
  Platform,
  View
} from 'react-native';
import { ScrollView, FlatList } from 'react-native-gesture-handler';

import type { CombinedReducerType, DispatchProps } from '../../../reducers';
import { FetchEventCenterConfig, FetchDiscoverConfig } from '../../../actions/HomeAction';
import { FetchLongShortList } from '../../../actions/SocialAction';
import { FetchCoinList } from '../../../actions/AccountAction';

import { connect } from 'react-redux';
import { defaultMapDispatchProp, customMapStateProp } from '../../../reducers';
import { Toast } from 'teaset';

import MemoizedCoinCard from '../../../components/MemoizedCoinCard'
import MemoizedStarPeople from '../../../components/MemoizedStarPeople'
import MemoizedLongShortData from '../../../components/MemoizedLongShortData'
import MemoizedLiquidationView from '../../../components/MemoizedLiquidationView'
import MemoizedAnalysisView from '../../../components/MemoizedAnalysisView'
import MemoizedFundingFeeView from '../../../components/MemoizedFundingFeeView'
import MemoizedTagView from '../../../components/MemoizedTagView'
import { DiscoverContentLoading } from '../../../components/ContentLoading'

import MemoizedEventItem from '../../../components/MemoizedEventItem'
import MemoizedThemeItem from '../../../components/MemoizedThemeItem'
import SocialBaseComponent from '../../../components/SocialBaseComponent';

import Posts from '../../social/Posts';
import { SpotTradeAPI } from '../../../api/spotTrade';
import type { ZoneListResponseType } from '../../../api/spotTrade';
import { CFDTradeAPI } from '../../../api/cfdTrade';
import type { SymbolListInfoResponseType } from '../../../api/cfdTrade';
import { SocialAPI } from '../../../api/social';
import { AccountAPI } from '../../../api/account';
import type { CoinListResponseType } from '../../../api/account';

import type { HotUsersResponseType, HotTopicsResponseType } from '../../../api/social';

import type {
  Item
} from '../../../api/exploreData';
import HomeStyle from '../styles/home'
import WebHomeStyle from '../styles/webHome'

import KOL from './Kol'
import bugsnag from '../../../utils/bugsnag';
import _ from 'lodash'
import helper from '../../../utils/helper';
import I18n from '../../../utils/i18n';

import { margin, padding } from '../../../styles/Common'
import { SpringScrollView } from 'react-native-spring-scrollview'
import LottieHeader from '../../../components/LottieHeader'
import type { ExtractMarketData, SymbolList } from '../../../models/market';
import type { HotTopic, HotKOL } from '../../../models/social';

import type { ZoneItemType } from '../../market/SectionList'
import type { KikiMasterConfigType } from '../../../actions/common';

// 定义Home页面的State类型
type DiscoverStateType = {

};

// 联合注入的类型？  缺少navigator 的注入声明
type DiscoverProsType = DiscoverStateType & CombinedReducerType & DispatchProps;

const dataSortArray = [
  { type: 'long_short', symbol: 'BTC', exchange: 'Binance' },
  { type: 'analysis', code: 'BTCUSD', FearAndGreedIndex: '' },
  { type: 'long_short', symbol: 'ETH', exchange: 'Binance' },
  { type: 'liquidation', symbol: 'BTC' },
  { type: 'long_short', symbol: 'BCH', exchange: 'Binance' },
  { type: 'funding_fee', symbol: 'BTC/ETH' }

]

class Discover extends SocialBaseComponent {
  _scrollView;

  constructor (props: DiscoverProsType) {
    super(props);
    this.state = {
      dataList: [],
      fundingFeeItems: {
      },
      marketSymbols: this.props.MarketReducer.marketSymbols || [],
      FearAndGreedIndex: '--',
      loading: true,
      topicData: [],
      kolData: [],
      latestCoinlist: []
    };
  }

  componentDidMount () {
    const that = this
    that.loadData(false)

    this.firebaseEventListener = DeviceEventEmitter.addListener('localeUpdate', function (languageCode: string) {
      that.props.dispatch(FetchEventCenterConfig(languageCode));
    })

    this.firebaseEventListener1 = DeviceEventEmitter.addListener('firebaseWebReady', function () {
      that.props.dispatch(FetchEventCenterConfig(that.props.HomeReducer.languageCode));
      that.props.dispatch(FetchDiscoverConfig());
    })
  }

  loadData: (forceRefresh: boolean) => void = (forceRefresh: boolean) => {
    const that = this
    const locale = this.props.HomeReducer.languageCode;
    const { marketSymbols } = that.state
    this.props.dispatch(FetchEventCenterConfig(locale));

    this.props.dispatch(FetchLongShortList())
    this.getTopicData()
    if (marketSymbols.length === 0) {
      this.getFullMarketSymbols()
    }
    if (forceRefresh) {
      this.getFullMarketSymbols()
      this.props.dispatch(FetchDiscoverConfig());
      DeviceEventEmitter.emit('refreshRecommend')
    }
    if (Platform.OS === 'web') {
      that.props.dispatch(FetchCoinList())
    }

    this.getKOLData()
    this.getZoneList()
    this.getLatestCoinlist()
  };

  getLatestCoinlist: () => void = () => {
    AccountAPI.coinList({ sort: 'created' })
      .then((response: CoinListResponseType) => {
        if (response && response.success && response.obj) {
          this.setState({
            latestCoinlist: response.obj

          })
        }
        this.setState({
          loading: false
        })
      })
      .catch((error: Error) => {
        console.log(error)
        this.setState({
          loading: false
        })
        bugsnag.notify(new Error('[AccountAPI] -- [getLatestCoinlist] -- [error]: ' + error.toString()));
      });
  };

  getFullMarketSymbols: () => void = () => {
    CFDTradeAPI.marketSymbols()
      .then((response: SymbolListInfoResponseType) => {
        if (response && response.success && response.obj) {
          const marketSymbols: SymbolList[] = response.obj;
          this.setState({
            marketSymbols: helper.extractMarketDataList(marketSymbols)
          })
        }
      })
      .catch((error: Error) => {
        console.log(error)
        bugsnag.notify(new Error('[CFDTrade] -- [marketSymbols] -- [error]: ' + error.toString()));
      });
  };

  onFollowSuccess: (code: number) => void = (code: number) => {
    this.sending = false;
    if (code === 0) {
      if (!this.props.itemData?.customerMisc?.follow) {
        try {
          const { kolData, followUser } = this.state
          const index = _.findIndex(kolData, (itemData: HotKOL): boolean => {
            return itemData.customerId === followUser.customerId
          })
          const newKolData = [...kolData]
          newKolData[index].isFollow = true
          this.setState({
            kolData: newKolData
          })
        } catch (error) {
          bugsnag.notify(new Error('[kolData] -- [onFollowSuccess] -- [error]: ' + error.toString()));
        }
      }
      Toast.show({
        text: this.props.itemData?.customerMisc?.follow
          ? I18n.t('community_cancelFollow')
          : I18n.t('community_followSuccess'),
        position: 'center'
      });
    }
  };

  onFollowFail: () => void = () => {
    this.sending = false;
    Toast.show({
      text: this.props.itemData?.customerMisc?.follow
        ? I18n.t('community_cancelFollowFail')
        : I18n.t('community_followFail'),
      position: 'center'
    });
  };

  doFollow: (itemData: HotKOL) => void = (itemData: HotKOL) => {
    itemData = itemData || {}
    this.setState({
      followUser: itemData
    })
    this.follow({
      toCustomerId: itemData.customerId,
      customerId: this.props.HomeReducer?.userInfo?.id,
      isFollow: !itemData.isFollow,
      onSuccess: this.onFollowSuccess,
      onFail: this.onFollowFail,
      needLogin: () => {
        helper.navigate(this.props.navigation.navigate, 'Login');
      }
    });
  };

  getKOLData: () => void = () => {
    const that = this
    SocialAPI.getHotUsers({ limit: 10 })
      .then((response: HotUsersResponseType) => {
        that.setState({
          kolData: response.result ? response.result.res : []
        })
      })
      .catch((error: Error) => {
        console.log(error)
        bugsnag.notify(new Error('[SocialAPI]--[getKOLData]--error:' + error.toString()));
      })
  };

  setTopicData: () => void = () => {
    const { topicData } = this.state
    const topicDataRow1 = []; const topicDataRow2 = []; const topicDataRow3 = []
    topicData.forEach((item: HotTopic, index: number) => {
      switch (index % 3) {
        case 0:
          topicDataRow1.push(item)
          break;
        case 1:
          topicDataRow2.push(item)
          break;
        case 2:
          topicDataRow3.push(item)
          break;
      }
    })

    this.setState({
      topicDataRow1: topicDataRow1,
      topicDataRow2: topicDataRow2,
      topicDataRow3: topicDataRow3
    })
  };

  getTopicData: () => void = () => {
    const that = this
    SocialAPI.getHotTopic({ limit: 24 })
      .then((response: HotTopicsResponseType) => {
        that.setState({
          topicData: response.result ? response.result.res : []
        }, () => {
          that.setTopicData()
        })
      })
      .catch((error: Error) => {
        console.log(error)
        bugsnag.notify(new Error('[SocialAPI]--[getTopicData]--error:' + error.toString()));
      })
  };

  getZoneList: () => void = () => {
    const that = this
    SpotTradeAPI.getZoneList()
      .then((response: ZoneListResponseType) => {
        that.setState({
          dataList: response.obj
        })
      })
      .catch((error: Error) => {
        console.log(error)
        bugsnag.notify(new Error('[SectionList]--[getZoneList]--error:' + error.toString()));
      })
  };

  componentWillUnmount () {
    this.firebaseEventListener?.remove();
  };

  renderCoinItem: ({item: ExtractMarketData, index: number}) => Element<*> = ({ item, index }: {item: ExtractMarketData, index: number}): Element<*> => {
    const symbol = this.props.AccountReducer.coinMap[item.coinCode.split('_')[0].toUpperCase()];
    return (
      <MemoizedCoinCard
        item={item}
        symbol={symbol}
        showName
        navigation={this.props.navigation}
        index={index}
      />
    )
  };

  renderNewCoinItem: ({item: ExtractMarketData, index: number}) => Element<*> = ({ item, index }: {item: ExtractMarketData, index: number}): Element<*> => {
    const usdMarketItem = _.find(this.state.marketSymbols, (_item: {coinCode: string}): boolean => {
      return _item.coinCode === item.coinCode + '_USDT'
    }) || {}

    return (
      <MemoizedCoinCard
        coinTextStyle={{ marginTop: margin.m40 }}
        riseStyle={{ marginTop: margin.m4 }}
        hidePrice={true}
        item={usdMarketItem}
        symbol={item}
        showName
        navigation={this.props.navigation}
        index={index}
      />
    )
  };

  renderStarPeopleItem: ({ item: KikiMasterConfigType, index: number}) => Element<*> = ({ item, index }: { item: KikiMasterConfigType, index: number}): Element<*> => {
    return (
      <MemoizedStarPeople item={item} navigation={this.props.navigation} />

    )
  };

  renderDataRoomItem: ({ item: Item, index: number}) => Element<*> = ({ item, index }: { item: Item, index: number}): Element<*> => {
    console.log(item)
    const symbol = this.props.AccountReducer.coinMap[item.symbol] || {};

    if (item.type === 'long_short') {
      const data = _.map(item.dataList ?? [], 'value')
      return (
        <MemoizedLongShortData navigation={this.props.navigation} item={item} symbol={symbol} data={data}/>
      )
    } else if (item.type === 'liquidation') {
      return (
        <MemoizedLiquidationView item={item} symbol={symbol} navigation={this.props.navigation} languageCode={ this.props.HomeReducer.languageCode}/>
      )
    } else if (item.type === 'analysis') {
      const symbolBTC = this.props.AccountReducer.coinMap.BTC || {};
      return (
        <MemoizedAnalysisView item={item} symbol={symbolBTC} navigation={this.props.navigation} languageCode={ this.props.HomeReducer.languageCode}/>
      )
    } else if (item.type === 'funding_fee') {
      const symbolBTC = this.props.AccountReducer.coinMap.BTC || {};
      const symbolETH = this.props.AccountReducer.coinMap.ETH || {};

      return (
        <MemoizedFundingFeeView item={item} symbolBTC={symbolBTC} symbolETH={symbolETH} navigation={this.props.navigation} languageCode={ this.props.HomeReducer.languageCode}/>
      )
    } else {
      return (<View/>)
    }
  };

  renderEventItem: ({ item: {image: string | number, url: string, title: string}, index: number}) => Element<*> = ({ item, index }: { item: {image: string | number, url: string, title: string}, index: number}): Element<*> => {
    return (
      <MemoizedEventItem item={item} isLogin={ this.props.HomeReducer.isLogin} navigation={this.props.navigation}/>

    );
  };

  renderThemeItem: ({item: ZoneItemType, index: number}) => Element<*> = ({ item, index }: {item: ZoneItemType, index: number}): Element<*> => {
    item.localeText = I18n.t('zoneName' + (item.categoryKey ?? '').toUpperCase())
    return (
      <MemoizedThemeItem item={item} isLogin={ this.props.HomeReducer.isLogin} navigation={this.props.navigation}/>

    )
  };

  FlatListItemSeparator20: () => Element<*> = (): Element<*> => {
    return (
      <View style={{ marginRight: margin.m20 }} />
    );
  };

  _onRefresh = () => {
    this.loadData(true)
    setTimeout(() => {
      this._scrollView.endRefresh();
    }, 2000);
  };

  renderScreenItem: ({isLargeScreen: boolean, isRightScreen?: boolean}) => Element<*> = ({ isLargeScreen, isRightScreen }: {isLargeScreen: boolean, isRightScreen?: boolean}): Element<*> => {
    const { marketSymbols, dataList, topicDataRow1 = [], topicDataRow2 = [], topicDataRow3 = [], kolData, latestCoinlist } = this.state
    let { longShortData } = this.props.SocialReducer;
    const usdMarketItems = marketSymbols.filter(
      (item: {coinCode: string}): boolean => item.coinCode.split('_')[1] === 'USDT'
    ) || []

    const newLatestCoinlist = latestCoinlist.filter(
      (item: {coinCode: string}): boolean => item.coinCode !== 'USDT' && item.coinCode !== 'USD' && item.coinCode !== 'HKD'
    ) || []

    const { eventCenterConfig, discoverConfig } = this.props.HomeReducer;
    let arr = []
    longShortData = longShortData.filter(
      (_item: Item): boolean => _item.type === 'long_short' || _item.type === 'liquidation' || _item.type === 'analysis' || _item.type === 'funding_fee'
    ) || []
    dataSortArray.forEach((item: {type: string, code?: string, symbol?: string, exchange?: string}) => {
      if (item.type === 'long_short') {
        const longShortItem = longShortData.filter(
          (_item: Item): boolean => item.symbol === _item.symbol && item.exchange === _item.exchange && item.type === _item.type
        ) || []
        arr = arr.concat(longShortItem)
      } else if (item.type === 'liquidation' || item.type === 'analysis' || item.type === 'funding_fee') {
        const longShortItem = longShortData.filter(
          (_item: Item): boolean => (item.symbol === _item.symbol || item.code === _item.code) && item.type === _item.type
        ) || []
        arr = arr.concat(longShortItem)
      }
    })
    return (
    // eslint-disable-next-line flowtype/no-weak-types,array-callback-return
      discoverConfig.sort.map((item: string, index: number): any => {
        switch (item) {
          case 'hotCoin': {
            return (
              typeof isRightScreen !== 'undefined' && isRightScreen === true
                ? <></>
                : (<>
              <Text style={[HomeStyle.sectionTitleStyle]}>{I18n.t('hotCoin')}</Text>
              <View style={HomeStyle.sectionStyle}>
                <FlatList
                  data={usdMarketItems.slice(0, discoverConfig.hotCoinNum ?? 8)}
                  horizontal
                  contentContainerStyle={HomeStyle.flatListContentStyle}
                  showsHorizontalScrollIndicator={false}
                  renderItem={this.renderCoinItem}
                  ItemSeparatorComponent={this.FlatListItemSeparator20}
                  keyExtractor={(item: {id: string}): string => item.id}
                />

              </View>
                  </>
                  ))
          }
          case 'newCoin': {
            return (
              typeof isRightScreen !== 'undefined' && isRightScreen === true
                ? <></>
                : (
                    <>
              <Text style={HomeStyle.sectionTitleStyle}>{I18n.t('newCoin')}</Text>

              <View style={HomeStyle.sectionStyle}>
                <FlatList
                  data={newLatestCoinlist.slice(0, discoverConfig.newCoinNum ?? 8)}
                  horizontal
                  contentContainerStyle={HomeStyle.flatListContentStyle}
                  showsHorizontalScrollIndicator={false}
                  renderItem={this.renderNewCoinItem}
                  ItemSeparatorComponent={this.FlatListItemSeparator20}
                  keyExtractor={(item: {id: string}): string => item.id}
                />

              </View>

                    </>)
            )
          }
          case 'event': {
            return (
              typeof isRightScreen !== 'undefined' && isRightScreen === true
                ? <></>
                : (
                    <>
              <Text style={HomeStyle.sectionTitleStyle}>{I18n.t('lastEvent')}</Text>
              <View style={HomeStyle.sectionStyle}>

                <FlatList
                  data={eventCenterConfig}
                  horizontal
                  contentContainerStyle={HomeStyle.flatListContentStyle}
                  showsHorizontalScrollIndicator={false}
                  renderItem={this.renderEventItem}
                  ItemSeparatorComponent={this.FlatListItemSeparator20}
                  keyExtractor={(item: {id: string}): string => item.id}
                />

              </View>

                    </>)
            )
          }
          case 'tag': {
            if (isLargeScreen && (typeof isRightScreen !== 'undefined' && isRightScreen === true)) {
              return (
                <View style={WebHomeStyle.descoverRightPartContainer}>
                <Text style={HomeStyle.sectionTitleStyle}>{I18n.t('tags')}</Text>

                <ScrollView
                  contentContainerStyle={HomeStyle.flatListContentStyle}
                  horizontal
                  showsHorizontalScrollIndicator={false} >
                  <View style={[HomeStyle.tagStyle]}>
                      <View style={[HomeStyle.tagRow, { marginBottom: margin.m24 }]}>
                        {
                          topicDataRow1.slice(0, discoverConfig.tagNum ?? 8).map((item: HotTopic): Element<*> => {
                            return (
                              <MemoizedTagView item={item} navigation={this.props.navigation}/>

                            )
                          })
                        }
                      </View>
                      <View style={[HomeStyle.tagRow, { marginBottom: margin.m24 }]}>
                        {
                          topicDataRow2.slice(0, discoverConfig.tagNum ?? 8).map((item: HotTopic): Element<*> => {
                            return (
                              <MemoizedTagView item={item} navigation={this.props.navigation}/>
                            )
                          })
                        }
                      </View>
                      <View style={HomeStyle.tagRow}>
                        {
                          topicDataRow3.slice(0, discoverConfig.tagNum ?? 8).map((item: HotTopic): Element<*> => {
                            return (
                              <MemoizedTagView item={item} navigation={this.props.navigation}/>
                            )
                          })
                        }
                      </View>

                  </View>
                </ScrollView>

                </View>)
            } else if (isLargeScreen && !(typeof isRightScreen !== 'undefined' && isRightScreen === true)) {
              return <></>
            } else {
              return (
                <>
          <Text style={HomeStyle.sectionTitleStyle}>{I18n.t('tags')}</Text>

          <ScrollView
            contentContainerStyle={HomeStyle.flatListContentStyle}
            horizontal
            showsHorizontalScrollIndicator={false} >
            <View style={[HomeStyle.tagStyle]}>
                <View style={[HomeStyle.tagRow, { marginBottom: margin.m24 }]}>
                  {
                    topicDataRow1.slice(0, discoverConfig.tagNum ?? 8).map((item: HotTopic): Element<*> => {
                      return (
                        <MemoizedTagView item={item} navigation={this.props.navigation}/>

                      )
                    })
                  }
                </View>
                <View style={[HomeStyle.tagRow, { marginBottom: margin.m24 }]}>
                  {
                    topicDataRow2.slice(0, discoverConfig.tagNum ?? 8).map((item: HotTopic): Element<*> => {
                      return (
                        <MemoizedTagView item={item} navigation={this.props.navigation}/>
                      )
                    })
                  }
                </View>
                <View style={HomeStyle.tagRow}>
                  {
                    topicDataRow3.slice(0, discoverConfig.tagNum ?? 8).map((item: HotTopic): Element<*> => {
                      return (
                        <MemoizedTagView item={item} navigation={this.props.navigation}/>
                      )
                    })
                  }
                </View>

            </View>
          </ScrollView>

                </>)
            }
          }
          case 'theme': {
            return (
              typeof isRightScreen !== 'undefined' && isRightScreen === true
                ? <></>
                : (
                    <>
              <Text style={HomeStyle.sectionTitleStyle}>{I18n.t('hotTheme')}</Text>

              <View style={HomeStyle.sectionStyle}>

                <FlatList
                  data={dataList}
                  horizontal
                  contentContainerStyle={HomeStyle.flatListContentStyle}
                  showsHorizontalScrollIndicator={false}
                  renderItem={this.renderThemeItem}
                  ItemSeparatorComponent={this.FlatListItemSeparator20}
                  keyExtractor={(item: {id: string}): string => item.id}
                />

              </View>
                    </>)
            )
          }
          case 'data': {
            return (
              typeof isRightScreen !== 'undefined' && isRightScreen === true
                ? <></>
                : (
                    <>
              <Text style={HomeStyle.sectionTitleStyle}>{I18n.t('data')}</Text>
              <View style={HomeStyle.sectionStyle}>
                <FlatList
                  data={arr}
                  horizontal
                  contentContainerStyle={HomeStyle.flatListContentStyle}
                  showsHorizontalScrollIndicator={false}
                  renderItem={this.renderDataRoomItem}
                  ItemSeparatorComponent={this.FlatListItemSeparator20}
                  keyExtractor={(item: {id: string}): string => item.id}
                />

              </View>
                    </>)
            )
          }
          case 'peopleToFollow': {
            if (isLargeScreen && (typeof isRightScreen !== 'undefined' && isRightScreen === true)) {
              return (<View style={WebHomeStyle.descoverRightPartContainer}>
              <KOL follow={this.doFollow} navigation={this.props.navigation} kolData={kolData.slice(0, discoverConfig.peopleToFollowNum ?? 8)}/>
            </View>)
            } else if (isLargeScreen && !(typeof isRightScreen !== 'undefined' && isRightScreen === true)) {
              return <></>
            } else {
              return <KOL follow={this.doFollow} navigation={this.props.navigation} kolData={kolData.slice(0, discoverConfig.peopleToFollowNum ?? 8)}/>
            }
          }
          case 'social': {
            return (
              typeof isRightScreen !== 'undefined' && isRightScreen === true
                ? <></>
                : (
                    <>
              <Text style={HomeStyle.sectionTitleStyle}>{I18n.t('hotPicks')}</Text>
              <View style={[HomeStyle.sectionStyle, { flexDirection: 'column' }]}>
                <Posts
                  type={'recommend'}
                  index={0}
                  limit={discoverConfig.socialNum ?? 3}
                  scrollEnabled={false}
                  noAddMore={true}

                  feedTopStyle={HomeStyle.feedTopStyle}
                  myFeedTopStyle={HomeStyle.myFeedTopStyle}
                  noEmptyComponent
                  activeRouteName={'recommend'}
                  {...this.props}
                />
              </View>
                    </>)
            )
          }
          case 'kol': {
            return (
              typeof isRightScreen !== 'undefined' && isRightScreen === true
                ? <></>
                : (
                    <>
              <Text style={HomeStyle.sectionTitleStyle}>{I18n.t('kikiMaster')}</Text>
              <View style={HomeStyle.sectionStyle}>
                <FlatList
                  data={discoverConfig.kol}
                  horizontal
                  contentContainerStyle={HomeStyle.flatListContentStyle}
                  showsHorizontalScrollIndicator={false}
                  renderItem={this.renderStarPeopleItem}
                  ItemSeparatorComponent={this.FlatListItemSeparator20}
                  keyExtractor={(item: {id: string}): string => item.id}
                />
              </View>
                    </>)
            )
          }
        }
      })
    )
  };

  render (): Element<*> {
    const { animatedScrollYValue = new Animated.Value(0), loading } = this.state
    const IS_LARGE_SCREEN = this.props.screenProps?.screenSize === 'large_screen';
    console.log(111)
    return (

        // eslint-disable-next-line flowtype/no-weak-types
        <SpringScrollView ref={(component: any): void => (this._scrollView = component)}
            onScroll={Animated.event(
              [
                {
                  nativeEvent: {
                    contentOffset: { y: animatedScrollYValue }
                  }
                }
              ],
              {
                useNativeDriver: false,
                // eslint-disable-next-line flowtype/no-weak-types
                listener: (event: any) => {
                  this.props.setAnimatedScrollYValue && this.props.setAnimatedScrollYValue(animatedScrollYValue)
                }
              }
            )}
            contentStyle={{ paddingBottom: padding.p30, marginTop: -margin.m20 }}
            onRefresh={this._onRefresh}
            refreshHeader={LottieHeader}>
            {
              loading
                ? (
                    [0, 1, 2, 4].map((item: number): Element<*> => {
                      return (<DiscoverContentLoading/>)
                    })
                  )
                : (
                  // eslint-disable-next-line flowtype/no-weak-types,array-callback-return

                    IS_LARGE_SCREEN
                      ? (<View style={WebHomeStyle.descoverContainer}>
                      <View style={WebHomeStyle.descoverLeftPartStyle}>
                        {this.renderScreenItem({ isLargeScreen: IS_LARGE_SCREEN })}
                      </View>
                      <View style={WebHomeStyle.descoverRightPartStyle}>
                        {this.renderScreenItem({ isLargeScreen: IS_LARGE_SCREEN, isRightScreen: true })}

                      </View>
                  </View>)
                      : this.renderScreenItem({ isLargeScreen: IS_LARGE_SCREEN })
                  )
            }
        </SpringScrollView>

    )
  }
}

const connector = connect(customMapStateProp<DiscoverProsType>(
  {
    HomeReducer: 1,
    SocialReducer: 1,
    AccountReducer: 1
  }
), defaultMapDispatchProp<DiscoverProsType>());
export default (connector(Discover): Class<Discover>);
