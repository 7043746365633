// @flow
// import p from '../../../utils/Transfrom';
// import createStyles, { layoutCenter, sectionTitleStyle, imageRounded60, width, height, justifyContent, lineHeight, paddingHorizontal, marginHorizontal, alignItems, colors, zIndex, radius, margin, padding, fontSize, fontWeight, textAlign } from '../../../styles/Common'
import createStyles, { colors } from '../../../styles/Common'

// eslint-disable-next-line flowtype/no-weak-types
const webStyles: any = createStyles({
  // newIndex.js ---start---
  container: {
    backgroundColor: 'none',
    width: 'auto'
  },
  tabStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 51,
    marginBottom: 29,
    position: 'relative'

  },
  contentContainerStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1
  },
  depositDotStyle: {
    width: 8,
    height: 8
  },
  tabContentContainerStyle: {
    marginLeft: 80,
    marginRight: 80,
    marginBottom: 58,
    marginTop: 29
  },
  tabWhiteBackground: {
    backgroundColor: colors.pureWhite,
    borderRadius: 12
  },
  tabButton: {
    borderWidth: 0
  },
  tabButtonActive: {
    backgroundColor: colors.pureWhite,
    borderColor: colors.pureWhite
  },
  tabButtonTextActive: {
    fontWeight: 'normal'
  },
  // newIndex.js ---end---

  // Search.js ---start---
  searchViewStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    height: 54,
    marginTop: 50,
    marginBottom: 30
  },
  searchStyle: {
    width: 552,
    height: 54,
    backgroundColor: colors.pureWhite

  },
  searchIconStyle: {
    height: 24,
    width: 24
  },
  searchTextStyle: {
    fontSize: 18,
    fontFamily: 'Helvetica',
    color: colors.gray3,
    opacity: 0.4
  },
  // Search.js ---end---

  // Descover.js ---start---
  descoverContainer: {
    flexDirection: 'row'
  },
  descoverLeftPartStyle: {
    backgroundColor: '#fff',
    borderRadius: 12,
    flex: 2,
    marginRight: 14,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 20,
    paddingEnd: 20
  },
  descoverRightPartStyle: {
    borderRadius: 12,
    flex: 1
  },
  descoverRightPartContainer: {
    backgroundColor: '#fff',
    borderRadius: 12,
    width: '100%',
    paddingTop: 21,
    paddingLeft: 20,
    paddingEnd: 20,
    paddingBottom: 46,
    marginBottom: 12
  }
  // Descover.js ---end---
})
export default webStyles
