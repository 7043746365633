// @flow
// import EncryptedStorage from 'react-native-encrypted-storage';
import AsyncStorage from '@react-native-async-storage/async-storage';

import persistReducer from 'redux-persist/es/persistReducer';
import { API_ENV } from '@env';
import { ActionTypes } from '../actions/ActionTypes';
import type { AccountStateType, PersistActionType } from '../actions/common';
import type { AccountActionsType } from '../actions/AccountAction';
import type { Reducer } from 'redux';
import type { WithdrawFee } from '../api/account';

const initialState: AccountStateType = {
  loading: false,
  loadingError: null,
  assetIndex: 0,
  accountMap: {},
  assetAccountMap: {},
  accountInfoLoading: false,
  accountInfoError: null,
  accountInfo: null,
  historyLoading: false,
  historyHasNextPage: false,
  historyPage: 0,
  historyData: [],
  historyCoinCode: '',
  historyError: null,
  coinListLoading: false,
  coinListError: null,
  coinMap: {},
  coinListUpdateTs: 0,
  depositAddrLoading: false,
  depositAddr: null,
  depositAddrError: null,
  createDepositAddrLoading: false,
  createDepositAddr: null,
  createDepositAddrError: null,
  currencyLoading: false,
  currencyMap: {},
  currencyError: null,
  transactionsLoading: false,
  transactions: [], // @deprecated using depositTransactions and withdrawTransactions
  depositTransactions: { },
  withdrawTransactions: { },
  depositTransactionsPager: { },
  withdrawTransactionsPager: { },
  transactionsError: null,
  transactionsPage: 0, // @deprecated
  transactionsType: '', // @deprecated

  accountTransferLoading: false,
  accountTransferSuccess: 0,
  accountTransferError: null,
  withdrawFeeLoading: false,
  productMap: {},
  withdrawFeeError: null,
  withdrawAddrListLoading: false,
  withdrawAddrList: [],
  withdrawAddrListError: null,
  withdrawAddrLoading: false,
  withdrawAddr: null,
  withdrawAddrError: null,
  yuebaoProduct: [],
  showAssetAmount: false,
  showMinCoinCode: false,
  accountAssetFetched: false,
  accountInfoFetched: false,
  rateType: 'USD'
};

export type PersistAccountActionsType = AccountActionsType | PersistActionType;
// Reducer 第一参数必需兼容void， 参见Reducer定义。
type VoidAccountStateType = AccountStateType | void;

const accountReducer: Reducer<AccountStateType, PersistAccountActionsType> = (state: VoidAccountStateType, action: PersistAccountActionsType): AccountStateType => {
  if (action.type === ActionTypes.REHYDRATE) {
    // console.log('get rehydrate return');
    // console.log('accountReducer rehydrate state is ' + JSON.stringify(state));
    return state ?? initialState;
  }
  if (!state) {
    return initialState;
  }
  switch (action.type) {
    // 获取全部数据
    case ActionTypes.ACCOUNT_ASSET_REQUEST:
      return Object.assign({}, state, {
        loading: true
      });

    case ActionTypes.ACCOUNT_ASSET: {
      const accountMap = state.accountMap;
      const assetAccountMap = state.assetAccountMap;
      accountMap[action.assetIndex] = action.accountList;
      assetAccountMap[action.assetIndex] = action.assetAccountList;

      console.log(assetAccountMap)
      console.log(accountMap)

      return Object.assign({}, state, {
        assetAccountMap: assetAccountMap,
        accountMap: accountMap,
        assetIndex: action.assetIndex,
        loading: false,
        accountAssetFetched: true
      });
    }
    case ActionTypes.ACCOUNT_ASSET_ERROR:
      return Object.assign({}, state, {
        loadingError: action.error,
        loading: false,
        accountAssetFetched: true
      });
    case ActionTypes.ACCOUNT_ASSET_RESET:
      return Object.assign({}, state, {
        loadingError: null,
        loading: false
      });

    case ActionTypes.ACCOUNT_ASSET_ALL: {
      const accountMap = state.accountMap;
      const assetAccountMap = state.assetAccountMap;
      let idx = '0';
      for (idx in action.allAssets) {
        accountMap[Number(idx)] = action.allAssets[Number(idx)].accountList;
        assetAccountMap[Number(idx)] = action.allAssets[Number(idx)].assetAccountList;
      }

      console.log(assetAccountMap)
      console.log(accountMap)

      return Object.assign({}, state, {
        assetAccountMap: assetAccountMap,
        accountMap: accountMap,
        assetIndex: Number(idx),
        loading: false
      });
    }

    case ActionTypes.ACCOUNT_ASSET_ALL_ERROR:
      return Object.assign({}, state, {
        loadingError: action.error,
        loading: false
      });
    case ActionTypes.ACCOUNT_ASSET_ALL_RESET:
      return Object.assign({}, state, {
        loadingError: null,
        loading: false
      });

    case ActionTypes.ACCOUNT_INFO_REQUEST:
      return Object.assign({}, state, {
        accountInfoLoading: true
      });
    case ActionTypes.ACCOUNT_INFO:
      return Object.assign({}, state, {
        accountInfo: action.accountInfo,
        accountInfoLoading: false,
        accountInfoFetched: true
      });
    case ActionTypes.ACCOUNT_INFO_ERROR:
      return Object.assign({}, state, {
        accountInfoError: action.error,
        accountInfoLoading: false,
        accountInfoFetched: true
      });
    case ActionTypes.ACCOUNT_INFO_RESET:
      return Object.assign({}, state, {
        accountInfoError: null,
        accountInfoLoading: false
      });

    case ActionTypes.ACCOUNT_HISTORY_REQUEST:
      return Object.assign({}, state, {
        historyLoading: true
      });

    case ActionTypes.ACCOUNT_HISTORY: {
      let historyData = action.historyData || [];
      const historyPage = action.historyPage;
      if (state.historyCoinCode === action.historyCoinCode) {
        if (action.historyPage > state.historyPage) {
          historyData = state.historyData.concat(historyData);
        }
      }
      return Object.assign({}, state, {
        historyHasNextPage: action.historyHasNextPage,
        historyPage: historyPage, // page不变或更小则无数据
        historyData: historyData,
        historyLoading: false,
        historyCoinCode: action.historyCoinCode
      });
    }

    case ActionTypes.ACCOUNT_HISTORY_ERROR:
      return Object.assign({}, state, {
        historyError: action.error,
        historyLoading: false
      });
    case ActionTypes.ACCOUNT_HISTORY_RESET:
      return Object.assign({}, state, {
        historyError: null,
        historyLoading: false
      });

    case ActionTypes.COIN_LIST_REQUEST:
      return Object.assign({}, state, { coinListLoading: true });
    case ActionTypes.COIN_LIST:
      return Object.assign({}, state, {
        coinListLoading: false,
        coinMap: action.coinMap,
        coinListUpdateTs: new Date().getTime()
      });
    case ActionTypes.COIN_LIST_ERROR:
      return Object.assign({}, state, {
        coinListLoading: false,
        coinListError: action.error
      });
    case ActionTypes.COIN_LIST_RESET:
      return Object.assign({}, state, {
        coinListLoading: false,
        coinListError: null
      });

    case ActionTypes.CREATE_DEPOSIT_ADDR_REQUEST:
      return Object.assign({}, state, { createDepositAddrLoading: true });
    case ActionTypes.CREATE_DEPOSIT_ADDR:
      return Object.assign({}, state, {
        createDepositAddrLoading: false,
        createDepositAddr: action.depositAddr,
        depositAddr: action.depositAddr
      });
    case ActionTypes.CREATE_DEPOSIT_ADDR_ERROR:
      return Object.assign({}, state, {
        createDepositAddrLoading: false,
        createDepositAddrError: action.error
      });
    case ActionTypes.CREATE_DEPOSIT_ADDR_RESET:
      return Object.assign({}, state, {
        createDepositAddrLoading: false,
        createDepositAddrError: null
      });

    case ActionTypes.DEPOSIT_ADDR_REQUEST:
      return Object.assign({}, state, { depositAddrLoading: true });
    case ActionTypes.DEPOSIT_ADDR:
      return Object.assign({}, state, {
        depositAddrLoading: false,
        depositAddr: action.depositAddr
      });
    case ActionTypes.DEPOSIT_ADDR_ERROR:
      return Object.assign({}, state, {
        depositAddrLoading: false,
        depositAddrError: action.error
      });
    case ActionTypes.DEPOSIT_ADDR_RESET:
      return Object.assign({}, state, {
        depositAddrLoading: false,
        depositAddrError: null
      });

    case ActionTypes.CURRENCY_REQUEST:
      return Object.assign({}, state, { currencyLoading: true });

    case ActionTypes.CURRENCY: {
      const currency = action.currency;
      if (currency) {
        state.currencyMap[currency.currency] = currency;
      }
      return Object.assign({}, state, {
        currencyLoading: false,
        currencyMap: state.currencyMap
      });
    }

    case ActionTypes.CURRENCY_ERROR:
      return Object.assign({}, state, {
        currencyLoading: false,
        currencyError: action.error
      });
    case ActionTypes.CURRENCY_RESET:
      return Object.assign({}, state, {
        currencyLoading: false,
        currencyError: null
      });

    case ActionTypes.ACCOUNT_TRANSACTIONS_REQUEST:
      return Object.assign({}, state, { transactionsLoading: true });

    case ActionTypes.ACCOUNT_TRANSACTIONS: {
      let transactions = action.transactions || [];
      const transactionsCurrency = action.transactionsCurrency || 'ALL_CURRENCY';
      if (action.transactionsType === 1) { // deposit
        if (action.transactionsPage > 1) { // when page 1, overwrite
          if (action.transactionsPage > state.transactionsPage) {
            const old = state.depositTransactions[transactionsCurrency];
            transactions = (old ?? []).concat(transactions);
          }
        }
        const depositTransactions = state.depositTransactions;
        const depositTransactionsPager = state.depositTransactionsPager;
        depositTransactions[transactionsCurrency] = transactions
        depositTransactionsPager[transactionsCurrency] = { page: action.transactionsPage, hasNext: action.transactionsHasNext };

        return Object.assign({}, state, {
          transactionsLoading: false,
          depositTransactions: depositTransactions,
          depositTransactionsPager: depositTransactionsPager
        });
      } else {
        if (action.transactionsPage > 1) {
          if (action.transactionsPage > state.transactionsPage) {
            const old = state.withdrawTransactions[transactionsCurrency];
            transactions = (old ?? []).concat(transactions);
          }
        }
        const withdrawTransactions = state.withdrawTransactions;
        const withdrawTransactionsPager = state.withdrawTransactionsPager;
        withdrawTransactions[transactionsCurrency] = transactions
        withdrawTransactionsPager[transactionsCurrency] = { page: action.transactionsPage, hasNext: action.transactionsHasNext };

        return Object.assign({}, state, {
          transactionsLoading: false,
          withdrawTransactions: withdrawTransactions,
          withdrawTransactionsPager: withdrawTransactionsPager
        });
      }
    }

    case ActionTypes.ACCOUNT_TRANSACTIONS_ERROR:
      return Object.assign({}, state, {
        transactionsLoading: false,
        transactionsError: action.error
      });
    case ActionTypes.ACCOUNT_TRANSACTIONS_RESET:
      return Object.assign({}, state, {
        transactionsLoading: false,
        transactionsError: null
      });

    case ActionTypes.ACCOUNT_TRANSFER_REQUEST:
      return Object.assign({}, state, { accountTransferLoading: true });
    case ActionTypes.ACCOUNT_TRANSFER:
      return Object.assign({}, state, {
        accountTransferLoading: false,
        accountTransferSuccess: action.accountTransferSuccess
      });
    case ActionTypes.ACCOUNT_TRANSFER_ERROR:
      return Object.assign({}, state, {
        accountTransferLoading: false,
        accountTransferError: action.error,
        accountTransferSuccess: 0
      });
    case ActionTypes.ACCOUNT_TRANSFER_RESET:
      return Object.assign({}, state, {
        accountTransferLoading: false,
        accountTransferError: null,
        accountTransferSuccess: 0
      });

    case ActionTypes.WITHDRAW_FEE_REQUEST:
      return Object.assign({}, state, { withdrawFeeLoading: true });

    case ActionTypes.WITHDRAW_FEE: {
      const product: WithdrawFee = action.product;
      if (product) {
        state.productMap[product.product.coinCode] = product;
      }
      return Object.assign({}, state, {
        withdrawFeeLoading: false,
        productMap: state.productMap
      });
    }
    case ActionTypes.WITHDRAW_FEE_ERROR:
      return Object.assign({}, state, {
        withdrawFeeLoading: false,
        withdrawFeeError: action.error
      });
    case ActionTypes.WITHDRAW_FEE_RESET:
      return Object.assign({}, state, {
        withdrawFeeLoading: false,
        withdrawFeeError: null
      });

    case ActionTypes.WITHDRAW_ADDR_LIST_REQUEST:
      return Object.assign({}, state, { withdrawAddrListLoading: true });
    case ActionTypes.WITHDRAW_ADDR_LIST:
      return Object.assign({}, state, {
        withdrawAddrListLoading: false,
        withdrawAddrList: action.withdrawAddrList
      });
    case ActionTypes.WITHDRAW_ADDR_LIST_ERROR:
      return Object.assign({}, state, {
        withdrawAddrListLoading: false,
        withdrawAddrListError: action.error
      });
    case ActionTypes.WITHDRAW_ADDR_LIST_RESET:
      return Object.assign({}, state, {
        withdrawAddrListLoading: false,
        withdrawAddrListError: null
      });

    case ActionTypes.WITHDRAW_ADDR_REQUEST:
      return Object.assign({}, state, { withdrawAddrLoading: true });
    case ActionTypes.WITHDRAW_ADDR:
      return Object.assign({}, state, {
        withdrawAddrLoading: false,
        withdrawAddr: action.withdrawAddr
      });
    case ActionTypes.WITHDRAW_ADDR_ERROR:
      return Object.assign({}, state, {
        withdrawAddrLoading: false,
        withdrawAddrError: action.error
      });
    case ActionTypes.WITHDRAW_ADDR_RESET:
      return Object.assign({}, state, {
        withdrawAddrLoading: false,
        withdrawAddrError: null,
        withdrawAddr: null
      });

    case ActionTypes.WITHDRAW_APPLY_REQUEST:
      return Object.assign({}, state, { withdrawApplyLoading: true });
    case ActionTypes.WITHDRAW_APPLY:
      return Object.assign({}, state, {
        withdrawApplyLoading: false,
        withdrawApply: action.withdrawApply
      });
    case ActionTypes.WITHDRAW_APPLY_ERROR:
      return Object.assign({}, state, {
        withdrawApplyLoading: false,
        withdrawApplyError: action.error
      });
    case ActionTypes.WITHDRAW_APPLY_RESET:
      return Object.assign({}, state, {
        withdrawApplyLoading: false,
        withdrawApplyError: null,
        withdrawApply: null
      });
    case ActionTypes.ACCOUNT_LOGOUT:
    case ActionTypes.ACCOUNT_ASSET_INFO_CLEAN: {
      return Object.assign({}, initialState, { showAssetAmount: state.showAssetAmount, coinMap: state.coinMap, rateType: state.rateType });
    }
    case ActionTypes.ACCOUNT_YUEBAO_PRODUCT_LIST:
      return Object.assign({}, state, {
        yuebaoProduct: action.yuebaoProduct
      });
    case ActionTypes.CURRENT_RATE_TYPE:
      return Object.assign({}, state, { rateType: action.rateType });
    case ActionTypes.SHOW_ASSET_AMOUNT:
      return Object.assign({}, state, { showAssetAmount: action.showAssetAmount });
    case ActionTypes.SHOW_MIN_COINCODE:
      return Object.assign({}, state, { showMinCoinCode: action.showMinCoinCode });

    default:
      return state;
  }
};
const blacklist = ['loading', 'loadingError', 'accountTransferSuccess',
  'accountInfoLoading', 'accountInfoError', 'historyLoading', 'historyHasNextPage',
  'historyError', 'coinListLoading', 'coinListError', 'depositAddrLoading',
  'depositAddrError', 'createDepositAddrLoading', 'createDepositAddrError',
  'currencyLoading', 'currencyError', 'transactionsLoading', 'transactionsError',
  'accountTransferLoading', 'accountTransferError', 'withdrawFeeLoading', 'withdrawFeeError',
  'withdrawAddrListLoading', 'withdrawAddrListError', 'withdrawAddrLoading', 'withdrawAddrError', 'accountAssetFetched', 'accountInfoFetched'];

const accountPersistConfig = {
  key: 'account',
  debug: API_ENV === 'prod',
  timeout: 10000,
  // storage: Platform.OS === 'ios' ? AsyncStorage : EncryptedStorage,
  storage: AsyncStorage,
  blacklist
};

const persistAccountReducer: (AccountStateType, PersistAccountActionsType) => AccountStateType = persistReducer<AccountStateType, PersistAccountActionsType>(
  accountPersistConfig,
  accountReducer
);

export default persistAccountReducer;
