/* @flow */



module.exports = {
  toShare: async function () {

  }

}
