/**
 * @flow
 */
// import bugsnag from '../utils/bugsnag';

import I18n from 'react-native-i18n';
import { isEmpty } from 'lodash';
// $FlowIgnore
import store from 'react-native-simple-store';
import { NativeModules, Platform } from 'react-native';
import config from './config'
import en from './language/en';
import zh from './language/zh';
import hk from './language/hk';

let appLocale: string;

if (Platform.OS === 'ios') {
  appLocale = NativeModules.SettingsManager.settings.AppleLocale;
} else if (Platform.OS === 'android') {
  appLocale = NativeModules.I18nManager.localeIdentifier;
} else if (Platform.OS === 'web') {
  appLocale = window.navigator.language;
} else {
  appLocale = window.navigator.language;
}

try {
  if (isEmpty(appLocale)) {
    I18n.defaultLocale = 'en';
    I18n.locale = 'en';
    I18n.locale_new = 'en'; // add korea
  } else {
    const arr = appLocale.replace(/-/g, '_').split('_')
    let lang = arr[0];
    lang = lang.toLowerCase();
    // const region = arr.length > 1 ? arr[arr.length - 1].toLowerCase() : ''

    if (['en'].indexOf(lang) !== -1) {
      I18n.defaultLocale = 'en'
      I18n.locale = 'en'
      I18n.locale_new = 'en'
    } else if (['ko'].indexOf(lang) !== -1) {
      I18n.defaultLocale = 'en';
      I18n.locale = 'en';
      I18n.locale_new = 'ko' // add korea
    } else if (['zh'].indexOf(lang) !== -1) {
      if (appLocale.toLowerCase().indexOf('zh-hant') !== -1 || appLocale.toLowerCase() === 'zh_hk' || appLocale.toLowerCase() === 'zh_tw') {
        I18n.defaultLocale = 'hk'
        I18n.locale = 'hk'
        I18n.locale_new = 'hk'
      } else {
        I18n.defaultLocale = 'zh';
        I18n.locale = 'zh';
        I18n.locale_new = 'zh'
      }
    }

    if (arr.length === 3) {
      if (appLocale.toLowerCase().indexOf('hant') !== -1) {
        I18n.defaultLocale = 'hk'
        I18n.locale = 'hk'
        I18n.locale_new = 'hk'
      } else if (appLocale.toLowerCase().indexOf('hans') !== -1) {
        I18n.defaultLocale = 'zh'
        I18n.locale = 'zh'
        I18n.locale_new = 'zh'
      } else {
        I18n.defaultLocale = 'en'
        I18n.locale = 'en'
        I18n.locale_new = 'en'
      }
    }

    // alert(appLocale.toLowerCase() === "zh-hk")
  }
} catch (e) {
  // bugsnag.notify(new Error('[I18nUtil]--[setLocale]--error  : ' + e.toString()));
  I18n.defaultLocale = 'en';
  I18n.locale = 'en';
  I18n.locale_new = 'en'; // add korea
}

const getQueryParams = function (qs: string): { [string]: string } {
  if (qs.indexOf('?') !== -1) {
    qs = qs.substr(qs.indexOf('?') + 1)
  }
  qs = qs.split('+').join(' ');

  const params = {};
  let tokens: string[] | null;
  const re = /[?&]?([^=]+)=([^&]*)/g;

  while ((tokens = re.exec(qs)) !== null) {
    if (tokens && tokens.length > 0) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }
  }

  return params;
}

if (Platform.OS === 'web' && getQueryParams(location.href).locale) {
  const _newLocale = getQueryParams(location.href).locale

  if (_newLocale === 'en' || _newLocale === 'hk' || _newLocale === 'zh') {
    I18n.defaultLocale = _newLocale
    I18n.locale = _newLocale
    I18n.locale_new = _newLocale
  }
}

// :TODO fix
// eslint-disable-next-line flowtype/require-parameter-type
store.get('languageCode').then(result => {
  const res = (result: string);
  if (res) {
    I18n.locale = res;
    I18n.defaultLocale = res;
  }
});

I18n.fallbacks = true;
I18n.defaultSeparator = '__';
I18n.translations = { en, zh, hk };

const I18nUtil = {
  t: function (msg: string, options?: { defaults?: string, defaultValue?: string }): string {
    options = options ?? {}
    if ((I18n.t(msg, options): string).indexOf('missing') === -1) {
      return (I18n.t(msg, options): string);
    } else {
      return en[msg] || msg;
    }
  },
  locale: I18n.locale,
  defaultLocale: I18n.defaultLocale,
  locale_new: I18n.locale_new,
  setLocale: function (newLocale: string) {
    if (newLocale !== 'en' && newLocale !== 'hk' && newLocale !== 'zh') {
      return;
    }
    I18n.locale = newLocale;
    I18nUtil.locale = newLocale;
    // 适配了目前的需求，但是如果feed有韩语，但是app没韩语，就需要重新测试和适配
    I18n.locale_new = newLocale;
    I18nUtil.locale_new = newLocale;
  },
  mergeRemoteLocale: async function (locale: string) {
    const promises = [];
    const url1 = config.api.kiki_website_url + 'language/' + locale.toLowerCase() + '.json?a=' + Date.now()
    const url2 = config.api.kiki_website_url + 'language/coinDesc-' + locale.toLowerCase() + '.json?a=' + Date.now()
    const url3 = config.api.kiki_website_url + 'language/zoneDesc-' + locale.toLowerCase() + '.json?a=' + Date.now()

    promises.push(this.fetchRemoteLocale(url1), this.fetchRemoteLocale(url2), this.fetchRemoteLocale(url3))
    try {
      const responses = await Promise.all(promises);
      console.log(responses)
      // eslint-disable-next-line  no-eval

      switch (locale) {
        case 'en':
          I18n.translations.en = Object.assign({}, I18n.translations.en, responses[0], responses[1], responses[2])
          break;
        case 'hk':
          I18n.translations.hk = Object.assign({}, I18n.translations.hk, responses[0], responses[1], responses[2])
          break;
        case 'zh':
          I18n.translations.zh = Object.assign({}, I18n.translations.zh, responses[0], responses[1], responses[2])
          break;
      }
    } catch (error) {
      // bugsnag.notify(new Error('[I18nUtil]--[mergeRemoteLocale]--error  : ' + error.toString()));
    }
  },
  fetchRemoteLocale: function (url: string): Promise<*> {
    return new Promise((resolve: (data: { [key: string]: string})=> void, reject: (Error)=> void) => {
      fetch(url)
      // fetch('http://172.30.139.29:8082/' + 'language/' + locale.toLowerCase() + '.json?a=' + Date.now())
        .then(function (response: Response): Promise<*> {
          return response.json();
        }).then(function (data: { [key: string]: string}) {
          resolve(data);
        }).catch((err: Error) => {
          console.log(err)
          // bugsnag.notify(new Error('[I18nUtil]--[fetchRemoteLocale]--error  : ' + err.toString()));
        });
    })
  }

};

export default I18nUtil;
