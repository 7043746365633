
// @flow
import React from 'react';
import {
  View,
  Text,
  TouchableWithoutFeedback
} from 'react-native';
import FastImage from 'react-native-fast-image';
import I18n from '../../../utils/i18n';
import type { Element } from 'react';
import helper from '../../../utils/helper';

import HomeStyle from '../styles/home';
import WebHomeStyle from '../styles/webHome';

export default function Search ({ navigation, IS_LARGE_SCREEN }: {
  navigation: {navigate: (route: string)=>void},
  IS_LARGE_SCREEN?: boolean
}): Element<*> {
  return (
      <View
        style={[
          { ...HomeStyle.searchViewStyle },
          typeof IS_LARGE_SCREEN !== 'undefined' && IS_LARGE_SCREEN === true ? WebHomeStyle.searchViewStyle : null
        ]}
      >
        <TouchableWithoutFeedback onPress={() => {
          try {
            helper.trackEvent('NewHome Click SearchEncryptionCurrency');
          } catch (e) {
          }

          navigation.navigate('SearchEncryptionCurrency')
        }}>
          <View style={[
            HomeStyle.searchStyle,
            typeof IS_LARGE_SCREEN !== 'undefined' && IS_LARGE_SCREEN === true ? WebHomeStyle.searchStyle : { flex: 1 }
          ]}>
            <FastImage
              source={require('../../../images/input_search_icon.webp')}
              style={HomeStyle.searchIconStyle}
              style={[
                HomeStyle.searchIconStyle,
                typeof IS_LARGE_SCREEN !== 'undefined' && IS_LARGE_SCREEN === true ? WebHomeStyle.searchIconStyle : null
              ]}
            />
            <View
                style={HomeStyle.searchTextViewStyle}
              >
                <Text style={[
                  HomeStyle.searchTextStyle,
                  typeof IS_LARGE_SCREEN !== 'undefined' && IS_LARGE_SCREEN === true ? WebHomeStyle.searchTextStyle : null
                ]}>

                  {I18n.t('search')}
                </Text>
              </View>
          </View>
        </TouchableWithoutFeedback>

      </View>

  )
}
